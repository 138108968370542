import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserManagementComponent } from './user-management.component';
import { SharedModule } from '../shared/shared.module';
import { AdminComponent } from './admin/admin.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { UserDialogBoxComponent } from './admin/user-dialog-box/user-dialog-box.component';
import { AdminService } from './admin/service/admin.service';
import { HistoryDialogBoxComponent } from './admin/history-dialog-box/history-dialog-box.component';
import { ViewUserDialogBoxComponent } from './admin/view-user-dialog-box/view-user-dialog-box.component';
import { LockoutDialogBoxComponent } from './admin/lockout-dialog-box/lockout-dialog-box.component';
import { StoriesCredentialsDialogBoxComponent } from './admin/stories-credentials-dialog-box/stories-credentials-dialog-box.component';

@NgModule({
  declarations: [
    UserManagementComponent,
    AdminComponent,
    UserDialogBoxComponent,
    HistoryDialogBoxComponent,
    ViewUserDialogBoxComponent,
    LockoutDialogBoxComponent,
    StoriesCredentialsDialogBoxComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    UserManagementRoutingModule,
    MaterialModule
  ],
  exports: [],
  providers: [AdminService],
  entryComponents: [
    UserDialogBoxComponent,
    HistoryDialogBoxComponent,
    ViewUserDialogBoxComponent,
    LockoutDialogBoxComponent,
    StoriesCredentialsDialogBoxComponent
  ]
})
export class UserManagementModule { }
