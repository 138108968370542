import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, OnDestroy, Input, Renderer2, AfterViewChecked } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { PlugNPredictTelecomService } from './plug-n-predict-telecom.service';
import { D3CustomShapesService } from '../../services/helper/d3-custom-shapes.service';
import { SmallClusterService } from 'src/app/services/small-cluster.service';
import { D3EventTimelineService } from 'src/app/services/d3-event-timeline.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

declare const d3: any;

@Component({
    selector: 'app-plug-n-predict-telecom',
    templateUrl: './plug-n-predict-telecom.component.html',
    styleUrls: ['./plug-n-predict-telecom.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [PlugNPredictTelecomService, SmallClusterService]
})
export class PlugNPredictTelecomComponent implements OnInit, AfterViewChecked, OnDestroy {

    @Output() public onVisibleSectionChange = new EventEmitter<any>();
    @Input() widgetData;
    customerData: any = [];
    settings: any = {
        circleCluster: null
    };
    scrollTop;
    commonContainer: any = {
        svg: null,
        parentNodeId: "path-to-force-section", /* plug-n-predict-telecom-svg-container */
        margin: {
            top: 10,
            right: 40,
            bottom: 30,
            left: 30
        },
        width: 1500, //1280
        height: 4200,
        categories: [
            {
                type: "abandonCart",
                color: "#faa24c"
            },
            {
                type: "surgeCall",
                color: "#39b2ff"
            },
            {
                type: "portOut",
                color: "#a1be00"
            },
            {
                type: "churn",
                color: "#d94785"
            }
        ],
        lineGenerator: null,
        circleRadius: 8,
        shapes: ["hexagoan", "square", "triangleDown", "star"],
        aspectRatio: 0,
        textContainerDetails: [
            {
                to: 'data_id',
                from: 'second_path_3'
            }
        ],
        scrollLength: null,
        renderStory: null,
        windowRequestFrameId: null
    };
    sectionsHolder: any = {
        proble: null,
        problemToData: {
            firstLinePath: null,
            smallCluster: {
                details: [],
                flag: [],
                updateCluster: Function
            },
            secondLinePath: null
        },
        dataToSolution: {
            thirdLinePath: null,
            timeline: {
                details: [],
                flag: [],
                updateTimeline: Function
            }
        },
        solution: {
            details: [],
            flag: [],
            updateDna: null
        },
        solutionToOutcome: null,
        outcome: {
            dotWall: null,
            barChart: null
        }
    };

    windowListner;
    private _lastHeight: number;

    constructor(
        private router: Router,
        private plugNPredictService: PlugNPredictTelecomService,
        private _D3CustomShapesService: D3CustomShapesService,
        private smallClusterService: SmallClusterService,
        private timelineService: D3EventTimelineService,
        private _gaService: GoogleAnalyticsService,
        private renderer: Renderer2) { }

    ngAfterViewChecked(): void {
        const scrollWindowHeight = document.getElementById('scroll-container').offsetHeight;
        if (this._lastHeight !== scrollWindowHeight) {
            this._lastHeight = scrollWindowHeight;
            setTimeout(() => {
                this.changeWindowDetails();
            }, 500);
        }
    }
    ngAfterViewInit() {
        //Initialize Dna Arc Path and PathScale

        this.sectionsHolder.solutionToOutcome.updateRainData();

        this.commonContainer.svg
            .call(this.responsivefy.bind(this));

        this.windowListner = this.renderer.listen(window, 'resize', _.debounce(this.changeWindowDetails.bind(this), 700));
    }

    initializeDnaPath(start, end) {
        let tempObject = null, path = { dna: [] }, pathScales = { dna: [] }, domain = null;

        //Get Dna Path
        for (let index = 0; index < 4; index++) {
            tempObject = {
                "left": this.commonContainer.svg.select("#dna-arc-left-" + index),
                "right": this.commonContainer.svg.select("#dna-arc-right-" + index)
            }
            path.dna.push(tempObject);
        }

        //Function to set pathScale
        let setPathScale = (path, domain) => {
            return d3.scale.linear()
                .domain([domain.start, domain.end])
                .range([0, path.node().getTotalLength()])
                .clamp(true);
        }

        //Set Scale Path
        let leftPath = null, rightPath = null;
        tempObject = null;
        domain = {
            start,
            end,
        };
        for (let index = 0; index < 4; index++) {
            leftPath = path.dna[index].left;
            rightPath = path.dna[index].left;
            tempObject = {
                left: setPathScale(leftPath, domain),
                right: setPathScale(rightPath, domain)
            };
            pathScales.dna.push(tempObject);
        }

        return {
            path, pathScales
        }
    }

    onScrollSecionChange($event) {
        this.onVisibleSectionChange.emit($event);
    }


    ngOnInit() {
        this.createSVGContainer();
        this.initializeLineScales();
        this.sectionsHolder.problem = this.initializeCircleCluster();
        this.sectionsHolder.problemToData.firstLinePath = this.initializeProblemToDataFirstLinePaths();
        this.sectionsHolder.problemToData.secondLinePath = this.initializeProblemToDataSecondLinePaths();
        this.sectionsHolder.problemToData.smallCluster.updateCluster = this.renderproblemSmallCluster();
        this.sectionsHolder.dataToSolution.timeline.updateTimeline = this.renderTimeline();
        this.sectionsHolder.dataToSolution.thirdLinePath = this.initializeProblemToDataThirdLinePaths();
        this.sectionsHolder.solution.updateDna = this.renderDna();
        this.sectionsHolder.solutionToOutcome = this.renderRainBlock();
        this.sectionsHolder.outcome.dotWall = this.outcomeDotWall();
        this.sectionsHolder.outcome.barChart = this.renderBarChart();
        this.sectionsHolder.renderStory = this.renderStory();
    }

    private initializeCircleCluster() {
        //Initialize data for cluster of circles with count 250
        this.plugNPredictService.setCustomerData(250);
        //Get data object
        this.customerData = this.plugNPredictService.getCustomerData();

        this.settings.circleCluster = {
            width: 500,
            height: 500,
            charge: -15,
            circleRadius: 8,
            circleColor: "#aaafb5",
            id: 'circles-cluster',
            parentSvg: this.commonContainer.svg,
            transformX: 1000,
            transformY: 0
        }

        let toggleBigCircleOpacity = (beforeTransition, afterTransition) => {
            d3.select("#" + this.settings.circleCluster.id + "-svg")
                .selectAll("circle")
                .attr("fill-opacity", beforeTransition)
                .attr("stroke-opacity", beforeTransition)
                .transition()
                .ease("ease-out")
                .duration(function (d, i) {
                    return 5 * i;
                })
                .attr("fill-opacity", afterTransition)
                .attr("stroke-opacity", afterTransition)
        }

        return {
            toggleBigCircleOpacity
        }
    }

    onScrollSectionChange($event) {
        this.onVisibleSectionChange.emit($event);
    }

    createSVGContainer() {
        this.commonContainer.svg = d3.select("#" + this.commonContainer.parentNodeId).append("svg")
            .attr("id", "path-to-force-section-cluster")
            .attr("width", this.commonContainer.width)
            .attr("height", this.commonContainer.height)
        // .call(this.responsivefy);
    }

    initializeLineScales() {
        let widthScale = (this.commonContainer.width - this.commonContainer.margin.left - this.commonContainer.margin.right);
        this.commonContainer.xScale = d3.scale.linear().domain([0, widthScale - 50]).range([widthScale - 50, 0]);
        this.commonContainer.yScale = d3.scale.linear().domain([0, 550]).range([0, 550]);

        this.commonContainer.lineGenerator = d3.svg.line()
            .x((d, i) => {
                return this.commonContainer.xScale(d.xCoordinate);
            })
            .y((d) => {
                return this.commonContainer.yScale(d.yCoordinate);
            });
    }

    private initializeProblemToDataFirstLinePaths() {

        let abanDonCartLinePoints = [{
            xCoordinate: 120,
            yCoordinate: 460
        }, {
            xCoordinate: 120,
            yCoordinate: 600
        }, {
            xCoordinate: 500,
            yCoordinate: 600
        },];

        let surgeCallLinePoints = [{
            xCoordinate: 120,
            yCoordinate: 460
        }, {
            xCoordinate: 120,
            yCoordinate: 750
        }, {
            xCoordinate: 400,
            yCoordinate: 750
        },];

        let portOutLinePoints = [{
            xCoordinate: 120,
            yCoordinate: 460
        }, {
            xCoordinate: 120,
            yCoordinate: 800
        }, {
            xCoordinate: 300,
            yCoordinate: 800
        }, {
            xCoordinate: 300,
            yCoordinate: 850
        },

        ];

        let churnLinePoints = [{
            xCoordinate: 120,
            yCoordinate: 460
        }, {
            xCoordinate: 120,
            yCoordinate: 800
        }, {
            xCoordinate: 10,
            yCoordinate: 800
        }, {
            xCoordinate: 10,
            yCoordinate: 850
        },

        ];

        let mainLinePoints = [{
            xCoordinate: 120,
            yCoordinate: 460
        }, {
            xCoordinate: 120,
            yCoordinate: 800
        }];

        let firstLinePath = [];

        let firstLinePoints = [
            abanDonCartLinePoints,
            surgeCallLinePoints,
            portOutLinePoints,
            churnLinePoints
        ]

        let firstLinePathScales = [];

        let drawFirstLinePath = () => {

            for (let index = 0; index < 4; index++) {
                firstLinePath[index] = this.drawLinePath(firstLinePoints[index], {
                    strokeColor: this.commonContainer.categories[index].color,
                    strokeWidth: 1,
                    id: "first_path_" + index
                });
                firstLinePathScales[index] = d3.scale.linear()
                    .domain([310, 200])//SCROLL_LENGTH - 2200
                    .range([firstLinePath[index].node().getTotalLength(), 0])
                    .clamp(true);
            }
            firstLinePath[4] = this.drawLinePath(mainLinePoints, {
                strokeColor: "#e1e4e6",
                strokeWidth: 2,
                id: "first_path_" + 4
            });


            firstLinePathScales[4] = d3.scale.linear()
                .domain([210, 200])//([SCROLL_LENGTH - 2300])
                .range([firstLinePath[4].node().getTotalLength(), 0])
                .clamp(true);

            // initializePathToForce();
        }


        let updateFirstLinePathLength = (path, index, scrollTop) => {
            path
                .style('stroke-dashoffset', (d) => {
                    if (firstLinePathScales[index](scrollTop) > 0) {
                        d3.select("#path1_context_1").classed('in', true);
                    } else {
                        d3.select("#path1_context_1").classed('in', false);
                    }

                    if (firstLinePathScales[index](scrollTop) > 200) {
                        d3.select("#path1_context_2").classed('in', true);
                    } else {
                        d3.select("#path1_context_2").classed('in', false);
                    }

                    movingCirclesOnPath.updateFirstLineShapesAnimation(index, scrollTop);
                    // console.log("!this.sectionsHolder.problem.toggleedOpacity && firstLinePathScales[index](scrollTop)", !this.sectionsHolder.problem.toggleedOpacity, firstLinePathScales[index](scrollTop));
                    if (!this.sectionsHolder.problem.toggleedOpacity && firstLinePathScales[index](scrollTop) > 0) {
                        this.sectionsHolder.problem.toggleedOpacity = true;
                        this.sectionsHolder.problem.toggleBigCircleOpacity(1, 0);
                    } else if (firstLinePathScales[index](scrollTop) == 0 && this.sectionsHolder.problem.toggleedOpacity) {
                        this.sectionsHolder.problem.toggleedOpacity = false;
                        this.sectionsHolder.problem.toggleBigCircleOpacity(0, 1);
                    }

                    return path.node().getTotalLength() - firstLinePathScales[index](scrollTop) + 'px';
                });


        }
        drawFirstLinePath();

        let movingCirclesOnPath = (() => {
            let movingCircles = [];
            let categoriesLength = this.commonContainer.categories.length;
            for (var index = 0; index < 25; index++) {
                movingCircles.push({
                    id: index + 1,
                    type: this.commonContainer.categories[(index % categoriesLength)].type,
                })
            }

            let drawMovingCircles = () => {
                this.commonContainer.svg
                    .append("g")
                    .selectAll("circle")
                    .data(movingCircles)
                    .enter()
                    .append("circle")
                    .attr("class", "first-line-moving-circle")
                    .attr("cx", this.commonContainer.xScale(100)) //Starting x
                    .attr("cy", this.commonContainer.yScale(12)) //Starting y
                    .attr("r", this.commonContainer.circleRadius)
                    .attr("id", function (d, i) {
                        return "id_circle_" + d.id;
                    })
                    .attr('fill', "none");
            };
            drawMovingCircles();

            var firstPathFlag = [0, 0, 0, 0], firstFlag = false;;
            let firstLineShapesTween = (index, i, d, scrollTop) => {
                let linePath = firstLinePath[index];
                let length = linePath.node().getTotalLength(); // Get the length of the path            
                let r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
                var diffFactorForEachShapes = (firstLinePathScales[index](scrollTop) == length) ? 0 : (i * 10);
                var divideAdjFactor = length;
                var point = linePath.node().getPointAtLength(r(((firstLinePathScales[index](scrollTop) - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path
                this.commonContainer.svg.select("#id_circle_" + d.id)
                    .attr("cx", point.x)
                    .attr("cy", point.y)
                    .attr("fill", this.commonContainer.categories[index].color)
                    .attr("fill-opacity", firstLinePathScales[index](scrollTop) < 10 ? 0 : 1);

                if (!firstPathFlag[index] && firstLinePathScales[index](scrollTop) == length) {
                    this.sectionsHolder.problemToData.smallCluster.updateCluster.updateOpacityOfAllCircles(index, 1);
                    this.sectionsHolder.problemToData.smallCluster.updateCluster.updateSmallClusterForce(index);
                    firstPathFlag[index] = 1;
                } else if (firstPathFlag[index] && firstLinePathScales[index](scrollTop) < length) {
                    this.sectionsHolder.problemToData.smallCluster.updateCluster.updateOpacityOfAllCircles(index, 0);
                    firstPathFlag[index] = 0;
                }
            }

            let updateFirstLineShapesAnimation = (index, scrollTop) => {
                if (index > 3) {
                    return;
                }
                let movingCircles = this.commonContainer.svg.selectAll(".first-line-moving-circle");
                movingCircles
                    .attr("fill-opacity", 1)
                    .each(function (d, i) {
                        if (d.type == "abandonCart") {
                            firstLineShapesTween(0, i, d, scrollTop);
                        } else if (d.type == "surgeCall") {
                            firstLineShapesTween(1, i, d, scrollTop);
                        } else if (d.type == "portOut") {
                            firstLineShapesTween(2, i, d, scrollTop);
                        } else if (d.type == "churn") {
                            firstLineShapesTween(3, i, d, scrollTop);
                        }
                    })
            }

            return {
                updateFirstLineShapesAnimation
            }

        })();

        let animateFirstLinePath = (scrollTop) => {
            for (let i = 0; i < 4; i++) {
                updateFirstLinePathLength(firstLinePath[i], i, scrollTop)
            }
            updateFirstLinePathLength(firstLinePath[4], 4, scrollTop);
        }

        let updateFirstPathScale = () => {
            const { scrollLength, aspectRatio } = this.commonContainer;
            for (let index = 0; index < 4; index++) {
                firstLinePathScales[index] = d3.scale.linear()
                    .domain([(scrollLength * 0.052) / aspectRatio, (scrollLength * 0.029) / aspectRatio])//SCROLL_LENGTH - 2200
                    .range([firstLinePath[index].node().getTotalLength(), 0])
                    .clamp(true);
            }

            firstLinePathScales[4] = d3.scale.linear()
                .domain([(scrollLength * 0.049) / aspectRatio, (scrollLength * 0.029) / aspectRatio])//([SCROLL_LENGTH - 2300])
                .range([firstLinePath[4].node().getTotalLength(), 0])
                .clamp(true);
        }

        return {
            animateFirstLinePath,
            updateFirstPathScale
        }

    }

    private initializeProblemToDataSecondLinePaths() {
        //Creating Next Set of Lines (2)
        let abanDonCartLinePoints_Line2 = [{
            xCoordinate: 700,
            yCoordinate: 600
        }, {
            xCoordinate: 1310,
            yCoordinate: 600
        }, {
            xCoordinate: 1310,
            yCoordinate: 1750
        }, {
            xCoordinate: 1050,
            yCoordinate: 1750
        },]

        let surgeCallLinePoints_Line2 = [{
            xCoordinate: 580,
            yCoordinate: 800
        }, {
            xCoordinate: 1250,
            yCoordinate: 800
        }, {
            xCoordinate: 1250,
            yCoordinate: 1650
        }, {
            xCoordinate: 1000,
            yCoordinate: 1650
        },];

        let portOutLinePoints_Line2 = [{
            xCoordinate: 400,
            yCoordinate: 950
        }, {
            xCoordinate: 1190,
            yCoordinate: 950
        }, {
            xCoordinate: 1190,
            yCoordinate: 1550
        }, {
            xCoordinate: 950,
            yCoordinate: 1550
        },];

        let churnLinePoints_Line2 = [{
            xCoordinate: 10,
            yCoordinate: 1050
        }, {
            xCoordinate: 10,
            yCoordinate: 1080
        }, {
            xCoordinate: 1130,
            yCoordinate: 1080
        }, {
            xCoordinate: 1130,
            yCoordinate: 1450
        }, {
            xCoordinate: 900,
            yCoordinate: 1450
        }];


        let secondLineCoordinates = [
            abanDonCartLinePoints_Line2,
            surgeCallLinePoints_Line2,
            portOutLinePoints_Line2,
            churnLinePoints_Line2
        ]

        let secondLineAxis: any = [
            abanDonCartLinePoints_Line2[0],
            surgeCallLinePoints_Line2[0],
            portOutLinePoints_Line2[0],
            churnLinePoints_Line2[0]
        ]

        let secondLineTextAxis = [{
            x: 460,
            y: 590
        }, {
            x: 640,
            y: 790
        }, {
            x: 860,
            y: 940
        }, {
            x: 1270,
            y: 1070
        }]
        let secondLinePathScales = [];
        let secondLinePath = [];
        let secondLineObject = [];
        let secondLineText = ["Abandon Cart", "Surge Call", "Port Out", "Churn"];

        let displayCategoriesName = (index) => {
            this.commonContainer.svg.select("#second_path_" + index).append("text")
                .attr("x", secondLineTextAxis[index].x)
                .attr("y", secondLineTextAxis[index].y)
                .attr("class", "type-text-section")
                .attr("fill", this.commonContainer.categories[index].color)
                .text(secondLineText[index])
                .attr("fill-opacity", 0);
        }
        let movingCircles = (() => {
            let createMovingCircle = (index, startingCoordinates) => {
                this.commonContainer.svg.select("#second_path_" + index)
                    .selectAll("circle")
                    .data([
                        {
                            id: index,
                            type: this.commonContainer.categories[index].type
                        }
                    ])
                    .enter()
                    .append("circle")
                    .attr("cx", this.commonContainer.xScale(startingCoordinates.xCoordinate)) //Starting x
                    .attr("cy", this.commonContainer.yScale(startingCoordinates.yCoordinate)) //Starting y
                    .attr("r", this.commonContainer.circleRadius)
                    .attr('fill', this.commonContainer.categories[index].color)
                    .attr("opacity", 0)
            }

            let secondPathFlag = [0, 0, 0, 0];
            let secondLinePathTween = (linePath, index, i, scrollTop) => {
                let length = linePath.node().getTotalLength(); // Get the length of the path
                let r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
                let divideAdjFactor = (length - secondLinePathScales[index](scrollTop)) < 100 ? length : length + 100;
                let point = linePath.node().getPointAtLength(r((secondLinePathScales[index](scrollTop)) / divideAdjFactor)); // Get the next point along the path
                this.commonContainer.svg.select("#second_path_" + index)
                    .selectAll("circle") // Select the circle
                    .attr("cx", point.x) // Set the cx
                    .attr("cy", point.y)
                    .attr("opacity", 1) // Set the cy
            }

            let updateSecondCircleAnimation = (index, scrollTop) => {
                var movingCircles = this.commonContainer.svg.select("#second_path_" + index).selectAll("circle");
                movingCircles
                    .attr("fill-opacity", 1)
                    .attr("d", function (d, i) {
                        secondLinePathTween(secondLinePath[index], index, i, scrollTop);
                    });
            }

            return {
                createMovingCircle,
                updateSecondCircleAnimation
            }
        })();


        let drawSecondLinePath = () => {
            for (let index = 0; index < 4; index++) {
                secondLinePath[index] = this.drawLinePath(secondLineCoordinates[index], {
                    strokeColor: this.commonContainer.categories[index].color,
                    strokeWidth: 1,
                    id: "second_path_" + index
                });
                secondLinePathScales[index] = d3.scale.linear()
                    .domain([1260, 350])//SCROLL_LENGTH - 1250
                    .range([secondLinePath[index].node().getTotalLength(), 0])
                    .clamp(true);
                displayCategoriesName(index);
                movingCircles.createMovingCircle(index, secondLineCoordinates[index][0]);
            }

        }
        let toggleCategoriesName = (index, opacity) => {
            this.commonContainer.svg.select("#second_path_" + index).selectAll('text')
                .attr("fill-opacity", opacity);
        }
        let hideTextAndCirclies = (index) => {
            let circles = this.commonContainer.svg.select("#second_path_" + index).selectAll("circle");
            circles.attr("fill-opacity", 0)
            toggleCategoriesName(index, 0)
        }


        let updateSecondLinePathLength = (path, index, scrollTop) => {
            path
                .style('stroke-dashoffset', function (d) {
                    movingCircles.updateSecondCircleAnimation(index, scrollTop);
                    if (secondLinePathScales[index](scrollTop) <= 30) {
                        hideTextAndCirclies(index);
                    } else if (secondLinePathScales[index](scrollTop) >= 30) {
                        toggleCategoriesName(index, 1);
                    }
                    return path.node().getTotalLength() - secondLinePathScales[index](scrollTop) + 'px';
                });
        }

        let animateSecondLinePath = (scrollTop) => {
            for (let secondIndex = 0; secondIndex < 4; secondIndex++) {
                updateSecondLinePathLength(secondLinePath[secondIndex], secondIndex, scrollTop);
            }

        }
        drawSecondLinePath();

        let updateSecondPathScale = () => {
            const { scrollLength, aspectRatio } = this.commonContainer;
            for (let index = 0; index < 4; index++) {
                secondLinePathScales[index] = d3.scale.linear()
                    .domain([(scrollLength * 0.155) / aspectRatio, (scrollLength * 0.052) / aspectRatio])//SCROLL_LENGTH - 1250
                    .range([secondLinePath[index].node().getTotalLength(), 0])
                    .clamp(true);
            }
        }

        return {
            animateSecondLinePath,
            updateSecondPathScale
        }

    }

    private initializeProblemToDataThirdLinePaths() {
        let thirdLineData = [];
        let thirdLinePath = []
        let thirdLinePathScales = [];

        for (let lineIndex = 0; lineIndex < 4; lineIndex++) {
            thirdLineData[lineIndex] = [];
            for (let index = 0; index < 3; index++) {
                thirdLineData[lineIndex].push({
                    id: index,
                    type: this.commonContainer.categories[index].type
                });
            }
        }

        //Creating Next Set of Lines (2)
        let abanDonCartLinePoints_Line3 = [
            {
                xCoordinate: 282,
                yCoordinate: 1750
            }, {
                xCoordinate: 130,
                yCoordinate: 1750
            }, {
                xCoordinate: 130,
                yCoordinate: 1800
            }, {
                xCoordinate: 555,
                yCoordinate: 1800
            }, {
                xCoordinate: 555,
                yCoordinate: 2000
            },]

        let surgeCallLinePoints_Line3 = [{
            xCoordinate: 232,
            yCoordinate: 1650
        }, {
            xCoordinate: 70,
            yCoordinate: 1650
        }, {
            xCoordinate: 70,
            yCoordinate: 1850
        }, {
            xCoordinate: 375,
            yCoordinate: 1850
        }, {
            xCoordinate: 375,
            yCoordinate: 2000
        },];

        let portOutLinePoints_Line3 = [{
            xCoordinate: 182,
            yCoordinate: 1550
        }, {
            xCoordinate: 10,
            yCoordinate: 1550
        }, {
            xCoordinate: 10,
            yCoordinate: 1900
        }, {
            xCoordinate: 195,
            yCoordinate: 1900
        }, {
            xCoordinate: 195,
            yCoordinate: 2000
        },];

        let churnLinePoints_Line3 = [{
            xCoordinate: 132,
            yCoordinate: 1450
        }, {
            xCoordinate: -50,
            yCoordinate: 1450
        }, {
            xCoordinate: -50,
            yCoordinate: 1950
        }, {
            xCoordinate: 15,
            yCoordinate: 1950
        }, {
            xCoordinate: 15,
            yCoordinate: 2000
        },];

        let thirdLineCoordinates = [
            abanDonCartLinePoints_Line3,
            surgeCallLinePoints_Line3,
            portOutLinePoints_Line3,
            churnLinePoints_Line3
        ];

        let line3_EndPoint = [
            abanDonCartLinePoints_Line3[abanDonCartLinePoints_Line3.length - 1],
            surgeCallLinePoints_Line3[surgeCallLinePoints_Line3.length - 1],
            portOutLinePoints_Line3[portOutLinePoints_Line3.length - 1],
            churnLinePoints_Line3[churnLinePoints_Line3.length - 1]
        ]

        let movingShapes = (() => {
            let totalShapes = this.commonContainer.shapes.length;
            let createShapes = (index, startingCoordinates) => {
                this.commonContainer.svg.select("#third_path_" + index).selectAll(".third-line-shapes")
                    .data(thirdLineData[index])
                    .enter()
                    .append("path")
                    .attr("class", "third-line-shapes")
                    .attr("id", function (d, i) {
                        return "third-line-shapes_" + index + "_" + i;
                    })
                    .attr("d", (d) => {
                        return this._D3CustomShapesService.getShapeCoordinates([this.commonContainer.shapes[Math.floor(Math.random() * totalShapes)]], (60 * 2))
                    })
                    .attr("transform", "translate(" + this.commonContainer.xScale(startingCoordinates.xCoordinate) + ", " + this.commonContainer.yScale(startingCoordinates.yCoordinate) + ")")
                    .style('fill', this.commonContainer.categories[index].color)
                    .style('stroke', "none")
                    .attr("opacity", 0);
            }

            let updateThirdCircleAnimation = (index, scrollTop) => {
                // thirdLinePathTween(thirdLinePath[index], index)();
                let movingShapes = this.commonContainer.svg.select("#third_path_" + index).selectAll(".third-line-shapes");
                movingShapes
                    .each(function (d, i) {
                        thirdLinePathTween(thirdLinePath[index], index, i, scrollTop);
                    })
            }

            let thirdLinePathTween = (linePath, index, i, scrollTop) => {
                let length = linePath.node().getTotalLength(); // Get the length of the path            
                let r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
                let movedLineLength = thirdLinePathScales[index](scrollTop);
                let diffFactorForEachShapes = (movedLineLength == length) ? 0 : (i * 200);
                let divideAdjFactor = (length - (movedLineLength - diffFactorForEachShapes)) < 100 ? length : length + 100;

                // console.log("(movedLineLength)", (movedLineLength), "index", index);
                let point = linePath.node().getPointAtLength(r(((movedLineLength - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path

                this.commonContainer.svg.select("#third_path_" + index).select("#third-line-shapes_" + index + "_" + i)
                    .attr("transform", "translate(" + point.x + ", " + point.y + ")")
                    .attr("opacity", 1);
            }

            let hideThirdPathShapes = (index) => {
                var movingShapes = this.commonContainer.svg.select("#third_path_" + index).selectAll(".third-line-shapes");
                movingShapes
                    .attr("opacity", 0);
            }

            return {
                createShapes,
                updateThirdCircleAnimation,
                hideThirdPathShapes
            }
        })();

        let drawThirdLinePath = () => {
            for (let index = 0; index < 4; index++) {
                thirdLinePath[index] = this.drawLinePath(thirdLineCoordinates[index], {
                    strokeColor: this.commonContainer.categories[index].color,
                    strokeWidth: 1,
                    id: "third_path_" + index
                });
                thirdLinePathScales[index] = d3.scale.linear()
                    .domain([1510, 1350])// SCROLL_LENGTH - 1000
                    .range([thirdLinePath[index].node().getTotalLength(), 0])
                    .clamp(true);

                movingShapes.createShapes(index, thirdLineCoordinates[index][0]);
            }
        }

        let updateThirdLinePathLength = (path, index, scrollTop) => {
            path
                .style('stroke-dashoffset', function (d) {
                    movingShapes.updateThirdCircleAnimation(index, scrollTop);
                    if (thirdLinePathScales[index](scrollTop) <= 30) {
                        movingShapes.hideThirdPathShapes(index);
                    }
                    return path.node().getTotalLength() - thirdLinePathScales[index](scrollTop) + 'px';
                });
        }

        let animateThirdLinePath = (scrollTop) => {
            for (let secondIndex = 0; secondIndex < 4; secondIndex++) {
                updateThirdLinePathLength(thirdLinePath[secondIndex], secondIndex, scrollTop);
            }

        }

        drawThirdLinePath();

        const getLine3EndPoints = () => { return line3_EndPoint; }

        let updateThirdPathScale = () => {
            const { scrollLength, aspectRatio } = this.commonContainer;
            for (let index = 0; index < 4; index++) {
                thirdLinePathScales[index] = d3.scale.linear()
                    .domain([(scrollLength * 0.205) / aspectRatio, (scrollLength * 0.168) / aspectRatio])// SCROLL_LENGTH - 1000
                    .range([thirdLinePath[index].node().getTotalLength(), 0])
                    .clamp(true);
            }
        }

        return {
            animateThirdLinePath,
            getLine3EndPoints,
            updateThirdPathScale
        }
    }

    private outcomeDotWall() {
        let wallFlags = [0, 0, 0, 0]

        let wallWidth = 300;
        let wallHeight = 150;

        let wallData = [17, 20, 23, 26];
        let wallSvg = [];
        let wallText = ["Abandon Cart", "Churn", "Port Out", "Surge Call"];
        let wallColor = ["#faa24c", "#d94785", "#a1be00", "#39b2ff"];
        let wallTextColor = ["#faa24c", "#d94785", "#a1be00", "#39b2ff"];
        let polygonText = [
            "Price Sensitive & Millennials & Urban",
            "High Social Media Usage & Suburndan & Low Call Usage",
            "Large Families & Live in NY & Income < $50k",
            "New Customers & New Contract & New Device"
        ];

        this.commonContainer.svg.attr({
            "height": parseInt(this.commonContainer.svg.style("height").replace("px", "")) + 1000 + 30
        });

        let translateValue = this.sectionsHolder.dataToSolution.thirdLinePath.getLine3EndPoints();

        let createDotWall = (index, flag) => {
            fourthLineObject[index] = generateFourthObject(index);
            let heightOfDNA = 650;
            let heightOfRain = 200;
            let translateWallX = (index == 0) || (index == 1) ? this.commonContainer.xScale(translateValue[0].xCoordinate) - (220 / 2) : this.commonContainer.xScale(translateValue[0].xCoordinate) + wallWidth + 150 - (220 / 2)
            let translateWallY = (index == 0) || (index == 2) ? (translateValue[0].yCoordinate + heightOfDNA + heightOfRain - 30) : (translateValue[0].yCoordinate + heightOfDNA + heightOfRain - 30) + wallHeight;

            wallSvg[index] = this.commonContainer.svg
                .append("g")
                .attr({
                    "width": wallWidth,
                    "height": wallHeight,
                    "id": "dot-wall_" + index,
                    "transform": "translate(" + (translateWallX) + ", " + translateWallY + ")"
                });

            wallSvg[index]
                .append('g')
                .attr('id', 'dot-wall-path-group-' + index);

            wallSvg[index].append("text")
                .text(wallText[index])
                .attr({
                    "x": flag ? -10 : 220,
                    "y": 10,
                    "fill": wallTextColor[index],
                    "width": wallWidth - 10,
                    "transform": "translate(10,10)"
                })
                .style({
                    "letter-spacing": 0.6,
                    "font-size": 14
                })

            wallData.forEach((v, k) => {
                let g = wallSvg[index].append('g')
                    .attr("transform", function (d, i) {
                        return "translate(10,40)";
                    });
                //var yPosition=(i*10)+10;
                var yPosition = k;
                g.selectAll("circle")
                    .data(d3.range(v))
                    .enter()
                    .append("circle")
                    .attr("class", function (d, i) {
                        return "dot-ball_" + index + "_" + k;
                    })
                    .classed("dot-ball_" + index, true)
                    .classed("dot-ball", true)
                    .attr("id", function (d, i) {
                        return "dot-ball_" + index + "_" + k + "_" + i;
                    })
                    .attr("cx", function (d, i) {
                        if (yPosition % 2 == 0) {
                            if (!flag) {
                                if (k == 0) {
                                    return (i + 10.5) * 10.5;
                                }
                                return (i + 4.5) * 10.5;
                            }
                            return i * 10.5;
                        } else {
                            if (!flag) {
                                if (k === 1) {
                                    return (i + 8.5) * 10.5 - 5;
                                }
                                return (i + 2.5) * 10.5 - 5;
                            }
                        }
                        return (i) * 10.5 - 5;
                    })
                    .attr("cy", function (d, i) {
                        return yPosition * 10;
                    })
                    .attr("r", function (d, i) {
                        return 5;
                    })
                    .attr("fill", wallColor[index])
                    .attr("fill-opacity", 0)
            });

            var g = wallSvg[index].append("g")
                .attr({
                    "id": "polygon_" + index
                });

            g.append("polygon")
                .attr({
                    "points": flag ? "0,0 270,0 300,10 300,50 0,50" : "0,10, 30,0 300,0 300,50 0,50 0,0",
                    "fill": "#e8ecef",
                    "transform": "translate(0,75)"
                })

            g.append("text")
                .attr("x", wallWidth / 2 + 5)
                .attr("y", wallHeight / 1.55)
                .attr("text-anchor", "middle")
                .attr("fill", "#616568")
                .text(polygonText[index])
                .attr({
                    "width": wallWidth - 10,
                    "id": "wrapme_" + index
                })
                .style({
                    "font-size": 14
                })

            function wrap(text) {
                text.each(function () {
                    var text = d3.select(this);
                    var words = text.text().split(/\s+/).reverse();
                    var lineHeight = 20;
                    var width = parseFloat(text.attr('width'));
                    var y = parseFloat(text.attr('y'));
                    var x = text.attr('x');
                    var anchor = text.attr('text-anchor');

                    var tspan = text.text(null).append('tspan').attr('x', x).attr('y', y);
                    var lineNumber = 0;
                    var line = [];
                    var word = words.pop();

                    while (word) {
                        line.push(word);
                        tspan.text(line.join(' '));
                        if (tspan.node().getComputedTextLength() > width) {
                            lineNumber += 1;
                            line.pop();
                            tspan.text(line.join(' '));
                            line = [word];
                            tspan = text.append('tspan').attr('x', x).attr('y', y + lineNumber * lineHeight).text(word);
                        }
                        word = words.pop();
                    }
                });
            }
            d3.selectAll('#wrapme_' + index).call(wrap);
        }

        var dotWidth = 700,
            dotHeight = 860;

        var dotSvg = d3.select("#path-to-outcome-section-2").append("svg")
            .attr("id", "path-to-outcome-block-2")
            .attr("width", dotWidth)
            .attr("height", dotHeight)
            .style("position", "absolute");



        //Creating Next Set of Lines 4
        var abanDonCartLinePoints_Line4_0 = [{
            xCoordinate: 1370,
            yCoordinate: 70
        }, {
            xCoordinate: 1100,
            yCoordinate: 70
        }, {
            xCoordinate: 1050,
            yCoordinate: 87
        }, {
            xCoordinate: 1050,
            yCoordinate: 280
        }, {
            xCoordinate: 1060,
            yCoordinate: 330
        }, {
            xCoordinate: 1035,
            yCoordinate: 430
        }, {
            xCoordinate: 1060,
            yCoordinate: 480
        }, {
            xCoordinate: 1120,
            yCoordinate: 580
        }, {
            xCoordinate: 1100,
            yCoordinate: 620
        }, {
            xCoordinate: 1180,
            yCoordinate: 680
        }, {
            xCoordinate: 1230,
            yCoordinate: 780
        }, {
            xCoordinate: 1220,
            yCoordinate: 860
        },]

        var surgeCallLinePoints_Line4_0 = [{
            xCoordinate: 1090,
            yCoordinate: 69
        }, {
            xCoordinate: 1370,
            yCoordinate: 69
        }, {
            xCoordinate: 1400,
            yCoordinate: 81
        }, {
            xCoordinate: 1390,
            yCoordinate: 255
        }, {
            xCoordinate: 1350,
            yCoordinate: 355
        }, {
            xCoordinate: 1330,
            yCoordinate: 455
        }, {
            xCoordinate: 1280,
            yCoordinate: 525
        }, {
            xCoordinate: 1270,
            yCoordinate: 605
        }, {
            xCoordinate: 1245,
            yCoordinate: 675
        }, {
            xCoordinate: 1220,
            yCoordinate: 685
        }, {
            xCoordinate: 1210,
            yCoordinate: 695
        }, {
            xCoordinate: 1210,
            yCoordinate: 700
        }];

        var portOutLinePoints_Line4_0 = [{
            xCoordinate: 1090,
            yCoordinate: 70
        }, {
            xCoordinate: 1370,
            yCoordinate: 70
        }, {
            xCoordinate: 1420,
            yCoordinate: 87
        }, {
            xCoordinate: 1410,
            yCoordinate: 167
        }, {
            xCoordinate: 1420,
            yCoordinate: 277
        }, {
            xCoordinate: 1440,
            yCoordinate: 327
        }, {
            xCoordinate: 1415,
            yCoordinate: 427
        }, {
            xCoordinate: 1438,
            yCoordinate: 477
        }, {
            xCoordinate: 1405,
            yCoordinate: 607
        }, {
            xCoordinate: 1335,
            yCoordinate: 707
        }, {
            xCoordinate: 1325,
            yCoordinate: 807
        }, {
            xCoordinate: 1325,
            yCoordinate: 860
        }];

        var churnLinePoints_Line4_0 = [{
            xCoordinate: 1370,
            yCoordinate: 69
        }, {
            xCoordinate: 1100,
            yCoordinate: 69
        }, {
            xCoordinate: 1070,
            yCoordinate: 81
        }, {
            xCoordinate: 1070,
            yCoordinate: 255
        }, {
            xCoordinate: 1060,
            yCoordinate: 355
        }, {
            xCoordinate: 1055,
            yCoordinate: 455
        }, {
            xCoordinate: 1070,
            yCoordinate: 525
        }, {
            xCoordinate: 1050,
            yCoordinate: 605
        }, {
            xCoordinate: 1065,
            yCoordinate: 700
        }];

        var fourthLineCoordinates = [
            [
                abanDonCartLinePoints_Line4_0,
                [],
                [],
                []
            ],
            [
                churnLinePoints_Line4_0,
                [],
                [],
                []
            ],
            [
                portOutLinePoints_Line4_0,
                [],
                [],
                []
            ],
            [
                surgeCallLinePoints_Line4_0,
                [],
                [],
                []
            ]
        ];
        for (let categoryIndex = 0; categoryIndex < fourthLineCoordinates.length; categoryIndex++) {
            for (let pathIndex = 1; pathIndex < 4; pathIndex++) {
                for (let coordinateIndex = 0; coordinateIndex < fourthLineCoordinates[categoryIndex][0].length; coordinateIndex++) {
                    fourthLineCoordinates[categoryIndex][pathIndex].push({
                        xCoordinate: fourthLineCoordinates[categoryIndex][0][coordinateIndex].xCoordinate,
                        yCoordinate: fourthLineCoordinates[categoryIndex][0][coordinateIndex].yCoordinate - (10 * pathIndex),
                    });
                }
            }
        }

        var fourthLineAxis: any = [
            [
                fourthLineCoordinates[0][0][0],
                fourthLineCoordinates[0][1][0],
                fourthLineCoordinates[0][2][0],
                fourthLineCoordinates[0][3][0]
            ],
            [
                fourthLineCoordinates[1][0][0],
                fourthLineCoordinates[1][1][0],
                fourthLineCoordinates[1][2][0],
                fourthLineCoordinates[1][3][0]
            ],
            [
                fourthLineCoordinates[2][0][0],
                fourthLineCoordinates[2][1][0],
                fourthLineCoordinates[2][2][0],
                fourthLineCoordinates[2][3][0]
            ],
            [
                fourthLineCoordinates[3][0][0],
                fourthLineCoordinates[3][1][0],
                fourthLineCoordinates[3][2][0],
                fourthLineCoordinates[3][3][0]
            ]
        ]

        var fourthLineEndPoints: any = [[], [], [], []];


        for (let categoryIndex = 0; categoryIndex < fourthLineCoordinates.length; categoryIndex++) {
            for (let pathIndex = 0; pathIndex < 4; pathIndex++) {
                fourthLineEndPoints[categoryIndex].push(
                    (fourthLineCoordinates[categoryIndex][pathIndex][fourthLineCoordinates[categoryIndex][pathIndex].length - 1].yCoordinate),
                );
            }
        }

        var fourthLinePath = [];

        //Creating Fourth Multi-Dimensional Path
        let drawfourthLinePath = (index, subIndex, durationOffset) => {
            fourthLinePath[index][subIndex] = this.commonContainer.svg.select("#dot-wall-path-group-" + index)
                .append("g")
                .attr("id", "dot_wall_path_group_" + index + "_" + subIndex)
                .append("path")
                .attr('d', this.commonContainer.lineGenerator(fourthLineCoordinates[index][subIndex]))
                .style("stroke", "none")
                .style("fill", "none");
            fourthLinePathScales[index][subIndex] = d3.scale.linear()
                .domain([2490, 2990]) //SCROLL_LENGTH - 250 //2270, 2600
                .range([fourthLinePath[index][subIndex].node().getTotalLength(), 0])
                .clamp(true);
        }
        var fourthDataFlag = [],
            fourthLinePathScales = [];

        var sums = 0;
        var barData = [];
        var parties = [];
        var raw = {
            "data": [{
                "name": "Abandon Cart",
                "left": "8x",
                "top": "4-5k",
                "value": 21
            }, {
                "name": "Churn",
                "left": "8x",
                "top": "15-18k",
                "value": 42
            }, {
                "name": "Port Out",
                "left": "10x",
                "top": "10-12k",
                "value": 63
            }, {
                "name": "Surge Call",
                "left": "10x",
                "top": "10-14k",
                "value": 84
            }

            ]
        };

        raw.data.forEach(element => {
            sums += (+element.value);
            barData.push(+element.value);
            parties.push(element.name)
        });


        let generateFourthObject = (index) => {

            let tempObject = [];
            let key = index;

            if (index == 1) {
                key = 3
            } else if (index == 3) {
                key = 1
            }

            for (let j = 0; j < 4; j++) {
                tempObject[j] = d3.range(dataSet[index][j]).map((d, k) => {
                    return {
                        id: k,
                        type: this.commonContainer.categories[key].type,
                    }
                });
            }

            return tempObject;
        }

        var tempObject = [];
        var dataSet = [
            [6, 6, 6, 3],
            [10, 10, 10, 12],
            [15, 15, 15, 18],
            [26, 23, 20, 15]
        ],
            checkEndReached = [],
            checkStartTraverse = [],
            checkReverseTraverse = [];

        for (let i = 0; i < 4; i++) {
            checkEndReached[i] = [];
            checkStartTraverse[i] = [];
            checkReverseTraverse[i] = [];
            for (let j = 0; j < 4; j++) {
                checkEndReached[i][j] = [];
                checkStartTraverse[i][j] = [];
                checkReverseTraverse[i][j] = [];
                for (let k = 0; k < dataSet[i][j]; k++) {
                    checkEndReached[i][j][k] = 0;
                    checkReverseTraverse[i][j][k] = 0;
                }
            }
        }

        var fourthLineObject = [];

        let movingShapes = (() => {
            let createShapes = (index, subIndex) => {
                this.commonContainer.svg.select("#dot_wall_path_group_" + + index + "_" + subIndex)
                    .selectAll(".dot_wall_moving_shapes")
                    .data(fourthLineObject[index][subIndex], function (d, i) {
                        return "dot_to_bar_" + index + "_" + subIndex + "_" + i;
                    })
                    .enter()
                    .append("circle")
                    .attr("class", "dot_wall_moving_shapes")
                    .attr("id", function (d, i) {
                        return "dot_to_bar_" + index + "_" + subIndex + "_" + i
                    })
                    .attr("r", 5)
                    .attr('fill', wallColor[index])
                    .attr("fill-opacity", 0);
            };

            let updateFourthLinePathShapes = (scrollTop) => {
                for (let index = 0; index < 4; index++) {
                    for (let subIndex = 0; subIndex < 4; subIndex++) {
                        for (let wallCircleIndex = 0; wallCircleIndex < dataSet[index][subIndex]; wallCircleIndex++) {
                            wallShapesPathTween(fourthLinePath[index][subIndex], index, subIndex, wallCircleIndex, scrollTop);
                        }
                    }
                }

                let outComesRebbons = d3.select('#outcome-rebbons');
                const { scrollLength, aspectRatio } = this.commonContainer;

                if (scrollTop > (scrollLength * 0.39) / aspectRatio) {
                    outComesRebbons.classed("in", true)
                } else {
                    outComesRebbons.classed("in", false)
                }
            }

            let wallShapesPathTween = (linePath, index, subIndex, i, scrollTop) => {
                let length = linePath.node().getTotalLength(); // Get the length of the path            
                let r = d3.interpolate(length, 0); //Set up interpolation from 0 to the path length

                var diffFactorForEachShapes = (fourthLinePathScales[index][subIndex](scrollTop) == length) ? 0 : ((i * subIndex * 10) + (index * Math.random()));

                var divideAdjFactor = (length - (fourthLinePathScales[index][subIndex](scrollTop) - diffFactorForEachShapes)) < 100 ? length : length + 100;

                checkStartTraverse[index][subIndex][i] = (length - (fourthLinePathScales[index][subIndex](scrollTop) - diffFactorForEachShapes)) < 100 ? 0 : 1;

                var point = linePath.node().getPointAtLength(r(((fourthLinePathScales[index][subIndex](scrollTop) - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path

                var dotToBarShape = wallSvg[index].select("#dot_to_bar_" + index + "_" + subIndex + "_" + i);
                var wallShape = wallSvg[index].select("#dot-ball_" + index + "_" + subIndex + "_" + i);

                //To manage the reverse traverse of shape in between of traversing
                if (checkStartTraverse[index][subIndex][i] && !checkReverseTraverse[index][subIndex][i]) {
                    wallShape
                        .classed("dot-ball_" + index + "_" + subIndex, false)
                        .attr("fill-opacity", 0);
                    checkReverseTraverse[index][subIndex][i] = 1;
                } else if (!checkStartTraverse[index][subIndex][i] && checkReverseTraverse[index][subIndex][i]) {
                    wallShape
                        .classed("dot-ball_" + index + "_" + subIndex, true)
                        .attr("fill-opacity", 1);
                    checkReverseTraverse[index][subIndex][i] = 0;
                }

                //To manage add or remove of shape to barChart
                if (point.y === fourthLineEndPoints[index][subIndex] && !checkEndReached[index][subIndex][i]) {
                    checkEndReached[index][subIndex][i] = 1;
                    this.sectionsHolder.outcome.barChart.createBarChartData(index);
                } else if (point.y < fourthLineEndPoints[index][subIndex] && checkEndReached[index][subIndex][i]) {
                    dotToBarShape
                        .attr("fill-opacity", 1)
                    checkEndReached[index][subIndex][i] = 0;
                    this.sectionsHolder.outcome.barChart.removeBarDot(index);
                }

                // To manage visibility of shapes
                if (fourthLinePathScales[index][subIndex](scrollTop) == length || point.y >= fourthLineEndPoints[index][subIndex]) {
                    dotToBarShape
                        .attr({
                            "fill-opacity": 0,
                            "transform": "translate(" + point.x + ", " + point.y + ")"
                        })
                } else if (fourthLinePathScales[index][subIndex](scrollTop) != length) {
                    dotToBarShape
                        .attr("fill-opacity", 1)
                        .attr("transform", "translate(" + point.x + ", " + point.y + ")")
                }
            }

            return {
                createShapes,
                updateFourthLinePathShapes,
            }

        })();

        for (let index = 0; index < 4; index++) {
            let flag = index < 2 ? true : false;
            createDotWall(index, flag);
        }

        function createFourthPaths() {
            for (let i = 0; i < 4; i++) {
                fourthDataFlag.push(wallData.slice().reverse());
            }

            for (let index = 0; index < 4; index++) {
                fourthLinePath[index] = [];
                fourthLinePathScales[index] = [];
                for (let subIndex = 0; subIndex < 4; subIndex++) {
                    drawfourthLinePath(index, subIndex, 0);
                    movingShapes.createShapes(index, subIndex);
                }
            }
        }

        createFourthPaths();

        let updateFourthPathScale = () => {
            const { scrollLength, aspectRatio } = this.commonContainer;
            for (let index = 0; index < 4; index++) {
                for (let subIndex = 0; subIndex < 4; subIndex++) {
                    fourthLinePathScales[index][subIndex] = d3.scale.linear()
                        .domain([(scrollLength * 0.337) / aspectRatio, (scrollLength * 0.405) / aspectRatio]) //SCROLL_LENGTH - 250 //2270, 2600
                        .range([fourthLinePath[index][subIndex].node().getTotalLength(), 0])
                        .clamp(true);
                }
            }
        }

        function getBarChartConfigDetails() {
            return {
                barData,
                sums,
                parties,
                raw
            }
        }

        return {
            getBarChartConfigDetails,
            animateDotWallShapes: movingShapes.updateFourthLinePathShapes,
            updateFourthPathScale
        }
    }

    drawLinePath(points, options) {
        return this.commonContainer.svg
            .append('g')
            .attr('id', options.id || 'temp_id')
            .append('path')
            .attr('d', this.commonContainer.lineGenerator(points))
            .style("stroke", options.strokeColor || "grey")
            .style("stroke-width", options.strokeWidth || 1)
            .style("fill", "none")
            .style('stroke-dasharray', function (d) {
                var l = d3.select(this).node().getTotalLength();
                return l + 'px, ' + l + 'px';
            })
            .style('stroke-dashoffset', function (d) {
                return d3.select(this).node().getTotalLength() + 'px';
            });
    }

    renderStory() {
        // this.renderer.listen(window, 'resize', _.debounce(this.changeWindowDetails.bind(this), 700));

        // Start:- Initialize Scroll Page Variables
        var HEIGHT = 0.9 * window.innerHeight;
        var mainContainer = d3.select('#scroll-container');
        // var content = d3.select('#content');
        // var SCROLL_LENGTH = content.node().getBoundingClientRect().height - HEIGHT;
        var scrollTop = 0;
        var newScrollTop = 0;
        this.scrollTop = newScrollTop;
        mainContainer.on("scroll.scroller", function () {
            newScrollTop = mainContainer.node().scrollTop;
        });

        var render = () => {
            if (scrollTop !== newScrollTop) {
                scrollTop = newScrollTop;
                this.sectionsHolder.problemToData.firstLinePath.animateFirstLinePath(scrollTop);

                this.sectionsHolder.problemToData.secondLinePath.animateSecondLinePath(scrollTop);

                this.sectionsHolder.dataToSolution.thirdLinePath.animateThirdLinePath(scrollTop);

                this.sectionsHolder.dataToSolution.timeline.updateTimeline.updateTimelineVisual(scrollTop);

                this.sectionsHolder.solution.updateDna.animateDnaLinePath(scrollTop);

                this.sectionsHolder.solutionToOutcome.animateRainDrop(scrollTop);

                this.sectionsHolder.outcome.dotWall.animateDotWallShapes(scrollTop);
            }
            if (this.commonContainer.windowRequestFrameId) {
                window.cancelAnimationFrame(this.commonContainer.windowRequestFrameId);
            }
            this.commonContainer.windowRequestFrameId = window.requestAnimationFrame(render);
        }

        // window.requestAnimationFrame(render)
        // End:- Initialize Scroll Page Variables

        return render;
    }

    ngOnDestroy() {
        d3.selectAll(".pnp-timeline-tooltip")
            .remove();

        this.windowListner();

        d3.select(window).on("resize." + d3.select(this.commonContainer.svg.node().parentNode).attr("id"), null);

        window.cancelAnimationFrame(this.commonContainer.windowRequestFrameId);
    }

    redirectTo() {
        this._gaService.triggerEvent('DS View', 'Usecase', this.widgetData.title);
        this.router.navigate(['gallery/story-page', this.widgetData.url]);
    }

    renderproblemSmallCluster() {
        const createSmallClusterCircle = (index, data, width, height, categories, categoriesColor) => {
            const { details, flag } = this.sectionsHolder.problemToData.smallCluster;
            details[index] = {
                svg: this.commonContainer.svg,
                index,
                data,
                width,
                height,
                idString: categories,
                categories,
                categoriesColor
            };
            flag[index] = 1;
        }

        var smallClusterConfig = {
            width: [605, 490, 300, 10],
            height: [1200, 1620, 1910, 1910],
            dotCount: [60, 66, 70, 76],
            data: []
        }

        const { width, height, dotCount, data } = smallClusterConfig;
        for (let index = 0; index < 4; index++) {
            let categories = this.commonContainer.categories[index].type,
                categoriesColor = this.commonContainer.categories[index].color,
                settings = {
                    width: this.commonContainer.xScale(width[index]),
                    height: height[index],
                    categories
                };

            this.smallClusterService.createData(index, settings, dotCount[index]);
            data[index] = this.smallClusterService.getData(index);
            createSmallClusterCircle(index, data[index], settings.width, settings.height, categories, categoriesColor);
        }

        const { details } = this.sectionsHolder.problemToData.smallCluster;

        let centerTextFlag = [0, 0, 0, 0];
        const updateSmallClusterForce = (index) => {
            let centerCircleNode = d3.select("#" + details[index].idString + "-" + index + "-0"),
                innerCircleRadius = 30,
                innercircleCharge = -160,
                outerCircleCharge = -8,
                centerNodeCoordinates = { x: null, y: null },
                textColorCode = "#FFFFFF";

            centerCircleNode
                .transition()
                .duration(500)
                .attr('r', (d) => {
                    centerNodeCoordinates.x = d.px;
                    centerNodeCoordinates.y = d.py;
                    return innerCircleRadius
                });

            if (!centerTextFlag[index]) {
                this.commonContainer.svg.append("text")
                    .attr({
                        "x": centerNodeCoordinates.x - 20,
                        "y": centerNodeCoordinates.y + 5,
                        "fill": textColorCode,
                        "class": "center-node-text",
                        "id": "center-node-text-" + index
                    })
                    .transition()
                    .delay(400)
                    .text(function (d) {
                        return "$" + smallClusterConfig.dotCount[index] + "0M"
                    })
                centerTextFlag[index] = 1;
            }

            d3.layout.force()
                .size([this.commonContainer.xScale(smallClusterConfig.width[index]) * 2, smallClusterConfig.height[index]])
                .charge(function (d, i) {
                    return i ? outerCircleCharge : innercircleCharge
                })
                .nodes(smallClusterConfig.data[index].nodes)
                .on("tick", () => {
                    d3.selectAll(".node_" + index)
                        .attr("cx", function (d) {
                            return d.x;
                        })
                        .attr("cy", function (d) {
                            return d.y;
                        });
                })
                .start();
        };

        const updateOpacityOfAllCircles = (index, opacity = 1) => {
            d3
                .selectAll(".node_" + index)
                .attr("fill-opacity", opacity)
        }

        return {
            updateSmallClusterForce,
            updateOpacityOfAllCircles
        };
    }

    renderTimeline() {
        let translate = null, translateX = [1050, 1000, 950, 900]; //[900, 950, 1000, 1050]
        let timelineColors = null,
            parseDate = d3.time.format("%m/%d/%Y").parse,
            years = ['1/1/2006', '1/1/2007', '1/1/2008', '1/1/2009', '1/1/2010', '1/1/2011', '1/1/2012', '1/1/2013', '1/1/2014', '1/1/2015', '1/1/2016', '1/1/2017'],
            shapes = ["hexagoan", "square", "triangleDown", "star"],
            timelineData = [],
            width = this.commonContainer.xScale(578),
            height = 1800,
            timeLineXScale = d3.time.scale().domain(d3.extent(years, (d) => {
                return parseDate(d);
            })).range([50, width - 50]),
            categoriesColor = [],
            shapePosition = [],
            timelinePathScales = [];

        const createTimeline = (index, width, height, translate, data) => {
            const { details, flag } = this.sectionsHolder.dataToSolution.timeline;
            details[index] = {
                svg: this.commonContainer.svg,
                index,
                width,
                height,
                data,
                translate,
                categoriesColor,
                tooltipDivId: this.commonContainer.parentNodeId
            };
            flag[index] = 1;
        }

        for (let i = 0; i < 4; i++) {
            translateX[i] = this.commonContainer.xScale(translateX[i]) - 15;
            categoriesColor[i] = this.commonContainer.categories[i].color;
        }
        timelineColors = categoriesColor.slice();

        for (let index = 0; index < 4; index++) {
            height -= 100;
            translate = {
                x: translateX[index],
                y: height
            }
            let timelineIndex = index % 2 == 0 ? index : -1;
            let timelineDetails = {
                toolTipMessage: ["Added wireless line", "Purchased a prepaid plan"],
                displayeMessageAt: [1, 8],
                timelineIndex,
                shapeSize: 200,
            }
            timelineData[index] = this.timelineService.getData(index);
            this.timelineService.createData(index, shapes, categoriesColor, timelineDetails);
            timelineData[index] = this.timelineService.getData(index);
            createTimeline(index, width, height, translate, timelineData[index]);
        }

        const timeLineAnimation = (path, index, direction) => {
            let pathTotalLength = path.node().getTotalLength();
            direction = direction || "forward";
            let startStrokeDashoffset = direction == "forward" ? pathTotalLength : 0;
            let endStrokeDashoffset = direction == "forward" ? 0 : pathTotalLength;

            path
                .style({
                    "stroke": timelineColors[index]
                })
                .attr({
                    "stroke-dasharray": pathTotalLength + " " + pathTotalLength,
                    "stroke-dashoffset": startStrokeDashoffset
                })
                .transition()
                .duration(400)
                .ease("linear")
                .attr("stroke-dashoffset", endStrokeDashoffset); // Set to pathTotalLength for reverse animation 
        }

        const showTimelineActivityTooltip = (index, key, visibilityFlag = true) => {
            let div = d3.select('#timeline-tooltip-' + index + "-" + key);
            let shape = this.commonContainer.svg.select('#date_timeline_shapes_' + index + "_" + key);
            let shapeBoundingRect = shape.node().getBoundingClientRect();
            let rect = this.commonContainer.svg.select("#second_path_" + index).node().getBoundingClientRect();
            div.style({
                "left": ((shapeBoundingRect.left + (shapeBoundingRect.width / 2)) - (div.node().offsetWidth / 2)) + "px",
                "top": (rect.height / (index == 0 ? 0.71 : 0.438)) + "px",
            });
            if (visibilityFlag) {
                div.style("visibility", "visible");
            }
        }

        const hideTimelineActivityTooltip = (index, key) => {
            let div = d3.select('#timeline-tooltip-' + index + "-" + key);
            if (!div[0][0]) {
                return;
            }
            let tooltipTop = div.style("top");
            div.style({
                "top": (Number(tooltipTop.replace("px", "")) - 40) + "px",
                "visibility": "hidden"
            });
        }

        let setTooltipPosition = () => {
            let index = [0, 2], subIndex = [0, 7];

            for (let i = 0; i < index.length; i++) {
                for (let j = 0; j < subIndex.length; j++) {
                    showTimelineActivityTooltip(index[i], subIndex[j], false);
                }
            }
        }

        let updateTimelinePathScale = () => {
            let { scrollLength, aspectRatio } = this.commonContainer,
                domain = {
                    "start": (scrollLength * 0.155) / aspectRatio,
                    "end": (scrollLength * 0.168) / aspectRatio
                };
            let timelineDomain = d3.scale.linear().domain([domain.start, domain.end]);
            for (let index = 0; index < 4; index++) {
                timelinePathScales[index] = (range) => timelineDomain.range([range.start, range.end]).clamp(true);
            }
        }

        let range = {
            start: 50,
            end: 0
        }
        let updateTimelineVisual = (scrollTop) => {
            for (let index = 0; index < 4; index++) {
                configTimelineAnimation(index, scrollTop);
                updateTimelineShapes(index, scrollTop)
            }
        }
        let timelineFlag = [0, 0, 0, 0];
        let configTimelineAnimation = (index, scrollTop) => {
            let centerPath = this.commonContainer.svg.select("#date_timeline_center_path_" + index);
            let length = centerPath.node().getTotalLength();
            range.end = length;

            if (timelinePathScales[index](range)(scrollTop) > range.start && !timelineFlag[index]) {
                timeLineAnimation(this.commonContainer.svg.select("#date_timeline_" + index), index, "forward");
                timelineFlag[index] = 1;
            } else if (timelinePathScales[index](range)(scrollTop) == range.start && timelineFlag[index]) {
                timeLineAnimation(this.commonContainer.svg.select("#date_timeline_" + index), index, "backward");
                timelineFlag[index] = 0;
            }
        }

        let updateTimelineShapes = (index, scrollTop) => {
            if (!shapePosition[index]) {
                shapePosition[index] = [];
                if (shapePosition[index].length == 0) {
                    shapePosition[index] = this.timelineService.getShapePosition();
                }
            }
            let timelineShapes = this.commonContainer.svg.selectAll(".date_timeline_shapes_" + index);
            timelineShapes.each((d, key) => {
                updateTimelineContentPosition(index, key, d, scrollTop)
            });
        }

        let updateTimelineContentPosition = (index, key, data, scrollTop) => {
            let shape = this.commonContainer.svg.select("#date_timeline_shapes_" + index + "_" + key);
            range.end = shapePosition[index][key];
            let opacityRange = {
                start: 0,
                end: 1
            }
            let getXPosition = timelinePathScales[index](range)(scrollTop);
            let string = shape.attr("transform"),
                translate = string.substring(string.indexOf("(") + 1, string.indexOf(")")).split(","),
                translateShape = {
                    x: getXPosition,
                    y: Number(translate[1])
                }

            shape
                .attr("transform", "translate(" + translateShape.x + ", " + translateShape.y + ")")
                .style('fill-opacity', timelinePathScales[index](opacityRange)(scrollTop))

            //Node Text and Tooltip Manipulation
            if (getXPosition == shapePosition[index][key]) {
                if (data.showTooltip && !data.isTooltipDisplayed) {
                    data.isTooltipDisplayed = true;
                    showTimelineActivityTooltip(index, key);
                }
            } else if (getXPosition != shapePosition[index][key]) {
                if (data.isTooltipDisplayed) {
                    data.isTooltipDisplayed = false;
                    hideTimelineActivityTooltip(index, key);
                }
            }
        }

        return {
            setTooltipPosition,
            updateTimelinePathScale,
            updateTimelineVisual
        };
    }

    responsivefy(svg) {
        // get container + svg aspect ratio
        var container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style("width")),
            height = parseInt(svg.style("height")),
            aspect = width / height;
        this.commonContainer.aspectRatio = aspect;
        // this.setPositionOfTextelements();    

        // get width of container and resize svg to fit it
        let resize = () => {
            var targetWidth = parseInt(container.style("width"));
            svg.attr("width", targetWidth);
            svg.attr("height", Math.round(targetWidth / aspect));
            this.setPositionOfTextelements();
            this.changeWindowDetails();
            this.sectionsHolder.renderStory();
        }
        // add viewBox and preserveAspectRatio properties,
        // and call resize so that svg resizes on inital page load
        svg.attr("viewBox", "0 0 " + width + " " + height)
            .attr("perserveAspectRatio", "xMinYMid")
            .call(resize);

        // to register multiple listeners for same event type, 
        // you need to add namespace, i.e., 'click.foo'
        // necessary if you call invoke this function for multiple svgs
        // api docs: https://github.com/mbostock/d3/wiki/Selections#on
        d3.select(window).on("resize." + container.attr("id"), resize);
    }

    setPositionOfTextelements() {
        let textContainer = d3.select("#problem");
        let boundingClientRect = this.commonContainer.svg.select("#first_path_4").node().getBoundingClientRect();
        const problemHeight = boundingClientRect.height * 3.21;
        textContainer
            .style({
                height: `${problemHeight}px`,
                'align-items': 'flex-start'
            });
        boundingClientRect = this.commonContainer.svg.select("#second_path_3").node().getBoundingClientRect();
        textContainer = d3.select("#data");//.select('.data-text-section')
        let textContainerBoundingRect = textContainer.node().getBoundingClientRect();

        textContainer
            .style({
                height: (boundingClientRect.height * 2.08) + "px", // 856
                'align-items': 'flex-start',
                top: `${problemHeight - 20}px`, // (boundingClientRect.height / this.commonContainer.aspectRatio) + ((boundingClientRect.height - textContainerBoundingRect.height) / 1.78) + "px",
                left: boundingClientRect.left + (boundingClientRect.width * 0.07) + "px"
            });

        boundingClientRect = this.commonContainer.svg.select("#dna-svg-block_0").node().getBoundingClientRect();
        textContainer = d3.select("#solution");
        // textContainerBoundingRect = textContainer.node().getBoundingClientRect();
        textContainer
            .style({
                height: (boundingClientRect.height * 1.25) + "px", // 810
                'align-items': 'flex-start',
                top: (boundingClientRect.height / 0.334) + "px",
            });

        boundingClientRect = this.commonContainer.svg.select("#dot-wall_0").node().getBoundingClientRect();
        textContainer = d3.select("#outcomes");
        // textContainerBoundingRect = textContainer.node().getBoundingClientRect();
        textContainer
            .style({
                height: (boundingClientRect.height * 1.11) + "px", // 854
                'align-items': 'flex-start',
                top: (boundingClientRect.height / 0.307) + "px", // 0.31
            });

        boundingClientRect = this.commonContainer.svg.select(".bar-chart-section-svg").node().getBoundingClientRect();
        textContainer = d3.select(".outcomes-rebbons");
        textContainer
            .style({
                top: (boundingClientRect.height) + "px",
            });

        boundingClientRect = this.commonContainer.svg.select("#first_path_0").node().getBoundingClientRect();
        let boundingClientRect_2 = this.commonContainer.svg.select("#first_path_4").node().getBoundingClientRect();
        textContainer = d3.select("#shop-pay");
        // textContainerBoundingRect = textContainer.node().getBoundingClientRect();
        textContainer
            .style({
                top: (boundingClientRect.height / 0.275) + "px",
                left: (boundingClientRect_2.height / 0.31) + "px"
            });
    }

    renderDna() {

        const createDna = (index, width, height, translate, idString) => {
            this.sectionsHolder.solution.details[index] = {
                svg: this.commonContainer.svg,
                container: null,
                width: width,
                height: height,
                index: index,
                data: [],
                categories,
                categoriesColor,
                shapes: ["hexagoan", "square", "triangleDown", "star"].reverse(),
                translate: translate,
                idString: idString
            };
            this.sectionsHolder.solution.flag[index] = 1;
        }

        let dnaIdString = "dna-arc", width, height, translate, categoriesColor = [], categories = [], dnaTranslate = [], svgHeight, path = { dna: [] }, pathScales = { dna: [] };

        for (let i = 0; i < 4; i++) {
            categoriesColor[i] = this.commonContainer.categories[i].color;
            categories[i] = this.commonContainer.categories[i].type;
        }

        dnaTranslate = this.sectionsHolder.dataToSolution.thirdLinePath.getLine3EndPoints();

        for (let index = 0; index < 4; index++) {
            width = 150;
            height = 650;
            translate = {
                x: this.commonContainer.xScale(dnaTranslate[index].xCoordinate) - (width / 2),
                y: dnaTranslate[index].yCoordinate - 30
            };
            svgHeight = dnaTranslate[index].yCoordinate + height
            createDna(index, width, height, translate, dnaIdString);
        }

        this.commonContainer.svg.attr({
            "height": svgHeight + 30
        })

        const getDnaPath = (object) => {
            path.dna = object.path.dna.slice();
            pathScales.dna = object.pathScales.dna.slice();
        }

        let animateDnaLinePath = (scrollTop) => {
            for (let secondIndex = 0; secondIndex < 4; secondIndex++) {
                updateDnaArcPathLength(path.dna[secondIndex].left, pathScales.dna[secondIndex].left, secondIndex, scrollTop, "left");
                updateDnaArcPathLength(path.dna[secondIndex].right, pathScales.dna[secondIndex].right, secondIndex, scrollTop, "right");
            }
        }

        const updateDnaArcPathLength = (path, pathScale, index, scrollTop, side) => {
            path
                .style('stroke-dashoffset', function (d) {
                    side == "right" ?
                        updateRightDnaShapesAnimation(path, pathScale, index, scrollTop) :
                        updateLeftDnaShapesAnimation(path, pathScale, index, scrollTop);
                    if (pathScale(scrollTop) <= 10) {
                        hideShapes(side, index);
                    }
                    return path.node().getTotalLength() - pathScale(scrollTop) + 'px';
                });
        }

        const updateLeftDnaShapesAnimation = (path, pathScale, index, scrollTop) => {
            let movingShapes = this.commonContainer.svg.selectAll(".left-shapes-" + index),
                idString = "#dna-left-moving-shapes-";

            movingShapes
                .attr("fill-opacity", 1)
                .each(function (d, key) {
                    dnaShapesPathTween(path, pathScale, index, key, scrollTop, idString);
                });
        }

        const updateRightDnaShapesAnimation = (path, pathScale, index, scrollTop) => {
            let movingShapes = this.commonContainer.svg.selectAll(".right-shapes-" + index),
                idString = "#dna-right-moving-shapes-";

            movingShapes
                .attr("fill-opacity", 1)
                .each(function (d, key) {
                    dnaShapesPathTween(path, pathScale, index, key, scrollTop, idString);
                });
        }

        const dnaShapesPathTween = (linePath, pathScale, index, key, scrollTop, idString) => {
            let length = linePath.node().getTotalLength();
            let r = d3.interpolate(0, length);
            let diffFactorForEachShapes = key * 25;
            let divideAdjFactor = length;
            let point = linePath.node().getPointAtLength(r(((pathScale(scrollTop) - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path

            this.commonContainer.svg.select(idString + index + "-" + key)
                .attr("transform", "translate(" + point.x + ", " + point.y + ")")
        }

        const hideShapes = (type, index) => {
            let movingShapes = this.commonContainer.svg.selectAll("." + type + "-shapes-" + index);
            movingShapes
                .attr("fill-opacity", 0);
        }

        return {
            animateDnaLinePath,
            getDnaPath
        }
    }

    renderBarChart() {
        var dim = {
            width: 800,
            height: 500,
            graphWidth: 0,
            graphHeight: 0
        };
        var barMargin = {
            top: 10,
            bottom: 50,
            left: 50,
            right: 10
        };
        dim.graphWidth = dim.width - barMargin.left - barMargin.right;
        dim.graphHeight = dim.height - barMargin.top - barMargin.bottom;

        var barChartSvg = this.commonContainer.svg.append('g')
            .attr({
                width: dim.width,
                height: dim.height,
                class: "bar-chart-section-svg"
            })
            .style({
                margin: 0,
                padding: 0
            });

        var axisLayer = barChartSvg.append('g').attr('transform', 'translate(' + barMargin.left + ',' + barMargin.top + ')'),
            graphLayer = barChartSvg.append('g').attr('transform', 'translate(' + barMargin.left + ',' + barMargin.top + ')'),
            barXScale = d3.scale.ordinal().rangeBands([0, dim.graphWidth], 0.83),
            xLocalScale = d3.scale.ordinal(),
            barYScale = d3.scale.ordinal().rangePoints([dim.graphHeight, 0]),
            colorScale = ["#faa24c", "#d94785", "#a1be00", "#39b2ff"],
            barXAxis = d3.svg.axis().orient('bottom').scale(barXScale),
            barYAxis = d3.svg.axis().orient('left').scale(barYScale),
            xAxisObj = axisLayer.append('g')
                .attr({
                    'transform': 'translate(' + 0 + ',' + dim.graphHeight + ')',
                    'class': 'axis'
                })
                .call(barXAxis),
            yAxisObj = axisLayer.append('g')
                .attr({
                    'transform': 'translate(' + 0 + ',' + 0 + ')',
                    'class': 'axis'
                })
                .call(barYAxis);

        axisLayer.selectAll('.axis text').style('font', '14px "Lucida Grande", Helvetica, Arial, sans-serif');
        axisLayer.selectAll('.axis path.domain').style({
            fill: 'none',
            stroke: '#e0e0e0',
            'shape-rendering': 'crispEdges'
        });
        axisLayer.selectAll('.axis line').style({
            fill: 'none',
            stroke: '#000000',
            'shape-rendering': 'crispEdges'
        });

        var radius = 4;
        var mar = 0.7;
        var barWidth = 3;

        // To get barChart Config Data
        let barChartConfigDetails = this.sectionsHolder.outcome.dotWall.getBarChartConfigDetails();
        let {
            barData,
            sums,
            parties,
            raw
        } = barChartConfigDetails;

        let svgHeight = parseInt(this.commonContainer.svg.style("height").replace("px", "")), translate = { x: null, y: null };
        let translateValue = this.sectionsHolder.dataToSolution.thirdLinePath.getLine3EndPoints();
        translate.y = svgHeight - dim.height + 20;
        translate.x = this.commonContainer.xScale(translateValue[0].xCoordinate) - (250 / 2);

        //To translate barChart Group
        barChartSvg.attr({
            transform: "translate(" + translate.x + "," + translate.y + ")"
        })

        //To Update Svg Height
        this.commonContainer.svg.attr({
            height: translate.y + dim.height
        });

        var nrow = Math.ceil(dim.graphHeight / (2 * (radius + mar)));
        barYScale.domain(d3.range(nrow));
        barXScale.domain(raw.data.map(function (d, i) {
            return i;
        }));
        barXAxis.tickFormat(function (d) {
            return parties[d];
        });
        xAxisObj.call(barXAxis);

        var yText = yAxisObj.append("g")
            .attr("class", "y axis");

        function createYAxisText(textSvg, text, boundaries) {
            textSvg.append("text") // and text1
                .attr({
                    "transform": "rotate(-90)",
                    "y": boundaries.y,
                    "x": boundaries.x,
                    "dy": ".71em"
                })
                .style({
                    "fill": "#979998",
                    "text-anchor": "end",
                    "font-size": "16px",
                    "font-weight": "500"
                })
                .text(text);
        }

        let boundaries = null;
        boundaries = {
            x: -120,
            y: -40
        }
        createYAxisText(yText, "Micro Cluster Event Likehood", boundaries);
        boundaries = {
            x: -140,
            y: -20
        }
        createYAxisText(yText, "Micro Cluster Size (pm)", boundaries);

        xLocalScale.rangeBands([0, barXScale.rangeBand()]).domain(d3.range(barWidth));

        var displaydata = d3.range(sums).map(function (d) {
            return [];
        });

        var barDataFlagCount = [0, 0, 0, 0];
        var barChartDots = [0, 0, 0, 0];

        for (let i = 0; i < barData.length; i++) {
            barDataFlagCount[i] = i == 0 ? 0 : barData[i - 1];
        }

        function createBarChartData(index) {
            displaydata[barDataFlagCount[index]].push({
                label: index,
                idx: barChartDots[index]
            });
            createBarDots(displaydata[barDataFlagCount[index]]);
            barChartDots[index]++;
            barDataFlagCount[index]++;

            if (barChartDots[index] == barData[index] && reverseFlag[index]) {
                animateRemainingDots(0);
                animationBarText(index, 0, 1);
                reverseFlag[index] = 0;
            }
        }

        var symbol = d3.svg.symbol().type('circle'),
            votes = null,
            reverseFlag = [];

        function createBarDots(d) {
            for (let i = 0; i < d.length; i++) {
                votes = graphLayer
                    .data(d)
                    .append("path")
                    .attr({
                        "d": symbol.size(50),
                        "transform": function () {
                            return "translate(" + ((d[i].label != null) ? (barXScale(d[i].label) + xLocalScale(d[i].idx % barWidth)) : (dim.graphWidth / 2)) +
                                "," + ((d[i].label != null) ? (isNaN(barYScale(Math.floor((d[i].idx + 0.1) / barWidth)) - radius - mar) ? 0 : barYScale(Math.floor((d[i].idx + 0.1) / barWidth)) - radius - mar) : 0) + ")";
                        },
                        "id": function () {
                            return "bar_chart_dot_" + d[i].label + "_" + d[i].idx
                        },
                        'class': 'vote'
                    })
                    .style({
                        "stroke": "none",
                        'fill': function () {
                            return colorScale[d[i].label];
                        }
                    });
            }
        }

        //for top text
        graphLayer.selectAll(".text")
            .data(raw.data)
            .enter()
            .append("text")
            .attr({
                "x": function (d, i) {
                    return (barXScale(i) + xLocalScale(0 % barWidth) - (i > 0 ? 7 : 0));
                },
                "y": function (d, i) {
                    return barYScale(Math.floor((d.value + 0.1) / barWidth)) - radius - mar
                },
                "id": function (d, i) {
                    return i + "_" + d.top;
                },
                "dy": "0em",
                "stroke-opacity": 0,
                "fill-opacity": 0,
                "font-family": "sans-serif",
                "font-size": "10px",
                "font-weight": "bold"
            })
            .text(function (d) {
                return d.top;
            })

        //for left text
        graphLayer.selectAll(".text")
            .data(raw.data)
            .enter()
            .append("text")
            .attr({
                "x": function (d, i) {
                    return (barXScale(i) + xLocalScale(0 % barWidth)) - 35;
                },
                "y": function (d, i) {
                    return (barYScale(Math.floor((d.value / 2 + 0.1) / barWidth)) - radius - mar) + 5
                },
                "id": function (d, i) {
                    return i + "_" + d.left;
                },
                "dy": "0em",
                "stroke-opacity": 0,
                "fill-opacity": 0,
                "font-family": "sans-serif",
                "font-size": "13px"
            })
            .text(function (d) {
                return d.left;
            });

        function animateRemainingDots(value) {
            for (let i = 0; i < 4; i++) {
                for (let j = 0; j < 4; j++) {
                    d3.selectAll(".dot-ball_" + i + "_" + j)
                        .transition()
                        .ease("linear")
                        .delay(450 * ((j + i) * Math.random()))
                        .attr({
                            "fill-opacity": value,
                            "stroke-opacity": value
                        })
                }
            }
        }

        function animationBarText(index, start, end) {
            var topTextObj = [],
                leftTextObj = [];

            topTextObj[index] = d3.select("text[id='" + index + "_" + raw.data[index].top + "']");
            topTextObj[index].attr("fill-opacity", start)
                .attr("stroke-opacity", start)
                .transition()
                .ease("linear")
                .delay(function (d, i) {
                    return 200 * (i + 2);
                })
                .attr({
                    "fill-opacity": end,
                    "stroke-opacity": end
                });

            leftTextObj[index] = d3.select("text[id='" + index + "_" + raw.data[index].left + "']");
            leftTextObj[index].attr("fill-opacity", start)
                .attr("stroke-opacity", start)
                .transition()
                .ease("linear")
                .delay(function (d, i) {
                    return 250 * (i + 2);
                })
                .attr({
                    "fill-opacity": end,
                    "stroke-opacity": end
                });
        }

        for (let i = 0; i < 4; i++) {
            reverseFlag[i] = 1;
        }

        function removeBarDot(index) {
            graphLayer.select("#bar_chart_dot_" + index + "_" + (barChartDots[index] - 1)).remove();
            displaydata[barDataFlagCount[index]].pop();
            barChartDots[index]--;
            barDataFlagCount[index]--;

            if (barChartDots[index] != barData[index] && !reverseFlag[index]) {
                animateRemainingDots(1);
                animationBarText(index, 1, 0);
                reverseFlag[index] = 1;
            }
        }

        return {
            createBarChartData,
            removeBarDot
        }
    }

    changeWindowDetails() {
        let HEIGHT = window.innerHeight;
        let content = d3.select("#" + this.commonContainer.parentNodeId),
            header = d3.select("#header");
        this.commonContainer.scrollLength = content.node().getBoundingClientRect().height - (HEIGHT - header.node().getBoundingClientRect().height);
        console.log("SCROLL LENGTH , SCREEN HEIGHT, Content HEIGHT, Header HEIGHT:", this.commonContainer.scrollLength, HEIGHT, content.node().getBoundingClientRect().height, header.node().getBoundingClientRect().height);

        //To update domains of Path and other components on resize(window height and svg viewbox resize)
        this.sectionsHolder.problemToData.firstLinePath.updateFirstPathScale();
        this.sectionsHolder.problemToData.secondLinePath.updateSecondPathScale();
        this.sectionsHolder.dataToSolution.timeline.updateTimeline.updateTimelinePathScale();
        this.sectionsHolder.dataToSolution.thirdLinePath.updateThirdPathScale();
        this.sectionsHolder.dataToSolution.timeline.updateTimeline.setTooltipPosition();

        let start = (this.commonContainer.scrollLength * 0.205) / this.commonContainer.aspectRatio,
            end = (this.commonContainer.scrollLength * 0.265) / this.commonContainer.aspectRatio;

        let dnaDetails = this.initializeDnaPath(start, end);
        this.sectionsHolder.solution.updateDna.getDnaPath(dnaDetails);
        this.sectionsHolder.solutionToOutcome.updateRainDropDomain()
        this.sectionsHolder.outcome.dotWall.updateFourthPathScale();
    }

    renderRainBlock() {
        let translateValue = this.sectionsHolder.dataToSolution.thirdLinePath.getLine3EndPoints();
        let heightOfDna = 650;
        var rainDim = {
            width: {
                start: this.commonContainer.xScale(translateValue[0].xCoordinate) - 75,
                end: this.commonContainer.width - 75
            },
            height: {
                start: translateValue[0].yCoordinate + heightOfDna - 60,
                end: 90
            }
        },
            categoriesColor = [],
            categories = [],
            rainShapeCount = 100,
            rainSvg = this.commonContainer.svg
                .append("g")
                .attr({
                    "class": "solution-to-outcome-rain-block",
                    "id": "solution-to-outcome-rain-block",
                }),
            dropRainData = [],
            rainFlowOffset = [];

        rainDim.height.end += rainDim.height.start;

        for (let i = 0; i < 4; i++) {
            categoriesColor[i] = this.commonContainer.categories[i].color;
            categories[i] = this.commonContainer.categories[i].type;
        }

        let createRainData = () => {
            for (let i = 0; i < rainShapeCount; i++) {
                dropRainData.push({
                    dotId: null,
                    rainId: "dna-to-wall-shapes_" + i,
                    shapeType: this.commonContainer.shapes[Math.floor(Math.random() * (this.commonContainer.shapes.length))],
                    coordinates: {
                        from: {
                            x: null,
                            y: null,
                        },
                        to: {
                            x: null,
                            y: null
                        }
                    },
                    position: {
                        xScale: null,
                        yScale: null
                    },
                    flag: {
                        start: false,
                        end: false
                    }
                });
                rainFlowOffset[i] = 0;
            }
        }

        let getRandom = (min, max) => {
            return Math.random() * (max - min) + min;
        }

        let createRainBlock = () => {
            rainSvg.selectAll(".rain_shapes")
                .data(dropRainData)
                .enter()
                .append("path")
                .attr({
                    "d": (d) => {
                        return this._D3CustomShapesService.getShapeCoordinates(d.shapeType, (35 * 2))
                    },
                    "transform": function (d, i) {
                        dropRainData[i].coordinates.from.x = getRandom(rainDim.width.start, rainDim.width.end);
                        dropRainData[i].coordinates.from.y = getRandom(rainDim.height.start, rainDim.height.end);
                        return "translate(" + dropRainData[i].coordinates.from.x + "," + dropRainData[i].coordinates.from.y + ")"
                    },
                    "class": "dna-to-wall-shapes",
                    "id": function (d, i) {
                        return d.rainId
                    },
                    "fill-opacity": 1
                })
                .style({
                    "fill": function () {
                        return categoriesColor[Math.floor(Math.random() * 4)]
                    },
                    "stroke": "none",
                })
        }

        let updateRainData = () => {
            let rainOffset = 0,
                dotGroup = null,
                dotShape = null,
                rainCount = 0,
                nextRainOffset = (rainShapeCount / 4),
                rainIndex = 0,
                translateDotWall = {
                    x: null,
                    y: null
                }

            function shuffle(array) {
                array.sort(() => Math.random() - 0.5);
            }

            for (let dotIndex = 0; dotIndex < 4; dotIndex++) {
                dotGroup = this.commonContainer.svg.select('#dot-wall_' + dotIndex);
                let string = dotGroup.attr("transform");
                let translate = string.substring(string.indexOf("(") + 1, string.indexOf(")")).split(",");

                translateDotWall = {
                    x: Number(translate[0]) + 10,
                    y: Number(translate[1]) + 40
                }

                dotShape = this.commonContainer.svg.selectAll('.dot-ball_' + dotIndex);
                shuffle(dotShape[0]);

                rainCount = 0;
                for (rainIndex = rainOffset; rainIndex < nextRainOffset; rainIndex++) {
                    dropRainData[rainIndex].dotId = dotShape[0][rainCount].getAttribute("id");
                    dropRainData[rainIndex].coordinates.to.x = Number(dotShape[0][rainCount].getAttribute("cx")) + translateDotWall.x;
                    dropRainData[rainIndex].coordinates.to.y = Number(dotShape[0][rainCount].getAttribute("cy")) + translateDotWall.y;
                    rainCount++;
                }
                rainOffset += rainCount;
                nextRainOffset += rainCount;
            }
        }

        let updateDomain = () => {
            let { scrollLength, aspectRatio } = this.commonContainer;
            let domain = {
                start: (scrollLength * 0.265) / aspectRatio,
                end: (scrollLength * 0.334) / aspectRatio
            }
            for (let index = 0; index < dropRainData.length; index++) {
                let { position, coordinates } = dropRainData[index], range;
                position.xScale = (range) => d3.scale.linear().domain([domain.start, domain.end]).range([range.start, range.end]).clamp(true);
                range = {
                    start: coordinates.from.y,
                    end: coordinates.to.y
                };
                position.yScale = d3.scale.linear().domain([domain.start, domain.end]).range([range.start, range.end]).clamp(true);
            }
        }

        let transformShape = (data, index, scrollTop) => {
            let { position, dotId, rainId, coordinates, flag } = data, x, y, raindropShape, dotWallShape, range;
            range = {
                start: coordinates.from.x,
                end: coordinates.to.x
            }
            if (position.yScale(scrollTop) <= (coordinates.from.y + (coordinates.to.y - coordinates.from.y) / 1.4)) {
                range = {
                    start: coordinates.from.x,
                    end: getRandom(coordinates.from.x, coordinates.to.x)
                };
                x = position.xScale(range)(scrollTop);
            } else {
                x = position.xScale(range)(scrollTop);
            }
            y = position.yScale(scrollTop);
            raindropShape = this.commonContainer.svg.select("#" + rainId);
            dotWallShape = this.commonContainer.svg.select("#" + dotId);

            if (x == coordinates.from.x && y == coordinates.from.y && !flag.start) {
                raindropShape
                    .attr({ "fill-opacity": 0 });

                flag.start = true;
            } else if (x != coordinates.from.x && y != coordinates.from.y && flag.start) {
                raindropShape
                    .attr({ "fill-opacity": 1 });

                flag.start = false;
            }

            if (x != coordinates.from.x && y != coordinates.from.y) {
                raindropShape
                    .attr({ "transform": "translate(" + x + "," + y + ")" });
            }

            if (y == coordinates.to.y && flag.end) {
                let otherDotShapes = this.commonContainer.svg.selectAll('.dot-ball');
                otherDotShapes.attr({ "fill-opacity": 1 })
            } else if (x == coordinates.to.x && y == coordinates.to.y && !flag.end) {
                raindropShape
                    .attr({ "fill-opacity": 0 });

                dotWallShape
                    .attr({ "fill-opacity": 1 })
                    .classed("dot-ball", false);

                flag.end = true;
            }

            if (x != coordinates.to.x && y != coordinates.to.y && flag.end) {
                raindropShape
                    .attr({ "fill-opacity": 1 });
                dotWallShape
                    .attr({ "fill-opacity": 0 })
                    .classed("dot-ball", true);
                flag.end = false;
            } else if (y != coordinates.to.y && !flag.end) {
                let otherDotShapes = this.commonContainer.svg.selectAll('.dot-ball');
                otherDotShapes
                    .attr({ "fill-opacity": 0 })
            }
        }

        let animateRainDrop = (scrollTop) => {
            for (let index = 0; index < dropRainData.length; index++) {
                transformShape(dropRainData[index], index, scrollTop);
            }
        }

        createRainData();
        createRainBlock();

        return {
            updateRainData,
            updateRainDropDomain: updateDomain,
            animateRainDrop
        }
    }
}
