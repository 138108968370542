import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy } from '@angular/core';
declare let d3: any;
import * as _ from 'lodash';
import { WidgetService } from '../widgets.service';

// Reference link: https://github.com/q-m/d3.chart.sankey
@Component({
  selector: 'app-d3-sanky-chart',
  templateUrl: './d3-sanky-chart.component.html'
})
export class D3SankyChartComponent implements OnInit, OnDestroy {
  @Input() widgetData;
  @ViewChild('sankyChartRef') chartElement: ElementRef;

  data;

  constructor(private widgetService: WidgetService) {
    this.widgetService.triggerDataChangeForD3SankyChart.subscribe((updatedDataInfo) => {
      this.updateChart(updatedDataInfo);
      /*if (updatedDataInfo.formField.uniqueName201 && updatedDataInfo.formField.uniqueName201.length) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }*/
    });
  }

  updateChart(updatedDataInfo) {
    this.data = this.createNodeLinkStructure(updatedDataInfo.formField.filterField);
    this.resetChart();
    this.loadGraph();
  }

  resetChart() {
    d3.select('#d3_sankey_chart_svg').remove();
  }

  resetToOrignal() {
    this.data = this.createNodeLinkStructure([]);
    this.resetChart();
    this.loadGraph();
  }

  ngOnInit() {
    this.data = this.createNodeLinkStructure([]);
    this.loadGraph();
  }

  createNodeLinkStructure(filterValues) {
    const nodeData = {
      nodes: [],
      links: []
    };
    const selectedData = [...filterValues];
    const isFilterEmpty = filterValues.length ? false : true;
    for (let selectedDataIndex = 0; (isFilterEmpty || selectedDataIndex < selectedData.length); selectedDataIndex++) {
      for (let index = 1; index < this.widgetData.data.length; index++) {
        if (!isFilterEmpty && this.widgetData.data[index][0] !== selectedData[selectedDataIndex]) {
          continue;
        }

        let foundIndex = _.findIndex(nodeData.nodes, { name: this.widgetData.data[index][0] });

        if (foundIndex < 0) {
          nodeData.nodes.push({
            name: this.widgetData.data[index][0],
            id: this.widgetData.data[index][0]
          });
        }

        foundIndex = _.findIndex(nodeData.nodes, { name: this.widgetData.data[index][1] });

        if (foundIndex < 0) {
          nodeData.nodes.push({
            name: this.widgetData.data[index][1],
            id: this.widgetData.data[index][1]
          });

          if (!isFilterEmpty) {
            selectedData.push(this.widgetData.data[index][1]);
          }
        }
      }

      if (isFilterEmpty) {
        break;
      }
    }

    for (let index = 1; index < this.widgetData.data.length; index++) {
      const sourceIndex = _.findIndex(nodeData.nodes, { name: this.widgetData.data[index][0] });
      const targetIndex = _.findIndex(nodeData.nodes, { name: this.widgetData.data[index][1] });

      if (sourceIndex > -1 && targetIndex > -1) {
        nodeData.links.push({
          'source': sourceIndex,
          'value': this.widgetData.data[index][2],
          'target': targetIndex
        });
      }
    }
    return _.cloneDeep(nodeData);
  }

  loadGraph() {
    const that = this;
    const d3Color = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f',
      '#cab2d6', '#ffff99', '#1f78b4', '#33a02c'
    ];
    var chart = d3.select(this.chartElement.nativeElement)
      .append('svg')
      .attr('id', 'd3_sankey_chart_svg')
      .attr('width', 1500)
      .attr('height', 2000)
      .chart('Sankey.Path');
    chart
      .name(label)
      .colorNodes(function (name, node) {
        return d3Color[Math.floor(Math.random() * d3Color.length)];
      })
      .colorLinks(function (link) {
        return d3Color[Math.floor(Math.random() * d3Color.length)];
      })
      .nodeWidth(15)
      .nodePadding(10)
      .spread(false)
      .iterations(1)
      .draw(this.data);

    function label(node) {
      return node.name.replace(/\s*\(.*?\)$/, '');
    }

// tslint:disable-next-line: prefer-const
    var div = d3.select('body').append('div')
      .attr('class', 'graph-tooltip white no-arrow')
      .attr('id', 'd3-chart-custom-tooltip')
      .style('display', 'none');

    chart.on('link:mouseover', function (link) {

      //<span>Weight: ${link.value}</span>
     
      div
        .html(`
                  <span>${link.source.name} -> ${link.target.name} </span>
        `)
        .style('left', (d3.event.pageX + 15) + 'px')
        .style('top', (d3.event.pageY - 20) + 'px');
      div.style('display', 'inline');

    });

    chart.on('link:mouseout', function (link) {
      div.style('display', 'none');

    });
  }

  ngOnDestroy() {
    d3.select('#d3-chart-custom-tooltip').remove();
  }

}
