import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { WidgetService } from '../widgets/widgets.service';


// import { NlpTextHighlighterService } from '../widgets/nlp-text-highlighter/nlp-text-highlighter.service';


@Component({
  selector: 'dynamic-control',
  templateUrl: './dynamic-form.component.html'
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() input;
  @Input() form: FormGroup;
  @Input() widgetType;

  hidden: boolean;
  subscription: Subscription;
  id: string;

  constructor(private widgetService: WidgetService) { }

  ngOnInit() {
    this.form = new FormGroup({
      filterField: new FormControl('')
    });
    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
    this.id = uuidv4();
    this.setUpConditions();
  }

  setUpConditions() {
    const relatedControl = this.form.get('filterField');
    if (!relatedControl) {
      return;
    }
    this.subscription = relatedControl.valueChanges.subscribe(x => {
      setTimeout(() => {
        // console.log( this.form.value )
        this.widgetService.dataChange({
          formField: this.form.value,
          widgetType: this.widgetType,
          inputDetails: this.input
        });
      });
    });

    if (this.input.selectByDefault && this.input.type === 'dropdown' && this.input.interactionData.length) {
      if (this.input.allowMultiSelect) {
        const selectValueCount = this.input.selectValueCount || 1;
        this.form.get('filterField').patchValue([...this.input.interactionData.slice(0, selectValueCount)]);
      } else {
        this.form.get('filterField').patchValue(this.input.interactionData[0]);
      }
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
