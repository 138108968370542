import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// import { any } from './loader';
@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<any>();
  loaderState = this.loaderSubject.asObservable();
  constructor() { }
  show() {
    this.loaderSubject.next(<any>{ loaded: true });
  }
  hide() {
    this.loaderSubject.next(<any>{ loaded: false });
  }
}
