import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sparatorsplit' })
export class SparatorSplitPipe implements PipeTransform {
  transform(value: string, sparator: string, element: string, isJoin: boolean) {
  	var newValue = value.split(sparator)[(+element-1)];
  	var allWords;
  	if (isJoin) {
  		allWords = value.split(sparator);
  		allWords.shift();
  		newValue = allWords.join(" ");
  	}
  	return newValue;
  }
}
