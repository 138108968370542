import { Component, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource, MatDialogRef, MatPaginator } from '@angular/material';
import { AdminService } from '../service/admin.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-lockout-dialog-box',
  templateUrl: './lockout-dialog-box.component.html',
  styleUrls: ['./lockout-dialog-box.component.css']
})
export class LockoutDialogBoxComponent implements OnInit {
  @ViewChild(MatPaginator) pageinator: MatPaginator;
  public userList: MatTableDataSource<any>;
  public filterText = '';
  public displayedColumns = ['ipAddress', 'userName', 'action'];
  noRecordsFound = '';
  constructor(
    public dialogRef: MatDialogRef<LockoutDialogBoxComponent>,
    private _adminService: AdminService,
    private _authService: AuthService) { }

  ngOnInit() {
      this._adminService.getIpList(this._authService.getUserId()).subscribe((res: any) => {
        if (res.data.length > 0) {
          res.data.forEach((v) => {
            v.isLoading = false;
          });
          this.userList = new MatTableDataSource(res.data);
          this.userList.paginator = this.pageinator;
          this.userList.filterPredicate = (data, filter: string): boolean => {
              return (data.username && data.username.toLowerCase().includes(filter)) || data.ipAddress.toLowerCase().includes(filter);
          };
        } else {
          this.noRecordsFound = 'Data is not available';
        }
      }, () => {
        this.noRecordsFound = 'Something went wrong. Please try again later.';
    })
  }

  applyFilter() {
    this.userList.filter = this.filterText.trim().toLowerCase();
  }
 
  toggleStatus(input, row) {
    input.checked = !input.checked;
    this.resetToggle(input, row, true);
    const obj = {
      userId: this._authService.getUserId(),
      accessedId: row.accessedId,
      isLocked: !row.isLocked
    };
    this._adminService.toggleUserLockedStatus(obj).subscribe(() => {
      this.resetToggle(input, row, false);
      input.checked = !input.checked;
      row.isLocked = !row.isLocked;
      this.applyFilter();
    }, err => {
      this.resetToggle(input, row, false);
      console.log('failed', err);
    })
  }
  
  resetToggle(input, row, status) {
    row.isLoading = status;
    input.disabled = status;
  }

}
