import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuardService implements CanActivate {

  constructor(private _authService: AuthService, private _router: Router) { }

  canActivate(): boolean | UrlTree {
    const user = this._authService.getUser();
    if (user && user.isAdmin) {
      return true;
    }
    // return this._router.parseUrl('/gallery');
    return false;
  }
}
