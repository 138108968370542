import { Component, OnInit, EventEmitter, Output, Renderer2, AfterContentInit, Input, ViewEncapsulation } from '@angular/core';
import _ from 'lodash';
import { SmallClusterService } from 'src/app/services/small-cluster.service';
import { D3EventTimelineService } from 'src/app/services/d3-event-timeline.service';
import { D3CustomShapesService } from 'src/app/services/helper/d3-custom-shapes.service';
import { PlugNPredictRWDService } from './plug-n-predict-rwd.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';

declare const d3: any;

@Component({
	selector: 'app-plug-n-predict-rwd',
	templateUrl: './plug-n-predict-rwd.component.html',
	styleUrls: ['./plug-n-predict-rwd.component.css'],
	providers: [SmallClusterService, PlugNPredictRWDService],
	encapsulation: ViewEncapsulation.None
})
export class PlugNPredictRwdComponent implements OnInit {
	@Output() public onVisibleSectionChange = new EventEmitter<any>();
	@Input() widgetData;
	windowContentHeight: Number = null;
	windowContentWidth: Number = null;
	categories: Array<string> = ["abandonCart", "surgeCall", "portOut", "churn"];
	categoriesColor: Array<string> = ["#faa24c", "#39b2ff", "#a1be00", "#d94785"];
	sectionsHolder = {
		problem: {
			bigCluster: {
				customerData: null,
				settings: null,
				updateCluster: null
			}
		},
		problemToData: {
			firstLinePath: null,
			secondLinePath: null,
			thirdLinePath: null,
			fourthLinePath: null,
			smallCluster: {
				details: [],
				flag: [0, 0, 0, 0],
				updateCluster: null
			}
		},
		data: {
			fifthLinePath: null,
			timeline: {
				details: [],
				flag: [],
				updateTimeline: null
			}
		},
		dataToSolution: {
			sixthLinePath: null,
		},
		solution: {
			dna: {
				details: [],
				flag: [],
				updateDna: null
			}
		},
		solutionToOutcome: {
			seventhLinePath: null,
		},
		outcome: {
			eightLinePath: null,
		}
	};
	commonContainer: any = {
		svg: null,
		parentDivId: "small-problem-cluster-section",
		offset: null,
		pathColor: "#c4c8ce",
		lineGenerator: null,
		scrollLength: null,
		renderStory: null,
		aspectRatio: null,
		windowRequestFrameId: null,
		windowListener: null,
		clusterGroupString: ["first-path-circle", "second-path-circle", "third-path-circle", "fourth-path-circle"],
		clusterTooltipClass: ["one", "two", "three", "four"],
	}

	//Path, PathScales, Domain
	path = {
		problem: {
			first: null,
			second: null,
			third: null,
			fourth: null,
		},
		problemToData: null,
		data: null,
		dataToSolution: null,
		solutionToOutcome: null,
		dna: [],
		outcome: []
	};
	pathScales = {
		problem: {
			first: null,
			second: null,
			third: null,
			fourth: null,
		},
		problemToData: null,
		data: null,
		dataToSolution: null,
		solutionToOutcome: null,
		dna: [],
		outcome: []
	};
	domain = {
		problem: {
			first: null,
			second: null,
			third: null,
			fourth: null,
		},
		problemToData: null,
		data: null,
		dataToSolution: null,
		solutionToOutcome: null,
		dna: null,
		outcome: []
	};
	private _lastHeight: number ;

	constructor(
		private renderer: Renderer2,
		private smallClusterService: SmallClusterService,
		private timelineService: D3EventTimelineService,
		private shapesService: D3CustomShapesService,
		private plugNPredictRWDService: PlugNPredictRWDService,
        private _gaService: GoogleAnalyticsService,
		private router: Router,
	) { }
	
	ngAfterViewChecked (): void {
        const scrollWindowHeight = document.getElementById('scroll-container').offsetHeight;
        if (this._lastHeight !== scrollWindowHeight) {
            this._lastHeight = scrollWindowHeight;
            setTimeout(() => {
                this.initializeScales();
            }, 500);
        }
	}
	
	setStoryConfiguration = () => {
		this.windowContentWidth = 1280;
		this.commonContainer.offset = Math.floor(Number(this.windowContentWidth) / 10);
	}

	ngOnInit() {
		this.windowContentHeight = window.innerHeight;
		this.setStoryConfiguration();
		this.renderStoryVisualization();
		this.renderStory();
	}

	ngAfterViewInit() {
		this.commonContainer.svg
			.call(this.responsivefy.bind(this))
	}

	resetPathData() {
		this.path.dna = [];
		this.pathScales.dna = [];
	}

	initializeScales() {
		let HEIGHT = window.innerHeight;
		let content = d3.select("#content_wrapper"),
			header = d3.select("#header");
		this.commonContainer.scrollLength = content.node().getBoundingClientRect().height - (HEIGHT - header.node().getBoundingClientRect().height);

		this.resetPathData();
		let tempObject = null, domain = null;


		let scaleValue = (scrollValue) => {
			let { scrollLength, aspectRatio } = this.commonContainer;
			return (scrollLength * scrollValue) / aspectRatio
		}

		//Get and Set First Path
		let tempPath = this.commonContainer.svg.select("#first-path");
		this.path.problem.first = tempPath;
		domain = {
			"start": scaleValue(0.018),
			"end": scaleValue(0.035)
		};
		this.domain.problem.first = domain;
		this.pathScales.problem.first = this.setPathScale(this.path.problem.first, domain);

		//Get and Set Second Path		
		tempPath = this.commonContainer.svg.select("#second-path");
		this.path.problem.second = tempPath;
		domain = {
			"start": scaleValue(0.035),
			"end": scaleValue(0.05)
		};
		this.domain.problem.second = domain;
		this.pathScales.problem.second = this.setPathScale(this.path.problem.second, domain);

		//Get and Set Third Path		
		tempPath = this.commonContainer.svg.select("#third-path");
		this.path.problem.third = tempPath;
		domain = {
			"start": scaleValue(0.05),
			"end": scaleValue(0.076)
		};
		this.domain.problem.third = domain;
		this.pathScales.problem.third = this.setPathScale(this.path.problem.third, domain);

		//Get and Set Fourth Path		
		tempPath = this.commonContainer.svg.select("#fourth-path");
		this.path.problem.fourth = tempPath;
		domain = {
			"start": scaleValue(0.076),
			"end": scaleValue(0.09)
		};
		this.domain.problem.fourth = domain;
		this.pathScales.problem.fourth = this.setPathScale(this.path.problem.fourth, domain);

		//Get and Set Fifth Path -> (Problem to Data)
		this.path.problemToData = [], this.pathScales.problemToData = [];
		domain = {
			"start": scaleValue(0.09),
			"end": scaleValue(0.136)
		};
		this.domain.problemToData = domain;
		for (let index = 0; index < this.categories.length; index++) {
			tempPath = this.commonContainer.svg.select("#fifth-path-" + index);
			this.path.problemToData[index] = tempPath;
			this.pathScales.problemToData[index] = this.setPathScale(this.path.problemToData[index], domain);
		}

		this.path.data = [], this.pathScales.data = [];
		domain = {
			"start": scaleValue(0.136),
			"end": scaleValue(0.144)
		};
		this.domain.data = domain;
		let timelineDomain = d3.scale.linear().domain([domain.start, domain.end]);
		for (let index = 0; index < this.categories.length; index++) {
			this.pathScales.data[index] = (range) => timelineDomain.range([range.start, range.end]).clamp(true);
			// console.log(this.pathScales.data[index])
			// console.log("this.pathScales.data[index]: ", domain.start, domain.end, this.pathScales.data[index](127)(1470));
		}

		//Get and Set Sixth Path -> (Data to Solution)
		this.path.dataToSolution = [], this.pathScales.dataToSolution = [];
		domain = {
			"start": scaleValue(0.144),
			"end": scaleValue(0.2)
		};
		this.domain.dataToSolution = domain;
		for (let index = 0; index < this.categories.length; index++) {
			tempPath = this.commonContainer.svg.select("#sixth-path-" + index);
			this.path.dataToSolution[index] = tempPath;
			this.pathScales.dataToSolution[index] = this.setPathScale(this.path.dataToSolution[index], domain);
		}

		//Get Dna Path
		for (let index = 0; index < 4; index++) {
			tempObject = {
				"left": this.commonContainer.svg.select("#dna-arc-left-" + index),
				"right": this.commonContainer.svg.select("#dna-arc-right-" + index)
			}
			this.path.dna.push(tempObject);
		}

		//Set Scale Path
		let leftPath = null, rightPath = null;
		tempObject = null;
		domain = {
			"start": scaleValue(0.199),
			"end": scaleValue(0.255),
		};
		this.domain.dna = domain;
		for (let index = 0; index < 4; index++) {
			leftPath = this.path.dna[index].left;
			rightPath = this.path.dna[index].left;
			tempObject = {
				left: this.setPathScale(leftPath, domain),
				right: this.setPathScale(rightPath, domain)
			};
			this.pathScales.dna.push(tempObject);
		}

		//Get and Set Seventh Path -> (Solution To Outcome-1)
		this.path.solutionToOutcome = [], this.pathScales.solutionToOutcome = [];
		domain = {
			"start": scaleValue(0.255),
			"end": scaleValue(0.28)
		};
		this.domain.solutionToOutcome = domain;
		for (let index = 0; index < this.categories.length; index++) {
			tempPath = this.commonContainer.svg.select("#seventh-path-" + index);
			this.path.solutionToOutcome[index] = tempPath;
			this.pathScales.solutionToOutcome[index] = this.setPathScale(this.path.solutionToOutcome[index], domain);
		}

		//Get and Set Outcome Section
		this.path.outcome = [], this.pathScales.outcome = [];
		domain = {
			"start": scaleValue(0.28),
			"end": scaleValue(0.3)
		};
		this.domain.outcome = domain;
		for (let index = 0; index < this.categories.length; index++) {
			tempPath = this.commonContainer.svg.select("#eight-path-" + index);
			this.path.outcome[index] = tempPath;
			this.pathScales.outcome[index] = this.setPathScale(this.path.outcome[index], domain);
		}
	}

	setPathScale(path, domain) {
		return d3.scale.linear()
			.domain([domain.start, domain.end])
			.range([0, path.node().getTotalLength()])
			.clamp(true);
	}

	initializeSvgScales() {
		this.commonContainer.xScale = d3.scale.linear().domain([0, this.commonContainer.offset]).range([0, this.windowContentWidth]);
		this.commonContainer.lineGenerator = d3.svg.line()
			.x((d, i) => {
				return this.commonContainer.xScale(d.xCoordinate);
			})
			.y(function (d) {
				return d.yCoordinate;
			});
	}

	renderStory() {
		//Initializing Scroll Values
		let mainContainer = d3.select('#scroll-container');
		let _scrollTop = 0;
		let _newScrollTop = 0;

		mainContainer.on("scroll.scroller", function () {
			_newScrollTop = mainContainer.node().scrollTop;
		});

		//Render the Scrol Event -> Monitor the Scroll Value
		const render = () => {
			if (_scrollTop !== _newScrollTop) {
				_scrollTop = _newScrollTop;
				this.sectionsHolder.problemToData.firstLinePath.updateFirstLinePathLength(_scrollTop);
				this.sectionsHolder.problemToData.secondLinePath.updateSecondLinePathLength(_scrollTop);
				this.sectionsHolder.problemToData.thirdLinePath.updateThirdLinePathLength(_scrollTop);
				this.sectionsHolder.problemToData.fourthLinePath.updateFourthLinePathLength(_scrollTop);
				this.sectionsHolder.data.fifthLinePath.updateFifthLinePath(_scrollTop);
				this.sectionsHolder.data.timeline.updateTimeline.updateTimelineVisual(_scrollTop);
				this.sectionsHolder.dataToSolution.sixthLinePath.updateSixthLinePath(_scrollTop);
				this.sectionsHolder.solution.dna.updateDna.animateDnaLinePath(_scrollTop);
				this.sectionsHolder.solutionToOutcome.seventhLinePath.updateSeventhLinePath(_scrollTop);
				this.sectionsHolder.outcome.eightLinePath.updateEightLinePath(_scrollTop);
			}
			if (this.commonContainer.windowRequestFrameId) {
				window.cancelAnimationFrame(this.commonContainer.windowRequestFrameId);
			}
			this.commonContainer.windowRequestFrameId = window.requestAnimationFrame(render);
		}
		window.requestAnimationFrame(render);
	}

	renderStoryVisualization = () => {
		let { problem, problemToData, data, dataToSolution, solution, solutionToOutcome, outcome } = this.sectionsHolder;

		this.commonContainer.windowListener = this.renderer.listen(window, 'resize', _.debounce(this.initializeScales.bind(this), 700));
		this.initializeSvgScales();
		this.initializeSvg();
		problem.bigCluster.updateCluster = this.initializeCircleCluster()
		problemToData.firstLinePath = this.renderFirstLinePath();
		problemToData.secondLinePath = this.renderSecondLinePath();
		problemToData.thirdLinePath = this.renderThirdLinePath();
		problemToData.fourthLinePath = this.renderFourthLinePath();
		problemToData.smallCluster.updateCluster = this.renderSmallProblemCluster();
		data.fifthLinePath = this.renderFifthLinePath();
		data.timeline.updateTimeline = this.renderTimeline();
		dataToSolution.sixthLinePath = this.renderSixthLinePath();
		solution.dna.updateDna = this.renderDna();
		solutionToOutcome.seventhLinePath = this.renderSeventhLinePath();
		outcome.eightLinePath = this.renderEightLinePath();

		this.getUpdatedSvgHeight();
	}

	getUpdatedSvgHeight() {
		let eightEndPoints = this.sectionsHolder.outcome.eightLinePath.getEightLineEndPoints();
		let svgHeight = eightEndPoints[1].yCoordinate;

		this.commonContainer.svg.attr({
			"height": svgHeight + 300
		});
	}

	initializeSvg() {
		let svgHeight = 0;
		this.commonContainer.svg = d3.select("#" + this.commonContainer.parentDivId).append("svg")
			.attr({
				"width": this.windowContentWidth,
				"height": svgHeight,
				"id": "small-problem-cluster-section-master-svg",
				"class": "small-problem-cluster-section-master-svg"
			});
	}

	updateClusterLineShapesAnimation(linePath, pathScale, index, domain, _scrollTop) {
		let { clusterGroupString } = this.commonContainer;
		let movingCircles = this.commonContainer.svg.selectAll("." + clusterGroupString[index]);
		movingCircles.each((value, key) => {
			this.shapePathTween(linePath, pathScale, index, key, clusterGroupString[index], "many", domain, _scrollTop);
		})
	}

	shapePathTween(linePath, pathScale, index, key, idString, distanceType, domain, _scrollTop) {
		let length = linePath.node().getTotalLength(); // Get the length of the path            
		let r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
		let distanceOffset = distanceType == "many" ? (key) : 1
		let diffFactorForEachShapes = (pathScale(_scrollTop) == length) ? 0 : (distanceOffset * 40);
		let divideAdjFactor = length;

		let point = linePath.node().getPointAtLength(r(((pathScale(_scrollTop) - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path

		if (pathScale(_scrollTop) < 2) {
			this.commonContainer.svg.select("#" + idString + "-" + index + "-" + key)
				.attr("fill-opacity", 0)
		} else {
			this.commonContainer.svg.select("#" + idString + "-" + index + "-" + key)
				.attr("cx", point.x)
				.attr("cy", point.y)
				.attr("fill-opacity", (length - pathScale(_scrollTop)) == 0 ? 0 : 1);
		}
	}

	tansformShape(linePath, pathScale, index, key, idString, distanceType, _scrollTop) {
		let length = linePath.node().getTotalLength(); // Get the length of the path            
		let r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
		let distanceOffset = distanceType == "many" ? (key) : 0
		let diffFactorForEachShapes = (pathScale(_scrollTop) == length) ? 0 : (distanceOffset * 40);
		let divideAdjFactor = length;

		let point = linePath.node().getPointAtLength(r(((pathScale(_scrollTop) - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path

		if (pathScale(_scrollTop) < 10) {
			this.commonContainer.svg.select("#" + idString + "-" + index + "-" + key)
				.attr("fill-opacity", 0)
		} else {
			this.commonContainer.svg.select("#" + idString + "-" + index + "-" + key)
				.attr({
					transform: "translate(" + point.x + "," + point.y + ")",
					"fill-opacity": (length - pathScale(_scrollTop)) < 5 ? 0 : 1
				});
		}
	}

	createPath(coordinate, classString, idString, color) {
		this.commonContainer.svg.append("path")
			.attr({
				'd': this.commonContainer.lineGenerator(coordinate),
				"class": classString,
				"id": idString
			})
			.style({
				"stroke": color,
				"fill": "none",
				'stroke-dasharray': function (d) {
					var l = d3.select(this).node().getTotalLength();
					return l + 'px, ' + l + 'px';
				},
				'stroke-dashoffset': function (d) {
					return d3.select(this).node().getTotalLength() + 'px';
				}
			});
	}

	renderFirstLinePath() {
		let { offset, pathColor, clusterGroupString } = this.commonContainer,
			startingPoints,
			line1 = [
				{
					xCoordinate: offset * 0.195,
					yCoordinate: 450
				}, {
					xCoordinate: offset * 0.195,
					yCoordinate: 600
				}
			],
			firstPathCircleColor = ["#aaafb5", "#aaafb5", "#aaafb5", "#aaafb5"],
			index = 0,
			firstFlag = false,
			toggleOpacity = false;

		let createFirstPath = () => {
			let lineClassName = "first-path", idName;
			idName = lineClassName;
			this.createPath(line1, lineClassName, idName, pathColor);
		}
		createFirstPath();

		startingPoints = line1[0];
		this.createPathCircle(startingPoints, index, clusterGroupString[index], firstPathCircleColor);

		let updateFirstLinePathLength = (_scrollTop) => {
			let path = this.path.problem.first,
				pathScale = this.pathScales.problem.first;

			let { updateOpacityOfAllCircles, updateSmallClusterForce, updateClusterTooltip } = this.sectionsHolder.problemToData.smallCluster.updateCluster;

			let pathTotalLength = path.node().getTotalLength();

			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.updateClusterLineShapesAnimation(path, pathScale, index, this.domain.problem.first, _scrollTop);

			if (!firstFlag && pathScale(_scrollTop) == pathTotalLength) {
				updateOpacityOfAllCircles(index, 1);
				updateSmallClusterForce(index, "forward");
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], true, index);
				firstFlag = true;
			} else if (firstFlag && pathScale(_scrollTop) < pathTotalLength) {
				updateSmallClusterForce(index, "backward");
				updateOpacityOfAllCircles(index, 0);
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], false, index);
				firstFlag = false;
			}

			if (!toggleOpacity && pathScale(_scrollTop) > 0) {
				this.sectionsHolder.problem.bigCluster.updateCluster.toggleBigCircleOpacity(1, 0);
				toggleOpacity = true;
			} else if (toggleOpacity && pathScale(_scrollTop) == 0) {
				this.sectionsHolder.problem.bigCluster.updateCluster.toggleBigCircleOpacity(0, 1);
				toggleOpacity = false;
			}
		}

		return {
			updateFirstLinePathLength
		}
	}

	renderSecondLinePath() {
		let { offset, pathColor, clusterGroupString } = this.commonContainer,
			startingPoints,
			line2 = [
				{
					xCoordinate: offset * 0.195,
					yCoordinate: 765
				}, {
					xCoordinate: offset * 0.195,
					yCoordinate: 900
				}, {
					xCoordinate: offset * 0.33,
					yCoordinate: 900
				}
			],
			secondPathCircleColor = ["#faa24c", "#faa24c", "#aaafb5", "#aaafb5"],
			index = 1,
			secondFlag = false;

		let createSecondPath = () => {
			let lineClassName = "second-path", idName;
			idName = lineClassName;
			this.createPath(line2, lineClassName, idName, pathColor);
		}
		createSecondPath();
		startingPoints = line2[0];
		this.createPathCircle(startingPoints, index, clusterGroupString[index], secondPathCircleColor);

		let updateSecondLinePathLength = (_scrollTop) => {
			let path = this.path.problem.second,
				pathScale = this.pathScales.problem.second;

			let { updateOpacityOfAllCircles, updateSmallClusterForce, updateClusterTooltip } = this.sectionsHolder.problemToData.smallCluster.updateCluster;

			let pathTotalLength = path.node().getTotalLength();
			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.updateClusterLineShapesAnimation(path, pathScale, index, this.domain.problem.second, _scrollTop);

			if (!secondFlag && pathScale(_scrollTop) == pathTotalLength) {
				updateOpacityOfAllCircles(index, 1);
				updateSmallClusterForce(index, "forward");
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], true, index);
				secondFlag = true;
			} else if (secondFlag && pathScale(_scrollTop) < pathTotalLength) {
				updateSmallClusterForce(index, "backward");
				updateOpacityOfAllCircles(index, 0);
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], false, index);
				secondFlag = false;
			}
		}

		return {
			updateSecondLinePathLength
		}
	}

	renderThirdLinePath() {
		let { offset, pathColor, clusterGroupString } = this.commonContainer,
			startingPoints,
			line3 = [
				{
					xCoordinate: offset * 0.46,
					yCoordinate: 900
				}, {
					xCoordinate: offset * 0.64,
					yCoordinate: 900
				}, {
					xCoordinate: offset * 0.64,
					yCoordinate: 1020
				}
			],
			thirdPathCircleColor = ["#39b2ff", "#39b2ff", "#faa24c", "#aaafb5"],
			index = 2,
			thirdFlag = false;

		let createThirdPath = () => {
			let lineClassName = "third-path", idName;
			idName = lineClassName;
			this.createPath(line3, lineClassName, idName, pathColor);
		}
		createThirdPath();

		startingPoints = line3[0];
		this.createPathCircle(startingPoints, index, clusterGroupString[index], thirdPathCircleColor);

		let updateThirdLinePathLength = (_scrollTop) => {
			let path = this.path.problem.third,
				pathScale = this.pathScales.problem.third,
				{ updateOpacityOfAllCircles, updateSmallClusterForce, updateClusterTooltip } = this.sectionsHolder.problemToData.smallCluster.updateCluster,
				pathTotalLength = path.node().getTotalLength();

			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.updateClusterLineShapesAnimation(path, pathScale, index, this.domain.problem.third, _scrollTop);

			if (!thirdFlag && pathScale(_scrollTop) == pathTotalLength) {
				updateOpacityOfAllCircles(index, 1);
				updateSmallClusterForce(index, "forward");
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], true, index);
				thirdFlag = true;
			} else if (thirdFlag && pathScale(_scrollTop) < pathTotalLength) {
				updateSmallClusterForce(index, "backward");
				updateOpacityOfAllCircles(index, 0);
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], false, index);
				thirdFlag = false;
			}
		}

		return {
			updateThirdLinePathLength
		}
	}

	renderFourthLinePath() {
		let { offset, pathColor, clusterGroupString } = this.commonContainer,
			startingPoints,
			line4 = [
				{
					xCoordinate: offset * 0.71,
					yCoordinate: 1100
				}, {
					xCoordinate: offset * 0.89,
					yCoordinate: 1100
				}, {
					xCoordinate: offset * 0.89,
					yCoordinate: 1220
				}
			],
			fourthPathCircleColor = ["#a1be00", "#39b2ff", "#faa24c", "#aaafb5"],
			index = 3,
			fourthFlag = false;

		let createFourthPath = () => {
			let lineClassName = "fourth-path", idName;
			idName = lineClassName;
			this.createPath(line4, lineClassName, idName, pathColor);
		}
		createFourthPath();

		startingPoints = line4[0];
		this.createPathCircle(startingPoints, index, clusterGroupString[index], fourthPathCircleColor);

		let updateFourthLinePathLength = (_scrollTop) => {
			let path = this.path.problem.fourth,
				pathScale = this.pathScales.problem.fourth,
				{ updateOpacityOfAllCircles, updateSmallClusterForce, updateClusterTooltip } = this.sectionsHolder.problemToData.smallCluster.updateCluster,
				pathTotalLength = path.node().getTotalLength();
			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.updateClusterLineShapesAnimation(path, pathScale, index, this.domain.problem.fourth, _scrollTop);

			if (!fourthFlag && pathScale(_scrollTop) == pathTotalLength) {
				updateOpacityOfAllCircles(index, 1);
				updateSmallClusterForce(index, "forward");
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], true, index);
				fourthFlag = true;
			} else if (fourthFlag && pathScale(_scrollTop) < pathTotalLength) {
				updateSmallClusterForce(index, "backward");
				updateOpacityOfAllCircles(index, 0);
				updateClusterTooltip(this.commonContainer.clusterTooltipClass[index], false, index);
				fourthFlag = false;
			}
		}

		return {
			updateFourthLinePathLength
		}
	}

	renderFifthLinePath() {
		let { offset, pathColor } = this.commonContainer,
			startingPoints,
			line5_1 = [
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1390
				},
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1450
				},
				{
					xCoordinate: offset * 0.05,
					yCoordinate: 1450
				}, {
					xCoordinate: offset * 0.05,
					yCoordinate: 1800
				}, {
					xCoordinate: offset * 0.20,
					yCoordinate: 1800
				},],
			line5_2 = [
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1390
				},
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1450
				},
				{
					xCoordinate: offset * 0.05,
					yCoordinate: 1450
				}, {
					xCoordinate: offset * 0.05,
					yCoordinate: 1900
				}, {
					xCoordinate: offset * 0.20,
					yCoordinate: 1900
				},],
			line5_3 = [
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1390
				},
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1450
				},
				{
					xCoordinate: offset * 0.05,
					yCoordinate: 1450
				}, {
					xCoordinate: offset * 0.05,
					yCoordinate: 2000
				}, {
					xCoordinate: offset * 0.20,
					yCoordinate: 2000
				},],

			line5_4 = [
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1390
				},
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 1450
				},
				{
					xCoordinate: offset * 0.05,
					yCoordinate: 1450
				}, {
					xCoordinate: offset * 0.05,
					yCoordinate: 2100
				}, {
					xCoordinate: offset * 0.20,
					yCoordinate: 2100
				},],
			fifthPathCircleColor = ["#d94785", "#a1be00", "#39b2ff", "#faa24c"],
			index = 4,
			fifthFlag = [0, 0, 0, 0];

		let line5 = [
			line5_1,
			line5_2,
			line5_3,
			line5_4
		]
		let createFifthPath = () => {
			for (let index = 0; index < line5.length; index++) {
				let lineClassName = "fifth-path", idName;
				idName = lineClassName + "-" + index;
				this.createPath(line5[index], lineClassName, idName, pathColor);
			}
		}
		createFifthPath();

		startingPoints = line5_1[0];
		this.createPathCircle(startingPoints, index, "fifth-path-circle", fifthPathCircleColor);

		let updateFifthLinePath = (_scrollTop) => {
			let path = this.path.problemToData,
				pathScale = this.pathScales.problemToData;

			for (let index = 0; index < 4; index++) {
				updateFifthLinePathLength(path[index], pathScale[index], index, _scrollTop)
			}
		}

		let updateFifthLinePathLength = (path, pathScale, index, _scrollTop) => {
			let pathTotalLength = path.node().getTotalLength();
			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.shapePathTween(path, pathScale, 4, index, "fifth-path-circle", "single", this.domain.problemToData, _scrollTop);
		}

		return {
			updateFifthLinePath
		}
	}

	renderTimeline() {
		let translate = null,
			translateX = this.commonContainer.xScale(this.commonContainer.offset * 0.20) - 20,
			parseDate = d3.time.format("%m/%d/%Y").parse,
			years = ['1/1/2006', '1/1/2007', '1/1/2008', '1/1/2009', '1/1/2010', '1/1/2011', '1/1/2012', '1/1/2013', '1/1/2014', '1/1/2015', '1/1/2016', '1/1/2017'],
			timelineData = [],
			width,
			height,
			timelineColors = this.categoriesColor.slice().reverse(),
			shapePosition = [];

		width = this.commonContainer.xScale(this.commonContainer.offset * 0.65);
		height = 1750;

		translate = {
			x: translateX,
			y: height
		};

		let displayeMessageAt =
			[
				[1, 5, 9],
				[],
				[],
				[4, 2, 9]
			],
			timelineIndex = [0, -1, -1, 3],
			shapeSize = 750,
			timelineDetails = null;

		let createTimeline = (index, width, height, translate, data) => {
			let { details, flag } = this.sectionsHolder.data.timeline;
			details[index] = {
				svg: this.commonContainer.svg,
				index,
				width,
				height,
				data,
				translate,
				categoriesColor: this.categoriesColor,
				tooltipDivId: this.commonContainer.parentDivId
			};
			flag[index] = 1;
		}

		for (let index = 0; index < 4; index++) {
			translate = {
				x: translateX,
				y: height + (index * 100)
			};
			timelineDetails = {
				shapeTextMessage: ["Dx", "Px", "Rx"],
				toolTipMessage: ["Diagnosis", "Prescription", "Prognosis"],
				displayeMessageAt: displayeMessageAt[index],
				timelineIndex: timelineIndex[index],
				shapeSize,
				shapeTextColor: "#FFFFFF"
			}
			this.timelineService.createData(index, ["circle"], this.categoriesColor, timelineDetails);
			timelineData[index] = this.timelineService.getData(index);
			createTimeline(index, width, height, translate, timelineData[index]);
		}

		function timeLineAnimation(path, index, direction) {
			let pathTotalLength = path.node().getTotalLength();
			direction = direction || "forward";
			let startStrokeDashoffset = direction == "forward" ? pathTotalLength : 0;
			let endStrokeDashoffset = direction == "forward" ? 0 : pathTotalLength;

			path
				.style({
					"stroke": timelineColors[index]
				})
				.attr({
					"stroke-dasharray": pathTotalLength + " " + pathTotalLength,
					"stroke-dashoffset": startStrokeDashoffset
				})
				.transition()
				.duration(400)
				.ease("linear")
				.attr("stroke-dashoffset", endStrokeDashoffset); // Set to pathTotalLength for reverse animation 
		}

		let circleXPosition = [[], [], [], []];

		let showTimelineActivityTooltip = (index, key,  visibilityFlag = true) => {
			const { aspectRatio } = this.commonContainer;
			let div = d3.select('#timeline-tooltip-' + index + "-" + key),
				shape = d3.select('#date_timeline_shapes_' + index + "_" + key),
				shapeBoundingRect = shape.node().getBoundingClientRect(),
				rect = this.commonContainer.svg.select("#fifth-path-" + index).node().getBoundingClientRect();

			div.style({
				"left": ((shapeBoundingRect.left + shapeBoundingRect.width / 2) - (div.node().offsetWidth / 2)) + "px",
				"top": ((rect.height * (index == 0 ? 1.302 : 0.882)) / aspectRatio) + "px",
			});
			if (visibilityFlag) {
                div.style("visibility", "visible");
            }
		}

		let hideTimelineActivityTooltip = (index, key) => {
			let div = d3.select('#timeline-tooltip-' + index + "-" + key);
			div.style({
				"visibility": "hidden"
			});
		}

		let showTimelineShapeText = (index, data, key) => {
			let div = d3.select('#timeline-node-text-' + index + "-" + key);
			div
				.attr({
					"x": circleXPosition[index][key] - (data.shapeText.length * 4),
					"y": () => {
						let y = div.attr("y");
						return Number(y) + 5;
					},
					"font-weight": "bold",
					"font-size": "16px",
					"fill": data.shapeTextColor
				});
		}

		let hideTimelineShapeText = (index, key) => {
			let div = d3.select('#timeline-node-text-' + index + "-" + key);
			div
				.attr({
					"fill": "transparent",
					"y": () => {
						let y = div.attr("y");
						return Number(y) - 5;
					},
				});
		}

		let configureTooltipPosition = () => {
			let index = [0, 3], subIndex = [[0, 4, 8], [3, 1, 8]];

			for (let i = 0; i < index.length; i++) {
				for (let j = 0; j < subIndex[i].length; j++) {
					showTimelineActivityTooltip(index[i], subIndex[i][j], false);
				}
			}
		}
		let range = {
			start: 50,
			end: 0
		}
		let updateTimelineVisual = (_scrollTop) => {
			for (let index = 0; index < 4; index++) {
				configTimelineAnimation(index, _scrollTop);
				updateTimelineShapes(index, _scrollTop)
			}
		}
		let timelineFlag = [0, 0, 0, 0];
		let configTimelineAnimation = (index, _scrollTop) => {
			let centerPath = this.commonContainer.svg.select("#date_timeline_center_path_" + index);
			let length = centerPath.node().getTotalLength();
			range.end = length;

			if (this.pathScales.data[index](range)(_scrollTop) > range.start && !timelineFlag[index]) {
				timeLineAnimation(this.commonContainer.svg.select("#date_timeline_" + index), index, "forward");
				timelineFlag[index] = 1;
			} else if (this.pathScales.data[index](range)(_scrollTop) == range.start && timelineFlag[index]) {
				timeLineAnimation(this.commonContainer.svg.select("#date_timeline_" + index), index, "backward");
				timelineFlag[index] = 0;
			}
		}

		let updateTimelineShapes = (index, _scrollTop) => {
			if (!shapePosition[index]) {
				shapePosition[index] = [];
				if (shapePosition[index].length == 0) {
					shapePosition[index] = this.timelineService.getShapePosition();
				}
			}
			let timelineShapes = this.commonContainer.svg.selectAll(".date_timeline_shapes_" + index);
			timelineShapes.each((d, key) => {
				updateTimelineContentPosition(index, key, d, _scrollTop)
			});
		}

		let updateTimelineContentPosition = (index, key, data, _scrollTop) => {
			let shape = this.commonContainer.svg.select("#date_timeline_shapes_" + index + "_" + key);
			range.end = shapePosition[index][key];
			let opacityRange = {
				start: 0,
				end: 1
			}
			let getXPosition = this.pathScales.data[index](range)(_scrollTop);
			let string = shape.attr("transform"),
				translate = string.substring(string.indexOf("(") + 1, string.indexOf(")")).split(","),
				translateShape = {
					x: getXPosition,
					y: Number(translate[1])
				}

			shape
				.attr("transform", "translate(" + translateShape.x + ", " + translateShape.y + ")")
				.style('fill-opacity', this.pathScales.data[index](opacityRange)(_scrollTop))

			//Node Text and Tooltip Manipulation
			if (getXPosition == shapePosition[index][key]) {
				if (data.showTooltip && !data.isTooltipDisplayed) {
					data.isTooltipDisplayed = true;
					showTimelineActivityTooltip(index, key);
				}
				if (data.shapeText && !data.isNodeTextDisplayed) {
					circleXPosition[index][key] = getXPosition;
					data.isNodeTextDisplayed = true;
					showTimelineShapeText(index, data, key);
				}
			} else if (getXPosition != shapePosition[index][key]) {
				if (data.isTooltipDisplayed) {
					data.isTooltipDisplayed = false;
					hideTimelineActivityTooltip(index, key);
				}
				if (data.isNodeTextDisplayed) {
					data.isNodeTextDisplayed = false;
					hideTimelineShapeText(index, key);
				}
			}
		}

		return {
			configureTooltipPosition,
			updateTimelineVisual
		}
	}

	renderSixthLinePath() {
		let { offset, pathColor } = this.commonContainer,
			sixthLineEndPoints,
			sixthLineStartPoints,
			line6_1 = [
				{
					xCoordinate: offset * 0.82,
					yCoordinate: 1800
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 1800
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 2560
				},
				{
					xCoordinate: offset * 0.17,
					yCoordinate: 2560
				}, {
					xCoordinate: offset * 0.17,
					yCoordinate: 2650
				},],
			line6_2 = [
				{
					xCoordinate: offset * 0.82,
					yCoordinate: 1900
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 1900
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 2560
				},
				{
					xCoordinate: offset * 0.39,
					yCoordinate: 2560
				}, {
					xCoordinate: offset * 0.39,
					yCoordinate: 2650
				},],
			line6_3 = [
				{
					xCoordinate: offset * 0.82,
					yCoordinate: 2000
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 2000
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 2560
				},
				{
					xCoordinate: offset * 0.61,
					yCoordinate: 2560
				},
				{
					xCoordinate: offset * 0.61,
					yCoordinate: 2650
				},],
			line6_4 = [
				{
					xCoordinate: offset * 0.82,
					yCoordinate: 2100
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 2100
				}, {
					xCoordinate: offset * 0.95,
					yCoordinate: 2560
				}, {
					xCoordinate: offset * 0.83,
					yCoordinate: 2560
				},
				{
					xCoordinate: offset * 0.83,
					yCoordinate: 2650
				},],
			sixthPathCircleColor = ["#d94785", "#a1be00", "#39b2ff", "#faa24c"],
			sixthPathShape = ["square", "square", "square", "square"];

		let line6 = [
			line6_1,
			line6_2,
			line6_3,
			line6_4
		];

		let createSixthPath = () => {
			for (let index = 0; index < line6.length; index++) {
				let lineClassName = "sixth-path", idName;
				idName = lineClassName + "-" + index;
				this.createPath(line6[index], lineClassName, idName, pathColor);
			}
		}
		createSixthPath();

		sixthLineStartPoints = [
			line6_1[0],
			line6_2[0],
			line6_3[0],
			line6_4[0]
		];

		this.createShapes(sixthLineStartPoints, 5, "sixth-path-circle", sixthPathCircleColor, sixthPathShape, 150);

		let getSixthLineEndPoints = () => {
			sixthLineEndPoints = [
				line6_1[line6_1.length - 1],
				line6_2[line6_2.length - 1],
				line6_3[line6_3.length - 1],
				line6_4[line6_4.length - 1],
			];
			return sixthLineEndPoints;
		}

		let updateSixthLinePath = (_scrollTop) => {
			let path = this.path.dataToSolution,
				pathScale = this.pathScales.dataToSolution;

			for (let index = 0; index < 4; index++) {
				updateSixthLinePathLength(path[index], pathScale[index], index, _scrollTop)
			}
		}

		let updateSixthLinePathLength = (path, pathScale, index, _scrollTop) => {
			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.tansformShape(path, pathScale, 5, index, "sixth-path-circle", "single", _scrollTop);
		}

		return {
			getSixthLineEndPoints,
			updateSixthLinePath
		}
	}

	renderDna() {
		let dnaIdString = "dna-arc",
			width,
			height,
			translate,
			dnaTranslate = [],
			dnaArc = {
				left: {
					side: "left",
					blockShapeString: "left-shapes-",
					shapeString: "dna-left-moving-shapes-"
				},
				right: {
					side: "right",
					blockShapeString: "right-shapes-",
					shapeString: "dna-right-moving-shapes-"
				}
			};

		dnaTranslate = this.sectionsHolder.dataToSolution.sixthLinePath.getSixthLineEndPoints();

		let createDna = (index, width, height, translate, idString) => {
			let { details, flag } = this.sectionsHolder.solution.dna;
			details[index] = {
				svg: this.commonContainer.svg,
				container: null,
				width: width,
				height: height,
				index: index,
				data: [],
				categories: this.categories.slice().reverse(),
				categoriesColor: this.categoriesColor.slice().reverse(),
				shapes: ["hexagoan", "square", "triangleDown", "star"].reverse(),
				translate: translate,
				idString: idString
			};
			flag[index] = 1;
		}

		//function to create Multiple Dna
		for (let index = 0; index < 4; index++) {
			width = 150,
				height = 650,
				translate = {
					x: this.commonContainer.xScale(dnaTranslate[index].xCoordinate) - (width / 2),
					y: dnaTranslate[index].yCoordinate - 40
				},
				createDna(index, width, height, translate, dnaIdString);
		}

		let animateDnaLinePath = (_scrollTop) => {
			for (let secondIndex = 0; secondIndex < 4; secondIndex++) {
				updateDnaArcPathLength(this.path.dna[secondIndex].left, this.pathScales.dna[secondIndex].left, secondIndex, dnaArc.left.side, _scrollTop);
				updateDnaArcPathLength(this.path.dna[secondIndex].right, this.pathScales.dna[secondIndex].right, secondIndex, dnaArc.right.side, _scrollTop);
			}
		}

		let updateDnaArcPathLength = (path, pathScale, index, arcSide, _scrollTop) => {
			path
				.style('stroke-dashoffset', function (d) {
					updateDnaShapesAnimation(path, pathScale, index, arcSide, _scrollTop);
					if (pathScale(_scrollTop) <= 10) {
						hideShapes(arcSide, index);
					}
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
		}

		let updateDnaShapesAnimation = (path, pathScale, index, arcSide, _scrollTop) => {
			let movingShapes = this.commonContainer.svg.selectAll("." + dnaArc[arcSide].blockShapeString + index);
			movingShapes
				.attr("fill-opacity", 1)
				.each(function (d, key) {
					dnaShapesPathTween(path, pathScale, index, key, arcSide, _scrollTop);
				});
		}

		let dnaShapesPathTween = (linePath, pathScale, index, key, arcSide, _scrollTop) => {
			let length = linePath.node().getTotalLength();
			let r = d3.interpolate(0, length);
			let diffFactorForEachShapes = key * 25;
			let divideAdjFactor = length;
			let point = linePath.node().getPointAtLength(r(((pathScale(_scrollTop) - diffFactorForEachShapes)) / divideAdjFactor)); // Get the next point along the path

			this.commonContainer.svg.select("#" + dnaArc[arcSide].shapeString + index + "-" + key)
				.attr("transform", "translate(" + point.x + ", " + point.y + ")")
		}

		let hideShapes = (type, index) => {
			let movingShapes = this.commonContainer.svg.selectAll("." + type + "-shapes-" + index);
			movingShapes
				.attr("fill-opacity", 0);
		}

		return {
			animateDnaLinePath
		}
	}

	renderSeventhLinePath() {
		let { offset, pathColor } = this.commonContainer,
			seventhLineEndPoints,
			seventhLineStartingPoints,
			line7_1 = [
				{
					xCoordinate: offset * 0.17,
					yCoordinate: 3240
				},
				{
					xCoordinate: offset * 0.17,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				}],
			line7_2 = [
				{
					xCoordinate: offset * 0.39,
					yCoordinate: 3240
				},
				{
					xCoordinate: offset * 0.39,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},],
			line7_3 = [
				{
					xCoordinate: offset * 0.61,
					yCoordinate: 3240
				},
				{
					xCoordinate: offset * 0.61,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},
			],
			line7_4 = [
				{
					xCoordinate: offset * 0.83,
					yCoordinate: 3240
				},
				{
					xCoordinate: offset * 0.83,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3350
				},
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},
			],
			seventhPathCircleColor = ["#d94785", "#a1be00", "#39b2ff", "#faa24c"],
			seventhPathShape = ["hexagoan", "square", "triangleDown", "star"],
			eightHeaderFlag = false;

		let line7 = [
			line7_1,
			line7_2,
			line7_3,
			line7_4
		];

		seventhLineEndPoints = [
			line7_1[line7_1.length - 1],
			line7_2[line7_2.length - 1],
			line7_3[line7_3.length - 1],
			line7_4[line7_4.length - 1],
		];

		let createSeventhPath = () => {
			for (let index = 0; index < line7.length; index++) {
				let lineClassName = "seventh-path", idName;
				idName = lineClassName + "-" + index;
				this.createPath(line7[index], lineClassName, idName, pathColor);
			}
		}
		createSeventhPath();

		seventhLineStartingPoints = [
			line7_1[0],
			line7_2[0],
			line7_3[0],
			line7_4[0]
		];

		this.shuffleArray(seventhPathShape);
		this.createShapes(seventhLineStartingPoints, 6, "seventh-path-shapes", seventhPathCircleColor, seventhPathShape, 150);

		let getSeventhLineEndPoints = () => {
			return seventhLineEndPoints;
		}

		let updateSeventhLinePath = (_scrollTop) => {
			let path = this.path.solutionToOutcome,
				pathScale = this.pathScales.solutionToOutcome;
			for (let index = 0; index < 4; index++) {
				updateSeventhLinePathLength(path[index], pathScale[index], index, _scrollTop)
			}
		}

		let updateSeventhLinePathLength = (path, pathScale, index, _scrollTop) => {
			path
				.style('stroke-dashoffset', function (d) {
					return path.node().getTotalLength() - pathScale(_scrollTop) + 'px';
				});
			this.tansformShape(path, pathScale, 6, index, "seventh-path-shapes", "single", _scrollTop);

			if ((path.node().getTotalLength() - pathScale(_scrollTop)) < 80 && !eightHeaderFlag) {
				eightHeaderFlag = true;
				let group = d3.select("#outcome-initial-block")
				group
					.transition()
					.duration(50)
					.ease("linear")
					.style({ opacity: 1 });
			} else if ((path.node().getTotalLength() - pathScale(_scrollTop)) >= 80 && eightHeaderFlag) {
				eightHeaderFlag = false;
				d3.select("#outcome-initial-block")
					.transition()
					.duration(100)
					.ease("linear")
					.style({ opacity: 0 });
			}
		}

		return {
			getSeventhLineEndPoints,
			updateSeventhLinePath
		}
	}

	shuffleArray = (array) => {
		array.sort(() => Math.random() - 0.5);
	}

	renderEightLinePath() {
		let { offset } = this.commonContainer,
			seventhPathEndPoints,
			eightLineEndPoints,
			eightShapePathColor = ["#6bc9d5", "#bfc66a", "#deb76e", "#7389dc"],
			line8_1 = [
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},
				{
					xCoordinate: offset * 0.11,
					yCoordinate: 3650
				},
			],

			line8_2 = [
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},
				{
					xCoordinate: offset * 0.37,
					yCoordinate: 3800
				},
			],

			line8_3 = [
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},
				{
					xCoordinate: offset * 0.63,
					yCoordinate: 3800
				},
			],

			line8_4 = [
				{
					xCoordinate: offset * 0.50,
					yCoordinate: 3550
				},
				{
					xCoordinate: offset * 0.89,
					yCoordinate: 3650
				},
			],
			eightGradientFlag = false,
			eightNodeFlag = [0, 0, 0, 0];

		let line8 = [
			line8_1,
			line8_2,
			line8_3,
			line8_4
		];

		let createEightPath = (colorCode) => {
			for (let index = 0; index < line8.length; index++) {
				this.commonContainer.svg.append("path")
					.attr({
						'd': this.commonContainer.lineGenerator(line8[index]),
						"id": "eight-path-" + (index),
					})
					.style({
						"stroke": colorCode[index],
						"stroke-width": "3px",
						"fill": "none",
						"stroke-dasharray": function (d) {
							var l = d3.select(this).node().getTotalLength();
							return l + 'px, ' + l + 'px';
						},
						"stroke-dashoffset": function (d) {
							return d3.select(this).node().getTotalLength() + 'px';
						}
					});
			}
		}
		createEightPath(eightShapePathColor);

		eightLineEndPoints = [
			line8_1[line8_1.length - 1],
			line8_2[line8_2.length - 1],
			line8_3[line8_3.length - 1],
			line8_4[line8_4.length - 1],
		];

		let outcomeTitleGroup,
			groupHeader,
			headerTextYPosition,
			outcomeInitialText,
			groupHeaderText,
			outcomeNodeBlock,
			nodeTextYPosition,
			nodeTextContent = [
				["15%", "Lift"],
				["20%", "Lift"],
				["10%", "Lift"],
				["15%", "Lift"]],
				// [
				// 	["2.5x", "Lift"],
				// 	["3x", "Lift"],
				// 	["1.8x", "Lift"],
				// 	["1.8x", "Lift"]]
			nodeTextClass = ['outcome-node-count', "outcome-node-text"],
			outcomeNodeSection,
			outcomeInnerNodeSection,
			outcomeNodeTextSvg;

		//Appending Outcome Header Text
		outcomeTitleGroup = this.commonContainer.svg.append('g')
			.attr({
				id: "outcome-title"
			});

		seventhPathEndPoints = this.sectionsHolder.solutionToOutcome.seventhLinePath.getSeventhLineEndPoints();
		let xMidPoint = this.commonContainer.xScale(seventhPathEndPoints[0].xCoordinate);

		outcomeTitleGroup
			.append("rect")
			.attr({
				id: "outcome-title-rect",
				width: 202,
				height: 53,
				x: xMidPoint - (202 / 2),
				y: seventhPathEndPoints[0].yCoordinate - 155 - (53 / 2),
			})
			.style({
				fill: "#FFF"
			});

		outcomeTitleGroup
			.append("text")
			.attr({
				// id: "outcomes",
				x: xMidPoint,
				y: seventhPathEndPoints[0].yCoordinate - 155,
				"text-anchor": "middle",
				"alignment-baseline": "central",
			})
			.text("Outcomes");

		// Outcome initial Circle		
		groupHeader = this.commonContainer.svg.append('g')
			.attr({
				id: "outcome-initial-block"
			})
			.style({ opacity: 0 });

		groupHeader
			.append('circle')
			.attr({
				id: "outcome-initial-cirlce-bg",
				cx: xMidPoint,
				cy: seventhPathEndPoints[0].yCoordinate,
				fill: "#FFFF",
				stroke: "none",
				r: 108
			});

		//Appending Outcome Header Circle
		groupHeader
			.append('circle')
			.attr({
				id: "outcome-initial-cirlce",
				cx: xMidPoint,
				cy: seventhPathEndPoints[0].yCoordinate,
				fill: "#136798",
				stroke: "none",
				r: 95
			});

		//Appending Outcome Header Text		
		// outcomeInitialText = ["4x", "faster feature", "engineering and", "modelling"]
		outcomeInitialText = ["50-70%", "reduction in feature", "engineering and", "modelling time"], headerTextYPosition = seventhPathEndPoints[0].yCoordinate - 35;
		groupHeaderText = groupHeader
			.append('text')
			.attr({
				x: xMidPoint,
				y: headerTextYPosition,
				fill: "#FFF",
				"text-anchor": "middle",
				"alignment-baseline": "central",
			});

		//Appending Outcome Header Tspan in Text SVg
		groupHeaderText
			.selectAll('tspan')
			.data(outcomeInitialText)
			.enter()
			.append("tspan")
			.text((text) => {
				return text
			})
			.attr({
				x: xMidPoint,
				y: (d, key) => {
					let result = key == 0 ? headerTextYPosition : headerTextYPosition + 10;
					return result + ((key) * 25)
				},
				class: (d, key) => {
					let result = key == 0 ? "outcome-header-text outcome-header-title" : "outcome-header-text";
					return result
				}
			});

		//Appending Defs in the svg
		let defs = this.commonContainer.svg.append("defs");
		let gradientSemiCircleDetails = {
			linearGradient: {
				id: "linear-gradient",
				gradientUnits: "objectBoundingBox",
				x2: "1",
				y1: "0.5",
				y2: "0.5",
				stop: {
					offset: ["0", "0.301", "0.72", "1"],
					"stop-color": ["#6fc9d4", "#bfc46e", "#ddb673", "#748bda"]
				}
			},
			path: {
				id: "gradient-half-circle",
				d: 'M155,234a117.893,117.893,0,0,1-23.579-2.377,116.351,116.351,0,0,1-41.836-17.6,117.346,117.346,0,0,1-42.39-51.476,116.457,116.457,0,0,1-6.818-21.962A117.886,117.886,0,0,1,38.038,120h10C49.63,177.346,97.611,224,155,224s105.37-46.654,106.959-104h10a117.788,117.788,0,0,1-2.339,20.58,116.354,116.354,0,0,1-17.6,41.836,117.346,117.346,0,0,1-51.476,42.39,116.43,116.43,0,0,1-21.962,6.818A117.911,117.911,0,0,1,155,234Z',
				// d: "M155,219a98.532,98.532,0,0,1-55.352-16.907A99.3,99.3,0,0,1,63.78,158.535,98.378,98.378,0,0,1,56,120H66a89,89,0,0,0,178,0h10a98.533,98.533,0,0,1-16.907,55.352,99.292,99.292,0,0,1-43.557,35.869A98.377,98.377,0,0,1,155,219Z",
				fill: "url(#linear-gradient)",
				translate: {
					x: -38.038,
					y: -117
				}
			}
		};
		let { id, gradientUnits, x2, y1, y2, stop } = gradientSemiCircleDetails.linearGradient;
		let linearGradient = defs.append("linearGradient")
			.attr({
				id, y1, x2, y2, gradientUnits
			});

		for (let i = 0; i < stop.offset.length; i++) {
			linearGradient.append("stop")
				.attr({
					offset: stop.offset[i],
					"stop-color": stop["stop-color"][i]
				})
		}

		let halfgradientCircle = this.commonContainer.svg.append("g")
			.attr("id", "gradient-half-group");

		halfgradientCircle.append("path")
			.attr({
				id: gradientSemiCircleDetails.path.id,
				d: gradientSemiCircleDetails.path.d,
				fill: gradientSemiCircleDetails.path.fill,
			})

		halfgradientCircle = this.commonContainer.svg.select("#" + gradientSemiCircleDetails.path.id);

		let gradientWidth = halfgradientCircle.node().getBoundingClientRect().width;
		halfgradientCircle
			.attr({
				transform: "translate(" +
					Math.round(xMidPoint + gradientSemiCircleDetails.path.translate.x - (gradientWidth / 2)) +
					"," +
					(seventhPathEndPoints[0].yCoordinate + gradientSemiCircleDetails.path.translate.y) +
					")",
				"fill-opacity": 0
			})
			;

		outcomeNodeBlock = this.commonContainer.svg
			.append("g")
			.attr({
				class: "outcome-node-block"
			});

		for (let index = 0; index < eightLineEndPoints.length; index++) {
			outcomeNodeSection = outcomeNodeBlock
				.append("g")
				.attr({
					class: "outcome-node-section",
					id: "outcome-node-section-" + index
				});

			outcomeNodeSection
				.append('circle')
				.attr({
					class: "outer-node-circle",
					id: "outer-node-circle-" + index,
					cx: this.commonContainer.xScale(eightLineEndPoints[index].xCoordinate),
					cy: eightLineEndPoints[index].yCoordinate,
					fill: "#FFF",
					stroke: eightShapePathColor[index],
					r: 0
				})
				.style({
					"stroke-width": "4px"
				})
				.style({ opacity: 0 });

			outcomeInnerNodeSection = outcomeNodeSection
				.append("g")
				.attr({
					class: "outcome-inner-circle-block",
					id: "outcome-inner-circle-block-" + index,
				})
				.style({ opacity: 0 });

			outcomeInnerNodeSection
				.append('circle')
				.attr({
					class: "inner-node-circle",
					id: "inner-node-circle-" + index,
					cx: this.commonContainer.xScale(eightLineEndPoints[index].xCoordinate),
					cy: eightLineEndPoints[index].yCoordinate,
					fill: eightShapePathColor[index],
					stroke: "none",
					r: 40
				});

			nodeTextYPosition = eightLineEndPoints[index].yCoordinate + 5;
			outcomeNodeTextSvg = outcomeInnerNodeSection
				.append("text")
				.attr({
					id: "inner-node-text-" + index,
					x: this.commonContainer.xScale(eightLineEndPoints[index].xCoordinate),
					y: nodeTextYPosition,
					fill: "#FFF",
					"text-anchor": "middle",
					"alignment-baseline": "central",
					"font-size": "20px",
				});

			outcomeNodeTextSvg
				.selectAll('tspan')
				.data(nodeTextContent[index])
				.enter()
				.append("tspan")
				.text((text) => {
					return text
				})
				.attr({
					x: this.commonContainer.xScale(eightLineEndPoints[index].xCoordinate),
					y: (d, key) => {
						return nodeTextYPosition + ((key) * 20)
					},
					"class": (d, key) => {
						return nodeTextClass[key]
					}
				});
		}

		let getEightLineEndPoints = () => {
			return eightLineEndPoints;
		}

		let updateEightLinePath = (_scrollTop) => {
			let path = this.path.outcome,
				pathScale = this.pathScales.outcome;

			for (let index = 0; index < 4; index++) {
				updateEightLinePathLength(path[index], pathScale[index], index, _scrollTop);
			}
		}

		let updateEightLinePathLength = (path, pathScale, index, _scrollTop) => {
			let pathLength = path.node().getTotalLength();
			path
				.style('stroke-dashoffset', function (d) {
					return pathLength - pathScale(_scrollTop) + 'px';
				});

			if ((pathLength - pathScale(_scrollTop)) < (pathLength * 0.88) && !eightGradientFlag) {
				eightGradientFlag = true;
				halfgradientCircle
					.transition()
					.duration(50)
					.ease("linear")
					.attr({ "fill-opacity": 1 });
			} else if ((pathLength - pathScale(_scrollTop)) >= (pathLength * 0.88) && eightGradientFlag) {
				eightGradientFlag = false;
				halfgradientCircle
					.transition()
					.duration(100)
					.ease("linear")
					.attr({ "fill-opacity": 0 });
			}

			if (pathScale(_scrollTop) == pathLength && !eightNodeFlag[index]) {
				eightNodeFlag[index] = 1;

				this.commonContainer.svg.select("#outer-node-circle-" + index)
					.attr({ r: 50 })
					.transition()
					.duration(500)
					.ease("linear")
					.each("end", setOutcomeNodeTextTop(index, false))
					.style({ opacity: 1 });

				this.commonContainer.svg.select("#outcome-inner-circle-block-" + index)
					.transition()
					.delay(100)
					.duration(700)
					.ease("linear")
					.style({ opacity: 1 });

			} else if (pathScale(_scrollTop) != pathLength && eightNodeFlag[index]) {
				eightNodeFlag[index] = 0;

				let textVisibility = d3.select("#outcome-block-" + (index + 1));

				textVisibility
					.classed("in", false);

				this.commonContainer.svg.select("#outer-node-circle-" + index)
					.attr({ r: 0 })
					.style({ opacity: 0 });

				this.commonContainer.svg.select("#outcome-inner-circle-block-" + index)
					.style({ opacity: 0 });
			}
		}

		let setOutcomeNodeTextTop = (index, resizeFlag) => {
			let topOffset = [14.52, 14.92, 14.92, 14.52];
			let container = d3.select(".container").node().getBoundingClientRect().left;
			let boundingClientRect = this.commonContainer.svg.select("#eight-path-" + index).node().getBoundingClientRect();
			let nodeSection = this.commonContainer.svg.select("#outcome-inner-circle-block-" + index).node().getBoundingClientRect();
			let textContainer = d3.select("#outcome-block-" + (index + 1));
			let textContainerWidth = textContainer.node().getBoundingClientRect().width;
			const top = (index === 0 || index === 3) ? (boundingClientRect.height * 3.44) : (boundingClientRect.height * 1.996);
			if (index == 0 || index == 1) {
				textContainer
					.style({
						top: `${top}px`,// ((nodeSection.height * topOffset[index]) / this.commonContainer.aspectRatio) + "px",
						left: (boundingClientRect.left - container - (textContainerWidth / 2)) + "px",
					});
			}
			else if (index == 2 || index == 3) {
				textContainer
					.style({
						top: `${top}px`,// ((nodeSection.height * topOffset[index]) / this.commonContainer.aspectRatio) + "px",
						left: ((boundingClientRect.right - container - (textContainerWidth / 2))) + "px",
					});
			}

			if (!resizeFlag) {
				let textVisibility = d3.select("#outcome-block-" + (index + 1));

				textVisibility
					.classed("in", true);
			}
		}

		return {
			getEightLineEndPoints,
			updateEightLinePath,
			setOutcomeNodeTextTop
		}
	}

	initializeCircleCluster() {
		//Initialize data for cluster of circles with count 250
		this.plugNPredictRWDService.setCustomerData(250);
		//Get data object
		let { bigCluster } = this.sectionsHolder.problem;
		bigCluster.customerData = this.plugNPredictRWDService.getCustomerData();
		bigCluster.settings = {
			width: 500,
			height: 500,
			charge: -15,
			circleRadius: 8,
			circleColor: "#aaafb5",
			id: 'circles-cluster',
			parentSvg: this.commonContainer.svg,
			transformX: 0,
			transformY: 0
		};

		let toggleBigCircleOpacity = (beforeTransition, afterTransition) => {
			d3.selectAll("#" + bigCluster.settings.id + "-svg")
				.selectAll("circle")
				.attr("fill-opacity", beforeTransition)
				.attr("stroke-opacity", beforeTransition)
				.transition()
				.ease("ease-out")
				.duration(function (d, i) {
					return 5 * i;
				})
				.attr("fill-opacity", afterTransition)
				.attr("stroke-opacity", afterTransition);
		}

		return {
			toggleBigCircleOpacity
		}
	}

	createPathCircle = (points, index, idString, color, shapeCount = 4) => {
		let firstPathCircleSvg = this.commonContainer.svg
			.append("g")
			.attr({
				"id": idString + "-" + index,
			});

		firstPathCircleSvg
			.selectAll("circle")
			.data(d3.range(shapeCount))
			.enter()
			.append("circle")
			.attr({
				"cx": this.commonContainer.xScale(points.xCoordinate),
				"cy": points.yCoordinate,
				"r": 8,
				"fill": (value, key) => {
					return color[key];
				},
				"class": idString,
				"id": (value, key) => {
					return idString + "-" + index + "-" + key
				},

				"fill-opacity": 0
			})
	}

	createShapes = (points, index, idString, color, shapeType, size) => {
		let shapesSvg = this.commonContainer.svg
			.append("g")
			.attr({
				"id": idString + "-" + index,
			});

		shapesSvg
			.selectAll(".symbol")
			.data(d3.range(4))
			.enter()
			.append("path")
			.attr({
				"d": (data, key) => { return this.shapesService.getShapeCoordinates(shapeType[key], size) },
				"fill": (data, key) => {
					return color[key];
				},
				"class": idString,
				"id": (data, key) => {
					return (idString + "-" + index + "-" + key);
				},
				"transform": (data, key) => {
					return "translate(" + this.commonContainer.xScale(points[key].xCoordinate) + "," + points[key].yCoordinate + ")";
				},
				"fill-opacity": 1
			})
	}

	renderSmallProblemCluster() {
		let { offset } = this.commonContainer,
			smallClusterOffset = [offset * 0.195, this.commonContainer.offset * 0.39, this.commonContainer.offset * 0.64, this.commonContainer.offset * 0.89],
			smallClusterHeight = [1355, 1800, 2200, 2600],
			clusterData = [],
			clusterColor = [...this.categoriesColor],
			idString = ["diagnosis", "treatmentInitiation", "treatmentprogression", "dropOff"],
			clusterDotCount = [80, 80, 80, 80],
			width, height, settings;

		let createSmallClusterCircle = (index, width, height, data, idString) => {
			let { details, flag } = this.sectionsHolder.problemToData.smallCluster;
			details[index] = {
				svg: this.commonContainer.svg,
				index,
				width,
				height,
				data,
				idString,
				categories: this.categories[index],
				categoriesColor: this.categoriesColor[index]
			}
			flag[index] = 1;
		}

		for (let index = 0; index < 4; index++) {
			width = this.commonContainer.xScale(smallClusterOffset[index]);
			height = smallClusterHeight[index];
			settings = {
				width,
				height,
				categories: this.categories[index]
			};
			this.smallClusterService.createData(index, settings, clusterDotCount[index]);
			clusterData[index] = this.smallClusterService.getData(index);
			createSmallClusterCircle(index, width, height, clusterData[index], idString[index]);
		}

		let updateOpacityOfAllCircles = (index, opacity = 1) => {
			this.commonContainer.svg
				.selectAll(".node_" + index)
				.attr("fill-opacity", opacity)
		}

		let updateClusterTooltip = (className, displayFlag, index) => {
			let clusterTooltip = d3.select("." + className);
			clusterTooltip.classed('show', displayFlag);

			let clusterTooltipHeading = d3.select("." + className + ' h4');
			clusterTooltipHeading.style({
				"color": clusterColor[index]
			});

			let clusterTooltipInfo = d3.select("." + className + ' p');
			clusterTooltipInfo.attr({
				"data-color": clusterColor[index].replace("#", "z")
			})
		}

		let updateSmallClusterForce = (index, chargeFlag) => {
			let { details } = this.sectionsHolder.problemToData.smallCluster;
			let centerCircleNode = d3.select("#" + details[index].idString + "-" + index + "-0"),
				innerCircleRadius = 20,
				innercircleCharge = -160,
				outerCircleCharge = -8,
				dimensions = {
					width: this.commonContainer.xScale(smallClusterOffset[index]) * 2,
					height: smallClusterHeight[index]
				},
				data = clusterData[index];

			if (chargeFlag == "forward") {
				centerCircleNode
					.transition()
					.duration(500)
					.attr('r', innerCircleRadius);

				d3.layout.force()
					.size([dimensions.width, dimensions.height])
					.charge(function (d, i) {
						return i ? outerCircleCharge : innercircleCharge
					})
					.nodes(data.nodes)
					.on("tick", () => {
						d3.selectAll(".node_" + index)
							.attr("cx", function (d) {
								return d.x;
							})
							.attr("cy", function (d) {
								return d.y;
							});
					})
					.start();
			} else if (chargeFlag == 'backward') {
				centerCircleNode
					.attr('r', 0);

				d3.layout.force()
					.size([dimensions.width, dimensions.height])
					.charge(outerCircleCharge)
					.nodes(data.nodes)
					.on("tick", () => {
						d3.selectAll(".node_" + index)
							.attr("cx", function (d) {
								return d.x;
							})
							.attr("cy", function (d) {
								return d.y;
							});
					})
					.start();
			}
		};

		return {
			updateSmallClusterForce,
			updateOpacityOfAllCircles,
			updateClusterTooltip
		}
	}

	onScrollSecionChange($event) {
		this.onVisibleSectionChange.emit($event);
	}

	responsivefy(svg) {
		// get container + svg aspect ratio
		let container = d3.select(svg.node().parentNode),
			width = parseInt(svg.style("width")),
			height = parseInt(svg.style("height")),
			aspect = width / height;
		this.commonContainer.aspectRatio = aspect;
		// this.setPositionOfTextelements();    

		// get width of container and resize svg to fit it
		let resize = () => {
			let targetWidth = parseInt(container.style("width"));
			svg.attr("width", targetWidth);
			svg.attr("height", Math.round(targetWidth / aspect));
			this.initializeScales();
			this.setPositionOfTextelements();
		}
		// add viewBox and preserveAspectRatio properties,
		// and call resize so that svg resizes on inital page load
		svg.attr("viewBox", "0 0 " + width + " " + height)
			.attr("perserveAspectRatio", "xMinYMid")
			.call(resize);

		// to register multiple listeners for same event type, 
		// you need to add namespace, i.e., 'click.foo'
		// necessary if you call invoke this function for multiple svgs
		// api docs: https://github.com/mbostock/d3/wiki/Selections#on
		d3.select(window).on("resize." + container.attr("id"), resize);
	}

	setPositionOfTextelements() {
		let boundingClientRect = this.commonContainer.svg.select("#first-path").node().getBoundingClientRect();
		let textContainer = d3.select("#first-cluster-tooltip");
		let container = d3.select(".container").node().getBoundingClientRect().left;

		let secondPoints = this.commonContainer.svg.select("#second-path").node().getBoundingClientRect();
		textContainer
			.style({
				top: ((boundingClientRect.height * 1.392) / this.commonContainer.aspectRatio) + "px",
				left: ((secondPoints.right - container - (secondPoints.width * 0.1))) + "px"
			});

		boundingClientRect = secondPoints;
		let thirdPathPoints = this.commonContainer.svg.select("#third-path").node().getBoundingClientRect();
		textContainer = d3.select("#second-cluster-tooltip");
		textContainer
			.style({
				top: ((boundingClientRect.height * 1.735) / this.commonContainer.aspectRatio) + "px",
				left: ((thirdPathPoints.left + 80) - container) + "px"
			});

		boundingClientRect = thirdPathPoints;
		textContainer = d3.select("#third-cluster-tooltip");
		textContainer
			.style({
				top: ((boundingClientRect.height * 2.8) / this.commonContainer.aspectRatio) + "px",
				right: ((boundingClientRect.right - container - (boundingClientRect.width * 0.85))) + "px"
			});

		boundingClientRect = this.commonContainer.svg.select("#fourth-path").node().getBoundingClientRect();;
		textContainer = d3.select("#fourth-cluster-tooltip");
		textContainer
			.style({
				top: ((boundingClientRect.height * 3.312) / this.commonContainer.aspectRatio) + "px",
				right: ((boundingClientRect.right - container - (boundingClientRect.width * 3.63))) + "px"
			});

		this.sectionsHolder.data.timeline.updateTimeline.configureTooltipPosition();
		boundingClientRect = this.commonContainer.svg.select("#first-path").node().getBoundingClientRect();
		textContainer = d3.select("#problem");
		const problemHeight = boundingClientRect.height * 9.67; 
		textContainer
			.style({
				'height': `${problemHeight}px`,
				'align-items': 'flex-start',
			});

		boundingClientRect = this.commonContainer.svg.select("#fifth-path-0").node().getBoundingClientRect();
		textContainer = d3.select("#data");
		let textContainerBoundingRect = textContainer.node().getBoundingClientRect();
		const dataTop = problemHeight + 1;
		const dataHeight = boundingClientRect.height * 1.732;
		textContainer
			.style({
				'height': `${dataHeight}px`,
				'align-items': 'flex-start',
				top: `${ dataTop }px`,// ((boundingClientRect.height / this.commonContainer.aspectRatio) + (boundingClientRect.height - (textContainerBoundingRect.height / 0.95))) + "px",
				left: boundingClientRect.left + (boundingClientRect.width * 0.07) + "px"
			});

		boundingClientRect = this.commonContainer.svg.select("#sixth-path-3").node().getBoundingClientRect();
		textContainer = d3.select("#solution");
		const solutionTop = dataTop + dataHeight + 1;
		textContainer
			.style({
				'height': `${boundingClientRect.height * 2.2}px`,
				'align-items': 'flex-start',
				top: `${solutionTop}px`// (((boundingClientRect.height * 1.118) / this.commonContainer.aspectRatio) + (boundingClientRect.height - textContainerBoundingRect.height) / 2) + "px", //2089
			});
		boundingClientRect = this.commonContainer.svg.select("#seventh-path-3").node().getBoundingClientRect();
		textContainer = d3.select("#outcomes");
		textContainer
			.style({
				'height': `${boundingClientRect.height * 2.26}px`,
				'align-items': 'flex-start',
				top: `${boundingClientRect.height * 10.87}px`
			});

		for (let index = 0; index < 4; index++) {
			this.sectionsHolder.outcome.eightLinePath.setOutcomeNodeTextTop(index, true);
		}
	};

	ngOnDestroy() {
		this.commonContainer.windowListener();

		d3.select(window).on("resize." + d3.select(this.commonContainer.svg.node().parentNode).attr("id"), null);
		window.cancelAnimationFrame(this.commonContainer.windowRequestFrameId);
	}

	redirectTo() {
        this._gaService.triggerEvent('DS View', 'Usecase', this.widgetData.title);
		this.router.navigate(['gallery/story-page', this.widgetData.url]);
	}
}
