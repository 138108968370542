import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { D3CustomShapesService } from 'src/app/services/helper/d3-custom-shapes.service';

declare let d3: any;
import * as _ from 'lodash';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'app-d3-event-timeline-chart',
	templateUrl: './d3-event-timeline-chart.component.html',
	styleUrls: ['./d3-event-timeline-chart.component.css']

})
export class D3EventTimelineChart implements OnInit {
	@Input() widgetData;
	@ViewChild('d3EventTimelineChartRef') chartElement: ElementRef;
	constructor(private shapesService: D3CustomShapesService) { }

	ngOnInit() {
		this.renderChart();
	}

	renderChart() {
		let width = d3.select('#event-timeline-chart').node(0).getBoundingClientRect().width,
			height = this.widgetData.data.length * 100;
		let parseDate = d3.time.format("%m/%d/%Y").parse;
		let timelineData = this.widgetData.data;
		//To create array of all years present in the data for calculation of min and max range for scale
		let years = [];
		let totalNumberOfUserData = []
		for (let index = 0; index < timelineData.length; index++) {
			totalNumberOfUserData.push(index + 1);
			timelineData[index].userActivities = _.orderBy(timelineData[index].userActivities, ['days_before'], ['desc']);
			years = [...years, ...(_.map(timelineData[index].userActivities, 'date'))];
		}

		let xScale = d3.time.scale().domain(d3.extent(years, function (d) { return parseDate(d); })).range([50, width - 150]).nice();
		let yScale = d3.scale.ordinal().domain([...totalNumberOfUserData]).rangePoints([50, height - 150], 1);
		// Append SVG 
		let svg = d3.select(this.chartElement.nativeElement)
			.append("svg")
			.attr("id", "timeline-svg")
			.attr("width", width)
			.attr("height", height);

		let xAxis = d3.svg.axis().scale(xScale).orient("bottom").tickFormat(d3.time.format("%m/%Y")).tickPadding(10)
			.outerTickSize(10);
		let yAxis = d3.svg.axis().scale(yScale).orient("left");

		svg.append("g").attr("transform", "translate(50, " + (height - 125) + ")").attr("class", "x axis").call(xAxis);
		svg.append("g").attr("transform", "translate(50,0)").attr("class", "y axis").call(yAxis)
			.append("text")
			.attr("transform", "rotate(-90)")
			.attr("class", "test")
			.attr("y", -20)
			.attr("x", (-(height / 2) + 60))
			.attr("dy", "-0.6em")
			.style("text-anchor", "end")
			.text(this.widgetData.yAxisLable || "Value");
		let lineGenerator = d3.svg.line()
			.x(function (d, i) {
				return d.xCoordinate;
			})
			.y(function (d) {
				return d.yFactorAdj ? (yScale(d.yCoordinate) + d.yFactorAdj) : yScale(d.yCoordinate);
			});
		let tooltipDiv = d3.select('body').append('div')
			.attr('class', 'graph-tooltip dark top-position')
			.attr('id', 'd3-event-timeline-chart-custom-tooltip')
			.style('display', 'none');
		for (let index = 0; index < timelineData.length; index++) {

			let yFactorAdj = -20;
			let dataPoints = { xCoordinate: 80, yCoordinate: (yScale(index + 1) + yFactorAdj), width: (width - 150), height: 40, roundedRadius: 10 };

			svg
				.append('path')
				.attr('d', roundedRectangle(dataPoints.xCoordinate, dataPoints.yCoordinate, dataPoints.width, dataPoints.height, dataPoints.roundedRadius))
				.style("stroke", function () {
					return timelineData[index].timelineColor || 'grey';
				})
				.style("fill", "none");

			let centerLinePoints = [
				{ xCoordinate: 50, yCoordinate: index + 1 },
				{ xCoordinate: width - 50, yCoordinate: index + 1 },
			];

			svg
				.append('path')
				.attr('d', lineGenerator(centerLinePoints))
				.style("stroke", "none")
				.style("fill", "none");

			svg.selectAll(".timeline-chart-shapes")
				.data(timelineData[index].userActivities)
				.enter()
				.append("path")
				.attr({
					"d": (d, i) => {
						return this.shapesService.getShapeCoordinates("circle", 400);
					},
					"transform": (d) => {
						return "translate(" + (xScale(parseDate(d.date)) + 50) + ", " + yScale(index + 1) + ")"
					}
				})
				.attr("fill", function (d) {
					return timelineData[index].timelineColor || 'grey';
				})
				.attr("fill-opacity", 0)
				.transition()
				.delay(function (d, i) {
					if (d.shapeText) {
						svg.append("text")
							.text(d.shapeText)
							.attr({
								"x": (xScale(parseDate(d.date)) - 7) + 50,
								"y": yScale(centerLinePoints[0].yCoordinate) + 4,
								"class": "timeline-label-text",
							})
							.on("mouseover", function () {
								tooltipDiv.style('display', 'inline');
							}
							)
							.on("mousemove", function () {
								let tooltip = tooltipDiv
									.html(d.event + " - " + d.date);
								let width = tooltip.node().offsetWidth;
								tooltip.style('left', (d3.event.pageX - (width / 2)) + 'px')
									.style('top', (d3.event.pageY - 55) + 'px');
							}
							).on('mouseout', function (d) {
								tooltipDiv.style('display', 'none');
							});
					}

					return i * 100
				})
				.duration(1000)
				.attr("fill-opacity", 1);


		}

		function roundedRectangle(x, y, w, h, r) {
			let retval;
			retval = "M" + (x + r) + "," + y;
			retval += "h" + (w - 2 * r);
			retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r
			retval += "v" + (h - 2 * r);
			retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
			retval += "h" + (2 * r - w);
			retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
			retval += "v" + (2 * r - h);
			retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
			retval += "z";
			return retval;
		}
	}

	OnDestroy() {
		d3.select("#d3-event-timeline-chart-custom-tooltip").remove();
	}

}
