import { Component, OnInit, Input} from '@angular/core';
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-guide-book',
  templateUrl: './guide-book.component.html'
})
export class GuideBookComponent implements OnInit {
 	@Input() widgetData;
	data;
 	htmlString;

	// constructor(private _sanitizer: DomSanitizer) {
 // 	}

 	ngOnInit() {
 		// console.log("this.data..........", this.data)
 		this.htmlString = this.widgetData.data.htmlString;
 	}

 	/*public get sanitizedHtmlProperty() : SafeHtml {
     	return this._sanitizer.sanitize(SecurityContext.HTML, this.htmlString);
  	}*/
}
