import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
} from "@angular/core";
import { DataShareService } from "src/app/shared/data-share.service";
import { FullpagescrollDirective } from "src/app/shared/fullpagescroll.directive";
import _ from "lodash";
import { MatDialog } from "@angular/material";
import { PlaygroundDialogComponent } from "./playground-dialog/playground-dialog.component";
import { transition } from "@angular/animations";
import { delay } from "rxjs/operators";

declare const d3: any;
import d3Tip from "d3-tip";
@Component({
  selector: "app-atlas-intelligence-kraft",
  templateUrl: "./atlas-intelligence-kraft.component.html",
  styleUrls: ["./atlas-intelligence-kraft.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class AtlasIntelligenceKraftComponent implements OnInit {
  @ViewChild("fullPageScroll") fpScroll: FullpagescrollDirective;
  public activeSlide = "";
  public activeStartSlide = "";
  public previousTraversedSlide = "";
  public actualSlide = "screen-1";
  public previousActiveSlide = [];
  public addStep = false;
  public add6Step = false;
  private screens = [
    "screen-1",
    "screen-2",
    "screen-3",
    "screen-4",
    "screen-5",
    "screen-6",
  ];
  public currentScrollDirection = "forward";
  public currentScreenIndex = 1;
  public lastActiveSlide = "screen-1";
  public prevSlide = "";
  public nextActiveSlide = "screen-2";
  public startScrollEvent = true;
  @ViewChild("playgroundbutton") private playgroundbuttonContainer: ElementRef;

  public clickActionScreensIndex = [1, 2, 3, 4];

  screen1visitno = 0;
  public screen2vistno = 0;
  screen3visitno = 0;
  screen5visitno = 0;
  screen6visitno = 0;
  screen4VisitNo = 0;
  public indicatorSettings = [
    {
      id: 1,
      label: "Introduction",
    },
    {
      id: 2,
      label: "Challenges & Solution",
    },
    {
      id: 3,
      label: "Process",
    },
    {
      id: 4,
      label: "Output & Benefits",
    },
  ];

  private _svgSection = null;
  public animationStarted = {
    screen1: false,
    screen2: false,
    screen3: false,
    screen4: false,
    screen5: false,
    screen6: false,
    screen7: false,
  };
  private _unsubscribeResize = null;
  public scaleXValue = 1;
  public scaleYValue = 1;

  constructor(
    private _renderer: Renderer2,
    private dataShareService: DataShareService,
    public dialog: MatDialog
  ) {}
  ngAfterContentInit(): void {
    this._unsubscribeResize = this._renderer.listen(
      window,
      "resize",
      _.debounce(() => {
        this._svgSection.resize();
      }, 700)
    );
  }

  ngOnInit() {
    this.dataShareService.onCustomEvent.next({
      event: "addClassToElement",
      data: { selector: "#header", class: "humburger-header" },
    });
    this.renderStory();
  }
  changeDirectiveScrollEvent(state: boolean) {}
  public onDownArrowClick(sectionId) {
    this.fpScroll.navigateTo(sectionId);
  }

  public onPlaygroundBtnClicked() {
    console.log("Playground button Clicked");
    let playgroundDialogRef = this.dialog.open(PlaygroundDialogComponent, {
      width: "100vw",
      height: "100vh",
      panelClass: "playground-overlay",
    });

    playgroundDialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  public onFullPageSlideChangeStart(event) {
    console.log(event);

    this.previousTraversedSlide = this.actualSlide;
    if (this.previousActiveSlide.indexOf(event) < 0) {
      this.previousActiveSlide.push(this.actualSlide);
    } else {
      this.previousActiveSlide.splice(
        this.previousActiveSlide.indexOf(this.actualSlide),
        1
      );
    }
    this.actualSlide = event;

    this.activeStartSlide = event;

    if (
      this.screens.indexOf(this.previousTraversedSlide) -
        this.screens.indexOf(this.actualSlide) <
      0
    ) {
      this.currentScrollDirection = "forward";
    } else {
      this.currentScrollDirection = "backward";
    }
    this.currentScreenIndex =
      this.clickActionScreensIndex[this.screens.indexOf(this.actualSlide)];
    this.lastActiveSlide =
      this.currentScrollDirection == "forward"
        ? this.screens[this.screens.indexOf(event) - 1]
        : this.screens[this.screens.indexOf(event) + 1];
    this.prevSlide = this.lastActiveSlide;
    setTimeout(() => {
      this.lastActiveSlide = this.activeStartSlide;
    }, 1000);
    const nextSlideIndex =
      this.screens.indexOf(event) + 1 > this.screens.length - 1
        ? this.screens.indexOf(event)
        : this.screens.indexOf(event) + 1;
    this.nextActiveSlide = this.screens[nextSlideIndex];
  }
  scrollTo(selector, type, x = 0, y = 0) {
    if (type == "id") document.querySelector(`#${selector}`).scrollTo(x, y);
    else
      document
        .querySelectorAll(`.${selector}`)
        .forEach((scrollEl, index) => scrollEl.scrollTo(x, y));
  }
  public onFullPageSlideChangeComplete(event) {
    this.activeSlide = event;

    switch (this.prevSlide.split("-")[1]) {
      case "1":
        this._svgSection.screenOne.stopAnimation();

        break;
      case "2":
        this._svgSection.screenTwo.stopAnimation();

        break;
      case "3":
        this._svgSection.screenThree.stopAnimation();

        break;
      case "4":
        this._svgSection.screenFour.stopAnimation();

        break;
      case "5":
        this._svgSection.screenFive.stopAnimation();

        break;
      case "6":
        this._svgSection.screenSix.stopAnimation();

        break;
    }
    this.scrollTo("scroll-wrapper", "class", 0, 0);

    switch (event) {
      case "screen-1":
        this._svgSection.screenOne.startAnimation(
          !this.animationStarted.screen1
        );

        break;
      case "screen-2":
        this._svgSection.screenTwo.startAnimation(
          !this.animationStarted.screen2
        );

        break;
      case "screen-3":
        this._svgSection.screenThree.startAnimation(
          !this.animationStarted.screen3
        );

        break;
      case "screen-4":
        this._svgSection.screenFour.startAnimation(
          !this.animationStarted.screen4
        );

        break;

      case "screen-5":
        this._svgSection.screenFive.startAnimation(
          !this.animationStarted.screen5
        );

        break;
      case "screen-6":
        this._svgSection.screenSix.startAnimation(
          !this.animationStarted.screen6
        );

        break;
    }
  }
  private renderStory() {
    this._svgSection = this._initSvgSection("svg-wrapper");

    setTimeout(() => {
      this.activeStartSlide = "screen-1";
      this.activeSlide = "screen-1";
    }, 100);
    setTimeout(() => {
      this._svgSection.screenOne.startAnimation(!this.animationStarted.screen1);
    }, 500);
  }

  NextScreen() {
    var slidenum = parseInt(this.activeSlide.split("-")[1]) + 1;
    console.log("Hey" + this.actualSlide);

    if (slidenum < 7) {
      this.fpScroll.navigateTo(`screen-${slidenum}`);
    }
  }

  nextDisabled() {
    var slide = parseInt(this.activeSlide.split("-")[1]);

    return slide === 6 ? true : false;
  }
  PrevDisabled() {
    var slide = parseInt(this.activeSlide.split("-")[1]);
    return slide === 1 ? true : false;
  }

  PrevScreen() {
    var slidenum = parseInt(this.activeSlide.split("-")[1]) - 1;
    if (slidenum > 0) {
      this.fpScroll.navigateTo(`screen-${slidenum}`);
    }
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr("width"), 10);
    const height = parseInt(svg.attr("height"), 10);
    const aspect = width / height;

    const resize = () => {
      const targetWidth = parseInt(container.style("width"), 10);
      svg.attr("width", targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr("height", Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style("width"), 10),
        height: parseInt(svg.style("height"), 10),
      };
    };
    svg
      .attr("viewBox", "0 0 " + width + " " + height)
      .attr("perserveAspectRatio", "xMinYMid")
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style("width"), 10) / width,
      heightAspect: parseInt(svg.style("height"), 10) / height,
    };
  }

  private _initSvgSection(containerId) {
    const that = this;
    let baseImage = "";
    const visitedScreen = {
      screen1: false,
      screen2: false,
      screen3: false,
      screen4: false,
      screen5: false,
      screen6: false,
      screen7: false,
    };
    const container = d3.select("#" + containerId);
    const WIDTH = 1920;
    const HEIGHT_OFFSET = 969 / 1920;
    const HEIGHT = WIDTH * HEIGHT_OFFSET;
    let createTooltip = () => {
      let tip = d3Tip()
        .attr("class", "d3-tip")
        .style("z-index", "999999999")
        .html(
          (d) =>
            `<div class="atlas-tooltip">
                <div>${d.name}</div>
              </div>`
        );
      return tip;
    };

    let createIotaTooltip = () => {
      let tip = d3Tip()
        .attr("class", "d3-tip")
        .style("z-index", "999999999")
        .html(
          (d) =>
            `<div class="atlas-iota-tooltip">
                <div>${d.name}</div>
              </div>`
        );
      return tip;
    };

    let tip = createTooltip();
    let iotaTip = createIotaTooltip();
    const svg = container
      .append("svg")
      .attr("width", WIDTH)
      .attr("height", HEIGHT)
      .call(tip)
      .call(iotaTip);

    const dataGroup = svg
      .append("g")
      .attr("id", "svg-data")
      .attr("class", "svg-data");

    const image = dataGroup
      .append("image")
      .attr("xlink:href", baseImage)
      .attr("class", "base-image-group")
      .attr("height", 969)
      .attr("width", 1920)
      .attr("id", "img");

    const createImageUsingData = (
      group,
      data: {
        id: string;
        src: string;
        height: number;
        width: number;
        x: number;
        y: number;
        s?: number;
        o?: number;
        class?: string;
        [k: string]: any;
        finalX?: number;
        finalY?: number;
        textStyle?: any;
      }
    ) => {
      return group
        .append("image")
        .datum(data)
        .attr("id", (d) => d.id)
        .attr("xlink:href", (d) => d.src)
        .attr("class", (d) => `${d.class || d.id} animate`)
        .attr("height", (d) => d.height)
        .attr("width", (d) => d.width)
        .attr("transform", (d) => {
          return `translate(${d.x}, ${d.y}) scale(${
            data.s !== void 0 ? data.s : 1
          })`;
        })
        .style("opacity", (d) => (d.o !== void 0 ? d.o : 1))
        .style("textStyle" in data ? data.textStyle : {});
    };

    const showImage = (
      group,
      className,
      operation: "draw" | "hide",
      delay = 0,
      duration = 1000,
      cb = function () {}
    ) => {
      group
        .selectAll(`.${className}`)
        .transition()
        .delay((d) => ("delay" in d ? d.delay : delay))
        .duration((d) => ("duration" in d ? d.duration : duration))
        .attr("transform", (d: any) => {
          return `translate(${d.finalX}, ${d.finalY}) scale(${d.s})`;
        })
        .style("opacity", operation === "hide" ? 0 : 1)
        .call(endAll, cb);
    };
    const showrotatedImage = (
      group,
      className,
      operation: "draw" | "hide",
      delay = 0,
      duration = 1000,
      rotation = 0,
      cb = function () {}
    ) => {
      group
        .selectAll(`.${className}`)
        .attr("transform", (d: any) => {
          return `translate(${d.finalX}, ${d.finalY}) scale(${d.s}) rotate(${rotation})`;
        })
        .transition()
        .delay((d) => ("delay" in d ? d.delay : delay))
        .duration((d) => ("duration" in d ? d.duration : duration))
        .style("opacity", operation === "hide" ? 0 : 1)
        .call(endAll, cb);
    };

    function endAll(tran, callback) {
      if (typeof callback !== "function") {
        throw new Error("Wrong callback in endall");
      }
      if (tran.size() === 0) {
        callback();
      }
      let n = 0;
      tran
        .each(() => {
          ++n;
        })
        .each("end", function (e) {
          if (--n === 0) {
            callback.apply(this, arguments);
          }
        });
    }

    const operateLine = (
      group,
      className,
      operation: "draw" | "hide",
      delay = 0,
      duration = 1000,
      cb = function () {}
    ) => {
      group
        .selectAll(`.${className}`)
        .style("opacity", operation === "hide" ? 0 : 1)
        .transition()
        .delay((d) => ("delay" in d ? d.delay : delay))
        .duration((d) => ("duration" in d ? d.duration : duration))
        .attr("stroke-dashoffset", (d) =>
          operation === "hide" ? d.pathLength : 0
        )
        .call(endAll, cb);
    };
    const line = d3.svg
      .line()
      .x((d) => d[0])
      .y((d) => d[1]);

    const addDashedLine = (
      group,
      lineData,
      className,
      strokeColor = "#000000",
      strokeDash = "8, 5",
      strokeWidth = 1,
      alreadyDraw = true
    ) => {
      return group
        .append("path")
        .datum(lineData)
        .attr("d", line)
        .attr("stroke", strokeColor)
        .attr("class", `animate ${className}`)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-dasharray", function (d: any) {
          d.pathLength = d3.select(this).node().getTotalLength();
          const dashing = strokeDash;
          const dashLength = dashing
            .split(/[\s,]/)
            .map(function (a) {
              return parseFloat(a) || 0;
            })
            .reduce(function (a, b) {
              return a + b;
            });

          const dashCount = Math.ceil(d.pathLength / dashLength);
          const newDashes = new Array(dashCount).join(dashing + " ");
          const dashArray = newDashes + " 1 , " + (d.pathLength + 5);
          return dashArray;
        })
        .attr("stroke-dashoffset", function (d: any) {
          return alreadyDraw ? 0 : d.pathLength;
        })

        .attr("fill", "none");
    };

    const showPhoto = (
      group,
      className,
      operation: "draw" | "hide",
      delay = 0,
      duration = 1000,
      rotation = 0,
      scale = 1,
      cb = function () {}
    ) => {
      group
        .selectAll(`.${className}`)

        .transition()
        .delay((d) => ("delay" in d ? d.delay : delay))
        .duration((d) => ("duration" in d ? d.duration : duration))
        .attr("transform", (d: any) => {
          if (operation === "hide") {
            return `translate(${d.x}, ${d.y}) scale(${scale}) rotate(${rotation})`;
          }
          return `translate(${d.finalX}, ${d.finalY}) scale(${scale}) rotate(${rotation})`;
        })
        .style("opacity", (d: any) => {
          return operation === "hide" ? 0 : `${d.o}`;
        })
        .call(endAll, cb);
    };

    const engineWrapper = dataGroup
      .append("g")
      .attr("class", "engineWrapper")
      .attr("id", "engineWrapper")
      .style("opacity", 0);

    const createForecastingEngine = (() => {
      const forecastingEngineGroup = engineWrapper
        .append("g")
        .attr("class", "forecastingEngineGroup")
        .attr("id", "forecastingEngineGroup")
        .style("opacity", 0);
      const forecastingengine = {
        dataContent: [
          {
            background: {
              id: "base_1",
              class: "",
              width: 355,
              height: 355,
              src: "assets/images/atlas-intelligence-kraft/base_1.svg",
              x: 1163,
              y: 335,
              finalX: 1163,
              finalY: 335,
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "base_2",
              class: "",
              width: 240,
              height: 240,
              src: "assets/images/atlas-intelligence-kraft/base_2.svg",
              x: 1220,
              y: 390,
              finalX: 1220,
              finalY: 390,
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "base_3",
              class: "",
              width: 180,
              height: 180,
              src: "assets/images/atlas-intelligence-kraft/base_3.svg",
              x: 1250,
              y: 420,
              finalX: 1250,
              finalY: 420,
              s: 1,
              o: 0,
            },
          },
        ],
      };
      forecastingengine.dataContent.forEach((content, index) => {
        const group = forecastingEngineGroup
          .append("g")
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            class: (d: any) => `forecastingEngine forecastingEngine-${index} `,
            id: `forecastingEngine-${index}`,
            transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
          })
          .style("opacity", (d: any) => d.o);
        createImageUsingData(group, content.background);
      });
      showImage(forecastingEngineGroup, "base_1", "draw", 0, 0, function () {});
      showImage(forecastingEngineGroup, "base_2", "draw", 0, 0, function () {});
      showImage(forecastingEngineGroup, "base_3", "draw", 0, 0, function () {});
    })();

    const createEngineFader = (() => {
      engineWrapper
        .append("circle")
        .attr("class", "EngineFader")
        .attr("transform", `translate(200,200)`)
        .attr("r", 10)
        .attr("fill", "#000000")
        .style("opacity", 0);
    })();

    const animateForecastingEngine = (
      screenNo,
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      const engineAttributes = [
        {
          screenNumber: 2,
          position: {
            x: 120,
            y: 0,
          },
          transitionOpacity: 1,
          opacity: 1,
          scale: 1,
          fader: {
            r: 0,
            cx: 0,
            cy: 0,
            opacity: 0,
          },
        },
        {
          screenNumber: 3,
          position: {
            x: -215,
            y: -130,
          },
          transitionOpacity: 0.3,
          opacity: 0.8,
          scale: 1.35,
          fader: {
            r: 104,
            cx: 1593,
            cy: 558,
            opacity: 0.5,
          },
        },
        {
          screenNumber: 4,
          position: {
            x: -2545,
            y: -850,
          },
          transitionOpacity: 0.2,
          opacity: 0.2,
          scale: 2.6,
          fader: {
            r: 0,
            cx: 0,
            cy: 0,
            opacity: 0,
          },
        },
        {
          screenNumber: 5,
          position: {
            x: -1080,
            y: -265,
          },
          transitionOpacity: 0.3,
          opacity: 0.8,
          scale: 1.14,
          fader: {
            r: 90,
            cx: 448,
            cy: 318,
            opacity: 0.5,
          },
        },
        {
          screenNumber: 6,
          position: {
            x: -530,
            y: -70,
          },
          transitionOpacity: 0.8,
          opacity: 0.8,
          scale: 1.1,
          fader: {
            r: 0,
            cx: 0,
            cy: 0,
            opacity: 0,
          },
        },
      ];
      const engineAttr = engineAttributes.filter((engAttr) => {
        if (engAttr.screenNumber === screenNo) return true;
        else return false;
      });

      if (screenNo === 2) {
        dataGroup
          .select(".engineWrapper")
          .select(".forecastingEngineGroup")

          .attr(
            "transform",
            `translate(${engineAttr[0].position.x},${engineAttr[0].position.y}) scale(${engineAttr[0].scale})`
          )
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", engineAttr[0].opacity)
          .call(endAll, () => {
            dataGroup
              .select(".engineWrapper")
              .select(".EngineFader")
              .attr("r", engineAttr[0].fader.r)
              .attr(
                "transform",
                `translate(${engineAttr[0].fader.cx},${engineAttr[0].fader.cy})`
              )
              .transition()
              .duration(500)
              .style("opacity", engineAttr[0].fader.opacity)
              .call(endAll, () => {});
          });
      } else if (screenNo === 3) {
        startEngineRotation(false, 0, 0);

        dataGroup
          .select(".engineWrapper")
          .select(".forecastingEngineGroup")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", engineAttr[0].transitionOpacity)
          .attr(
            "transform",
            `translate(${engineAttr[0].position.x},${engineAttr[0].position.y}) scale(${engineAttr[0].scale})`
          )
          .call(endAll, cb);
      } else if (screenNo === 4) {
        dataGroup
          .select(".engineWrapper")
          .select(".EngineFader")
          .attr("r", engineAttr[0].fader.r)
          .attr(
            "transform",
            `translate(${engineAttr[0].fader.cx},${engineAttr[0].fader.cy})`
          )
          .style("opacity", engineAttr[0].fader.opacity);

        startEngineRotation(true, 0, 0);

        dataGroup
          .select(".engineWrapper")
          .select(".forecastingEngineGroup")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", engineAttr[0].transitionOpacity)
          .attr("transform", `translate(${-400},${-40}) scale(${1})`)
          .call(endAll, () => {
            dataGroup
              .select(".engineWrapper")
              .select(".forecastingEngineGroup")
              .transition()
              .delay(0)
              .duration(duration === 0 ? 0 : 800)
              .style("opacity", engineAttr[0].transitionOpacity)
              .attr(
                "transform",
                `translate(${engineAttr[0].position.x},${engineAttr[0].position.y}) scale(${engineAttr[0].scale})`
              )
              .call(endAll, cb);
          });
      } else if (screenNo === 5) {
        dataGroup
          .select(".engineWrapper")
          .select(".forecastingEngineGroup")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", engineAttr[0].transitionOpacity)
          .attr(
            "transform",
            `translate(${engineAttr[0].position.x},${engineAttr[0].position.y}) scale(${engineAttr[0].scale})`
          )
          .call(endAll, () => {
            dataGroup
              .select(".engineWrapper")
              .select(".forecastingEngineGroup")
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", engineAttr[0].opacity)
              .call(endAll, () => {
                dataGroup
                  .select(".engineWrapper")
                  .select(".EngineFader")
                  .attr("r", engineAttr[0].fader.r)
                  .attr(
                    "transform",
                    `translate(${engineAttr[0].fader.cx},${engineAttr[0].fader.cy})`
                  )
                  .transition()
                  .delay(delay)
                  .duration(duration)
                  .style("opacity", engineAttr[0].fader.opacity)
                  .call(endAll, cb);
              });
          });
      } else if (screenNo === 6) {
        dataGroup
          .select(".engineWrapper")
          .select(".EngineFader")
          .attr("r", engineAttr[0].fader.r)
          .attr(
            "transform",
            `translate(${engineAttr[0].fader.cx},${engineAttr[0].fader.cy})`
          )
          .style("opacity", engineAttr[0].fader.opacity);
        dataGroup
          .select(".engineWrapper")
          .select(".forecastingEngineGroup")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", engineAttr[0].transitionOpacity)
          .attr(
            "transform",
            `translate(${engineAttr[0].position.x},${engineAttr[0].position.y}) scale(${engineAttr[0].scale})`
          )
          .call(endAll, cb);
      }
    };

    const startEngineRotation = (start, delay, duration) => {
      if (start) {
        engineWrapper
          .select(".forecastingEngineGroup")
          .select(".forecastingEngine-1")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr(
            "class",
            "forecastingEngine forecastingEngine-1 rotate-clockwise"
          );

        engineWrapper
          .select(".forecastingEngineGroup")
          .select(".forecastingEngine-2")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr(
            "class",
            "forecastingEngine forecastingEngine-2 rotate-anticlockwise"
          );
      } else {
        engineWrapper
          .select(".forecastingEngineGroup")
          .select(".forecastingEngine-1")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("class", "forecastingEngine forecastingEngine-1");

        engineWrapper
          .select(".forecastingEngineGroup")
          .select(".forecastingEngine-2")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("class", "forecastingEngine forecastingEngine-2");
      }
    };

    const dashedRectWrapper = dataGroup
      .append("g")
      .attr("class", "dashedRectWrapper")
      .attr("id", "dashedRectWrapper")
      .style("opacity", 0);

    const createDashedRect = (() => {
      dashedRectWrapper
        .append("rect")
        .attr("class", "dashedRect")
        .attr("transform", `translate(${80},${180})`)
        .attr("width", 1760)
        .attr("height", 710)
        .style("stroke", "#ffffff")
        .style("stroke-width", 1)
        .style("stroke-dasharray", 7)
        .style("fill", "#503a7e")
        .style("opacity", 1)
        .style("fill-opacity", 0.25);
    })();

    const animateDashedRect = (
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      console.log(delay, duration);
      dashedRectWrapper
        .transition()
        .delay(delay)
        .duration(duration)
        .style("opacity", operation === "draw" ? 1 : 0)
        .call(endAll, cb);
    };

    const focusrings = dataGroup
      .append("g")
      .attr("class", "focusgroup")
      .attr("id", "focusgroup")
      .style("opacity", 0);

    const createFocusRings = (() => {
      focusrings
        .append("circle")
        .attr("cx", 260)
        .attr("cy", 415)
        .attr("r", 0)
        .style("fill", "#fff")
        .style("opacity", 0)
        .attr("class", "screen-4-highlightlingnode-back");
      focusrings
        .append("circle")
        .attr("cx", 260)
        .attr("cy", 415)
        .attr("r", 0)
        .style("fill", "#fff")
        .style("opacity", 0)
        .attr("class", "screen-4-highlightlingnode-front");
    })();

    const animateFocusRings = (
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      focusrings.style("opacity", operation === "draw" ? 1 : 0);
      focusrings
        .select(".screen-4-highlightlingnode-back")
        .style("opacity", 0.2)
        .transition()
        .delay(delay)
        .duration(duration)
        .attr("r", 60);
      focusrings
        .select(".screen-4-highlightlingnode-front")
        .style("opacity", 0.4)
        .transition()
        .delay(delay)
        .duration(duration)
        .attr("r", 35)
        .call(endAll, cb);
    };

    const processGraph = dataGroup
      .append("g")
      .attr("class", "processGraph")
      .attr("id", "processGraph")
      .style("opacity", 0);

    const legendNodesCopy = processGraph
      .append("g")
      .attr("class", "processLegendNodesCopy")
      .attr("id", "processLegendNodesCopy");

    const nodeTextCopy = processGraph
      .append("g")
      .attr("class", "processNodeTextsCopy")
      .attr("id", "processNodeTextsCopy");

    const graphLines = processGraph
      .append("g")
      .attr("class", "processGraphLines")
      .attr("id", "processGraphLines");

    const legendNodesDataCopy = [
      {
        radius: 25,
        centerX: 350,
        centerY: 370,
        fillColor: "#2867B0",
        nodeText: "A",
        legendText: "Citric Acid",
      },
      {
        radius: 25,
        centerX: 350,
        centerY: 450,
        fillColor: "#E28D2A",
        nodeText: "B",
        legendText: "Whey",
      },
      {
        radius: 25,
        centerX: 350,
        centerY: 530,
        fillColor: "#1CAA44",
        nodeText: "C",
        legendText: "Dextrose",
      },
      {
        radius: 25,
        centerX: 350,
        centerY: 605,
        fillColor: "#B8BA51",
        nodeText: "D",
        legendText: "Palm Oil",
      },
      {
        radius: 25,
        centerX: 350,
        centerY: 680,
        fillColor: "#C73434",
        nodeText: "E",
        legendText: "Unbleached Wheat flour",
      },
      {
        radius: 25,
        centerX: 350,
        centerY: 760,
        fillColor: "#0FB8A4",
        nodeText: "F",
        legendText: "Eggs",
      },
    ];
    const createGNNGraphNodes = (() => {
      legendNodesDataCopy.forEach((node) => {
        legendNodesCopy
          .append("circle")
          .attr("class", "CopiedNode" + node.nodeText)
          .attr("r", node.radius)
          .attr("transform", `translate(${node.centerX},${node.centerY})`)
          .attr("fill", node.fillColor);
        nodeTextCopy
          .append("text")
          .attr("class", "CopiedNodeText" + node.nodeText)
          .attr("transform", `translate(${node.centerX},${node.centerY + 10})`)
          .style("text-anchor", "middle")
          .style("font-size", "30px")
          .style("font-weight", "bold")
          .style("fill", "#ffffff")
          .text(node.nodeText);
      });
    })();

    const graphAdjMatx = [
      {
        start: "A",
        end: "B",
        x1: 825 + 500,
        y1: 350,
        x2: 998 + 500,
        y2: 350,
      },
      {
        start: "A",
        end: "C",
        x1: 820 + 500,
        y1: 365,
        x2: 1005 + 500,
        y2: 555,
      },
      {
        start: "A",
        end: "D",
        x1: 800 + 500,
        y1: 375,
        x2: 800 + 500,
        y2: 545,
      },
      {
        start: "B",
        end: "C",
        x1: 1023 + 500,
        y1: 375,
        x2: 1023 + 500,
        y2: 545,
      },
      {
        start: "C",
        end: "E",
        x1: 1010 + 500,
        y1: 590,
        x2: 950 + 500,
        y2: 695,
      },
      {
        start: "C",
        end: "F",
        x1: 1040 + 500,
        y1: 590,
        x2: 1070 + 500,
        y2: 693,
      },
      ,
      {
        start: "E",
        end: "F",
        x1: 962 + 500,
        y1: 715,
        x2: 1050 + 500,
        y2: 715,
      },
    ];
    const createGNNGraphLines = (() => {
      graphAdjMatx.forEach((link, index) => {
        graphLines
          .append("line")
          .attr("class", "graphLine" + index)
          .attr("x1", link.x1)
          .attr("y1", link.y1)
          .attr("x2", link.x1)
          .attr("y2", link.y1)
          .attr("stroke", "white")
          .attr("stroke-width", 1.5);
      });
    })();

    const animateGNNNodesToIntermidiate = (
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      if (that.screen4VisitNo > 0) return;
      const legendNodesDataCopyIntermidiate = [
        {
          radius: 25,
          centerX: 800 + 500,
          centerY: 350,
          fillColor: "#2867B0",
          nodeText: "A",
          legendText: "Citric Acid",
        },
        {
          radius: 25,
          centerX: 900 + 500,
          centerY: 435,
          fillColor: "#E28D2A",
          nodeText: "B",
          legendText: "Whey",
        },
        {
          radius: 25,
          centerX: 700 + 500,
          centerY: 520,
          fillColor: "#1CAA44",
          nodeText: "C",
          legendText: "Dextrose",
        },
        {
          radius: 25,
          centerX: 1000 + 500,
          centerY: 605,
          fillColor: "#B8BA51",
          nodeText: "D",
          legendText: "Palm Oil",
        },
        {
          radius: 25,
          centerX: 750 + 500,
          centerY: 690,
          fillColor: "#C73434",
          nodeText: "E",
          legendText: "Unbleached Wheat flour",
        },
        {
          radius: 25,
          centerX: 850 + 500,
          centerY: 775,
          fillColor: "#0FB8A4",
          nodeText: "F",
          legendText: "Eggs",
        },
      ];

      processGraph.style("opacity", operation === "draw" ? 1 : 0);

      legendNodesDataCopyIntermidiate.forEach((node, index) => {
        legendNodesCopy
          .select(".CopiedNode" + node.nodeText)

          .transition()
          .delay(delay)
          .duration(duration)
          .attr("transform", `translate(${node.centerX},${node.centerY})`);
        nodeTextCopy
          .select(".CopiedNodeText" + node.nodeText)

          .transition()
          .delay(delay)
          .duration(duration)
          .attr("transform", `translate(${node.centerX},${node.centerY + 10})`)
          .call(
            endAll,
            legendNodesDataCopyIntermidiate.length === index + 1
              ? cb
              : function () {}
          );
      });
    };

    const animateGNNNodesToFinal = (
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      const legendNodesDataCopyGraph = [
        {
          radius: 25,
          centerX: 800 + 500,
          centerY: 350,
          fillColor: "#2867B0",
          nodeText: "A",
          legendText: "Citric Acid",
        },
        {
          radius: 25,
          centerX: 1023 + 500,
          centerY: 350,
          fillColor: "#E28D2A",
          nodeText: "B",
          legendText: "Whey",
        },
        {
          radius: 25,
          centerX: 1023 + 500,
          centerY: 570,
          fillColor: "#1CAA44",
          nodeText: "C",
          legendText: "Dextrose",
        },
        {
          radius: 25,
          centerX: 800 + 500,
          centerY: 570,
          fillColor: "#B8BA51",
          nodeText: "D",
          legendText: "Palm Oil",
        },
        {
          radius: 25,
          centerX: 937 + 500,
          centerY: 715,
          fillColor: "#C73434",
          nodeText: "E",
          legendText: "Unbleached Wheat flour",
        },
        {
          radius: 25,
          centerX: 1075 + 500,
          centerY: 715,
          fillColor: "#0FB8A4",
          nodeText: "F",
          legendText: "Eggs",
        },
      ];

      legendNodesDataCopyGraph.forEach((node, index) => {
        legendNodesCopy
          .select(".CopiedNode" + node.nodeText)
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("transform", `translate(${node.centerX},${node.centerY})`);
        nodeTextCopy
          .select(".CopiedNodeText" + node.nodeText)
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("transform", `translate(${node.centerX},${node.centerY + 10})`)
          .call(
            endAll,
            legendNodesDataCopyGraph.length === index + 1 ? cb : function () {}
          );
      });
    };

    const animateGNNLines = (
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      const graphAdjMatx = [
        {
          start: "A",
          end: "B",
          x1: 825 + 500,
          y1: 350,
          x2: 998 + 500,
          y2: 350,
        },
        {
          start: "A",
          end: "C",
          x1: 820 + 500,
          y1: 365,
          x2: 1005 + 500,
          y2: 555,
        },
        {
          start: "A",
          end: "D",
          x1: 800 + 500,
          y1: 375,
          x2: 800 + 500,
          y2: 545,
        },
        {
          start: "B",
          end: "C",
          x1: 1023 + 500,
          y1: 375,
          x2: 1023 + 500,
          y2: 545,
        },
        {
          start: "C",
          end: "E",
          x1: 1010 + 500,
          y1: 590,
          x2: 950 + 500,
          y2: 695,
        },
        {
          start: "C",
          end: "F",
          x1: 1040 + 500,
          y1: 590,
          x2: 1070 + 500,
          y2: 693,
        },
        ,
        {
          start: "E",
          end: "F",
          x1: 962 + 500,
          y1: 715,
          x2: 1050 + 500,
          y2: 715,
        },
      ];

      graphAdjMatx.forEach((link, index) => {
        graphLines
          .select(".graphLine" + index)

          .transition()
          .delay(delay)
          .duration(duration)
          .attr("x2", link.x2)
          .attr("y2", link.y2)
          .call(
            endAll,
            graphAdjMatx.length === index + 1 ? cb : function () {}
          );

        delay += duration === 0 ? 0 : 250;
      });
    };

    const animateGNNPosition = (
      screenNo,
      delay = 0,
      duration,
      operation: "draw" | "hide",
      cb = function () {}
    ) => {
      var x, y, scale;
      if (screenNo === 4) {
        x = 0;
        y = 0;
        scale = 1;
      } else if (screenNo === 5) {
        x = -45;
        y = 140;
        scale = 0.34;
      }

      processGraph.style("opacity", operation === "draw" ? 1 : 0);

      if (screenNo === 4) {
        processGraph
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("transform", `translate(${-650},${170}) scale(${0.7})`)
          .call(endAll, cb);
      } else if (screenNo === 5) {
        processGraph
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("transform", `translate(${x},${y}) scale(${scale})`)
          .call(endAll, cb);
      }
    };

    const screenOne = (() => {
      const HidePhoto = (
        group,
        className,
        delay = 0,
        duration = 1000,
        rotation = 0,
        cb = function () {}
      ) => {
        group
          .selectAll(`.${className}`)
          .transition()
          .delay((d) => ("delay" in d ? d.delay : delay))
          .duration((d) => ("duration" in d ? d.duration : duration))

          .attr("transform", (d: any) => {
            return `translate(${d.x}, ${d.y}) scale(${
              d.fs === void 0 ? 0 : d.s
            }) rotate(${rotation})`;
          })
          .call(endAll, cb);
      };
      const currentProcess = dataGroup
        .append("g")
        .attr("id", "screen-1-group")
        .attr("class", "screen-1-group d-none")
        .style("opacity", 1);

      const manInMarketGroup = currentProcess
        .append("g")
        .attr("id", "man-in-market-group")
        .attr("class", "man-in-market-group");

      const dataSummaryData = {
        dataContent: [
          {
            background: {
              id: "curved-lines",
              width: 950,
              height: 1100,
              src: "assets/images/atlas-intelligence-kraft/lines_arrows (2).svg",
              x: 1345,
              y: 360,
              finalX: 870,
              finalY: -190,
              class: "",
              s: 0,
              o: 0.6,
            },
          },
          {
            background: {
              id: "man-with-trolley",
              width: 500,
              height: 415,
              src: "assets/images/atlas-intelligence-kraft/shopping.svg",
              x: 1920,
              y: 419,
              finalX: 1255,
              finalY: 419,
              class: "",
              s: 1,
              o: 1,
            },
          },
          {
            background: {
              id: "question-mark",
              width: 170,
              height: 170,
              src: "assets/images/atlas-intelligence-kraft/talk_bubble.svg",
              x: 1600,
              y: 260,
              finalX: 1600,
              finalY: 260,
              class: "",
              s: 0,
              o: 1,
            },
          },

          {
            background: {
              id: "product-bottle-1",
              width: 100,
              height: 150,
              src: "assets/images/atlas-intelligence-kraft/prod_1.svg",
              x: 1135,
              y: 90,
              finalX: 1135,
              finalY: 90,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-bottle-2",
              width: 80,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/prod_2.svg",
              x: 1065,
              y: 90,
              finalX: 1065,
              finalY: 90,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-bottle-3",
              width: 65,
              height: 90,
              src: "assets/images/atlas-intelligence-kraft/prod_3.svg",
              x: 1350,
              y: 375,
              finalX: 1350,
              finalY: 375,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-bottle-4",
              width: 65,
              height: 90,
              src: "assets/images/atlas-intelligence-kraft/prod_5.svg",
              x: 1210,
              y: 540,
              finalX: 1210,
              finalY: 540,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-bottle-5",
              width: 80,
              height: 180,
              src: "assets/images/atlas-intelligence-kraft/prod_3.svg",
              x: 1800,
              y: 180,
              finalX: 1815,
              finalY: 180,
              class: "",
              s: 0,
              o: 1,
            },
          },

          {
            background: {
              id: "question_mark-1",
              width: 90,
              height: 150,
              src: "assets/images/atlas-intelligence-kraft/question_1.svg",
              x: 940,
              y: 320,
              finalX: 940,
              finalY: 320,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "question_mark-2",
              width: 110,
              height: 250,
              src: "assets/images/atlas-intelligence-kraft/question_2.svg",
              x: 1585,
              y: 70,
              finalX: 1585,
              finalY: 70,
              class: "",
              s: 0,
              o: 0.6,
            },
          },
          {
            background: {
              id: "question_mark-3",
              width: 125,
              height: 250,
              src: "assets/images/atlas-intelligence-kraft/question_3.svg",
              x: 1060,
              y: 440,
              finalX: 1060,
              finalY: 440,
              class: "",
              s: 0,
              o: 0.7,
            },
          },
          {
            background: {
              id: "question_mark-4",
              width: 100,
              height: 220,
              src: "assets/images/atlas-intelligence-kraft/question_2.svg",
              x: 1705,
              y: 505,
              finalX: 1705,
              finalY: 505,
              class: "",
              s: 0,
              o: 0.7,
            },
          },
          {
            background: {
              id: "product-checklist-1",
              width: 200,
              height: 150,
              src: "assets/images/atlas-intelligence-kraft/survey_form_tilted.svg",
              x: 1063,
              y: 250,
              finalX: 1063,
              finalY: 250,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-checklist-2",
              width: 200,
              height: 150,
              src: "assets/images/atlas-intelligence-kraft/survey_form_tilted.svg",
              x: 1260,
              y: 450,
              finalX: 1260,
              finalY: 450,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-checklist-blank",
              width: 300,
              height: 300,
              src: "assets/images/atlas-intelligence-kraft/survey_form_blank.svg",
              x: 1270,
              y: 370,
              finalX: 1120,
              finalY: 220,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-checklist-tick-1",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/tick.svg",
              x: 1185,
              y: 250,
              finalX: 1185,
              finalY: 250,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-checklist-tick-2",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/tick.svg",
              x: 1185,
              y: 295,
              finalX: 1185,
              finalY: 295,
              class: "",
              s: 0,
              o: 1,
            },
          },
          {
            background: {
              id: "product-checklist-tick-3",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/tick.svg",
              x: 1185,
              y: 340,
              finalX: 1185,
              finalY: 340,
              class: "",
              s: 0,
              o: 1,
            },
          },
        ],
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = manInMarketGroup
          .append("g")
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            class: `man-in-market-group man-in-market-group-${index}`,
            id: `man-in-market-group-${index}`,
            transform: (d: any) => `translate(${d.x}, ${d.y}) `,
          })
          .style("opacity", (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      showPhoto(
        manInMarketGroup,
        "product-checklist-blank",
        "draw",
        2000,
        400,
        0,
        1,
        function () {}
      );
      showPhoto(
        manInMarketGroup,
        "product-checklist-tick-1",
        "draw",
        2200,
        400,
        0,
        1,
        function () {
          showPhoto(
            manInMarketGroup,
            "question_mark-1",
            "draw",
            100,
            200,
            0,
            1,
            function () {}
          );
        }
      );

      showPhoto(
        manInMarketGroup,
        "product-checklist-tick-2",
        "draw",
        2700,
        400,
        0,
        1,
        function () {
          showPhoto(
            manInMarketGroup,
            "question_mark-2",
            "draw",
            200,
            400,
            -25,
            1,
            function () {}
          );
        }
      );

      showPhoto(
        manInMarketGroup,
        "product-checklist-tick-3",
        "draw",
        3300,
        400,
        0,
        1,
        function () {
          showPhoto(
            manInMarketGroup,
            "question_mark-3",
            "draw",
            200,
            400,
            0,
            1,
            function () {}
          );
          showPhoto(
            manInMarketGroup,
            "question_mark-4",
            "draw",
            400,
            400,
            0,
            1,
            function () {}
          );
        }
      );

      HidePhoto(
        manInMarketGroup,
        "product-checklist-blank",
        4500,
        500,
        0,
        function () {}
      );
      HidePhoto(
        manInMarketGroup,
        "product-checklist-tick-1",
        4500,
        500,
        0,
        function () {}
      );
      HidePhoto(
        manInMarketGroup,
        "product-checklist-tick-2",
        4500,
        500,
        0,
        function () {}
      );
      HidePhoto(
        manInMarketGroup,
        "product-checklist-tick-3",
        4500,
        500,
        0,
        function () {}
      );

      function initiateAnimation() {
        const htmlDelayValue = 1500;
        const scale = 1;
        dataGroup.select(".engineWrapper").style("opacity", 0);
        processGraph.style("opacity", 0);
        focusrings.style("opacity", 0);

        showPhoto(
          manInMarketGroup,
          "man-with-trolley",
          "draw",
          0,
          500,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "product-bottle-1",
          "draw",
          1000,
          500,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "product-bottle-2",
          "draw",
          1000,
          500,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "product-bottle-3",
          "draw",
          1000,
          500,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "product-bottle-4",
          "draw",
          1000,
          500,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "product-bottle-5",
          "draw",
          1000,
          500,
          0,
          1,
          function () {}
        );

        showPhoto(
          manInMarketGroup,
          "product-checklist-1",
          "draw",
          4600,
          1000,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "product-checklist-2",
          "draw",
          4600,
          1000,
          0,
          1,
          function () {}
        );
        showPhoto(
          manInMarketGroup,
          "curved-lines",
          "draw",
          5000,
          1000,
          0,
          1,
          function () {}
        );

        showPhoto(
          manInMarketGroup,
          "question-mark",
          "draw",
          6000,
          500,
          0,
          1,
          function () {}
        );

        setTimeout(() => {
          that.screen1visitno++;
        }, 6000);
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = "";

          image.attr("xlink:href", baseImage);
          currentProcess.style("opacity", 1);

          currentProcess.attr("class", "screen-1-group");
          initiateAnimation();
        },
        stopAnimation() {
          currentProcess.attr("class", "screen-1-group d-none");
          currentProcess.selectAll(".animate").interrupt();

          showPhoto(
            manInMarketGroup,
            "question_mark-1",
            "draw",
            0,
            0,
            0,
            1,
            function () {}
          ),
            showPhoto(
              manInMarketGroup,
              "question_mark-2",
              "draw",
              0,
              0,
              -25,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "question_mark-3",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "question_mark-4",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            HidePhoto(
              manInMarketGroup,
              "product-checklist-blank",
              0,
              0,
              0,
              function () {}
            ),
            HidePhoto(
              manInMarketGroup,
              "product-checklist-tick-1",
              0,
              0,
              0,
              function () {}
            ),
            HidePhoto(
              manInMarketGroup,
              "product-checklist-tick-2",
              0,
              0,
              0,
              function () {}
            ),
            HidePhoto(
              manInMarketGroup,
              "product-checklist-tick-3",
              0,
              0,
              0,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "man-with-trolley",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-bottle-1",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-bottle-2",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-bottle-3",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-bottle-4",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-bottle-5",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-checklist-1",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "product-checklist-2",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "curved-lines",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            ),
            showPhoto(
              manInMarketGroup,
              "question-mark",
              "draw",
              0,
              0,
              0,
              1,
              function () {}
            );

          currentProcess
            .transition()

            .duration(200)
            .style("opacity", 0);
          that.animationStarted.screen1 = true;
        },
      };
    })();

    const screenTwo = (() => {
      const currentProcess = dataGroup
        .append("g")
        .attr("id", "screen-2-group")
        .attr("class", "screen-2-group d-none")
        .style("opacity", 1);

      currentProcess.attr("transform", "translate(0,75)");

      const operateCircle = (
        group,
        className,
        operation: "draw" | "hide",
        delay = 0,
        duration = 1000,
        cb = function () {}
      ) => {
        group
          .selectAll(`.${className}`)
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", operation === "hide" ? 0 : 0.4)
          .call(endAll, cb);
      };

      const listData = {
        lines: [
          [
            [692, 195],
            [805, 195],
          ],
          [
            [692, 710],
            [805, 710],
          ],
          [
            [800, 195],
            [800, 455],
          ],
          [
            [800, 710],
            [800, 455],
          ],
          [
            [800, 455],
            [1280, 455],
          ],
        ],
      };
      const circlegroup = currentProcess
        .append("g")
        .attr("id", "circle-group")
        .attr("class", "circle-group")
        .style("opacity", 1);

      const lineGroup = currentProcess
        .append("g")
        .attr("id", "line-group")
        .attr("class", "line-group")
        .style("opacity", 1);

      listData.lines.forEach((el, i) => {
        let index = i;
        const line = addDashedLine(
          lineGroup,
          el,
          `screen-2 process-line-${i}`,
          "#ffffff",
          "8,4",
          1,
          false
        );
        line.attr("stroke-dashoffset", (d) => d.pathLength);
      });

      lineGroup.attr("transform", "translate(5,-15)");

      const rectGroup = currentProcess
        .append("g")
        .attr("id", "rect-group")
        .attr("class", "rect-group");

      circlegroup
        .append("circle")
        .attr("cx", 1360)
        .attr("cy", 440)
        .attr("r", 340)
        .style("fill", "#815ECF")
        .style("opacity", 0)
        .attr("class", "screen-2-fadedcircle");

      circlegroup.attr("transform", "translate(100,0)");

      const challengesforeigndataobject = {
        dataContent: [
          {
            background: {
              id: "solution-1",
              width: "642px",
              height: "745px",
              html: `<h5 class="position-relative">Challenges</h5>
                <ul>
                  <li>
                    <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span><strong>Lengthy and expensive</strong> process</span>
                  </li>
                  <li>
                      <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span><strong>Poor Prediction</strong></span>
                  </li>
                  <li>
                      <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span><strong>Overly-exuberant Consumers</strong> in primary research</span>
                  </li>
                  <li>
                      <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span>Small launches & lack of <strong>extensions budget</strong></span>
                  </li>
                  <li>
                      <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span>Items across categories<strong> interaction ignorence</strong></span>
                  </li>
                  <li>
                      <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span><strong>Non innovative</strong> Single-tasker forcasting</span>
                  </li>
                  <li class="border-0">
                      <img src="../../../assets/images/atlas-intelligence-kraft/pointer.svg" alt="?">
                    <span>Mis-represented <strong>Support and Distribution</strong></span>
                  </li>
                </ul>`,
              x: 50,
              y: 80,
              class: "challenges",
              s: 1,
              o: 1,
            },
          },
        ],
      };

      const dataSummaryData = {
        dataContent: [
          {
            background: {
              id: "arrow-right-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 975,
              y: 430,
              finalX: 975,
              finalY: 430,
              class: "arrow-right-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arrow-right-2",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 1265,
              y: 430,
              finalX: 1265,
              finalY: 430,
              class: "arrow-right-2",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arrow-down-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 815,
              y: 293,
              finalX: 815,
              finalY: 293,
              cx: 45,
              class: "arrow-down-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arrow-up-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 795,
              y: 560,
              finalX: 795,
              finalY: 560,
              class: "arrow-up-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-arrow-1",
              width: 25,
              height: 25,
              src: "assets/images/atlas-intelligence-kraft/BoldArrow.svg",
              x: 1345,
              y: 147,
              finalX: 1345,
              finalY: 147,
              class: "arc-arrow-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-arrow-2",
              width: 25,
              height: 25,
              src: "assets/images/atlas-intelligence-kraft/BoldArrow.svg",
              x: 1705,
              y: 240,
              finalX: 1705,
              finalY: 240,
              class: "arc-arrow-2",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-arrow-3",
              width: 25,
              height: 25,
              src: "assets/images/atlas-intelligence-kraft/BoldArrow.svg",
              x: 1720,
              y: 625,
              finalX: 1720,
              finalY: 625,
              class: "arc-arrow-3",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-arrow-4",
              width: 25,
              height: 25,
              src: "assets/images/atlas-intelligence-kraft/BoldArrow.svg",
              x: 1374,
              y: 742,
              finalX: 1374,
              finalY: 742,
              class: "arc-arrow-4",
              s: 1,
              o: 0,
            },
          },

          /*{
            background: {
              id: "question-mark",
              width: 25,
              height: 25,
              src: "assets/images/atlas-intelligence-kraft/icon_question_mark.svg",
              x: d3.select('#chlng-rectangle-1').node().getBBox().x+20,
              y: d3.select('#chlng-rectangle-1').node().getBBox().y+50,
              finalX: d3.select('#chlng-rectangle-1').node().getBBox().x+20,
              finalY: d3.select('#chlng-rectangle-1').node().getBBox().y+50,
              class:
                "",
              s: 1,
              o: 0,
            },
          },
          */
        ],
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = rectGroup
          .append("g")
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            class: `rect-group rect-group-${index}`,
            id: `rect-group-${index}`,
            transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
          })
          .style("opacity", (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      const foreigndataobject = {
        dataContent: [
          {
            background: {
              id: "solution-1",
              width: "200px",
              height: "220px",
              html: `<div class="case case_1">
              <img src="../../../assets/images/atlas-intelligence-kraft/icon_idea_screening.svg" alt="">
              <span>Idea Screening
                & Optimization</span>
              </div>`,
              x: 1020,
              y: 174,
              class: "screen-2-solution-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "solution-2",
              width: "180px",
              height: "220px",
              html: `<div class="case case_2">
              <img src="../../../assets/images/atlas-intelligence-kraft/icon_formula.svg" alt="">
              <span>Product
                Formulation</span>
              </div>`,
              x: 1366,
              y: 55,
              class: "screen-2-solution-2",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "solution-4",
              width: "240px",
              height: "220px",
              html: `<div class="case case_4">
              <img src="../../../assets/images/atlas-intelligence-kraft/icon_launch_plan.svg" alt="">
              <span>Launch Forecast
                & Planning</span>
              </div>`,
              x: 1521,
              y: 350,
              class: "screen-2-solution-4 ml-5",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "solution-5",
              width: "180px",
              height: "240px",
              html: `<div class="case case_5">
              <img src="../../../assets/images/atlas-intelligence-kraft/icon_price_tag.svg" alt="">
              <span>Price &
                Promotion</span>
              </div>`,
              x: 1365,
              y: 590,
              class: "screen-2-solution-5",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "solution-6",
              width: "200px",
              height: "220px",
              html: `<div class="case case_6">
              <img src="../../../assets/images/atlas-intelligence-kraft/icon_brand_growth.svg" alt="">
              <span>Brand Growth<br> & Renovation</span>
              </div>`,
              x: 1023,
              y: 525,
              class: "screen-2-solution-6",
              s: 1,
              o: 0,
            },
          },
        ],
      };

      const arcgroupobject = {
        dataContent: [
          {
            background: {
              id: "arc-1",
              ir: 250,
              or: 255,
              x: 1350,
              y: 395,
              startangle: -45 * (Math.PI / 180),
              endangle: (10 * Math.PI) / 180,
              class: "screen-2-arc-1",
              s: 0,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-2",
              ir: 275,
              or: 280,
              x: 1380,
              y: 430,
              startangle: (25 * Math.PI) / 180,
              endangle: (80 * Math.PI) / 180,
              class: "screen-2-arc-2",
              s: 0,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-3",
              ir: 250,
              or: 255,
              x: 1400,
              y: 465,
              startangle: (80 * Math.PI) / 180,
              endangle: (180 * Math.PI) / 180,
              class: "screen-2-arc-3",
              s: 0,
              o: 0,
            },
          },
          {
            background: {
              id: "arc-4",
              ir: 320,
              or: 315,
              x: 1370,
              y: 420,
              startangle: (180 * Math.PI) / 180,
              endangle: (235 * Math.PI) / 180,
              class: "screen-2-arc-4",
              s: 0,
              o: 0,
            },
          },
        ],
      };

      challengesforeigndataobject.dataContent.forEach((content, index) => {
        const group = rectGroup
          .append("foreignObject")
          .attr("x", content.background.x)
          .attr("y", content.background.y)
          .attr("width", content.background.width)
          .attr("height", content.background.height)
          .append("xhtml:div")
          .attr("class", content.background.class + " animate")
          .html(content.background.html);
      });

      lineGroup
        .append("text")
        .text("Solution")
        .attr("class", "solutiontext animate")
        .attr("x", 930)
        .attr("y", 417)
        .attr("dy", ".71em")
        .style("fill", "#ffffff")
        .style("font-size", "26px")
        .style("opacity", 0);

      arcgroupobject.dataContent.forEach((content, index) => {
        const group = circlegroup
          .append("g")
          .attr(
            "transform",
            `translate(${content.background.x},${content.background.y})`
          );

        var arc = d3.svg
          .arc()
          .innerRadius(content.background.ir)
          .outerRadius(content.background.or)
          .startAngle(content.background.startangle)
          .endAngle(content.background.endangle);

        const path = group
          .append("path")
          .attr("class", content.background.class + " animate")
          .datum({})
          .attr("d", arc)
          .attr("stroke", "#E8E8E9")
          .attr("stroke-width", 4);
        console.log(path);
        path
          .attr("stroke-dasharray", function (d: any) {
            d.pathLength = d3.select(this).node().getTotalLength();
            return d.pathLength;
          })
          .attr("stroke-dashoffset", function (d: any) {
            d.finalDashoffset = d.pathLength / 2;
            return d.pathLength;
          })
          .attr("fill", "none")

          .style("opacity", 1);
      });

      foreigndataobject.dataContent.forEach((content, index) => {
        const group = circlegroup
          .append("foreignObject")
          .attr("x", content.background.x)
          .attr("y", content.background.y)
          .attr("width", content.background.width)
          .attr("height", content.background.height)
          .append("xhtml:div")
          .attr("class", content.background.class + " animate")
          .html(content.background.html)
          .style("opacity", content.background.o);
      });

      function initiateAnimation() {
        const htmlDelayValue = 5500;
        dataGroup.select(".engineWrapper").style("opacity", 1);
        processGraph.style("opacity", 0);
        focusrings.style("opacity", 0);
        if (that.screen2vistno > 0) {
          startEngineRotation(true, 0, 0);
          animateForecastingEngine(2, 0, 0, "draw", function () {});
        }

        challengesforeigndataobject.dataContent.forEach((content, index) => {
          rectGroup
            .select("." + content.background.class)
            .attr("class", content.background.class + " in" + " animate");
        });

        operateLine(
          lineGroup,
          "process-line-0",
          "draw",
          2500,
          500,
          function () {
            operateLine(
              lineGroup,
              "process-line-2",
              "draw",
              0,
              500,
              function () {}
            );
            showrotatedImage(
              rectGroup,
              "arrow-down-1",
              "draw",
              100,
              500,
              90,
              function () {}
            );
          }
        );
        operateLine(
          lineGroup,
          "process-line-1",
          "draw",
          2500,
          500,
          function () {
            operateLine(
              lineGroup,
              "process-line-3",
              "draw",
              0,
              500,
              function () {}
            );
            showrotatedImage(
              rectGroup,
              "arrow-up-1",
              "draw",
              100,
              500,
              -90,
              function () {}
            );

            lineGroup
              .select(".solutiontext")
              .transition()
              .delay(600)
              .duration(500)
              .style("opacity", 1);
            operateLine(
              lineGroup,
              "process-line-4",
              "draw",
              500,
              500,
              function () {}
            );
            showImage(
              rectGroup,
              "arrow-right-1",
              "draw",
              600,
              200,
              function () {}
            );

            showImage(
              rectGroup,
              "arrow-right-2",
              "draw",
              800,
              200,
              function () {
                if (that.screen2vistno === 0)
                  animateForecastingEngine(2, 0, 500, "draw", function () {});

                operateCircle(
                  circlegroup,
                  "screen-2-fadedcircle",
                  "draw",
                  0,
                  500,
                  function () {}
                );
                var solutiondelay = 500;
                foreigndataobject.dataContent.forEach((content, index) => {
                  circlegroup
                    .select("." + content.background.class.split(" ")[0])
                    .transition()
                    .delay(solutiondelay)
                    .duration(600)
                    .style("opacity", 1);

                  solutiondelay += 700;
                });

                var arcDelay = 500;
                arcgroupobject.dataContent.forEach((content, index) => {
                  var arc = d3.svg
                    .arc()
                    .innerRadius(content.background.ir)
                    .outerRadius(content.background.or)
                    .startAngle(content.background.startangle)
                    .endAngle(content.background.endangle);

                  circlegroup
                    .select("." + content.background.class)
                    .transition()
                    .delay(arcDelay)
                    .duration(700)
                    .attr("d", arc)
                    .style("opacity", 1)
                    .attr("stroke-dashoffset", function (d: any) {
                      return d.finalDashoffset;
                    });
                  arcDelay += 800;
                });
                showrotatedImage(
                  rectGroup,
                  "arc-arrow-1",
                  "draw",
                  700,
                  200,
                  0,
                  function () {}
                );
                showrotatedImage(
                  rectGroup,
                  "arc-arrow-2",
                  "draw",
                  1500,
                  200,
                  70,
                  function () {}
                );
                showrotatedImage(
                  rectGroup,
                  "arc-arrow-3",
                  "draw",
                  2500,
                  200,
                  160,
                  function () {}
                );
                showrotatedImage(
                  rectGroup,
                  "arc-arrow-4",
                  "draw",
                  3100,
                  200,
                  230,
                  function () {
                    startEngineRotation(true, 200, 0);
                  }
                );
              }
            );
          }
        );
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = "";

          currentProcess.style("opacity", 1);

          image.attr("xlink:href", baseImage);

          currentProcess.attr("class", "screen-2-group");
          initiateAnimation();
        },
        stopAnimation() {
          that.screen2vistno++;
          currentProcess.attr("class", "screen-2-group d-none");
          currentProcess.selectAll(".animate").interrupt();
          dataGroup.select(".forecastingEngineGroup").interrupt();

          operateLine(
            lineGroup,
            "process-line-0",
            "draw",
            0,
            0,
            function () {}
          ),
            operateLine(
              lineGroup,
              "process-line-1",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-2",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-3",
              "draw",
              0,
              0,
              function () {}
            ),
            lineGroup.select(".solutiontext").style("opacity", 1),
            operateLine(
              lineGroup,
              "process-line-4",
              "draw",
              0,
              0,
              function () {}
            );
          animateForecastingEngine(2, 0, 0, "draw", function () {});

          foreigndataobject.dataContent.forEach((content, index) => {
            circlegroup
              .select("." + content.background.class.split(" ")[0])
              .style("opacity", 1);
          }),
            arcgroupobject.dataContent.forEach((content, index) => {
              var arc = d3.svg
                .arc()
                .innerRadius(content.background.ir)
                .outerRadius(content.background.or)
                .startAngle(content.background.startangle)
                .endAngle(content.background.endangle);

              circlegroup
                .select("." + content.background.class)
                .attr("d", arc)
                .style("opacity", 1)
                .attr("stroke-dashoffset", function (d: any) {
                  return d.finalDashoffset;
                });
            }),
            showrotatedImage(
              rectGroup,
              "arrow-up-1",
              "draw",
              0,
              0,
              -90,
              function () {}
            ),
            showrotatedImage(
              rectGroup,
              "arrow-down-1",
              "draw",
              0,
              0,
              90,
              function () {}
            ),
            showImage(rectGroup, "arrow-right-1", "draw", 0, 0, function () {}),
            showImage(rectGroup, "arrow-right-2", "draw", 0, 0, function () {}),
            showrotatedImage(
              rectGroup,
              "arc-arrow-1",
              "draw",
              0,
              0,
              0,
              function () {}
            ),
            showrotatedImage(
              rectGroup,
              "arc-arrow-2",
              "draw",
              0,
              0,
              70,
              function () {}
            ),
            showrotatedImage(
              rectGroup,
              "arc-arrow-3",
              "draw",
              0,
              0,
              160,
              function () {}
            ),
            showrotatedImage(
              rectGroup,
              "arc-arrow-4",
              "draw",
              0,
              0,
              230,
              function () {}
            ),
            operateCircle(
              circlegroup,
              "screen-2-fadedcircle",
              "draw",
              0,
              0,
              function () {}
            ),
            currentProcess.style("opacity", 0);

          that.animationStarted.screen2 = true;
        },
      };
    })();

    const screenThree = (() => {
      const currentProcess = dataGroup
        .append("g")
        .attr("id", "screen-3-group")
        .attr("class", "screen-3-group d-none")
        .style("opacity", 1);

      const globeGroup = currentProcess
        .append("g")
        .attr("id", "globe-group")
        .attr("class", "globe-group");

      const rectgroup = globeGroup
        .append("g")
        .attr("id", "screen-3-rect-group")
        .attr("class", "screen-3-rect-group");
      rectgroup
        .append("rect")
        .attr("class", "screen-3-OuterRect animate")
        .attr("transform", `translate(1335,300)`)
        .attr("width", 520)
        .attr("height", 520)
        .attr("stroke", "#ffffff")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("rx", 30)
        .attr("ry", 30)
        .attr("opacity", 0);

      rectgroup
        .append("rect")
        .attr("class", "screen-3-TextRect animate")
        .style("fill", "#ae8ff1")
        .attr("transform", `translate(1505,285)`)
        .attr("width", 200)
        .attr("height", 30)
        .attr("opacity", 0);
      rectgroup
        .append("text")
        .attr("class", "screen-3-RectText animate")
        .text("Forecasting Engine")
        .attr("transform", `translate(1513,290)`)
        .attr("dy", ".71em")
        .style("fill", "#ffffff")
        .style("font-size", "22px")
        .attr("opacity", 0);

      const listData = {
        lines: [
          [
            [470, 390],
            [817, 390],
          ],
          [
            [720, 745],
            [817, 745],
          ],
          [
            [815, 390],
            [815, 560],
          ],
          [
            [815, 745],
            [815, 550],
          ],
          [
            [815, 560],
            [900, 560],
          ],
          [
            [1090, 630],
            [1340, 630],
          ],
        ],
      };
      const lineGroup = currentProcess
        .append("g")
        .attr("id", "line-group")
        .attr("class", "line-group")
        .style("opacity", 1);

      listData.lines.forEach((el, i) => {
        let index = i;
        const line = addDashedLine(
          lineGroup,
          el,
          `screen-3 process-line-${i}`,
          "#ffffff",
          "8,4",
          1,
          false
        );
        line.attr("stroke-dashoffset", (d) => d.pathLength);
      });

      const dataSummaryData = {
        dataContent: [
          {
            background: {
              id: "screen-3-arrow-right-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 625,
              y: 380,
              finalX: 625,
              finalY: 380,
              class: "screen-3-arrow-right-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-arrow-right-2",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 750,
              y: 735,
              finalX: 750,
              finalY: 735,
              class: "screen-3-arrow-right-2",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-arrow-up-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 805,
              y: 675,
              finalX: 805,
              finalY: 675,
              class: "screen-3-arrow-up-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-arrow-down-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 825,
              y: 440,
              finalX: 825,
              finalY: 440,
              class: "screen-3-arrow-down-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-arrow-right-3",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormailArrow.svg",
              x: 1200,
              y: 620,
              finalX: 1200,
              finalY: 620,
              class: "screen-3-arrow-right-3",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block",
              width: 250,
              height: 200,
              src: "assets/images/atlas-intelligence-kraft/data_input_comb.svg",
              x: 870,
              y: 500,
              finalX: 870,
              finalY: 500,
              class: "screen-3-data-block",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-1",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 900,
              y: 535,
              finalX: 900,
              finalY: 535,
              class: "screen-3-data-block-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-2",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 965,
              y: 500,
              finalX: 965,
              finalY: 500,
              class: "screen-3-data-block-2",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-3",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 1030,
              y: 535,
              finalX: 1030,
              finalY: 535,
              class: "screen-3-data-block-3",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-4",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 900,
              y: 600,
              finalX: 900,
              finalY: 600,
              class: "screen-3-data-block-4",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-5",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 965,
              y: 570,
              finalX: 965,
              finalY: 570,
              class: "screen-3-data-block-5",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-6",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 1030,
              y: 600,
              finalX: 1030,
              finalY: 600,
              class: "screen-3-data-block-6",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-data-block-7",
              width: 60,
              height: 60,
              src: "assets/images/atlas-intelligence-kraft/data-block.svg",
              x: 965,
              y: 640,
              finalX: 965,
              finalY: 640,
              class: "screen-3-data-block-7",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-3-Node-graph",
              width: 160,
              height: 150,
              src: "assets/images/atlas-intelligence-kraft/Nodes.svg",
              x: 1520,
              y: 475,
              finalX: 1520,
              finalY: 475,
              class: "screen-3-Node-graph",
              s: 1,
              o: 0,
            },
          },
        ],
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append("g")
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            class: `globe-group globe-group-${index}`,
            id: `globe-group-${index}`,
            transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
          })
          .style("opacity", (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      const foreigndataobject = {
        dataContent: [
          {
            background: {
              id: "screen-3-products-input-1",
              width: "388px",
              height: "400px",
              html: `
              <div class="wrapper d-flex align-items-center">
                <div class="product-wrapper d-flex flex-column justify-content-center">
                  <img src="../../../assets/images/atlas-intelligence-kraft/product.svg" alt="Product">
                  <span>Product A</span>
                </div>
                <ul class="input-list mb-0">
                  <li>Brand</li>
                  <li>Category</li>
                  <li>Ingredients</li>
                  <li>Nutrients</li>
                  <li>Price</li>
                  <li>Discount</li>
                </ul>
              </div>
              <h5 class="position-relative">Product Inputs</h5>
            `,
              x: 80,
              y: 220,
              class: "product-inputs",
              s: 1,
              o: 1,
            },
          },
          {
            background: {
              id: "screen-3-source-input-1",
              width: "388px",
              height: "410px",
              html: `
              <h5 class="position-relative">Data Sources</h5>
              <div class="wrapper d-flex align-items-center justify-content-center">
                <ul class="input-list mb-0 pl-0">
                  <li>Label Data - Syndigo, Label Insights</li>
                  <li>MR Data - Brand Perception</li>
                  <li>Media Spend Data - Kantar</li>
                  <li class="mb-0">Sales Data - Neilson, iRI, Spins</li>
                </ul>
              </div>
            `,
              x: 328,
              y: 579,
              class: "source-inputs mt-2 pt-0",
              s: 1,
              o: 1,
            },
          },
        ],
      };

      const lineAttribues = [
        { sourceX: 958, sourceY: 547, destinationX: 980, destinationY: 538 },
        { sourceX: 1010, sourceY: 538, destinationX: 1032, destinationY: 547 },
        { sourceX: 995, sourceY: 560, destinationX: 995, destinationY: 575 },
        { sourceX: 945, sourceY: 571, destinationX: 968, destinationY: 583 },
        { sourceX: 1022, sourceY: 583, destinationX: 1043, destinationY: 571 },
        { sourceX: 947, sourceY: 643, destinationX: 967, destinationY: 653 },
        { sourceX: 1023, sourceY: 652, destinationX: 1045, destinationY: 640 },
        { sourceX: 958, sourceY: 614, destinationX: 975, destinationY: 606 },
      ];

      foreigndataobject.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append("foreignObject")
          .attr("x", content.background.x)
          .attr("y", content.background.y)
          .attr("width", content.background.width)
          .attr("height", content.background.height)
          .append("xhtml:div")
          .attr({
            class: content.background.class + " animate",
          })
          .html(content.background.html);
      });
      lineGroup
        .append("text")
        .text("Data Input Combinations")
        .attr("class", "inputCombinationsText animate")
        .attr("x", 900)
        .attr("y", 455)
        .attr("dy", ".71em")
        .style("fill", "#fff")
        .style("font-size", "20px")
        .style("opacity", 0);
      lineAttribues.forEach((line, index) => {
        lineGroup
          .append("line")
          .style("stroke", "#fff")
          .style("stroke-width", 2)
          .attr("x1", line.sourceX)
          .attr("y1", line.sourceY)
          .attr("x2", line.destinationX)
          .attr("y2", line.destinationY)
          .attr("class", `data-block-line-${index} animate`)
          .style("opacity", 0);
      });

      this.playgroundbuttonContainer.nativeElement.style.opacity = 0;

      function initiateAnimation() {
        const htmlDelayValue = 500;

        var delayforeignobject = 100;

        console.log(that.screen3visitno);

        processGraph.style("opacity", 0);
        focusrings.style("opacity", 0);
        foreigndataobject.dataContent.forEach((content, index) => {
          globeGroup
            .select("." + content.background.class.split(" ")[0])
            .transition()
            .delay(delayforeignobject)
            .duration(100)
            .attr("class", content.background.class + " in" + " animate");
        });

        animateForecastingEngine(
          3,
          0,
          that.screen3visitno > 0 ? 0 : 500,
          "draw",
          function () {
            if (that.screen3visitno > 0) {
              dataGroup
                .select(".engineWrapper")
                .select(".forecastingEngineGroup")
                .style("opacity", 0.8);

              dataGroup
                .select(".engineWrapper")
                .select(".EngineFader")
                .attr("r", 104)
                .attr("transform", `translate(1593,558)`)
                .style("opacity", 0.5);

              startEngineRotation(true, 0, 0);
            }
            operateLine(
              lineGroup,
              "process-line-0",
              "draw",
              3500,
              300,
              function () {
                operateLine(
                  lineGroup,
                  "process-line-2",
                  "draw",
                  0,
                  300,
                  function () {}
                );
                showrotatedImage(
                  globeGroup,
                  "screen-3-arrow-down-1",
                  "draw",
                  200,
                  300,
                  90,
                  function () {}
                );
              }
            );
            showrotatedImage(
              globeGroup,
              "screen-3-arrow-right-1",
              "draw",
              4700,
              300,
              0,
              function () {}
            );
            showrotatedImage(
              globeGroup,
              "screen-3-arrow-right-2",
              "draw",
              4700,
              300,
              0,
              function () {}
            );
            operateLine(
              lineGroup,
              "process-line-1",
              "draw",
              3500,
              300,
              function () {
                operateLine(
                  lineGroup,
                  "process-line-3",
                  "draw",
                  0,
                  300,
                  function () {
                    operateLine(
                      lineGroup,
                      "process-line-4",
                      "draw",
                      0,
                      300,
                      function () {
                        lineGroup
                          .select(".inputCombinationsText")
                          .transition()
                          .delay(100)
                          .duration(300)
                          .style("opacity", 1);
                        showImage(
                          globeGroup,
                          "screen-3-data-block-1",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        showImage(
                          globeGroup,
                          "screen-3-data-block-2",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        showImage(
                          globeGroup,
                          "screen-3-data-block-3",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        showImage(
                          globeGroup,
                          "screen-3-data-block-4",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        showImage(
                          globeGroup,
                          "screen-3-data-block-5",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        showImage(
                          globeGroup,
                          "screen-3-data-block-6",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        showImage(
                          globeGroup,
                          "screen-3-data-block-7",
                          "draw",
                          100,
                          300,
                          function () {}
                        );
                        lineAttribues.forEach((line, index) => {
                          lineGroup
                            .select(`.data-block-line-${index}`)
                            .transition()
                            .delay(200)
                            .duration(300)
                            .style("opacity", 1);
                        });
                        operateLine(
                          lineGroup,
                          "process-line-5",
                          "draw",
                          500,
                          300,
                          function () {
                            rectgroup
                              .select(".screen-3-RectText")
                              .transition()
                              .delay(0)
                              .duration(200)
                              .style("opacity", 1);

                            rectgroup
                              .select(".screen-3-TextRect")
                              .transition()
                              .delay(0)
                              .duration(200)
                              .style("opacity", 1);

                            rectgroup
                              .select(".screen-3-OuterRect")
                              .transition()
                              .delay(0)
                              .duration(200)
                              .style("opacity", 1);

                            if (that.screen3visitno === 0) {
                              dataGroup
                                .select(".engineWrapper")
                                .select(".forecastingEngineGroup")
                                .transition()
                                .duration(500)
                                .style("opacity", 0.8)
                                .call(endAll, () => {
                                  dataGroup
                                    .select(".engineWrapper")
                                    .select(".EngineFader")
                                    .attr("r", 104)
                                    .attr("transform", `translate(1593,558)`)
                                    .transition()
                                    .duration(500)
                                    .style("opacity", 0.5)
                                    .call(endAll, () => {
                                      startEngineRotation(true, 0, 0);
                                    });
                                });
                            }
                          }
                        );
                        showrotatedImage(
                          globeGroup,
                          "screen-3-arrow-right-3",
                          "draw",
                          520,
                          200,
                          0,
                          function () {}
                        );
                      }
                    );
                  }
                );
                showrotatedImage(
                  globeGroup,
                  "screen-3-arrow-up-1",
                  "draw",
                  200,
                  200,
                  -90,
                  function () {}
                );
                showImage(
                  globeGroup,
                  "screen-3-Node-graph",
                  "draw",
                  2000,
                  500,
                  function () {
                    that.playgroundbuttonContainer.nativeElement.style.opacity = 1;
                  }
                );
              }
            );
          }
        );
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = "";

          currentProcess.style("opacity", 1);

          image.attr("xlink:href", baseImage);

          currentProcess.attr("class", "screen-3-group");
          initiateAnimation();
        },
        stopAnimation() {
          that.screen3visitno++;
          currentProcess.attr("class", "screen-3-group d-none");
          that.playgroundbuttonContainer.nativeElement.style.opacity = 1;
          currentProcess.selectAll(".animate").interrupt();
          dataGroup.select(".forecastingEngineGroup").interrupt();

          operateLine(
            lineGroup,
            "process-line-0",
            "draw",
            0,
            0,
            function () {}
          ),
            operateLine(
              lineGroup,
              "process-line-1",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-2",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-3",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-4",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-5",
              "draw",
              0,
              0,
              function () {}
            ),
            lineGroup.select(".inputCombinationsText").style("opacity", 1),
            lineAttribues.forEach((line, index) => {
              lineGroup.select(`.data-block-line-${index}`).style("opacity", 1);
            });

          showrotatedImage(
            globeGroup,
            "screen-3-arrow-right-1",
            "draw",
            0,
            0,
            0,
            function () {}
          ),
            showrotatedImage(
              globeGroup,
              "screen-3-arrow-right-2",
              "draw",
              0,
              0,
              0,
              function () {}
            ),
            showrotatedImage(
              globeGroup,
              "screen-3-arrow-down-1",
              "draw",
              0,
              0,
              90,
              function () {}
            ),
            showrotatedImage(
              globeGroup,
              "screen-3-arrow-up-1",
              "draw",
              0,
              0,
              -90,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-1",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-2",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-3",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-4",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-5",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-6",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-data-block-7",
              "draw",
              0,
              0,
              function () {}
            ),
            showrotatedImage(
              globeGroup,
              "screen-3-arrow-right-3",
              "draw",
              0,
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "screen-3-Node-graph",
              "draw",
              0,
              0,
              function () {}
            );

          rectgroup.select(".screen-3-RectText").style("opacity", 1);
          rectgroup.select(".screen-3-TextRect").style("opacity", 1);
          rectgroup.select(".screen-3-OuterRect").style("opacity", 1);
          animateForecastingEngine(3, 0, 0, "draw", function () {});

          currentProcess
            .transition()
            .delay(100)
            .duration(200)
            .style("opacity", 0);

          that.animationStarted.screen3 = true;
        },
      };
    })();

    const screenFour = (() => {
      const currentProcess = dataGroup
        .append("g")
        .attr("id", "screen-4-group")
        .attr("class", "screen-4-group d-none")
        .style("opacity", 1);

      const globeGroup = currentProcess
        .append("g")
        .attr("id", "globe-group")
        .attr("class", "globe-group");

      const process2Graph = currentProcess
        .append("g")
        .attr("id", "process2Graph")
        .attr("class", "process2Graph");

      const process2Nodes = process2Graph
        .append("g")
        .attr("id", "process2GraphNodes")
        .attr("class", "process2GraphNodes");

      const process2Rects = process2Graph
        .append("g")
        .attr("id", "process2GraphRects")
        .attr("class", "process2GraphRects");

      const process2Lines = process2Graph
        .append("g")
        .attr("id", "process2GraphLines")
        .attr("class", "process2GraphLines");

      const process2Texts = process2Graph
        .append("g")
        .attr("id", "process2GraphTexts")
        .attr("class", "process2GraphTexts");

      const ringGroup = currentProcess
        .append("g")
        .attr("id", "ringGroup")
        .attr("class", "ringGroup");

      const dataSummaryData = {
        dataContent: [
          {
            background: {
              id: "node_info_1",
              width: 140,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 660 + 500,
              y: 260,
              finalX: 660 + 500,
              finalY: 260,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_2",
              width: 140,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 1020 + 500,
              y: 200,
              finalX: 1020 + 500,
              finalY: 200,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_3",
              width: 140,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 655 + 500,
              y: 520,
              finalX: 655 + 500,
              finalY: 520,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_4",
              width: 140,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 1020 + 500,
              y: 450,
              finalX: 1020 + 500,
              finalY: 450,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_5",
              width: 140,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 1070 + 500,
              y: 640,
              finalX: 1070 + 500,
              finalY: 640,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_6",
              width: 140,
              height: 140,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 800 + 500,
              y: 640,
              finalX: 800 + 500,
              finalY: 640,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "mu1",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/icon_mu.svg",
              x: 1020,
              y: 380,
              finalX: 1020,
              finalY: 380,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "mu2",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/icon_mu.svg",
              x: 1035,
              y: 540,
              finalX: 1035,
              finalY: 540,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "mu3",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/icon_mu.svg",
              x: 1013,
              y: 697,
              finalX: 1013,
              finalY: 697,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "mu4",
              width: 30,
              height: 30,
              src: "assets/images/atlas-intelligence-kraft/icon_mu.svg",
              x: 797,
              y: 555,
              finalX: 797,
              finalY: 555,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_7",
              width: 120,
              height: 120,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 235,
              y: 270,
              finalX: 235,
              finalY: 270,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_8",
              width: 120,
              height: 120,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 385,
              y: 270,
              finalX: 385,
              finalY: 270,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_9",
              width: 120,
              height: 120,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 410,
              y: 465,
              finalX: 410,
              finalY: 465,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_10",
              width: 120,
              height: 120,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 130,
              y: 510,
              finalX: 130,
              finalY: 510,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_11",
              width: 120,
              height: 120,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 230,
              y: 670,
              finalX: 230,
              finalY: 670,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "node_info_12",
              width: 120,
              height: 120,
              src: "assets/images/atlas-intelligence-kraft/node_info.svg",
              x: 430,
              y: 690,
              finalX: 430,
              finalY: 690,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-6-arrow-1",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/NormalArrow.svg",
              x: 1770,
              y: 525,
              finalX: 1770,
              finalY: 525,
              class: "screen-6-arrow-1",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-6-iota",
              width: 20,
              height: 20,
              src: "assets/images/atlas-intelligence-kraft/iota.svg",
              x: 495,
              y: 445,
              finalX: 495,
              finalY: 445,
              class: "screen-6-iota",
              s: 1.25,
              o: 0,
            },
          },
        ],
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        if (index <= 5 || index >= 10) {
          const group = globeGroup
            .append("g")
            .datum({
              x: 0,
              y: 0,
              finalX: 0,
              finalY: 0,
              s: 1,
              o: 1,
            })
            .attr({
              class: `globe-group globe-group-${index}`,
              id: `globe-group-${index}`,
              transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
            })
            .style("opacity", (d: any) => d.o);

          createImageUsingData(group, content.background);
        } else {
          const group = process2Graph
            .append("g")
            .datum({
              x: 0,
              y: 0,
              finalX: 0,
              finalY: 0,
              s: 1,
              o: 1,
            })
            .attr({
              class: `globe-group globe-group-${index}`,
              id: `globe-group-${index}`,
              transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
            })
            .style("opacity", (d: any) => d.o);
          createImageUsingData(group, content.background);
        }
      });

      const foreigndataobject = {
        dataContent: [
          {
            background: {
              id: "screen-5-products-input-1",
              width: "388px",
              height: "400px",
              html: `
              <div class="wrapper d-flex align-items-center">
                <div class="product-wrapper d-flex flex-column justify-content-center">
                  <img src="../../../assets/images/atlas-intelligence-kraft/product.svg" alt="Product">
                  <span>Product A</span>
                </div>
                <ul class="input-list mb-0">
                  <li>Product Weight</li>
                  <li>List Price</li>
                  <li>Media & Spend</li>
                  <li>Discount</li>
                  <li>Units/Pack</li>
                </ul>
              </div>
              <h5 class="position-relative">Numeric Attributes</h5>
          `,
              x: 1313,
              y: 200,
              class: "screen5FO1 product-inputs product-inputs_mod pb-0",
              s: 1,
              o: 1,
            },
          },
          {
            background: {
              id: "screen-5-products-input-2",
              width: "388px",
              height: "400px",
              html: `
              <div class="wrapper d-flex align-items-center">
                <div class="product-wrapper d-flex flex-column justify-content-center">
                  <img src="../../../assets/images/atlas-intelligence-kraft/product.svg" alt="Product">
                  <span>Product A</span>
                </div>
                <ul class="input-list mb-0">
                  <li>Category</li>
                  <li>Sub-Category</li>
                  <li>Manufacturer</li>
                  <li>Brand</li>
                  <li>Storage</li>
                </ul>
              </div>
              <h5 class="position-relative">Categorical Attributes</h5>
            `,
              x: 1313,
              y: 560,
              class: "screen5FO2 product-inputs product-inputs_mod pb-0",
              s: 1,
              o: 1,
            },
          },
          {
            background: {
              id: "screen-5-products-input-3",
              width: "800px",
              height: "100px",
              html: `
              <div class="legends">
                <ul class="input-list input-list p-0 d-flex align-items-center">
                  <li><i class="a">A</i>Citric Acid</li>
                  <li><i class="b">B</i>Whey</li>
                  <li><i class="c">C</i>Dextrose</li>
                  <li><i class="d">D</i>Palm Oil</li>
                  <li><i class="e">E</i>Unbleached Wheat Flour</li>
                  <li><i class="f">F</i>Eggs</li>
                </ul>
              </div>
          `,
              x: 120,
              y: 210,
              class: "screen5FO3",
              s: 1,
              o: 1,
            },
          },
        ],
      };

      foreigndataobject.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append("foreignObject")
          .attr("x", content.background.x)
          .attr("y", content.background.y)
          .attr("width", content.background.width)
          .attr("height", content.background.height)
          .append("xhtml:div")
          .attr({
            class: content.background.class,
          })
          .html(content.background.html)
          .html(content.background.html)
          .style("opacity", 0);
      });

      const legend = currentProcess
        .append("g")
        .attr("class", "processLegend")
        .attr("id", "processLegend");

      const legendNodes = legend
        .append("g")
        .attr("class", "processLegendNodes")
        .attr("id", "processLegendNodes");

      const nodeText = legend
        .append("g")
        .attr("class", "processNodeTexts")
        .attr("id", "processNodeTexts");

      const legendText = legend
        .append("g")
        .attr("class", "processLegendTexts")
        .attr("id", "processLegendTexts");

      const legendNodesData = [
        {
          radius: 25,
          centerX: 350,
          centerY: 370,
          fillColor: "#2867B0",
          nodeText: "A",
          legendText: "Citric Acid",
        },
        {
          radius: 25,
          centerX: 350,
          centerY: 450,
          fillColor: "#E28D2A",
          nodeText: "B",
          legendText: "Whey",
        },
        {
          radius: 25,
          centerX: 350,
          centerY: 530,
          fillColor: "#1CAA44",
          nodeText: "C",
          legendText: "Dextrose",
        },
        {
          radius: 25,
          centerX: 350,
          centerY: 605,
          fillColor: "#B8BA51",
          nodeText: "D",
          legendText: "Palm Oil",
        },
        {
          radius: 25,
          centerX: 350,
          centerY: 680,
          fillColor: "#C73434",
          nodeText: "E",
          legendText: "Unbleached Wheat flour",
        },
        {
          radius: 25,
          centerX: 350,
          centerY: 760,
          fillColor: "#0FB8A4",
          nodeText: "F",
          legendText: "Eggs",
        },
      ];

      legendNodesData.forEach((node, index) => {
        legendNodes
          .append("circle")
          .attr("class", "node" + index)
          .attr("r", node.radius)
          .attr("transform", `translate(${node.centerX},${node.centerY})`)
          .attr("fill", node.fillColor)
          .style("opacity", 0);
        nodeText
          .append("text")
          .attr("class", "nodeText" + index)
          .attr("transform", `translate(${node.centerX},${node.centerY + 10})`)
          .style("text-anchor", "middle")
          .style("font-size", "30px")
          .style("font-weight", "bold")
          .style("fill", "#ffffff")
          .text(node.nodeText)
          .style("opacity", 0);
        legendText
          .append("text")
          .attr("class", "legendText" + index)
          .attr(
            "transform",
            `translate(${node.centerX + 50},${node.centerY + 10})`
          )
          .style("font-size", "20px")
          .style("fill", "#ffffff")
          .text(node.legendText)
          .style("opacity", 0);
      });

      currentProcess
        .append("text")
        .attr("class", "screen4NodeGrpTitle animate")
        .text("Ingredient Nodes")
        .attr("x", 330)
        .attr("y", 310)
        .style("font-size", "20px")
        .style("font-weight", "bold")
        .style("fill", "#ffffff")
        .style("opacity", 0);

      const startX = 880,
        startY = 470,
        endX = 1000,
        endY = 470;
      var arrowTickUp = currentProcess
        .append("line")
        .attr("x1", endX - 25 * Math.cos((45 * Math.PI) / 180))
        .attr("y1", endY - 25 * Math.sin((45 * Math.PI) / 180))
        .attr("x2", endX - 25 * Math.cos((45 * Math.PI) / 180))
        .attr("y2", endY - 25 * Math.sin((45 * Math.PI) / 180))
        .attr("stroke", "white")
        .attr("stroke-width", 3);
      var arrowTickMiddle = currentProcess
        .append("line")
        .attr("x1", startX)
        .attr("y1", startY)
        .attr("x2", startX)
        .attr("y2", startY)
        .attr("stroke", "white")
        .attr("stroke-width", 3);
      var arrowTickDown = currentProcess
        .append("line")
        .attr("x1", endX - 25 * Math.cos((45 * Math.PI) / 180))
        .attr("y1", endY + 25 * Math.sin((45 * Math.PI) / 180))
        .attr("x2", endX - 25 * Math.cos((45 * Math.PI) / 180))
        .attr("y2", endY + 25 * Math.sin((45 * Math.PI) / 180))
        .attr("stroke", "white")
        .attr("stroke-width", 3);

      const listData = {
        lines: [
          [
            [1265, 535],
            [1790, 535],
          ],
          [
            [1735, 360],
            [1735, 535],
          ],
          [
            [1735, 730],
            [1735, 535],
          ],
          [
            [1700, 360],
            [1737, 360],
          ],
          [
            [1700, 730],
            [1737, 730],
          ],
        ],
      };
      const lineGroup = currentProcess
        .append("g")
        .attr("id", "line-group")
        .attr("class", "line-group")
        .style("opacity", 1);

      listData.lines.forEach((el, i) => {
        let index = i;
        const line = addDashedLine(
          lineGroup,
          el,
          `screen5process-line-${i}`,
          "#ffffff",
          "8,4",
          1,
          false
        );
        line.attr("stroke-dashoffset", (d) => d.pathLength);
      });

      globeGroup
        .append("rect")
        .attr("class", "screen-5-OuterRect")
        .attr("transform", `translate(613,265)`)
        .attr("width", 650)
        .attr("height", 530)
        .attr("stroke", "#ffffff")
        .attr("stroke-width", 1)
        .attr("rx", 10)
        .attr("ry", 10)
        .attr("fill", "none")
        .style("opacity", 0);

      globeGroup
        .append("rect")
        .attr("class", "screen-5-TextRect")
        .style("fill", "#8467c1")
        .attr("transform", `translate(680,780)`)
        .attr("width", 185)
        .attr("height", 30)
        .style("opacity", 0);

      globeGroup
        .append("text")
        .attr("class", "screen-5-RectText")
        .text("Graph Embedding")
        .attr("transform", `translate(691,787)`)
        .attr("dy", ".71em")
        .style("fill", "#ffffff")
        .style("font-size", "20px")
        .style("opacity", 0);

      const nodesAttr = [
        {
          name: "A",
          cx: 250,
          cy: 415,
          r: 35,
          fill: "#2867B0",
          layer: 1,
          count: 1,
        },
        {
          name: "B",
          cx: 955,
          cy: 443,
          r: 35,
          fill: "#E28D2A",
          layer: 2,
          count: 1,
        },
        {
          name: "C",
          cx: 955,
          cy: 550,
          r: 35,
          fill: "#1CAA44",
          layer: 2,
          count: 1,
        },
        {
          name: "D",
          cx: 945,
          cy: 665,
          r: 35,
          fill: "#B8BA51",
          layer: 2,
          count: 1,
        },
        {
          name: "E",
          cx: 1215,
          cy: 585,
          r: 25,
          fill: "#C73434",
          layer: 3,
          count: 1,
        },
        {
          name: "F",
          cx: 1180,
          cy: 650,
          r: 25,
          fill: "#0FB8A4",
          layer: 3,
          count: 1,
        },

        {
          name: "A",
          cx: 1100,
          cy: 305,
          r: 25,
          fill: "#2867B0",
          layer: 3,
          count: 1,
        },
        {
          name: "A",
          cx: 1180,
          cy: 470,
          r: 25,
          fill: "#2867B0",
          layer: 3,
          count: 2,
        },
        {
          name: "A",
          cx: 1115,
          cy: 750,
          r: 25,
          fill: "#2867B0",
          layer: 3,
          count: 3,
        },
        {
          name: "B",
          cx: 1220,
          cy: 525,
          r: 25,
          fill: "#E28D2A",
          layer: 3,
          count: 1,
        },
        {
          name: "C",
          cx: 1140,
          cy: 370,
          r: 25,
          fill: "#1CAA44",
          layer: 3,
          count: 1,
        },
      ];
      nodesAttr.forEach((node) => {
        let nodeGroup = process2Nodes
          .append("g")
          .attr("id", "node" + node.name + node.layer + node.count)
          .attr(
            "class",
            "node" + node.name + node.layer + node.count + " d-none"
          )
          .style("cursor", "pointer");
        nodeGroup
          .append("circle")
          .datum(node)
          .attr("class", "node")
          .attr("transform", `translate(${node.cx},${node.cy})`)
          .attr("r", node.r)
          .style("fill", node.fill)
          .style("opacity", 0);

        nodeGroup
          .append("text")
          .datum(node)
          .attr("class", "nodeText")
          .attr("transform", `translate(${node.cx},${node.cy + 10})`)
          .style("text-anchor", "middle")
          .style("font-size", "30px")
          .style("font-weight", "bold")
          .style("fill", "#ffffff")
          .text(node.name)
          .style("opacity", 0);

        nodeGroup
          .on("mouseover", function (event, d) {
            let data;
            if (node.name === "A") data = { name: "Citric Acid" };
            else if (node.name === "B") data = { name: "Whey" };
            else if (node.name === "C") data = { name: "Dextrose" };
            else if (node.name === "D") data = { name: "Palm Oil" };
            else if (node.name === "E")
              data = { name: "Unbleached Wheat FLour" };
            else if (node.name === "F") data = { name: "Eggs" };
            tip.show(data, this);
          })
          .on("mouseout", function (event, d) {
            let data;
            if (node.name === "A") data = { name: "Citric Acid" };
            else if (node.name === "B") data = { name: "Whey" };
            else if (node.name === "C") data = { name: "Dextrose" };
            else if (node.name === "D") data = { name: "Palm Oil" };
            else if (node.name === "E")
              data = { name: "Unbleached Wheat FLour" };
            else if (node.name === "F") data = { name: "Eggs" };
            tip.hide(data, this);
          });
      });

      const rectAttr = [
        { x: 735, y: 510, width: 150, height: 80, rx: 5, ry: 5 },
        { x: 1020, y: 525, width: 60, height: 60, rx: 5, ry: 5 },
        { x: 1005, y: 365, width: 60, height: 60, rx: 5, ry: 5 },
        { x: 1000, y: 685, width: 60, height: 60, rx: 5, ry: 5 },
      ];
      rectAttr.forEach((rect, index) => {
        process2Rects
          .append("rect")
          .attr("class", `graphrect-${index}`)
          .attr("transform", `translate(${rect.x},${rect.y})`)
          .attr("width", rect.width)
          .attr("height", rect.height)
          .attr("fill", "#ffffff")
          .attr("rx", rect.rx)
          .attr("ry", rect.ry)
          .style("opacity", 0);
      });

      const lineAttr = [
        { x1: 710, y1: 550, x2: 735, y2: 550, id: 1 },
        { x1: 885, y1: 550, x2: 920, y2: 550, id: 3 },
        { x1: 990, y1: 550, x2: 1020, y2: 550, id: 5 },
        { x1: 820, y1: 510, x2: 925, y2: 460, id: 2 },
        { x1: 820, y1: 590, x2: 914, y2: 653, id: 4 },
        { x1: 970, y1: 685, x2: 1000, y2: 700, id: 6 },
        { x1: 981, y1: 422, x2: 1004, y2: 410, id: 7 },
        { x1: 1045, y1: 365, x2: 1085, y2: 325, id: 8 },
        { x1: 1065, y1: 385, x2: 1115, y2: 375, id: 9 },
        { x1: 1080, y1: 530, x2: 1160, y2: 485, id: 10 },
        { x1: 1080, y1: 547, x2: 1195, y2: 527, id: 11 },
        { x1: 1080, y1: 563, x2: 1193, y2: 580, id: 12 },
        { x1: 1080, y1: 580, x2: 1163, y2: 633, id: 13 },
        { x1: 1060, y1: 725, x2: 1093, y2: 740, id: 14 },
      ];
      lineAttr.forEach((line) => {
        process2Lines
          .append("line")
          .attr("class", "process2Line" + line.id)
          .attr("x1", line.x1)
          .attr("y1", line.y1)
          .attr("x2", line.x1)
          .attr("y2", line.y1)
          .attr("stroke", "white")
          .attr("stroke-width", 1.5);
      });

      process2Graph
        .append("text")
        .attr("class", "AggregationText")
        .text("Neighborhood Aggregation Function")
        .attr("x", 770)
        .attr("y", 525)
        .attr("dy", ".5em")
        .attr("text-anchor", "middle")
        .style("fill", "black")
        .style("font-size", "14px")
        .call(that.wrap, 160, 810)
        .style("opacity", 0);

      const animateNodeGrpTitle = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        currentProcess
          .select(".screen4NodeGrpTitle")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", operation === "draw" ? 1 : 0)
          .call(endAll, cb);
      };

      const animateLegendNodes = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        legendNodesData.forEach((node, index) => {
          legendNodes
            .select(".node" + index)
            .transition()
            .delay(delay)
            .duration(duration)
            .style("opacity", operation === "draw" ? 1 : 0);
          nodeText
            .select(".nodeText" + index)
            .transition()
            .delay(delay)
            .duration(duration)
            .style("opacity", operation === "draw" ? 1 : 0);
          legendText
            .select(".legendText" + index)
            .transition()
            .delay(delay)
            .duration(duration)
            .style("opacity", operation === "draw" ? 1 : 0)
            .call(
              endAll,
              legendNodesData.length === index + 1 ? cb : function () {}
            );
          delay += duration === 0 || operation === "hide" ? 0 : 250;
        });
      };

      const animateArrow = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        arrowTickUp
          .style("opacity", operation === "draw" ? 1 : 0)
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("x2", endX)
          .attr("y2", endY);
        arrowTickMiddle
          .style("opacity", operation === "draw" ? 1 : 0)
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("x2", endX)
          .attr("y2", endY);
        arrowTickDown
          .style("opacity", operation === "draw" ? 1 : 0)
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("x2", endX)
          .attr("y2", endY)
          .call(endAll, cb);
      };

      const animateNodeInfoRightGrp = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        for (var i = 1; i <= 6; i++) {
          showImage(
            globeGroup,
            "node_info_" + i,
            operation,
            delay,
            duration,
            i === 6 ? cb : function () {}
          );
          delay += duration === 0 || operation === "hide" ? 0 : 100;
        }
      };

      const animateNodeInfoLeftGrp = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        for (var i = 7; i <= 12; i++) {
          showImage(
            globeGroup,
            "node_info_" + i,
            operation,
            delay,
            duration,
            i === 12 ? cb : function () {}
          );
          delay += duration === 0 || operation === "hide" ? 0 : 100;
        }
      };

      const animateNodeIota = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        showImage(globeGroup, "screen-6-iota", operation, delay, duration, cb);
        globeGroup
          .select(".screen-6-iota")
          .style("cursor", "pointer")
          .on("mouseover", function (event, d) {
            let data = { name: "BERT based Ingredients Embedding" };
            iotaTip.show(data, this);
          })
          .on("mouseout", function (event, d) {
            let data = { name: "BERT based Ingredients Embedding" };
            iotaTip.hide(data, this);
          });

        console.log(globeGroup.select(".screen-6-iota"));
      };

      const animateLegendFO = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        foreigndataobject.dataContent.forEach((content, index) => {
          if (index === 2) {
            globeGroup
              .select("." + content.background.class.split(" ")[0])
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", operation === "draw" ? 1 : 0)
              .call(endAll, cb);
          }
        });
      };

      const animateProcess2GNN = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        const rootNode = [
          {
            name: "A",
            cx: 675,
            cy: 550,
            r: 35,
            fill: "#2867B0",
            layer: 1,
            count: 1,
          },
        ];
        const lineGrp1 = [{ x1: 710, y1: 550, x2: 735, y2: 550 }];
        const rectGrp1 = [
          { x: 735, y: 510, width: 150, height: 80, rx: 5, ry: 5 },
        ];
        const lineGrp2 = [
          { x1: 820, y1: 510, x2: 925, y2: 460 },
          { x1: 885, y1: 550, x2: 920, y2: 550 },
          { x1: 820, y1: 590, x2: 914, y2: 653 },
        ];
        const layer2Nodes = [
          {
            name: "B",
            cx: 955,
            cy: 443,
            r: 35,
            fill: "#E28D2A",
            layer: 2,
            count: 1,
          },
          {
            name: "C",
            cx: 955,
            cy: 550,
            r: 35,
            fill: "#1CAA44",
            layer: 2,
            count: 1,
          },
          {
            name: "D",
            cx: 945,
            cy: 665,
            r: 35,
            fill: "#B8BA51",
            layer: 2,
            count: 1,
          },
        ];
        const lineGrp3 = [
          { x1: 990, y1: 550, x2: 1020, y2: 550 },
          { x1: 970, y1: 685, x2: 1000, y2: 700 },
          { x1: 981, y1: 422, x2: 1004, y2: 410 },
        ];
        const rectGrp2 = [
          { x: 1020, y: 525, width: 60, height: 60, rx: 5, ry: 5 },
          { x: 1005, y: 365, width: 60, height: 60, rx: 5, ry: 5 },
          { x: 1000, y: 685, width: 60, height: 60, rx: 5, ry: 5 },
        ];
        const lineGrp4 = [
          { x1: 1045, y1: 365, x2: 1085, y2: 325 },
          { x1: 1065, y1: 385, x2: 1115, y2: 375 },
          { x1: 1080, y1: 530, x2: 1160, y2: 485 },
          { x1: 1080, y1: 547, x2: 1195, y2: 527 },
          { x1: 1080, y1: 563, x2: 1193, y2: 580 },
          { x1: 1080, y1: 580, x2: 1163, y2: 633 },
          { x1: 1060, y1: 725, x2: 1093, y2: 740 },
        ];
        const layer3Nodes = [
          {
            name: "A",
            cx: 1100,
            cy: 305,
            r: 25,
            fill: "#2867B0",
            layer: 3,
            count: 1,
          },
          {
            name: "C",
            cx: 1140,
            cy: 370,
            r: 25,
            fill: "#1CAA44",
            layer: 3,
            count: 1,
          },
          {
            name: "A",
            cx: 1180,
            cy: 470,
            r: 25,
            fill: "#2867B0",
            layer: 3,
            count: 2,
          },
          {
            name: "B",
            cx: 1220,
            cy: 525,
            r: 25,
            fill: "#E28D2A",
            layer: 3,
            count: 1,
          },
          {
            name: "E",
            cx: 1215,
            cy: 585,
            r: 25,
            fill: "#C73434",
            layer: 3,
            count: 1,
          },
          {
            name: "F",
            cx: 1180,
            cy: 650,
            r: 25,
            fill: "#0FB8A4",
            layer: 3,
            count: 1,
          },
          {
            name: "A",
            cx: 1115,
            cy: 750,
            r: 25,
            fill: "#2867B0",
            layer: 3,
            count: 3,
          },
        ];

        var id = 1;

        const animateRootNode = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          rootNode.forEach((node, index) => {
            let nodeGroup = process2Nodes
              .select(".node" + node.name + node.layer + node.count)
              .transition()
              .delay(delay)
              .attr("class", "node" + node.name + node.layer + node.count);

            nodeGroup
              .select(".node")

              .transition()
              .delay(delay)
              .duration(duration)
              .attr("transform", `translate(${node.cx},${node.cy}) scale(1)`)
              .style("opacity", 1);

            nodeGroup
              .select(".nodeText")

              .transition()
              .delay(delay)
              .duration(duration)
              .attr(
                "transform",
                `translate(${node.cx},${node.cy + 10}) scale(1)`
              )
              .style("opacity", 1)
              .call(
                endAll,
                rootNode.length === index + 1 ? cb : function () {}
              );
          });
        };

        const animateLineGrp1 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          lineGrp1.forEach((line, index) => {
            process2Lines
              .select(".process2Line" + id)
              .transition()
              .delay(delay)
              .duration(duration)
              .attr("x2", line.x2)
              .attr("y2", line.y2)
              .call(
                endAll,
                lineGrp1.length === index + 1 ? cb : function () {}
              );
            id++;
          });
        };

        const animateRectGrp1 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          rectGrp1.forEach((rect, index) => {
            process2Rects
              .select(`.graphrect-${index}`)
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", 1)
              .call(endAll, function () {
                process2Graph
                  .select(".AggregationText")
                  .transition()
                  .delay(delay)
                  .duration(duration)
                  .style("opacity", 1)
                  .call(endAll, function () {
                    showImage(process2Graph, "mu4", "draw", 0, duration, cb);
                  });
              });
          });
        };

        const animateLineGrp2 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          lineGrp2.forEach((line, index) => {
            process2Lines
              .select(".process2Line" + id)
              .transition()
              .delay(delay)
              .duration(duration)
              .attr("x2", line.x2)
              .attr("y2", line.y2)
              .call(
                endAll,
                lineGrp2.length === index + 1 ? cb : function () {}
              );
            id++;
          });
        };

        const animateNodeGrp2 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          layer2Nodes.forEach((node, index) => {
            let nodeGroup = process2Nodes
              .select(".node" + node.name + node.layer + node.count)
              .transition()
              .delay(delay)
              .attr("class", "node" + node.name + node.layer + node.count);

            nodeGroup
              .select(".node")
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", 1);

            nodeGroup
              .select(".nodeText")
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", 1)
              .call(
                endAll,
                layer2Nodes.length === index + 1 ? cb : function () {}
              );
          });
        };

        const animateLineGrp3 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          lineGrp3.forEach((line, index) => {
            process2Lines
              .select(".process2Line" + id)
              .transition()
              .delay(delay)
              .duration(duration)
              .attr("x2", line.x2)
              .attr("y2", line.y2)
              .call(
                endAll,
                lineGrp3.length === index + 1 ? cb : function () {}
              );
            id++;
          });
        };

        const animateRectGrp2 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          rectGrp2.forEach((rect, index) => {
            process2Rects
              .select(`.graphrect-${index + 1}`)
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", 1);
          });
          for (var i = 1; i <= 3; i++) {
            showImage(
              process2Graph,
              "mu" + i,
              "draw",
              delay,
              duration,
              i === 3 ? cb : function () {}
            );
          }
        };

        const animateLineGrp4 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          let animateLine = (node, line) => {
            node
              .transition()
              .duration(1200)
              .attr("y2", line.y2 + parseInt(Math.random() * 3 + ""))
              .call(endAll, function () {
                animateLine(node, line);
              });
          };

          lineGrp4.forEach((line, index) => {
            var node = process2Lines.select(".process2Line" + id);
            node
              .transition()
              .delay(delay)
              .duration(duration)
              .attr("x2", line.x2)
              .attr("y2", line.y2)
              .call(endAll, function () {
                animateLine(node, line);

                lineGrp4.length === index + 1 ? cb() : function () {};
              });

            id++;
          });
        };

        const animateNodeGrp3 = (
          delay = 0,
          duration,
          operation: "draw" | "hide",
          cb = function () {}
        ) => {
          layer3Nodes.forEach((node, index) => {
            let nodeGroup = process2Nodes
              .select(".node" + node.name + node.layer + node.count)
              .attr("class", "node" + node.name + node.layer + node.count);

            var circleNode = nodeGroup.select(".node");
            circleNode
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", 1);

            let animateNode = (node, txt) => {
              let y = parseInt(Math.random() * 3 + "");
              txt
                .transition()
                .duration(1200)
                .attr(
                  "transform",
                  (d) => `translate(${d.cx},${d.cy + 10 + y})`
                );
              node
                .transition()
                .duration(1200)
                .attr("transform", (d) => `translate(${d.cx},${d.cy + y})`)
                .call(endAll, function () {
                  animateNode(node, txt);
                });
            };

            var circleTxt = nodeGroup.select(".nodeText");
            circleTxt
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", 1)
              .call(endAll, function () {
                animateNode(circleNode, circleTxt);
                layer3Nodes.length === index + 1 ? cb() : function () {};
              });
          });
        };

        animateRootNode(delay, duration, "draw", function () {
          animateLineGrp1(delay, duration, "draw", function () {
            animateRectGrp1(delay, duration, "draw", function () {
              animateLineGrp2(delay, duration, "draw", function () {
                animateNodeGrp2(delay, duration, "draw", function () {
                  animateLineGrp3(delay, duration, "draw", function () {
                    animateRectGrp2(delay, duration, "draw", function () {
                      animateLineGrp4(delay, duration, "draw", function () {
                        animateNodeGrp3(delay, duration, "draw", cb);
                      });
                    });
                  });
                });
              });
            });
          });
        });
      };

      const animateRect = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        globeGroup
          .select(".screen-5-OuterRect")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", operation === "draw" ? 1 : 0);

        globeGroup
          .select(".screen-5-TextRect")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", operation === "draw" ? 1 : 0);

        globeGroup
          .select(".screen-5-RectText")
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", operation === "draw" ? 1 : 0)
          .call(endAll, cb);
      };

      const animateFO = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        foreigndataobject.dataContent.forEach((content, index) => {
          if (index < 2) {
            globeGroup
              .select("." + content.background.class.split(" ")[0])
              .transition()
              .delay(delay)
              .duration(duration)
              .style("opacity", operation === "draw" ? 1 : 0)
              .attr("class", content.background.class + " in")
              .call(endAll, index + 1 === 2 ? cb : function () {});

            delay += duration === 0 ? 0 : 250;
          }
        });
      };

      const animateDashedLines = (
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) => {
        operateLine(
          lineGroup,
          "screen5process-line-0",
          "draw",
          delay,
          duration,
          function () {
            showImage(
              globeGroup,
              "screen-6-arrow-1",
              operation,
              0,
              duration,
              function () {
                operateLine(
                  lineGroup,
                  "screen5process-line-3",
                  operation,
                  0,
                  duration,
                  function () {}
                );
                operateLine(
                  lineGroup,
                  "screen5process-line-4",
                  operation,
                  0,
                  duration,
                  function () {
                    operateLine(
                      lineGroup,
                      "screen5process-line-1",
                      operation,
                      0,
                      duration,
                      function () {}
                    );
                    operateLine(
                      lineGroup,
                      "screen5process-line-2",
                      operation,
                      0,
                      duration,
                      function () {}
                    );
                  }
                );
              }
            );
          }
        );
      };

      function initiateAnimation() {
        const htmlDelayValue = 500;
        if (that.screen4VisitNo > 0) {
          processGraph.style("opacity", 1);
          focusrings.style("opacity", 1);
          animateGNNPosition(4, 0, 0, "draw", function () {});
        }
        animateForecastingEngine(
          4,
          0,
          that.screen4VisitNo > 0 ? 0 : 500,
          "draw",
          function () {
            console.log(+new Date());
            animateDashedRect(
              0,
              that.screen4VisitNo > 0 ? 0 : 500,
              "draw",
              function () {
                console.log(+new Date());
                animateNodeGrpTitle(
                  0,
                  that.screen4VisitNo > 0 ? 0 : 500,
                  that.screen4VisitNo > 0 ? "hide" : "draw",
                  function () {
                    console.log(+new Date());
                    animateLegendNodes(
                      0,
                      that.screen4VisitNo > 0 ? 0 : 500,
                      that.screen4VisitNo > 0 ? "hide" : "draw",
                      function () {
                        console.log(+new Date());
                        animateArrow(
                          0,
                          that.screen4VisitNo > 0 ? 0 : 500,
                          that.screen4VisitNo > 0 ? "hide" : "draw",
                          function () {
                            console.log(+new Date());
                            animateGNNNodesToIntermidiate(
                              0,
                              that.screen4VisitNo > 0 ? 0 : 500,
                              that.screen4VisitNo > 0 ? "hide" : "draw",
                              function () {
                                console.log(+new Date());
                                animateGNNNodesToFinal(
                                  0,
                                  that.screen4VisitNo > 0 ? 0 : 500,
                                  that.screen4VisitNo > 0 ? "hide" : "draw",
                                  function () {
                                    console.log(+new Date());
                                    animateGNNLines(
                                      0,
                                      that.screen4VisitNo > 0 ? 0 : 500,
                                      that.screen4VisitNo > 0 ? "hide" : "draw",
                                      function () {
                                        console.log(+new Date());
                                        animateNodeInfoRightGrp(
                                          0,
                                          that.screen4VisitNo > 0 ? 0 : 500,
                                          that.screen4VisitNo > 0
                                            ? "hide"
                                            : "draw",
                                          function () {
                                            console.log(+new Date());
                                            animateNodeGrpTitle(
                                              0,
                                              that.screen4VisitNo > 0 ? 0 : 50,
                                              "hide",
                                              function () {
                                                console.log(+new Date());
                                                animateLegendNodes(
                                                  0,
                                                  that.screen4VisitNo > 0
                                                    ? 0
                                                    : 50,
                                                  "hide",
                                                  function () {
                                                    console.log(+new Date());
                                                    animateArrow(
                                                      0,
                                                      that.screen4VisitNo > 0
                                                        ? 0
                                                        : 50,
                                                      "hide",
                                                      function () {
                                                        console.log(
                                                          +new Date()
                                                        );
                                                        animateNodeInfoRightGrp(
                                                          0,
                                                          that.screen4VisitNo >
                                                            0
                                                            ? 0
                                                            : 50,
                                                          "hide",
                                                          function () {
                                                            console.log(
                                                              +new Date()
                                                            );
                                                            animateGNNPosition(
                                                              4,
                                                              0,
                                                              that.screen4VisitNo >
                                                                0
                                                                ? 0
                                                                : 500,
                                                              "draw",
                                                              function () {
                                                                console.log(
                                                                  +new Date()
                                                                );
                                                                animateNodeInfoLeftGrp(
                                                                  0,
                                                                  that.screen4VisitNo >
                                                                    0
                                                                    ? 0
                                                                    : 500,
                                                                  "draw",
                                                                  function () {
                                                                    animateNodeIota(
                                                                      0,
                                                                      that.screen4VisitNo >
                                                                        0
                                                                        ? 0
                                                                        : 300,
                                                                      "draw",
                                                                      function () {}
                                                                    );
                                                                    animateFocusRings(
                                                                      0,
                                                                      that.screen4VisitNo >
                                                                        0
                                                                        ? 0
                                                                        : 500,
                                                                      "draw",
                                                                      function () {
                                                                        animateLegendFO(
                                                                          0,
                                                                          that.screen4VisitNo >
                                                                            0
                                                                            ? 0
                                                                            : 500,
                                                                          "draw",
                                                                          function () {
                                                                            animateProcess2GNN(
                                                                              0,
                                                                              that.screen4VisitNo >
                                                                                0
                                                                                ? 0
                                                                                : 200,
                                                                              "draw",
                                                                              function () {
                                                                                animateRect(
                                                                                  0,
                                                                                  that.screen4VisitNo >
                                                                                    0
                                                                                    ? 0
                                                                                    : 500,
                                                                                  "draw",
                                                                                  function () {
                                                                                    animateFO(
                                                                                      0,
                                                                                      that.screen4VisitNo >
                                                                                        0
                                                                                        ? 0
                                                                                        : 500,
                                                                                      "draw",
                                                                                      function () {
                                                                                        animateDashedLines(
                                                                                          0,
                                                                                          that.screen4VisitNo >
                                                                                            0
                                                                                            ? 0
                                                                                            : 500,
                                                                                          "draw",
                                                                                          function () {}
                                                                                        );
                                                                                      }
                                                                                    );
                                                                                  }
                                                                                );
                                                                              }
                                                                            );
                                                                          }
                                                                        );
                                                                      }
                                                                    );
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    );
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        );
                                      }
                                    );
                                  }
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  }
                );
              }
            );
          }
        );
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = "";

          currentProcess.style("opacity", 1);

          image.attr("xlink:href", baseImage);

          currentProcess.attr("class", "screen-4-group");
          initiateAnimation();
        },
        stopAnimation() {
          that.screen4VisitNo++;

          dataGroup.select(".forecastingEngineGroup").interrupt();
          animateForecastingEngine(4, 0, 0, "draw", function () {});

          dataGroup.select(".dashedRectWrapper").interrupt();
          animateDashedRect(0, 0, "hide", function () {});

          currentProcess.select(".screen4NodeGrpTitle").interrupt();
          animateNodeGrpTitle(0, 0, "hide", function () {});

          legendNodesData.forEach((node, index) => {
            legendNodes.select(".node" + index).interrupt();
            nodeText.select(".nodeText" + index).interrupt();
            legendText.select(".legendText" + index).interrupt();
          });
          animateLegendNodes(0, 0, "hide", function () {});

          arrowTickUp.interrupt();
          arrowTickMiddle.interrupt();
          arrowTickDown.interrupt();
          animateArrow(0, 0, "hide", function () {});

          legendNodesDataCopy.forEach((node) => {
            legendNodesCopy.select(".CopiedNode" + node.nodeText).interrupt();
            nodeTextCopy.select(".CopiedNodeText" + node.nodeText).interrupt();
          });
          graphAdjMatx.forEach((link, index) => {
            graphLines.select(".graphLine" + index).interrupt();
          });
          animateGNNNodesToFinal(0, 0, "draw", function () {});
          animateGNNLines(0, 0, "draw", function () {});
          animateGNNPosition(4, 0, 0, "draw", function () {});

          for (let i = 1; i <= 12; i++) {
            globeGroup.select(".node_info_" + i).interrupt();
          }
          animateNodeInfoRightGrp(0, 0, "hide", function () {});
          animateNodeInfoLeftGrp(0, 0, "draw", function () {});

          globeGroup.select(".screen-6-iota").interrupt();
          animateNodeIota(0, 0, "draw", function () {});

          focusrings.select(".screen-4-highlightlingnode-back").interrupt();
          focusrings.select(".screen-4-highlightlingnode-front").interrupt();
          animateFocusRings(0, 0, "draw", function () {});

          foreigndataobject.dataContent.forEach((content, index) => {
            if (index === 2) {
              globeGroup
                .select("." + content.background.class.split(" ")[0])
                .interrupt();
            }
          });
          animateLegendFO(0, 0, "draw", function () {});

          nodesAttr.forEach((node) => {
            let nodeGroup = process2Nodes
              .select(".node" + node.name + node.layer + node.count + " d-none")
              .interrupt();
            nodeGroup.select(".node").interrupt();
            nodeGroup.select(".nodeText").interrupt();
          });
          rectAttr.forEach((rect, index) => {
            process2Rects.select(`.graphrect-${index}`).interrupt();
          });
          lineAttr.forEach((line) => {
            process2Lines.select(".process2Line" + line.id).interrupt();
          });
          animateProcess2GNN(0, 0, "draw", function () {});

          globeGroup.select(".screen-5-OuterRect").interrupt();
          globeGroup.select(".screen-5-TextRect").interrupt();
          globeGroup.select(".screen-5-RectText").interrupt();
          animateRect(0, 0, "draw", function () {});

          foreigndataobject.dataContent.forEach((content, index) => {
            if (index < 2) {
              globeGroup
                .select("." + content.background.class.split(" ")[0])
                .interrupt();
            }
          });
          animateFO(0, 0, "draw", function () {});

          for (let i = 0; i <= 4; i++) {
            lineGroup.select(".screen5process-line-" + i).interrupt();
          }
          animateDashedLines(0, 0, "draw", function () {});

          currentProcess.attr("class", "screen-4-group d-none");

          currentProcess.style("opacity", 0);

          that.animationStarted.screen4 = true;
        },
      };
    })();

    const screenFive = (() => {
      const currentProcess = dataGroup
        .append("g")
        .attr("id", "screen-6-group")
        .attr("class", "screen-6-group d-none")
        .style("opacity", 1);

      const globeGroup = currentProcess
        .append("g")
        .attr("id", "globe-group")
        .attr("class", "globe-group");

      const rectgroup = globeGroup
        .append("g")
        .attr("id", "screen-3-rect-group")
        .attr("class", "screen-3-rect-group");
      rectgroup
        .append("rect")
        .attr("class", "screen-5-OuterRect animate")
        .attr("transform", `translate(60,87)`)
        .attr("width", 778)
        .attr("height", 837)
        .attr("stroke", "#ffffff")
        .attr("stroke-width", 1)
        .attr("fill", "none")
        .attr("rx", 30)
        .attr("ry", 30)
        .attr("opacity", 0);

      rectgroup
        .append("rect")
        .attr("class", "screen-5-TextRect animate")
        .style("fill", "#a485e8")
        .attr("transform", `translate(370,75)`)
        .attr("width", 193)
        .attr("height", 30)
        .attr("opacity", 0);
      rectgroup
        .append("text")
        .attr("class", "screen-5-RectText animate")
        .text("Forecasting Engine")
        .attr("transform", `translate(372,80)`)
        .attr("dy", ".71em")
        .style("fill", "#ffffff")
        .style("font-size", "22px")
        .attr("opacity", 0);

      const listData = {
        lines: [
          [
            [838, 270],
            [1135, 270],
          ],
          [
            [838, 680],
            [1135, 680],
          ],
        ],
      };
      const lineGroup = currentProcess
        .append("g")
        .attr("id", "line-group")
        .attr("class", "line-group")
        .style("opacity", 1);

      listData.lines.forEach((el, i) => {
        let index = i;
        const line = addDashedLine(
          lineGroup,
          el,
          `screen-3 process-line-${i}`,
          "#ffffff",
          "8,4",
          1,
          false
        );
        line.attr("stroke-dashoffset", (d) => d.pathLength);
      });

      const screen6 = globeGroup
        .append("g")
        .attr("id", "screen-6-Inner-rect-group")
        .attr("class", "screen-6-Inner-rect-group animate")
        .style("opacity", 1);

      screen6
        .append("rect")
        .attr("class", "InnerRect animate")
        .attr("transform", `translate(164,620)`)
        .attr("rx", 30)
        .attr("ry", 30)
        .attr("width", 572)
        .attr("height", 278)
        .attr("stroke", "#ffffff")
        .attr("stroke-width", 1)
        .attr("fill", "none")
        .attr("opacity", 0);

      screen6
        .append("rect")
        .attr("class", "InnerTextRect  animate")
        .style("fill", "#a17fea")
        .attr("transform", `translate(374,883)`)
        .attr("width", 150)
        .attr("height", 30)
        .attr("opacity", 0);

      screen6
        .append("text")
        .attr("class", "InnerRectText  animate")
        .text("Driver Analysis")
        .attr("transform", `translate(376,889)`)
        .attr("dy", ".71em")
        .style("fill", "#fff")
        .style("font-size", "22px")
        .attr("opacity", 0);

      let focusRingGroup = screen6
        .append("g")
        .attr("id", "focusRingGroup")
        .attr("class", "focusRingGroup  animate")
        .attr("transform", "translate(-440,39)");

      screen6
        .append("text")
        .attr("class", "ShapAnalysisText  animate")
        .text("SHAP Analysis")
        .attr("transform", `translate(190,655)`)
        .attr("dy", ".71em")
        .style("fill", "#fff")
        .style("font-size", "16px")
        .style("opacity", 0);

      screen6
        .append("text")
        .attr("class", "GraphText  animate")
        .text("Attention Graph")
        .attr("transform", `translate(564,655)`)
        .attr("dy", ".71em")
        .style("fill", "#fff")
        .style("font-size", "16px")
        .style("opacity", 0);

      screen6
        .append("line")
        .attr("class", "innerRectLine  animate")
        .attr("x1", 538)
        .attr("y1", 655)
        .attr("x2", 538)
        .attr("y2", 870)
        .attr("stroke", "#ffffff")
        .attr("opacity", 0);

      const dataSummaryData = {
        dataContent: [
          {
            background: {
              id: "shap_analysis",
              width: 326,
              height: 200,
              src: "assets/images/atlas-intelligence-kraft/shapbackgroundsvg.svg",
              x: 188,
              y: 675,
              finalX: 188,
              finalY: 675,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "shap_image",
              width: 80,
              height: 80,
              src: "assets/images/atlas-intelligence-kraft/SHAPlogo.svg",
              x: 215,
              y: 730,
              finalX: 215,
              finalY: 730,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "shap_analysis_output",
              width: 130,
              height: 150,
              src: "assets/images/atlas-intelligence-kraft/Outputlogo.svg",
              x: 345,
              y: 700,
              finalX: 345,
              finalY: 700,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "attention_graph",
              width: 150,
              height: 170,
              src: "assets/images/atlas-intelligence-kraft/attention_graph.svg",
              x: 562,
              y: 687,
              finalX: 562,
              finalY: 687,
              class: "",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "document-1",
              width: 80,
              height: 80,
              src: "assets/images/atlas-intelligence-kraft/document.svg",
              x: 420,
              y: 540,
              finalX: 580,
              finalY: 600,
              class: "",
              s: 0.5,
              o: 0,
            },
          },
          {
            background: {
              id: "document-2",
              width: 80,
              height: 80,
              src: "assets/images/atlas-intelligence-kraft/document.svg",
              x: 420,
              y: 540,
              finalX: 580,
              finalY: 600,
              class: "",
              s: 0.5,
              o: 0,
            },
          },
        ],
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append("g")
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            class: `globe-group globe-group-${index}`,
            id: `globe-group-${index}`,
            transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
          })
          .style("opacity", (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      const foreigndataobject = {
        dataContent: [
          {
            background: {
              id: "screen-7-output-box",
              width: "705px",
              height: "295px",
              html: `<div class="output-box">
              <h4>Output</h4>
              <div class="wrapper">
                <h5 class="my-3">Final Sales Output</h5>
                <ul class="pl-0">
                  <li class="d-flex align-items-center justify-content-between">
                    <span>1st Year Sales Prediction</span>
                    <strong>$7.9M</strong>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Velocity | Category Percentile</span>
                    <strong>12 | 61.80</strong>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Predicted TDP (Weekly)</span>
                    <strong>$3.2M</strong>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>First Year Units</span>
                    <strong>7858</strong>
                  </li>
                </ul>
              </div>
            </div>`,
              x: 1132,
              y: 130,
              class: "screen-7-output-box",
              s: 1,
              o: 0,
            },
          },
          {
            background: {
              id: "screen-7-driver-box",
              width: "705px",
              height: "450px",
              html: `<div class="output-box driver-box">
              <h4>Driver Analysis</h4>
              <div class="wrapper mt-2">
                <ul class="pointer-list">
                  <li class="">
                    The presence of Ingredient <strong>Citric Acid</strong> is viewed neutrally by the consumer
                  </li>
                  <li class="">
                    The pairing of Whey and <strong>Palm Oil</strong> appears to play a positive role in consumer preferences
                  </li>
                  <li class="">
                  <strong>Dextrose</strong> Ingredient is working as driver
                  </li>
                  <li class="">
                  <strong>Wheat Flour</strong> Ingredient can be replaced by <strong>Unbleached Wheat Flour</strong> for better impact on sales
                  </li>
                  <li class="">
                  <strong>Eggs</strong> ingredient has a negative impact
                  </li>
                </ul>
              </div>
            </div>`,
              x: 1132,
              y: 492,
              class: "screen-7-driver-box",
              s: 1,
              o: 0,
            },
          },
        ],
      };

      foreigndataobject.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append("foreignObject")
          .attr("class", "screen6ForeignObj" + index)
          .attr("x", content.background.x)
          .attr("y", content.background.y)
          .attr("width", content.background.width)
          .attr("height", content.background.height)
          .append("xhtml:div")
          .attr({
            class: content.background.class + " animate",
          })
          .html(content.background.html)
          .style("opacity", 0);
      });

      var documentIntervalId;

      let createFocusRings = (() => {
        let position = [
          [755, 250],
          [840, 250],
          [840, 327],
        ];
        for (var i = 0; i < 3; i++) {
          let group = focusRingGroup
            .append("g")
            .attr("id", "focusRing5" + i)
            .attr("class", "focusRing5" + i + " animate");

          group
            .append("circle")
            .attr("class", "innerCircle animate")
            .attr("cx", 260)
            .attr("cy", 415)
            .attr("r", 23)
            .style("fill", "#fff")
            .style("opacity", 0.4);

          group
            .attr("transform", `translate(${position[i][0]},${position[i][1]})`)
            .style("opacity", 0);
        }
      })();

      let animateFocusRing = (
        group,
        className,
        delay = 0,
        duration = 500,
        cb = function () {}
      ) => {
        group
          .select("." + className)
          .style("opacity", 0)
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", 1)
          .call(endAll, function () {
            animateFocusRing(group, className, 500, duration);
            cb();
          });
      };

      let animateDocument = (
        group,
        className,
        delay = 0,
        duration = 500,
        x,
        y,
        s,
        cb = function () {}
      ) => {
        group
          .selectAll(`.${className}`)
          .style("opacity", 0)
          .attr("transform", `translate(420, 430) scale(1)`)
          .transition()
          .delay(delay)
          .duration(duration)
          .style("opacity", 1)
          .attr("transform", `translate(${x}, ${y}) scale(${s})`)
          .call(endAll, function () {
            animateDocument(group, className, 600, 900, x, y, s);
          });
        cb();
      };

      function initiateAnimation() {
        const htmlDelayValue = 500;
        focusrings.style("opacity", 0);
        animateForecastingEngine(
          5,
          0,
          that.screen5visitno > 0 ? 0 : 1000,
          "draw",
          function () {}
        );
        animateGNNPosition(
          5,
          0,
          that.screen5visitno > 0 ? 0 : 500,
          "draw",
          function () {
            rectgroup
              .select(".screen-5-RectText")
              .transition()
              .delay(0)
              .duration(500)
              .style("opacity", 1);
            rectgroup
              .select(".screen-5-TextRect")
              .transition()
              .delay(0)
              .duration(500)
              .style("opacity", 1);
            rectgroup
              .select(".screen-5-OuterRect")
              .transition()
              .delay(0)
              .duration(500)
              .style("opacity", 1)
              .call(endAll, function () {
                screen6
                  .select(".InnerRect")
                  .transition()
                  .delay(0)
                  .duration(500)
                  .attr("opacity", 1);

                screen6
                  .select(".InnerTextRect")
                  .transition()
                  .delay(0)
                  .duration(500)
                  .attr("opacity", 1);

                screen6
                  .select(".InnerRectText")
                  .transition()
                  .delay(0)
                  .duration(500)
                  .attr("opacity", 1);

                screen6
                  .select(".ShapAnalysisText")
                  .transition()
                  .delay(0)
                  .duration(500)
                  .style("opacity", 1)
                  .call(endAll, function () {
                    showImage(
                      globeGroup,
                      "shap_analysis",
                      "draw",
                      0,
                      500,
                      function () {
                        showImage(
                          globeGroup,
                          "shap_image",
                          "draw",
                          0,
                          500,
                          function () {
                            showImage(
                              globeGroup,
                              "shap_analysis_output",
                              "draw",
                              0,
                              500,
                              function () {
                                screen6
                                  .select(".innerRectLine")
                                  .transition()
                                  .delay(0)
                                  .duration(200)
                                  .attr("opacity", 1)
                                  .call(endAll, function () {
                                    screen6
                                      .select(".GraphText")
                                      .transition()
                                      .delay(0)
                                      .duration(500)
                                      .style("opacity", 1)
                                      .call(endAll, function () {
                                        showImage(
                                          globeGroup,
                                          "attention_graph",
                                          "draw",
                                          0,
                                          500,
                                          function () {
                                            animateFocusRing(
                                              focusRingGroup,
                                              "focusRing50",
                                              0,
                                              500
                                            );
                                            animateFocusRing(
                                              focusRingGroup,
                                              "focusRing51",
                                              0,
                                              500
                                            );
                                            animateFocusRing(
                                              focusRingGroup,
                                              "focusRing52",
                                              0,
                                              500,
                                              function () {
                                                animateDocument(
                                                  globeGroup,
                                                  "document-1",
                                                  0,
                                                  1000,
                                                  420,
                                                  600,
                                                  0.5
                                                );
                                                animateDocument(
                                                  globeGroup,
                                                  "document-2",
                                                  300,
                                                  1300,
                                                  420,
                                                  600,
                                                  0.5,
                                                  function () {
                                                    operateLine(
                                                      lineGroup,
                                                      "process-line-0",
                                                      "draw",
                                                      0,
                                                      200,
                                                      function () {
                                                        globeGroup
                                                          .select(
                                                            ".screen-7-output-box"
                                                          )
                                                          .transition()
                                                          .delay(0)
                                                          .duration(500)
                                                          .style("opacity", 1)
                                                          .call(
                                                            endAll,
                                                            function () {
                                                              operateLine(
                                                                lineGroup,
                                                                "process-line-1",
                                                                "draw",
                                                                0,
                                                                200,
                                                                function () {
                                                                  globeGroup
                                                                    .select(
                                                                      ".screen-7-driver-box"
                                                                    )
                                                                    .transition()
                                                                    .delay(0)
                                                                    .duration(
                                                                      500
                                                                    )
                                                                    .style(
                                                                      "opacity",
                                                                      1
                                                                    );
                                                                }
                                                              );
                                                            }
                                                          );
                                                      }
                                                    );
                                                  }
                                                );
                                              }
                                            );
                                          }
                                        );
                                      });
                                  });
                              }
                            );
                          }
                        );
                      }
                    );
                  });
              });
          }
        );
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = "";

          currentProcess.style("opacity", 1);

          image.attr("xlink:href", baseImage);

          currentProcess.attr("class", "screen-6-group");
          initiateAnimation();
        },
        stopAnimation() {
          that.screen5visitno++;

          currentProcess.selectAll(".animate").interrupt();
          currentProcess.attr("class", "screen-6-group d-none");

          dataGroup.select(".forecastingEngineGroup").interrupt();
          animateForecastingEngine(5, 0, 0, "draw", function () {});

          rectgroup.select(".screen-5-RectText").style("opacity", 1);
          rectgroup.select(".screen-5-TextRect").style("opacity", 1);
          rectgroup.select(".screen-5-OuterRect").style("opacity", 1);

          screen6.select(".InnerRect").attr("opacity", 1),
            screen6.select(".InnerTextRect").attr("opacity", 1),
            screen6.select(".InnerRectText").attr("opacity", 1),
            screen6.select(".ShapAnalysisText").style("opacity", 1),
            screen6.select(".innerRectLine").attr("opacity", 1),
            screen6.select(".GraphText").style("opacity", 1),
            animateDocument(globeGroup, "document-1", 0, 500, 420, 600, 0.5),
            animateDocument(globeGroup, "document-2", 300, 800, 420, 600, 0.5),
            showImage(
              globeGroup,
              "shap_analysis",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(globeGroup, "shap_image", "draw", 0, 0, function () {}),
            showImage(
              globeGroup,
              "shap_analysis_output",
              "draw",
              0,
              0,
              function () {}
            ),
            showImage(
              globeGroup,
              "attention_graph",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-0",
              "draw",
              0,
              0,
              function () {}
            ),
            operateLine(
              lineGroup,
              "process-line-1",
              "draw",
              0,
              0,
              function () {}
            ),
            foreigndataobject.dataContent.forEach((content, index) => {
              globeGroup
                .select("." + content.background.class)
                .style("opacity", 1);
            }),
            currentProcess.style("opacity", 0);

          that.animationStarted.screen5 = true;
        },
      };
    })();

    const screenSix = (() => {
      const currentProcess = dataGroup
        .append("g")
        .attr("id", "screen-7-group")
        .attr("class", "screen-7-group d-none")
        .style("opacity", 1);

      const globeGroup = currentProcess
        .append("g")
        .attr("id", "globe-group")
        .attr("class", "globe-group");

      const lineGroup = currentProcess
        .append("g")
        .attr("id", "output-and-benifits-line-group")
        .attr("class", "output-and-benifits-line-group");

      const circleGroup = currentProcess
        .append("g")
        .attr("id", "output-and-benifits-circle-group")
        .attr("class", "output-and-benifits-circle-group");

      const rectGroup = currentProcess
        .append("g")
        .attr("id", "output-and-benifits-rect-group")
        .attr("class", "output-and-benifits-rect-group");

      const titleGroup = currentProcess
        .append("g")
        .attr("id", "output-and-benifits-title-group")
        .attr("class", "output-and-benifits-title-group");

      const outerRadius = 20;
      const innerRadius = 12;

      const outerCircle = (outerRadius, innerRadius) => {
        return d3.svg
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(0)
          .endAngle(360);
      };

      const inneCircle = (outerRadius, innerRadius) => {
        return d3.svg
          .arc()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius)
          .startAngle(0)
          .endAngle(360);
      };

      const lineAttribues = [
        {
          id: "line1",
          sourceX: 820,
          sourceY: 340,
          angle: 180 - 35,
          length: 180,
          type: "Impacts",
          boldcomment: "+$28M ",
          comment: "Annually Incremental",
          commentBoxWidth: 270,
        },
        {
          id: "line2",
          sourceX: 750,
          sourceY: 450,
          angle: 180 - 9,
          length: 230,
          type: "Impacts",
          boldcomment: "-4x ",
          comment: "Reduction in cost vs. Product Testing Experiment",
          commentBoxWidth: 255,
        },
        {
          id: "line3",
          sourceX: 760,
          sourceY: 550,
          angle: -180 + 11,
          length: 230,
          type: "Impacts",
          boldcomment: "20% ",
          comment:
            "Predicted improvement in Sales due to Recommended Product Modifications",
          commentBoxWidth: 385,
        },
        {
          id: "line4",
          sourceX: 840,
          sourceY: 660,
          angle: -180 + 35,
          length: 180,
          type: "Impacts",
          boldcomment: "4x ",
          comment:
            "Reduction in Time required to arrive at critical insights vs. traditional MR",
          commentBoxWidth: 345,
        },

        {
          id: "line5",
          sourceX: 1080,
          sourceY: 350,
          angle: 35,
          length: 180,
          type: "Benefits",
          comment:
            "Prioritizing of top profiles driving growth and launch success",
          commentBoxWidth: 300,
        },
        {
          id: "line6",
          sourceX: 1135,
          sourceY: 455,
          angle: 9,
          length: 230,
          type: "Benefits",
          comment:
            "Estimation of incremental value of top profiles in current categories",
          commentBoxWidth: 307,
        },
        {
          id: "line7",
          sourceX: 1130,
          sourceY: 550,
          angle: -11,
          length: 230,
          type: "Benefits",
          comment: "Projection of top profile performances for upcoming year",
          commentBoxWidth: 295,
        },
        {
          id: "line8",
          sourceX: 1060,
          sourceY: 650,
          angle: -35,
          length: 180,
          type: "Benefits",
          comment:
            "The client can make an informed decision for their product development roadmap more accurately, cost effciently & quickly",
          commentBoxWidth: 390,
        },
      ];
      lineAttribues.forEach((line) => {
        lineGroup
          .append("line")
          .attr("class", line.id + " animate")
          .style("stroke", "#ffffff")
          .style("stroke-width", 1)
          .attr("x1", line.sourceX)
          .attr("y1", line.sourceY)
          .attr("x2", line.sourceX)
          .attr("y2", line.sourceY);

        const cx =
          line.sourceX +
          (line.length + 20) * Math.cos((line.angle * Math.PI) / 180);
        const cy =
          line.sourceY -
          (line.length + 20) * Math.sin((line.angle * Math.PI) / 180);
        circleGroup
          .append("path")
          .attr("class", "OuterCircle-" + line.id + " animate")
          .attr("d", outerCircle(19, 19))
          .attr("fill", "#ffffff")
          .attr("transform", `translate(${cx},${cy})`);

        circleGroup
          .append("path")
          .attr("class", "InnerCircle-" + line.id + " animate")
          .attr("d", inneCircle(0, 0))
          .attr("fill", "#ffffff")
          .attr("transform", `translate(${cx},${cy})`);

        const rectWidth = line.commentBoxWidth;
        const yPadding = 15;
        const xPadding = 15;
        const cornerRadius = 10;
        const rectX =
          line.type === "Benefits"
            ? cx + outerRadius + 20
            : cx - rectWidth - outerRadius - 20;
        const rectY = cy - 30;
        var rect = rectGroup
          .append("rect")
          .attr("class", "Rect-" + line.id + " animate")
          .style("fill", line.type === "Benefits" ? "#7151B1" : "#906EDB")
          .attr("x", rectX)
          .attr("y", rectY)
          .attr("rx", cornerRadius)
          .attr("ry", cornerRadius)
          .attr("width", rectWidth)
          .attr("height", 100)
          .style("opacity", 0);

        var txt = rectGroup
          .append("text")
          .attr("class", "Text-" + line.id + " animate")
          .text(
            line.type === "Impacts"
              ? line.boldcomment + line.comment
              : line.comment
          )
          .attr("x", rectX + xPadding)
          .attr("y", rectY + 3 + yPadding)
          .attr("dy", ".71em")
          .style("fill", "white")
          .style("font-size", "18px")
          .call(this.wrap, rectWidth - 2 * xPadding, rectX + xPadding)
          .style("opacity", 0);

        var height = txt.node().getBBox().height + 2 * yPadding;
        txt
          .attr("y", cy - height / 2 + 3 + yPadding)
          .call(this.wrap, rectWidth - 2 * xPadding, rectX + xPadding);
        rect.attr("height", height).attr("y", cy - height / 2);
      });

      titleGroup
        .append("text")
        .attr("class", "ImpactTitleText animate")
        .text("Impact")
        .attr("x", 500)
        .attr("y", 130)
        .style("fill", "white")
        .style("font-size", "30px")
        .attr("opacity", 0);

      titleGroup
        .append("text")
        .attr("class", "BenefitsTitleText animate")
        .text("Benefits")
        .attr("x", 1300)
        .attr("y", 130)
        .style("fill", "white")
        .style("font-size", "30px")
        .attr("opacity", 0);

      titleGroup
        .append("text")
        .attr("class", "EngineTitleText animate")
        .text("Forecasting Engine")
        .attr("x", 845)
        .attr("y", 750)
        .style("fill", "white")
        .style("font-size", "23px")
        .attr("opacity", 0);

      const dataSummaryData = {
        dataContent: [
          {
            background: {
              id: "impact-benefits-symbol",
              width: 70,
              height: 65,
              src: "assets/images/atlas-intelligence-kraft/symbol.svg",
              x: 910,
              y: 460,
              finalX: 910,
              finalY: 460,
              class: "impact-benefits-symbol",
              s: 1,
              o: 0,
            },
          },
        ],
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append("g")
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            class: `screen-7 screen-7-group-${index}`,
            id: `screen-7-group-${index}`,
            transform: (d: any) => `translate(${d.x}, ${d.y}) scale(1)`,
          })
          .style("opacity", (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      function animateEngineTitleText(
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) {
        titleGroup
          .select(".EngineTitleText")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("opacity", operation === "draw" ? 1 : 0)
          .call(endAll, cb);
      }

      function animateImpactTitleText(
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) {
        titleGroup
          .select(".ImpactTitleText")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("opacity", operation === "draw" ? 1 : 0)
          .call(endAll, cb);
      }

      function animateBenifitsTitleText(
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) {
        titleGroup
          .select(".BenefitsTitleText")
          .transition()
          .delay(delay)
          .duration(duration)
          .attr("opacity", operation === "draw" ? 1 : 0)
          .call(endAll, cb);
      }

      function ainimateImpacts(
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) {
        lineAttribues.forEach((line, i) => {
          if (line.type === "Impacts") {
            lineGroup
              .select("." + line.id)
              .transition()
              .delay(delay)
              .duration(duration)
              .attr(
                "x2",
                line.sourceX +
                  line.length * Math.cos((line.angle * Math.PI) / 180)
              )
              .attr(
                "y2",
                line.sourceY -
                  line.length * Math.sin((line.angle * Math.PI) / 180)
              )
              .call(endAll, function () {
                circleGroup
                  .select(".InnerCircle-" + line.id)
                  .transition()
                  .duration(100)
                  .attr("d", inneCircle(12, 0))
                  .call(endAll, () => {
                    circleGroup
                      .select(".OuterCircle-" + line.id)
                      .transition()
                      .duration(100)
                      .attr("d", outerCircle(20, 19))
                      .call(endAll, () => {
                        rectGroup
                          .select(".Rect-" + line.id)
                          .transition()
                          .duration(100)
                          .style("opacity", 1);
                        // .call(endAll, () => {
                        rectGroup
                          .select(".Text-" + line.id)
                          .transition()
                          .duration(100)
                          .style("opacity", 1)
                          .call(
                            endAll,
                            line.id === "line4" ? cb : function () {}
                          );
                        // });
                      });
                  });
              });
            delay += duration + 400;
          }
        });
      }

      function animateBenefits(
        delay = 0,
        duration,
        operation: "draw" | "hide",
        cb = function () {}
      ) {
        lineAttribues.forEach((line, i) => {
          if (line.type === "Benefits") {
            lineGroup
              .select("." + line.id)
              .transition()
              .delay(delay)
              .duration(duration)
              .attr(
                "x2",
                line.sourceX +
                  line.length * Math.cos((line.angle * Math.PI) / 180)
              )
              .attr(
                "y2",
                line.sourceY -
                  line.length * Math.sin((line.angle * Math.PI) / 180)
              )
              .call(endAll, function () {
                circleGroup
                  .select(".InnerCircle-" + line.id)
                  .transition()
                  .duration(100)
                  .attr("d", inneCircle(12, 0))
                  .call(endAll, () => {
                    circleGroup
                      .select(".OuterCircle-" + line.id)
                      .transition()
                      .duration(100)
                      .attr("d", outerCircle(20, 19))
                      .call(endAll, () => {
                        rectGroup
                          .select(".Rect-" + line.id)
                          .transition()
                          .duration(100)
                          .style("opacity", 1);
                        // .call(endAll, () => {
                        rectGroup
                          .select(".Text-" + line.id)
                          .transition()
                          .duration(100)
                          .style("opacity", 1)
                          .call(
                            endAll,
                            line.id === "line8" ? cb : function () {}
                          );
                        // });
                      });
                  });
              });
            delay += duration + 400;
          }
        });
      }

      function initiateAnimation() {
        const htmlDelayValue = 500;
        processGraph.style("opacity", 0);
        focusrings.style("opacity", 0);
        globeGroup.select(".impact-benefits-symbol").style("opacity", 0);

        animateForecastingEngine(
          6,
          0,
          that.screen6visitno > 0 ? 0 : 500,
          "draw",
          function () {
            showImage(
              globeGroup,
              "impact-benefits-symbol",
              "draw",
              0,
              that.screen6visitno > 0 ? 0 : 200,
              function () {
                animateEngineTitleText(
                  0,
                  that.screen6visitno > 0 ? 0 : 200,
                  "draw",
                  function () {
                    animateImpactTitleText(
                      0,
                      that.screen6visitno > 0 ? 0 : 200,
                      "draw",
                      function () {
                        ainimateImpacts(
                          0,
                          that.screen6visitno > 0 ? 0 : 100,
                          "draw",
                          function () {
                            animateBenifitsTitleText(
                              0,
                              that.screen6visitno > 0 ? 0 : 200,
                              "draw",
                              function () {
                                animateBenefits(
                                  0,
                                  that.screen6visitno > 0 ? 0 : 100,
                                  "draw",
                                  function () {}
                                );
                              }
                            );
                          }
                        );
                      }
                    );
                  }
                );
              }
            );
          }
        );
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = "";

          currentProcess.style("opacity", 1);

          image.attr("xlink:href", baseImage);

          currentProcess.attr("class", "screen-7-group");
          initiateAnimation();
        },
        stopAnimation() {
          that.screen6visitno++;
          currentProcess.attr("class", "screen-7-group d-none");

          currentProcess.selectAll(".animate").interrupt();

          showImage(
            globeGroup,
            "impact-benefits-symbol",
            "draw",
            0,
            0,
            function () {}
          ),
            dataGroup.select(".forecastingEngineGroup").interrupt();
          animateForecastingEngine(6, 0, 1000, "draw", function () {});

          titleGroup.select(".EngineTitleText").attr("opacity", 1),
            titleGroup.select(".ImpactTitleText").attr("opacity", 1),
            titleGroup.select(".BenefitsTitleText").attr("opacity", 1),
            lineAttribues.forEach((line, i) => {
              lineGroup
                .select("." + line.id)
                .attr(
                  "x2",
                  line.sourceX +
                    line.length * Math.cos((line.angle * Math.PI) / 180)
                )
                .attr(
                  "y2",
                  line.sourceY -
                    line.length * Math.sin((line.angle * Math.PI) / 180)
                );

              circleGroup.interrupt(
                circleGroup
                  .select(".OuterCircle-" + line.id)
                  .attr("d", outerCircle(20, 19)),

                circleGroup
                  .select(".InnerCircle-" + line.id)
                  .attr("d", inneCircle(12, 0))
              );

              rectGroup.interrupt(
                rectGroup.select(".Rect-" + line.id).style("opacity", 1),

                rectGroup.select(".Text-" + line.id).style("opacity", 1)
              );
            });

          currentProcess.transition().style("opacity", 0);

          that.animationStarted.screen5 = true;
        },
      };
    })();

    const { resize, heightAspect, widthAspect } = this._responsivefy(svg, true);
    return {
      resize() {
        const aspect = resize();
        that.scaleXValue = aspect.widthAspect;
        that.scaleYValue = aspect.heightAspect;
        return aspect;
      },
      screenOne,
      screenTwo,
      screenThree,
      screenFour,
      screenFive,
      screenSix,
    };
  }

  wrap(text, width, x) {
    text.each(function () {
      var text = d3.select(this);
      var words = text.text().split(/\s+/).reverse();
      console.log(words, text.text());
      var word;
      var line = [];
      var lineNumber = 0;
      var lineHeight = 1.1;
      var y = text.attr("y");
      var dy = parseFloat(text.attr("dy"));
      var tspan = text
        .text(null)
        .append("tspan")
        .attr("x", x)
        .attr("y", y)
        .attr("dy", dy + "em");

      var tspanBold = tspan
        .append("tspan")
        .attr("class", "bold")
        .style("font-weight", 500)
        .style('font-size',"20px");
      var tspanNormal = tspan.append("tspan").attr("class", "normal");
      while ((word = words.pop())) {
        if (word === "-4x" || word === "+$28M" || word === "20%" || word === "4x") {
          tspanBold.text(word + " ");
        } else {
          line.push(word);
          tspanNormal.text(line.join(" "));

          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspanNormal.text(line.join(" ") + " ");

            line = [word];
            tspan = text
              .append("tspan")
              .attr("x", x)
              .attr("y", y)
              .attr("dy", ++lineNumber * lineHeight + dy + "em");
            tspanBold = tspan.append("tspan");
            tspanNormal = tspan.append("tspan").text(line);
          }
        }
      }
    });
  }

  onAnimationEnd() {
    d3.selectAll("#area1").attr("opacity", 0);
    d3.selectAll("#area2").attr("opacity", 0);
    d3.selectAll("#area3").attr("opacity", 0);
  }

  addClassToDom(selector, className, type = "id") {
    if (type == "id") d3.select(`#${selector}`).classed(className, true);
    else d3.select(`.${selector}`).classed(className, true);
  }

  removeClassFromDom(selector, className, type = "id") {
    if (type == "id") d3.select(`#${selector}`).classed(className, false);
    else d3.select(`.${selector}`).classed(className, false);
  }

  ngOnDestroy() {
    this._renderer.removeClass(document.body, "atlas-intelligence-kraft");
  }
}
