import { Component, ElementRef, OnInit, Input, AfterContentInit, ViewChild } from '@angular/core';
declare let d3: any;

@Component({
  selector: 'app-solid-gauge',
  templateUrl: './solid-gauge-chart.component.html'
})
export class SolidGaugeComponent implements AfterContentInit {
  @ViewChild('solidGaugeRef') chartElement: ElementRef;
  @Input() widgetData;
  svg;

  // coverageData;

  ngAfterContentInit() {
  	// console.log(this.chartData);
    // alert("Here");
    // this.coverageData = this.chartData;
    this.render(this.widgetData.data);
  }

  render(data) {
    var width = 500;
    var height = 500;
    var arcSize = (4 * width / 100);
    var innerRadius = arcSize * 3;
    var color = d3.scale.category20();
    this.svg = d3.select(this.chartElement.nativeElement).append('svg').attr('width', width).attr('height', height);

    var arcs = data.map(function(obj, i) {
      return d3.svg.arc()
            .innerRadius(i * arcSize + innerRadius)
            .outerRadius((i + 1) * arcSize - (width / 100) + innerRadius)
            .cornerRadius(100);
    });
    var arcsGrey = data.map(function(obj, i) {
      // return d3.svg.arc().innerRadius(i * arcSize + (innerRadius + ((arcSize / 2) - 2))).outerRadius((i + 1) * arcSize - ((arcSize / 2)) + (innerRadius));
      return d3.svg.arc()
            .innerRadius(i * arcSize + innerRadius)
            .outerRadius((i + 1) * arcSize - (width / 100) + innerRadius);
    });

    var pieData = data.map(function(obj, i) {
      return [
        { value: obj.value * 0.75, arc: arcs[i], object: obj },
        { value: (100 - obj.value) * 0.75, arc: arcsGrey[i], object: obj },
        { value: 100 * 0.25, arc: arcs[i], object: obj }
      ];
    });

    var pie = d3.layout.pie().sort(null).value(function(d) {
      return d.value;
    });

    var g = this.svg.selectAll('g').data(pieData).enter()
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + width / 2 + ') rotate(180)');
    var gText = this.svg.selectAll('g.textClass').data([{}]).enter()
      .append('g')
      .classed('textClass', true)
      .attr('transform', 'translate(' + width / 2 + ',' + width / 2 + ') rotate(180)');

var tooltip = d3.select("body")
    .append("div")
    .attr("class", "solid-guage-tooltip")
    .style("position", "absolute")
    .style("z-index", "10")
    .style("display", "none");


    g.selectAll('path').data(function(d) {
        return pie(d);
      })
      .enter()
      .append('path')
      .attr('id', function(d, i) {
        if (i == 1) {
          return "Text" + d.data.object.label
        }
      })
      .attr('d', function(d) {
        return d.data.arc(d);
      }).attr('fill', function(d, i) {
        // return ;
        // return i == 0 ? "hsl(" + Math.random() * 360 + ",100%,50%)" : i == 1 ? '#D3D3D3' : 'none'
        return i == 0 ? color(Math.random()) : i == 1 ? '#D3D3D3' : 'none';
        // return i == 0 ? d.data.object.color : i == 1 ? '#D3D3D3' : 'none';
      })
      .on("mouseover", function(d){tooltip.text(d.data.object.label + " - " + d.data.object.value); return tooltip.style("display", "block");})
      .on("mousemove", function(){return tooltip.style("top", (d3.event.pageY-10)+"px").style("left",(d3.event.pageX+30)+"px");})
      .on("mouseout", function(){return tooltip.style("display", "none");})


    this.svg.selectAll('g').each(function(d, index) {
      var el = d3.select(this);
      var path = el.selectAll('path').each(function(r, i) {
        if (i === 1) {
          var centroid = r.data.arc.centroid({
            startAngle: r.startAngle + 0.05,
            endAngle: r.startAngle + 0.001 + 0.05
          });
          var lableObj = r.data.object;
          /*g.append('text')
            .attr('font-size', ((5 * width) / 100))
            .attr('dominant-baseline', 'central')
            // .attr('transform', "translate(" + centroid[0] + "," + (centroid[1] + 10) + ") rotate(" + (180 / Math.PI * r.startAngle + 7) + ")")
            // .attr('alignment-baseline', 'middle')
            .append("textPath")
            .attr("textLength", function(d, i) {
              return 0;
            })
            .attr("xlink:href", "#Text" + r.data.object.label)
            .attr("startOffset", '5')
            .attr("dy", '-3em')
            .attr('class', 'solid-gauage-label')
            .text(lableObj.value + "%");*/
        }
        if (i === 0) {
          var centroidText = r.data.arc.centroid({
            startAngle: r.startAngle,
            endAngle: r.startAngle
          });
          // console.log(centroidText)
          var lableObj = r.data.object;
          gText.append('text')
            .text(lableObj.label + " - " + lableObj.value)
            .attr('transform', "translate(" + (centroidText[0] - ((1.5 * width) / 100)) + "," + (centroidText[1] + ") rotate(" + (180) + ")"))
            .attr('dominant-baseline', 'central')
            .attr('class', 'solid-gauage-label');
        }
      });
    });
  }


}
