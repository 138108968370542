import { Component, OnInit, ViewEncapsulation, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GalleryService } from 'src/app/gallery/gallery.service';
import { FullpagescrollDirective } from 'src/app/shared/fullpagescroll.directive';
import data from './data.json';
import { DataShareService } from 'src/app/shared/data-share.service';
declare const d3: any;

@Component({
  selector: 'app-adaptive-pricing',
  templateUrl: './adaptive-pricing.component.html',
  styleUrls: ['./adaptive-pricing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdaptivePricingComponent implements OnInit, AfterContentInit, AfterViewInit {

  @ViewChild('fullPageScroll') fpScroll: FullpagescrollDirective;

  public activeStartSlide = '';
  public activeSlide = '';
  public pricingData = null;
  public previousActiveSlide = [];
  public previousTraversedSlide = '';
  public addStep = false;
  public lastActiveSlide = 'screen-1';
  public nextActiveSlide = 'screen-2';
  private screens = ['screen-1', 'screen-2', 'screen-3', 'screen-4', 'screen-5', 'screen-6', 'screen-7'];
  public isLoading = false;
  private _unsubscribeResize = null;
  private _svgSection = null;
  private isMenuOpen: boolean = true;
  private animationStarted = {
    screen1: false,
    screen2: false,
    screen3: false,
    screen4: false,
    screen5: false,
    screen6: false,
    screen7: false,
  };
  public selectedTraveller = null;
  public selectedTravellerData = null;
  public selectedTravellerHotelData = null;
  public isAnimation = false;
  public guestSegmentWTP = 0;
  public scaleXValue = 1;
  public scaleYValue = 1;
  public isMouseEnter = false;
  public rightSectionCardWidth = 0;
  public rightSectionCardAspect = {
    width: 0,
    left: 0
  };
  public travellerSectionAspect = {
    left: 0,
    bottom: 0
  };
  public htmlClassVariable = {
    card: 'card-active',
    hotelListLoader: 'card-loader',
    hotelList: 'list-active'
  };
  public svgResize = null;
  public indicatorSettings = [
    {
      id: 1,
      label: 'Introduction'
    },
    {
      id: 2,
      label: 'Current Situation'
    },
    {
      id: 3,
      label: 'Proposed Situation'
    },
    {
      id: 6,
      label: 'Outcome Situation'
    },
    {
      id: 7,
      label: 'Benefits'
    }
  ];
  public clickActionScreensIndex = [1, 2, 3, 3, 3, 6, 7];
  public currentScreenIndex = 1;
  public screenWiseSection = {
    screen7: {
      left: 0
    }
  }
  public currentScrollDirection = 'forward';

  constructor(
    private dataShareService: DataShareService
  ) {

  }

  ngAfterContentInit(): void {
    this._unsubscribeResize = fromEvent(window, 'resize').pipe(debounceTime(700)).subscribe(x => {
      // console.log(x, this._svgSection);
      if (this._svgSection) {
        this.svgResize = this._svgSection.resize();
      }
    });
  }

  ngAfterViewInit(): void {

  }
  checkEventLogs(event) {
    // console.log('Event Trigger: ', event.type, event);
  }

  onDownArrowClick(sectionId) {
    // console.log("sectionId: ", sectionId);
    if (+this.currentScreenIndex != +sectionId)
      this.fpScroll.navigateTo(`screen-${sectionId}`);
  }

  changeScrollEvent(event, state: boolean, screenId) {
    // event.stopPropagation();
    // let el = event.target.closest('.event-wrapper');
    // let scrollEl = event.target.closest('.scroll-wrapper');
    // let elStyle = window.getComputedStyle(el);
    // console.log('event', screenId, elStyle.opacity);
    // console.log('changeScrollEvent triggered', screenId, this.activeStartSlide, event.type);
    if (this.activeStartSlide == screenId) {
      // if (+elStyle.opacity == 1) {
      // console.log("Card has appeared totally");
      if (event.type === 'mouseenter') {
        if (event.srcElement.scrollHeight > event.srcElement.clientHeight) {
          this.isMouseEnter = true;
          this.fpScroll.setEventState(state);
        } else if (this.isMenuOpen && !this.isAnimation) {
          // console.log("no scroll encountered");
          this.fpScroll.setEventState(!state);
        }
      } else if (event.type === 'mouseleave') {
        this.isMouseEnter = false;
        if (this.isMenuOpen && !this.isAnimation) {
          this.fpScroll.setEventState(state);
        }
      }
      // }
    }
  }

  changeDirectiveScrollEvent(state: boolean) {
    // console.log('menuState ::', state);
    this.isMenuOpen = state;
    this.fpScroll.setEventState(state);
    this.onAnimationEnd();
  }

  restrictScrollAndClick() {
    // console.log("Inside restrictScrollAndClick function");
    // console.log('restrictScrollAndClick ::', this.isMenuOpen);
    if (this.isMenuOpen) {
      this.isAnimation = true;
      // this.fpScroll.setEventState(!this.isAnimation);
    }
  }

  onAnimationEnd() {
    // console.log('animationEnd :: this.isMenuOpen: ', this.isMenuOpen, '\t this.isMouseEnter : ', this.isMouseEnter);
    if (this.isMenuOpen) {
      this.isAnimation = false;
      if (!this.isMouseEnter) {
        // this.fpScroll.setEventState(!this.isAnimation);
      }
    }
  }

  addClassToDom(selector, className, type = 'id') {
    // console.log("Inside addClassToDom");
    if (type == 'id')
      d3.select(`#${selector}`).classed(className, true)
    else
      d3.select(`.${selector}`).classed(className, true)
  }

  removeClassFromDom(selector, className, type = 'id') {
    // console.log("Inside removeClassFromDom");
    if (type == 'id')
      d3.select(`#${selector}`).classed(className, false);
    else
      d3.select(`.${selector}`).classed(className, false);
  }

  scrollTo(selector, type, x = 0, y = 0) {
    if (type == 'id')
      document.querySelector(`#${selector}`).scrollTo(x, y);
    else
      document.querySelectorAll(`.${selector}`).forEach((scrollEl, index) => scrollEl.scrollTo(x, y));
  }

  ngOnInit() {
    // console.log("d3: ", d3);
    this.dataShareService.onCustomEvent.next({ event: 'addClassToElement', data: { selector: '#header', class: 'humburger-header' } });
    this.pricingData = data;
    this.selectedTravellerHotelData = this.pricingData.hotelList['default'];
    this.renderStory();
  }

  private renderStory() {
    this._svgSection = this._initSvgSection('svg-wrapper');
    this.activeStartSlide = 'screen-1';
    this.activeSlide = 'screen-1';
    // this.addClassToDom('slider-1', 'first-time-load', 'id');
    // console.log('activeSlide: ', this.activeSlide);
    setTimeout(() => {
      this.addClassToDom('slider-1', 'first-time-load', 'id');
    }, 3000);
    this.svgResize = this._svgSection.resize();
    setTimeout(() => {
      this._svgSection.screenOne.startAnimation(!this.animationStarted.screen1);
      this.animationStarted.screen1 = true;
    }, 100);
  }

  public setTravellerData() {
    this.selectedTravellerData = this.pricingData.travellerDetails[this.selectedTraveller];
    this.selectedTravellerHotelData = this.pricingData.hotelList[this.selectedTraveller];
  }

  public onFullPageSlideChangeStart(event) {
    this.previousTraversedSlide = this.activeStartSlide;
    if (this.previousActiveSlide.indexOf(event) < 0) {
      this.previousActiveSlide.push(this.activeStartSlide);
    } else {
      this.previousActiveSlide.splice(this.previousActiveSlide.indexOf(this.activeStartSlide), 1);
    }
    this.activeStartSlide = event;
    if ((this.screens.indexOf(this.previousTraversedSlide) - this.screens.indexOf(this.activeStartSlide)) < 0) {
      this.currentScrollDirection = 'forward';
    } else {
      this.currentScrollDirection = 'backward';
    }
    this.currentScreenIndex = this.clickActionScreensIndex[this.screens.indexOf(this.activeStartSlide)];
    this.lastActiveSlide = this.screens[this.screens.indexOf(event) - 1];
    const nextSlideIndex = ((this.screens.indexOf(event) + 1) > (this.screens.length - 1)) ? this.screens.indexOf(event) : (this.screens.indexOf(event) + 1);
    this.nextActiveSlide = this.screens[nextSlideIndex];
    // this._svgSection.changeScreen();
    // console.log('previousTraversedSlide: ', this.previousTraversedSlide);
    // console.log('activeStartSlide: ', this.activeStartSlide);
    if (!(['screen-1', 'screen-2'].includes(this.activeStartSlide))) {
      this._svgSection.commonLeftSection.checkAndSetDefaultTraveller();
    }

    if (this.activeStartSlide === 'screen-2') {
      this._svgSection.screenTwo.resetElements();
    } else if (this.activeStartSlide === 'screen-3') {
      this._svgSection.screenThree.resetElements();
    } else if (this.activeStartSlide === 'screen-4') {
      this._svgSection.screenFour.resetElements();
    } else if (this.activeStartSlide === 'screen-5') {
      this._svgSection.screenFive.resetElements();
    } else if (this.activeStartSlide === 'screen-6') {
      this._svgSection.screenSix.resetElements();
    } else if (this.activeStartSlide === 'screen-7') {
      this._svgSection.screenSeven.resetElements();
    }

    if (this.previousTraversedSlide === 'screen-2') {
      this._svgSection.screenTwo.climaxAnimation();
    } else if (this.previousTraversedSlide === 'screen-3') {
      this._svgSection.screenThree.climaxAnimation();
    } else if (this.previousTraversedSlide === 'screen-4') {
      this._svgSection.screenFour.climaxAnimation();
    } else if (this.previousTraversedSlide === 'screen-5') {
      this._svgSection.screenFive.climaxAnimation();
    } else if (this.previousTraversedSlide === 'screen-6') {
      this._svgSection.screenSix.climaxAnimation();
    } else if (this.previousTraversedSlide === 'screen-7') {
      this._svgSection.screenSeven.climaxAnimation();
    }

    if (['screen-1', 'screen-2', 'screen-6', 'screen-7'].includes(this.activeStartSlide)) {
      this.addStep = false;
    }
  }

  public onFullPageSlideChangeComplete(event) {
    // console.log('onFullPageSlideChangeComplete');
    this.activeSlide = event;

    this.scrollTo('scroll-wrapper', 'class', 0, 0);
    this._svgSection.screenOne.stopAnimation();
    this._svgSection.screenTwo.stopAnimation();
    this._svgSection.screenThree.stopAnimation();
    this._svgSection.screenFour.stopAnimation();
    this._svgSection.screenFive.stopAnimation();
    this._svgSection.screenSix.stopAnimation();
    this._svgSection.screenSeven.stopAnimation();


    if (['screen-3', 'screen-4', 'screen-5'].includes(this.activeSlide)) {
      setTimeout(() => {
        this.addStep = true;
      }, 2500);
    } else {
      this.addStep = false;
    }

    if (['screen-1', 'screen-7'].includes(this.activeSlide)) {
      this._svgSection.commonLeftSection.manageTravellersGroup();
    } else {
      this._svgSection.commonLeftSection.manageTravellersGroup(1);
    }
    // this._svgSection.manageScreenwiseAnimation.changeAnimation();

    switch (event) {
      case 'screen-1':
        this._svgSection.screenOne.startAnimation(!this.animationStarted.screen1);
        this.animationStarted.screen1 = true;
        break;
      case 'screen-2':
        this._svgSection.screenTwo.startAnimation(!this.animationStarted.screen2);
        this.animationStarted.screen2 = true;
        break;
      case 'screen-3':
        this._svgSection.screenThree.startAnimation(!this.animationStarted.screen3);
        this.animationStarted.screen3 = true;
        break;
      case 'screen-4':
        this._svgSection.screenFour.startAnimation(!this.animationStarted.screen4);
        this.animationStarted.screen4 = true;
        break;
      case 'screen-5':
        this._svgSection.screenFive.startAnimation(!this.animationStarted.screen5);
        this.animationStarted.screen5 = true;
        break;
      case 'screen-6':
        this._svgSection.screenSix.startAnimation(!this.animationStarted.screen6);
        this.animationStarted.screen6 = true;
        break;
      case 'screen-7':
        this._svgSection.screenSeven.startAnimation(!this.animationStarted.screen7);
        this.animationStarted.screen1 = true;
        break;
    }
  }

  private _initSvgSection(containerId) {
    const that = this;
    const container = d3.select('#' + containerId);
    const WIDTH = 1920;
    const HEIGHT = 969;
    // const HEIGHT_OFFSET = 969 / 1920;
    const svg = container.append('svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT)
      // .style('background', 'aliceblue')
      .on('click', function () {
        // console.log(d3.mouse(this));
      });

    const { resize, heightAspect, widthAspect } = this._responsivefy(svg, true);

    const defs = svg.append('defs')
      .html(` <linearGradient id='traveller-selected-lg' x1='0.449' y1='1' x2='1' y2='1' gradientUnits='objectBoundingBox'>
              <stop offset='0' stop-color='#ffeae3'/>
              <stop offset='1' stop-color='#65617d'/>
            </linearGradient>
            <linearGradient id='traveller-lg' x1='0.449' y1='1' x2='1' y2='1' gradientUnits='objectBoundingBox'>
              <stop offset='0' stop-color='#f3f5f7'/>
              <stop offset='1' stop-color='#65617d'/>
            </linearGradient>
            <linearGradient xmlns="http://www.w3.org/2000/svg" id="linear-gradient" x1="-0.059" y1="0.418" x2="0.975" y2="0.421" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#f8fafc" stop-opacity="0.192"/>
              <stop offset="0.507" stop-color="#fdf2ec"/>
              <stop offset="1" stop-color="#fef0e7"/>
            </linearGradient>
            <linearGradient xmlns="http://www.w3.org/2000/svg" id="linear-gradient-grey" x1="1.049" y1="0.428" x2="0.025" y2="0.421" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#f8fafc" stop-opacity="0"/>
              <stop offset="0.542" stop-color="#f2f3f5"/>
              <stop offset="1" stop-color="#e1e3e8"/>
            </linearGradient>
            <linearGradient xmlns="http://www.w3.org/2000/svg" id="linear-gradient-white" x1="0.006" y1="0.405" x2="1" y2="0.447" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#fff"/>
              <stop offset="1" stop-color="#fff" stop-opacity="0"/>
            </linearGradient>
            `);

    const line = d3.svg.line()
      .x((d) => d[0])
      .y((d) => d[1]);

    const addDashedLine = (group, lineData, className, strokeColor = '#000000', strokeDash = '8, 5', strokeWidth = 1, alreadyDraw = true) => {
      return group.append('path')
        .datum(lineData)
        .attr('d', line)
        .attr('stroke', strokeColor)
        .attr('class', `animate ${className}`)
        .attr('stroke-width', strokeWidth)
        .attr('stroke-dasharray', function (d: any) {
          d.pathLength = d3.select(this).node().getTotalLength();
          const dashing = strokeDash;
          const dashLength =
            dashing
              .split(/[\s,]/)
              .map(function (a) { return parseFloat(a) || 0 })
              .reduce(function (a, b) { return a + b; });

          const dashCount = Math.ceil(d.pathLength / dashLength);
          // console.log('dashLength', dashLength);
          // console.log('dashCount', dashCount);
          const newDashes = new Array(dashCount).join(dashing + ' ');
          const dashArray = newDashes + ' 1 , ' + (d.pathLength + 5);
          return dashArray;
        })
        .attr('stroke-dashoffset', function (d: any) {
          return (alreadyDraw ? 0 : d.pathLength);
        })
        // .attr('stroke-dashoffset', (d: any) => 0)
        .attr('fill', 'none');
    };

    const addLine = (group, pathData, selector, key, strokeColor = '#000000', strokeWidth = 1, alreadyDraw = true) => {
      return group.append('path')
        .datum(pathData)
        .attr('d', line)
        .attr('class', `animate ${selector}`)
        .attr('id', `${selector}-${key}`)
        .attr('stroke', strokeColor)
        .attr('stroke-width', strokeWidth)
        // .attr('stroke-dasharray', function (d: any) {
        //   d.pathLength = d3.select(this).node().getTotalLength();
        //   return d.pathLength;
        // })
        // .attr('stroke-dashoffset', function (d: any) {
        //   return (alreadyDraw ? 0 : d.pathLength);
        // })
        .attr('fill', 'none');
    };

    const addRect = (group, data) => {
      return group.append('rect')
        .datum(data)
        .attr('class', 'class' in data ? data.class : '')
        .attr('id', 'id' in data ? data.id : '')
        .attr('x', data.x)
        .attr('y', data.y)
        .attr('width', data.width)
        .attr('height', data.height)
        .attr('fill', data.fill)
        .attr('rx', 'rx' in data ? data.rx : 0)
        .style('opacity', 'o' in data ? data.o : 1);
    };

    const createImageUsingData = (group, data: { id: string, src: string, height: number, width: number, x: number, y: number, s?: number, o?: number, class?: string, [k: string]: any, finalX?: number, finalY?: number, textStyle?: any }) => {
      return group
        .append('image')
        .datum(data)
        .attr('id', (d) => d.id)
        .attr('xlink:href', (d) => d.src)
        .attr('class', (d) => `${(d.class || d.id)} animate`)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('transform', (d) => `translate(${d.x}, ${d.y}) scale(${(data.s !== void 0) ? data.s : 1})`)
        .style('opacity', (d) => (d.o !== void (0)) ? d.o : 1)
        .style('textStyle' in data ? data.textStyle : {});
    };

    const createTextUsingData = (group, data: { wholeText: any, id: string, x: number, y: number, dy: string, s?: number, o?: number, class?: string, textStyle?: object, finalX?: number, finalY?: number }) => {
      const text = group.append('text');
      text
        .datum(data)
        .style('textStyle' in data ? data.textStyle : {})
        .attr({
          'class': (d) => d.class !== void (0) ? d.class : '',
          'id': (d) => d.id,
          'transform': (d) => `translate(${d.x},${d.y})`,
        })
        .style({
          'opacity': 'o' in data ? data.o : 1
        });

      data.wholeText.forEach((textPart, index) => {
        text.append('tspan')
          .attr({
            'dy': 'dy' in textPart ? textPart.dy : data.dy,
            'x': 'x' in textPart ? textPart.x : '',
            'y': 'y' in textPart ? textPart.y : ''
          })
          .style('textStyle' in textPart ? textPart.textStyle : {})
          .text(textPart.text)
      });

      return text;
    }

    const createCenterBox = (group, data) => {
      const centerWraperGroup = group.append('g')
      const svgText = createTextUsingData(centerWraperGroup, data.titleText);

      data.imageData.forEach(el => {
        createImageUsingData(centerWraperGroup, {
          id: el.id,
          height: el.height,
          width: el.width,
          class: el.class,
          src: el.src,
          x: el.x,
          y: el.y,
          o: 'o' in el ? el.o : 1,
          s: 's' in el ? el.s : 1,
          finalX: 'finalX' in el ? el.finalX : 0,
          finalY: 'finalY' in el ? el.finalY : 0
        })
      });


      return centerWraperGroup;
    }

    const dataGroup = svg.append('g')
      .attr('id', 'svg-data')
      .attr('class', 'svg-data');

    const updateIcons = (group, attributeName = 'hotel', cb = function () { }) => {
      // console.log();
      const attributes = { ...that.pricingData.travellerDetails[that.selectedTraveller] };
      // console.log(attributes);
      const totalPreferrence = attributes.attributes[attributeName].reduce((total, obj) => {
        const isPreferredCount = obj.isPreffered && obj.isConsidered ? 1 : 0;
        return total + isPreferredCount
      }, 0);
      let count = 0, tempCount = 0;
      attributes.attributes[attributeName].map((v, index, arr) => {
        // console.log(v)
        group.selectAll(`.${v.id}`)
          .each(function () {
            const selected = d3.select(this)
            // selected.attr('xlink:href', `assets/images/adaptive-pricing/${v.id}_grey.svg`);
            if (v.isPreffered) {
              selected
                .transition()
                .delay(80 * tempCount)
                .duration(100)
                .style('opacity', 1)
                .attr('xlink:href', `assets/images/adaptive-pricing/${v.id}_red.svg`)
                .call(endAll, function () {
                  count++;
                  if (count === totalPreferrence) {
                    // console.log("Last icon updated");
                    cb();
                  }
                });
              tempCount++;
            }
          });
      });
    }

    function endAll(tran, callback) {
      if ((typeof callback) !== 'function') { throw new Error('Wrong callback in endall'); }
      if (tran.size() === 0) { callback(); }
      let n = 0;
      tran.each(() => { ++n; })
        .each('end', function (e) {
          if (--n === 0) { callback.apply(this, arguments); }
        });
    }

    const showText = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(duration)
        .style('opacity', 1)
        .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        .call(endAll, cb)
    }

    const hideText = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .style('opacity', 0)
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
        .call(endAll, cb)
    }

    const operateLine = (group, className, operation: 'draw' | 'hide', delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .style('opacity', operation === 'hide' ? 0 : 1)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('stroke-dashoffset', d => operation === 'hide' ? d.pathLength : 0)
        .call(endAll, cb)
    }

    const showImage = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('transform', (d: any) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
        .call(endAll, cb)
    }

    const hideImage = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('transform', (d: any) => `translate(${d.x}, ${d.y}) scale(0)`)
        .call(endAll, cb)
    }

    const showGroup = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('transform', (d: any) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
        .style('opacity', 1)
        .call(endAll, cb)
    }

    let baseImage = '';

    const image = dataGroup.append('image')
      .attr('xlink:href', baseImage)
      .attr('class', 'base-image-group')
      .attr('height', 969)
      .attr('width', 1920)
      .attr('id', 'img');

    const screenSeven = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-7-group')
        .attr('class', 'screen-7-group')
        .style('opacity', 1);

      const caseStudyGroup = currentProcess.append('g')
        .attr('id', 'case-study-group')
        .attr('class', 'case-study-group');

      const benefitsGroup = currentProcess.append('g')
        .attr('id', 'benefits-group')
        .attr('class', 'benefits-group')
        .attr('transform', 'translate(-50,0)');

      // TODO LEFT SECTION
      const dataSummaryData = {
        titleContent: {
          background: {
            id: 'data-summary-background-screen-7',
            width: 244,
            height: 165,
            src: 'assets/images/adaptive-pricing/text_box_4.svg',
            x: 125,
            y: 530.5,
            finalX: 3,
            finalY: 448,
            class: 'screen-7 data-summary-bg background animate screen-7-images',
            s: 0,
            o: 1
          },
          text: {
            wholeText: [
              {
                text: 'Data',
                dy: '0em',
                x: 0
              }, {
                text: 'Summary',
                dy: '1.2em',
                x: 0
              }],
            id: 'screen-data-summary-title-text-screen-7',
            class: 'screen-data-summary-title-text-screen-7 screen-7-text',
            x: 126,
            y: 526,
            dy: '0em',
            finalX: 126,
            finalY: 526,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 24,
              'fill': '#3f3d56',
              'font-weight': 400,
            },
          }
        },
        dataContent: [
          {
            background: {
              id: 'pie-bg-1',
              width: 277,
              height: 256,
              src: 'assets/images/adaptive-pricing/pie_bg_1.svg',
              x: 142,
              y: 236,
              finalX: 142,
              finalY: 236,
              class: 'screen-7 pie background pie-background animate screen-7-images',
              s: 1,
              o: 1
            },
            text: {
              wholeText: [
                {
                  text: '169K',
                  dy: '0em',
                  x: 0,
                  y: 0,
                  textStyle: {
                    'font-size': 40,
                  }
                },
                {
                  text: 'Guest',
                  dy: '0em',
                  x: 0,
                  y: 25,
                  textStyle: {
                    'font-size': 16,
                  }
                },
                {
                  text: 'bookings',
                  dy: '0em',
                  x: 0,
                  y: 44,
                  textStyle: {
                    'font-size': 16,
                  }
                }
              ],
              id: 'pie-1-text',
              class: 'pie-1-text screen-7-text',
              x: 223,
              y: 350,
              dy: '0em',
              finalX: 223,
              finalY: 350,
              o: 1,
              textStyle: {
                'text-anchor': 'start',
                'fill': '#FFFFFF',
                'font-weight': 400,
              },
            }
          },
          {
            background: {
              id: 'pie-bg-2',
              width: 255,
              height: 278,
              src: 'assets/images/adaptive-pricing/pie_bg_2.svg',
              x: 218,
              y: 375,
              finalX: 218,
              finalY: 375,
              class: 'screen-7 pie background pie-background animate screen-7-images',
              s: 1,
              o: 1
            },
            text: {
              wholeText: [
                {
                  text: '39K',
                  dy: '0em',
                  x: 0,
                  y: 0,
                  textStyle: {
                    'font-size': 40,
                  }
                },
                {
                  text: 'Unique hotels',
                  dy: '0em',
                  x: 0,
                  y: 25,
                  textStyle: {
                    'font-size': 16,
                  }
                },
                {
                  text: 'listed',
                  dy: '0em',
                  x: 0,
                  y: 44,
                  textStyle: {
                    'font-size': 16,
                  }
                }
              ],
              id: 'pie-2-text',
              class: 'pie-2-text screen-7-text',
              x: 305,
              y: 529,
              dy: '0em',
              finalX: 305,
              finalY: 529,
              o: 1,
              textStyle: {
                'text-anchor': 'start',
                'fill': '#FFFFFF',
                'font-weight': 400,
              },
            }
          },
          {
            background: {
              id: 'pie-bg-3',
              width: 255,
              height: 275,
              src: 'assets/images/adaptive-pricing/pie_bg_3.svg',
              x: 135,
              y: 592,
              finalX: 135,
              finalY: 592,
              class: 'screen-7 pie background pie-background animate screen-7-images',
              s: 1,
              o: 1
            },
            text: {
              wholeText: [
                {
                  text: '26',
                  dy: '0em',
                  x: 0,
                  y: 0,
                  textStyle: {
                    'font-size': 40,
                  }
                },
                {
                  text: 'Hotels',
                  dy: '0em',
                  x: 0,
                  y: 25,
                  textStyle: {
                    'font-size': 16,
                  }
                },
                {
                  text: 'alternatives',
                  dy: '0em',
                  x: 0,
                  y: 44,
                  textStyle: {
                    'font-size': 16,
                  }
                }
              ],
              id: 'pie-3-text',
              class: 'pie-3-text screen-7-text',
              x: 242,
              y: 699,
              dy: '0em',
              finalX: 242,
              finalY: 699,
              o: 1,
              textStyle: {
                'text-anchor': 'start',
                'fill': '#FFFFFF',
                'font-weight': 400,
              },
            }
          },
        ]
      };

      const impactData = {
        titleContent: {
          text: {
            wholeText: [
              {
                text: 'Impact'
              }],
            id: 'screen-impact-title-text-screen-7',
            class: 'screen-impact-title-text-screen-7 screen-7-text',
            x: 521,
            y: 250,
            dy: '0em',
            finalX: 521,
            finalY: 260,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 24,
              'fill': '#3f3d56',
              'font-weight': 400,
            },
          }
        },
        dataContent: [
          {
            background: {
              id: 'impact-list-bg-1',
              width: 500,
              height: 223,
              src: 'assets/images/adaptive-pricing/impact_list_bg_box.svg',
              x: 438,
              y: 300,
              finalX: 438,
              finalY: 300,
              class: 'screen-7 impact-list background impact-list-background animate screen-7-images',
              s: 1,
              o: 1
            },
            text: [
              {
                wholeText: [
                  {
                    text: '14%',
                    dy: '0em',
                    x: 0,
                    y: 0,
                  }
                ],
                id: 'impact-list-value-1-text',
                class: 'impact-list-value-1-text screen-7-text',
                x: 509,
                y: 420.5,
                dy: '0em',
                finalX: 509,
                finalY: 420.5,
                o: 1,
                textStyle: {
                  'text-anchor': 'start',
                  'fill': '#D6781E',
                  'font-weight': 400,
                  'font-size': 32,
                },
              },
              {
                wholeText: [
                  {
                    text: 'Increased in booking conversion',
                    dy: '0em',
                    x: 0,
                    y: 0,
                  },
                  {
                    text: 'rate in top 10 recommended',
                    dy: '0em',
                    x: 0,
                    y: 25,
                  },
                  {
                    text: 'properties from the incumbent',
                    dy: '0em',
                    x: 0,
                    y: 50,
                  },
                  {
                    text: 'ranking algorithm',
                    dy: '0em',
                    x: 0,
                    y: 75,
                  }
                ],
                id: 'impact-list-label-1-text',
                class: 'impact-list-label-1-text screen-7-text',
                x: 601,
                y: 379,
                dy: '0em',
                finalX: 601,
                finalY: 379,
                o: 1,
                textStyle: {
                  'text-anchor': 'start',
                  'fill': '#000000',
                  'font-weight': 400,
                  'font-size': 16,
                },
              }
            ]
          },
          {
            background: {
              id: 'impact-list-bg-2',
              width: 500,
              height: 223,
              src: 'assets/images/adaptive-pricing/impact_list_bg_box.svg',
              x: 438,
              y: 312,
              finalX: 438,
              finalY: 312,
              class: 'screen-7 impact-list background impact-list-background animate screen-7-images',
              s: 1,
              o: 1
            },
            text: [
              {
                wholeText: [
                  {
                    text: '19%',
                    dy: '0em',
                    x: 0,
                    y: 0,
                  }
                ],
                id: 'impact-list-value-2-text',
                class: 'impact-list-value-2-text screen-7-text',
                x: 509,
                y: 434,
                dy: '0em',
                finalX: 509,
                finalY: 434,
                o: 1,
                textStyle: {
                  'text-anchor': 'start',
                  'fill': '#D6781E',
                  'font-weight': 400,
                  'font-size': 32,
                },
              },
              {
                wholeText: [
                  {
                    // text: 'uplift in NDCG value from',
                    text: 'Uplift in engagement via booked/',
                    dy: '0em',
                    x: 0,
                    y: 0,
                  },
                  {
                    // text: 'the incumbent ranking',
                    text: 'clicked properties, as measured using',
                    dy: '0em',
                    x: 0,
                    y: 25,
                  },
                  {
                    // text: 'algorithm',
                    text: 'NDCG (Normalized Discounted',
                    dy: '0em',
                    x: 0,
                    y: 50,
                  },
                  {
                    // text: 'algorithm',
                    text: 'Cumulative Gain) a metric measuring',
                    dy: '0em',
                    x: 0,
                    y: 75,
                  },
                  {
                    // text: 'algorithm',
                    text: 'goodness of recommendations',
                    dy: '0em',
                    x: 0,
                    y: 100,
                  }
                ],
                id: 'impact-list-label-2-text',
                class: 'impact-list-label-2-text screen-7-text',
                x: 601,
                y: 379,
                dy: '0em',
                finalX: 601,
                finalY: 379,
                o: 1,
                textStyle: {
                  'text-anchor': 'start',
                  'fill': '#000000',
                  'font-weight': 400,
                  'font-size': 16,
                },
              }
            ]
          },
          {
            background: {
              id: 'impact-list-bg-3',
              width: 500,
              height: 223,
              src: 'assets/images/adaptive-pricing/impact_list_bg_box.svg',
              x: 438,
              y: 320,
              finalX: 438,
              finalY: 320,
              class: 'screen-7 impact-list background impact-list-background animate screen-7-images',
              s: 1,
              o: 1
            },
            text: [
              {
                wholeText: [
                  {
                    text: '$54K',
                    dy: '0em',
                    x: 0,
                    y: 0,
                  }
                ],
                id: 'impact-list-value-3-text',
                class: 'impact-list-value-3-text screen-7-text',
                x: 509,
                y: 444,
                dy: '0em',
                finalX: 509,
                finalY: 444,
                o: 1,
                textStyle: {
                  'text-anchor': 'start',
                  'fill': '#D6781E',
                  'font-weight': 400,
                  'font-size': 32,
                },
              },
              {
                wholeText: [
                  {
                    text: 'Average excess potential for the',
                    dy: '0em',
                    x: 0,
                    y: 0,
                  },
                  {
                    text: 'business bookings (difference of',
                    dy: '0em',
                    x: 0,
                    y: 25,
                  },
                  {
                    text: 'Guest’s assessed WTP and the',
                    dy: '0em',
                    x: 0,
                    y: 50,
                  },
                  {
                    text: 'booked property price) for engaging in',
                    dy: '0em',
                    x: 0,
                    y: 75,
                  },
                  {
                    text: 'better cross-sell/up-sell',
                    dy: '0em',
                    x: 0,
                    y: 100,
                  }
                ],
                id: 'impact-list-label-3-text',
                class: 'impact-list-label-3-text screen-7-text',
                x: 601,
                y: 389,
                dy: '0em',
                finalX: 601,
                finalY: 389,
                o: 1,
                textStyle: {
                  'text-anchor': 'start',
                  'fill': '#000000',
                  'font-weight': 400,
                  'font-size': 16,
                },
              }
            ]
          },
        ],
        dataGroupContent: [
          {
            x: 0,
            y: -100,
            finalX: 0,
            finalY: -50,
            s: 1,
            o: 0
          },
          {
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 120,
            s: 1,
            o: 0
          },
          {
            x: 0,
            y: 132,
            finalX: 0,
            finalY: 295,
            s: 1,
            o: 0
          },
        ]
      }
      const dataSummarySection = caseStudyGroup.append('g')
        .attr({
          'class': 'data-summary-group',
          'id': 'data-summary-group',
        });

      // Data-Summary SECTION
      dataSummaryData.dataContent.forEach((content, index) => {
        const group = dataSummarySection
          .append('g')
          .datum({
            x: 126,
            y: 526,
            finalX: 0,
            finalY: 0,
            s: 0,
            o: 0
          })
          .attr({
            'class': `pie-group pie-group-${index}`,
            'id': `pie-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        createTextUsingData(group, content.text);
      });

      createImageUsingData(dataSummarySection, dataSummaryData.titleContent.background);
      createTextUsingData(dataSummarySection, dataSummaryData.titleContent.text);

      const impactSection = caseStudyGroup.append('g')
        .attr({
          'class': 'impact-group',
          'id': 'impact-group',
        });

      // Impact SECTION
      createTextUsingData(impactSection, impactData.titleContent.text);
      impactData.dataContent.reverse().forEach((content, index, arr) => {
        const key = arr.length - 1 - index;
        const group = impactSection
          .append('g')
          .datum(impactData.dataGroupContent[key])
          .attr({
            'class': `impact-list-group impact-list-group-${key}`,
            'id': `impact-list-group-${key}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        createTextUsingData(group, content.text[0]);
        createTextUsingData(group, content.text[1]);
      });


      // TODO RIGHT SECTION
      const benefitListGroup = benefitsGroup.append('g')
        .attr('id', 'benefit-list-group')
        .attr('class', 'benefit-list-group');

      const benefitsListData = {
        lines: [
          [[1083, 492.5], [1083, 242]],
          [[1083, 492.5], [1083, 741]],
          // [[1029, 246], [1051, 246]],
          [[1083, 242], [1143, 242]],
          [[1083, 407], [1143, 407]],
          [[1083, 571], [1143, 571]],
          [[1083, 741], [1143, 741]],
        ],
        listData: {
          background: {
            id: 'benefit-list-background-rect-screen-7',
            width: 0,
            height: 506,
            finalWidth: 777,
            fill: 'url(#linear-gradient-white)',
            x: 1098,
            y: 194,
            // finalX: 1244,
            // finalY: 156,
            rx: 30,
            class: 'screen-7 benefit-list benefit-list-background animate',
            s: 1,
            o: 1
          },
          textData: [
            {
              wholeText: [
                {
                  text: 'Pre-check in: Ability to bundle marketing offers and engage in',
                  // textStyle: {
                  //   'font-weight': 500,
                  // }
                },
                {
                  text: ' ancillary or room based cross-sell/up-sell',
                  x: -5,
                  dy: '1.3em',
                }
              ],
              id: 'benefit-list-text-1-screen-7',
              class: 'benefit-list-text-1-screen-7 screen-7-text',
              x: 1145,
              y: 193,
              dy: '0em',
              finalX: 1245,
              finalY: 193,
              o: 0,
              textStyle: {
                // 'text-anchor': 'middle',
                'font-size': 20,
                'font-weight': 400,
                'fill': '#3f3d56'
              }
            },
            {
              wholeText: [
                {
                  text: 'Post-check in: Targeting curated ancillaries and concierge services',
                  // textStyle: {
                  //   'font-weight': 500,
                  // }
                },
                // {
                //   text: ' creative cross-sell/up-sell',
                //   x: -5,
                //   dy: '1.3em',
                // }
              ],
              id: 'benefit-list-text-2-screen-7',
              class: 'benefit-list-text-2-screen-7 screen-7-text',
              x: 1145,
              y: 354,
              dy: '0em',
              finalX: 1245,
              finalY: 354,
              o: 0,
              textStyle: {
                'text-anchor': 'left',
                'font-size': 20,
                'font-weight': 400,
                'fill': '#3f3d56'
              }
            },
            {
              wholeText: [
                {
                  text: 'Framework for evolving marketing and pricing strategy towards',
                  // textStyle: {
                  //   'font-weight': 500,
                  // }
                },
                {
                  text: ' Attribute-Based Selling model',
                  x: -5,
                  dy: '1.3em',
                }
              ],
              id: 'benefit-list-text-3-screen-7',
              class: 'benefit-list-text-3-screen-7 screen-7-text',
              x: 1145,
              y: 524,
              dy: '0em',
              finalX: 1245,
              finalY: 524,
              o: 0,
              textStyle: {
                // 'text-anchor': 'middle',
                'font-size': 20,
                'font-weight': 400,
                'fill': '#3f3d56'
              }
            },
            {
              wholeText: [
                {
                  text: 'More personalized offerings and better digital brand engagement,',
                  // textStyle: {
                  //   'font-weight': 500,
                  // }
                },
                {
                  text: ' thus an enhanced customer experience',
                  x: -5,
                  dy: '1.3em',
                }
              ],
              id: 'benefit-list-text-4-screen-7',
              class: 'benefit-list-text-4-screen-7 screen-7-text',
              x: 1145,
              y: 690,
              dy: '0em',
              finalX: 1245,
              finalY: 690,
              o: 0,
              textStyle: {
                // 'text-anchor': 'middle',
                'font-size': 20,
                'font-weight': 400,
                'fill': '#3f3d56'
              }
            }
          ]
        }
      };

      // createImageUsingData(benefitListGroup, { ...benefitsListData.titleBackgroundData.imageData[0] });
      // createTextUsingData(benefitListGroup, { ...benefitsListData.titleBackgroundData.titleText });
      // addRect(benefitListGroup, { ...benefitsListData.listData.background });
      benefitsListData.lines.forEach((el, i) => {
        let index = i;
        if (index === 0 || index === 1) {
          index = 0;
        } else {
          index = index - 1;
        }
        addDashedLine(benefitListGroup, el, 'list-line benefit-list-line-' + index, '#C94573', '4, 3', 1, false);
        // addLine(benefitListGroup, el, 'list-line benefit-list-line-', index);
      });

      benefitsListData.listData.textData.forEach((el, i) => {
        const textGroup = benefitListGroup.append('g')

        textGroup
          .datum({ ...el })
          .attr('class', 'benefit-list-text-group-' + (i + 1) + '-screen-7');

        const backgroundData = { ...benefitsListData.listData.background };

        backgroundData.id = `benefit-list-text-background-${(i + 1)}-screen-7`;
        backgroundData.x = el.x;
        backgroundData.y = el.y;
        backgroundData.height = 100;
        backgroundData.class += ` benefit-list-text-background-${(i + 1)}-screen-7`;

        addRect(textGroup, { ...backgroundData });
        const text = createTextUsingData(textGroup, { ...el, finalX: (el.finalX - 43) });
        text.datum({ ...el, finalX: (el.finalX - 43), y: el.y + ((100 - text.node().getBBox().height) / 2), finalY: el.y + ((100 - text.node().getBBox().height) / 2) });
        text.style('dominant-baseline', 'text-before-edge');
        text.attr('transform', (d: any) => `translate(${d.x} , ${(el.y + ((100 - text.node().getBBox().height) / 2))})`);
      });

      const screen7LeftSection = currentProcess.append('circle')
        .attr({
          'cx': 1030,
          'cy': 320,
          'r': 5,
          // 'fill': 'black',
          'fill': 'none',
        });

      function showList(group, className, startIndex, endIndex, cb) {
        operateLine(group, className + '-list-line-' + startIndex, 'draw', 0, 100, function () {
          group.select('.' + className + '-list-text-background-' + startIndex + '-screen-7')
            .transition()
            .delay(0)
            .duration(250)
            .attr('width', (d: any) => d.finalWidth)
            .call(endAll, function () {
              showText(group, className + '-list-text-' + startIndex + '-screen-7', 0, 200, function () {
                if (startIndex <= endIndex) {
                  startIndex++;
                  showList(group, className, startIndex, endIndex, cb);
                } else {
                  cb();
                }
              })
            })

        })
      }

      function benefitAnimation(group, className, endIndex, cb = function () { }) {
        // showImage(group, 'benefits-background-' + className + '-title-bg', 0, 300, function () {
        //   showText(group, 'benefits-' + className + '-title-screen-7', 0, 100, function () {
        operateLine(group, className + '-list-line-0', 'draw', 0, 300, function () {
          showList(group, className, 1, endIndex, cb);
        })
        //   })
        // })
      }

      function initiateAnimation() {
        const htmlDelayValue = 500;
        showImage(dataSummarySection, 'data-summary-bg', htmlDelayValue, 300, function () {
          showText(dataSummarySection, 'screen-data-summary-title-text-screen-7', 0, 100, function () {
            showGroup(dataSummarySection, 'pie-group-0', 50, 250, function () {
              showGroup(dataSummarySection, 'pie-group-1', 50, 250, function () {
                showGroup(dataSummarySection, 'pie-group-2', 50, 250, function () {
                  showText(impactSection, 'screen-impact-title-text-screen-7', 100, 100, function () {
                    showGroup(impactSection, 'impact-list-group-0', 50, 250, function () {
                      showGroup(impactSection, 'impact-list-group-1', 50, 250, function () {
                        showGroup(impactSection, 'impact-list-group-2', 50, 250, function () {
                          // // TODO class add remaining
                          that.addClassToDom('benefit-right-section', 'benefit-active');
                          benefitListGroup
                            .transition()
                            .delay(htmlDelayValue)
                            .duration(0)
                            .call(endAll, function () {
                              benefitAnimation(benefitListGroup, 'benefit', benefitsListData.listData.textData.length + 1, function () {
                              });
                            });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      }

      return {
        startAnimation(isFirst = false) {
          // // console.log('screen-7 startAnimation');
          // baseImage = '/assets/images/adaptive-pricing/7.png';
          // image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          }
          that.onAnimationEnd();
        },
        climaxAnimation() {
          currentProcess
            .transition()
            .duration(300)
            .style('opacity', 0);
        },
        resetElements() {

        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess.style('opacity', 0);
        },
        groups: {
          screen7LeftSection
        }
      }
    })();

    const screenOne = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-1-group')
        .attr('class', 'screen-1-group')
        .style('opacity', 1);

      return {
        startAnimation(isFirst = false) {
          // console.log('screen-1 startAnimation');
          // baseImage = '';
          // baseImage = '/assets/images/adaptive-pricing/1.png';
          // image.attr('xlink:href', baseImage);
          that.onAnimationEnd();
          commonLeftSection.manageTravellersGroup();
        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess.style('opacity', 0);
        }
      }
    })();

    const screenTwo = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-2-group')
        .attr('class', 'screen-2-group')
        .style('opacity', 0);

      const travelLeftLineGroup = currentProcess.append('g')
        .attr('class', 'travel-lines-screen-2');

      const travelLeftLines = [{
        line: [[293, 318], [378, 320], [426, 542], [472, 542]],
        type: 'solo'
      }, {
        line: [[292, 498], [378, 498], [426, 542], [472, 542]],
        type: 'business'
      }, {
        line: [[291, 673], [377, 673], [426, 542], [472, 542]],
        type: 'couple'
      }, {
        line: [[291, 845], [378, 845], [426, 542], [472, 542]],
        type: 'group'
      }];

      travelLeftLines.forEach((data, index) => {
        // console.log(data);
        const line = addDashedLine(travelLeftLineGroup, data.line, `screen-2 travel-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', d => d.pathLength);
      });

      const centerLeftLineGroup = currentProcess.append('g')
        .attr('class', 'center-left-lines-screen-2');

      const centerLeftLines = [{
        line: [[472, 542], [472, 476], [505, 447]],
        type: 'top'
      }, {
        line: [[472, 542], [472, 612], [506, 648]],
        type: 'bottom'
      }];

      centerLeftLines.forEach((data, index) => {
        const line = addDashedLine(centerLeftLineGroup, data.line, `screen-2 screen-2-lines center-left-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      });

      const middleSection = currentProcess.append('g')
        .attr('transform', 'translate(488, 360)');

      const screenTwoCenterData = {
        titleText: {
          wholeText: [{
            text: 'Hotel',
            textStyle: {
              'font-weight': 500,
            }
          }, {
            text: ' Attributes',
            textStyle: {
            }
          }],
          id: 'screen-title-text-screen-2',
          class: 'screen-title-text-screen-2 screen-2-text',
          x: 336,
          y: 0,
          finalX: 336,
          finalY: 10,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 19,
            'fill': '#3f3d56'
          }
        },
        imageData: [
          {
            id: 'center-image-background-screen-2',
            height: 172,
            width: 672,
            src: 'assets/images/adaptive-pricing/box.svg',
            x: 0,
            y: 86,
            finalX: 0,
            finalY: 0,
            class: 'screen-2 center background animate screen-2-images',
            s: 0
          }, {
            id: 'center-brand-screen-2',
            height: 65,
            width: 44,
            src: 'assets/images/adaptive-pricing/brand_grey.svg',
            finalX: 98,
            finalY: 55,
            x: 98,
            y: 45,
            class: 'screen-2 center icon brand animate screen-2-images',
            o: 0
          }, {
            id: 'center-location-screen-2',
            height: 65,
            width: 56,
            src: 'assets/images/adaptive-pricing/location_grey.svg',
            finalX: 200,
            finalY: 55,
            x: 200,
            y: 45,
            class: 'screen-2 center icon location animate screen-2-images',
            o: 0
          }, {
            id: 'center-star-screen-2',
            height: 64,
            width: 37,
            src: 'assets/images/adaptive-pricing/star_grey.svg',
            finalX: 318,
            finalY: 55,
            x: 318,
            y: 45,
            class: 'screen-2 center icon star animate screen-2-images',
            o: 0
          }, {
            id: 'center-review-screen-2',
            height: 62,
            width: 52,
            src: 'assets/images/adaptive-pricing/review_grey.svg',
            finalX: 418,
            finalY: 58,
            x: 418,
            y: 48,
            class: 'screen-2 center icon review animate screen-2-images',
            o: 0
          }, {
            id: 'center-discount-screen-2',
            height: 64,
            width: 56,
            src: 'assets/images/adaptive-pricing/discount_grey.svg',
            finalX: 530,
            finalY: 56,
            x: 530,
            y: 46,
            class: 'screen-2 center icon discount animate screen-2-images',
            o: 0
          }]
      };

      createTextUsingData(currentProcess, {
        wholeText: [{
          text: 'Attribute Unique to Each Guest',
          textStyle: {
            'font-weight': 500,
          }
        }],
        id: 'screen-other-title-text-screen-2',
        class: 'screen-other-title-text-screen-2 screen-2-text',
        x: 824,
        y: 299,
        dy: '0em',
        finalX: 824,
        finalY: 309,
        o: 0,
        textStyle: {
          'text-anchor': 'middle',
          'font-size': 19,
          'fill': '#3f3d56'
        }
      });

      createCenterBox(middleSection, screenTwoCenterData);

      const middleBottomSection = currentProcess.append('g')
        .attr('transform', 'translate(488, 572)');

      const screenTwoBottomData = {
        titleText: {
          wholeText: [{
            text: 'In-room',
            textStyle: {
              'font-weight': 500,
            }
          }, {
            text: ' Attributes',
            textStyle: {
            }
          }],
          id: 'screen-title-bottom-text-screen-2',
          class: 'screen-title-bottom-text-screen-2 screen-2-text',
          x: 336,
          y: 0,
          finalX: 336,
          finalY: 10,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 18,
            'fill': '#3f3d56'
          }
        },
        imageData: [
          {
            id: 'center-image-background-bottom-screen-2',
            width: 672,
            height: 172,
            src: 'assets/images/adaptive-pricing/box.svg',
            x: 0,
            y: 86,
            finalX: 0,
            finalY: 0,
            class: 'screen-2 center background animate screen-2-images',
            s: 0
          },
          {
            id: 'center-bed-screen-2',
            height: 60,
            width: 66,
            src: 'assets/images/adaptive-pricing/bed_grey.svg',
            x: 84,
            y: 50,
            finalX: 84,
            finalY: 60,
            class: 'screen-2 center icon bed animate screen-2-images',
            o: 0
          },
          {
            id: 'center-wifi-screen-2',
            height: 62,
            width: 96,
            src: 'assets/images/adaptive-pricing/wifi_grey.svg',
            x: 191,
            y: 48,
            finalX: 191,
            finalY: 58,
            class: 'screen-2 center icon wifi animate screen-2-images',
            o: 0
          },
          {
            id: 'center-bar-screen-2',
            height: 60,
            width: 63,
            src: 'assets/images/adaptive-pricing/bar_grey.svg',
            x: 328,
            y: 46,
            finalX: 328,
            finalY: 56,
            class: 'screen-2 center icon bar animate screen-2-images',
            o: 0
          },
          {
            id: 'center-balcony-screen-2',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/balcony_grey.svg',
            x: 431,
            y: 48,
            finalX: 431,
            finalY: 58,
            class: 'screen-2 center icon balcony animate screen-2-images',
            o: 0
          },
          {
            id: 'center-kitchen-screen-2',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/kitchen_grey.svg',
            x: 524,
            y: 47,
            finalX: 524,
            finalY: 57,
            class: 'screen-2 center icon kitchen animate screen-2-images',
            o: 0
          },
        ]
      };

      createCenterBox(middleBottomSection, screenTwoBottomData);

      function updateCenterIcons(delay) {
        // console.log('end');
        that.addClassToDom('screen-two-right-section', that.htmlClassVariable.card);
        setTimeout(function () {
          initialAnimationEnd();
        }, delay)
      }

      function startMiddleSectionAnimation(group, className, attribute, cb = function () { }) {
        showText(group, className, 0, 500);
        showImage(group, 'center'
          , 0, 500, function () {
            group.selectAll('.icon')
              .transition()
              .delay((d, i) => i * 10)
              .duration(200)
              .style('opacity', 1)
              .attr({
                'transform': (d: any) => `translate(${d.finalX}, ${d.finalY})`,
                'xlink:href': (d: any) => d.src
              })
              .transition()
              .delay(500)
              .call(endAll, function (d, i) {
                // console.log('All attributes have been visible');
                updateIcons(group, attribute, function () {
                  //add class(card-active) to right section
                  cb();
                });
              })
          }
        )
      }

      function endAnimation() {
        // console.log('Inside endAnimation');
        const selection = middleSection.selectAll('.icon')
        selection[0].reverse();
        const bottomSelection = middleBottomSection.selectAll('.icon')
        bottomSelection[0].reverse();
        //remove class(card-active) from right section
        that.removeClassFromDom('screen-two-right-section', that.htmlClassVariable.card);

        bottomSelection
          .transition()
          .delay((d, i) => i * 10)
          .duration(10)
          .style('opacity', 0)
          .attr('transform', (d: any) => `translate(${d.x}, ${d.y})`)

        selection
          .transition()
          .delay((d, i) => i * 10)
          .duration(10)
          .style('opacity', 0)
          .attr('transform', (d: any) => `translate(${d.x}, ${d.y})`)
          .call(endAll, function () {
            // console.log('On ENALL endAnimation');
            hideText(currentProcess, 'screen-other-title-text-screen-2', 0, 0);
            operateLine(centerLeftLineGroup, 'center-left-line', 'hide', 0, 0);
            operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 10);
            hideImage(middleSection, 'center', 0, 10);
            hideImage(middleBottomSection, 'center', 0, 10);
            hideText(middleSection, 'screen-title-text-screen-2', 0, 10);
            hideText(middleBottomSection, 'screen-title-bottom-text-screen-2', 0, 10);
          })
      }

      function updateTravellerPreference() {
        middleBottomSection.selectAll('.icon')
          .transition()
          .duration(5)
          .attr({
            'xlink:href': (d: any) => d.src
          })

        middleSection.selectAll('.icon')
          .transition()
          .duration(5)
          .attr({
            'xlink:href': (d: any) => d.src
          })
          .call(endAll, function (d, i) {
            operateLine(centerLeftLineGroup, 'center-left-line', 'hide', 0, 100, function () {
              operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 100, function () {
                operateLine(travelLeftLineGroup, that.selectedTraveller, 'draw', 0, 400, function () {
                  operateLine(centerLeftLineGroup, 'top', 'draw', 0, 400, function () {
                    updateIcons(middleSection, 'hotel', function () { initialAnimationEnd() });
                  })
                  operateLine(centerLeftLineGroup, 'bottom', 'draw', 0, 400, function () {
                    updateIcons(middleBottomSection, 'inRoom');
                  })
                })
              }
              );
            })

          });
      }

      function startLeftToMiddleAnimation() {
        // console.log('Screen 2 animation start');
        operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 0, function () {
          operateLine(travelLeftLineGroup, that.selectedTraveller, 'draw', 0, 400, function () {
            operateLine(centerLeftLineGroup, 'center-left-line', 'hide', 0, 0, function () {
              showText(currentProcess, 'screen-other-title-text-screen-2', 0, 400, function () { })
              operateLine(centerLeftLineGroup, 'bottom', 'draw', 0, 400)
              operateLine(centerLeftLineGroup, 'top', 'draw', 0, 400, function () {
                startMiddleSectionAnimation(middleSection, 'screen-title-text-screen-2', 'hotel', function () { updateCenterIcons(500) })
                startMiddleSectionAnimation(middleBottomSection, 'screen-title-bottom-text-screen-2', 'inRoom', function () { });
              })

            })

          })
        });
      }

      function resetElements() {
        // console.log('=================Inside screen2 hideElements function');
        operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 0);
        operateLine(centerLeftLineGroup, 'center-left-line', 'hide', 0, 0);
        hideImage(middleSection, 'screen-2-images', 0, 0);
        hideImage(middleSection, 'screen-2-text', 0, 0);
        hideImage(middleBottomSection, 'screen-2-images', 0, 0);
        hideImage(middleBottomSection, 'screen-2-text', 0, 0);
      }

      function initialAnimationEnd() {
        that.onAnimationEnd();
      }

      return {
        startAnimation(isFirst = false) {
          // console.log('screen-2 startAnimation');
          // baseImage = '';
          // baseImage = '/assets/images/adaptive-pricing/2.png';
          // image.attr('xlink:href', baseImage);
          // that.isAnimation = true;
          that.restrictScrollAndClick();
          currentProcess
            .transition()
            .delay(2000)
            .style('opacity', 1)
            .call(endAll, function () {
              commonLeftSection.animateTravellers();
            });
        },
        climaxAnimation() {
          // console.log('Screen 2 climaxAnimation Triggered');
          // that.isAnimation = true;
          currentProcess.transition();
          that.restrictScrollAndClick();
          endAnimation();
        },
        stopAnimation() {
          // console.log('Screen 2 stop animation');
          // currentProcess.interrupt();
          currentProcess.style('opacity', 0);
        },
        updateTravellerPreference,
        startLeftToMiddleAnimation,
        resetElements() {
          that.restrictScrollAndClick();
          resetElements();
        }
      }
    })();

    const screenThree = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-3-group')
        .attr('class', 'screen-3-group')
        .style('opacity', 0);

      const stepData = {
        bottomText: {
          wholeText: [
            {
              text: 'Segmentation to create',
              dy: '0em',
              y: 0,
              // x: -58,
              x: 0,
            },
            // {
            //   text: ' & ',
            //   dy: '0em',
            //   y: 0,
            //   x: 83
            // },
            {
              text: ' homogenized behavioral cohorts',
              dy: '1.2em',
              y: 0,
              x: 0
            }
          ],
          id: 'screen-3-step-bottom-text',
          class: 'screen-3 first-step-bottom-text screen-3-text',
          x: 833,
          y: 243,
          dy: '0em',
          finalX: 833,
          finalY: 253,
          o: 0,
          textStyle: {
            'font-weight': 400,
            'text-anchor': 'middle',
            'font-size': 18,
            'fill': '#C94573'
            // 'font-weight': 500,
            // 'fill': '#3f3d56'
          }
        },
        mainData: {
          id: 'screen-3-step-1',
          class: 'first-step-image step-background screen-3-images',
          x: 832,
          y: 196,
          finalX: 622,
          finalY: 116,
          width: 420,
          height: 160,
          src: '/assets/images/adaptive-pricing/step-bg.svg',
          o: 1,
          s: 0,
          text: {
            wholeText: [
              {
                text: 'Step 1:',
                dy: '0em',
                y: 0,
                x: 0,
                textStyle: {
                  // 'font-weight': 500,
                  'fill': '#C94573'
                }
              },
              {
                text: 'Analyzing booking intent',
                dy: '0em',
                y: 0,
                x: 66
              }
            ],
            id: 'screen-3-step-text',
            class: 'screen-3 first-step screen-3-text',
            x: 690,
            y: 194,
            dy: '0em',
            finalX: 690,
            finalY: 204,
            o: 0,
            textStyle: {
              // 'text-anchor': 'middle',
              'font-size': 20,
              'font-weight': 500,
              'fill': '#3f3d56'
            }
          }
        }
      };

      const octopus = {
        id: 'octopus-center',
        class: 'octopus-center screen-3-images',
        x: 837,
        y: 605,
        finalX: 712,
        finalY: 528,
        width: 250,
        height: 154,
        src: '/assets/images/adaptive-pricing/highlighted_box.svg',
        o: 1,
        s: 0,
        text: {
          wholeText: [
            {
              text: 'Attributes',
              dy: '0em',
              y: 0,
              x: 0
            },
            {
              text: 'of',
              dy: '1.3em',
              y: 0,
              x: 0
            },
            {
              text: 'Segmentation',
              dy: '2.3em',
              y: 0,
              x: 0
            },
          ],
          id: 'octopus-center-title',
          class: 'screen-3 octopus-center-title screen-3-text',
          x: 838,
          y: 591,
          dy: '0em',
          finalX: 838,
          finalY: 591,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 18,
            'font-weight': 500,
            'fill': '#bc5d7d'
          }
        }
      };

      const octopusLegsData = [
        {
          line: [[791, 554], [791, 511], [755, 479]],
          class: 'pair-1',
          duration: 100,
          delay: 0,
          model: {
            src: '/assets/images/adaptive-pricing/text_box.svg',
            x: 648,
            y: 458,
            id: '',
            class: '',
            finalX: 514,
            finalY: 411,
            width: 268,
            height: 94,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Length of stay',
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 860,
              y: 340,
              finalX: 860,
              finaly: 340,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[885, 554], [885, 511], [921, 479]],
          class: 'pair-1',
          duration: 100,
          delay: 100,
          model: {
            src: '/assets/images/adaptive-pricing/text_yellow_box.svg',
            x: 1058,
            y: 478,
            id: '',
            class: '',
            finalX: 903,
            finalY: 425,
            width: 310,
            height: 106,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Past dollars spent',
                  dy: '0.4em'
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 440,
              y: 440,
              finalX: 440,
              finalY: 440,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[742, 587], [713, 587], [681, 552]],
          class: 'pair-1',
          duration: 100,
          delay: 100,
          model: {
            src: '/assets/images/adaptive-pricing/text_box.svg',
            x: 571,
            y: 533,
            id: '',
            class: '',
            finalX: 437,
            finalY: 486,
            width: 268,
            height: 94,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Booking lead time',
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 955,
              y: 440,
              finalX: 955,
              finalY: 440,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[936, 589], [972, 589], [992, 570]],
          class: 'pair-1',
          duration: 100,
          delay: 100,
          model: {
            src: '/assets/images/adaptive-pricing/text_yellow_box.svg',
            x: 1123,
            y: 564,
            id: '',
            class: '',
            finalX: 968,
            finalY: 511,
            width: 310,
            height: 106,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Past total night stayed',
                  dy: '0.4em'
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 440,
              y: 440,
              finalX: 440,
              finalY: 440,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[738, 602], [646, 602]],
          class: 'pair-2',
          duration: 100,
          delay: 200,
          model: {
            src: '/assets/images/adaptive-pricing/text_box.svg',
            x: 528,
            y: 609,
            id: '',
            class: '',
            finalX: 394,
            finalY: 562,
            width: 268,
            height: 94,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Destination location',
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 440,
              y: 548,
              finalX: 392,
              finalY: 562,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[741, 617], [714, 617], [683, 652]],
          class: 'pair-3',
          duration: 100,
          delay: 200,
          model: {
            src: '/assets/images/adaptive-pricing/text_box.svg',
            x: 570,
            y: 688,
            id: '',
            class: '',
            finalX: 436,
            finalY: 641,
            width: 268,
            height: 94,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Travel type',
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 955,
              y: 548,
              finalX: 955,
              finalY: 548,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }

          }
        },
        {
          line: [[936, 620], [972, 620], [992, 639]],
          class: 'pair-3',
          duration: 100,
          delay: 200,
          model: {
            src: '/assets/images/adaptive-pricing/text_yellow_box.svg',
            x: 1123,
            y: 643,
            id: '',
            class: '',
            finalX: 968,
            finalY: 590,
            width: 310,
            height: 106,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Past star rating ref.',
                  dy: '0.4em'
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 440,
              y: 548,
              finalX: 440,
              finalY: 548,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[791, 655], [791, 698], [753, 734]],
          class: 'pair-3',
          duration: 100,
          delay: 300,
          model: {
            src: '/assets/images/adaptive-pricing/text_box.svg',
            x: 647,
            y: 762,
            id: '',
            class: '',
            finalX: 513,
            finalY: 715,
            width: 268,
            height: 94,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Weekend/day travel',
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 860,
              y: 650,
              finalX: 860,
              finalY: 650,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        },
        {
          line: [[885, 655], [885, 698], [921, 734]],
          class: 'pair-3',
          duration: 100,
          delay: 200,
          model: {
            src: '/assets/images/adaptive-pricing/text_yellow_box.svg',
            x: 1060,
            y: 731,
            id: '',
            class: '',
            finalX: 905,
            finalY: 678,
            width: 310,
            height: 106,
            s: 0,
            text: {
              wholeText: [
                {
                  text: 'Past ancillary interactions',
                  dy: '0.4em'
                }
              ],
              id: '',
              class: '',
              dy: '0em',
              x: 440,
              y: 548,
              finalX: 440,
              finalY: 548,
              o: 0,
              textStyle: {
                'dy': '0.4em',
                'text-anchor': 'middle',
                'font-size': '18',
                'fill': '#3f3d56'
              }
            }
          }
        }
      ];

      const middleGroup = currentProcess.append('g')
        .attr({
          'class': 'middle-group',
          'id': 'middle-group',
          'transform': `translate(0, 0)`
        });

      const octopusBackgroundGroup = middleGroup.append('g')
        .attr({
          'class': 'octopus-background-group',
          'id': 'octopus-background-group',
          'transform': 'translate(0, 0)'
        });

      const octopusGroup = middleGroup.append('g')
        .attr({
          'class': 'octopus-group',
          'id': 'octopus-group',
          'transform': 'translate(0, 0)'
        });

      const stepGroup = middleGroup.append('g')
        .attr({
          'class': 'step-group',
          'id': 'step-group',
          'transform': 'translate(0, 0)'
        });

      createImageUsingData(stepGroup, { ...stepData.mainData });

      createTextUsingData(stepGroup, { ...stepData.mainData.text });
      createTextUsingData(stepGroup, { ...stepData.bottomText });

      const backgroundData = {
        leftText: {
          wholeText: [{
            text: 'Current booking facets',
            textStyle: {
              'font-weight': 500,
            }
          }],
          id: 'octopus-background-left-text-screen-3',
          class: 'octopus-background-left-text-screen-3 octopus-background-text left-text',
          x: 661,
          y: 376,
          finalX: 661,
          finalY: 386,
          dy: '0em',
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 18,
            'fill': '#3f3d56'
          }
        },
        rightText: {
          wholeText: [{
            text: 'Guest\'s historical preferences',
            textStyle: {
              'font-weight': 500,
            }
          }],
          id: 'octopus-background-right-text-screen-3',
          class: 'octopus-background-right-text-screen-3 octopus-background-text right-text',
          x: 1045,
          y: 376,
          finalX: 1045,
          finalY: 386,
          dy: '0em',
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 18,
            'fill': '#3f3d56'
          }
        },
        rectData: [
          {
            id: 'octopus-background-left-rect-screen-3',
            width: 0,
            height: 550,
            finalWidth: 483,
            fill: 'url(#linear-gradient)',
            x: 840,
            y: 326,
            finalX: 357,
            finalY: 0,
            class: 'screen-3 left background animate',
            s: 1,
            o: 1
          }, {
            id: 'octopus-background-right-rect-screen-3',
            width: 0,
            height: 550,
            finalWidth: 483,
            x: 840,
            y: 326,
            fill: 'url(#linear-gradient-grey)',
            finalX: 103,
            finalY: 58,
            class: 'screen-3 right background animate',
            s: 1,
            o: 1
          }
        ]
      };

      addRect(octopusBackgroundGroup, { ...backgroundData.rectData[0] });
      addRect(octopusBackgroundGroup, { ...backgroundData.rectData[1] });
      createTextUsingData(octopusBackgroundGroup, { ...backgroundData.leftText });
      createTextUsingData(octopusBackgroundGroup, { ...backgroundData.rightText });

      const octopusLegsGroup = octopusGroup.append('g')
        .attr({
          'class': 'octopus-legs-group',
          'id': 'octopus-legs-group',
        })
        .style('font-weight', '400');

      createImageUsingData(octopusGroup, { ...octopus });

      createTextUsingData(octopusGroup, { ...octopus.text })

      octopusLegsData.forEach((leg, index) => {
        const group = octopusLegsGroup.append('g')
          .attr({
            'class': 'octopus-leg-group',
            'id': `octopus-leg-group-${index}`,
          });
        const line = addDashedLine(group, leg.line, `screen-3-lines octopus-base-line line-${leg.class}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', d => d.pathLength)

        leg.model.class = `screen-3-images octopus-toe text-background-${leg.class}`;
        leg.model.id = `octopus-toe-${index}`;

        createImageUsingData(group, { ...leg.model });

        leg.model.text.id = `text-${leg.class}`;
        leg.model.text.class = `screen-3-text text-${leg.class}`;
        leg.model.text.x = leg.model.finalX + (leg.model.width / 2);
        leg.model.text.y = leg.model.finalY + leg.model.height / 2;
        leg.model.text.finalX = leg.model.finalX + (leg.model.width / 2);
        leg.model.text.finalY = (leg.model.finalY + (leg.model.height / 2));

        createTextUsingData(group, { ...leg.model.text })
      })

      function initAnimation() {
        showImage(stepGroup, 'first-step-image', 0, 300, function () {
          showText(stepGroup, 'first-step', 0, 200, function () {
            showText(stepGroup, 'first-step-bottom-text', 0, 200, function () {
              showImage(octopusGroup, 'octopus-center', 0, 300, function () {
                showText(octopusGroup, 'octopus-center-title', 0, 200, function () {
                  octopusBackgroundGroup.select('.left')
                    .transition()
                    .delay(200)
                    .duration(300)
                    .attr('width', (d: any) => d.finalWidth)
                    .attr('x', (d: any) => d.finalX)

                  octopusBackgroundGroup.select('.right')
                    .transition()
                    .delay(200)
                    .duration(300)
                    .attr('width', (d: any) => d.finalWidth)
                    .call(endAll, function () {
                      showText(octopusBackgroundGroup, 'left-text', 0, 200, function () { })
                      showText(octopusBackgroundGroup, 'right-text', 0, 200, function () { })
                      let index = 1;
                      function opctopusRepeat(index, maxValue) {
                        operateLine(octopusLegsGroup, `line-pair-${index}`, 'draw', 0, 100, function () {
                          showImage(octopusLegsGroup, `text-background-pair-${index}`, 0, 200, function () {
                            showText(octopusLegsGroup, `text-pair-${index}`, 0, 100, function () {
                              index++;
                              if (index <= maxValue) {
                                opctopusRepeat(index, maxValue);
                              } else {
                                //add class(card-active) to right section
                                that.addClassToDom('screen-three-right-section', that.htmlClassVariable.card);
                                setTimeout(function () {
                                  // console.log("screen three initial animation done");
                                  initialAnimationEnd();
                                }, 500);
                              }
                            })
                          })
                        })
                      }
                      opctopusRepeat(1, 3)
                    });
                })
              })


            })
          })
        })

      }

      function initialAnimationEnd() {
        that.onAnimationEnd();
      }

      function endAnimation() {
        function opctopusRepeat(index, maxValue) {
          hideText(octopusLegsGroup, `text-pair-${index}`, 0, 10, function () {
            hideImage(octopusLegsGroup, `text-background-pair-${index}`, 0, 10, function () {
              operateLine(octopusLegsGroup, `line-pair-${index}`, 'hide', 0, 10, function () {
                index--;
                if (index >= maxValue) {
                  opctopusRepeat(index, maxValue);
                } else {
                  hideText(octopusGroup, 'octopus-center-title', 0, 10, function () {
                    hideImage(octopusGroup, 'octopus-center', 0, 10)
                    hideText(octopusBackgroundGroup, 'left-text', 0, 10, function () { })
                    hideText(octopusBackgroundGroup, 'right-text', 0, 10, function () { })

                    octopusBackgroundGroup.select('.left')
                      .transition()
                      .duration(10)
                      .attr('width', (d: any) => d.width)
                      .attr('x', (d: any) => d.x)

                    octopusBackgroundGroup.select('.right')
                      .transition()
                      .duration(10)
                      .attr('width', (d: any) => d.width)

                    hideText(stepGroup, 'first-step-bottom-text', 0, 10, function () {
                      hideText(stepGroup, 'first-step', 0, 10);
                      hideImage(stepGroup, 'first-step-image', 0, 10);
                    })
                  })
                }
              })
            })
          })
        }
        //remove class(card-active) from right section
        that.removeClassFromDom('screen-three-right-section', that.htmlClassVariable.card);
        opctopusRepeat(3, 1);
      }

      function resetElements() {
        // console.log('=================Inside screen3 hideElements function');
        operateLine(middleGroup, 'screen-3-lines', 'hide', 0, 0);
        hideImage(middleGroup, 'screen-3-images', 0, 0);
        hideText(middleGroup, 'screen-3-text', 0, 0);
      }

      return {
        startAnimation(isFirst = false) {
          // that.isAnimation = true;
          // // console.log('screen-3 startAnimation');
          // baseImage = '';
          // baseImage = '/assets/images/adaptive-pricing/3.png';
          // image.attr('xlink:href', baseImage);
          that.restrictScrollAndClick();
          currentProcess
            .transition()
            .delay(that.currentScrollDirection == 'backward' ? 0 : 2000)
            .style('opacity', 1)
            .call(endAll, function () {
              initAnimation();
            });
        },
        climaxAnimation() {
          // that.isAnimation = true;
          currentProcess.transition();
          that.restrictScrollAndClick();
          endAnimation();
          // that.restrictScrollAndClick();
        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess.style('opacity', 0);
        },
        resetElements() {
          resetElements();
        }
      }
    })();

    const screenFour = (() => {
      const stepData = {
        bottomText: {
          wholeText: [
            {
              text: 'Estimate guest\'s level WTP using segment-level',
              dy: '0em',
              y: 0,
              x: 0
            },
            {
              text: 'preferences as priors',
              dy: '1.2em',
              y: 0,
              x: 0
            }
          ],
          id: 'screen-4-step-bottom-text',
          class: 'screen-4 second-step-bottom-text',
          x: 773,
          y: 243,
          dy: '0em',
          finalX: 773,
          finalY: 253,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 18,
            'font-weight': 400,
            'fill': '#c94573'
          }
        },
        mainData: {
          id: 'screen-4-step-2',
          class: 'second-step-image step-background',
          x: 772,
          y: 176,
          finalX: 562,
          finalY: 116,
          width: 420,
          height: 160,
          src: '/assets/images/adaptive-pricing/step-bg.svg',
          o: 1,
          s: 0,
          text: {
            wholeText: [
              {
                text: 'Step 2:',
                dy: '0em',
                y: 0,
                x: 0,
                textStyle: {
                  'fill': '#C94573'
                }
              },
              {
                text: 'WTP Assessment',
                dy: '0em',
                y: 0,
                x: 66
              }
            ],
            id: 'screen-4-step-text',
            class: 'screen-4 second-step',
            x: 660,
            y: 194,
            dy: '0em',
            finalX: 660,
            finalY: 204,
            o: 0,
            textStyle: {
              // 'text-anchor': 'middle',
              'font-size': 20,
              'font-weight': 500,
              'fill': '#3f3d56'
            }
          }
        }
      };

      const middleBlockContent = {
        title: {
          wholeText: [
            {
              text: 'Decision Choice',
              textStyle: {
                'font-weight': 500
              }
            }, {
              text: ' Modeling'
            }
          ],
          id: 'screen-4-title-center',
          class: 'title-center screen-4-text',
          dy: '0em',
          x: 300,
          y: -5,
          finalX: 300,
          finalY: 5,
          textStyle: {
            'font-size': '22',
            'text-anchor': 'middle',
            'fill': '#3f3d56'
          }
        },
        images: [
          {
            id: 'rrm-block-screen-4',
            height: 154,
            width: 660,
            src: 'assets/images/adaptive-pricing/robust_regression_model.svg',
            x: 330,
            y: 77,
            finalX: -30,
            finalY: 10,
            s: 0,
            class: 'screen-4 center rrm-block animate screen-4-images'
          }, {
            id: 'brain-image',
            height: 57,
            width: 57,
            src: 'assets/images/adaptive-pricing/brain.svg',
            x: 299,
            y: 85.5,
            finalX: 271,
            finalY: 57,
            s: 0,
            class: 'screen-4 center brain-image animate screen-4-images'
          }],
        contents: [{
          x: 66,
          y: 71,
          text: {
            wholeText: [
              {
                text: 'Booked Property'
              }
            ],
            id: '',
            class: 'concept-title screen-4-text',
            x: 0,
            y: -10,
            finalX: 0,
            finalY: 0,
            dy: '0em',
            o: 0,
            textStyle: {
              'fill': '#3f3d56',
              'font-size': '16',
              'text-anchor': 'start',
              'font-weight': '500'
            },

            properties: [{
              wholeText: [
                {
                  text: '- Hotel attributes'
                }
              ],
              id: '',
              class: 'concept-props-1 screen-4-text',
              x: 0,
              y: -9,
              dy: '1.5em',
              finalX: 0,
              finalY: 1,
              o: 0,
              textStyle: {
                'fill': '#3f3d56',
                'font-size': '13',
                'text-anchor': 'start'
              },
            },
            {
              wholeText: [
                {
                  text: '- In-room attributes'
                }
              ],
              id: '',
              class: 'concept-props-2 screen-4-text',
              x: 0,
              y: -9,
              dy: '3em',
              finalX: 0,
              finalY: 1,
              o: 0,
              textStyle: {
                'fill': '#3f3d56',
                'font-size': '13',
                'text-anchor': 'start'
              },
            }]
          }
        },
        {
          x: 392,
          y: 71,
          text: {
            wholeText: [
              {
                text: 'Alternative Properties'
              }
            ],
            id: '',
            class: 'concept-title screen-4-text',
            x: 0,
            y: -10,
            finalX: 0,
            finalY: 0,
            dy: '0em',
            o: 0,
            textStyle: {
              'fill': '3f3d56',
              'font-size': '16',
              'text-anchor': 'start',
              'font-weight': '500'
            },
            properties: [{
              wholeText: [
                {
                  text: '- Clicked booking choice'
                }
              ],
              id: '',
              class: 'concept-props-1 screen-4-text',
              x: 0,
              y: -9,
              dy: '1.5em',
              finalX: 0,
              finalY: 1,
              o: 0,
              textStyle: {
                'fill': '#3f3d56',
                'font-size': '13',
                'text-anchor': 'start'
              },
            },
            {
              wholeText: [
                {
                  text: '- Non-clicked booking choice'
                }
              ],
              id: '',
              class: 'concept-props-2 screen-4-text',
              x: 0,
              y: -9,
              dy: '3em',
              finalX: 0,
              finalY: 1,
              o: 0,
              textStyle: {
                'fill': '#3f3d56',
                'font-size': '13',
                'text-anchor': 'start'
              }
            }]
          }
        }],
        types: [
          {
            line: [[276, 39], [276, 87], [240, 123]],
            model: {
              x: 125,
              y: 155,
              // rx: 25,
              id: '',
              class: '',
              finalX: -60,
              finalY: 102,
              height: 126,
              width: 350,
              s: 0,
              text: {
                wholeText: [
                  {
                    text: 'Feature Engineering',
                    textStyle: {
                      'font-weight': 500
                    }
                  }
                ],
                id: '',
                class: 'model-bottom-bg',
                dy: '0em',
                x: -20,
                y: 102,
                finalX: -20,
                finalY: 102,
                o: 0,
                textStyle: {
                  'dy': '0.4em',
                  'text-anchor': 'middle',
                  'font-size': '16',
                  'fill': '#3f3d56'
                }
              }
            }
          },
          {
            line: [[300, 42], [300, 230]],
            model: {
              x: 300,
              y: 265,
              id: '',
              class: '',
              finalX: 118,
              finalY: 212,
              height: 126,
              width: 350,
              s: 0,
              text: {
                wholeText: [
                  {
                    text: 'Multinomial logit',
                    x: 0,
                    dy: '-0.7em',
                    textStyle: {
                      'font-weight': 500
                    }
                  },
                  {
                    text: '(Segment model)',
                    x: 0,
                    dy: '1.2em',
                    textStyle: {
                      'font-weight': 500
                    }
                  }
                ],
                id: '',
                class: 'model-bottom-bg',
                dy: '0em',
                x: 300,
                y: 233,
                finalX: 300,
                finalY: 233,
                o: 0,
                textStyle: {
                  'dy': '0.4em',
                  'text-anchor': 'middle',
                  'font-size': '16',
                  'fill': '#3f3d56'
                }
              }
            }
          },
          {
            line: [[324, 39], [324, 87], [360, 123]],
            model: {
              y: 155,
              x: 465,
              id: '',
              class: '',
              finalY: 102,
              finalX: 310,
              height: 126,
              width: 350,
              s: 0,
              text: {
                wholeText: [
                  {
                    text: 'Hierarchical Bayesian',
                    x: 0,
                    dy: '-0.7em',
                    textStyle: {
                      'font-weight': 500
                    }
                  },
                  {
                    text: '(Customer Decision Choice model)',
                    x: 0,
                    dy: '1.2em',
                    textStyle: {
                      'font-weight': 500
                    }
                  }
                ],
                id: '',
                class: 'model-bottom-bg',
                dy: '0em',
                x: 465,
                y: 155,
                finalX: 465,
                finalY: 155,
                o: 0,
                textStyle: {
                  'dy': '0.4em',
                  'text-anchor': 'middle',
                  'font-size': '16',
                  'fill': '#3f3d56'
                }
              }
            }
          }
        ]
      };

      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-4-group')
        .attr('class', 'screen-4-group')
        .style('opacity', 0);

      const middleSectionWrapper = currentProcess.append('g')
        .attr({
          'class': 'middle-screen-wrapper',
          'id': 'middle-screen-wrapper'
        });

      const stepGroup = middleSectionWrapper.append('g')
        .attr({
          'class': 'screen-4-step-group',
          'id': 'screen-4-step-group',
          'transform': 'translate(0, 0)'
        });

      createImageUsingData(stepGroup, { ...stepData.mainData });

      createTextUsingData(stepGroup, { ...stepData.mainData.text });
      createTextUsingData(stepGroup, { ...stepData.bottomText });

      const middleSectionBlock = middleSectionWrapper.append('g')
        .style({
          'transform': `translate(471px, 358px)`
        });

      const middleSectionAttrProps = middleSectionBlock.append('g')
        .attr({
          'class': 'middle-section-attr-props',
          'id': 'middle-section-attr-props'
        });

      const middleSectionModels = middleSectionBlock.append('g')
        .attr({
          'class': 'middle-section-attr-props',
          'id': 'middle-section-attr-props'
        })
        .style({
          'transform': `translate(0, 100px)`
        });

      createTextUsingData(middleSectionAttrProps, middleBlockContent.title);

      middleBlockContent.images.forEach(d => {
        createImageUsingData(middleSectionAttrProps, { ...d })
      })

      middleBlockContent.contents.forEach((section, key) => {
        const group = middleSectionAttrProps.append('g')
          .style({
            'transform': `translate(${section.x}px,${section.y}px)`
          })
        section.text.id = 'title-text-' + key;
        createTextUsingData(group, section.text)

        section.text.properties.forEach((prop, index) => {

          prop.id = 'sub-text-' + index;
          createTextUsingData(group, prop)
        })
      });

      const modelLinePath = [];
      middleBlockContent.types.forEach((model, index) => {
        const group = middleSectionModels.append('g')
          .attr({
            'class': 'concept-model-group',
            'id': `concept-model-group-${index}`,
          });

        const line = addDashedLine(group, model.line, 'screen-4-lines model-base-line', '#65617d', '5,4');
        line.attr('stroke-dashoffset', d => d.pathLength);
        // console.log(model.model);
        model.model.id = model.model.id || 'bottom-background-' + index;
        model.model.class = 'bottom-background screen-4-images';


        createImageUsingData(group, {
          ...model.model,
          src: 'assets/images/adaptive-pricing/' + (model.model.id === 'large-text' ? 'text_box_large' : 'text_box_3') + '.svg'
        })

        model.model.text.id = `bottom-text`;
        model.model.text.class = `bottom-text screen-4-text`;
        model.model.text.x = model.model.finalX + (model.model.width / 2);
        model.model.text.y = model.model.finalY + model.model.height / 2;
        model.model.text.finalX = model.model.finalX + (model.model.width / 2);
        model.model.text.finalY = (model.model.finalY + (model.model.height / 2)) + 6;

        // model.model.text.o = 1;
        createTextUsingData(group, { ...model.model.text });
      });

      // right portion
      const lineGraphTranslate = {
        x: 1271,
        y: 270
      };

      const rightContainer = currentProcess.append('g')
        .attr({
          'class': 'right-container',
          'id': 'right-container',
        })

      createImageUsingData(rightContainer,
        {
          id: `right-bg`
          , class: 'right-bg'
          , src: '/assets/images/adaptive-pricing/graph_container.svg'
          , width: 715.77
          , height: 906
          , x: 1540
          , y: 533
          , finalX: 1133
          , finalY: 75
          , s: 0
          , o: 1
        });

      function manageDraggableTooltip(group, value) {
        if (value < 90) {
          group.style('transform', `translate(-23px, -37px)`);
          group.select(`path`)
            .attr({
              'd': 'm19.617,20l6.155,0l-3.087,3.243l-3.068,-3.243zm-9.617,0a10,10 0 0 1 -10,-10a10,10 0 0 1 10,-10l25,0a10,10 0 0 1 10,10a10,10 0 0 1 -10,10l-25,0z', //top
            });
          group.select('text')
            .attr({
              'x': '24',
              'dy': '1.1em',
            });
        } else {
          group.style('transform', `translate(-23px, 13px)`);
          group.select('path').attr({
            'd': 'm22.32,0l3.06,3.24l-6.15,0l3.09,-3.24zm-12.32,3.24c-1.31,0 -2.61,0.26 -3.83,0.76c-1.21,0.51 -2.31,1.24 -3.24,2.17c-0.93,0.93 -1.67,2.03 -2.17,3.25c-0.5,1.21 -0.76,2.51 -0.76,3.82c0,1.32 0.26,2.62 0.76,3.83c0.5,1.21 1.24,2.32 2.17,3.24c0.93,0.93 2.03,1.67 3.24,2.17c1.22,0.5 2.52,0.76 3.83,0.76l25,0c1.31,0 2.61,-0.26 3.83,-0.76c1.21,-0.5 2.31,-1.24 3.24,-2.17c0.93,-0.92 1.67,-2.03 2.17,-3.24c0.5,-1.21 0.76,-2.51 0.76,-3.83c0,-1.31 -0.26,-2.61 -0.76,-3.82c-0.5,-1.22 -1.24,-2.32 -2.17,-3.25c-0.93,-0.93 -2.03,-1.66 -3.24,-2.17c-1.22,-0.5 -2.52,-0.76 -3.83,-0.76l-25,0z', //below
          });
          group.select('text')
            .attr({
              'x': '22',
              'dy': '1.3em',
            });
        }
      }

      const lineGraphWrapper = currentProcess.append('g')
        .attr('id', 'line-graph-wrapper')
        .attr('class', 'line-graph-wrapper')
        .style('transform', `translate(${lineGraphTranslate.x}px, ${lineGraphTranslate.y}px)`)
        .style('opacity', 0);

      const margin = { top: 30, right: 20, bottom: 30, left: 50 }
        , width = 500 - margin.left - margin.right
        , height = 550 - margin.top - margin.bottom
        , parseDate = d3.time.format('%d-%b-%y').parse;

      // Set the ranges
      let yMax = 105;
      let x = d3.time.scale().range([0, width]);
      let y = d3.scale.linear().range([height, 0]);
      let value = d3.scale.linear().range([yMax, 0]);

      // // console.log('height: ', height);
      // .origin(function () {
      //   var current = d3.select(this);
      //   return { x: current.attr("x"), y: current.attr("y") };
      // })
      var drag = d3.behavior.drag()
        .on('drag', function (d, i) {
          if (d.isDraggable) {
            lineGraphWrapper.select('.hand-cursor')
              .style('opacity', d => {
                d.isAnimating = false;
                return '0'
              })
              .attr({
                'transform': `translate(0, 0)`
              });
            // const tx = x(d.date);
            // const ty = (d3.event.sourceEvent.pageY / that.svgResize.heightAspect) - lineGraphTranslate.y;
            d.y += d3.event.y;
            const parentNode = this.parentNode;
            const close = Math.round(value(d.y));
            if (close >= 0 && close <= 100) {
              draggableCircleWrapper.select(`#${this.parentNode.id}`)
                .attr('transform', `translate(${d.x}, ${d.y})`);
              const group = currentProcess.select(`#drag-circle-tooltip-${i}`)
              manageDraggableTooltip(group, close);
              currentProcess.select(`#drag-circle-tooltip-${i} text`).text(`$ ${close}`);
              that.pricingData.travellerDetails[that.selectedTraveller].attributes.hotel[i].preferredValue = close;
              data[i].close = close;
              const updateGuestValue = data.reduce((total, obj, key) => total + obj.close, 0);
              const updateSegmentValue = that.pricingData.travellerDetails[that.selectedTraveller].attributes.hotel.reduce((total, obj) => total + obj.defaultValue, 0);;
              guestValueText.text(`$${updateGuestValue}`);
              // const avgValue = `${that.pricingData.currencyUnit}${((updateGuestValue + updateSegmentValue) / 2).toFixed(0)}`
              // avgValueText.text(avgValue);
            }
          }
        });

      var yGridLine = d3.svg.axis()
        .scale(y)
        .tickSize(-width - 45, 0, 0)
        .tickFormat('')
        .orient('left');

      var xGridLine = d3.svg.axis()
        .scale(x)
        .tickSize(height, 0, 0)
        .tickFormat('')
        .orient('bottom');

      var xAxis = d3.svg.axis().scale(x)
        .orient('bottom').ticks(5);

      var yAxis = d3.svg.axis().scale(y)
        .orient('left').ticks(10);

      // Define the line
      // var valueline = d3.svg.line()
      //   .x(function (d) { return x(d.date); })
      //   .y(function (d) { return y(d.close); });

      const data = [
        {
          date: '23-Apr-12',
          close: 0,
          dragValues: [-3, 5, -4, 4, 0],
          isDraggable: true
        },
        {
          date: '24-Apr-12',
          close: 0,
          dragValues: [-3, 5, -4, 4, 0],
          isDraggable: true
        },
        {
          date: '25-Apr-12',
          close: 0,
          dragValues: [4, -5, 3, -4, 0],
          isDraggable: true
        },
        {
          date: '26-Apr-12',
          close: 0,
          dragValues: [-5, 4, -4, 5, 0],
          isDraggable: true
        },
        {
          date: '27-Apr-12',
          close: 0,
          dragValues: [4, -4, 3, -5, 0],
          isDraggable: true
        },
        {
          date: '28-Apr-12',
          close: 0,
          dragValues: [4, -4, 3, -5, 0],
          isDraggable: true
        },
        {
          date: '29-Apr-12',
          close: 0,
          dragValues: [-3, 4, -5, 3, 0],
          isDraggable: true
        },
      ];

      data.forEach(function (d) {
        d.date = parseDate(d.date);
        d.close = +d.close;
      });

      // // console.log('Data: ', data);

      // Scale the range of the data
      x.domain(d3.extent(data, function (d) { return d.date; }));
      y.domain([0, d3.max(data, function (d) { return yMax; })]);
      value.domain([0, height]);

      // Add the X Axis
      // lineGraphWrapper.append('g')
      //   .attr('class', 'x axis')
      //   .attr('transform', 'translate(0,' + height + ')')
      //   .call(xAxis);

      // Add the Y Axis
      const xGridGroup = lineGraphWrapper.append('g')
        .attr('class', 'x-grid')
        .style('stroke', '#fff')
        // .style('stroke', 'black')
        .style('stroke-width', '1')
        .call(xGridLine);

      const yGridGroup = lineGraphWrapper.append('g')
        .attr('class', 'y-grid')
        .style('stroke', '#fff')
        // .style('stroke', 'black')
        .style('stroke-width', '1')
        .call(yGridLine);

      yGridGroup.selectAll('path').remove();
      yGridGroup
        .select('g:nth-child(1)')
        .select('line')
        .style('stroke', '#A19FB5');

      xGridGroup.selectAll('path').remove();
      xGridGroup
        .select('g:nth-child(1)')
        .remove();
      xGridGroup.selectAll('g').each(function (object, index) {
        if (index % 2 == 0)
          this.remove()
      });

      const yAxisGroup = lineGraphWrapper.append('g')
        .attr('class', 'y axis')
        .attr('font-size', '14')
        // .attr('transform', `translate(${width},0)`)
        .style({
          // 'letter-spacing': '1px',
          // 'stroke': '#A19FB5',
          'font-weight': '500'
        })
        .call(yAxis);

      yAxisGroup.select('path')
        .attr('d', 'M0,0V490');
      yAxisGroup.selectAll('line').remove();

      const fixedCircleWrapper = lineGraphWrapper.append('g')
        .attr({
          'class': 'fixed-circle-wrapper',
          'id': 'fixed-circle-wrapper',
        })

      data.shift();

      const fixedCircleGroup = fixedCircleWrapper.selectAll('circle')
        .data(data)
        .enter()
        .append('g')
        .attr({
          'class': (d, i) => `fixed-circle-group ${that.pricingData.travellerDetails.solo.attributes.hotel[i].id}`,
          'transform': d => `translate(${x(d.date)}, ${y(d.close)})`
        });

      fixedCircleGroup.append('circle')
        .attr({
          'cx': (d) => 0,
          'cy': (d) => 0,
          'r': 6,
          'fill': '#7e5f69'
        });

      const fixedCircleTooltip = fixedCircleGroup.append('g')
        .attr({
          'class': 'fixed-circle-tooltip',
          'id': (d, i) => `fixed-circle-tooltip-${i}`,
        })
        .style({
          'transform': (d) => `translate(-22px, 12px)`
        });

      fixedCircleTooltip.append('path')
        .attr({
          'd': 'm22.32,0l3.06,3.24l-6.15,0l3.09,-3.24zm-12.32,3.24c-1.31,0 -2.61,0.26 -3.83,0.76c-1.21,0.51 -2.31,1.24 -3.24,2.17c-0.93,0.93 -1.67,2.03 -2.17,3.25c-0.5,1.21 -0.76,2.51 -0.76,3.82c0,1.32 0.26,2.62 0.76,3.83c0.5,1.21 1.24,2.32 2.17,3.24c0.93,0.93 2.03,1.67 3.24,2.17c1.22,0.5 2.52,0.76 3.83,0.76l25,0c1.31,0 2.61,-0.26 3.83,-0.76c1.21,-0.5 2.31,-1.24 3.24,-2.17c0.93,-0.92 1.67,-2.03 2.17,-3.24c0.5,-1.21 0.76,-2.51 0.76,-3.83c0,-1.31 -0.26,-2.61 -0.76,-3.82c-0.5,-1.22 -1.24,-2.32 -2.17,-3.25c-0.93,-0.93 -2.03,-1.66 -3.24,-2.17c-1.22,-0.5 -2.52,-0.76 -3.83,-0.76l-25,0z', //below
          // 'd': 'm19.617,20l6.155,0l-3.087,3.243l-3.068,-3.243zm-9.617,0a10,10 0 0 1 -10,-10a10,10 0 0 1 10,-10l25,0a10,10 0 0 1 10,10a10,10 0 0 1 -10,10l-25,0z', //top
          // 'd': 'm13.29495,19.99899a10.006,10.006 0 0 1 -9.663,-7.41l-3.632,-2.673l3.675,-2.659a10.005,10.005 0 0 1 9.62,-7.257l21.707,0a10,10 0 0 1 10,10a10,10 0 0 1 -10,10l-21.707,-0.001z', //right
          'fill': '#8b6e78',
        });

      fixedCircleTooltip
        .append('text')
        .text((d) => `$ ${d.close}`)
        .attr({
          'fill': '#fff',
          'x': 22,
          'y': 0,
          'dy': '1.3em',
          'text-anchor': 'middle',
          'font-size': '14'
        });

      const draggableCircleWrapper = lineGraphWrapper.append('g')
        .attr({
          'class': 'drag-circle-wrapper',
          'id': 'drag-circle-wrapper',
        });

      const draggableCircleGroup = draggableCircleWrapper.selectAll('g')
        .data(data)
        .enter()
        .append('g')
        .attr({
          'class': (d, i) => `drag-circle-group ${that.pricingData.travellerDetails.solo.attributes.hotel[i].id}`,
          'id': (d, i) => `drag-circle-group-${i}`,
          'opacity': '0',
          'transform': (d) => {
            d.x = x(d.date);
            d.y = y(d.close);
            return `translate(${d.x}, ${d.y})`
          },
        })

      const dragPointerGroup = draggableCircleGroup.append('g')
        .attr({
          'class': 'drag-pointer',
          'id': (d, i) => `drag-pointer-${i}`,
        })
        .style({
          'transform': (d) => `translate(${-10}px, ${-7}px)`,
          'cursor': 'pointer'
        })
        // .on('click', function () {
        //   // console.log('pointer value: ', d3.event)
        // })
        .call(drag);

      dragPointerGroup.append('rect')
        .attr({
          'x': 0,
          'y': 0,
          'width': 20,
          'height': 14,
          'rx': 6,
          'fill': '#c94573'
        });

      dragPointerGroup.append('path')
        .attr({
          'd': 'm4.998,9l0,-1l10,0l0,1l-10,0zm0,-3l0,-1l10,0l0,1l-10,0z',
          'fill': '#fff'
        });

      const draggableCircleTooltip = draggableCircleGroup.append('g')
        .attr({
          'class': 'drag-circle-tooltip',
          'id': (d, i) => `drag-circle-tooltip-${i}`,
        })
        .style({
          'transform': (d) => `translate(${-23}px, ${-37}px)`
        });

      draggableCircleTooltip.append('path')
        .attr({
          'd': 'm19.617,20l6.155,0l-3.087,3.243l-3.068,-3.243zm-9.617,0a10,10 0 0 1 -10,-10a10,10 0 0 1 10,-10l25,0a10,10 0 0 1 10,10a10,10 0 0 1 -10,10l-25,0z',
          // 'd': 'm13.29495,19.99899a10.006,10.006 0 0 1 -9.663,-7.41l-3.632,-2.673l3.675,-2.659a10.005,10.005 0 0 1 9.62,-7.257l21.707,0a10,10 0 0 1 10,10a10,10 0 0 1 -10,10l-21.707,-0.001z',
          'fill': '#c36b89',
        });

      draggableCircleTooltip
        .append('text')
        .text((d) => `$ ${d.close + 15}`)
        .attr({
          'fill': '#fff',
          'x': 24,
          'y': 0,
          'dy': '1.1em',
          'text-anchor': 'middle',
          'font-size': '14'
        });

      createImageUsingData(lineGraphWrapper, {
        id: 'hand-cursor',
        class: 'hand-cursor',
        height: 70,
        width: 70,
        src: 'assets/images/adaptive-pricing/hand_cursor.svg',
        x: -5,
        y: y(data[0].close),
        o: 0,
        textStyle: {
          display: 'none',
          // cursor: 'pointer'
        }
      });

      // lineGraphWrapper.select('.hand-cursor')
      //   .on('click', function (d) {
      //     this.style.display = 'none';
      //     d.isAnimating = false;
      //     lineGraphWrapper.select('.hand-cursor')
      //       .attr({
      //         'tranform': 'translate(0,0)'
      //       })
      //       .interrupt();
      //   })

      currentProcess.append('g')
        .attr({
          'class': 'line-y-value-currency',
          'id': 'line-y-value-currency',
          'opacity': 0
        })
        .style({
          'transform': `translate(${lineGraphTranslate.x}px,${lineGraphTranslate.y + height}px)`
        })
        .append('text')
        .attr({
          'x': 0,
          'y': 0,
          'dy': '0em',
          'font-size': '16',
          'text-anchor': 'end'
        })
        .style({
          'transform': `translate(-10px, 30px)`,
          'font-weight': '500'
        })
        .text('$');

      const segmentWTPWrapper = currentProcess.append('g')
        .attr({
          'class': 'segment-wtp-wrapper',
          'id': 'segment-wtp-wrapper',
          'opacity': 0,
          'transform': `translate(1549, ${lineGraphTranslate.y + height + 6 - 10})`
        });

      const segmentWTPBlock = segmentWTPWrapper.append('g')
        .attr({
          'class': 'segment-wtp-block',
          'id': 'segment-wtp-block'
        })
        .style({
          'transform': `translate( 0px, ${10}px)`
        });

      segmentWTPBlock.append('rect')
        .attr({
          'x': 0,
          'y': 0,
          'width': 150,
          'height': 76,
          'rx': 40,
          'fill': '#7e5f69'
        });

      segmentWTPBlock.append('text')
        .attr({
          'fill': '#FFF',
          'x': 75,
          'y': 25,
          'dy': '0em',
          'text-anchor': 'middle',
          'font-size': '12'
        })
        .style({
          'font-weight': '400'
        })
        .text('SEGMENT\'s WTP');

      segmentWTPBlock.append('text')
        .attr({
          'fill': '#FFF',
          'x': 50,
          'y': 45,
          'dy': '0em',
          'text-anchor': 'middle',
          'font-size': '12'
        })
        .style({
          'font-weight': '400'
        })
        .text('From');

      const segmentValueText = segmentWTPBlock.append('text')
        .attr({
          'class': 'segment-wtp-value',
          'id': 'segment-wtp-value',
          'fill': '#fff',
          'x': 93,
          'y': 25,
          'dy': '1.2em',
          'text-anchor': 'middle',
          'font-size': '22'
        })
        .style({
          'font-weight': '500',
          // 'letter-spacing': '1px'
        })
        .text(`$${data.reduce((total, obj) => total + obj.close, 0)}`);

      segmentWTPBlock.append('text')
        .attr({
          'class': 'segment-wtp-value',
          'id': 'segment-wtp-value',
          'fill': '#fff',
          'x': 99,
          'y': 34,
          'dy': '2.7em',
          'text-anchor': 'middle',
          'font-size': '12'
        })
        .text(`/night`);

      const guestWTPWrapper = currentProcess.append('g')
        .attr({
          'class': 'guest-wtp-wrapper',
          'id': 'guest-wtp-wrapper',
          'opacity': 0,
          'transform': `translate(1335, ${lineGraphTranslate.y + height + 6 - 10})`
        });

      const guestWTPBlock = guestWTPWrapper.append('g')
        .attr({
          'class': 'guest-wtp-block',
          'id': 'guest-wtp-block'
        })
        .style({
          'transform': `translate( 0px, ${10}px)`
        });

      guestWTPBlock.append('rect')
        .attr({
          'x': 0,
          'y': 0,
          'width': 150,
          'height': 76,
          'rx': 40,
          'fill': '#c94573'
        });

      guestWTPBlock.append('text')
        .attr({
          'fill': '#fff',
          'x': 75,
          'y': 25,
          'dy': '0em',
          'text-anchor': 'middle',
          'font-size': '14'
        })
        .style({
          'font-weight': '400'
        })
        .text('GUEST\'s WTP');

      guestWTPBlock.append('text')
        .attr({
          'fill': '#FFF',
          'x': 50,
          'y': 45,
          'dy': '0em',
          'text-anchor': 'middle',
          'font-size': '12'
        })
        .style({
          'font-weight': '400'
        })
        .text('From');

      const guestValueText = guestWTPBlock.append('text')
        .attr({
          'class': 'guest-wtp-value',
          'id': 'guest-wtp-value',
          'fill': '#fff',
          'x': 93,
          'y': 25,
          'dy': '1.2em',
          'text-anchor': 'middle',
          'font-size': '22'
        })
        .style({
          'font-weight': '500',
          // 'letter-spacing': '1px'
        })
        .text(`$${data.reduce((total, obj) => total + obj.close + 15, 0)}`);


      guestWTPBlock.append('text')
        .attr({
          'class': 'guest-wtp-value',
          'id': 'guest-wtp-value',
          'fill': '#fff',
          'x': 99,
          'y': 34,
          'dy': '2.7em',
          'text-anchor': 'middle',
          'font-size': '12'
        })
        .text(`/night`);

      // const avgWTPPos = {
      //   x: 1136,
      //   y: 472
      // }
      // const avgWTPWrapper = currentProcess.append('g')
      //   .attr({
      //     'class': 'avg-wtp-wrapper',
      //     'id': 'avg-wtp-wrapper',
      //     'opacity': 0,
      //     'transform': `translate(${avgWTPPos.x + 10}, ${avgWTPPos.y})`
      //   })
      //   .style('opacity', 0);

      // avgWTPWrapper.append('rect')
      //   .attr({
      //     'x': 0,
      //     'y': 0,
      //     'width': 160,
      //     'height': 85,
      //     'rx': 45,
      //     'fill': '#65617d'
      //   });

      // const avgTitle = avgWTPWrapper.append('text')
      //   .attr({
      //     'fill': '#ffeae3',
      //     'x': 79,
      //     'y': 27,
      //     'dy': '0em',
      //     'text-anchor': 'middle',
      //     'font-size': '15'
      //   })
      //   .style({
      //     'letter-spacing': '1px'
      //   })

      // avgTitle.append('tspan')
      //   .text('AVERAGE');

      // avgTitle.append('tspan')
      //   .style('font-weight', '500')
      //   .text(' WTP');

      // const avgValueText = avgWTPWrapper.append('text')
      //   .attr({
      //     'class': 'avg-wtp-value',
      //     'id': 'avg-wtp-value',
      //     'fill': '#fff',
      //     'x': 79,
      //     'y': 33,
      //     'dy': '1.2em',
      //     'text-anchor': 'middle',
      //     'font-size': '22'
      //   })
      //   .style({
      //     'font-weight': '500',
      //     // 'letter-spacing': '1px'
      //   })
      //   .text(`$${data.reduce((total, obj) => total + obj.close + 15, 0)}`);


      // avgWTPWrapper.append('text')
      //   .attr({
      //     'class': 'avg-wtp-value',
      //     'id': 'avg-wtp-value',
      //     'fill': '#fff',
      //     'x': 90,
      //     'y': 42,
      //     'dy': '2.7em',
      //     'text-anchor': 'middle',
      //     'font-size': '12'
      //   })
      //   .text(`/night`);

      const individualSegmentRightData = {
        titleText: {
          wholeText: [
            {
              text: 'Individual & Segment ',
              textStyle: {
                'font-weight': 500
              }
            },
            {
              text: 'WTP'
            }
          ],
          text: 'Individual & Segment WTP',
          id: 'individual-segment-title',
          class: 'individual-segment-title screen-4-text',
          x: 320,
          y: 25,
          finalX: 320,
          finalY: 35,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 26,
          }
        },
        bottomText: {
          wholeText: [
            {
              text: 'Change '
            },
            {
              text: 'Guest’s attribute-level WTP',
              textStyle: {
                'font-weight': 500,
              }
            },
            {
              text: ' to know the'
            },
            {
              text: ' Aggregated WTP',
              textStyle: {
                'font-weight': 500,
              }
            },
          ],
          id: 'bottom-title-text-screen-4',
          class: 'bottom-title-text-screen-4 screen-4-text',
          x: 320,
          y: 145,
          dy: '0em',
          finalX: 320,
          finalY: 155,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 12,
            'fill': '#65617d'
          }
        },
        imageData: [{
          id: 'right-image-background-screen-4',
          width: 626,
          height: 137,
          src: 'assets/images/adaptive-pricing/white_box_with_shadow.svg',
          x: 285,
          y: 99,
          finalX: 0,
          finalY: 37,
          class: 'screen-4 right center background animate screen-4-images',
          s: 0
        },
        {
          id: 'right-building-screen-4',
          height: 34,
          width: 35,
          src: 'assets/images/adaptive-pricing/building.svg',
          x: 78,
          y: 88.5,
          finalX: 78,
          finalY: 88.5,
          class: 'screen-4 right icon building animate screen-4-images',
          o: 0
        },
        {
          id: 'right-brand-screen-4',
          height: 45,
          width: 32,
          src: 'assets/images/adaptive-pricing/brand_sm_grey.svg',
          x: 152,
          y: 82,
          finalX: 152,
          finalY: 82,
          class: 'screen-4 right icon brand animate screen-4-images',
          o: 0
        },
        {
          id: 'right-location-screen-4',
          height: 45,
          width: 40,
          src: 'assets/images/adaptive-pricing/location_sm_grey.svg',
          x: 218,
          y: 82,
          finalX: 218,
          finalY: 82,
          class: 'screen-4 right icon location animate screen-4-images',
          o: 0
        },
        {
          id: 'right-star-screen-4',
          height: 44,
          width: 28,
          src: 'assets/images/adaptive-pricing/star_sm_grey.svg',
          x: 297,
          y: 84,
          finalX: 297,
          finalY: 84,
          class: 'screen-4 right icon star animate screen-4-images',
          o: 0
        },
        {
          id: 'right-review-screen-4',
          height: 43,
          width: 38,
          src: 'assets/images/adaptive-pricing/review_sm_grey.svg',
          x: 365,
          y: 74,
          finalX: 365,
          finalY: 84,
          class: 'screen-4 right icon review animate screen-4-images',
          o: 0
        },
        {
          id: 'right-discount-screen-4',
          height: 45,
          width: 40,
          src: 'assets/images/adaptive-pricing/discount_sm_grey.svg',
          x: 435,
          y: 83,
          finalX: 435,
          finalY: 83,
          class: 'screen-4 right icon discount animate screen-4-images',
          o: 0
        },
        {
          id: 'right-discount-screen-4',
          height: 45,
          width: 40,
          src: 'assets/images/adaptive-pricing/bed_type_sm_grey.svg',
          x: 505,
          y: 74,
          finalX: 505,
          finalY: 84,
          class: 'screen-4 right icon discount animate screen-4-images',
          o: 0
        },
        {
          id: 'right-discount-screen-4',
          height: 4,
          width: 22,
          src: 'assets/images/adaptive-pricing/three_dots_grey.svg',
          x: 555,
          y: 104,
          finalX: 555,
          finalY: 104,
          class: 'screen-4 right icon discount animate screen-4-images',
          o: 0
        }
        ]
      };

      const individualSegment = currentProcess.append('g')
        .attr({
          'class': 'individual-segment-wrapper',
          'id': 'individual-segment-wrapper'
        })
        .style({
          'transform': `translate(${lineGraphTranslate.x - 96}px, 120px)`
        });

      createCenterBox(individualSegment, individualSegmentRightData);

      createTextUsingData(individualSegment, {
        wholeText: individualSegmentRightData.bottomText.wholeText
        , id: individualSegmentRightData.bottomText.id
        , x: individualSegmentRightData.bottomText.x
        , y: individualSegmentRightData.bottomText.y
        , finalX: individualSegmentRightData.bottomText.finalX
        , finalY: individualSegmentRightData.bottomText.finalY
        , dy: individualSegmentRightData.bottomText.dy
        , class: individualSegmentRightData.bottomText.class
        , textStyle: individualSegmentRightData.bottomText.textStyle
        , o: individualSegmentRightData.bottomText.o
      });

      function updateRightSection() {
        // // console.log('Inside updateRightSection: ', that.selectedTraveller);
        let updateGuestValue = 0;
        let updateSegmentValue = 0;
        that.pricingData.travellerDetails[that.selectedTraveller].attributes.hotel.forEach((hAttributes, index) => {
          const fixedGroup = fixedCircleWrapper.select(`.${hAttributes.id}`);

          fixedGroup.attr({
            'transform': (d) => {
              d.close = hAttributes.defaultValue;
              return `translate(${x(d.date)}, ${y(d.close)})`
            }
          });
          fixedGroup.select('.fixed-circle-tooltip text')
            .text(d => `${that.pricingData.currencyUnit} ${d.close}`);
          updateSegmentValue += hAttributes.defaultValue;

          const draggableGroup = draggableCircleWrapper.select(`.${hAttributes.id}`);
          const dragTooltipGroup = draggableGroup.select('.drag-circle-tooltip');

          draggableGroup.attr({
            'transform': (d) => {
              d.close = hAttributes.preferredValue;
              d.x = x(d.date);
              d.y = y(d.close);
              return `translate(${x(d.date)}, ${y(d.close)})`
            }
          });

          if (index == 0) {
            lineGraphWrapper.select('.hand-cursor')
              .attr({
                'transform': (d) => {
                  d.y = y(hAttributes.preferredValue)
                  return `translate(${d.x}, ${d.y - 30})`
                }
              });
          }
          manageDraggableTooltip(dragTooltipGroup, hAttributes.preferredValue);
          dragTooltipGroup.select('text')
            .text(d => `${that.pricingData.currencyUnit} ${d.close}`);

          updateGuestValue += hAttributes.preferredValue;
        });
        segmentValueText.text(`$${updateSegmentValue}`);
        guestValueText.text(`$${updateGuestValue}`);
        // const avgValue = `${that.pricingData.currencyUnit}${((updateGuestValue + updateSegmentValue) / 2).toFixed(0)}`;
        // avgValueText.text(avgValue);
      }

      function initiateAnimation() {

        showImage(stepGroup, 'second-step-image', 0, 300, function () {
          showText(stepGroup, 'second-step', 0, 200, function () {
            showText(stepGroup, 'second-step-bottom-text', 0, 200, function () {
              showText(middleSectionAttrProps, 'title-center', 0, 250)
              showImage(middleSectionAttrProps, 'rrm-block', 0, 250, () => {
                showImage(middleSectionAttrProps, 'brain-image', 0, 250, () => {
                  showText(middleSectionAttrProps, 'concept-title', 0, 200, () => {
                    showText(middleSectionAttrProps, 'concept-props-1', 0, 200)
                    showText(middleSectionAttrProps, 'concept-props-2', 200, 200, () => {
                      initiateRightAnimation();
                      operateLine(middleSectionModels, 'model-base-line', 'draw', 0, 300, () => {
                        showImage(middleSectionModels, 'bottom-background', 0, 200, () => {
                          showText(middleSectionModels, 'bottom-text', 100, 100, () => {

                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })
        })
      }

      function initiateRightAnimation() {
        showImage(rightContainer, 'right-bg', 0, 300, () => {
          showText(individualSegment, 'individual-segment-title', 0, 250, () => { })
          showText(individualSegment, 'bottom-title-text-screen-4', 0, 250, () => { })
          showImage(individualSegment, 'center'
            , 0, 250, () => {
              individualSegment.selectAll('.icon')
                .transition()
                .delay((d, i) => i * 80)
                .duration(150)
                .style('opacity', 1)
                .attr({
                  'transform': (d: any) => `translate(${d.finalX}, ${d.finalY})`,
                })
                .call(endAll, function () {

                  currentProcess.selectAll('.line-y-value-currency')
                    .transition()
                    .delay(0)
                    .duration(300)
                    .attr({
                      'opacity': 1
                    });
                  draggableCircleWrapper.selectAll(`.drag-circle-group`)
                    .attr({
                      'opacity': 0
                    });
                  lineGraphWrapper
                    .transition()
                    .delay(0)
                    .duration(300)
                    .style({
                      'opacity': 1
                    })
                    .call(endAll, function () {
                      animateDraggablePoints();

                      // avgWTPWrapper
                      //   .transition()
                      //   .delay(0)
                      //   .duration(500)
                      //   .attr({
                      //     'opacity': 1,
                      //     'transform': (d: any) => `translate(${avgWTPPos.x}, ${avgWTPPos.y})`,
                      //   });

                      segmentWTPWrapper
                        .transition()
                        .delay(0)
                        .duration(300)
                        .attr({
                          'opacity': 1,
                          'transform': (d: any) => `translate(1549, ${lineGraphTranslate.y + height + 6})`,
                        });

                      guestWTPWrapper
                        .transition()
                        .delay(0)
                        .duration(300)
                        .attr({
                          'opacity': 1,
                          'transform': (d: any) => `translate(1335, ${lineGraphTranslate.y + height + 6})`,
                        })
                        .call(endAll, function () {
                          initialAnimationEnd();
                        });
                    })
                })
            })
        })
      }

      function initiateEndAnimation() {
        hideText(middleSectionModels, 'bottom-text', 0, 10, () => {
          hideImage(middleSectionModels, 'bottom-background', 0, 10, () => {
            operateLine(middleSectionModels, 'model-base-line', 'hide', 0, 10, () => {
              hideText(middleSectionAttrProps, 'concept-props-2', 0, 10)
              hideText(middleSectionAttrProps, 'concept-props-1', 0, 10, () => {
                hideText(middleSectionAttrProps, 'concept-title', 0, 10, () => {
                  hideImage(middleSectionAttrProps, 'brain-image', 0, 10, () => {
                    hideText(middleSectionAttrProps, 'title-center', 0, 10);
                    hideImage(middleSectionAttrProps, 'rrm-block', 0, 10);
                    hideImage(stepGroup, 'second-step-image', 0, 10, function () {
                      hideText(stepGroup, 'second-step', 0, 10, function () {
                        hideText(stepGroup, 'second-step-bottom-text', 0, 10, function () {
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      }

      function initiateEndRightAnimation() {

        // avgWTPWrapper
        //   .transition()
        //   .delay(0)
        //   .duration(100)
        //   .attr({
        //     'opacity': 0,
        //     'transform': (d: any) => `translate(${avgWTPPos.x + 10}, ${avgWTPPos.y})`,
        //   })

        segmentWTPWrapper
          .transition()
          .delay(0)
          .duration(10)
          .attr({
            'opacity': 0,
            'transform': (d: any) => `translate(1549, ${lineGraphTranslate.y + height + 6 - 10})`,
          });

        guestWTPWrapper
          .transition()
          .delay(0)
          .duration(10)
          .attr({
            'opacity': 0,
            'transform': (d: any) => `translate(1335, ${lineGraphTranslate.y + height + 6 - 10})`,
          })
          .call(endAll, function () {
            currentProcess.selectAll('.line-y-value-currency')
              .transition()
              .delay(0)
              .duration(10)
              .attr({
                'opacity': 0
              })
            lineGraphWrapper
              .transition()
              .delay(0)
              .duration(10)
              .style({
                'opacity': 0
              })
              .call(endAll, function () {
                const selectIcons = individualSegment.selectAll('.icon')
                selectIcons[0].reverse();
                selectIcons
                  .transition()
                  .delay((d, i) => i * 10)
                  .duration(10)
                  .style('opacity', 0)
                  .attr({
                    'transform': (d: any) => `translate(${d.x}, ${d.y})`,
                  })
                  .call(endAll, function () {
                    hideImage(individualSegment, 'center', 0, 10, () => { })
                    hideText(individualSegment, 'individual-segment-title', 0, 10, () => { })
                    hideText(individualSegment, 'bottom-title-text-screen-4', 0, 10, () => {
                      hideImage(rightContainer, 'right-bg', 0, 10, () => {
                        initiateEndAnimation();
                      })
                    })
                  })
              })
          })
      }

      function endAnimation() {
        initiateEndAnimation();
      }

      function resetElements() {
        // console.log('=================Inside screen4 hideElements function');
        operateLine(currentProcess, 'screen-4-lines', 'hide', 0, 0);
        hideImage(currentProcess, 'screen-4-images', 0, 0);
        hideText(currentProcess, 'screen-4-text', 0, 0);
        hideImage(rightContainer, 'right-bg', 0, 0);
        currentProcess.selectAll('.line-y-value-currency')
          .transition()
          .duration(0)
          .attr('opacity', 0);
        lineGraphWrapper.style('opacity', 0);
        // avgWTPWrapper.attr('opacity', 0);
        segmentWTPWrapper.attr('opacity', 0);
        guestWTPWrapper.attr('opacity', 0);
      }

      function initialAnimationEnd() {
        that.onAnimationEnd();
      }

      function animateHandCursor(index = true) {
        lineGraphWrapper.select('.hand-cursor')
          .style({
            'opacity': d => {
              d.isAnimating = true;
              return 1
            },
            'display': 'block'
          })
          .transition()
          .duration(350)
          .ease('linear')
          .attr({
            'transform': d => {
              const offset = index ? 4 : -4;
              return `translate(${d.x}, ${d.y - 30 + offset}) scale(1)`
            }
          })
          .call(endAll, function (d, i) {
            if (d.isAnimating) {
              animateHandCursor(!index);
            } else {
              this.style.display = 'none';
            }
          });
      }

      function animateDraggablePoints(cb = function () { animateHandCursor() }) {
        // draggableCircleWrapper.selectAll(`.drag-circle-group`)
        //   .attr('opacity', d => {
        //     d.isDraggable = false;
        //     return 1
        //   })
        //   .transition()
        //   .delay(50)
        //   .duration(180)
        //   .ease('linear')
        //   .attr('transform', (d) => `translate(${x(d.date)}, ${y(d.close + d.dragValues[index])})`)
        //   .each('end', function (d) {
        //     if (index == 4)
        //       d.isDraggable = true;
        //   })
        //   .transition()
        //   .duration(0)
        //   .call(endAll, function (d, i) {
        //     index++;
        //     if (index < 4) {
        //       if (index % 2 == 0)
        //         animateDraggablePoints(index);
        //       else
        //         animateDraggablePoints(index);
        //     } else if (index == 4) {
        //       animateDraggablePoints(index);
        //     } else if (index > 4) {
        //       cb();
        //     }
        //   });

        draggableCircleWrapper.selectAll(`.drag-circle-group`)
          .attr({
            'transform': (d) => {
              d.isDraggable = false;
              return `translate(${x(d.date)}, ${y(d.close - 10)})`
            }
          })
          .transition()
          .delay(50)
          .duration(500)
          .ease('linear')
          .attr({
            'opacity': '1',
            'transform': (d) => `translate(${x(d.date)}, ${y(d.close)})`
          })
          .each('end', function (d) {
            d.isDraggable = true;
          })
          .transition()
          .duration(100)
          .call(endAll, function (d, i) {
            cb();
          });
      }

      return {
        startAnimation(isFirst = false) {
          // console.log('screen-4 startAnimation');
          // that.isAnimation = true;
          // baseImage = '';
          // baseImage = '/assets/images/adaptive-pricing/4.png';
          // image.attr('xlink:href', baseImage);
          commonLeftSection.resetClass('expand');
          travellersWrapper.select(`.${that.selectedTraveller} .title-underline`)
            .attr({
              'stroke': 'url(#traveller-selected-lg)',
              'stroke-dashoffset': 0,
              'opacity': 1
            });
          that.restrictScrollAndClick();
          updateRightSection();
          currentProcess.style('opacity', 1);
          initiateAnimation();
          // setTimeout(function () {
          //   animateDraggablePoints()
          // }, 10000);
        },
        climaxAnimation() {
          // that.isAnimation = true;
          currentProcess.transition();
          that.restrictScrollAndClick();
          initiateEndRightAnimation()
          // endAnimation();
          // initiateEndAnimation()
        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess.style('opacity', 0);
          lineGraphWrapper.select('.hand-cursor')
            .style('opacity', d => {
              d.isAnimating = false;
              return '0'
            });
        },
        updateRightSection,
        resetElements() {
          resetElements();
        }
      }
    })();

    const screenFive = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-5-group')
        .attr('class', 'screen-5-group')
        .style('opacity', 0);

      const travelLeftLineGroup = currentProcess.append('g')
        .attr('class', 'travel-lines-screen-5');

      const travelLeftWTPGroup = currentProcess.append('g')
        .attr('class', 'traveller-wtp-screen-5');

      const stepData = {
        bottomText: {
          wholeText: [
            {
              text: 'WTP based rank-optimized hotel alternatives',
              dy: '0em',
              y: 0,
              x: 0
            },
            {
              text: ' to distribute tailored recommendations and offers',
              dy: '1.2em',
              y: 0,
              x: 0,
            }
          ],
          id: 'screen-5-step-bottom-text',
          class: 'screen-5 third-step-bottom-text screen-5-text',
          x: 818,
          y: 243,
          dy: '0em',
          finalX: 818,
          finalY: 253,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 18,
            'font-weight': 400,
            'fill': '#C94573'
          }
        },
        mainData: {
          id: 'screen-5-step-2',
          class: 'third-step-image step-background screen-5-images',
          x: 818,
          y: 176,
          finalX: 608,
          finalY: 116,
          width: 420,
          height: 160,
          src: '/assets/images/adaptive-pricing/step-bg.svg',
          o: 1,
          s: 0,
          text: {
            wholeText: [

              {
                text: 'Step 3:',
                dy: '0em',
                y: 0,
                x: 0,
                textStyle: {
                  'fill': '#C94573'
                }
              },
              {
                text: 'WTP Application',
                dy: '0em',
                y: 0,
                x: 66
              }
            ],
            id: 'screen-5-step-text',
            class: 'screen-5 third-step screen-5-text',
            x: 709,
            y: 193,
            dy: '0em',
            finalX: 709,
            finalY: 203,
            o: 0,
            textStyle: {
              // 'text-anchor': 'middle',
              'font-size': 20,
              'font-weight': 500,
              'fill': '#3f3d56'
            }
          }
        }
      };

      const travelLeftLines = [{
        line: [[388, 325], [408, 325], [451, 617], [473, 617]],
        type: 'solo'
      }, {
        line: [[388, 487], [406, 487], [451, 617], [473, 617]],
        type: 'business'
      }, {
        line: [[388, 673], [410, 673], [451, 617], [473, 617]],
        type: 'couple'
      }, {
        line: [[388, 851], [406, 851], [451, 617], [473, 617]],
        type: 'group'
      }]

      travelLeftLines.forEach((data, index) => {
        const line = addDashedLine(travelLeftLineGroup, data.line, `screen-5 screen-5-lines travel-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      });

      const centerLeftLineGroup = currentProcess.append('g')
        .attr('class', 'center-left-lines-screen-5');

      const centerLeftLines = [{
        line: [[473, 617], [473, 497], [514, 472]],
        type: 'top'
      }, {
        line: [[473, 617], [473, 742], [514, 762]],
        type: 'bottom'
      }]

      centerLeftLines.forEach((data, index) => {
        const line = addDashedLine(centerLeftLineGroup, data.line, `screen-5 screen-5-lines center-left-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      })

      const middleSection = currentProcess.append('g')
        .attr('transform', 'translate(466, 390)');

      const stepGroup = currentProcess.append('g')
        .attr({
          'class': 'screen-5-step-group',
          'id': 'screen-5-step-group',
          'transform': 'translate(0, 0)'
        });

      createImageUsingData(stepGroup, { ...stepData.mainData });

      createTextUsingData(stepGroup, { ...stepData.mainData.text });
      createTextUsingData(stepGroup, { ...stepData.bottomText });

      createTextUsingData(currentProcess, {
        wholeText: [{
          text: 'Attribute level WTP',
          textStyle: {
            'font-weight': 500,
          }
        }],
        id: 'screen-other-title-text-screen-5',
        class: 'screen-other-title-text-screen-5 screen-5-text',
        x: 824,
        y: 333,
        dy: '0em',
        finalX: 824,
        finalY: 343,
        o: 0,
        textStyle: {
          'text-anchor': 'middle',
          'font-size': 20,
          'fill': '#3f3d56'
        }
      });

      const screenFiveCenterData = {
        titleText: {
          wholeText: [
            {
              text: 'Hotel ',
              textStyle: {
                'font-weight': 500
              }
            },
            {
              text: 'Attributes'
            }
          ],
          text: 'Hotel Attributes',
          id: 'screen-title-text-screen-5',
          class: 'screen-title-text-screen-5 screen-5-text',
          x: 358,
          y: 4,
          finalX: 358,
          finalY: 14,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 20.5,
            'fill': '#65617d'
          }
        },
        footerText: {
          wholeText: [{
            text: 'Attribute level',
            textStyle: {
              'font-weight': 500,
            }
          },
          {
            text: ' WTP'
          }],
          id: 'footer-title-text-screen-5',
          class: 'footer-title-text-screen-5 screen-5-text',
          x: 353,
          y: 233,
          dy: '0em',
          finalX: 353,
          finalY: 243,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 20,
            'letter-spacing': 0.1,
            'fill': '#65617d'
          }
        },
        imageData: [{
          id: 'center-image-background-screen-5',
          width: 720,
          height: 280,
          src: 'assets/images/adaptive-pricing/double_box.svg',
          finalX: 0,
          finalY: 0,
          x: 353,
          y: 120,
          s: 0,
          class: 'screen-5 center background animate screen-5-images'
        }, {
          id: 'center-brand-screen-5',
          height: 65,
          width: 44,
          src: 'assets/images/adaptive-pricing/brand_red.svg',
          x: 121,
          y: 40,
          finalX: 121,
          finalY: 50,
          o: 0,
          class: 'screen-5 center icon brand animate screen-5-images'
        }, {
          id: 'center-location-screen-5',
          height: 65,
          width: 56,
          src: 'assets/images/adaptive-pricing/location_red.svg',
          x: 224,
          y: 40,
          finalX: 224,
          finalY: 50,
          o: 0,
          class: 'screen-5 center icon location animate screen-5-images'
        }, {
          id: 'center-star-screen-5',
          height: 64,
          width: 37,
          src: 'assets/images/adaptive-pricing/star_red.svg',
          x: 340,
          y: 42,
          finalX: 340,
          finalY: 52,
          o: 0,
          class: 'screen-5 center icon star animate screen-5-images'
        }, {
          id: 'center-review-screen-5',
          height: 62,
          width: 52,
          src: 'assets/images/adaptive-pricing/review_red.svg',
          x: 442,
          y: 43,
          finalX: 442,
          finalY: 53,
          o: 0,
          class: 'screen-5 center icon review animate screen-5-images'
        }, {
          id: 'center-discount-screen-5',
          height: 64,
          width: 56,
          src: 'assets/images/adaptive-pricing/discount_red.svg',
          x: 552,
          y: 42,
          finalX: 552,
          finalY: 52,
          o: 0,
          class: 'screen-5 center icon discount screen-5-images'
        }],
        connectors: [
          {
            x1: 142,
            x2: 142,
            y1: 125,
            y2: 142,
            class: 'screen-5 connector animate',
            stroke: '#b79e8f'
          },
          {
            x1: 252,
            x2: 252,
            y1: 125,
            y2: 142,
            class: 'screen-5 connector animate',
            stroke: '#b79e8f'
          },
          {
            x1: 360,
            x2: 360,
            y1: 125,
            y2: 142,
            class: 'screen-5 connector animate',
            stroke: '#b79e8f'
          },
          {
            x1: 468,
            x2: 468,
            y1: 125,
            y2: 142,
            class: 'screen-5 connector animate',
            stroke: '#b79e8f'
          },
          {
            x1: 580,
            x2: 580,
            y1: 125,
            y2: 142,
            class: 'screen-5 connector animate',
            stroke: '#b79e8f'
          },
        ],
      };

      createCenterBox(middleSection, screenFiveCenterData);

      const legendData = {
        textsContent: [
          {
            wholeText: [
              {
                text: 'Increase',
                textStyle: {
                  'font-weight': 500
                }
              }
            ],
            text: 'Increase',
            id: 'increase-indicator-text-screen-5',
            class: 'increase-indicator-text-screen-5',
            x: 160,
            y: 223,
            dy: '0em',
            finalX: 160,
            finalY: 223,
            o: 1,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#10919f'
            }
          },
          {
            wholeText: [
              {
                text: 'Decrease',
                textStyle: {
                  'font-weight': 500
                }
              }
            ],
            text: 'Decrease',
            id: 'decrease-indicator-text-screen-5',
            class: 'decrease-indicator-text-screen-5',
            x: 242,
            y: 223,
            dy: '0em',
            finalX: 242,
            finalY: 223,
            o: 1,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#a2487b'
            }
          },
          {
            wholeText: [
              {
                text: '( Variation of WTP from segment level based',
                dy: '0em',
                x: 0,
                y: 0,
              },
              {
                text: 'on guest’s individual heterogeneity )',
                dy: '1.1em',
                x: 30,
                y: 0,
              }
            ],
            text: '( Variation of WTP from segment level based on guest’s individual heterogeneity )',
            id: 'price-variation-text-screen-5',
            class: 'price-variation-text-screen-5',
            x: 322,
            y: 216,
            dy: '0em',
            finalX: 322,
            finalY: 216,
            o: 1,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#65617d'
            }
          },
        ],
        imageData: [
          {
            id: 'increase-indicator-screen-5',
            height: 16,
            width: 6,
            src: 'assets/images/adaptive-pricing/green_up_arrow.svg',
            x: 147,
            y: 210.5,
            finalX: 147,
            finalY: 210.5,
            o: 1,
            class: 'screen-5 indicator increase-indicator'
          },
          {
            id: 'decrease-indicator-screen-5',
            height: 16,
            width: 6,
            src: 'assets/images/adaptive-pricing/red_down_arrow.svg',
            x: 229,
            y: 210.5,
            finalX: 229,
            finalY: 210.5,
            o: 1,
            class: 'screen-5 indicator decrease-indicator'
          }
        ]
      };

      const legendSection = middleSection.append('g')
        .attr({
          'class': 'wtp-legend-section',
          'id': 'wtp-legend-section',
        })
        .style('opacity', 0);

      legendData.textsContent.forEach((textContent, index) => {
        createTextUsingData(legendSection, textContent);
      });

      legendData.imageData.forEach((image, index) => {
        createImageUsingData(legendSection, image);
      });

      const middleBottomSection = currentProcess.append('g')
        .attr('transform', 'translate(488, 667)');

      const screenFiveBottomData = {
        titleText: {
          wholeText: [{
            text: 'In-room',
            textStyle: {
              'font-weight': 500,
            }
          }, {
            text: ' Attributes',
            textStyle: {
            }
          }],
          id: 'screen-title-bottom-text-screen-5',
          class: 'screen-title-bottom-text-screen-5 screen-5-images',
          x: 336,
          y: 4,
          finalX: 336,
          finalY: 14,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 20.5,
            'fill': '#3f3d56'
          }
        },
        footerText: {
          wholeText: [{
            text: '(In-room Attributes are also considered in WTP)',
            // textStyle: {
            //   'font-weight': 500,
            // }
          }
            //   ,
            // {
            //   text: ' WTP'
            // }
          ],
          id: 'footer-title-bottom-text-screen-5',
          class: 'footer-title-bottom-text-screen-5 screen-5-text',
          x: 336,
          y: 151,
          dy: '0em',
          finalX: 336,
          finalY: 161,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 14,
            'letter-spacing': 0.1,
            'fill': '#65617d'
          }
        },
        imageData: [
          {
            id: 'center-image-background-bottom-screen-5',
            width: 672,
            height: 172,
            src: 'assets/images/adaptive-pricing/box.svg',
            x: 353,
            y: 86,
            finalX: 0,
            finalY: 0,
            class: 'screen-5 center background animate screen-5-images',
            s: 0
          },
          {
            id: 'center-bed-screen-5',
            height: 60,
            width: 66,
            src: 'assets/images/adaptive-pricing/bed_grey.svg',
            x: 84,
            y: 50,
            finalX: 84,
            finalY: 60,
            class: 'screen-5 center icon bed animate screen-5-images',
            o: 0
          },
          {
            id: 'center-wifi-screen-5',
            height: 62,
            width: 96,
            src: 'assets/images/adaptive-pricing/wifi_grey.svg',
            x: 191,
            y: 48,
            finalX: 191,
            finalY: 58,
            class: 'screen-5 center icon wifi animate screen-5-images',
            o: 0
          },
          {
            id: 'center-bar-screen-5',
            height: 60,
            width: 63,
            src: 'assets/images/adaptive-pricing/bar_grey.svg',
            x: 328,
            y: 46,
            finalX: 328,
            finalY: 56,
            class: 'screen-5 center icon bar animate screen-5-images',
            o: 0
          },
          {
            id: 'center-balcony-screen-5',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/balcony_grey.svg',
            x: 431,
            y: 48,
            finalX: 431,
            finalY: 58,
            class: 'screen-5 center icon balcony animate screen-5-images',
            o: 0
          },
          {
            id: 'center-kitchen-screen-5',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/kitchen_grey.svg',
            x: 524,
            y: 47,
            finalX: 524,
            finalY: 57,
            class: 'screen-5 center icon kitchen animate screen-5-images',
            o: 0
          },
        ]
      };

      createCenterBox(middleBottomSection, screenFiveBottomData);

      const confValuesGroup = middleSection.append('g');

      screenFiveCenterData.connectors.forEach((connector, index) => {
        const group = confValuesGroup.append('g')
          .attr({
            'class': `values-group ${that.pricingData.travellerDetails.solo.attributes.hotel[index].id}`,
            'id': `values-group-${index}`
          });

        const dvCalculateGroup = group.append('g')
          .attr({
            'class': 'dv-calculate-group',
            'id': `dv-calculate-group-${index}`,
          });

        group.append('line')
          .attr({
            'x1': connector.x1,
            'x2': connector.x2,
            'y1': connector.y1,
            'y2': connector.y2,
            'class': `${connector.class}`,
            'id': `${connector.class || `line-${index}`}`,
            'stroke': connector.stroke || '#65617d',
          })
          .style({
            'opacity': 0
          });

        dvCalculateGroup
          .datum(connector)
          .attr({
            'opacity': 0
          });

        group.append('text')
          .datum(connector)
          .attr({
            'class': `selected-values new-wtp-value animate`,
            'id': `selected-values-${index}`,
            'x': connector.x1 - 2,
            'y': connector.y2 + 37,
            'dy': '0em',
            'fill': '#65617d',
            'font-size': '22',
            'text-anchor': 'end',
            'opacity': 0
          })
          .style({
            'font-weight': '500'
          })
          .text(`$70`);

        createImageUsingData(dvCalculateGroup, {
          'id': `dv-indicator-${index}`,
          'class': `dv-indicator screen-5-images-2`,
          'height': 16,
          'width': 6,
          'src': 'assets/images/adaptive-pricing/green_up_arrow.svg',
          // 'src': 'assets/images/adaptive-pricing/red_down_arrow.svg',
          'x': connector.x1 + 2,
          'y': connector.y2 + 10,
        });

        dvCalculateGroup.append('text')
          .datum(connector)
          .attr({
            'class': `dv-values-deduct animate`,
            'id': `dv-values-deduct-${index}`,
            'x': connector.x1 + 10 + 2,
            'y': connector.y2 + 10 + 14,
            'dy': '0em',
            'fill': '#10919f',
            'font-size': '14',
            'text-anchor': 'start'
          })
          .style({
            'font-weight': '500'
          })
          .text(`$10`);
      });

      createTextUsingData(middleBottomSection, { ...screenFiveBottomData.footerText });

      const travelRightLineGroup = currentProcess.append('g')
        .attr('class', 'right-lines-screen-5');

      const travelRightLines = [{
        line: [[1135, 473], [1177, 495], [1177, 616]],
        type: 'top'
      }, {
        line: [[1135, 758], [1177, 732], [1177, 616]],
        type: 'bottom'
      }, {
        line: [[1178, 617], [1204, 615], [1231, 313], [1264, 312]],
        type: 'right'
      }];

      travelRightLines.forEach((data, index) => {
        const line = addDashedLine(travelRightLineGroup, data.line, `screen-5 screen-5-lines ${data.type} ${data.type}-line`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      });

      function updateMiddleSection() {
        // // console.log('screen-5 Inside updateMiddleSection');
        // drawLine(travelLeftLineGroup);
        that.pricingData.travellerDetails[that.selectedTraveller].attributes.hotel.forEach((hAttributes, index) => {
          const confGroup = confValuesGroup.select(`.${hAttributes.id}`);
          const dvCalGroup = confGroup.select(`.dv-calculate-group`);

          const dvCalculation = hAttributes.preferredValue - hAttributes.defaultValue;
          let dvColor = '#10919f';
          let dvText = `${that.pricingData.currencyUnit}${Math.abs(dvCalculation)}`;
          let dvSrc = 'assets/images/adaptive-pricing/green_up_arrow.svg';

          confGroup.select('.selected-values')
            .text(`${that.pricingData.currencyUnit}${hAttributes.preferredValue}`);

          if (dvCalculation < 0) {
            dvSrc = 'assets/images/adaptive-pricing/red_down_arrow.svg';
            dvColor = '#a2487b';
          } else if (dvCalculation == 0) {
            dvSrc = '';
            dvText = '';
          } else {
            dvSrc = 'assets/images/adaptive-pricing/green_up_arrow.svg';
          }

          dvCalGroup
            .each(d => {
              d.preferredValue = hAttributes.preferredValue;
              d.dvCalculation = dvCalculation;
              d.defaultValue = hAttributes.defaultValue;
            });

          dvCalGroup.select('.dv-indicator')
            .attr({
              'xlink:href': dvSrc,
            });

          dvCalGroup.select('.dv-values-deduct')
            .text(dvText)
            .attr('fill', dvColor)
        });
      }

      function initStepAnimation(cb = function () { }) {
        showImage(stepGroup, 'third-step-image', 0, 300, function () {
          showText(stepGroup, 'third-step', 0, 200, function () {
            showText(stepGroup, 'third-step-bottom-text', 0, 200, function () {
              cb();
            })
          })
        })
      }

      function setUpdatedValue() {
        //add class(card-active) to right section
        that.addClassToDom('screen-five-right-section', that.htmlClassVariable.card);
        that.addClassToDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
        updateGuestSegmentWTPValue();
        commonLeftSection.resetClass('expand');
        commonLeftSection.addClass('expand');
        travellersWrapper.select(`.${that.selectedTraveller} .title-underline`)
          .transition()
          .duration(80)
          .attr({
            'stroke': 'url(#traveller-selected-lg)',
            'stroke-dashoffset': 0,
            'opacity': 0
          });
        animateLeftToRightSection(function () { initialAnimationEnded() });
      }

      function initiateAnimation(group, className, bottomTextClassName, cb = function () { }) {
        showText(group, className, 0, 500, () => { })
        showText(group, bottomTextClassName, 0, 500, () => { })
        showImage(group, 'center'
          , 0, 500, () => {
            group.selectAll('.icon')
              .transition()
              .delay((d, i) => i * 80)
              .duration(100)
              .style('opacity', 1)
              .attr({
                'transform': (d: any) => `translate(${d.finalX}, ${d.finalY})`,
              })
              .call(endAll, function (d, i) {
                group.selectAll('.connector')
                  .transition()
                  // .delay((d, i) => 80)
                  .duration(100)
                  .style({
                    'opacity': 1,
                  })
                  .call(endAll, function (d, i) {
                    // TODO
                    cb();
                  })
              })
          })
      }

      function updateGuestSegmentWTPValue() {
        let attributes = that.pricingData.travellerDetails[that.selectedTraveller].attributes.hotel;
        let totalValue = 0;
        attributes.forEach((attribute, index) => {
          totalValue += attribute.preferredValue;
        })
        that.guestSegmentWTP = totalValue;
        // console.log('guestSegmentWTP: ', that.guestSegmentWTP);
        that.updateHotelPrice();
        commonLeftSection.updateGuestSegmentWTPValueToDOM();
      }

      function initialAnimationEnded() {
        // console.log('All attributes have been visible');
        // TODO
        //add class(list-active) be added from here
        that.removeClassFromDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
        that.addClassToDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelList);
        that.onAnimationEnd();
      }

      function initiatEndAnimation(cb = function () { }) {
        legendSection
          .transition()
          .delay(10)
          .style({
            'opacity': 0
          });
        middleSection.selectAll('.dv-calculate-group')
          .attr({
            'opacity': 1,
            'transform': (d: any) => `translate(${0}, ${0})`
          })
          .transition()
          .delay((d, i) => 10)
          .duration(10)
          .attr({
            'opacity': 0,
            'transform': (d: any) => `translate(${0}, ${d.dvCalculation < 0 ? -10 : 10})`
          })
          .call(endAll, function (d, i) {
            const newWtpValue = middleSection.selectAll('.new-wtp-value')
            newWtpValue[0].reverse();
            newWtpValue.attr({
              'opacity': 1,
              'transform': (d: any) => `translate(${0}, ${0})`
            })
              .transition()
              .delay((d, i) => i * 10)
              .duration(10)
              .attr({
                'opacity': 0,
                'transform': (d: any) => `translate(${-15}, ${0})`
              })
              .call(endAll, function (d, i) {
                const selectConnector = middleSection.selectAll('.connector')
                selectConnector[0].reverse();
                selectConnector
                  .transition()
                  .delay((d, i) => i * 10)
                  .duration(10)
                  .style({
                    'opacity': 0,
                  })
                  .call(endAll, function (d, i) {
                    const selectIcon = middleSection.selectAll('.icon')
                    selectIcon[0].reverse()
                    selectIcon
                      .transition()
                      .delay((d, i) => i * 10)
                      .duration(10)
                      .style('opacity', 0)
                      .attr({
                        'transform': (d: any) => `translate(${d.x}, ${d.y})`,
                      })
                      .call(endAll, function (d, i) {
                        hideText(middleSection, 'screen-title-text-screen-5', 0, 10);
                        hideText(middleSection, 'footer-title-text-screen-5', 0, 10);
                        hideImage(middleSection, 'center', 0, 10);
                        hideText(currentProcess, 'screen-other-title-text-screen-5', 0, 10);
                        hideText(stepGroup, 'third-step-bottom-text', 0, 10);
                        hideText(stepGroup, 'third-step', 0, 10, function () {
                          hideImage(stepGroup, 'third-step-image', 0, 10, cb);
                        })
                      })
                  })
              })
          })
      }

      function endBottomAnimation() {
        const selectIcon = middleBottomSection.selectAll('.icon')
        selectIcon[0].reverse()
        selectIcon
          .transition()
          .delay((d, i) => i * 10)
          .duration(10)
          .style('opacity', 0)
          .attr({
            'transform': (d: any) => `translate(${d.x}, ${d.y})`,
          })
          .call(endAll, function (d, i) {
            hideText(middleBottomSection, 'screen-title-bottom-text-screen-5', 0, 10, () => { });
            hideText(middleBottomSection, 'footer-title-bottom-text-screen-5', 0, 10, () => { });
            hideImage(middleBottomSection, 'center', 0, 10);
            // hideText(stepGroup, 'third-step-bottom-text', 0, 100)
            // hideText(stepGroup, 'third-step', 0, 100, function () {
            //   hideImage(stepGroup, 'third-step-image', 0, 100);
            // })
          })
      }

      function endAnimation() {
        //remove class(card-active) from right section
        that.removeClassFromDom('screen-five-right-section', that.htmlClassVariable.card);
        operateLine(travelRightLineGroup, 'right-line', 'hide', 0, 10, function () {
          operateLine(travelRightLineGroup, 'bottom-line', 'hide', 0, 10)
          operateLine(travelRightLineGroup, 'top-line', 'hide', 0, 10, function () {
            endBottomAnimation();
            initiatEndAnimation(function () {
              operateLine(centerLeftLineGroup, 'top', 'hide', 0, 10, function () {
                operateLine(travelLeftLineGroup, that.selectedTraveller, 'hide', 0, 10, function () {
                  commonLeftSection.resetClass('expand');
                });
              })
              operateLine(centerLeftLineGroup, 'bottom', 'hide', 0, 10)
            });
          })
        });
      }

      function updateTravellerPreference() {
        //remove class(list-active) from right section
        that.removeClassFromDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelList);
        that.addClassToDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);

        operateLine(travelRightLineGroup, 'right-line', 'hide', 0, 200, function () {
          operateLine(travelRightLineGroup, 'bottom-line', 'hide', 0, 200)
          operateLine(travelRightLineGroup, 'top-line', 'hide', 0, 200, function () {
            middleBottomSection.selectAll('.icon')
              .transition()
              .duration(5)
              .attr({
                'xlink:href': (d: any) => d.src
              });
            middleSection.selectAll('.dv-calculate-group')
              .attr({
                'opacity': 1,
                'transform': (d: any) => `translate(${0}, ${0})`
              })
              .transition()
              .delay((d, i) => 80)
              .duration(100)
              .attr({
                'opacity': 0,
                'transform': (d: any) => `translate(${0}, ${d.dvCalculation < 0 ? -10 : 10})`
              })
              .call(endAll, function () {
                middleSection.selectAll('.new-wtp-value')
                  .attr({
                    'opacity': 1,
                    'transform': (d: any) => `translate(${0}, ${0})`
                  })
                  .transition()
                  .delay((d, i) => i * 50)
                  .duration(100)
                  .attr({
                    'opacity': 0,
                    'transform': (d: any) => `translate(${-15}, ${0})`
                  })
                  .call(endAll, function (d, i) {
                    operateLine(centerLeftLineGroup, 'bottom', 'hide', 0, 200)
                    operateLine(centerLeftLineGroup, 'top', 'hide', 0, 200, function () {
                      operateLine(travelLeftLineGroup, 'screen-5-lines', 'hide', 0, 200, function () {
                        // TODO
                        //expand class to be added from here
                        updateGuestSegmentWTPValue();
                        commonLeftSection.resetClass('expand');
                        commonLeftSection.addClass('expand');
                        updateMiddleSection();
                        animateLeftToRightSection(function () { initialAnimationEnded() });
                      });
                    })
                  });
              });
          })
        });
      }

      function animateLeftToRightSection(cb = function () { }) {
        operateLine(travelLeftLineGroup, that.selectedTraveller, 'draw', 200, 400, function () {
          operateLine(centerLeftLineGroup, 'center-left-line', 'hide', 0, 0, function () {
            operateLine(centerLeftLineGroup, 'bottom', 'draw', 0, 400)
            operateLine(centerLeftLineGroup, 'top', 'draw', 0, 400, function () {
              updateIcons(middleBottomSection, 'inRoom');
              middleSection.selectAll('.new-wtp-value')
                .attr({
                  'opacity': 0,
                  'transform': (d: any) => `translate(${-15}, ${0})`
                })
                .transition()
                .delay((d, i) => i * 80)
                .duration(200)
                .attr({
                  'opacity': 1,
                  'transform': (d: any) => `translate(${0}, ${0})`
                })
                .call(endAll, function (d, i) {
                  legendSection
                    .transition()
                    .duration(150)
                    .style({
                      'opacity': 1
                    });
                  middleSection.selectAll('.dv-calculate-group')
                    .attr({
                      'opacity': 0,
                      'transform': (d: any) => `translate(${0}, ${d.dvCalculation < 0 ? -10 : 10})`
                    })
                    .transition()
                    .delay((d, i) => 80)
                    .duration(250)
                    .attr({
                      'opacity': 1,
                      'transform': (d: any) => `translate(${0}, ${0})`
                    })
                    .call(endAll, function () {
                      operateLine(travelRightLineGroup, 'bottom-line', 'draw', 0, 400)
                      operateLine(travelRightLineGroup, 'top-line', 'draw', 0, 400, function () {
                        operateLine(travelRightLineGroup, 'right-line', 'draw', 0, 400, function () {
                          cb();
                        });
                      });
                    })
                });
            })

          })

          // operateLine(travelRightLineGroup, 'right-line', 'draw', 0, 700, function () {
          //   initialAnimationEnded();
          // });
        })
      }

      function resetElements() {
        // console.log('=================Inside screen5 hideElements function');
        operateLine(currentProcess, 'screen-5-lines', 'hide', 0, 0);
        hideImage(currentProcess, 'screen-5-images', 0, 0);
        hideText(currentProcess, 'screen-5-text', 0, 0);
        legendSection.style('opacity', 0);
        currentProcess.selectAll('.dv-calculate-group')
          .transition()
          .duration(0)
          .attr('opacity', '0');
        currentProcess.selectAll('.new-wtp-value')
          .transition()
          .duration(0)
          .attr('opacity', '0');
        middleSection.selectAll('.connector')
          .transition()
          .duration(0)
          .style('opacity', '0');
      }

      return {
        startAnimation(isFirst = false) {
          // console.log('screen-5 startAnimation');
          // baseImage = '';
          // baseImage = '/assets/images/adaptive-pricing/5.png';
          // image.attr('xlink:href', baseImage);
          that.restrictScrollAndClick();
          updateMiddleSection();
          // console.log(that.previousTraversedSlide);
          currentProcess
            .transition()
            .delay(that.currentScrollDirection == 'forward' ? 0 : 2000)
            .duration(0)
            .style('opacity', 1)
            .call(endAll, function () {
              initStepAnimation(function () {
                showText(currentProcess, 'screen-other-title-text-screen-5', 0, 200, () => { })
                initiateAnimation(middleSection, 'screen-title-text-screen-5', 'footer-title-text-screen-5', function () { setUpdatedValue() });
                initiateAnimation(middleBottomSection, 'screen-title-bottom-text-screen-5', 'footer-title-bottom-text-screen-5', function () { });
              });
            });
        },
        climaxAnimation() {
          // that.isAnimation = true;
          currentProcess.transition();
          that.restrictScrollAndClick();
          endAnimation();
        },
        stopAnimation() {
          // currentProcess.interrupt();
          //remove class(list-active) from right section
          that.removeClassFromDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelList);
          that.removeClassFromDom('screen-five-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
          commonLeftSection.resetClass('expand');
          currentProcess.style('opacity', 0);
        },
        updateMiddleSection,
        updateTravellerPreference,
        resetElements() {
          resetElements();
        },
        groups: {
          travelRightLineGroup
        }
      }
    })();

    const screenSix = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-6-group')
        .attr('class', 'screen-6-group')
        .style('opacity', 0);

      const travelLeftLineGroup = currentProcess.append('g')
        .attr('class', 'travel-lines-screen-6');

      const travelLeftLines = [
        {
          line: [[310, 320], [395, 320], [446, 570], [482, 570]],
          type: 'solo'
        },
        {
          line: [[310, 491], [395, 491], [446, 570], [482, 570]],
          type: 'business'
        },
        {
          line: [[310, 674], [395, 674], [446, 570], [482, 570]],
          type: 'couple'
        },
        {
          line: [[310, 848], [395, 848], [446, 570], [482, 570]],
          type: 'group'
        }
      ];

      travelLeftLines.forEach((data, index) => {
        const line = addDashedLine(travelLeftLineGroup, data.line, `screen-6 screen-6-lines travel-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      });

      // const centerLeftLineGroup = currentProcess.append('g')
      //   .attr('class', 'center-left-lines-screen-6');

      const centerLeftLines = [
        {
          line: [[482, 542], [482, 361], [516, 328]],
          type: 'top'
        },
        {
          line: [[482, 542], [482, 772], [516, 814]],
          type: 'bottom'
        },
        {
          line: [[482, 500], [516, 500]],
          type: 'center-top'
        },
        {
          line: [[482, 664], [516, 664]],
          type: 'center-bottom'
        }
      ];

      centerLeftLines.forEach((data, index) => {
        const line = addDashedLine(travelLeftLineGroup, data.line, `screen-6 screen-6-lines center-left-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      });

      const middleTopSection = currentProcess.append('g')
        .attr('transform', 'translate(490, 240)');

      const screenSixTopData = {
        titleText: {
          wholeText: [{
            text: 'Hotel',
            textStyle: {
              'font-weight': 500,
            }
          }, {
            text: ' Attributes',
            textStyle: {
            }
          }],
          id: 'screen-title-top-text-screen-6',
          class: 'screen-title-top-text-screen-6 screen-6-text',
          x: 334,
          y: 8,
          finalX: 334,
          finalY: 18,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 20.5,
            'fill': '#3f3d56'
          }
        },
        imageData: [{
          id: 'center-image-background-top-screen-6',
          width: 672,
          height: 172,
          src: 'assets/images/adaptive-pricing/box.svg',
          x: 336,
          y: 86,
          finalX: 0,
          finalY: 0,
          class: 'screen-6 center background animate screen-6-images',
          s: 0
        }, {
          id: 'center-brand-screen-6',
          height: 65,
          width: 44,
          src: 'assets/images/adaptive-pricing/brand_grey.svg',
          x: 98,
          y: 45,
          finalX: 98,
          finalY: 55,
          class: 'screen-6 center icon brand animate screen-6-images',
          o: 0.3
        }, {
          id: 'center-location-screen-6',
          height: 65,
          width: 56,
          src: 'assets/images/adaptive-pricing/location_grey.svg',
          x: 200,
          y: 45,
          finalX: 200,
          finalY: 55,
          class: 'screen-6 center icon location animate screen-6-images',
          o: 0.3
        }, {
          id: 'center-star-screen-6',
          height: 64,
          width: 37,
          src: 'assets/images/adaptive-pricing/star_grey.svg',
          x: 318,
          y: 45,
          finalX: 318,
          finalY: 55,
          class: 'screen-6 center icon star animate screen-6-images',
          o: 0.3
        }, {
          id: 'center-review-screen-6',
          height: 62,
          width: 52,
          src: 'assets/images/adaptive-pricing/review_grey.svg',
          x: 418,
          y: 45,
          finalX: 418,
          finalY: 58,
          class: 'screen-6 center icon review animate screen-6-images',
          o: 0.3
        }, {
          id: 'center-discount-screen-6',
          height: 64,
          width: 56,
          src: 'assets/images/adaptive-pricing/discount_grey.svg',
          x: 530,
          y: 45,
          finalX: 530,
          finalY: 56,
          class: 'screen-6 center icon discount animate screen-6-images',
          o: 0.3
        }]
      };

      createCenterBox(middleTopSection, screenSixTopData);

      const middleBottomSection = currentProcess.append('g')
        .attr('transform', 'translate(490, 408)');

      const screenSixBottomData = {
        titleText: {
          wholeText: [{
            text: 'In-room',
            textStyle: {
              'font-weight': 500,
            }
          }, {
            text: ' Attributes',
            textStyle: {
            }
          }],
          id: 'screen-title-bottom-text-screen-6',
          class: 'screen-title-bottom-text-screen-6 screen-6-text',
          x: 334,
          y: 8,
          finalX: 334,
          finalY: 18,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 20.5,
            'fill': '#3f3d56'
          }
        },
        imageData: [
          {
            id: 'center-image-background-bottom-screen-6',
            width: 672,
            height: 172,
            src: 'assets/images/adaptive-pricing/box.svg',
            x: 336,
            y: 86,
            finalX: 0,
            finalY: 0,
            class: 'screen-6 center background animate screen-6-images',
            s: 0
          },
          {
            id: 'center-bed-screen-6',
            height: 60,
            width: 66,
            src: 'assets/images/adaptive-pricing/bed_grey.svg',
            x: 63,
            y: 46,
            finalX: 63,
            finalY: 56,
            class: 'screen-6 center icon bed animate screen-6-images',
            o: 0
          },
          {
            id: 'center-wifi-screen-6',
            height: 62,
            width: 96,
            src: 'assets/images/adaptive-pricing/wifi_grey.svg',
            x: 153,
            y: 44,
            finalX: 153,
            finalY: 54,
            class: 'screen-6 center icon wifi animate screen-6-images',
            o: 0
          },
          {
            id: 'center-bar-screen-6',
            height: 60,
            width: 63,
            src: 'assets/images/adaptive-pricing/bar_grey.svg',
            x: 277,
            y: 43,
            finalX: 277,
            finalY: 53,
            class: 'screen-6 center icon bar animate screen-6-images',
            o: 0
          },
          {
            id: 'center-balcony-screen-6',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/balcony_grey.svg',
            x: 383,
            y: 44,
            finalX: 383,
            finalY: 54,
            class: 'screen-6 center icon balcony animate screen-6-images',
            o: 0
          },
          {
            id: 'center-kitchen-screen-6',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/kitchen_grey.svg',
            x: 481,
            y: 42,
            finalX: 481,
            finalY: 52,
            class: 'screen-6 center icon kitchen animate screen-6-images',
            o: 0
          },
          {
            id: 'center-dining-screen-6',
            height: 62,
            width: 48,
            src: 'assets/images/adaptive-pricing/dining_grey.svg',
            x: 565,
            y: 43,
            finalX: 565,
            finalY: 53,
            class: 'screen-6 center icon dining animate screen-6-images',
            o: 0
          }
        ]
      };

      createCenterBox(middleBottomSection, screenSixBottomData);

      const centerRightLineGroup = currentProcess.append('g')
        .attr('class', 'center-right-lines-screen-6');

      const centerRightLines = [
        {
          line: [[1136, 326], [1172, 359], [1172, 570]],
          type: 'top'
        },
        {
          line: [[1136, 813], [1172, 772], [1172, 570]],
          type: 'bottom'
        },
        {
          line: [[1136, 500], [1172, 500]],
          type: 'center-top'
        },
        {
          line: [[1136, 664], [1172, 664]],
          type: 'center-bottom'
        },
        {
          line: [[1172, 570], [1198, 570], [1230, 356], [1259, 354]],
          type: 'right'
        }
      ];

      centerRightLines.forEach((data, index) => {
        const line = addDashedLine(centerRightLineGroup, data.line, `screen-6 screen-6-lines center-right-line ${data.type}`, '#65617d', '5,4');
        line.attr('stroke-dashoffset', (d) => d.pathLength);
      });

      const screenSixCurrentBookingIntent = {
        labelTextsContent: [
          {
            wholeText: [
              {
                text: 'Length of stay'
              }
            ],
            id: 'travel-type-label-text-screen-6',
            class: 'travel-type-label-text-screen-6 segment-profile-label segment-details screen-6-text',
            x: 88,
            y: 72,
            dy: '0em',
            finalX: 88,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          },
          {
            wholeText: [{
              text: 'Booking lead time'
            }],
            id: 'customer-type-label-text-screen-6',
            class: 'customer-type-label-text-screen-6 segment-profile-label segment-details screen-6-text',
            x: 352,
            y: 72,
            dy: '0em',
            finalX: 352,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          },
          {
            wholeText: [{
              text: 'Destination location'
            }],
            id: 'avg-length-of-stay-label-text-screen-6',
            class: 'avg-length-of-stay-label-text-screen-6 segment-profile-label segment-details screen-6-text',
            x: 88,
            y: 103,
            dy: '0em',
            finalX: 88,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          },
          {
            wholeText: [{
              text: 'Weekend/day travel'
            }],
            id: 'avg-price-label-text-screen-6',
            class: 'avg-price-label-text-screen-6 segment-profile-label segment-details screen-6-text',
            x: 352,
            y: 103,
            dy: '0em',
            finalX: 352,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          }
        ],
        answerTextsContent: [
          {
            wholeText: [
              {
                text: 'Travel type'
              }
            ],
            id: 'travel-type-answer-screen-6',
            class: 'travel-type-answer-screen-6 segment-profile-answer segment-details screen-6-text',
            x: 227,
            y: 72,
            dy: '0em',
            finalX: 227,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          },
          {
            wholeText: [{
              text: 'Cust. type'
            }],
            id: 'customer-type-answer-screen-6',
            class: 'customer-type-answer-screen-6 segment-profile-answer segment-details screen-6-text',
            x: 490,
            y: 72,
            dy: '0em',
            finalX: 490,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          },
          {
            wholeText: [{
              text: 'Avg. len. stay'
            }],
            id: 'avg-length-of-stay-answer-screen-6',
            class: 'avg-length-of-stay-answer-screen-6 segment-profile-answer segment-details screen-6-text',
            x: 227,
            y: 103,
            dy: '0em',
            finalX: 227,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          },
          {
            wholeText: [{
              text: 'Avg. price/night'
            }],
            id: 'avg-price-answer-screen-6',
            class: 'avg-price-answer-screen-6 segment-profile-answer segment-details screen-6-text',
            x: 490,
            y: 103,
            dy: '0em',
            finalX: 490,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          }
        ],
        titleBackgroundData: {
          titleText: {
            wholeText: [{
              text: 'Current',
              textStyle: {
                'font-weight': 500,
              }
            }, {
              text: ' Booking Intent',
              textStyle: {
              }
            }],
            id: 'screen-title-segment-profile-text-screen-6',
            class: 'screen-title-segment-profile-text-screen-6 screen-6-text',
            x: 334,
            y: 8,
            finalX: 334,
            finalY: 18,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 20.5,
              'fill': '#3f3d56'
            }
          },
          imageData: [
            {
              id: 'segment-profile-background-bottom-screen-6',
              width: 672,
              height: 172,
              src: 'assets/images/adaptive-pricing/box.svg',
              x: 336,
              y: 86,
              // x: 0,
              // y: 0,
              finalX: 0,
              finalY: 0,
              class: 'screen-6 bottom background animate segment-profile-bg screen-6-images',
              s: 0,
              // o: 1
            }]
        },
      };

      const segementProfileDataSection = currentProcess.append('g')
        .attr({
          'class': 'segment-profile-section',
          'id': 'segment-profile-section',
          'transform': 'translate(490, 570)'
        });

      createCenterBox(segementProfileDataSection, screenSixCurrentBookingIntent.titleBackgroundData);

      const answerTextElement = [];

      screenSixCurrentBookingIntent.labelTextsContent.forEach((labelTextContent, index) => {
        const answerTextsContent = screenSixCurrentBookingIntent.answerTextsContent;
        createTextUsingData(segementProfileDataSection, labelTextContent);
        answerTextElement[index] = createTextUsingData(segementProfileDataSection, answerTextsContent[index]);
      });

      const screenSixHistoricPreferenceData = {
        labelTextsContent: [
          {
            wholeText: [
              {
                text: 'Past dollars spent'
              }
            ],
            id: 'travel-type-label-text-screen-6',
            class: 'travel-type-label-text-screen-6 historical-preference-label historical-details screen-6-text',
            x: 88,
            y: 72,
            dy: '0em',
            finalX: 88,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          },
          {
            wholeText: [{
              text: 'Past star rating ref'
            }],
            id: 'customer-type-label-text-screen-6',
            class: 'customer-type-label-text-screen-6 historical-preference-label historical-details screen-6-text',
            x: 352,
            y: 72,
            dy: '0em',
            finalX: 352,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          },
          {
            wholeText: [{
              text: 'Past total night',
              dy: '0em',
              x: 0,
            },
            {
              text: 'stayed',
              dy: '1.2em',
              x: 0,
            }],
            id: 'avg-length-of-stay-label-text-screen-6',
            class: 'avg-length-of-stay-label-text-screen-6 historical-preference-label historical-details screen-6-text',
            x: 88,
            y: 103,
            dy: '0em',
            finalX: 88,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          },
          {
            wholeText: [{
              text: 'Past ancillary',
              dy: '0em',
              x: 0,
            },
            {
              text: 'interactions',
              dy: '1.2em',
              x: 0,
            }],
            id: 'avg-price-label-text-screen-6',
            class: 'avg-price-label-text-screen-6 historical-preference-label historical-details screen-6-text',
            x: 352,
            y: 103,
            dy: '0em',
            finalX: 352,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 14,
              'fill': '#3f3d56',
              'font-weight': 400
            }
          }
        ],
        answerTextsContent: [
          {
            wholeText: [
              {
                text: 'Travel type'
              }
            ],
            id: 'travel-type-answer-screen-6',
            class: 'travel-type-answer-screen-6 historical-preference-answer historical-details screen-6-text',
            x: 227,
            y: 72,
            dy: '0em',
            finalX: 227,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          },
          {
            wholeText: [{
              text: 'Cust. type'
            }],
            id: 'customer-type-answer-screen-6',
            class: 'customer-type-answer-screen-6 historical-preference-answer historical-details screen-6-text',
            x: 490,
            y: 72,
            dy: '0em',
            finalX: 490,
            finalY: 72,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          },
          {
            wholeText: [{
              text: 'Avg. len. stay'
            }],
            id: 'avg-length-of-stay-answer-screen-6',
            class: 'avg-length-of-stay-answer-screen-6 historical-preference-answer historical-details screen-6-text',
            x: 227,
            y: 103,
            dy: '0em',
            finalX: 227,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          },
          {
            wholeText: [{
              text: 'Avg. price/night'
            }],
            id: 'avg-price-answer-screen-6',
            class: 'avg-price-answer-screen-6 historical-preference-answer historical-details screen-6-text',
            x: 490,
            y: 103,
            dy: '0em',
            finalX: 490,
            finalY: 103,
            o: 0,
            textStyle: {
              'text-anchor': 'start',
              'font-size': 15,
              'fill': '#C94573',
              'font-weight': 500
            }
          }
        ],
        titleBackgroundData: {
          titleText: {
            wholeText: [{
              text: 'Guest\'s',
              textStyle: {
                'font-weight': 500,
              }
            }, {
              text: ' Historical Preferences',
              textStyle: {
              }
            }],
            id: 'screen-title-historical-preference-text-screen-6',
            class: 'screen-title-historical-preference-text-screen-6 screen-6-text',
            x: 334,
            y: 10,
            finalX: 334,
            finalY: 20,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 20.5,
              'fill': '#3f3d56'
            }
          },
          imageData: [
            {
              id: 'segment-profile-background-bottom-screen-6',
              width: 672,
              height: 172,
              src: 'assets/images/adaptive-pricing/historic_box.svg',
              x: 336,
              y: 86,
              // x: 0,
              // y: 0,
              finalX: 0,
              finalY: 0,
              class: 'screen-6 bottom background animate historical-preference-bg screen-6-images',
              s: 0,
              // o: 1
            }]
        },
      };

      const segementHistoricPreferenceSection = currentProcess.append('g')
        .attr({
          'class': 'segment-historic-preference-section',
          'id': 'segment-historic-preference-section',
          'transform': 'translate(490, 720)'
        });

      createCenterBox(segementHistoricPreferenceSection, screenSixHistoricPreferenceData.titleBackgroundData);

      const historicPreferenceAnswerTextElement = [];

      screenSixHistoricPreferenceData.labelTextsContent.forEach((labelTextContent, index) => {
        const answerTextsContent = screenSixHistoricPreferenceData.answerTextsContent;
        createTextUsingData(segementHistoricPreferenceSection, labelTextContent);
        historicPreferenceAnswerTextElement[index] = createTextUsingData(segementHistoricPreferenceSection, answerTextsContent[index]);
      });

      function startMiddleSectionAnimation(group, className, cb = function () { }) {
        showText(group, className, 0, 500, function () { });
        showImage(group, 'center'
          , 0, 500, function () {
            group.selectAll('.icon')
              .transition()
              .delay((d, i) => i * 80)
              .duration(100)
              .style('opacity', 0.3)
              .attr({
                'xlink:href': (d: any) => d.src,
                'transform': (d: any) => `translate(${d.finalX}, ${d.finalY})`
              })
              .call(endAll, function (d, i) {
                // console.log('All attributes have been visible');
                cb();
              })
          }
        )
      }

      function resetIconsLinesAndStartSelectedTravellertraverse() {
        hideText(segementProfileDataSection, 'segment-profile-answer', 0, 5);
        hideText(segementHistoricPreferenceSection, 'historical-preference-answer', 0, 5);
        currentProcess.selectAll('.icon')
          .transition()
          .duration(5)
          .attr({
            'xlink:href': (d: any) => d.src
          })
          .style('opacity', 0.3)
          .call(endAll, function (d, i) {
            operateLine(travelLeftLineGroup, 'bottom', 'hide', 0, 100);
            operateLine(travelLeftLineGroup, 'center-bottom', 'hide', 0, 100);
            operateLine(travelLeftLineGroup, 'center-top', 'hide', 0, 100);
            operateLine(travelLeftLineGroup, 'top', 'hide', 0, 100,
              function () {
                startLeftToMiddleToRightAnimation();
              });
          });
      }

      function updateTravellerPreference(cb = resetIconsLinesAndStartSelectedTravellertraverse, removeClass = true) {
        // console.log('Screen six updateTravellerPreference');
        if (removeClass) {
          //remove class(list-active) from reight section
          that.removeClassFromDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelList);
          that.addClassToDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
        }
        operateLine(centerRightLineGroup, 'right', 'hide', 0, removeClass ? 100 : 10,
          function () {
            operateLine(centerRightLineGroup, 'top', 'hide', 0, removeClass ? 100 : 10);
            operateLine(centerRightLineGroup, 'center-top', 'hide', 0, removeClass ? 100 : 10);
            operateLine(centerRightLineGroup, 'center-bottom', 'hide', 0, removeClass ? 100 : 10);
            operateLine(centerRightLineGroup, 'bottom', 'hide', 0, removeClass ? 100 : 10,
              cb);
          });
      }

      function startLeftToMiddleToRightAnimation() {
        // console.log('Screen 6 Lines animation started');
        operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 0, () =>
          operateLine(travelLeftLineGroup, that.selectedTraveller, 'draw', 0, 400,
            function () {
              operateLine(travelLeftLineGroup, 'center-left-line', 'hide', 0, 0,
                function () {
                  operateLine(travelLeftLineGroup, 'bottom', 'draw', 0, 400, function () { updateTravellerDetailsInGuestsHistoricalPreferences(); });
                  operateLine(travelLeftLineGroup, 'center-bottom', 'draw', 0, 400, function () { updateTravellerDetailsInCurrentBookingIntent(); });
                  operateLine(travelLeftLineGroup, 'center-top', 'draw', 0, 400, function () { updateIcons(middleBottomSection, 'inRoom'); });
                  operateLine(travelLeftLineGroup, 'top', 'draw', 0, 400, function () {
                    updateIcons(middleTopSection, 'hotel', function () {
                      operateLine(centerRightLineGroup, 'center-right-line', 'hide', 50, 0,
                        function () {
                          operateLine(centerRightLineGroup, 'top', 'draw', 0, 400);
                          operateLine(centerRightLineGroup, 'center-top', 'draw', 0, 400);
                          operateLine(centerRightLineGroup, 'center-bottom', 'draw', 0, 400);
                          operateLine(centerRightLineGroup, 'bottom', 'draw', 0, 400,
                            function () {
                              operateLine(centerRightLineGroup, 'right', 'draw', 0, 400, function () {
                                initialAnimationEnd();
                              });
                            });
                        });
                    });
                  });
                });
            })
        );
      }

      function initialAnimationEnd() {
        //add class(list-active) to right section card-body
        that.removeClassFromDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
        that.addClassToDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelList);
        that.onAnimationEnd();
      }

      function initiateAnimation() {
        // updateIcons(middleTopSection);
        // updateIcons(middleBottomSection);
        startMiddleSectionAnimation(middleTopSection, 'screen-title-top-text-screen-6');
        startMiddleSectionAnimation(middleBottomSection, 'screen-title-bottom-text-screen-6', function () {
          //add class(card-active) to right section
          that.addClassToDom('screen-six-right-section', that.htmlClassVariable.card);
          that.addClassToDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
          startLeftToMiddleToRightAnimation();
        });
        showText(segementProfileDataSection, 'screen-title-segment-profile-text-screen-6', 0, 500, function () { });
        showImage(segementProfileDataSection, 'segment-profile-bg', 0, 500, function () {
          showText(segementProfileDataSection, 'segment-profile-label', 0, 200);
        });
        showText(segementHistoricPreferenceSection, 'screen-title-historical-preference-text-screen-6', 0, 500, function () { });
        showImage(segementHistoricPreferenceSection, 'historical-preference-bg', 0, 500, function () {
          showText(segementHistoricPreferenceSection, 'historical-preference-label', 0, 200);
        });
      }

      function updateTravellerDetailsInCurrentBookingIntent() {
        // console.log("selectedTravellerData", that.selectedTravellerData);
        const travellerHistoryDetails = that.selectedTravellerData.historyData;
        answerTextElement[0].select('tspan').text(travellerHistoryDetails.lengthOfStay);
        answerTextElement[1].select('tspan').text(travellerHistoryDetails.bookingLeadTime);
        answerTextElement[2].select('tspan').text(travellerHistoryDetails.destinationLocation);
        answerTextElement[3].select('tspan').text(travellerHistoryDetails.weekendDayTravel);
        // answerTextElement[4].select('tspan').text(travellerHistoryDetails.checkInDays);
        // answerTextElement[5].select('tspan').text(travellerHistoryDetails.averageReviewScore);
        showText(segementProfileDataSection, 'segment-profile-answer', 0, 250);
      }

      function updateTravellerDetailsInGuestsHistoricalPreferences() {
        // console.log("selectedTravellerData", that.selectedTravellerData);
        const travellerHistoryDetails = that.selectedTravellerData.historyData;
        historicPreferenceAnswerTextElement[0].select('tspan').text(`${that.pricingData.currencyUnit}${travellerHistoryDetails.pastDollarSpent}/night`);
        historicPreferenceAnswerTextElement[1].select('tspan').text(travellerHistoryDetails.pastStarRatingReference);
        historicPreferenceAnswerTextElement[2].select('tspan').text(travellerHistoryDetails.pastTotalNightStayed);
        historicPreferenceAnswerTextElement[3].select('tspan').text(travellerHistoryDetails.pastAncillaryInteractions);
        // answerTextElement[4].select('tspan').text(travellerHistoryDetails.checkInDays);
        // answerTextElement[5].select('tspan').text(travellerHistoryDetails.averageReviewScore);
        showText(segementHistoricPreferenceSection, 'historical-preference-answer', 0, 250);
      }

      function hideMiddleBackground(group, className) {
        currentProcess.selectAll('.icon')
          .transition()
          .duration(0)
          .attr({
            'xlink:href': (d: any) => d.src
          })
          .style('opacity', '0');
        hideImage(group, 'center', 0, 0);
        hideText(group, className, 0, 0);
      }

      function initiateEndAnimation(group, className, cb = function () { }) {
        // console.log('Inside endAnimation');
        const selection = group.selectAll('.icon')
        selection[0].reverse();
        selection
          .transition()
          .delay((d, i) => i * 10)
          .duration(10)
          .style('opacity', 0)
          .attr({
            'transform': (d: any) => `translate(${d.x}, ${d.y})`,
            'xlink:href': (d: any) => d.src
          })
          .call(endAll, function () {
            hideImage(group, 'center', 0, 10)
            hideText(group, className, 0, 10,
              cb);
          })
      }

      function endAnimation() {
        that.removeClassFromDom('screen-six-right-section', that.htmlClassVariable.card);
        updateTravellerPreference(function () {
          hideText(segementProfileDataSection, 'segment-details', 0, 10, function () {
            hideText(segementProfileDataSection, 'screen-title-segment-profile-text-screen-6', 0, 10);
            hideImage(segementProfileDataSection, 'segment-profile-bg', 0, 10);

            // hideText(segementHistoricPreferenceSection, 'screen-title-historical-preference-text-screen-6', 0, 10);
            // hideImage(segementHistoricPreferenceSection, 'historical-preference-bg', 0, 10);
          });

          hideText(segementHistoricPreferenceSection, 'historical-details', 0, 10, function () {
            hideText(segementHistoricPreferenceSection, 'screen-title-historical-preference-text-screen-6', 0, 10);
            hideImage(segementHistoricPreferenceSection, 'historical-preference-bg', 0, 10);
          });

          initiateEndAnimation(middleTopSection, 'screen-title-top-text-screen-6')
          initiateEndAnimation(middleBottomSection, 'screen-title-bottom-text-screen-6',
            function () {
              operateLine(travelLeftLineGroup, 'bottom', 'hide', 0, 10);
              operateLine(travelLeftLineGroup, 'center-bottom', 'hide', 0, 10);
              operateLine(travelLeftLineGroup, 'center-top', 'hide', 0, 10);
              operateLine(travelLeftLineGroup, 'top', 'hide', 0, 10,
                function () {
                  operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 10);
                });
            });
        }, false);
      }

      // function updateTravellerPreference() {
      //   // drawLine(travelLeftLineGroup);

      //   updateIcons(middleTopSection);
      //   updateIcons(middleBottomSection);
      // }
      function resetElements() {
        // console.log('=================Inside screen6 hideElements function');
        operateLine(currentProcess, 'screen-6-lines', 'hide', 0, 0);
        hideImage(currentProcess, 'screen-6-images', 0, 0);
        hideText(currentProcess, 'screen-6-text', 0, 0);
        hideMiddleBackground(middleTopSection, 'screen-title-top-text-screen-6');
        hideMiddleBackground(middleBottomSection, 'screen-title-bottom-text-screen-6');
      }

      return {
        startAnimation(isFirst = false) {
          // // console.log('screen-6 startAnimation');
          // baseImage = '';
          // baseImage = '/assets/images/adaptive-pricing/6.png';
          // image.attr('xlink:href', baseImage);
          commonLeftSection.resetClass('expand');
          that.restrictScrollAndClick();
          travellersWrapper.select(`.${that.selectedTraveller} .title-underline`)
            .attr({
              'stroke': 'url(#traveller-selected-lg)',
              'stroke-dashoffset': 0,
              'opacity': 1
            });
          currentProcess
            .transition()
            .delay(2000)
            .style('opacity', 1)
            .call(endAll, function () {
              initiateAnimation();
            })
        },
        climaxAnimation() {
          currentProcess.transition();
          that.restrictScrollAndClick();
          endAnimation();
        },
        stopAnimation() {
          // currentProcess.interrupt();
          that.removeClassFromDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelList);
          that.removeClassFromDom('screen-six-right-section #card-body-1', that.htmlClassVariable.hotelListLoader);
          currentProcess.style('opacity', 0);
        },
        updateTravellerPreference,
        resetElements() {
          resetElements();
        },
        groups: {
          centerRightLineGroup
        }
      }
    })();


    const travellersData = [
      {
        id: 'solo',
        class: 'solo',
        isDefault: false,
        x: 0,
        y: 0,
        rx: 65,
        width: 180,
        height: 130,
        imageURL: '',
        isShadow: true,
        title: 'Solo Traveller',
        color: '#FFFFFF',
        duration: 650,
        delay: 100,
        shadow: {
          x: 40,
          y: 112,
          rx: 65,
          height: 5,
          width: 100,
          color: '#e6e6e6'
        },
        titleLine: [[88, 1], [268, 2]],
      },
      {
        id: 'business',
        class: 'business',
        isDefault: false,
        x: 0,
        y: 175,
        rx: 65,
        width: 180,
        height: 130,
        imageURL: '',
        isShadow: true,
        title: 'Business Traveller',
        color: '#FFFFFF',
        duration: 650,
        delay: 400,
        shadow: {
          x: 43,
          y: 280,
          rx: 65,
          height: 7,
          width: 100,
          color: '#e6e6e6'
        },
        titleLine: [[88, 176], [268, 177]]

      },
      {
        id: 'couple',
        class: 'couple',
        isDefault: false,
        x: 0,
        y: 350,
        rx: 65,
        width: 180,
        height: 130,
        imageURL: '',
        isShadow: true,
        title: 'Couple Traveller',
        color: '#FFFFFF',
        duration: 650,
        delay: 600,
        shadow: {
          x: 40,
          y: 456,
          rx: 65,
          height: 5,
          width: 100,
          color: '#e6e6e6'
        },
        titleLine: [[88, 351], [268, 352]]

      },
      {
        id: 'group',
        class: 'group',
        isDefault: false,
        x: 0,
        y: 525,
        rx: 65,
        width: 180,
        height: 130,
        imageURL: '',
        isShadow: true,
        title: 'Group Traveller',
        color: '#FFFFFF',
        duration: 650,
        delay: 800,
        shadow: {
          x: 40,
          y: 618,
          rx: 65,
          height: 10,
          width: 110,
          color: '#e6e6e6'
        },
        titleLine: [[88, 526], [268, 527]],
        isLast: true
      }];

    const travellersWrapper = dataGroup.append('g')
      .attr({
        'class': 'travellers-wrapper',
        'id': 'travellers-wrapper'
      })
      .style({
        'transform': `translate(100px, 250px)`,
        'opacity': 1
      });

    const travellerGroup = travellersWrapper.selectAll('g')
      .data(travellersData)
      .enter()
      .append('g')
      .attr({
        'class': (d, i) => `traveller-group ${d.class}`,
        'id': (d, i) => `traveller-group-${i}`
      });

    const manageScreenwiseAnimation = function () {
      return {
        changeAnimation() {
          if (['screen-1', 'screen-7'].includes(that.activeSlide)) {
            // console.log("Inside manageScreenwiseAnimation screen 1 and 7 if scope triggered");
            commonLeftSection.manageTravellersGroup();
          } else if (that.activeSlide == 'screen-2') {
            screenTwo.updateTravellerPreference();
          } else if (that.activeSlide == 'screen-3') {
            that.onAnimationEnd();
          } else if (that.activeSlide == 'screen-4') {
            screenFour.updateRightSection();
            that.onAnimationEnd();
          } else if (that.activeSlide == 'screen-5') {
            screenFive.updateTravellerPreference();
          } else if (that.activeSlide == 'screen-6') {
            screenSix.updateTravellerPreference();
          }
          // that.isAnimation = false;
        }
      }
    }();

    travellerGroup.append('foreignObject')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y)
      .attr('width', (d) => 280 + 20)
      .attr('height', (d) => d.height + 20)
      .append('xhtml:div')
      .attr({
        'class': (d) => `oval-wrapper ${d.id}`
      })
      .style({
        'opacity': 0,
        'transition': (d, i) => `all ${d.duration}ms ease-in-out`
      })
      .append('xhtml:div')
      .attr({
        'class': (d, i) => `oval-space ${d.id}`
      })
      .html(d => `<div class='inner-image w-100 h-100 position-relative'></div>
                  <div class='info'>
                    <h2>WTP</h2>
                    <h3 class='avg-value' >$215</h3>
                    <p>/night</p>
                  </div>`)
      .on('mouseover', function (obj, index) {
        if (obj.id != that.selectedTraveller) {
          travellersWrapper.select(`.${obj.id} #title-underline-${index}`)
            .transition()
            .attr({
              'stroke': 'url(#traveller-lg)',
              'stroke-dashoffset': 0,
              'opacity': 1
            });
          travellersWrapper.select(`.${obj.id} #traveller-title-${index}`)
            .transition()
            .attr({
              'opacity': 1
            });
        }
      })
      .on('mouseout', function (obj, index) {
        if (obj.id != that.selectedTraveller) {
          travellersWrapper.select(`.${obj.id} #title-underline-${index}`)
            .transition()
            .attr({
              'opacity': 0
            });
          travellersWrapper.select(`.${obj.id} #traveller-title-${index}`)
            .transition()
            .attr({
              'opacity': 0
            });
        }
      })
      .on('click', function (object, index) {
        // console.log("Object: ", object);
        //reset default
        if (object.id !== that.selectedTraveller && !that.isAnimation) {
          // // console.log('Traveller Unmatched');
          // that.isAnimation = true;
          that.restrictScrollAndClick();
          that.selectedTraveller = object.id;
          that.setTravellerData();
          commonLeftSection.resetClass('active');

          travellersWrapper.selectAll('.title-underline')
            .attr({
              'stroke': 'url(#traveller-lg)',
              'opacity': 0
            });
          travellersWrapper.selectAll(`.traveller-title`)
            .attr({
              'opacity': 0
            });

          //set selected
          commonLeftSection.setSelectedTraveller();

          manageScreenwiseAnimation.changeAnimation();
        } else {
          // console.log('Animation is not yet over');
        }
      });

    // travellerGroup.append('rect')
    //   .attr({
    //     'class': 'background animate',
    //     'id': (d, i) => `background-${i}`,
    //     'x': (d) => d.x,
    //     'y': (d) => d.y,
    //     'rx': (d) => d.rx,
    //     'height': (d) => d.height,
    //     'width': (d) => d.width,
    //     'fill': (d) => d.isDefault ? '#ffeae3' : d.color,
    //     'opacity': 0,
    //   })
    //   .on('mouseover', function (obj, index) {
    //     if (obj.id != that.selectedTraveller) {
    //       travellersWrapper.select(`.${obj.id} #title-underline-${index}`)
    //         .transition()
    //         .attr({
    //           'stroke': 'url(#traveller-lg)',
    //           'stroke-dashoffset': 0,
    //           'opacity': 1
    //         });
    //       travellersWrapper.select(`.${obj.id} #traveller-title-${index}`)
    //         .transition()
    //         .attr({
    //           'opacity': 1
    //         });
    //     }
    //   })
    //   .on('mouseout', function (obj, index) {
    //     if (obj.id != that.selectedTraveller) {
    //       travellersWrapper.select(`.${obj.id} #title-underline-${index}`)
    //         .transition()
    //         .attr({
    //           'opacity': 0
    //         });
    //       travellersWrapper.select(`.${obj.id} #traveller-title-${index}`)
    //         .transition()
    //         .attr({
    //           'opacity': 0
    //         });
    //     }
    //   });

    // travellerGroup.append('rect')
    //   .attr({
    //     'class': 'shadow',
    //     'id': (d, i) => `shadow-${i}`,
    //     'x': (d) => d.shadow.x,
    //     'y': (d) => d.shadow.y,
    //     'rx': (d) => d.shadow.rx,
    //     'height': (d) => d.shadow.height,
    //     'width': (d) => d.shadow.width,
    //     'fill': (d) => d.isDefault ? '#FFFFFF' : d.shadow.color,
    //   });

    travellerGroup.append('text')
      .attr({
        'class': (d, i) => `traveller-title`,
        'id': (d, i) => `traveller-title-${i}`,
        'x': (d) => d.titleLine[1][0],
        'y': (d) => d.titleLine[1][1] - 10,
        'dy': '0em',
        'fill': '#65617d',
        'text-anchor': 'end',
        'font-size': '16',
        'opacity': 0
      })
      .style({
        'font-weight': '500'
      })
      .text((d) => d.title);

    let titleLinePath = [];
    for (let [key, traveller] of Object.entries(travellersData)) {
      // console.log('traveller: ', traveller, `traveller-group-${key}`, travellersWrapper.select(`#traveller-group-${key}`));
      titleLinePath.push(addLine(travellersWrapper.select(`#traveller-group-${key}`), traveller.titleLine, `title-underline`, key, 'url(#traveller-lg)', 2.5, false));
    }

    const commonLeftSection = function () {
      function manageTravellersGroup(opacity = 0) {
        travellersWrapper.selectAll('.traveller-group')
          .attr({
            'opacity': opacity
          });
      };

      function animateTravellers(cb = () => { }) {
        manageTravellersGroup(1);
        if (that.selectedTraveller !== null) {
          // console.log('Inside animateTraveller traveller is selected');
          screenTwo.startLeftToMiddleAnimation();
        } else {
          travellersWrapper.selectAll('.oval-wrapper')
            .transition()
            .delay((d, i) => 100 * i)
            .style('opacity', 1)
            .each('end', function (data) {
              travellersWrapper.select(`.oval-wrapper.${data.id}`)
                .transition()
                .delay(d => d.duration + 100)
                .each('end', (d, index) => {
                  // console.log('onEnd: ', index, d);
                  travellersWrapper.select(`.${d.id} .title-underline`)
                    .attr({
                      'stroke-dashoffset': 0,
                      'opacity': 0
                    });

                  if (d.isLast !== void (0)) {
                    //Instead of this add class of active to div in foreignObject later once developed
                    if (that.selectedTraveller == null) {
                      that.selectedTraveller = travellersData[0].id;
                      that.setTravellerData();
                    }

                    setSelectedTraveller(function () {
                      screenTwo.startLeftToMiddleAnimation();
                    });
                  }
                  // travellersWrapper.select(`.${d.id} .title-underline`)
                  //   .transition()
                  //   .duration(80)
                  //   .attr({
                  //     'stroke-dashoffset': 0,
                  //     'opacity': 1
                  //   });

                  // travellersWrapper.select(`.${d.id} .traveller-title`)
                  //   .transition()
                  //   .delay(10)
                  //   .duration(100)
                  //   .attr({
                  //     'opacity': 1
                  //   })
                  //   .each('end', function (d, i) {
                  //     if (d.isLast !== void (0)) {
                  //       console.log('All Lines Animation end', d);
                  //       travellersWrapper.selectAll('.title-underline')
                  //         .transition()
                  //         .attr({
                  //           'opacity': 0
                  //         });
                  //       travellersWrapper.selectAll('.traveller-title')
                  //         .transition()
                  //         .attr({
                  //           'opacity': 0
                  //         });
                  //     }
                  //   });
                });
            });
        }
      }

      function setSelectedTraveller(cb = function () { }) {
        // console.log('Inside setSelectedTraveller selectedTraveller: ', that.selectedTraveller);

        addClass('active');

        if (that.activeSlide != 'screen-5') {
          travellersWrapper.select(`.${that.selectedTraveller} .title-underline`)
            .attr({
              'stroke': 'url(#traveller-selected-lg)',
              'stroke-dashoffset': 0,
              'opacity': 1
            });
        }

        travellersWrapper.select(`.${that.selectedTraveller} .traveller-title`)
          .transition()
          .duration(0)
          .attr({
            'opacity': 1
          })
          .call(endAll, function () {
            cb();
          });
      }

      function checkAndSetDefaultTraveller() {
        if (that.selectedTraveller == null) {
          that.selectedTraveller = 'solo';
          that.setTravellerData();
          travellersWrapper.selectAll('.oval-wrapper')
            .style('opacity', 1);
          setSelectedTraveller();
        }
      }

      function addClass(className) {
        travellerGroup.select(`.oval-space.${that.selectedTraveller}`)
          .classed(className, true);
      }

      function resetClass(className) {
        travellersWrapper.selectAll('.oval-space')
          .classed(className, false);
      }

      function updateGuestSegmentWTPValueToDOM() {
        // console.log('To update AVG WTP Value: ', travellersWrapper.select(`.${that.selectedTraveller} .avg-value`))
        travellersWrapper.select(`.${that.selectedTraveller} .avg-value`)
          .html(`${that.pricingData.currencyUnit}${that.guestSegmentWTP}`)
      }

      return {
        manageTravellersGroup,
        animateTravellers,
        setSelectedTraveller,
        checkAndSetDefaultTraveller,
        addClass,
        resetClass,
        updateGuestSegmentWTPValueToDOM
      }
    }();

    // const wrapper = svg.append('g')
    //   .attr('class', 'center-wrapper')
    //   .attr('transform', 'translate(490, 422)')

    // const centerAnimation = createCenterBox(wrapper, screenTwoCenterData);
    // setTimeout(() => {
    //   centerAnimation.animation();
    // }, 1000);

    return {
      resize() {
        // console.log('resize');
        const aspect = resize();
        // console.log('aspect ::', aspect);
        that.scaleXValue = aspect.widthAspect;
        that.scaleYValue = aspect.heightAspect;
        setTimeout(() => {
          const screenFiveBoundingClientRect = screenFive.groups.travelRightLineGroup.node().getBoundingClientRect();
          const travellerWrapperBoundingClient = travellersWrapper.node().getBoundingClientRect();
          const screen7LeftBoundingClient = screenSeven.groups.screen7LeftSection.node().getBoundingClientRect();
          // d3.select('#screen-five-right-section')
          //   // .style('top', `${screenFiveBoundingClientRect.top}px`)
          //   .style('left', `${screenFiveBoundingClientRect.right}px`);
          that.rightSectionCardAspect.left = screenFiveBoundingClientRect.right;
          that.rightSectionCardAspect.width = window.innerWidth - screenFiveBoundingClientRect.right;
          that.travellerSectionAspect.left = travellerWrapperBoundingClient.left;
          that.travellerSectionAspect.bottom = window.innerHeight - (travellerWrapperBoundingClient.top + travellerWrapperBoundingClient.height);
          that.screenWiseSection.screen7.left = screen7LeftBoundingClient.left;
          // const screenSixBoundingClientRect = screenSix.groups.centerRightLineGroup.node().getBoundingClientRect();
          // d3.select('#screen-six-right-section')
          //   // .style('top', `${screenSixBoundingClientRect.top}px`)
          //   .style('left', `${screenSixBoundingClientRect.right}px`);
        }, 0);
        return aspect;
      },
      screenOne,
      screenTwo,
      screenThree,
      screenFour,
      screenFive,
      screenSix,
      screenSeven,
      manageScreenwiseAnimation,
      commonLeftSection
    }
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'), 10);
    const height = parseInt(svg.attr('height'), 10);
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'), 10);
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width'), 10),
        height: parseInt(svg.style('height'), 10)
      };
    };
    svg.attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width'), 10) / width,
      heightAspect: parseInt(svg.style('height'), 10) / height
    };
  }

  public updateHotelPrice() {
    this.selectedTravellerHotelData.pricePerDay = this.guestSegmentWTP;

    this.selectedTravellerHotelData.forEach((el, i) => {
      if (i === 0) {
        el.pricePerDay = this.guestSegmentWTP;
      } else {
        const minValue = this.guestSegmentWTP - 4;
        const maxValue = this.guestSegmentWTP + 4;

        el.pricePerDay = (minValue + Math.random() * (maxValue - minValue)).toFixed(0);
      }
    });
  }

  ngOnDestroy() {
    if (this._unsubscribeResize) {
      this._unsubscribeResize.unsubscribe();
    }
    d3.selectAll('.animate').interrupt();
    // d3.selectAll('*').interrupt();

    this.dataShareService.onCustomEvent.next({ event: 'removeClassToElement', data: { selector: '#header', class: 'humburger-header' } });
  }
}
