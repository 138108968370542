import { Component } from '@angular/core';

@Component({
selector: 'app-footer',
templateUrl: './footer.component.html'
})
export class FooterComponent {
	public currentYear = new Date().getFullYear();
	constructor() { }

	onGoToTopClick() {
		const scrollContainer = document.getElementById('scroll-container');
		scrollContainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}
}
