import { Component, OnInit, Output, EventEmitter, ViewChild,
        ElementRef, ViewEncapsulation, AfterViewInit, Renderer2, OnDestroy, DoCheck } from '@angular/core';
import _ from 'lodash';
import * as topojson from 'topojson';

import WorldMapData from './worldMap50.json';
import jsonData from './data.json';
import { D3CustomShapesService } from '../../services/helper/d3-custom-shapes.service';

declare const d3: any;

@Component({
  selector: 'app-micro-lead-identification',
  templateUrl: './micro-lead-identification.component.html',
  styleUrls: ['./micro-lead-identification.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MicroLeadIdentificationComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef;
  @ViewChild('content') content: ElementRef;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onVisibleSectionChange = new EventEmitter<any>();

  private sectionDimention: any;
  private problemSection: any;
  private dataSection: any;
  private problemMapSection: any;
  private solutionSection: any;
  private outcomesSection: any;
  private scrollSection: any;
  public showDataAnimation = false;
  private unsubscribeResize = null;
  private unsubscribeAnimationFrame = null;
  private _lastHeight: number ;
  constructor(
    private shapesService: D3CustomShapesService,
    private renderer: Renderer2) { }

  ngDoCheck(): void {
    const scrollWindowHeight = document.getElementById('scroll-container').offsetHeight;
    if (this._lastHeight !== scrollWindowHeight) {
      this._lastHeight = scrollWindowHeight;
      this.calculateAndSetScale();
    }
  }

  ngAfterViewInit() {
    this.calculateAndSetScale();

    setTimeout(() => {
      const ele = d3.select('#product_list');
      ele.attr('class', ele.attr('class') + ' in');
    }, 500);

    this.unsubscribeResize = this.renderer.listen(window, 'resize', _.debounce(() => {
      this.problemSection.resize();
      this.problemMapSection.resize();
      this.dataSection.resize();
      this.solutionSection.resize();
      this.outcomesSection.resize();
      this.calculateAndSetScale();
    }, 700));
  }

  private calculateAndSetScale() {
    const problem = d3.select('#problem').node(0).getBoundingClientRect();
    const data = d3.select('#data').node(0).getBoundingClientRect();
    const solution = d3.select('#solution').node(0).getBoundingClientRect();
    const outcomes = d3.select('#outcomes').node(0).getBoundingClientRect();
    const main = d3.select('#scroll-container').node(0).getBoundingClientRect();
    const problemWallSection = d3.select('#banner_id').node(0).getBoundingClientRect();
    const problemMapSection = d3.select('#problem_map_id').node(0).getBoundingClientRect();
    this.scrollSection = main;
    function checkLeavePoint(bottom) {
      let result = bottom - main.height;
      if (result < 0) {
        result = 0;
      }
      return result;
    }
    const size: any = {
      problemWall: {
        top: 0,
        bottom: problemWallSection.height,
        scrollStart: 0,
        scrollLeave: checkLeavePoint(problemWallSection.height),
        scrollEnd: problemWallSection.height
      }
    };
    size.problemWorldMap = {
      top: size.problemWall.bottom + 1,
      bottom: size.problemWall.bottom + problemMapSection.height,
      scrollStart: size.problemWall.scrollLeave + 1,
      scrollLeave: size.problemWall.bottom + problemMapSection.height - main.height,
      scrollEnd: size.problemWall.bottom + problemMapSection.height
    };
    size.problem = {
      top: 0,
      bottom: problem.height,
      scrollStart: 0,
      scrollLeave: problem.height - main.height,
      scrollEnd: problem.height
    };
    size.data = {
      top: size.problem.bottom + 1,
      bottom: size.problem.bottom + data.height,
      scrollStart: size.problem.bottom + 1,
      scrollLeave: (size.problem.bottom + data.height - main.height),
      scrollEnd: (size.problem.bottom + data.height)
    };
    size.solution = {
      top: size.data.bottom + 1,
      bottom: size.data.bottom + solution.height,
      scrollStart: size.data.bottom + 1,
      scrollLeave: (size.data.bottom + solution.height - main.height),
      scrollEnd: (size.data.bottom + solution.height)
    };
    size.outcomes = {
      top: size.solution.bottom + 1,
      bottom: size.solution.bottom + outcomes.height,
      scrollStart: size.solution.bottom + 1,
      scrollLeave: (size.solution.bottom + outcomes.height - main.height),
      scrollEnd: (size.solution.bottom + outcomes.height - main.height)
    };
    this.sectionDimention = size;
    // START: Set domain range or scale for animation
    this.problemSection.setProblemScaleDomain(size.problemWall.scrollStart, size.problemWall.scrollEnd);
    this.problemMapSection.setScaleDomain(
      size.problemWorldMap.scrollStart,
      size.problemWorldMap.scrollLeave,
      size.problem.scrollEnd);
    this.dataSection.setDataScaleDomain(
      size.problem.scrollLeave + ((size.problem.scrollEnd - size.problem.scrollLeave) / 2),
      size.data.scrollLeave + (size.data.scrollEnd - size.data.scrollLeave) / 2,
      size.data.scrollEnd);
    this.solutionSection.setSolutionScaleDomain(
      (size.data.scrollLeave + (size.data.scrollEnd - size.data.scrollLeave) / 2),
      (size.solution.scrollLeave + (size.solution.scrollEnd - size.solution.scrollLeave) / 2)
    );
    this.outcomesSection.setScaleDomain(
      (size.solution.scrollLeave + (size.solution.scrollEnd - size.solution.scrollLeave) / 2),
      size.outcomes.scrollLeave - 100);
    // END: Set domain range or scale for animation
  }

  private responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.style('width'), 10);
    const height = parseInt(svg.style('height'), 10);
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'), 10);
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height
      };
    };
    svg.attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(resize);

    return resize;
  }

  ngOnInit() {
    this.renderStory();
  }

  private renderStory() {
    let _scrollTop = 0;
    let _newScrollTop = 0;

    d3.select('#scroll-container').on('scroll.scroller', function () {
      _newScrollTop = d3.select(this).node(0).scrollTop;
    });

    // START Init Problem section SVG
    this.problemSection = this.initProblemSection('banner_id', jsonData);
    this.problemMapSection = this.initDataWorldChart('problem_map_id', WorldMapData);
    // END: Init Problem section SVG

    // START Init Data section SVG
    this.dataSection = this.initDataSection('data');
    // END: Init Data section SVG

    // START Init Problem section SVG
    this.solutionSection = this.initSolutionSection('solution', jsonData);
    // END: Init Problem section SVG

    // START Init Data section SVG
    this.outcomesSection = this.initDataWorldChart('outcomes_svg_id', WorldMapData, true);
    // END: Init Data section SVG

    /**
     * Render function
     * This function is recurcive function and called to show animation on every scroll change.
     */
    const render = () => {
      this.problemSection.animateSecondBigCircle();
      // this.solutionSection.rotateGears();
      if (_scrollTop !== _newScrollTop) {
        _scrollTop = _newScrollTop;

        if (this.sectionDimention.problem.scrollStart <= _scrollTop
          && _scrollTop < this.sectionDimention.problem.scrollEnd + 100) {
          this.problemSection.startProblemAnimation(_scrollTop);
          this.problemMapSection.startAnimation(_scrollTop);
        }
        if ((this.sectionDimention.data.scrollStart - this.scrollSection.height) < _scrollTop
          && _scrollTop < (this.sectionDimention.data.scrollEnd + 100)) {
          this.dataSection.startDataAnimation(_scrollTop);

        }
        if ((this.sectionDimention.solution.scrollStart - this.scrollSection.height) < _scrollTop
          && _scrollTop <= (this.sectionDimention.solution.scrollEnd + 100)) {
          this.solutionSection.startSolutionAnimation(_scrollTop);
        }
        if ((this.sectionDimention.outcomes.scrollStart - this.scrollSection.height) < _scrollTop) {
          this.outcomesSection.startAnimation(_scrollTop);
        }
      }
      window.cancelAnimationFrame(this.unsubscribeAnimationFrame);
      this.unsubscribeAnimationFrame = window.requestAnimationFrame(render);
    };
    this.unsubscribeAnimationFrame = window.requestAnimationFrame(render);
  }

  /**
   * Initiate world map creation on specific container
   *
   * @param containerId - Parent container ID
   * @param world - WoldJson Data
   * @param width - Width of the SVG
   * @param isFinal - Is final output SVG
   */
  private initDataWorldChart(containerId, world, isFinal = false) {
    const container = d3.select('#' + containerId);
    const WIDTH = container.node().clientWidth || container.node().offsetWidth;
    const HEIGHT_OFFSET = 710 / 1280;
    const HEIGHT = WIDTH * HEIGHT_OFFSET;
    const MAP_SCALE_OFFSET = 177 / 1280;
    const scale = WIDTH * MAP_SCALE_OFFSET;
    const projection = d3.geo.mercator()
      .center([0, 20])
      .scale(scale)
      .translate([WIDTH / 2, (HEIGHT / 2)])
      .precision(.1);

    const path = d3.geo.path()
      .projection(projection);

    const svg = container.append('svg')
      .attr({
        id: 'svg-problem-map',
        width: WIDTH,
        height: HEIGHT
      });
    const onResize = this.responsivefy(svg);
    const worldMapContainer = svg.append('g');

    worldMapContainer.append('path')
      .datum(topojson.feature(world, world.objects.land))
      .attr('d', path)
      .attr('class', 'map-chart-path land-boundary');

    worldMapContainer.append('path')
      .datum(topojson.mesh(world, world.objects.countries, (a, b) => a !== b))
      .attr('d', path)
      .attr('class', 'map-chart-path country-boundary');
    let initSalesTeam;
    if (!isFinal) {
      initSalesTeam = ((salesData) => {
        return d3.select('#' + containerId).selectAll('.sales-team')
          .data(salesData, (d, i) => {
            if (!d.cx || !d.cy) {
              d.cx = projection([d.lat, d.lng])[0];
              d.cy = projection([d.lat, d.lng])[1];
            }
            return i;
          })
          .enter()
          .append('div')
          .text(d => d.teamName)
          .attr({
            id: (d) => d.id,
            class: (d) => 'sales-team ' + d.id
          })
          .style({
            opacity: 0,
            left: (d) => (d.cx || 0) + 'px',
            top: (d) => (d.cy || 0) + 'px'
          });
      })(jsonData.data_salesTeam_coordinates);
    }
    const initZoneDots = ((dotData) => {
      const circleAttr = (d) => {
        const result: any = {
          class: 'zone-dot',
          cx: d.xInitPosition,
          cy: d.yInitPosition,
          r: 5,
          fill: '#51cfba'
        };
        if (isFinal) {
          result.fill = d.color;
        }
        return result;
      };
      return worldMapContainer.selectAll('.zone-dot')
        .data(dotData, (d, i) => {
          if (!d.cx || !d.cy) {
            d.cx = projection([d.lat, d.lng])[0];
            d.cy = projection([d.lat, d.lng])[1];
          }
          d.yInitPosition = getRandomIntInclusive(-50, -10);
          d.xInitPosition = getRandomIntInclusive(d.cx - 100, d.cx + 100);
          if (!isFinal) {
            if (((WIDTH * .65) < d.xInitPosition) || (WIDTH * 0.1046875 > d.xInitPosition)) {
              d.xInitPosition = getRandomIntInclusive((WIDTH * 0.1046875), (WIDTH * .65));
            }
          }
          return i;
        })
        .enter()
        .append('g')
        .append('circle')
        .each(function (d) {
          d3.select(this)
            .attr(circleAttr(d));
          if (isFinal && d.tooltip) {
            d3.select('#' + containerId)
              .append('div')
              .text(d.tooltip)
              .attr({
                id: d.tooltipId,
                class: 'tooltip-container'
              })
              .style({
                opacity: 0,
                left: () => {
                  d.tipWidth = (d3.select('#' + d.tooltipId).node().offsetWidth / 2) || 150;
                  return (d.cx - d.tipWidth) + 'px';
                },
                top: () => {
                  d.tipHeight = (d3.select('#' + d.tooltipId).node().offsetHeight || 80) + 20;
                  return (d.cy - d.tipHeight) + 'px';
                },
              });
          }
        });
    })(JSON.parse(JSON.stringify(jsonData.data_cities_on_globe)));
    let salesTeamOpacityScale;
    let lastSalesTeamOpacity = 0;
    let animationEnd;
    let outAnimationEnd;
    return {
      resize() {
        const ratio: any = onResize();
        if (isFinal) {
          initZoneDots
            .each(function (d) {
              if (d.tooltip) {
                d3.select('#' + d.tooltipId)
                  .style({
                    left: () => {
                      return ((d.cx * ratio.widthAspect) - d.tipWidth) + 'px';
                    },
                    top: () => {
                      return ((d.cy * ratio.heightAspect) - d.tipHeight) + 'px';
                    },
                  });
              }
            });
        } else {
          initSalesTeam
            .style({
              left: (d) => ((d.cx * ratio.widthAspect) || 0) + 'px',
              top: (d) => ((d.cy * ratio.heightAspect) || 0) + 'px'
            });
        }
      },
      startAnimation(scrollTop) {
        initZoneDots
          .attr({
            cy: (d) => {
              const result = d.yPositionScale(scrollTop);
              if (isFinal && d.tooltip) {
                if (result > d.cy - 100) {
                  d3.select('#' + d.tooltipId)
                    .style('opacity', 1);
                } else {
                  d3.select('#' + d.tooltipId)
                    .style('opacity', 0);
                }
              }
              return result;
            },
            cx: (d) => d.xPositionScale(scrollTop)
          });
        if (!isFinal) {
          if (animationEnd < scrollTop && scrollTop < outAnimationEnd) {
            initZoneDots
              .attr({
                cx: function(d) {
                  if (d.xOutOpacityScale) {
                    d3.select(this).style('opacity', d.xOutOpacityScale(scrollTop));
                  }
                  return d.xOutPosScale(scrollTop);
                },
                cy: (d) => d.yOutPosScale(scrollTop)
              });
          } else if (outAnimationEnd < scrollTop) {
            initZoneDots
              .attr({
                cy: (d) => d.yFinalOutScale(scrollTop),
                cx: (d) => d.xOutPosScale(scrollTop)
              });
          }
          const newOpacityVal = salesTeamOpacityScale(scrollTop);
          if (initSalesTeam && newOpacityVal !== lastSalesTeamOpacity) {
            lastSalesTeamOpacity = newOpacityVal;
            initSalesTeam
              .style('opacity', newOpacityVal);
          }
        }
      },
      setScaleDomain: (start, end, finalEnd = end) => {
        const animationStart = start;
        animationEnd = start + 200;
        outAnimationEnd = end + ((finalEnd - end) / 2);
        if (!isFinal) {
          salesTeamOpacityScale = d3.scale.linear().domain([animationStart, animationStart + 1]).range([0, 1]).clamp(true);
        }
        initZoneDots
          .each((d, i) => {
            if (!isFinal) {
              const halfX = WIDTH / 2;
              const y = getRandomIntInclusive(50, 200);
              const finalXCalScale = d3.scale.linear().domain([80, 100]).range([1, 10]).clamp(true);
              let x = (halfX > d.cx) ? halfX - finalXCalScale(y) : halfX + finalXCalScale(y);
              if (i % 3 === 0) {
                x = (halfX > d.cx) ? halfX - getRandomIntInclusive(0, 5) : halfX + getRandomIntInclusive(0, 10);
              }
              if (i > 20 && i < 100) {
                d.xOutOpacityScale = d3.scale.linear().domain([end, outAnimationEnd]).range([1, 0]).clamp(true);
              }
              d.xOutPosScale = d3.scale.linear().domain([end, outAnimationEnd]).range([d.cx, x]).clamp(true);
              d.yOutPosScale = d3.scale.linear().domain([end, outAnimationEnd]).range([d.cy, HEIGHT - y]).clamp(true);
              d.yFinalOutScale = d3.scale.linear().domain([outAnimationEnd, outAnimationEnd + 100])
                                    .range([HEIGHT - y, HEIGHT + 300]).clamp(true);
              // d.xOutPosScale = d3.scale.linear().domain([end, finalEnd]).range([d.cx, WIDTH / 2]).clamp(true);
              // d.yOutPosScale = d3.scale.linear().domain([end, finalEnd]).range([d.cy, HEIGHT - 50]).clamp(true);
            }
            d.yPositionScale = d3.scale.linear().domain([animationStart, animationEnd]).range([d.yInitPosition, d.cy]).clamp(true);
            d.xPositionScale = d3.scale.linear().domain([animationStart, animationEnd]).range([d.xInitPosition, d.cx]).clamp(true);
          });
      },
    };
  }

  /**
   * Init data section on specific container.
   *
   * @param containerId - Parent Container ID
   */
  private initDataSection(containerId) {
    const container = d3.select('#' + containerId);
    const WIDTH = 1280;
    const HEIGHT = container.node().getBoundingClientRect().height;
    const arrayData = [];
    const lineData = [{ 'x': 0, 'y': 0 }, { 'x': 0, 'y': HEIGHT - 30 }];
    for (let i = 0; i < 10; i++) {
      const ar = (5 - i * 3);
      const r = (ar < 0) ? 0 : ar;
      arrayData.push({
        id: 'data_circle_' + i,
        actualRadius: ar,
        radius: r
      });
      if (i < 3) {
        let txt = '';
        switch (i) {
          case 0:
            txt = 'Demographics';
            break;
          case 1:
            txt = 'Firmographics';
            break;
          case 2:
            txt = 'Company characteristics';
            break;
        }
        arrayData[i].tipText = txt;
        arrayData[i].tipId = 'data_tooltip_' + i;
      }
    }
    const svg = container.append('svg')
      .attr('class', 'ms-data-svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT);

    const onResize = this.responsivefy(svg, true);

    const group = svg
      .append('g')
      .attr('transform', 'translate(' + ((WIDTH / 2)) + ',0)');

    const lineFunction = d3.svg.line()
      .x(d => d.x)
      .y(d => d.y)
      .interpolate('cardinal');

    const lineGraph = group.append('path')
      .attr('d', lineFunction(lineData))
      .attr('stroke-width', 1)
      .attr('fill', 'none');

    const dataCircles = svg.selectAll('.data-circle')
      .data(arrayData)
      .enter()
      .append('circle')
      .style('fill', '#52d2c1')
      .attr({
        'id': d => d.id,
        'class': 'data-circle',
        'r': d => (d.radius || 5)
      })
      .each(function (d) {
        if (d.tipId) {
          container.append('div')
            .text(d.tipText)
            .attr({
              id: d.tipId,
              class: 'data_tooltip'
            })
            .style({
              left: () => {
                return ((WIDTH / 2) || 0) + 'px';
              },
              opacity: 0
            });
        }
      });

    let pathScale: any;
    let opacityScale: any;
    let symoblDiffScale: any;
    let radiusScale: any;
    let animationStart;
    let animationEnd;
    let animationFinalEnd;
    let resizeRatio;
    const animationFunction = (path, _scrollTop, d, i) => {
      const scaleFn = pathScale;
      const length = path.node().getTotalLength();
      const r = d3.interpolate(0, length);
      const diffFactorForEachShapes = (scaleFn(_scrollTop) === length) ? 0 : (i * symoblDiffScale(_scrollTop));
      const point = path.node().getPointAtLength(r((scaleFn(_scrollTop) - diffFactorForEachShapes) / length));
      d3.select('#' + d.id)
        .attr({
          'r': (v) => {
            const calcActualR = v.actualRadius + radiusScale(_scrollTop);
            return (calcActualR >= 0) ? calcActualR : 0; // (v.radius + radiusScale(_scrollTop));
          },
          'transform': 'translate(' + ((WIDTH / 2)) + ', ' + point.y + ')'
        })
        .style('opacity', opacityScale(_scrollTop));
      const startTooltip = (animationStart + ((animationEnd - animationStart) / 2));
      if (startTooltip < _scrollTop && d.tipId) {
        d3.select('#' + d.tipId)
          .style({
            opacity: 1,
            left: () => {
              const updatedWidth = container.node().getBoundingClientRect().width;
              const calcActualR = d.actualRadius + radiusScale(_scrollTop);
              const finalR = (calcActualR >= 0) ? calcActualR : 0;
              return ((updatedWidth / 2) + finalR) + 'px';
            },
            top: () => {
              let top = point.y;
              if (resizeRatio) {
                top = top * resizeRatio.heightAspect;
              }
              return top + 'px';
            },
          });
      }
      if (startTooltip > _scrollTop || _scrollTop > animationEnd) {
        d3.select('#' + d.tipId)
          .style('opacity', 0);
      }
    };
    const that = this;
    return {
      resize() {
        resizeRatio = onResize();
      },
      startDataAnimation(scrollTop) {
        let newShowAnimationVal;
        if (animationStart < scrollTop) {
          newShowAnimationVal = true;
        } else {
          newShowAnimationVal = false;
        }
        if (newShowAnimationVal !== that.showDataAnimation) {
          that.showDataAnimation = newShowAnimationVal;
        }
        dataCircles.each((d, i) => {
          animationFunction(lineGraph, scrollTop, d, i);
        });
      },
      setDataScaleDomain: (start, end, finalEnd) => {
        animationStart = start;
        animationEnd = end;
        animationFinalEnd = finalEnd;
        pathScale = d3.scale.linear().domain([animationStart, animationEnd]).range([0, HEIGHT + ((finalEnd - end) / 2)]).clamp(true);
        opacityScale = d3.scale.linear().domain([animationStart, animationFinalEnd]).range([HEIGHT, 0]).clamp(true);
        symoblDiffScale = d3.scale.linear().domain([animationEnd, animationFinalEnd]).range([100, 60]).clamp(true);
        radiusScale = d3.scale.linear().domain([animationStart, animationEnd]).range([0, 25]).clamp(true);
      }
    };
  }

  private initSolutionSection(containerId, jData) {
    const container = d3.select('#' + containerId);
    const width = container.node().getBoundingClientRect().width;
    const height = 1250;
    const x = Math.sin(2 * Math.PI / 3);
    const y = Math.cos(2 * Math.PI / 3);

    const svg = container.append('svg')
      .attr('width', width)
      .attr('height', height);
    const resize = this.responsivefy(svg);
    const clusterGroupArr = [];
    const clustersGroup = svg.append('g')
      .attr({
        'id': 'clusters-group',
        'class': 'clusters-group',
        'transform': 'translate(' + 0 + ',' + 300 + ')'
      })
      .style('opacity', 0);

    for (let i = 0; i < 3; i++) {
      clusterGroupArr.push(drawCircleGroup(i));
    }

    // function for draw circle group
    function drawCircleGroup(parentCircleId) {
      const circleR = 5;
      const yPosition = parentCircleId === 1 ? 50 : -30;
      const xPosition = parentCircleId === 0 ? -300 : parentCircleId === 1 ? 0 : 300;
      const circleGroup = clustersGroup.append('g')
        .attr('transform', 'translate(' + xPosition + ',' + yPosition + ')')
        .attr('class', 'cluster-group');

      const force = d3.layout.force()
        .size([width, height])
        .charge(-5)
        .on('tick', tick);
      const colorCircle = ['#ff8250', '#cdc50d', '#39b3ff'];
      let node = circleGroup.selectAll('.node');
      const drawInit = function (graph) {
        node = node.data(graph.nodes, function (d) {
          return d.id;
        })
          .enter()
          .append('circle')
          .each(function (d) {
            d3.select(this)
              .attr({
                cx: d.cx,
                cy: d.cy,
                r: circleR,
                // class: 'solutionCirclePosition',
              });
          })
          .attr('class', 'node')
          .attr('id', function (d, i) {
            return 'cluster_id_' + parentCircleId + '_' + d.id;
          })
          .attr('r', 5)
          .style('fill', function (d, i) {
            return colorCircle[parentCircleId];
          });
        force
          .nodes(graph.nodes)
          .start();
      };

      const update = function (graph, isForward) {
        const centerCircleNode = d3.select('#cluster_id_' + parentCircleId + '_' + 0);
        const centerNodeCoordinates: any = {};
        const centerCircleNodeText = ['Hot', 'Warm', 'Cold'];

        centerCircleNode
          .transition()
          .duration(50)
          .attr('r', function (d) {
            centerNodeCoordinates.x = d.px;
            centerNodeCoordinates.y = d.py;
            return (isForward) ? 35 : circleR;
          });
        if (isForward) {
          circleGroup.append('text')
            .attr('text-anchor', 'middle')
            .attr('x', centerNodeCoordinates.x)
            .attr('y', centerNodeCoordinates.y + 5)
            .attr('class', 'center_node_text')
            .attr('id', 'center_node_text_' + parentCircleId)
            .style({
              'fill': 'white',
              'font-family': 'DINProRegular',
              'font-size': '20px',
              'font-weight': 'bold'
            })
            .transition()
            .duration(300)
            .text(centerCircleNodeText[parentCircleId]);
        } else {
          circleGroup.selectAll('text').remove();
        }
        force
          .charge(function (d, i) {
            return i ? -9 : -160;
          })
          .nodes(graph.nodes)
          .start();
        // setTimeout(() => {
        //   force.stop();
        // });
      };


      function tick() {
        node.attr({
          'cx': (d) => d.x,
          'cy': (d) => d.y
        });
      }
      // tslint:disable-next-line: no-shadowed-variable
      const arrayData = [];
      for (let i = 0; i < 90; i++) {
        const d_obj: any = { id: i, name: i, group: 1, size: 10 };
        if (!i) {
          d_obj.x = width / 2;
          d_obj.y = height / 2;
          d_obj.fixed = true;
        }
        arrayData.push(d_obj);
      }
      const data = { 'nodes': arrayData };
      drawInit(data);

      return {
        update: (forward) => { update(data, forward); },
        stop: () => { force.stop(); }
      };
    }
    const solutionCircle = d3.selectAll('.node');
    function drawSmoothGear({ teeth, radius, toothRadius, holeRadius, annulus }: any) {
      const n = teeth;
      const r2 = Math.abs(radius);
      let r0 = r2 - toothRadius;
      let r1 = r2;
      let r3 = holeRadius;
      if (annulus) { r3 = r0, r0 = r1, r1 = r3, r3 = r2 + toothRadius * 3; }
      const da = Math.PI / n;
      let a0 = -Math.PI / 2 + (annulus ? Math.PI / n : 0);
      const path = ['M', r0 * Math.cos(a0), ',', r0 * Math.sin(a0)];
      let i = -1;
      while (++i < n) {
        path.push(
          'A', r0, ',', r0, ' 0 0,1 ', r0 * Math.cos(a0 += da), ',', r0 * Math.sin(a0),
          'L', r2 * Math.cos(a0), ',', r2 * Math.sin(a0),
          'L', r1 * Math.cos(a0 += da / 3), ',', r1 * Math.sin(a0),
          'A', r1, ',', r1, ' 0 0,1 ', r1 * Math.cos(a0 += da / 3), ',', r1 * Math.sin(a0),
          'L', r2 * Math.cos(a0 += da / 3), ',', r2 * Math.sin(a0),
          'L', r0 * Math.cos(a0), ',', r0 * Math.sin(a0)
        );
      }
      path.push('M0,', -r3, 'A', r3, ',', r3, ' 0 0,0 0,', r3, 'A', r3, ',', r3, ' 0 0,0 0,', -r3, 'Z');
      return path.join('');
    }
    function drawSharpGear({ teeth, radius, holeRadius }: any) {
      // Four points to draw a complete gear tooth:
      // Two points to draw an 'outer tooth',
      // then two points for an 'inner tooth' and so forth...
      function toothPointIndex(point) {
        return point % 4;
      }
      const pointDummies = d3.range(teeth * 4);

      const radOuter = radius;
      let radInner = radOuter - 1.7 * (radius * 2 * Math.PI / pointDummies.length);
      // When few teeth (< ~10)
      radInner = Math.max(radInner, radOuter / 2);

      const angleDelta = (2 * Math.PI) / (pointDummies.length);
      // Widen the 'inner teeth' so they are as wide as the 'outer teeth':
      const angleWidener = angleDelta * ((radOuter / radInner) - 1) / 2;

      // tslint:disable-next-line: no-shadowed-variable
      const lineFunction = d3.svg.line.radial()
        .radius((d, i) => {
          const outerTooth = ([0, 1].indexOf(toothPointIndex(i)) >= 0);
          return outerTooth ? radOuter : radInner;
        })
        .angle((d, i) => {
          let angle = i * angleDelta;
          if (toothPointIndex(i) === 2) {
            angle -= angleWidener;
          } else if (toothPointIndex(i) === 3) {
            angle += angleWidener;
          }
          return angle;
        });
      const r3 = holeRadius + '';
      let pathData = (lineFunction(pointDummies) + '')
        .concat('M0,', (-r3 + ''), 'A', r3, ',', r3, ' 0 0,0 0,', r3, 'A', r3, ',', r3, ' 0 0,0 0,', (-r3 + ''), 'Z');
      pathData = pathData
        // - Small exponents equal 0 (e.g. 1.234e-56 -> 0):
        .replace(/(\d*\.)?\d+e\-\d+/g, '0')
        // - Use only one decimal (e.g. 1.234 -> 1.2):
        .replace(/(\.\d)(\d+)/g, '$1')
        // - Space between path operations for readability (e.g. M1,2L3,4 -> M1,2 L3,4)):
        .replace(/(\d)([a-zA-Z])/g, '$1 $2');

      return pathData;
    }
    function drawArc(innerRadius, outerRadius, startAngle, endAngle) {
      return d3.svg.arc()
        .innerRadius(innerRadius)
        .outerRadius(outerRadius)
        .startAngle(startAngle)
        .endAngle(endAngle);
    }
    let gearsAssembly;
    function drawNewGears() {
      const textLineFunction = d3.svg.line()
        .x(d => d.x)
        .y(d => d.y)
        .interpolate('basic');
      gearsAssembly = svg.append('g')
        .attr({
          'id': 'gears-group',
          'class': 'gears-group',
          'transform': 'translate(' + width / 2 + ',' + 550 + ')'
        });
      const firstGearGroup = gearsAssembly.append('g')
        .attr({
          'id': 'first-gear',
          'class': 'first-gear',
          'transform': 'translate(' + 0 + ',' + 160 + ')'
        })
        .datum({ teeth: 15, radius: 80, holeRadius: 50 });
      firstGearGroup.append('path')
        .attr({
          class: 'gear rotate-anti-clockwise',
          d: drawSharpGear,
          stroke: 'none',
          'stroke-width': 0,
          'fill': '#5183a6'
        });
      firstGearGroup.append('path')
        .attr({
          d: drawArc(20, 30, 0, (Math.PI * 2)),
          fill: '#5183a6'
        });
      const firstLineGrp = firstGearGroup.append('g')
        .attr('transform', 'translate(' + 160 + ',' + 0 + ')');
      firstLineGrp.append('path')
        .attr('d', textLineFunction([
          { 'x': 0, 'y': 0 },
          { 'x': 30, 'y': -30 },
          { 'x': 150, 'y': -30 },
          { 'x': 180, 'y': -60 }]))
        .attr('stroke', '#5183a6')
        .attr('stroke-width', 1)
        .attr('fill', 'none');
      firstLineGrp.append('circle')
        .attr('cx', -2)
        .attr('cy', 3)
        .attr('r', 4)
        .attr('stroke', '#5183a6')
        .attr('stroke-width', 1)
        .attr('fill', 'none');

      firstLineGrp.append('circle')
        .attr('cx', 182)
        .attr('cy', -63)
        .attr('r', 4)
        .attr('stroke', '#5183a6')
        .attr('stroke-width', 1)
        .attr('fill', 'none');

      firstLineGrp.append('text')
        .attr('transform', 'translate(' + 120 + ',' + -85 + ')')
        .text('Objective Optimizer')
        .attr('x', 0)
        .attr('y', 0)
        .attr('font-size', '16px');
      const secondGearGroup = gearsAssembly.append('g')
        .attr({
          'id': 'second-gear',
          'class': 'second-gear',
          'transform': 'translate(' + 160 + ',' + -50 + ')'
        })
        .datum({ teeth: 15, radius: 80, holeRadius: 50, toothRadius: 10 });
      secondGearGroup.append('path')
        .attr({
          class: 'gear rotate-anti-clockwise',
          d: drawSmoothGear,
          stroke: 'none',
          'stroke-width': 0,
          'fill': '#a0ba14'
        });
      secondGearGroup.append('path')
        .attr({
          d: drawArc(20, 30, 0, (Math.PI * 2)),
          fill: '#a0ba14'
        });
      const secondTextGrp = secondGearGroup.append('g')
        .attr('transform', 'translate(' + 120 + ',' + -20 + ')');
      secondTextGrp.append('path')
        .attr('d', textLineFunction([
          { 'x': 0, 'y': 0 },
          { 'x': 30, 'y': -30 },
          { 'x': 150, 'y': -30 },
          { 'x': 180, 'y': -60 }]))
        .attr('stroke', '#a0ba14')
        .attr('stroke-width', 1)
        .attr('fill', 'none');
      secondTextGrp.append('circle')
        .attr('cx', -2)
        .attr('cy', 3)
        .attr('r', 4)
        .attr('stroke', '#a0ba14')
        .attr('stroke-width', 1)
        .attr('fill', 'none');

      secondTextGrp.append('circle')
        .attr('cx', 182)
        .attr('cy', -63)
        .attr('r', 4)
        .attr('stroke', '#a0ba14')
        .attr('stroke-width', 1)
        .attr('fill', 'none');

      secondTextGrp.append('text')
        .attr('transform', 'translate(' + 120 + ',' + -85 + ')')
        .text('Lead Scoring Model')
        .attr('x', 0)
        .attr('y', 0)
        .attr('font-size', '16px');

      const thirdGearGroup = gearsAssembly.append('g')
        .attr({
          'id': 'third-gear',
          'class': 'third-gear',
          'transform': 'translate(' + -120 + ',' + -50 + ')'
        })
        .datum({ teeth: 10, radius: 60, holeRadius: 35, toothRadius: 10 });
      thirdGearGroup.append('path')
        .attr({
          class: 'gear rotate-clockwise',
          d: drawSmoothGear,
          stroke: 'none',
          'stroke-width': 0,
          'fill': '#bd8e5f'
        });
      thirdGearGroup.append('path')
        .attr({
          d: drawArc(15, 20, 0, (Math.PI * 2)),
          fill: '#bd8e5f'
        });
      const thirdTextGrp = thirdGearGroup.append('g')
        .attr('transform', 'translate(' + -200 + ',' + 140 + ')');
      thirdTextGrp.append('path')
        .attr('d', textLineFunction([
          { 'x': 0, 'y': 0 },
          { 'x': 30, 'y': -30 },
          { 'x': 150, 'y': -30 },
          { 'x': 180, 'y': -60 }]))
        .attr('stroke', '#bd8e5f')
        .attr('stroke-width', 1)
        .attr('fill', 'none');
      thirdTextGrp.append('circle')
        .attr('cx', -2)
        .attr('cy', 3)
        .attr('r', 4)
        .attr('stroke', '#bd8e5f')
        .attr('stroke-width', 1)
        .attr('fill', 'none');

      thirdTextGrp.append('circle')
        .attr('cx', 182)
        .attr('cy', -63)
        .attr('r', 4)
        .attr('stroke', '#bd8e5f')
        .attr('stroke-width', 1)
        .attr('fill', 'none');

      thirdTextGrp.append('text')
        .attr('transform', 'translate(' + -60 + ',' + 40 + ')')
        .text('Deal Size Model')
        .attr('x', 0)
        .attr('y', 0)
        .attr('font-size', '16px');
    }
    drawNewGears();
    // Code for All paths and it's animation
    let pathScale;
    let rainScale;
    const curvedPath = svg.append('g')
      .attr('transform', 'translate(' + ((width / 2)) + ',0)');

    const lineFunction = d3.svg.line()
      .x(d => d.x)
      .y(d => d.y)
      .interpolate('cardinal');
    function drawCurvedPath(group, data) {
      return group.append('path')
        .attr({
          'd': data,
          'stroke': 'none',
          'stroke-width': 2,
          'fill': 'none'
        });
    }
    const lineGraph = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.lineData));
    const lineFirstData = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.lineFirstData));
    const lineSecondData = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.lineSecondData));
    const lineThirdData = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.lineThirdData));
    const secondlineGraph = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.secondLineData));
    const thirdPath = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.thirdPathData));
    const fourthPath = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.fourthPathData));
    const fifthPath = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.fifthPathData));
    const sixPath = drawCurvedPath(curvedPath, lineFunction(jData.data_solution.sixPathData));

    const symbolPathArr = ['lineGraph', 'secondlineGraph', 'lineFirstData', 'lineSecondData', 'lineThirdData'];
    const symbolArr = [
      { shape: 'hexagoan', color: '#19A5FC' },
      { shape: 'square', color: '#FF3AAE' },
      { shape: 'star', color: '#AC4AFF' }
    ];
    const circlePathArr = ['thirdPath', 'fourthPath', 'fifthPath', 'sixPath'];
    const arrayData = [];
    const circleArrayData = [];
    for (let i = 0; i < 300; i++) {
      const symbolIndex = Math.floor(Math.random() * symbolArr.length);
      arrayData.push({
        id: 'data_shape_' + i,
        type: symbolPathArr[i % 5],
        symbol: symbolArr[symbolIndex].shape,
        fill: symbolArr[symbolIndex].color
      });
      circleArrayData.push({
        id: 'data_shape_circle_' + i,
        type: circlePathArr[i % 4]
      });
    }

    const circleShapeGroup = curvedPath.append('g')
      .attr('class', 'circle-shapes-group')
      .style('opacity', 0);
    const circleShapes = circleShapeGroup.selectAll('.circleSymbol')
      .data(circleArrayData)
      .enter()
      .append('path')
      .attr({
        'd': (d) => d3.svg.symbol().type('circle').size(100)(),
        'class': 'circleSymbol',
        'id': d => d.id
      })
      .style('fill', (d) => d.type === 'thirdPath' ? '#ff8250' : d.type === 'fourthPath' ? '#39b3ff' : '#cdc50d');

    const shapeGroup = curvedPath.append('g')
      .attr('class', 'shapes-group')
      .style('opacity', 0);
    const allShapes = shapeGroup.selectAll('.symbol')
      .data(arrayData)
      .enter()
      .append('path')
      .attr({
        'd': (d) => (this.shapesService.getShapeCoordinates(d.symbol, 150)),
        'class': 'symbol',
        'id': d => d.id
      })
      .style({
        'fill': (d) => d.fill,
        'opacity': 0
      });
    function shapeAnimation(_scrollTop, d, i) {
      let path;
      let scaleFn = pathScale.firstPahtScale;
      switch (d.type) {
        case 'lineGraph':
          path = lineGraph;
          break;
        case 'lineFirstData':
          path = lineFirstData;
          scaleFn = pathScale.lineFirstData;
          break;
        case 'lineSecondData':
          path = lineSecondData;
          scaleFn = pathScale.lineSecondData;
          break;
        case 'lineThirdData':
          path = lineThirdData;
          scaleFn = pathScale.lineThirdData;
          break;
        default:
          path = secondlineGraph;
          scaleFn = pathScale.secondPahtScale;
      }
      const length = path.node().getTotalLength();
      const r = d3.interpolate(0, length);
      const diffFactorForEachShapes = (i * pathScale.shapeDiffScale(_scrollTop)); // (scaleFn(_scrollTop) === length) ? 0 : (i * 10);
      const point = path.node().getPointAtLength(r((scaleFn(_scrollTop) - diffFactorForEachShapes) / length));
      d3.select('#' + d.id)
        .attr('transform', 'translate(' + point.x + ',' + point.y + ')')
        .style('opacity', pathScale.opacityScale(_scrollTop));
    }

    function circlePathTween(_scrollTop, d, i) {
      let path = thirdPath;
      let scaleFn = rainScale.thirdPahtScale;
      switch (d.type) {
        case 'fourthPath':
          path = fourthPath;
          scaleFn = rainScale.fourthPahtScale;
          break;
        case 'fifthPath':
          path = fifthPath;
          scaleFn = rainScale.fifthPahtScale;
          break;
        case 'sixPath':
          path = sixPath;
          scaleFn = rainScale.sixPahtScale;
      }
      const length = path.node().getTotalLength();
      const r = d3.interpolate(0, length);
      const diffFactorForEachShapes = (scaleFn(_scrollTop) === length) ? 0 : (i * 10);
      const point = path.node().getPointAtLength(r((scaleFn(_scrollTop) - diffFactorForEachShapes) / length));
      d3.select('#' + d.id)
        .attr('transform', 'translate(' + point.x + ',' + point.y + ')')
        .style('opacity', rainScale.opacityCircleScale(_scrollTop));
    }
    // let clusterCircleOpacityScale;
    let yPosScale;
    let xPosScale;
    let lastVal = 0;
    let lastSymbolOpacity = 0;
    let lastCircleOpacity = 0;
    let circleStart = 0;
    let symbolStart = 0;
    // let lastCircleRainValue = false;
    let circleRainStart;
    return {
      resize,
      startSolutionAnimation(scrollTop) {
        let newSymbolOpacity;
        let newCircleSymbolOpacity;
        // This condition is to check and set symbols (Features) group opacity.
        if (scrollTop > symbolStart) {
          newSymbolOpacity = 1;
        } else {
          newSymbolOpacity = 0;
        }
        if (lastSymbolOpacity !== newSymbolOpacity) {
          lastSymbolOpacity = newSymbolOpacity;
          shapeGroup
            .style('opacity', newSymbolOpacity);
        }
        // This condition is to check and set circles (Processed features) group opacity.
        if (scrollTop > circleStart) {
          newCircleSymbolOpacity = 1;
        } else {
          newCircleSymbolOpacity = 0;
        }
        if (lastCircleOpacity !== newCircleSymbolOpacity) {
          lastCircleOpacity = newCircleSymbolOpacity;
          circleShapeGroup
            .style('opacity', newCircleSymbolOpacity);
        }
        if (lastSymbolOpacity === 1) {
          allShapes
            .each(function (d, i) {
              shapeAnimation(scrollTop, d, i);
            });
        }
        if (lastCircleOpacity === 1) {
          circleShapes
            .each(function (d, i) {
              circlePathTween(scrollTop, d, i);
            });
        }
        if (rainScale.groupOpacityScale(scrollTop) !== lastVal) {
          lastVal = rainScale.groupOpacityScale(scrollTop);
          clustersGroup
            .style('opacity', lastVal);
          clusterGroupArr.forEach(v => { v.update(!!lastVal); });
        }
        if (scrollTop > circleRainStart) {
          clusterGroupArr.forEach(v => { v.stop(); });
        }
        solutionCircle
          .filter((d, i) => !d.fixed)
          .attr('cy', (d) => ((yPosScale) ? yPosScale(d.py)(scrollTop) : d.py))
          .attr('cx', (d) => (((xPosScale) ? xPosScale(d.px)(scrollTop) : d.px)));
        // .style('opacity', clusterCircleOpacityScale(scrollTop));
      },
      setSolutionScaleDomain: (start, end) => {
        symbolStart = start;
        // const symbolEnd = start + 550;
        const symbolEnd = start + 550;
        // circleStart = symbolEnd;
        circleStart = start + 550;
        const circleEnd = circleStart + 300;
        // const cluste = circleEnd - 50;
        const cluste = circleEnd - 100;
        circleRainStart = circleEnd + 50;
        pathScale = {
          firstPahtScale: d3.scale.linear().domain([start, symbolEnd]).range([0, lineGraph.node().getTotalLength() + 300]).clamp(true),
          secondPahtScale: d3.scale.linear().domain([start, symbolEnd]).range([0, secondlineGraph.node().getTotalLength() + 300])
            .clamp(true),
          lineFirstData: d3.scale.linear().domain([start, symbolEnd]).range([0, lineFirstData.node().getTotalLength() + 300]).clamp(true),
          lineSecondData: d3.scale.linear().domain([start, symbolEnd]).range([0, lineSecondData.node().getTotalLength() + 300]).clamp(true),
          lineThirdData: d3.scale.linear().domain([start, symbolEnd]).range([0, lineThirdData.node().getTotalLength() + 300]).clamp(true),
          opacityScale: d3.scale.linear().domain([start, symbolEnd + 300]).range([20, 0]).clamp(true),
          shapeDiffScale: d3.scale.linear().domain([start, symbolEnd + 300]).range([15, 10]).clamp(true)
        };
        const thirdLineLenght = thirdPath.node().getTotalLength();
        const fourthLineLenght = fourthPath.node().getTotalLength();
        const fifthLineLenght = thirdPath.node().getTotalLength();
        const sixLineLenght = fourthPath.node().getTotalLength();
        rainScale = {
          thirdPahtScale: d3.scale.linear().domain([circleStart, circleEnd]).range([0, thirdLineLenght + 250]).clamp(true),
          fourthPahtScale: d3.scale.linear().domain([circleStart, circleEnd]).range([0, fourthLineLenght + 250]).clamp(true),
          fifthPahtScale: d3.scale.linear().domain([circleStart, circleEnd]).range([0, fifthLineLenght + 250]).clamp(true),
          sixPahtScale: d3.scale.linear().domain([circleStart, circleEnd]).range([0, sixLineLenght + 250]).clamp(true),
          opacityCircleScale: d3.scale.linear().domain([circleStart, circleEnd]).range([20, 0]).clamp(true),
          groupOpacityScale: d3.scale.linear().domain([cluste, cluste + 1]).range([0, 1]).clamp(true),
        };
        yPosScale = (cy) => d3.scale.linear().domain([circleRainStart, end + 200]).range([cy, cy + 500]).clamp(true);
        xPosScale = (cx) => d3.scale.linear().domain([circleRainStart, end + 200])
          .range([cx, getRandomIntInclusive(cx - 500, cx + 500)]).clamp(true);
        // clusterCircleOpacityScale = d3.scale.linear().domain([circleRain, end + 200]).range([1, 1]).clamp(true);
      }
    };


  }

  private initProblemSection(containerId, jData) {
    const width = 1100;
    const height = 630;
    const data = jData.data_problem_circle_wall;
    const wallYposition = height - 132;
    const svg = d3.select('#' + containerId)
      .append('svg')
      .attr({
        'width': width,
        'height': height,
        'id': 'dots_svg'
      })
      .style({
        'left': '0px',
        'top': '0px'
      });

    const resize = this.responsivefy(svg, true);
    // function responsivefy(svg) {
    //   // get container + svg aspect ratio
    //   const container = d3.select(svg.node().parentNode);
    //   const width = parseInt(svg.style('width'), 10);
    //   const height = parseInt(svg.style('height'), 10);
    //   const aspect = width / height;
    //   svg.attr('viewBox', '0 0 ' + width + ' ' + height)
    //     .attr('perserveAspectRatio', 'xMinYMid')
    //     .call(resize);
    //   d3.select(window).on('resize.' + container.attr('id'), resize);
    //   function resize() {
    //     const targetWidth = parseInt(container.style('width'), 10);
    //     svg.attr('width', targetWidth);
    //     svg.attr('height', parseInt(container.style('height'), 10));
    //   }
    // }

    const g = svg.append('g')
      .attr('class', 'circle-wall-group')
      .attr('transform', 'translate(10,' + 485 + ')');
    const structureData: any = [];
    const hiddenData = [];
    data.forEach((v, ind) => {
      if (ind < 2) {
        hiddenData.push(v);
      }
      d3.range(v).forEach((d, i) => {
        const cx = i * 10.5 - (15 * ind);
        const cy = ind * 10.5;
        structureData.push({
          cx,
          cy,
          i: i,
          id: 'data_circle_wall_' + ind + '_' + i,
          opacity: (ind < 2) ? 0 : 1,
          isRemove: !!(Math.floor(Math.random() * 10) % 2),
          yFinalPos: getRandomIntInclusive(height, height + 10),
          xFinalPos: getRandomIntInclusive((cx - 250), (cx + 250))
        });
      });
    });
    const wallCircle = g.selectAll('.wallCircle')
      .data(structureData)
      .enter()
      .append('circle')
      .each(function (d) {
        d3.select(this)
          .attr({
            cx: d.cx,
            cy: d.cy,
            r: 5,
            id: d.id,
            //  (d) => {
            //   const mid = data[yPosition] / 2;
            //   if (d.i > (mid - 7) && d.i < (mid + 8) && yPosition < 8) {
            //     return 0;
            //   } else {
            //     return 5;
            //   }
            // },
            class: 'wallCircle',
            fill: '#ffffff'
          })
          .style('opacity', d.opacity);
      });
    let h_i = 1;
    let i = hiddenData[h_i] - 1;
    let noOfcircle = jData.data_problem_circle_wall[0];
    let mid = Math.floor(noOfcircle / 2);
    let val = mid / 3;
    let opacityInterval = setInterval(() => {

      d3.select('#data_circle_wall_' + h_i + '_' + (i - mid))
        .style('opacity', 1);

      d3.select('#data_circle_wall_' + h_i + '_' + (noOfcircle - (i - mid)))
        .style('opacity', 1);

      i--;
      if (i <= (mid - 3)) {
        i = 0;
      }


      // val--;
      if (i === 0 && h_i === 1) {
        h_i = 0;
        i = hiddenData[h_i] - 1;
      } else if (i === 0 && h_i !== 1) {
        clearInterval(opacityInterval);
      }
    }, 100);

    drawCircleGroup();
    // function for draw circle group
    function drawCircleGroup() {
      const parentCircleId = 0;
      // const svgCircle = d3.select('#banner_id')
      const circleGroup = svg.append('g')
        .attr('transform', 'translate(-250,180)')
        .attr('id', 'problemSectionCircleGroup');

      const categories = ['firstcircleGroupLineData', 'secondCircleGroupLineData', 'thirdCircleGroupLineData', 'fourthCircleGroupLineData'];
      const textLineFunction = d3.svg.line()
        .x(function (d) { return d.x; })
        .y(function (d) { return d.y; })
        .interpolate('bundle');

      const secondCircleGroupLineData = circleGroup.append('path')
        .attr('d', textLineFunction(jData.data_problem_path.secondCircleGroupLine))
        .attr('stroke', 'none')
        .attr('stroke-width', 2)
        .attr('fill', 'none');

      const firstcircleGroupLineData = circleGroup.append('path')
        .attr('d', textLineFunction(jData.data_problem_path.firstcircleGroupLine))
        .attr('stroke', 'none')
        .attr('stroke-width', 2)
        .attr('fill', 'none');

      const thirdCircleGroupLineData = circleGroup.append('path')
        .attr('d', textLineFunction(jData.data_problem_path.thirdCircleGroupLine))
        .attr('stroke', 'none')
        .attr('stroke-width', 2)
        .attr('fill', 'none');

      const fourthCircleGroupLineData = circleGroup.append('path')
        .attr('d', textLineFunction(jData.data_problem_path.fourthCircleGroupLine))
        .attr('stroke', 'none')
        .attr('stroke-width', 2)
        .attr('fill', 'none');


      const force = d3.layout.force()
        .size([250, 50])
        .charge(function (d) {
          return -7;
        })
        .on('tick', tick);

      const arrayDataCircle = [];
      for (let index = 0; index < 210; index++) {
        arrayDataCircle.push({
          id: index + 1,
          // type: categories[index % 4],
          type: categories[Math.floor(Math.random() * categories.length)]
        });
      }
      const symbolCircle = circleGroup.selectAll('.symbolCircle')
        .data(arrayDataCircle)
        .enter()
        .append('path')
        .attr('d', function (d) {
          return d3.svg.symbol().type('circle').size(70)();

        })
        // .style('stroke', 'black')
        .style('fill', 'white');
      repeatTransition();
      function repeatTransition() {
        symbolCircle.transition()
          .attr('class', 'symbolCircle')
          .delay(function (d, i) {
            return 120 * i;
          })
          .duration(2000)
          .ease('linear')
          .tween('pathTween', function (d, i) {
            if (d.type === 'firstcircleGroupLineData') {
              return pathTween(firstcircleGroupLineData, i);
            } else if (d.type === 'secondCircleGroupLineData') {
              return pathTween(secondCircleGroupLineData, i);
            } else if (d.type === 'thirdCircleGroupLineData') {
              return pathTween(thirdCircleGroupLineData, i);
            } else if (d.type === 'fourthCircleGroupLineData') {
              return pathTween(fourthCircleGroupLineData, i);
            }
          })
          .call(endAll, function () {
            repeatTransition();
          });
      }

      function endAll(transition, callback) {
        // tslint:disable-next-line: curly
        if (typeof callback !== 'function') throw new Error('Wrong callback in endall');
        if (transition.size() === 0) {
          callback();
        }
        let n = 0;
        transition
          .each(function () { ++n; })
          .each('end', function () {

            if (--n === 80) {
              callback.apply(this, arguments);
            }

          });
      }


      function pathTween(path, i) {
        const length = path.node().getTotalLength(); // Get the length of the path
        const r = d3.interpolate(0, length); // Set up interpolation from 0 to the path length
        return function (t) {
          const point = path.node().getPointAtLength(r(t)); // Get the next point along the path
          d3.select(this) // Select the circle
            .attr('transform', 'translate(' + point.x + ',' + point.y + ')'); // Set the cx
          if (t === 1) {
            // d3.select(this).remove();
          }
        };
      }

      let node = circleGroup.selectAll('.problemCircleCluster');
      const drawInit = function (graph) {
        node = node.data(graph.nodes, function (d) {
          return d.id;
        })
          .enter()
          .append('circle')
          .each(function (d) {
            d3.select(this)
              .attr({
                cx: d.cx,
                cy: d.cy,
                r: 5,
                class: 'problemCircleCluster',
              });
          })
          .attr('id', (d) => 'cluster' + parentCircleId + '_' + d.id)
          .attr('r', 5)
          .style('fill', 'white')
          .style('opacity', function (d, i) { return Math.random() * (1.5 - 0.02) + 0.02; });
        force
          .nodes(graph.nodes)
          .start();
      };

      // const update = function (graph) {
      //   const centerCircleNode = d3.select('#cluster' + parentCircleId + '_' + 0);
      //   const centerNodeCoordinates: any = {};

      //   centerCircleNode
      //     .transition()
      //     .duration(400)
      //     .attr('r',
      //       function (d) {
      //         centerNodeCoordinates.x = d.px;
      //         centerNodeCoordinates.y = d.py;
      //         return 40;
      //       });
      //   circleGroup.append('text')
      //     .attr('text-anchor', 'middle')
      //     .attr('x', centerNodeCoordinates.x)
      //     .attr('y', centerNodeCoordinates.y + 5)
      //     .attr('class', 'center_node_text')
      //     .attr('id', 'center_node_text_' + parentCircleId)
      //     .style({
      //       'fill': 'black',
      //       'font-family': 'DINProRegular',
      //       'font-size': '40px'
      //     })
      //     .transition()
      //     .duration(200)
      //     .text('USERS');

      //   force
      //     .charge(function (d, i) {
      //       return i ? -70 : -950;
      //     })
      //     .nodes(graph.nodes)
      //     .start();
      //   setTimeout(() => {
      //     force.stop();
      //   });
      // };

      function tick() {
        node.attr({
          'cx': (d) => d.x,
          'cy': (d) => d.y
        });
      }
      const arrayData = [];
      for (let i = 0; i < 350; i++) {
        const d_obj: any = { id: i, name: i, group: 1, size: 10 };
        if (!i) {
          d_obj.x = 120;
          d_obj.y = 50 / 2;
          d_obj.fixed = true;
        }
        arrayData.push(d_obj);
      }
      const data = { 'nodes': arrayData };
      drawInit(data);
      const middleDCircle = d3.select('#cluster0_0');
      middleDCircle
        .attr('x', 40)
        .attr('y', -100)
        .attr('r', 50)
        .style('opacity', 1);

      circleGroup.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', 103)
        .attr('y', 45)
        .attr('class', 'center_node_text huge')
        .attr('id', 'center_node_text_' + parentCircleId)
        .style({
          'fill': '#1f2228'
        })
        .text('50');

      circleGroup.append('text')
        .attr('text-anchor', 'middle')
        .attr('x', 145)
        .attr('y', 45)
        .attr('class', 'center_node_text')
        .attr('id', 'center_node_text_' + parentCircleId)
        .style({
          'fill': '#1f2228'
        })
        .text('M+');

      // update(data);
      // svg.call(responsivefy);
      // secondSvg.call(secondResponsivefy);

    }

    let opacityScale: any;
    return {
      resize,
      animateSecondBigCircle() {
        for (let index = 0; index < 250; index++) {
          const circleNode = d3.select('#cluster0_' + index);
          circleNode
            .transition()
            .ease('ease-in')
            .delay(800)
            .duration(400)
            .style('opacity', (d) => (d.id !== 0 ? Math.random() * (1.5 - 0.1) + 0.1 : 1));
        }
      },
      startProblemAnimation(scrollTop) {
        wallCircle
          .filter((d) => !d.isRemove)
          .style('opacity', ((opacityScale) ? opacityScale(scrollTop) : 0));
        wallCircle
          .filter((d) => d.isRemove)
          .attr({
            cx: (d) => d.xPosScale(scrollTop),
            cy: (d) => d.yPosScale(scrollTop)
          })
          .style('opacity', ((opacityScale) ? opacityScale(scrollTop) : 0));
        if (opacityInterval) {
          clearInterval(opacityInterval);
          opacityInterval = null;
        }
      },
      setProblemScaleDomain: (start, end) => {
        opacityScale = d3.scale.linear().domain([start, end]).range([1, 0]).clamp(true);
        wallCircle
          .each(d => {
            d.yPosScale = d3.scale.linear().domain([start, end]).range([d.cy, d.yFinalPos]).clamp(true);
            d.xPosScale = d3.scale.linear().domain([start, end]).range([d.cx, d.xFinalPos]).clamp(true);
          });
      }
    };
  }

  /**
   * This is Visble Section change Handler.
   *
   * @param $event - Section Id
   */
  onScrollSecionChange($event) {
    this.onVisibleSectionChange.emit($event);
  }

  ngOnDestroy(): void {
    if (this.unsubscribeResize) {
      this.unsubscribeResize();
    }
    if (this.unsubscribeAnimationFrame) {
      window.cancelAnimationFrame(this.unsubscribeAnimationFrame);
    }
  }
}

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
