import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownDirective } from './dropdown.directive';
import { DataShareService } from './data-share.service';
import { AuthGuardService } from './auth-guard.service';
import { AuthService } from './auth.service';
import { AdminAuthGuardService } from './admin-auth-guard.service';


@NgModule({
  declarations: [
    DropdownDirective,
    // ScrollSpyDirective
  ],
  exports: [
    CommonModule,
    DropdownDirective,
    // ScrollSpyDirective
  ],
  providers: [
    DataShareService,
    AuthGuardService,
    AuthService,
    AdminAuthGuardService
  ]
})
export class SharedModule { }
