import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { ConfirmationOverlayComponent } from '../confirmation-overlay/confirmation-overlay.component';

export interface CanComponentDeactivate {
  confirm(): any;
}

@Injectable({
  providedIn: 'root'
})

export class ConfirmCancelGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private dialog: MatDialog,) { }
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Promise<boolean> {
    const confirmValues = component.confirm();
    let confirmation = true;
    if (confirmValues.isChangeDetected) {
      return new Promise((resolve, reject) => {
        const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
          data: {
            title: 'Leave Edit Mode',
            message: 'Do you want leave page? Note: All the unsaved changes will be removed if you click Yes'
          },
          panelClass: 'cdk-overlay-pane_modifier',
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(e => {
          resolve(e);
        });
      });
    } else {
      return confirmation;
    }
    if (confirmValues.isChangeDetected) {
      let message = 'Do you want leave page? Note: All the unsaved changes will be removed if you click Yes';
      confirmation = confirm(message) ? true : false;
    }
    return confirmation
  }
}
