import { SelectionChange } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmationOverlayComponent } from '../confirmation-overlay/confirmation-overlay.component';
import { GalleryService } from '../gallery.service';

@Component({
  selector: 'app-group-settings-overlay',
  templateUrl: './group-settings-overlay.component.html',
  styleUrls: ['./group-settings-overlay.component.css'],
  providers: [GalleryService]
})
export class GroupSettingsOverlayComponent {

  public isNew = false;
  public groupData;
  public storiesData;

  public oldColor = '';
  public oldTitle = '';
  public oldIds = [];
  public oldStatus = null;

  public selectedStory = null;
  public selectedStories = [];
  public selectableStories = [];
  public isUpdated = false;

  public validateKeys = {
    'title': false,
    'color': false,
    'stories': false,
    'status': false
  }

  public validateEmpty = {
    title: false,
    color: false,
    stories: false,
  }


  public colors = [];

  public groupList = [];
  public story = null;


  constructor(
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<GroupSettingsOverlayComponent>,
    public gallaryService: GalleryService) {
    this.groupList = data.groupList;
    this.isNew = !data.groupData;
    this.selectableStories = [...data.stories.other];
    this.selectableStories.forEach(e => {
        e.oldGroupId = e.groupId
    });
    this.colors = gallaryService.groupColors.slice();
    this.colors.forEach(e => {
      e.available = true;
    });
    if (this.isNew) {
      this.oldIds = [];
      // this.validateEmpty.color = true;
      this.validateEmpty.title = true;
      this.oldColor = "";
      this.oldTitle = "";
      this.oldStatus = false;
      this.groupData = {
        "configurationId": null,
        "name": "",
        "color": "",
        "isHidden": false,
        "stories": []
      }
      this.validateEmpty.color = true;
      this.validateEmpty.title = true;
      this.validateEmpty.stories = true;
    } else {
      this.groupData = JSON.parse(JSON.stringify(data.groupData));
      this.oldIds = data.stories.existing.map(e => e.id);
      this.oldColor = this.groupData.color;
      this.oldTitle = this.groupData.title;
      this.oldStatus = this.groupData.isHidden;
      this.selectedStories = [...data.stories.existing];
    }
    this.updateColorAvailability();
  }

  updateColorAvailability() {
    this.colors.forEach(e => {
      const color = this.groupList.find(c => e.color !== this.groupData.color && c.color === e.color)
      if (color) {
        e.available = false;
      } else {
        e.available = true;
      }
    });
    this.colors = [...this.colors.filter(e => e.available), ...this.colors.filter(e => !e.available)];
    if (this.oldColor.trim() !== '') {
      const colorIndex = this.colors.findIndex(e => e.color === this.oldColor);
      if (colorIndex > -1) {
        const selectedColor = this.colors[colorIndex];
        this.colors.splice(colorIndex, 1);
        this.colors.splice(0, 0, selectedColor);
      }
    } else {
      this.updateColor(this.colors[0]);
    }
  }


  updateSelection($e) {
    const index = this.selectableStories.findIndex(e => e.id === $e.target.value);
    if (index > -1) {
      this.selectedStories.push(this.selectableStories[index]);
      this.selectableStories.splice(index, 1);
      this.selectedStory = null;
    }
    this.validateStories();
  }

  removeFromGroup(id) {
    const index = this.selectedStories.findIndex(e => e.id === id);
    if (index > -1) {
      this.selectableStories.unshift(this.selectedStories[index])
      this.selectedStories.splice(index, 1);
    }
    this.validateStories();
  }

  updateColor(color) {
    if (!color.available) { return }
    this.groupData.color = color.color;
    if (this.groupData.color.trim() === '') {
      this.validateEmpty.color = true;
    } else {
      this.validateEmpty.color = false;
      this.validateKeys.color = this.oldColor !== this.groupData.color;
    }
    this.validateSave();
  }

  updateTitle() {
    if (this.groupData.name.trim() === '') {
      this.validateEmpty.title = true;
    } else {
      this.validateEmpty.title = false;
      this.validateKeys.title = this.oldTitle !== this.groupData.name;
    }
    this.validateSave();
  }

  validateStatus() {
    this.validateKeys.status = this.oldStatus !== this.groupData.isHidden;
    this.validateSave();
  }

  validateStories() {
    if (this.oldIds.length !== this.selectedStories.length) {
      this.validateKeys.stories = true;
    } else {
      this.validateKeys.stories = false;
      this.selectedStories.forEach(story => {
        if (!this.oldIds.includes(story.id)) {
          this.validateKeys.stories = true;
        }
      });
    }
    if (this.selectedStories.length === 0) {
      this.validateEmpty.stories = true;
    } else {
      this.validateEmpty.stories = false;
    }
    this.validateSave();
  }

  validateSave() {
    this.isUpdated = false;
    let isEmpty = false;
    for (const key in this.validateEmpty) {
      if (Object.prototype.hasOwnProperty.call(this.validateEmpty, key)) {
        if (this.validateEmpty[key] === true) {
          isEmpty = true;
        }
      }
    }

    if (isEmpty) { return false }

    for (const key in this.validateKeys) {
      if (Object.prototype.hasOwnProperty.call(this.validateKeys, key)) {
        if (this.validateKeys[key] === true) {
          this.isUpdated = true;
        }
      }
    }

    return this.isUpdated;
  }

  getStories(isLocal = false) {
    if (this.isNew) {
      const added = [];
      const transfered = [];
      this.selectedStories.forEach(e => {
        if (e.oldGroupId !== null) {
          const obj: any = {
            storyId: e.id,
          };
          if (isLocal) {
            obj.groupId = e.groupId
          }
          transfered.push(obj)
        } else {
          added.push({ 
            storyId: e.id 
          })
        }
      });
      return {
        add: added,
        transfer: transfered,
      }
    } else {
      const added = [];
      const removed = [];
      const transfered = [];
      this.oldIds.forEach(old => {
        if (!this.selectedStories.find(e => e.id === old)) {
          removed.push({ storyId: old });
        }
      })

      this.selectedStories.forEach(e => {
        if (!this.oldIds.includes(e.id)) {
          if (e.oldGroupId !== null) {
            const obj: any = {
              storyId: e.id,
            };
            if (isLocal) {
              obj.groupId = e.groupId
            }
            transfered.push(obj);
          } else {
            added.push({ storyId: e.id });
          }
        }
      })

      return {
        add: added,
        remove: removed,
        transfer: transfered,
      }
    }
  }

  onSubmit() {
    if (this.validateSave()) {
      // if (this.selectedStories.length === 0) {
      //   const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
      //     data: {
      //       title: this.isNew ? 'Back to List' : 'Delete Confirmation',
      //       message: `As there are no selected stories this will ${this.isNew ? 'not be create' : 'deleted'}. Are you sure you want to continue?`
      //     },
      //     panelClass: 'cdk-overlay-pane_modifier',
      //     disableClose: true
      //     });
      //     dialogRef.afterClosed().subscribe(e => {
      //       if (e) {
      //         if (this.isNew) {
      //           this.onCancel()
      //         } else {
      //           this.deleteGroup(false);
      //         }
      //       }
      //     })
      // } else {
        const stories = this.getStories();
        const obj: any = {
          "configurationId": null,
          "name": this.groupData.name,
          "color": this.groupData.color,
          "isVisible": !this.groupData.isHidden,
          "stories": this.isNew ? [...stories.add, ...stories.transfer] : {
            add: [...stories.add, ...stories.transfer],
            remove: [...stories.remove]
          }
        }
        if (!this.isNew) {
          obj.groupId = this.groupData.id;
          this.updateGroup(obj);
        } else {
          this.createGroup(obj);
        }
      // }
    }
  }

  updateGroup(data) {
    this.isDataLoading = true;
    this.gallaryService.updateGroup(data).subscribe((res: any) => {
      this.showToastMessage(res.message, false);
      this.isDataLoading = false;
      this.dialogRef.close({
        type: 'update',
        color: this.groupData.color,
        name: this.groupData.name,
        isHidden: this.groupData.isHidden,
        stories: this.getStories(true)
        })
    }, (err) => {
      this.isDataLoading = false;
      this.showToastMessage(err.error.message);
    })
  }

  public isDataLoading = false;

  createGroup(data) {
    this.isDataLoading = true;
    this.gallaryService.createGroup(data).subscribe((res: any) => {
      this.showToastMessage(res.message, false);
      this.isDataLoading = false;
      this.dialogRef.close({
        type: 'create',
        color: this.groupData.color,
        name: this.groupData.name,
        isHidden: this.groupData.isHidden,
        id: res.data.groupId,
        stories: this.getStories(true),
      })
    }, (err) => {
      this.isDataLoading = false;
      this.showToastMessage(err.error.message);
    })
  }

  deleteGroup(showPrompt = true) {
    const payload = {
      "groupId": this.groupData.id,
      "configurationId": null
    }
    const executeDelete = () => {
      this.gallaryService.deleteGroup(payload).subscribe((res: any) => {
        this.showToastMessage(res.message, false);
        this.dialogRef.close({
          type: 'delete',
          id: this.groupData.id
        })
      }, (err) => {
        this.showToastMessage(err.error.message);
      })
    }

    if (showPrompt) {
      const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
        data: {
          title: 'Delete Confirmation',
          message: 'Do you want to delete a group?'
        },
        panelClass: 'cdk-overlay-pane_modifier',
        disableClose: true
        });
        dialogRef.afterClosed().subscribe(e => {
          if (e) {
            executeDelete();
          }
        })
    } else {
      executeDelete();
    }

  }

  onCancel() {
    this.dialogRef.close(false);
  }

  showToastMessage(message, isError = true, duration= 3000) {
    this._snackBar.open(message, '', {
			duration,
			panelClass: isError ? 'error' : 'success',
			verticalPosition: 'top',
			horizontalPosition: 'right'
		})
  }
}
