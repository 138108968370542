import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-virtual-rep-trainer-app-dialog',
  templateUrl: './virtual-rep-trainer-app-dialog.component.html',
  styleUrls: ['./virtual-rep-trainer-app-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VirtualRepTrainerAppDialogComponent implements OnInit {

  // tslint:disable-next-line: quotemark
  // tslint:disable-next-line: max-line-length
  public htmlString = "<iframe class='virtual-rep-trainer-iframe-wrapper' width='100%' height='100%' src='https://d1z4od8qsy2vtg.cloudfront.net/applications/stories/field_coaching/app_1/index.html' frameborder='0'></iframe>";

  constructor(public dialogRef: MatDialogRef<VirtualRepTrainerAppDialogComponent>) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
