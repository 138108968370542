export class D3EventTimelineService {
    private timelineData: Array<any> = [];
    private shapePosition: Array<any> = [];
    years = ['1/1/2006', '1/1/2007', '1/1/2008', '1/1/2009', '1/1/2010', '1/1/2011', '1/1/2012', '1/1/2013', '1/1/2014', '1/1/2015', '1/1/2016', '1/1/2017'];
    getYear(index) {
        return this.years[index].split("/")[2]
    }

    setData(timelineData, index) {
        this.timelineData[index] = { "userActivities": timelineData.userActivities };
    }

    getData(index) {
        return this.timelineData[index];
    }

    createData(index, shapes, shapesColor, timelineDetails) {
        const { shapeTextMessage, toolTipMessage, displayeMessageAt, timelineIndex, shapeSize, shapeTextColor } = timelineDetails;
        let userActivities = [];
        for (let activityIndex = 1; activityIndex < (this.years.length - 1); activityIndex++) {
            // var randomNumber = getRndInteger(7, 16);
            let shapePosition = Math.floor(Math.random() * shapes.length);
            userActivities.push({
                date: (Math.floor(Math.random() * 12) + 1) + '/1/' + this.getYear(activityIndex), //month/day/year years[activityIndex]
                shapeType: shapes[shapePosition],
                colorId: shapes.length == 1 ? Math.floor(Math.random() * this.getRandom(0, shapesColor.length)) : shapePosition,
                shapeText: shapeTextMessage ? shapeTextMessage[activityIndex % shapeTextMessage.length] : null,
                shapeTextColor: shapeTextColor ? shapeTextColor : null,
                shapeSize,
                showTooltip: ((displayeMessageAt.find((element) => { return element === activityIndex }) === activityIndex) &&
                    (timelineIndex === index)) ? toolTipMessage[activityIndex % toolTipMessage.length] : null,
            })
        }
        this.timelineData[index] = { "userActivities": userActivities.slice() };
    }

    getRandom(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    setShapePosition(value) {
        this.shapePosition = value;
    }

    getShapePosition() {
        return this.shapePosition.slice();
    }
}
