import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  // isOpen = false;

  @Output() updateBanerData: EventEmitter<any> = new EventEmitter();
  @Output() updateNavigationData: EventEmitter<any> = new EventEmitter();
  @Output() onNavigationSelect: EventEmitter<any> = new EventEmitter();
  @Output() onNavigationSectionChange: EventEmitter<any> = new EventEmitter();
  // @Output() onUserLogout: EventEmitter<any> = new EventEmitter();
  @Output() onLoginFormLink: EventEmitter<any> = new EventEmitter();
  @Output() onGalleryDataLoad: EventEmitter<any> = new EventEmitter();
  @Output() onCustomEvent: EventEmitter<any> = new EventEmitter();

  @Output() onCustomDashboardEvent: EventEmitter<any> = new EventEmitter();

  dataChange(udpatedDataInfo) {
    // alert("Called naa")
    this.updateBanerData.emit(udpatedDataInfo);
  }

  changeNavigation(navData) {
    this.updateNavigationData.emit(navData);
  }

  navigationClick(data) {
    this.onNavigationSelect.emit(data);
  }

  navigationSectionChange(data) {
    this.onNavigationSectionChange.emit(data);
  }

  // triggerLogout(userId) {
  //   this.onUserLogout.emit(userId);
  // }

  showLoginForm(type: string) {
    this.onLoginFormLink.emit(type);
  }

  galleryDataLoad(data: any) {
    this.onGalleryDataLoad.emit(data);
  }

  updateCustomEvent(obj) {
    this.onCustomEvent.next(obj);
  }

  getBodyStoryClass(url, prefix) {
    let className = '';
    // console.log("url", url);
    console.log(url,prefix);
    
    switch (url) {
      case 'plug_n_predict_telecom':
        className = `pnp-telecom-${prefix}-active`;
        break;
      case 'plug_n_predict_rwd':
        className = `pnp-rwd-${prefix}-active`;
        break;
      case 'leakage_prediction':
        className = `leakage-prediction-${prefix}-active`;
        break;
      case 'nlp':
      case 'text_analytics':
        className = `nlp-${prefix}-active`;
        break;
      case 'design_intelligence':
        className = `design-intelligence-${prefix}-active`;
        break;
      case 'driverless_forecasting':
        className = `driverless-forecasting-${prefix}-active`;
        break;
      case 'dynamic_targeting':
        className = `dynamic-targeting-${prefix}-active`;
        break;
      case 'personalized_analytics':
        className = `personalized-analytics-${prefix}-active`;
        break;
      case 'computer_vision_for_gaming':
        className = `computer-vision-${prefix}-active`;
        break;
      case 'contextual_engine':
      case 'next_best_action_platform':
        className = `contextual-engine-${prefix}-active`;
        break;
      case 'performance_analytics':
      case 'agile_launch_analytics':
        className = `performance-analytics-${prefix}-active`;
        break;
      case 'Competitive_intelligence':
      case 'launch_prediction_using_unstructured_data_analysis':
        className = `competitive-intelligence-${prefix}-active`;
        break;
      case 'audio_analytics':
        className = `audio-analytics-${prefix}-active`;
        break;
      case 'video_analytics':
        className = `video-analytics-${prefix}-active`;
        break;
      case 'field_coaching':
        className = `field-coaching-${prefix}-active`;
        break;
        case 'virtual_rep_trainer':
          className = `virtual-rep-trainer-${prefix}-active`;
          break;
      case 'aiqna':
        className = `aiqna-${prefix}-active`;
        break;
      case 'adaptive_attribute_based_offers':
        className = `adaptive-pricing-${prefix}-active`;
        break;
      // TODO:RM Rename the url
      case 'ctemp':
        className = `ctemp-${prefix}-active`;
        break;
      case 'advanced_market_intelligence':
        className = `advanced_market_intelligence-${prefix}-active`;
        break;
      case 'personalize_ai':
        className = `personalize_ai-${prefix}-active`;
        break;
        case 'patient_radar':
          className = `patient_radar-${prefix}-active`;
          break;
      default:
        className = `default-${prefix}-active`;
        break;
    }
    return className;
  }
}
