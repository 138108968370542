import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { WidgetService } from '../widgets.service';
declare let d3: any;
declare let Donut3D: any;
import * as _ from 'lodash';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-d3-threed-donut',
  templateUrl: './d3-threed-donut.component.html',
  styleUrls: ['./d3-threed-donut.component.css']
})
export class D3ThreedDonutComponent implements OnInit {

  @ViewChild('d3TreedDonutChartRef') chartElement: ElementRef;
  @Input() widgetData;

  constructor() { }

  ngOnInit() {
   for (let i = 0; i < this.widgetData.data.length; i++) {
      // this.loadThreedDonut(_.cloneDeep(this.salesData[i]));
      this.loadThreedDonut(this.widgetData.data[i], i);

    }
  }

  loadThreedDonut(salesDatas, i) {
    var svg = d3.select(this.chartElement.nativeElement).append("div").attr('class', 'donut-svg').append("svg").attr("width", 400).attr("height", 300);
    svg.append("g").attr("id", "salesDonut" + i);
    // svg.append("g").attr("id","quotesDonut");

    Donut3D.draw("salesDonut" + i, randomData(), 140, 150, 100, 70, 25, 0.4);
    // Donut3D.draw("quotesDonut", randomData(), 450, 150, 130, 100, 30, 0);

    // function changeData(){
    // 	Donut3D.transition("salesDonut", randomData(), 130, 100, 30, 0.4);
    // 	Donut3D.transition("quotesDonut", randomData(), 130, 100, 30, 0);
    // }

    function randomData() {
      return salesDatas.data.map(function (d) {
        return { label: d.label, value: d.value, color: d.color };
      });
    }

    var legend = svg.selectAll(".legend")
      .data(salesDatas.data.map(function (d) { return d; }).reverse())
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", function (d, i) { return "translate(0," + i * 20 + ")"; })
      //.attr("transform", function (d, i) { return "translate("+ (i==0?-165:-60)+", -35)"; })
      .style("opacity", "0");

    legend.append("circle")
      //.attr("x", 350 - 18)
      .attr("r", 4)
      .attr("cx", 350 - 100)
      .attr("cy", 120)
      // .attr("width", 18)
      // .attr("height", 18)
      .style("fill", function (d) { return d.color });

    legend.append("text")
      //.attr("x", 350 - 24)
      .attr("x", 350 - 90)
      .attr("y", 120)
      .attr("dy", ".35em")
      .attr("class", "text-font")
      .style("text-anchor", "start")
      .style("fill", "#000")
      .text(function (d) { return d.label; });

    legend.transition().duration(0).style("opacity", "1");

    var heading = svg.selectAll(".heading")
      .data("a")
      .enter().append("g")
      .attr("class", "heading")
      .style("opacity", "0");

      heading.append("text")
      .attr("x", 110)
      .attr("y", 260)
      .attr("dy", ".35em")
      .attr("class", "heading-text-font")
      .style("text-anchor", "start")
      //.style("font-family","'Roboto', sans-serif")
      .style("fill", "#000")
      .text(salesDatas.name);

      heading.transition().duration(0).style("opacity", "1");


  }

}
