import { Injectable, EventEmitter, Output } from '@angular/core';
import { Subject, BehaviorSubject, Observable, timer } from 'rxjs';
import { Router } from '@angular/router';
import { UserType } from './enum/enum';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { environment } from 'src/environments/environment';
import { CookieService } from './cookie-wrapper.service';
import { DataShareService } from './data-share.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user: any = null;
  private _isStoredUser = false;
  public onUserInfoUpate = null;
  private onUserInfoUpateSub: BehaviorSubject<any> = new BehaviorSubject(this.getUser());
  private _tokenTimer = null;

  constructor(
    private _router: Router,
    private _cookieService: CookieService,
    private _dataShareService: DataShareService) {
    const _user = localStorage.getItem('user');
    if (_user) {
      this.setUser(JSON.parse(window.atob(_user)));
      // this._token = JSON.parse(window.atob(_user)).token;
      this._isStoredUser = true;
    }
    this.onUserInfoUpate = this.onUserInfoUpateSub.asObservable();
  }

  public isStoredUser() {
    return this._isStoredUser;
  }

  public setUser(user) {
    this._user = {
      id: user.id,
      emailId: user.emailId,
      name: user.name,
      type: user.type,
      userType: user.userType,
      isAdmin: user.isAdmin || false,
      token: user.token,
      capabilityGroup: (user.data && user.data.capabilityGroup) || null,
      department: (user.data && user.data.department) || null
    };
    if (user._s) {
      this._cookieService.setCloudFrontCookie(user._s);
    }
    if (user.isAdmin || (user.type === UserType.ZS)) {
      localStorage.setItem('user', window.btoa(JSON.stringify(this._user)));
    }
    this._tokenTimer = timer(environment.token_refresh_time).subscribe(val => {
      this._dataShareService.updateCustomEvent({'event': 'refresh_token', data: true});
    });
    this.onUserInfoUpateSub.next({ ...this._user });
  }

  public getUser() {
    if (this._user) {
      return { ...this._user };
    } else {
      return null;
    }
  }

  public getUserId() {
    if (this._user) {
      return this._user.id;
    } else {
      return null;
    }
  }

  public getToken() {
    if (this._user) {
      return this._user.token;
    } else {
      return '';
    }
  }

  public removeUser() {
    let isAdmin = false;
    if (this._user) {
      isAdmin = this._user.isAdmin;
    }
    this._user = null;
    this._isStoredUser = false;
    localStorage.removeItem('user');
    localStorage.removeItem('purpose');
    if (this._tokenTimer) {
      this._tokenTimer.unsubscribe();
    }
    this._cookieService.clearCloudfrontCookies();
    this.onUserInfoUpateSub.next(null);
    if (isAdmin) {
      this._router.navigate(['login', 'admin']);
    } else {
      this._router.navigate(['login']);
    }
  }

  public getUserFromLocalStorage() {
    return localStorage.getItem('user');
  }
}
