import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HttpWrapperService } from './services/http-wrapper.service';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { GalleryModule } from './gallery/gallery.module';
import { SharedModule } from './shared/shared.module';
import { LoaderService } from './services/loader.service';
import { D3EventTimelineService } from './services/d3-event-timeline.service';
import { ShapesConfigService } from './services/shapes-config.service';
import { UserManagementModule } from './user-management/user-management.module';
import { GoogleAnalyticsService } from './services/google-analytics.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    GalleryModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    UserManagementModule
  ],
  providers: [
    HttpWrapperService,
    LoaderService,
    D3EventTimelineService,
    ShapesConfigService,
    GoogleAnalyticsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
