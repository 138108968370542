import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { WidgetService } from '../../../widgets/widgets.service';
import { VisulizationService } from '../../services/visulization.service';

@Component({
	selector: 'app-visulization',
	templateUrl: './visulization.component.html',
	providers: [WidgetService, VisulizationService]
})
export class VisulizationComponent implements OnInit {

	@Input() visulization;
	@Input() osetParent;
	isOpen;
	collapseVisulization = false;
	showFullView = false;
	response;
	
	constructor(private renderer: Renderer2,
		private visulizationService: VisulizationService) { }

	ngOnInit() {
		this.collapseVisulization = this.visulization.defaultCollapse || this.collapseVisulization;
		const widgetTypes = [];
		for (let widgetNo = 0; widgetNo < this.visulization.widget.length; widgetNo++) {
			widgetTypes.push(this.visulization.widget[widgetNo].type);
		}
		this.visulization.widgetTypes = widgetTypes;
	}

	toggeleVisulization() {
		this.collapseVisulization = !this.collapseVisulization;
	}

	toggleFullView() {
		this.showFullView = !this.showFullView;
		if (this.showFullView) {
			this.renderer.addClass(document.body, 'full-view-enable');
		} else {
			this.renderer.removeClass(document.body, 'full-view-enable');
		}
	}

	handleActionClick(actionData) {
		actionData.data = null;
		this.visulizationService.onActionClick(actionData);
	}


}
