import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
declare let d3: any;

@Component({
  selector: 'app-d3-genetic-algorithm',
  templateUrl: './d3-genetic-algorithm.component.html',
  styleUrls: ['./d3-genetic-algorithm.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class D3GeneticAlgorithmComponent implements OnInit {

  @Input() widgetData;

  constructor() { }

  ngOnInit() {
    this.loadMap(this.widgetData.data);
  }

  loadMap(data) {
    var margin = { top: 70, right: 0, bottom: 30, left: 30 },
      width = 960 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom,
      gridSize = Math.floor(width / 24),
      legendElementWidth = gridSize * 2,
      buckets = 9,
      colors = ["#ffffd9", "#edf8b1", "#c7e9b4", "#7fcdbb", "#41b6c4", "#1d91c0", "#225ea8", "#253494", "#081d58"], // alternatively colorbrewer.YlGnBu[9]
      days = ["n1", "n2", "n3", "n4", "n5", "n6", "n7"],
      times = ["g1", "g2", "g3", "g4", "g5", "g6", "g7", "g8", "g9", "g10", "g11", "g12", "g13", "g14", "g15", "g16", "g17", "g18", "g19", "g20", "g21", "g22", "g23", "g24"];

    var svg = d3.select("#chart").append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var dayLabels = svg.selectAll(".dayLabel")
      .data(days)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", 0)
      .attr("y", function (d, i) { return i * gridSize; })
      .style("text-anchor", "end")
      .attr("transform", "translate(-6," + gridSize / 1.5 + ")")
      .attr("class", "dayLabel mono axis axis-workweek");

    var timeLabels = svg.selectAll(".timeLabel")
      .data(times)
      .enter().append("text")
      .text(function (d) { return d; })
      .attr("x", function (d, i) { return i * gridSize; })
      .attr("y", 0)
      .style("text-anchor", "middle")
      .attr("transform", "translate(" + gridSize / 2 + ", -6)")
      .attr("class", "timeLabel mono axis axis-worktime");


    var colorScale = d3.scale.quantile()
      .domain([0, buckets - 1, d3.max(data, function (d) { return d.value; })])
      .range(colors);

    var cards = svg.selectAll(".hour")
      .data(data, function (d) { return d.day + ':' + d.hour; });

    cards.append("title");

    cards.enter().append("rect")
      .attr("x", function (d) { return (d.hour - 1) * gridSize + 5; })
      .attr("y", function (d) { return (d.day - 1) * gridSize + 5; })
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("class", "bordered")
      .attr("width", gridSize)
      .attr("height", gridSize)

      .on("mouseover", function (d) {
        d3.select(this).attr('rx', 12).attr('ry', 12);
      })
      .on("mouseout", function (d) {
        d3.select(this).attr('rx', 4).attr('ry', 4);
      })
      .style("fill", colors[0]);

    cards.transition().duration(1000)
      .style("fill", function (d) { return colorScale(d.value); });

    cards.select("title").text(function (d) { return d.value; });

    cards.exit().remove();
  }
}
