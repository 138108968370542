import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
declare let d3: any;
import d3Tip from "d3-tip";
@Component({
  selector: "app-playground-dialog",
  templateUrl: "./playground-dialog.component.html",
  styleUrls: ["./playground-dialog.component.css"],
})
export class PlaygroundDialogComponent implements OnInit {
  public playgroundForm: FormGroup;
  public isFormSubmitted = false;
  public categories = ["Bakery Products"];
  public subCategories = ["Cookies"];
  public flavors = [
    "chocolate liquor",
    "cocoa",
    "chocolate",
    "chocolate chip",
    "citric acid",
    "cocoa processed",
    "buttermilk",
    "coconut",
    "peanut butter",
    "peanut butter chocolate chip",
    "cinnamon",
    "chocolate flavor",
  ];
  public proteines = [
    "milk protein isolate",
    "sodium caseinate",
    "soy protein isolate",
    "whey",
    "whey protein concentrate",
    "whey protein isolate",
    "milk protein concentrate",
  ];
  public sweetners = [
    "corn syrup",
    "dextrose",
    "cane sugar",
    "fructose",
    "brown sugar",
    "glycerin",
    "invert sugar",
    "erythritol",
    "cane syrup",
    "barley malt extract",
    "glucose syrup",
  ];
  public fats = [
    "canola oil",
    "palm",
    "cocoa butter",
    "palm oil",
    "coconut oil",
    "sunflower oil",
    "soybean oil",
    "palm kernel oil",
  ];
  public grains = [
    "oat",
    "tapioca starch",
    "soybean",
    "unbleached wheat flour",
    "wheat starch",
    "peanut flour",
    "soy flour",
    "potato starch",
    "sunflower seed",
    "whole grain oat",
    "malted barley flour",
  ];
  public wellnesses = [
    "wellness_Carb Friendly",
    "wellness_No Added Salt",
    "wellness_Contains Healthy Fats",
    "wellness_Vegetarian (Derived)",
    "wellness_Vegan (Derived)",
    "wellness_Saturated Fat Free",
    "wellness_Omega 3 (Custom)",
    "wellness_100 Calories or Less",
    "wellness_Kosher",
    "wellness_Fat Free",
    "wellness_Certified Gluten Free",
    "wellness_Lifestyle - Protein",
    "wellness_Low Fat",
    "wellness_5 Ingredients or Less",
    "wellness_Very Low Sodium",
    "wellness_Sugar Free",
    "wellness_Whole Grain Stamp",
    "wellness_No Gluten Ingredients",
    "wellness_Lifestyle - Sugar",
    "wellness_Low Saturated Fat",
  ];

  currentWellness = "";
  currentGrain = "";
  currentSweetner = "";

  constructor(private formBuilder: FormBuilder) {
    this.playgroundForm = formBuilder.group({
      category: [this.categories[0], Validators.required],
      subCategory: [this.subCategories[0], Validators.required],
      s1_flavor: [this.flavors[0], Validators.required],
      s1_protein: [this.proteines[0], Validators.required],
      s1_sweetner: [this.sweetners[0], Validators.required],
      s1_fats: [this.fats[0], Validators.required],
      s1_grains: [this.grains[0], Validators.required],
      s1_wellness: [this.wellnesses[0], Validators.required],
      s2_flavor: [this.flavors[1], Validators.required],
      s2_protein: [this.proteines[1], Validators.required],
      s2_sweetner: [this.sweetners[1], Validators.required],
      s2_fats: [this.fats[1], Validators.required],
      s2_grains: [this.grains[1], Validators.required],
      s2_wellness: [this.wellnesses[1], Validators.required],
    });
  }

  @ViewChild("playgroundCandleChart") private chartContainer: ElementRef;
  groups: any;
  data: any[];
  svgDims = { width: 695.83, height: 344 };
  margin = { top: 90, right: 30, bottom: 50, left: 80 };
  xAxisMargin = { left: 70, right: 70 };
  contentDims = {
    width: this.svgDims.width - this.margin.left - this.margin.right,
    height: this.svgDims.height - this.margin.top - this.margin.bottom,
  };
  divIsEmpty = true;

  svg: any;

  runForecast() {
    console.log(this.playgroundForm);

    if (this.chartContainer.nativeElement.children.length > 0)
      while (this.chartContainer.nativeElement.children.length !== 0)
        this.chartContainer.nativeElement.children[0].remove();
    this.divIsEmpty = true;
    setTimeout(() => {
      this.initiateGraph();
      this.divIsEmpty = false;
    }, 500);
  }

  ngOnInit() {
    this.divIsEmpty = true;
    this.initiateGraph();
    this.divIsEmpty = false;
  }

  initiateGraph() {
    this.svgDims.width = this.chartContainer.nativeElement.offsetWidth;

    this.contentDims = {
      width: this.svgDims.width - this.margin.left - this.margin.right,
      height: this.svgDims.height - this.margin.top - this.margin.bottom,
    };
    this.createData();
    this.createGraph();
  }

  createData() {
    let max = 35;
    let min = 15;
    let x = Math.floor(Math.random() * (max - min + 1)) + min;
    let y = Math.floor(Math.random() * (max - min + 1)) + min;
    let open = Math.min(x, y);
    let close = Math.max(x, y);

    let maxRand = 5;
    let minRand = -5;
    let randomInt =
      Math.floor(Math.random() * (maxRand - minRand + 1)) + minRand;

    console.log(this.playgroundForm.value);
    if (randomInt > 0) {
      this.currentGrain = this.playgroundForm.value["s2_grains"];
      this.currentSweetner = this.playgroundForm.value["s2_sweetner"];
      this.currentWellness = this.playgroundForm.value["s2_wellness"];
    } else {
      this.currentGrain = this.playgroundForm.value["s1_grains"];
      this.currentSweetner = this.playgroundForm.value["s1_sweetner"];
      this.currentWellness = this.playgroundForm.value["s1_wellness"];
    }

    this.data = [
      {
        name: "Scenario1",
        color: "#EAA749",
        low: open - 2,
        open: open,
        close: close,
        high: close + 2,
      },
      {
        name: "Scenario2",
        color: "#0BE1F0",
        low: open - 2 + randomInt,
        open: open + randomInt,
        close: close + randomInt,
        high: close + 2 + randomInt,
      },
    ];
  }

  createGraph() {
    setTimeout(() => {
      this.createSvg();
      this.createChart();
    }, 100);
  }

  createSvg() {
    this.svg = d3
      .select(this.chartContainer.nativeElement)
      .append("svg")
      .attr("width", this.svgDims.width)
      .attr("height", this.svgDims.height)
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
  }

  createXAxis() {
    let xLabels = ["Scenario1", "Scenario2"];
    let xAxis = d3.scale
      .ordinal()
      .domain(xLabels)
      .rangeRoundBands([0, this.contentDims.width], 0.8);

    let xScale = this.svg
      .append("g")
      .attr("transform", "translate(0," + this.contentDims.height + ")")
      .call(d3.svg.axis().scale(xAxis).outerTickSize(0));

    xScale
      .selectAll(".domain")
      .attr("fill", "none")
      .attr("stroke-width", 1)
      .attr("stroke", "white");

    xScale.selectAll(".tick text").attr("fill", "#ffffff");

    return xAxis;
  }

  createYAxis() {
    let yAxis = d3.scale
      .linear()
      .domain([0, 40])
      .range([this.contentDims.height, 0]);

    let yScale = this.svg.append("g").call(
      d3.svg
        .axis()
        .scale(yAxis)
        .orient("left")
        .ticks(5)
        .outerTickSize(0)
        .tickFormat((d) => {
          console.log(d);
          return d !== 0 ? "$" + d + "M" : d;
        })
    );

    yScale
      .selectAll(".domain")
      .attr("fill", "none")
      .attr("stroke-width", 1)
      .attr("stroke", "white");

    yScale
      .selectAll(".tick line")
      .attr({
        fill: "none",
        "stroke-width": 1,
        stroke: "white",
      })
      .attr("x2", this.contentDims.width);

    yScale.selectAll(".tick text").attr("fill", "#ffffff");

    return yAxis;
  }

  createTooltip() {
    let tip = d3Tip()
      .attr("class", "d3-tip")
      .style("z-index", "999999999")
      .html(
        (d) =>
          `<div class="atlas-tooltip">
              <div>High:- ${this.data[d].high}M</div>
              <div>Low:- ${this.data[d].low}M</div>
            </div>`
      );
    return tip;
  }

  createRects(xAxis, yAxis, tip) {
    this.svg
      .selectAll("rects")
      .data(this.data)
      .enter()
      .append("rect")
      .attr("x", (d) => {
        console.log(d);
        return xAxis(d.name);
      })
      .attr("y", (d) => yAxis(Math.max(d.open, d.close)))
      .attr("width", 30)
      .attr("fill", (d) => d.color)
      .on("mouseover", function (event, d) {
        console.log(d);
        tip.show(d, this);
      })
      .on("mouseout", function (event, d) {
        tip.hide(d, this);
      })
      .attr("height", 0)
      .transition()
      .delay(550)
      .duration(200)
      .attr(
        "height",
        (d) =>
          yAxis(Math.min(d.open, d.close)) - yAxis(Math.max(d.open, d.close))
      );
  }

  createTitle(text) {
    this.svg
      .append("text")
      .attr("y", -50)
      .attr("x", -this.margin.left + 20)
      .style("font-size", "15px")
      .text(text)
      .attr("fill", "#ffffff");
  }

  createYAxisLabel(text) {
    this.svg
      .append("text")
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("y", -this.margin.left + 25)
      .attr("x", -(this.contentDims.height / 2))
      .style("font-size", "15px")
      .text(text)
      .attr("fill", "#ffffff");
  }

  createStems(xAxis, yAxis) {
    for (var i = 0; i < this.data.length; i++) {
      var x1 = xAxis(this.data[i].name) + 30 / 2;
      var x2 = xAxis(this.data[i].name) + 30 / 2;
      var xMid = (x1 + x2) / 2;

      var y1 = yAxis(this.data[i].high);
      var y2 = yAxis(this.data[i].low);
      var yMid = (y1 + y2) / 2;

      var color = this.data[i].color;

      this.svg
        .append("line")
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 2)
        .attr("x1", xMid)
        .attr("y1", yMid)
        .attr("x2", xMid)
        .attr("y2", yMid)
        .transition()
        .delay(600)
        .duration(250)
        .attr("x2", x1)
        .attr("y2", y1);

      this.svg
        .append("line")
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 2)
        .attr("x1", xMid)
        .attr("y1", yMid)
        .attr("x2", xMid)
        .attr("y2", yMid)
        .transition()
        .delay(600)
        .duration(250)
        .attr("x2", x2)
        .attr("y2", y2);
    }
  }

  createChart() {
    let xAxis = this.createXAxis();
    let yAxis = this.createYAxis();
    let tip = this.createTooltip();
    this.svg.call(tip);

    this.createTitle("Year 1 Launch Sales");
    this.createYAxisLabel("Forecasted Annual Sales");
    this.createRects(xAxis, yAxis, tip);
    this.createStems(xAxis, yAxis);
  }
}
