import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { WidgetService } from '../widgets.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-success-failure',
  templateUrl: './success-failure.component.html'
})
export class SuccessFailure implements OnInit {
  @Input() widgetData;
  data;
  displayedColumns: string[] = ['sponsor_name', 'collaborator_name', 'success/failure'];
  dataSource;
  // coverageData;

  filterData: any = {
    nct_id: [],
    sponsor: [],
    collaborator: [],
    success_range: []
  };

  constructor(private widgetService: WidgetService) {
    this.widgetService.triggerDataChangeForTableViewer.subscribe((updatedDataInfo) => {
      if (updatedDataInfo.inputDetails.filterOn === 'nct_id') {
        this.filterData.nct_id = updatedDataInfo.formField.filterField;
      } else if (updatedDataInfo.inputDetails.filterOn === 'sponsor_name') {
        this.filterData.sponsor = updatedDataInfo.formField.filterField;
      } else if (updatedDataInfo.inputDetails.filterOn === 'collaborator_name') {
        this.filterData.collaborator = updatedDataInfo.formField.filterField;
      } else if (updatedDataInfo.inputDetails.filterOn === 'success_percentage') {
        this.filterData.success_range = updatedDataInfo.formField.filterField;
      }
      if (this.filterData.nct_id.length || this.filterData.sponsor.length
        || this.filterData.collaborator.length || this.filterData.success_range.length) {
        // if ((updatedDataInfo.formField.uniqueName4 && updatedDataInfo.formField.uniqueName4.length) 
        // || (updatedDataInfo.formField.uniqueName5 && updatedDataInfo.formField.uniqueName5.length) 
        // || (updatedDataInfo.formField.uniqueName6 && updatedDataInfo.formField.uniqueName6.length) 
        // || (updatedDataInfo.formField.uniqueName7 && updatedDataInfo.formField.uniqueName7.length)) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }
    });
  }

  updateChart(updatedDataInfo) {
    // alert("Update chart")
    //this.data.length
    let updatedDate = [];
    let dataLength = this.data.length;
    /*let isUniqueName4Selected = (updatedDataInfo.formField.uniqueName4 && updatedDataInfo.formField.uniqueName4.length && true) || false;
    let isUniqueName5Selected = (updatedDataInfo.formField.uniqueName5 && updatedDataInfo.formField.uniqueName5.length && true) || false; 
    let isUniqueName6Selected = (updatedDataInfo.formField.uniqueName6 && updatedDataInfo.formField.uniqueName6.length && true)|| false; 
    let isUniqueName7Selected = (updatedDataInfo.formField.uniqueName7 && updatedDataInfo.formField.uniqueName7.length && true)|| false; */

    // console.log(isUniqueName4Selected, isUniqueName5Selected, isUniqueName6Selected)


    let isUniqueName4Found, isUniqueName5Found, isUniqueName6Found, isUniqueName7Found;

    // let lowerCaseUniqueName4 = updatedDataInfo.formField.uniqueName4.map((value) => {
    //   return value.toLowerCase();
    // });

    let lowerCaseUniqueName5 = this.filterData.sponsor.map((value) => {
      // let lowerCaseUniqueName5 = updatedDataInfo.formField.uniqueName5.map((value) => {
      return value.toLowerCase();
    });

    let lowerCaseUniqueName6 = this.filterData.collaborator.map((value) => {
      // let lowerCaseUniqueName6 = updatedDataInfo.formField.uniqueName6.map((value) => {
      return value.toLowerCase();
    });

    for (let index = 0; index < dataLength; index++) {
        isUniqueName5Found = true,
        isUniqueName6Found = true,
        isUniqueName7Found = false;

      if (lowerCaseUniqueName5.length && ((lowerCaseUniqueName5.indexOf(this.data[index].sponsor_name.toLowerCase())) == -1)) {
        isUniqueName5Found = false;
      }

      if (lowerCaseUniqueName6.length && ((lowerCaseUniqueName6.indexOf(this.data[index].collaborator_name.toLowerCase())) == -1)) {
        isUniqueName6Found = false;
      }

      if (this.filterData.success_range.length) {
        // if (updatedDataInfo.formField.uniqueName7.length) {
        for (let rangeIndex = 0; rangeIndex < this.filterData.success_range.length; rangeIndex++) {
          let range = this.filterData.success_range[rangeIndex].split(' - ');
          if ((((parseInt(this.data[index].success_percentage) >= parseInt(range[0].slice(0, -1)))
            && (parseInt(this.data[index].success_percentage) <= parseInt(range[1].slice(0, -1)))))) {
            isUniqueName7Found = true;
            break;
          }
        }
      } else {
        isUniqueName7Found = true;
      }

      if (isUniqueName5Found && isUniqueName6Found && isUniqueName7Found) {
        updatedDate.push(this.data[index]);
      }
    }

    this.dataSource.data = updatedDate;
  }

  resetToOrignal() {
    this.dataSource.data = this.data.slice();
  }

  ngOnInit() {
    this.data = this.widgetData.data;
    this.dataSource = new MatTableDataSource(this.data);
    // this.dataSource = this.data;
    // this.coverageData = this.data;
  }

}
