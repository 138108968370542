import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.css']
})
export class CopyToClipboardComponent implements OnInit {

  @Input() data;
  @Output() public onCopied = new EventEmitter<any>();
  isClipBoardCopied = false;

  constructor() { }

  ngOnInit() {
  }

  copyDataToClipBoard(inputElement) {
    // console.log("Inside copyDataToClipBoard: ", this.data.value);
    // this.userName = Math.floor(Date.now() * Math.random());
    inputElement.select();
    document.execCommand('copy');
    inputElement.value = this.data.value;
    inputElement.select();
    document.execCommand('copy');
    inputElement.value = '';
    // console.log("this.data.label: ", this.data.label);
    this.onCopied.emit(this.data.label);
  }

}
