import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-html-viewer',
  templateUrl: './html-viewer.component.html'
})
export class HtmlViewerComponent implements OnInit {
 	@Input() data;


 	ngOnInit() {
 		// console.log("this.data", this.data)
 	}
}
