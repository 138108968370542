import { Component, OnInit, ViewChild, Renderer2, AfterContentInit, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import _ from 'lodash';
import { FullpagescrollDirective } from 'src/app/shared/fullpagescroll.directive';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
// import { FieldCoachingAppDialogComponent } from './field-coaching-app-dialog/field-coaching-app-dialog.component';
import { DataShareService } from 'src/app/shared/data-share.service';
declare const d3: any;
@Component({
  selector: 'app-ctemp',
  templateUrl: './ctemp.component.html',
  styleUrls: ['./ctemp.component.css']
})


export class CtempComponent implements OnInit {


  @ViewChild('fullPageScroll') fpScroll: FullpagescrollDirective;
  public activeStartSlide = '';
  public actualSlide = 'screen-1';
  public activeSlide = '';
  public previousActiveSlide = [];
  public lastActiveSlide = 'screen-1';
  public nextActiveSlide = 'screen-2';
  private screens = ['screen-1', 'screen-2', 'screen-3', 'screen-4', 'screen-5', 'screen-6', 'screen-7', 'screen-8'];
  public previousTraversedSlide = '';
  public scaleXValue = 1;
  public scaleYValue = 1;
  public isLoading;
  
  private animationStarted = {
    screen1: false,
    screen2: false,
    screen3: false,
    screen4: false,
    screen5: false,
    screen6: false,
    screen7: false,
    screen8: false,
    screen9: false,
    screen10: false,
    screen11: false,
    screen12: false,
    screen13: false,
    screen14: false,
    screen15: false,
    screen16: false,
    hospitalBed: false
  };
  public currentScrollDirection = 'forward';
  public addStep = false;
  public currentScreenIndex = 1;
  public clickActionScreensIndex = [1, 2, 3, 3, 3, 6, 7, 8, 9, 10, 11, , 12, 13, 14, 15, 16];
  public selectedProcess: any;
  public prevProcess: any;
  public selectedProcessId: any = -1;
  public selectedYear: any = "2021"
  private testTubeAnimationStarted = false
  private _svgSection = null;
  private _unsubscribeResize = null;
  public prevSlide = "";
  add6Step = false;
  isMenuOpen: any;
  isAnimation: boolean;
  isMouseEnter: any;
  public changedManualy = false;
  chartData: [
    {
      "country": "India",
      "years": [
        {
          "year": "2020",
          "enrollment": "85",
          "siteFailure": "7",
          "emptySites": "4",
          "screeningFailures": "2.5",
          "patientDropOff": "3"
        },
        {
          "year": "2021",
          "enrollment": "45",
          "siteFailure": "3",
          "emptySites": "2",
          "screeningFailures": "0.5",
          "patientDropOff": "1"
        }
      ]
    },
    {
      "country": "Australia",
      "years": [
        {
          "year": "2020",
          "enrollment": "75",
          "siteFailure": "5",
          "emptySites": "1",
          "screeningFailures": "0.5",
          "patientDropOff": "2"
        },
        {
          "year": "2021",
          "enrollment": "55",
          "siteFailure": "2",
          "emptySites": "2",
          "screeningFailures": "1.5",
          "patientDropOff": "5"
        }
      ]
    },
    {
      "country": "USA",
      "years": [
        {
          "year": "2020",
          "enrollment": "35",
          "siteFailure": "5",
          "emptySites": "1",
          "screeningFailures": "0.5",
          "patientDropOff": "2"
        },
        {
          "year": "2021",
          "enrollment": "95",
          "siteFailure": "6",
          "emptySites": "7",
          "screeningFailures": "3.5",
          "patientDropOff": "1"
        }
      ]
    },
    {
      "country": "UK",
      "years": [
        {
          "year": "2020",
          "enrollment": "60",
          "siteFailure": "8",
          "emptySites": "2",
          "screeningFailures": "6.5",
          "patientDropOff": "1"
        },
        {
          "year": "2021",
          "enrollment": "65",
          "siteFailure": "2",
          "emptySites": "4",
          "screeningFailures": "1.5",
          "patientDropOff": "3"
        }
      ]
    },
    {
      "country": "China",
      "years": [
        {
          "year": "2020",
          "enrollment": "40",
          "siteFailure": "8",
          "emptySites": "3",
          "screeningFailures": "1.5",
          "patientDropOff": "8"
        },
        {
          "year": "2021",
          "enrollment": "75"
        }
      ]
    }
  ]
  constructor(
    private _renderer: Renderer2
    , private _router: Router,
    private dialog: MatDialog,
    private dataShareService: DataShareService
  ) { }

  ngAfterContentInit(): void {
    this._unsubscribeResize = this._renderer.listen(window, 'resize', _.debounce(() => {
      this._svgSection.resize();
    }, 700));
  }

  ngOnInit() {
    this.dataShareService.onCustomEvent.next({ event: 'addClassToElement', data: { selector: '#header', class: 'humburger-header' } });
    this.renderStory();
  }

  public onFullPageSlideChangeStart(event) {
    // setTimeout(() => {
    this.previousTraversedSlide = this.actualSlide;
    if (this.previousActiveSlide.indexOf(event) < 0) {
      this.previousActiveSlide.push(this.actualSlide);
    } else {
      this.previousActiveSlide.splice(this.previousActiveSlide.indexOf(this.actualSlide), 1);
    }
    this.actualSlide = event;

    // }, 4000);
    switch (event) {
      // case 'screen-3' : 
      // case 'screen-4' : 
      // case 'screen-5' : 
      //     if(this.activeStartSlide!== 'screen-2'){
      //       this.activeStartSlide = 'screen-2'
      //     };break;
      //   case 'screen-6':
      //     this.activeStartSlide = 'screen-7';break;
      //   case 'screen-7':
      // if(this.activeStartSlide!== 'screen-7'){
      //   this.activeStartSlide = 'screen-7'
      // };break;
      default:
        this.activeStartSlide = event;
    }

    if ((this.screens.indexOf(this.previousTraversedSlide) - this.screens.indexOf(this.actualSlide)) < 0) {
      this.currentScrollDirection = 'forward';
    } else {
      this.currentScrollDirection = 'backward';
    }
    this.currentScreenIndex = this.clickActionScreensIndex[this.screens.indexOf(this.actualSlide)];
    this.lastActiveSlide = this.currentScrollDirection == 'forward' ? this.screens[this.screens.indexOf(event) - 1] : this.screens[this.screens.indexOf(event) + 1];
    this.prevSlide = this.lastActiveSlide;
    // if(this.currentScrollDirection === 'backward'){
    setTimeout(() => {
      this.lastActiveSlide = this.activeStartSlide;
    }, 4000);
    // }
    const nextSlideIndex = ((this.screens.indexOf(event) + 1) > (this.screens.length - 1)) ? this.screens.indexOf(event) : (this.screens.indexOf(event) + 1);
    this.nextActiveSlide = this.screens[nextSlideIndex];
    // setTimeout(() => {
    //   this.lastActiveSlide = this.activeStartSlide
    // }, 1000);
    // this._svgSection.changeScreen();

    // if (this.activeStartSlide === 'screen-2') {
    //   this._svgSection.screenTwo.resetElements();
    // } else if (this.activeStartSlide === 'screen-3') {
    //   this._svgSection.screenThree.resetElements();
    // } else if (this.activeStartSlide === 'screen-4') {
    //   this._svgSection.screenFour.resetElements();
    // } else if (this.activeStartSlide === 'screen-5') {
    //   this._svgSection.screenFive.resetElements();
    // } else if (this.activeStartSlide === 'screen-6') {
    //   this._svgSection.screenSix.resetElements();
    // } else if (this.activeStartSlide === 'screen-7') {
    //   this._svgSection.screenSeven.resetElements();
    // }

    // if (this.previousTraversedSlide === 'screen-2') {
    //   this._svgSection.screenTwo.climaxAnimation();
    // } else if (this.previousTraversedSlide === 'screen-3') {
    //   this._svgSection.screenThree.climaxAnimation();
    // } else if (this.previousTraversedSlide === 'screen-4') {
    //   this._svgSection.screenFour.climaxAnimation();
    // } else if (this.previousTraversedSlide === 'screen-5') {
    //   this._svgSection.screenFive.climaxAnimation();
    // } else if (this.previousTraversedSlide === 'screen-6') {
    //   this._svgSection.screenSix.climaxAnimation();
    // } else if (this.previousTraversedSlide === 'screen-7') {
    //   this._svgSection.screenSeven.climaxAnimation();
    // }

    if (['screen-1', 'screen-8'].includes(this.activeStartSlide)) {
      this.addStep = false;
      this.add6Step = false;
    }
    // this._svgSection.changeScreen();
  }


  scrollTo(selector, type, x = 0, y = 0) {
    if (type == 'id')
      document.querySelector(`#${selector}`).scrollTo(x, y);
    else
      document.querySelectorAll(`.${selector}`).forEach((scrollEl, index) => scrollEl.scrollTo(x, y));
  }

  public onFullPageSlideChangeComplete(event) {
    this.activeSlide = event;

    switch (this.prevSlide.split('-')[1]) {
      case '1':
        this._svgSection.screenSix.stopAnimation();
        this._svgSection.screenOne.stopAnimation(); break;
      case '2':
        this._svgSection.screenTwo.stopAnimation(); break;
      case '3':
        this._svgSection.screenThree.stopAnimation(); break;
      case '4':
        this._svgSection.screenFour.stopAnimation(); break;
      case '5':
        this._svgSection.screenFive.stopAnimation(); break;
      case '6':
        this._svgSection.screenSix.stopAnimation(); break;
      case '7':
        this._svgSection.screenSeven.stopAnimation(); break;
      case '8':
        this._svgSection.screenEight.stopAnimation(); break;
    }
    this.scrollTo('scroll-wrapper', 'class', 0, 0);
    // this._svgSection.screenTwo.stopAnimation();
    // this._svgSection.screenThree.stopAnimation();
    // this._svgSection.screenFour.stopAnimation();
    // this._svgSection.screenFive.stopAnimation();
    // this._svgSection.screenSix.stopAnimation();
    // this._svgSection.screenSeven.stopAnimation();
    // this._svgSection.screenEight.stopAnimation();
    // this._svgSection.screenEight.stopAnimation();
    // this._svgSection.screenNine.stopAnimation();
    // this._svgSection.screenTen.stopAnimation();
    // this._svgSection.screenEleven.stopAnimation();
    // this._svgSection.screenTwelve.stopAnimation();
    // this._svgSection.screenThirteen.stopAnimation();
    // this._svgSection.screenFourteen.stopAnimation();
    // this._svgSection.screenFifteen.stopAnimation();
    // this._svgSection.screenSixteen.stopAnimation();

    if (['screen-2', 'screen-3', 'screen-4', 'screen-5'].includes(this.activeSlide)) {
      setTimeout(() => {
        this.addStep = true;
      }, 600);
    } else if (['screen-6', 'screen-7'].includes(this.activeSlide)) {
      setTimeout(() => {
        this.add6Step = true;
      }, 600);
    }
    else {
      this.add6Step = false;
      this.addStep = false;
    }

    // if (['screen-1', 'screen-7'].includes(this.activeSlide)) {
    //   this._svgSection.commonLeftSection.manageTravellersGroup();
    // } else {
    //   this._svgSection.commonLeftSection.manageTravellersGroup(1);
    // }
    // // this._svgSection.manageScreenwiseAnimation.changeAnimation();
    // this.selectedProcessId = -1;
    if (event === 'screen-1') {
      this._svgSection.commonAnimation.hideGroup();
    }
    // setTimeout(() => {
    switch (event) {
      case 'screen-1':
        this._svgSection.screenOne.startAnimation(!this.animationStarted.screen1);
        this._svgSection.commonAnimation.hideAmerica();
        this.animationStarted.screen1 = true;
        break;
      case 'screen-2':
        this._svgSection.commonAnimation.showGroup();
        this._svgSection.screenTwo.startAnimation(!this.animationStarted.screen2);
        this._svgSection.commonAnimation.hideAmerica();
        this.animationStarted.screen2 = true;
        this._svgSection.commonAnimation.showTestTube();
        this._svgSection.commonAnimation.startAnimation(!this.testTubeAnimationStarted);
        this.testTubeAnimationStarted = true;
        break;
      case 'screen-3':
        this._svgSection.screenThree.startAnimation(!this.animationStarted.screen3);
        this.animationStarted.screen3 = true;
        this._svgSection.commonAnimation.showAmerica();
        this._svgSection.commonAnimation.hideHospitalBed();
        break;
      case 'screen-4':
        this._svgSection.screenFour.startAnimation(!this.animationStarted.screen4);
        this.animationStarted.screen4 = true;
        this._svgSection.commonAnimation.showHospitalBed();
        this._svgSection.commonAnimation.showAmerica();
        break;
      case 'screen-5':
        this._svgSection.screenFive.startAnimation(!this.animationStarted.screen5);
        this.animationStarted.screen5 = true;
        this._svgSection.commonAnimation.showHospitalBed();
        this._svgSection.commonAnimation.hideAmerica();
        break;
      case 'screen-6':
        this._svgSection.screenSix.startAnimation(!this.animationStarted.screen6);
        this._svgSection.commonAnimation.hideHospitalBed();
        this._svgSection.commonAnimation.chnageLiquidColorBack();
        this.animationStarted.screen6 = true;
        break;
      case 'screen-7':
        this._svgSection.screenSeven.startAnimation(!this.animationStarted.screen7);
        this.animationStarted.screen7 = true;
        this._svgSection.commonAnimation.startLiquidAnimation();
        break;
      case 'screen-8':
        this._svgSection.screenEight.startAnimation(!this.animationStarted.screen8);
        this.animationStarted.screen8 = true;
        this._svgSection.commonAnimation.chnageLiquidColorBack();
        break;
      // case 'screen-8':
      //   this._svgSection.screenEight.startAnimation(!this.animationStarted.screen8);
      //   this.animationStarted.screen8 = true;
      //   break;
      // case 'screen-9':
      //   this._svgSection.screenNine.startAnimation(!this.animationStarted.screen9);
      //   this.animationStarted.screen9 = true;
      //   break;
      // case 'screen-10':
      //   this._svgSection.screenTen.startAnimation(!this.animationStarted.screen10);
      //   this.animationStarted.screen10 = true;
      //   break;
      // case 'screen-11':
      //   this._svgSection.screenEleven.startAnimation(!this.animationStarted.screen11);
      //   this.animationStarted.screen11 = true;
      //   break;
      // case 'screen-12':
      //   this._svgSection.screenTwelve.startAnimation(!this.animationStarted.screen12);
      //   this.animationStarted.screen12 = true;
      //   break;
      // case 'screen-13':
      //   this._svgSection.screenThirteen.startAnimation(!this.animationStarted.screen13);
      //   this.animationStarted.screen13 = true;
      //   break;
      // case 'screen-14':
      //   this._svgSection.screenFourteen.startAnimation(!this.animationStarted.screen14);
      //   this.animationStarted.screen14 = true;
      //   break;
      // case 'screen-15':
      //   this._svgSection.screenFifteen.startAnimation(!this.animationStarted.screen15);
      //   this.animationStarted.screen15 = true;
      //   break;
      // case 'screen-16':
      //   this._svgSection.screenSix.startAnimation(!this.animationStarted.screen16);
      //   this.animationStarted.screen16 = true;
      //   break;
    }
    // }, 1000);
  }
  public indicatorSettings = [
    {
      id: 1,
      label: 'Introduction'
    },
    {
      id: 2,
      label: 'Current Challenges'
    },
    {
      id: 6,
      label: 'Approach and Solution'
    },
    {
      id: 8,
      label: 'Outcome and Benefits'
    }
  ];


  private renderStory() {
    this._svgSection = this._initSvgSection('svg-wrapper');
    this._svgSection.screenOne.stopAnimation();
    this._svgSection.screenTwo.stopAnimation();
    this._svgSection.screenThree.stopAnimation();
    this._svgSection.screenFour.stopAnimation();
    this._svgSection.screenFive.stopAnimation();
    this._svgSection.screenSix.stopAnimation();
    this._svgSection.screenSeven.stopAnimation();
    this._svgSection.screenEight.stopAnimation();
    // this._svgSection.screenEight.stopAnimation();
    // this._svgSection.screenNine.stopAnimation();
    // this._svgSection.screenTen.stopAnimation();
    // this._svgSection.screenEleven.stopAnimation();
    // this._svgSection.screenTwelve.stopAnimation();
    // this._svgSection.screenThirteen.stopAnimation();
    // this._svgSection.screenFourteen.stopAnimation();
    // this._svgSection.screenFifteen.stopAnimation();
    // this._svgSection.screenSixteen.stopAnimation();
    setTimeout(() => {
      this.activeStartSlide = "screen-1";
      this.activeSlide = 'screen-1';
      // this.addClassToDom('slider-1', 'animate-in', 'id');
    }, 100);
    setTimeout(() => {

      this._svgSection.screenOne.startAnimation(!this.animationStarted.screen1);
      // this.animationStarted.screen1 = true;
    }, 500);
  }
  public onDownArrowClick(sectionId) {
    this.fpScroll.navigateTo(sectionId);
  }

  changeDirectiveScrollEvent(state: boolean) {
    // this.isMenuOpen = state;
    // this.fpScroll.setEventState(state);
    // this.onAnimationEnd();
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'), 10);
    const height = parseInt(svg.attr('height'), 10);
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'), 10);
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width'), 10),
        height: parseInt(svg.style('height'), 10)
      };
    };
    svg.attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width'), 10) / width,
      heightAspect: parseInt(svg.style('height'), 10) / height
    };
  }

  private _initSvgSection(containerId) {
    const that = this;
    let baseImage = '';
    const visitedScreen = {
      screen1: false,
      screen2: false,
      screen3: false,
      screen4: false,
      screen5: false,
      screen6: false,
    }
    const container = d3.select('#' + containerId);
    const WIDTH = 1920;
    const HEIGHT_OFFSET = 969 / 1920;
    const HEIGHT = WIDTH * HEIGHT_OFFSET;
    const svg = container.append('svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT)
      .on('click', function () {
      });

    const dataGroup = svg.append('g')
      .attr('id', 'svg-data')
      .attr('class', 'svg-data');



    const image = dataGroup.append('image')
      .attr('xlink:href', baseImage)
      .attr('class', 'base-image-group')
      .attr('height', 969)
      .attr('width', 1920)
      .attr('id', 'img');

    const line = d3.svg.line()
      .x((d) => d[0])
      .y((d) => d[1]);

    const createCircle = (group, x, y, r, fill = 'none', stroke = 'black') => {
      group.append('circle')
        .attr({
          'cx': x,
          'cy': y,
          'r': r,
          'fill': fill,
          'stroke': stroke
        });
    }
    const addDashedLine = (group, lineData, className, strokeColor = '#000000', strokeDash = '8, 5', strokeWidth = 1, alreadyDraw = true) => {
      return group.append('path')
        .datum(lineData)
        .attr('d', line)
        .attr('stroke', strokeColor)
        .attr('class', `animate ${className}`)
        .attr('stroke-width', strokeWidth)
        .attr('stroke-dasharray', function (d: any) {
          d.pathLength = d3.select(this).node().getTotalLength();
          const dashing = strokeDash;
          const dashLength =
            dashing
              .split(/[\s,]/)
              .map(function (a) { return parseFloat(a) || 0 })
              .reduce(function (a, b) { return a + b; });

          const dashCount = Math.ceil(d.pathLength / dashLength);
          const newDashes = new Array(dashCount).join(dashing + ' ');
          const dashArray = newDashes + ' 1 , ' + (d.pathLength + 5);
          return dashArray;
        })
        .attr('stroke-dashoffset', function (d: any) {
          return (alreadyDraw ? 0 : d.pathLength);
        })
        // .attr('stroke-dashoffset', (d: any) => 0)
        .attr('fill', 'none');
    };
    const addLine = (group, pathData, selector, key, strokeColor = '#000000', strokeWidth = 1, alreadyDraw = true) => {
      return group.append('path')
        .datum(pathData)
        .attr('d', line)
        .attr('class', `animate ${selector}`)
        .attr('id', `${selector}-${key}`)
        .attr('stroke', strokeColor)
        .attr('stroke-width', strokeWidth)
        .attr('stroke-dasharray', function (d: any) {
          d.pathLength = d3.select(this).node().getTotalLength();
          return d.pathLength;
        })
        .attr('stroke-dashoffset', function (d: any) {
          return (alreadyDraw ? 0 : d.pathLength);
        })
        .attr('fill', 'none');
    };

    const addRect = (group, data) => {
      return group.append('rect')
        .datum(data)
        .attr('class', 'class' in data ? data.class : '')
        .attr('id', 'id' in data ? data.id : '')
        .attr('x', data.x)
        .attr('y', data.y)
        .attr('width', data.width)
        .attr('height', data.height)
        .attr('fill', data.fill)
        .attr('rx', 'rx' in data ? data.rx : 0)
        .style('opacity', 'o' in data ? data.o : 1);
    };

    const createImageUsingData = (group, data: { id: string, src: string, height: number, width: number, x: number, y: number, s?: number, o?: number, class?: string, [k: string]: any, finalX?: number, finalY?: number, textStyle?: any }) => {
      return group
        .append('image')
        .datum(data)
        .attr('id', (d) => d.id)
        .attr('xlink:href', (d) => d.src)
        .attr('class', (d) => `${(d.class || d.id)} animate`)
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .attr('transform', (d) => `translate(${d.x}, ${d.y}) scale(${(data.s !== void 0) ? data.s : 1})`)
        .style('opacity', (d) => (d.o !== void (0)) ? d.o : 1)
        .style('textStyle' in data ? data.textStyle : {});
    };

    const createTextUsingData = (group, data: { wholeText: any, id: string, x: number, y: number, dy: string, s?: number, o?: number, class?: string, textStyle?: object, finalX?: number, finalY?: number }) => {
      const text = group.append('text');
      text
        .datum(data)
        .style('textStyle' in data ? data.textStyle : {})
        .attr({
          'class': (d) => d.class !== void (0) ? d.class : '',
          'id': (d) => d.id,
          'transform': (d) => `translate(${d.x},${d.y})`,
        })
        .style({
          'opacity': 'o' in data ? data.o : 1
        });

      data.wholeText.forEach((textPart, index) => {
        text.append('tspan')
          .attr({
            'dy': 'dy' in textPart ? textPart.dy : data.dy,
            'x': 'x' in textPart ? textPart.x : '',
            'y': 'y' in textPart ? textPart.y : ''
          })
          .style('textStyle' in textPart ? textPart.textStyle : {})
          .text(textPart.text)
      });

      return text;
    }

    function endAll(tran, callback) {
      if ((typeof callback) !== 'function') { throw new Error('Wrong callback in endall'); }
      if (tran.size() === 0) { callback(); }
      let n = 0;
      tran.each(() => { ++n; })
        .each('end', function (e) {
          if (--n === 0) { callback.apply(this, arguments); }
        });
    }

    const showText = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      let selection = group.selectAll(`.${className}`);
      selection
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(duration)
        .style('opacity', 1)
        .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        .call(endAll, cb)
    }

    const hideText = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .style('opacity', 0)
        .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
        .call(endAll, cb)
    }

    const operateLine = (group, className, operation: 'draw' | 'hide', delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .style('opacity', operation === 'hide' ? 0 : 1)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('stroke-dashoffset', d => operation === 'hide' ? d.pathLength : 0)
        .call(endAll, cb)
    }

    const showImage = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('transform', (d: any) => { return `translate(${d.finalX}, ${d.finalY}) scale(${d.s})` })
        .style('opacity', 1)
        .call(endAll, cb)
    }

    const hideImage = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('transform', (d: any) => `translate(${d.x}, ${d.y}) scale(0)`)
        .call(endAll, cb)
    }

    const showGroup = (group, className, delay = 0, duration = 1000, cb = function () { }) => {
      group.selectAll(`.${className}`)
        .transition()
        .delay(d => 'delay' in d ? d.delay : delay)
        .duration(d => 'duration' in d ? d.duration : duration)
        .attr('transform', (d: any) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
        .style('opacity', 1)
        .call(endAll, cb)
    }



    const screenOne = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-1-group')
        .attr('class', 'screen-1-group')
        .style('opacity', 1);

      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen1.png';
          image.attr('xlink:href', baseImage);
          // commonLeftSection.manageTravellersGroup();
        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess
            .transition()
            .delay(100)
            .duration(200)
            .style('opacity', 0);
        }
      }
    })();


    const screenTwo = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-2-group')
        .attr('class', 'screen-2-group')
        .style('opacity', 1);

      const globeGroup = currentProcess.append('g')
        .attr('id', 'globe-group')
        .attr('class', 'globe-group');
      const dataSummaryData = {
        titleContent: {
          text: {
            wholeText: [
              {
                text: 'FINDING RIGHT SITES',
                dy: '0em',
                x: 473,
                y: -190
              }],
            id: 'screen-globe-text-screen-2',
            class: 'screen-globe-text-screen-2 screen-2-text',
            x: 473,
            y: -190,
            dy: '0em',
            finalX: 126,
            finalY: 526,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 26,
              'fill': '#3f3d56',
              'font-weight': 400,
            },
          }
        },
        dataContent: [
          {
            background: {
              id: 'globe-image-1',
              width: 1920,
              height: 591,
              src: 'assets/images/ctemp/SVG/globe.svg',
              x: 0,
              y: 969,
              finalX: 0,
              finalY: 380,
              class: 'screen-2 globe-image-1 background pie-background animate screen-2-images',
              s: 1,
              o: 1
            },
          },
          {
            background: {
              id: 'continents-1',
              width: 1488,
              height: 450,
              src: 'assets/images/ctemp/SVG/continents.svg',
              x: 353,
              y: 969,
              finalX: 353,
              finalY: 505,
              class: 'screen-2 continents-1 background pie-background animate screen-2-images',
              s: 1,
              o: 0.8
            },
          },
          {
            background: {
              id: 'screen-2-country-1',
              width: 219,
              height: 84,
              src: 'assets/images/ctemp/SVG/Country1.svg',
              x: 1298,
              y: 621,
              finalX: 1298,
              finalY: 621,
              class: 'country screen-2-country-1 background pie-background animate screen-7-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-country-2',
              width: 109,
              height: 72,
              src: 'assets/images/ctemp/SVG/Country2.svg',
              x: 1288,
              y: 656,
              finalX: 1288,
              finalY: 656,
              class: 'country screen-2-country-2 background pie-background animate screen-7-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-country-3',
              width: 88,
              height: 41,
              src: 'assets/images/ctemp/SVG/Country3.svg',
              x: 1174,
              y: 637,
              finalX: 1174,
              finalY: 637,
              class: 'country screen-2-country-3 background pie-background animate screen-7-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-country-4',
              width: 81,
              height: 48,
              src: 'assets/images/ctemp/SVG/Country4.svg',
              x: 1048,
              y: 727,
              finalX: 1048,
              finalY: 727,
              class: 'country screen-2-country-4 background pie-background animate screen-7-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-country-5',
              width: 186,
              height: 114,
              src: 'assets/images/ctemp/SVG/Country5.svg',
              x: 633,
              y: 734,
              finalX: 633,
              finalY: 734,
              class: 'country screen-2-country-5 background pie-background animate screen-7-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-country-6',
              width: 240,
              height: 67,
              src: 'assets/images/ctemp/SVG/Country6.svg',
              x: 468,
              y: 622,
              finalX: 468,
              finalY: 622,
              class: 'country screen-2-country-6 background pie-background animate screen-7-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-country-7',
              width: 112,
              height: 50,
              src: 'assets/images/ctemp/SVG/Country7.svg',
              x: 952,
              y: 593,
              finalX: 952,
              finalY: 593,
              class: 'country screen-2-country-7 background pie-background animate screen-2-images',
              s: 1,
              o: 0
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-1',
              width: 100.8,
              height: 91.8,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 1480,
              y: 656,
              finalX: 1430,
              finalY: 610,
              class: 'hospital screen-2-hospital-1 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-6',
              width: 168,
              height: 153,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 562,
              y: 588,
              finalX: 478,
              finalY: 512,
              class: 'hospital screen-2-hospital-6 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-4',
              width: 100.8,
              height: 81.8,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 1118,
              y: 723,
              finalX: 1068,
              finalY: 682,
              class: 'hospital screen-2-hospital-4 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-1_1',
              width: 84,
              height: 76.5,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 1382,
              y: 626,
              finalX: 1330,
              finalY: 588,
              class: 'hospital screen-2-hospital-1_1 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-5',
              width: 122.4,
              height: 134.4,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 755,
              y: 745,
              finalX: 694,
              finalY: 678,
              class: 'hospital screen-2-hospital-5 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-2',
              width: 100.8,
              height: 81.8,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 1325,
              y: 730,
              finalX: 1275,
              finalY: 690,
              class: 'hospital screen-2-hospital-2 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-7',
              width: 100.8,
              height: 81.8,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 995,
              y: 579,
              finalX: 945,
              finalY: 538,
              class: 'hospital screen-2-hospital-7 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'screen-2-Hospital-3',
              width: 100.8,
              height: 91.8,
              src: 'assets/images/ctemp/SVG/hospital.svg',
              x: 1225,
              y: 624,
              finalX: 1175,
              finalY: 578,
              class: 'hospital screen-2-hospital-3 background pie-background animate screen-2-images',
              s: 0,
              o: 1
            },
          },
        ]
      };

      const lineGroup = currentProcess.append('g')
        .attr('id', 'screen-2-line-group')
        .attr('class', 'screen-2-line-group');

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = globeGroup
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `globe-group globe-group-${index}`,
            'id': `globe-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        // createTextUsingData(group, content.text);
      });

      createTextUsingData(lineGroup, dataSummaryData.titleContent.text);

      const listData = {
        lines: [
          [[893, 486], [740, 362]],
          [[740, 362], [475, 360]],
        ],
      }
      listData.lines.forEach((el, i) => {
        let index = i;
        addLine(lineGroup, el, 'list-line list-line-' + index, index, 'black', 1, false);
      });

      function initiateAnimation() {
        const htmlDelayValue = 500;
        showImage(globeGroup, 'globe-image-1', htmlDelayValue, 300, function () {

        });
        showImage(globeGroup, 'continents-1', htmlDelayValue, 300, function () {
          createCircle(lineGroup, 900, 490, 9);
          operateLine(lineGroup, 'list-line-0', 'draw', htmlDelayValue, 300, function () {
            operateLine(lineGroup, 'list-line-1', 'draw', htmlDelayValue, 300, function () {
              showText(lineGroup, 'screen-globe-text-screen-2', 0, 200, function () {
                let countries = globeGroup.selectAll('.country');
                countries
                  .transition()
                  .delay((d, i) => 100)
                  .duration(100)
                  // .ease('bounce')
                  .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                  .style('opacity', 1)
                  .call(endAll, function () {
                    let hospital = globeGroup.selectAll('.hospital');
                    hospital
                      .transition()
                      .delay((d, i) => i * 500)
                      .duration(500)
                      .ease('bounce')
                      .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                      .style('opacity', 1)
                      .call(endAll, function () {
                      });
                  });
              })
            })
          })
        });

      }
      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen2.png';
          image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          }
        },
        climaxAnimation() {
          currentProcess
            .transition()
            // .duration(300)
            .style('opacity', 0);
        },
        resetElements() {

        },
        stopAnimation() {
          // currentProcess.interrupt();

          currentProcess
            // .transition()
            // .delay(100)
            // .duration(200)
            .style('opacity', 0);
        },
        groups: {
        }
      }

    })();


    const screenThree = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-3-group')
        .attr('class', 'screen-3-group')
        .style('opacity', 1);
      const americaGroup = currentProcess.append('g')
        .attr('id', 'america-group')
        .attr('class', 'america-group');
      const lineGroup = currentProcess.append('g')
        .attr('id', 'screen-3-line-group')
        .attr('class', 'screen-3-line-group');

      const dataSummaryData = {
        titleContent: {
          text: {
            wholeText: [
              {
                text: 'PREDICTION',
                dy: '0em',
                x: 570,
                y: 0
              },
              {
                text: 'ENROLLMENTS',
                dy: '0em',
                x: 570,
                y: 40
              }],
            id: 'screen-america-text-screen-3',
            class: 'screen-america-text-screen-3 screen-3-text',
            x: 473,
            y: -190,
            dy: '0em',
            finalX: 126,
            finalY: 526,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 29,
              'fill': '#3f3d56',
              'font-weight': 500,
            },
          }
        },
        dataContent: [
          {
            background: {
              id: 'america-image-1',
              width: 852,
              height: 775,
              src: 'assets/images/ctemp/SVG/america.svg',
              x: 849,
              y: 969,
              finalX: 849,
              finalY: 170,
              class: 'screen-3 america-image-1 background pie-background animate screen-3-images',
              s: 1,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-1',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 1180,
              y: 599,
              finalX: 1148,
              finalY: 558,
              class: 'screen-3 card card-image-1 animate screen-3-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-2',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 1100,
              y: 553,
              finalX: 1068,
              finalY: 512,
              class: 'screen-3 card card-image-2 animate screen-3-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-3',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 1020,
              y: 511,
              finalX: 988,
              finalY: 470,
              class: 'screen-3 card card-image-3 animate screen-3-images',
              s: 0,
              o: 1
            },
          },

          {
            background: {
              id: 'card-image-4',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 1112,
              y: 621,
              finalX: 1080,
              finalY: 580,
              class: 'screen-3 card card-image-4 animate screen-3-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-5',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 1035,
              y: 580,
              finalX: 1003,
              finalY: 539,
              class: 'screen-3 card card-image-5 animate screen-3-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-6',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 951,
              y: 536,
              finalX: 919,
              finalY: 495,
              class: 'screen-3 card card-image-6 animate screen-3-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-7',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 1035,
              y: 658,
              finalX: 1003,
              finalY: 617,
              class: 'screen-3 card card-image-7 animate screen-3-images',
              s: 0,
              o: 1
            },
          },
          {
            background: {
              id: 'card-image-8',
              width: 65,
              height: 83,
              src: 'assets/images/ctemp/SVG/card.svg',
              x: 951,
              y: 611,
              finalX: 919,
              finalY: 570,
              class: 'screen-8 card card-image-8 animate screen-8-images',
              s: 0,
              o: 1
            },
          },
        ]
      };

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = americaGroup
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `america-group america-group-${index}`,
            'id': `america-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        // createTextUsingData(group, content.text);
      });
      createTextUsingData(americaGroup, dataSummaryData.titleContent.text);

      const listData = {
        lines: [
          [[1040, 435], [916, 435]],
          [[916, 435], [852, 528]],
          [[1319, 710], [794, 710]],
          [[794, 710], [672, 611]]
        ],
      }
      listData.lines.forEach((el, i) => {
        let index = i;
        addLine(lineGroup, el, 'list-line list-line-' + index, index, "black", 1, false);
      });
      function initiateAnimation() {
        const htmlDelayValue = 500;
        showImage(dataGroup, 'common-america-image-1', 500, 300, function () {

        })
        // showImage(americaGroup, 'america-image-1', htmlDelayValue, 300, function () {
        let mainCard = dataGroup.selectAll('.america');
        mainCard
          .transition()
          .delay((d, i) => i * 500)
          .duration(500)
          // .ease('bounce')
          .attr('height', 852)
          .attr('width', 775)
          .attr('transform', (d) => `translate(875, 120) scale(1)`)
          .style('opacity', 1)
          .call(endAll, function () {
            operateLine(lineGroup, 'list-line-0', 'draw', htmlDelayValue, 300, function () {
              operateLine(lineGroup, 'list-line-1', 'draw', htmlDelayValue, 300, function () {
                createCircle(americaGroup, 847, 537, 9);
                operateLine(lineGroup, 'list-line-2', 'draw', htmlDelayValue, 300, function () {
                  operateLine(lineGroup, 'list-line-3', 'draw', htmlDelayValue, 300, function () {
                    createCircle(americaGroup, 664, 607, 9)
                    showText(americaGroup, 'screen-america-text-screen-3', 0, 200, function () {
                      let allCards = americaGroup.selectAll('.card');
                      allCards
                        .transition()
                        .delay((d, i) => i * 300)
                        .duration(300)
                        .ease('bounce')
                        .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                        .attr('opacity', 1)
                        .call(endAll, function () {
                        });
                    })
                  })
                })
              })
            })

          });
        // });

      }

      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen3.png';
          image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          }
        },
        climaxAnimation() {
          currentProcess
            .transition()
            // .duration(300)
            .style('opacity', 0);
        },
        resetElements() {

        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess
            // .transition()
            // .delay(100)
            // .duration(200)
            .style('opacity', 0);
        },
        groups: {
        }
      }
    })();

    const screenFour = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-4-group')
        .attr('class', 'screen-4-group')
        .style('opacity', 1);
      const screenFourGrp = currentProcess.append('g')
        .attr('id', 'screen4-group')
        .attr('class', 'screen4-group');
      const lineGroup = currentProcess.append('g')
        .attr('id', 'screen-3-line-group')
        .attr('class', 'screen-3-line-group');
      const dataSummaryData = {
        titleContent: {
          text: {
            wholeText: [
              {
                text: 'CONTINOUS ON FIELD',
                dy: '0em',
                x: 490,
                y: 10
              },
              {
                text: 'ENROLLMENT MONITORING',
                dy: '0em',
                x: 475,
                y: 42
              }],
            id: 'screen-america-text-screen-3',
            class: 'screen-america-text-screen-3 screen-3-text',
            x: 473,
            y: -190,
            dy: '0em',
            finalX: 126,
            finalY: 526,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 29,
              'fill': '#3f3d56',
              'font-weight': 500,
            },
          }
        },
        dataContent: [
          {
            background: {
              id: 'hospital-bed-1',
              width: 497,
              height: 851,
              src: 'assets/images/ctemp/SVG/hospitalBed.svg',
              x: 800,
              y: 969,
              finalX: 800,
              finalY: 132,
              class: 'screen-4 hospital-bed-1 animate screen-4-images',
              s: 1,
              o: 1
            },
          },
        ]
      }
      dataSummaryData.dataContent.forEach((content, index) => {
        const group = screenFourGrp
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `screen4-group screen4-group-${index}`,
            'id': `screen4-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        // createTextUsingData(group, content.text);
      });
      createTextUsingData(screenFourGrp, dataSummaryData.titleContent.text)
      const listData = {
        lines: [
          [[1024, 407], [899, 407]],
          [[899, 407], [835, 500]],
          [[1319, 710], [794, 710]],
          [[794, 710], [672, 611]]
        ],
      }
      listData.lines.forEach((el, i) => {
        let index = i;
        addLine(lineGroup, el, 'list-line list-line-' + index, index, "black", 1, false);
      });
      function initiateAnimation() {
        const htmlDelayValue = 500;
        // showImage(screenFourGrp, 'hospital-bed-1', htmlDelayValue, 300, function () {

        operateLine(lineGroup, 'list-line-0', 'draw', htmlDelayValue, 300, function () {
          operateLine(lineGroup, 'list-line-1', 'draw', htmlDelayValue, 300, function () {
            createCircle(lineGroup, 831, 508, 9)
            operateLine(lineGroup, 'list-line-2', 'draw', htmlDelayValue, 300, function () {
              operateLine(lineGroup, 'list-line-3', 'draw', htmlDelayValue, 300, function () {
                createCircle(lineGroup, 664, 606, 9);
                showText(screenFourGrp, 'screen-america-text-screen-3', 0, 200, function () {

                  // dataGroup.selectAll('.common-hospital-bed-1')
                })
              })
            })
          })
        })


        // });

      }

      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen4.png';
          image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          } else {
            dataGroup.selectAll('.common-group-2')
              .attr('transform', 'translate(0,0) scale(1)');
            dataGroup.selectAll('.common-hospital-bed-1')
              .attr('transform', 'translate(800,130) scale(1)');
          }
          // commonLeftSection.manageTravellersGroup();
        },
        climaxAnimation() {
          currentProcess
            .transition()
            // .duration(300)
            .style('opacity', 0);
        },
        resetElements() {

        },
        stopAnimation() {
          // currentProcess.interrupt();
          currentProcess
            // .transition()
            // .delay(100)
            // .duration(200)
            .style('opacity', 0);
        },
      }
    })();

    const screenFive = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-5-group')
        .attr('class', 'screen-5-group')
        .style('opacity', 1);
      const screenFiveGrp = currentProcess.append('g')
        .attr('id', 'screen5-group')
        .attr('class', 'screen5-group');
      const lineGroup = currentProcess.append('g')
        .attr('id', 'screen-5-line-group')
        .attr('class', 'screen-5-line-group');
      const dataSummaryData = {
        titleContent: {
          text: {
            wholeText: [
              {
                text: 'TIME PREDICTION FOR',
                dy: '0em',
                x: 453,
                y: 68
              },
              {
                text: 'COMPLETION OF TRIALS',
                dy: '0em',
                x: 453,
                y: 102
              }],
            id: 'screen-america-text-screen-3',
            class: 'screen-america-text-screen-3 screen-3-text',
            x: 473,
            y: -190,
            dy: '0em',
            finalX: 126,
            finalY: 526,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 29,
              'fill': '#3f3d56',
              'font-weight': 500,
            },
          }
        },
        dataContent: [
          {
            background: {
              id: 'hospital-bed-1',
              width: 497,
              height: 851,
              src: 'assets/images/ctemp/SVG/hospitalBed.svg',
              x: 750,
              y: 969,
              finalX: 750,
              finalY: -130,
              class: 'screen-4 hospital-bed-1 animate screen-4-images',
              s: 1.65,
              o: 1
            },
          },
          {
            background: {
              id: 'Phase-1',
              width: 133,
              height: 40,
              src: 'assets/images/ctemp/SVG/Phase1.svg',
              x: 1200,
              y: 296,
              finalX: 1350,
              finalY: 296,
              class: 'screen-4 Phase-1 animate screen-4-images',
              s: 1,
              o: 0
            },
          }, {
            background: {
              id: 'Phase-2',
              width: 133,
              height: 40,
              src: 'assets/images/ctemp/SVG/Phase2.svg',
              x: 1312,
              y: 296,
              finalX: 1462,
              finalY: 296,
              class: 'screen-4 Phase-2 animate screen-4-images',
              s: 1,
              o: 0
            },
          }, {
            background: {
              id: 'Phase-3',
              width: 133,
              height: 40,
              src: 'assets/images/ctemp/SVG/Phase3.svg',
              x: 1424,
              y: 296,
              finalX: 1582,
              finalY: 296,
              class: 'screen-4 Phase-3 animate screen-4-images',
              s: 1,
              o: 0
            },
          }, {
            background: {
              id: 'calendar-1',
              width: 96,
              height: 100,
              src: 'assets/images/ctemp/SVG/calender.svg',
              x: 1300,
              y: 376,
              finalX: 1493,
              finalY: 376,
              class: 'screen-4 calendar-1 animate screen-4-images',
              s: 1,
              o: 0
            },
          },
        ]
      }

      dataSummaryData.dataContent.forEach((content, index) => {
        const group = screenFiveGrp
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `screen5-group screen5-group-${index}`,
            'id': `screen5-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        // createTextUsingData(group, content.text);
      });
      createTextUsingData(screenFiveGrp, dataSummaryData.titleContent.text)
      const listData = {
        lines: [
          [[1141, 376], [1201, 314]],
          [[1201, 314], [1316, 314]],
          [[1234, 779], [793, 780]],
          [[793, 780], [671, 681]]
        ],
      }

      listData.lines.forEach((el, i) => {
        let index = i;
        addLine(lineGroup, el, 'list-line list-line-' + index, index, "black", 1, false);
      });

      function initiateAnimation() {
        const htmlDelayValue = 500;
        // showImage(screenFiveGrp, 'hospital-bed-1', htmlDelayValue, 300, function () {
        dataGroup.selectAll('.common-group-2')
          .transition()
          .delay(0)
          .duration(500)
          .attr('transform', 'translate(0,0) scale(1.65)')
          .call(endAll, function () {
          })
        dataGroup.selectAll('.common-hospital-bed-1')
          .transition()
          .delay(0)
          .duration(500)
          .attr('transform', 'translate(455,-80)')
          .call(endAll, function () {
            operateLine(lineGroup, 'list-line-0', 'draw', htmlDelayValue, 300, function () {
              operateLine(lineGroup, 'list-line-1', 'draw', htmlDelayValue, 300, function () {
                createCircle(lineGroup, 1326, 314, 9)
                operateLine(lineGroup, 'list-line-2', 'draw', htmlDelayValue, 300, function () {
                  operateLine(lineGroup, 'list-line-3', 'draw', htmlDelayValue, 300, function () {
                    createCircle(lineGroup, 664, 676, 9);
                    showText(screenFiveGrp, 'screen-america-text-screen-3', 0, 200, function () {
                      showImage(screenFiveGrp, 'Phase-1', htmlDelayValue, 300, function () {
                        showImage(screenFiveGrp, 'Phase-2', htmlDelayValue, 300, function () {
                          showImage(screenFiveGrp, 'Phase-3', htmlDelayValue, 300, function () {
                            showImage(screenFiveGrp, 'calendar-1', htmlDelayValue, 300, function () {

                            })
                          })
                        })
                      })
                    })
                  })
                })
              })
            })
          })


        // });

      }

      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen5.png';
          image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          } else {
            dataGroup.selectAll('.common-group-2')
              .attr('transform', 'translate(0,0) scale(1.65)');
            dataGroup.selectAll('.common-hospital-bed-1')
              .attr('transform', 'translate(455,-80) scale(1)');
          }
          // commonLeftSection.manageTravellersGroup();
        },
        climaxAnimation() {
          currentProcess
            .transition()
            // .duration(300)
            .style('opacity', 0);
        },
        resetElements() {

        },
        stopAnimation() {
          // currentProcess.interrupt();
          // currentProcess.style('opacity', 0);
          currentProcess
            // .transition()
            // .delay(100)
            // .duration(200)
            .style('opacity', 0);

        }
      }
    })();

    const screenSix = (() => {
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-6-group')
        .attr('class', 'screen-6-group')
        .style('opacity', 1);
      const businessProcess = currentProcess.append('g')
        .attr('id', 'screen6-group')
        .attr('class', 'screen6-group')
        .style('opacity', 1);
      const processStepsGroup = currentProcess.append('g')
        .attr('id', 'process-steps')
        .attr('class', 'process-steps')
        .style('opacity', 1);
      const lineGroup = currentProcess.append('g')
        .attr('id', 'line-group')
        .attr('class', 'line-group')
        .style('opacity', 1);

      const dataSummaryData = {
        dataContent: [
          {
            text: {
              wholeText: [
                {
                  text: 'Strategy',
                  dy: '0em',
                  x: 475,
                  y: -8
                },
                {
                  text: 'and',
                  dy: '0em',
                  x: 477,
                  y: 61
                },
                {
                  text: 'Design',
                  dy: '0em',
                  x: 477,
                  y: 129
                }],
              id: 'screen-6-text-1',
              class: 'screen-6-text-1 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 44,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 400,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Planning',
                  dy: '0em',
                  x: 895,
                  y: 55
                },
              ],
              id: 'screen-6-text-2',
              class: 'screen-6-text-2 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 44,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 400,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Operations',
                  dy: '0em',
                  x: 1366,
                  y: 55
                },
              ],
              id: 'screen-6-text-3',
              class: 'screen-6-text-3 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 44,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 400,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Business process',
                  dy: '0em',
                  x: 901,
                  y: -272
                },
              ],
              id: 'screen-6-text-4',
              class: 'screen-6-text-4 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 34,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 600,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Planning',
                  dy: '0em',
                  x: 901,
                  y: 50
                },
              ],
              id: 'screen-6-text-5',
              class: 'screen-6-text-5 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 34,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 600,
              },
            }
          },

        ]
      };

      const processSteps = {
        textContent: {
          wholeText: [
            {
              text: 'Enrollment Forecasting 5-Step Process',
              dy: '0em',
              x: 915,
              y: -103
            }
          ],
          id: 'screen-6-process-steps-main-text',
          class: 'screen-6-process-steps-main-text screen-6-process-steps-text',
          x: 126,
          y: 526,
          dy: '0em',
          finalX: 126,
          finalY: 526,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 33,
            'font-family': 'Roboto',
            'fill': '#000000',
            'font-weight': 500,
          },
        },
        dataContent: [
          {
            background: {
              id: 'process-steps-1',
              width: 240,
              height: 240,
              src: 'assets/images/ctemp/SVG/purple.svg',
              x: 345,
              y: 550,
              finalX: 345,
              finalY: 550,
              class: 'screen-6 process-steps-1 animate screen-6-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Data',
                  dy: '0em',
                  x: 335,
                  y: 135
                }, {
                  text: 'Augmentation',
                  dy: '0em',
                  x: 335,
                  y: 165
                },
              ],
              id: 'screen-6-process-steps-text-1',
              class: 'screen-6-process-steps-text-1 screen-6-process-steps-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'process-steps-2',
              width: 330,
              height: 330,
              src: 'assets/images/ctemp/SVG/blue.svg',
              x: 585,
              y: 510,
              finalX: 585,
              finalY: 510,
              class: 'screen-6 process-steps-2 animate screen-6-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Distribution',
                  dy: '0em',
                  x: 625,
                  y: 125
                }, {
                  text: 'Parameter',
                  dy: '0em',
                  x: 625,
                  y: 150
                }, {
                  text: 'Estimation',
                  dy: '0em',
                  x: 625,
                  y: 180
                },
              ],
              id: 'screen-6-process-steps-text-2',
              class: 'screen-6-process-steps-text-2 screen-6-process-steps-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'process-steps-3',
              width: 240,
              height: 240,
              src: 'assets/images/ctemp/SVG/purple.svg',
              x: 925,
              y: 550,
              finalX: 925,
              finalY: 550,
              class: 'screen-6 process-steps-3 animate screen-6-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Future Events',
                  dy: '0em',
                  x: 915,
                  y: 135
                }, {
                  text: 'Adjustments',
                  dy: '0em',
                  x: 915,
                  y: 165
                },
              ],
              id: 'screen-6-process-steps-text-3',
              class: 'screen-6-process-steps-text-3 screen-6-process-steps-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'process-steps-4',
              width: 330,
              height: 330,
              src: 'assets/images/ctemp/SVG/blue.svg',
              x: 1168,
              y: 510,
              finalX: 1168,
              finalY: 510,
              class: 'screen-6 process-steps-4 animate screen-6-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Forecasting - Mixed',
                  dy: '0em',
                  x: 1206,
                  y: 135
                }, {
                  text: 'Distribution Models',
                  dy: '0em',
                  x: 1206,
                  y: 165
                },
              ],
              id: 'screen-6-process-steps-text-4',
              class: 'screen-6-process-steps-text-4 screen-6-process-steps-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'process-steps-5',
              width: 240,
              height: 240,
              src: 'assets/images/ctemp/SVG/purple.svg',
              x: 1510,
              y: 550,
              finalX: 1510,
              finalY: 550,
              class: 'screen-6 process-steps-5 animate screen-6-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Validations',
                  dy: '0em',
                  x: 1506,
                  y: 150
                },
              ],
              id: 'screen-6-process-steps-text-5',
              class: 'screen-6-process-steps-text-5 screen-6-process-steps-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }
        ]
      };

      const businessProcessImages = {
        textContent: {
          wholeText: [
            {
              text: 'Business process',
              dy: '0em',
              x: 901,
              y: -272
            },
          ],
          id: 'screen-6-text-4',
          class: 'screen-6-text-4 screen-6-text',
          x: 126,
          y: 526,
          dy: '0em',
          finalX: 126,
          finalY: 526,
          o: 0,
          textStyle: {
            'text-anchor': 'middle',
            'font-size': 34,
            'font-family': 'Roboto',
            'fill': '#3f3d56',
            'font-weight': 600,
          },
        },
        dataContent: [
          {
            grp: {
              x: -470,
              y: 0,
              finalX: 0,
              finalY: 0,
              s: 1,
              o: 1,
            },
            background: {
              id: 'business-process-3',
              width: 402,
              height: 402,
              src: 'assets/images/ctemp/SVG/whiteBig.svg',
              x: 1305,
              y: 380,
              finalX: 1305,
              finalY: 380,
              class: 'screen-6 business-process-3 animate screen-6-images',
              s: 1,
              o: 1
            },
            text: {
              wholeText: [
                {
                  text: 'Operations',
                  dy: '0em',
                  x: 1366,
                  y: 55
                },
              ],
              id: 'screen-6-text-3',
              class: 'screen-6-text-3 screen-6-text',
              x: 136,
              y: 536,
              dy: '0em',
              finalX: 136,
              finalY: 536,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 44,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 400,
              },
            }
          },
          {
            grp: {
              x: 0,
              y: 0,
              finalX: 0,
              finalY: 0,
              s: 1,
              o: 1,
            },
            background: {
              id: 'business-process-2',
              width: 402,
              height: 402,
              src: 'assets/images/ctemp/SVG/whiteBig.svg',
              x: 835,
              y: 380,
              finalX: 835,
              finalY: 380,
              class: 'screen-6 business-process-2 animate screen-6-images',
              s: 1,
              o: 1
            }, text: {
              wholeText: [
                {
                  text: 'Planning',
                  dy: '0em',
                  x: 895,
                  y: 55
                },
              ],
              id: 'screen-6-text-2',
              class: 'screen-6-text-2 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 44,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 400,
              },
            }
          },
          {
            grp: {
              x: 420,
              y: 0,
              finalX: 0,
              finalY: 0,
              s: 1,
              o: 1,
            },
            background: {
              id: 'business-process-1',
              width: 402,
              height: 402,
              src: 'assets/images/ctemp/SVG/whiteBig.svg',
              x: 415,
              y: 380,
              finalX: 415,
              finalY: 380,
              class: 'screen-6 business-process-1 animate screen-6-images',
              s: 1,
              o: 1
            },
            text: {
              wholeText: [
                {
                  text: 'Strategy',
                  dy: '0em',
                  x: 475,
                  y: -8
                },
                {
                  text: 'and',
                  dy: '0em',
                  x: 477,
                  y: 61
                },
                {
                  text: 'Design',
                  dy: '0em',
                  x: 477,
                  y: 129
                }],
              id: 'screen-6-text-1',
              class: 'screen-6-text-1 screen-6-text',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 44,
                'font-family': 'Roboto',
                'fill': '#3f3d56',
                'font-weight': 400,
              },
            }
          },
          {
            grp: {
              x: 0,
              y: 0,
              finalX: 0,
              finalY: 0,
              s: 1,
              o: 1,
            },
            background: {
              id: 'business-process-2_black',
              width: 402,
              height: 402,
              src: 'assets/images/ctemp/SVG/blackSmall.svg',
              x: 835,
              y: 380,
              finalX: 835,
              finalY: 380,
              class: 'screen-6 business-process-2_black animate screen-6-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Planning',
                  dy: '0em',
                  x: 901,
                  y: 50
                },
              ],
              id: 'screen-6-text-5',
              class: 'screen-6-text-5 screen-6-text',
              x: 136,
              y: 536,
              dy: '0em',
              finalX: 136,
              finalY: 536,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 34,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 600,
              },
            }
          },
        ]
      };
      // createCircle(businessProcess,603,568,185,'white', 'white')
      // createCircle(businessProcess,1023,568,185,'white', 'white')
      // createCircle(businessProcess,1493,568,185,'white', 'white')
      businessProcessImages.dataContent.forEach((content, index) => {
        const group = businessProcess
          .append('g')
          .datum(content.grp)
          .attr({
            'class': `screen6-group business-process-group-${index}`,
            'id': `business-process-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        createTextUsingData(group, content.text);
      });
      createTextUsingData(businessProcess, businessProcessImages.textContent);

      processSteps.dataContent.forEach((content, index) => {
        const group = processStepsGroup
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `screen6-group process-steps-group process-steps-group-${index}`,
            'id': `process-steps-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        createTextUsingData(group, content.text);
      });
      createTextUsingData(processStepsGroup, processSteps.textContent);
      const listData = {
        lines: [
          [[1591, 203], [1591, 275]],
          [[1591, 275], [1046, 275]],
          [[1046, 275], [1046, 361]],
          [[1044, 477], [1631, 477]],
          [[1044, 477], [461, 477]],
          [[461, 477], [461, 547]],
          [[753, 477], [753, 547]],
          [[1044, 477], [1044, 547]],
          [[1336, 477], [1336, 547]],
          [[1631, 477], [1631, 547]],
        ],
      }

      listData.lines.forEach((el, i) => {
        let index = i;
        addLine(lineGroup, el, 'list-line list-line-' + index, index, "black", 1, false);
      });

      function initiateAnimation() {
        const htmlDelayValue = 200;
        dataGroup.selectAll('.testTube-main-group')
          // .transition()
          // .delay(200)
          // .duration(300)
          .attr('transform', `translate(-60,0) scale(1)`);
        showText(businessProcess, "screen-6-text-4", 0, 0, function () {
          showText(businessProcess, "screen-6-text-3", 0, 0, function () {
            showText(businessProcess, "screen-6-text-2", 0, 0, function () {
              showText(businessProcess, "screen-6-text-1", 0, 0, function () {
                let firstCircle = businessProcess.selectAll('.business-process-group-2');
                let thirdCircle = businessProcess.selectAll('.business-process-group-0');
                firstCircle
                  .transition()
                  .delay(200)
                  .duration(300)
                  .attr({ 'transform': (d: any) => `translate(0,0) scale(1)` })
                  .call(endAll, function () {
                    thirdCircle
                      .transition()
                      .delay(200)
                      .duration(300)
                      .attr({ 'transform': (d: any) => `translate(0,0) scale(1)` })
                      .call(endAll, function () {
                        showText(businessProcess, "screen-6-text-5", htmlDelayValue, 300, function () { })
                        showImage(businessProcess, 'business-process-2_black', htmlDelayValue, 300, function () {
                          hideText(businessProcess, "screen-6-text-2", htmlDelayValue, 300, function () {
                            hideText(businessProcess, "screen-6-text-4", htmlDelayValue, 300, function () {
                              let blackCircle = businessProcess.selectAll('.business-process-2_black');
                              let firstCircle = businessProcess.selectAll('.business-process-group-0');
                              let secondCircle = businessProcess.selectAll('.business-process-group-2');
                              businessProcess.selectAll('.screen-6-text-5')
                                .transition()
                                .delay(200)
                                .duration(200)
                                .attr({ 'transform': (d: any) => `translate(-220,526) scale(1.4)` })
                              blackCircle
                                .transition()
                                .delay(200)
                                .duration(200)
                                .attr({ 'transform': (d: any) => `translate(760,300) scale(1.4)` })
                                .call(endAll, function () {
                                  businessProcess.selectAll('.screen-6-text-5')
                                    .transition()
                                    .delay(200)
                                    .duration(200)
                                    .attr({ 'transform': (d: any) => `translate(136,536) scale(1)` })
                                  blackCircle
                                    .transition()
                                    .delay(200)
                                    .duration(200)
                                    .attr({ 'transform': (d: any) => `translate(835,380) scale(1)` })
                                    .call(endAll, function () {

                                      businessProcess
                                        .transition()
                                        .delay(500)
                                        .duration(300)
                                        .attr({ 'transform': (d: any) => `translate(660,-44) scale(0.37)` })
                                        .call(endAll, function () {
                                          businessProcess.selectAll('.screen-6-text-5')
                                            .transition()
                                            .delay(200)
                                            .duration(200)
                                            .attr({ 'transform': (d: any) => `translate(-220,526) scale(1.4)` })
                                          blackCircle
                                            .transition()
                                            .delay(200)
                                            .duration(200)
                                            .attr({ 'transform': (d: any) => `translate(760,300) scale(1.4)` })
                                            .call(endAll, function () {
                                              firstCircle
                                                .style('opacity', 0.5);
                                              secondCircle
                                                .style('opacity', 0.5);
                                              // operateLine(lineGroup, "list-line-0", "draw", 300, 300, function () {
                                              //   operateLine(lineGroup, "list-line-1", "draw", 300, 300, function () {
                                              operateLine(lineGroup, "list-line-2", "draw", 300, 300, function () {
                                                showText(processStepsGroup, 'screen-6-process-steps-main-text', htmlDelayValue, 300, function () {
                                                  operateLine(lineGroup, "list-line-3", "draw", 300, 300, function () {

                                                  })
                                                  operateLine(lineGroup, "list-line-4", "draw", 300, 300, function () {
                                                    operateLine(lineGroup, "list-line-5", "draw", 300, 300, function () {
                                                    })
                                                    operateLine(lineGroup, "list-line-6", "draw", 300, 300, function () {
                                                    })
                                                    operateLine(lineGroup, "list-line-7", "draw", 300, 300, function () {
                                                    })
                                                    operateLine(lineGroup, "list-line-8", "draw", 300, 300, function () {
                                                    })
                                                    operateLine(lineGroup, "list-line-9", "draw", 300, 300, function () {
                                                      showImage(processStepsGroup, 'process-steps-1', htmlDelayValue, 300, function () {
                                                      })
                                                      showImage(processStepsGroup, 'process-steps-2', htmlDelayValue, 300, function () {
                                                      })
                                                      showImage(processStepsGroup, 'process-steps-3', htmlDelayValue, 300, function () {
                                                      })
                                                      showImage(processStepsGroup, 'process-steps-4', htmlDelayValue, 300, function () {
                                                      })
                                                      showImage(processStepsGroup, 'process-steps-5', htmlDelayValue, 300, function () {
                                                      })
                                                      showText(processStepsGroup, 'screen-6-process-steps-text-1', htmlDelayValue, 300, function () {
                                                      })
                                                      showText(processStepsGroup, 'screen-6-process-steps-text-2', htmlDelayValue, 300, function () {
                                                      })
                                                      showText(processStepsGroup, 'screen-6-process-steps-text-3', htmlDelayValue, 300, function () {
                                                      })
                                                      showText(processStepsGroup, 'screen-6-process-steps-text-4', htmlDelayValue, 300, function () {
                                                      })
                                                      showText(processStepsGroup, 'screen-6-process-steps-text-5', htmlDelayValue, 300, function () {
                                                      })
                                                    })
                                                  })
                                                })
                                              })
                                              //   })
                                              // })
                                            })
                                        });
                                    })
                                })
                            })
                          })
                        })
                      })
                  })
                // showImage(businessProcess,'business-process-1', htmlDelayValue, 300, function() {
                //   showImage(businessProcess,'business-process-2', htmlDelayValue, 300, function() {
                //     showImage(businessProcess,'business-process-3', htmlDelayValue, 300, function() {
                //     })
                //   })
                // })
              })
            })
          })
        })
        d3.selectAll('.process-steps-group-0')
          .on('click', function () {
            that.onDownArrowClick('screen-7');
            that.selectedProcessId = 0;
            that.changedManualy = true;
          });
        d3.selectAll('.process-steps-group-1')
          .on('click', function () {
            that.onDownArrowClick('screen-7');
            that.selectedProcessId = 1;
            that.changedManualy = true;
          });
        d3.selectAll('.process-steps-group-2')
          .on('click', function () {
            that.onDownArrowClick('screen-7');
            that.selectedProcessId = 2;
            that.changedManualy = true;
          });
        d3.selectAll('.process-steps-group-3')
          .on('click', function () {
            that.onDownArrowClick('screen-7');
            that.selectedProcessId = 3;
            that.changedManualy = true;
          });
        d3.selectAll('.process-steps-group-4')
          .on('click', function () {
            that.onDownArrowClick('screen-7');
            that.selectedProcessId = 4;
            that.changedManualy = true;
          })
      }

      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          d3.selectAll('.screen-7-group')
            .attr('transform', 'scale(0)');
          d3.selectAll('.screen-2-common-group')
            .attr('transform', 'scale(0)');
          d3.selectAll('.screen-4-common-group')
            .attr('transform', 'scale(0)');
          // baseImage = '/assets/images/ctemp/screen8.png';
          image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          }
          processStepsGroup.selectAll('.process-steps-group')
          .style('cursor', 'pointer');
          // that.onAnimationEnd();

          // commonLeftSection.manageTravellersGroup();
        },
        stopAnimation() {

          d3.selectAll('.screen-7-group')
            .attr('transform', 'scale(1)');
          d3.selectAll('.screen-2-common-group')
            .attr('transform', 'scale(1)');
          d3.selectAll('.screen-4-common-group')
            .attr('transform', 'scale(1)');
          currentProcess
            .style('opacity', 0);
          processStepsGroup.selectAll('.process-steps-group')
          .style('cursor', 'initial');
        }
      }
    })();

    const screenSeven = (() => {
      let metricsLoaderTimer = null;
      const currentProcess = dataGroup.append('g')
        .attr('id', 'screen-7-group')
        .attr('class', 'screen-7-group')
        .style('opacity', 1);
      const businessProcess = currentProcess.append('g')
        .attr('id', 'process-group')
        .attr('class', 'process-group')
        .style('opacity', 1);
      const lineGroup = currentProcess.append('g')
        .attr('id', 'line-group')
        .attr('class', 'line-group')
        .style('opacity', 1);


      const businessProcessImages = {
        selectedProcess: [{
          background: {
            id: 'selected-0',
            width: 445,
            height: 80,
            src: 'assets/images/ctemp/SVG/selectedProcess.svg',
            x: 454,
            y: 290,
            finalX: 454,
            finalY: 290,
            class: 'screen-7 selected selected-0 animate screen-7-images',
            s: 1,
            o: 0
          },
        },],
        otherImages: [
          {
            background: {
              id: 'selected-circle-0',
              width: 145,
              height: 145,
              src: 'assets/images/ctemp/SVG/selectedCircle.svg',
              x: 387,
              y: 258,
              finalX: 387,
              finalY: 258,
              class: 'screen-7 selected-circle selected-circle-0 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: '1',
                  dy: '0em',
                  x: 486,
                  y: -189
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': '#25A1B2',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'unselected-circle-0',
              width: 145,
              height: 145,
              src: 'assets/images/ctemp/SVG/unselectedCircle.svg',
              x: 387,
              y: 258,
              finalX: 387,
              finalY: 258,
              class: 'screen-7 unselected unselected-circle-0 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: '1',
                  dy: '0em',
                  x: 460,
                  y: 340
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3',
              x: 0,
              y: 0,
              dy: '0em',
              finalX: 0,
              finalY: 0,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'unselected-circle-1',
              width: 145,
              height: 145,
              src: 'assets/images/ctemp/SVG/unselectedCircle.svg',
              x: 387,
              y: 394,
              finalX: 387,
              finalY: 394,
              class: 'screen-7 unselected unselected-circle-1 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: '2',
                  dy: '0em',
                  x: 72,
                  y: 80
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3',
              x: 387,
              y: 394,
              dy: '0em',
              finalX: 387,
              finalY: 394,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'unselected-circle-2',
              width: 145,
              height: 145,
              src: 'assets/images/ctemp/SVG/unselectedCircle.svg',
              x: 387,
              y: 532,
              finalX: 387,
              finalY: 532,
              class: 'screen-7 unselected unselected-circle-2 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: '3',
                  dy: '0em',
                  x: 72,
                  y: 80
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3',
              x: 387,
              y: 532,
              dy: '0em',
              finalX: 387,
              finalY: 532,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'unselected-circle-3',
              width: 145,
              height: 145,
              src: 'assets/images/ctemp/SVG/unselectedCircle.svg',
              x: 387,
              y: 668,
              finalX: 387,
              finalY: 668,
              class: 'screen-7 unselected unselected-circle-3 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: '4',
                  dy: '0em',
                  x: 72,
                  y: 80
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3',
              x: 387,
              y: 668,
              dy: '0em',
              finalX: 387,
              finalY: 668,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }, {
            background: {
              id: 'unselected-circle-4',
              width: 145,
              height: 145,
              src: 'assets/images/ctemp/SVG/unselectedCircle.svg',
              x: 387,
              y: 806,
              finalX: 387,
              finalY: 806,
              class: 'screen-7 unselected unselected-circle-4 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: '5',
                  dy: '0em',
                  x: 72,
                  y: 80
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3',
              x: 387,
              y: 806,
              dy: '0em',
              finalX: 387,
              finalY: 806,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'white',
                'font-weight': 500,
              },
            }
          }
        ],
        dataContent: [
          {
            background: {
              id: 'unselected-0',
              width: 398,
              height: 98,
              src: 'assets/images/ctemp/SVG/unselected.svg',
              x: 447,
              y: 283,
              finalX: 447,
              finalY: 283,
              class: 'screen-7 unselected unselected-0 animate screen-7-images',
              s: 1,
              o: 0
            },
            text: {
              wholeText: [
                {
                  text: 'Data Augmentation',
                  dy: '0em',
                  x: 486,
                  y: -189
                },
              ],
              id: 'screen-7-text-3',
              class: 'screen-7-text-3 labels',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': '#25A1B2',
                'font-weight': 500,
              },
            }
          },
          {
            background: {
              id: 'unselected-1',
              width: 398,
              height: 98,
              src: 'assets/images/ctemp/SVG/unselected.svg',
              x: 447,
              y: 420,
              finalX: 447,
              finalY: 420,
              class: 'screen-7 unselected unselected-1 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Distribution',
                  dy: '0em',
                  x: 448,
                  y: -66
                }, {
                  text: 'Parameter Estimation',
                  dy: '0em',
                  x: 498,
                  y: -38
                },
              ],
              id: 'screen-7-text-2',
              class: 'screen-7-text-2 labels',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': '#25A1B2',
                'font-weight': 500,
              },
            }
          },
          {
            background: {
              id: 'unselected-2',
              width: 398,
              height: 98,
              src: 'assets/images/ctemp/SVG/unselected.svg',
              x: 447,
              y: 557,
              finalX: 447,
              finalY: 557,
              class: 'screen-7 unselected unselected-2 animate screen-7-images',
              s: 1,
              o: 0
            },
            text: {
              wholeText: [
                {
                  text: 'Future Events Adjustments',
                  dy: '0em',
                  x: 523,
                  y: 85
                },],
              id: 'screen-7-text-1',
              class: 'screen-7-text-1 labels',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': '#25A1B2',
                'font-weight': 500,
              },
            }
          },
          {
            background: {
              id: 'unselected-3',
              width: 398,
              height: 98,
              src: 'assets/images/ctemp/SVG/unselected.svg',
              x: 447,
              y: 695,
              finalX: 447,
              finalY: 695,
              class: 'screen-7 unselected unselected-3 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Forecasting - Mixed',
                  dy: '0em',
                  x: 488,
                  y: 208
                }, {
                  text: 'Distribution Models',
                  dy: '0em',
                  x: 488,
                  y: 236
                },
              ],
              id: 'screen-6-text-5',
              class: 'screen-6-text-5 screen-6-text labels',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': '#25A1B2',
                'font-weight': 500,
              },
            }
          },
          {
            background: {
              id: 'unselected-4',
              width: 398,
              height: 98,
              src: 'assets/images/ctemp/SVG/unselected.svg',
              x: 447,
              y: 830,
              finalX: 447,
              finalY: 830,
              class: 'screen-7 unselected unselected-4 animate screen-7-images',
              s: 1,
              o: 0
            }, text: {
              wholeText: [
                {
                  text: 'Validations',
                  dy: '0em',
                  x: 447,
                  y: 360
                },
              ],
              id: 'screen-7-text-5',
              class: 'screen-7-text-5 labels',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 1,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': '#25A1B2',
                'font-weight': 500,
              },
            }
          },
        ],
        chartImages: [
          {
            background: {
              id: 'chart-0',
              width: 1150,
              height: 384,
              src: 'assets/images/ctemp/graphImage.png',
              x: 950,
              y: 620,
              finalX: 950,
              finalY: 620,
              class: 'screen-7 chart chart-0 animate screen-7-images',
              s: 0.8,
              o: 0
            }
          }
        ],
        chartText: [
          {
            text: {
              wholeText: [
                {
                  text: 'Historical Benchmarking',
                  dy: '0em',
                  x: 1000,
                  y: -220
                }, {
                  text: '+',
                  dy: '0em',
                  x: 1250,
                  y: -220
                }, {
                  text: 'Distribution Parameter',
                  dy: '0em',
                  x: 1500,
                  y: -220
                },
              ],
              id: 'screen-7-chart-text-1',
              class: 'chart-text-group screen-chart-7-text-1',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'black',
                'font-weight': 600,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Method of Moments',
                  dy: '0em',
                  x: 1000,
                  y: -220
                }, {
                  text: '+',
                  dy: '0em',
                  x: 1200,
                  y: -220
                }, {
                  text: 'Bayesian Markov Chain Monte-Carlo',
                  dy: '0em',
                  x: 1500,
                  y: -220
                },
              ],
              id: 'screen-7-chart-text-2',
              class: 'chart-text-group screen-chart-7-text-2',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'black',
                'font-weight': 600,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Stratified Sampling',
                  dy: '0em',
                  x: 1000,
                  y: -220
                }, {
                  text: '+',
                  dy: '0em',
                  x: 1250,
                  y: -220
                }, {
                  text: 'Probabilistic Measurement',
                  dy: '0em',
                  x: 1500,
                  y: -220
                },
              ],
              id: 'screen-7-chart-text-3',
              class: 'chart-text-group screen-7-chart-text-3',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'black',
                'font-weight': 600,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Poisson Distribution',
                  dy: '0em',
                  x: 1000,
                  y: -220
                }, {
                  text: '+',
                  dy: '0em',
                  x: 1250,
                  y: -220
                }, {
                  text: 'Gamma Distribution',
                  dy: '0em',
                  x: 1500,
                  y: -220
                },
              ],
              id: 'screen-7-chart-text-4',
              class: 'chart-text-group screen-chart-7-text-4',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'black',
                'font-weight': 600,
              },
            }
          }, {
            text: {
              wholeText: [
                {
                  text: 'Tracking',
                  dy: '0em',
                  x: 1000,
                  y: -220
                }, {
                  text: '+',
                  dy: '0em',
                  x: 1250,
                  y: -220
                }, {
                  text: 'Reforecasting during live Enrollments',
                  dy: '0em',
                  x: 1500,
                  y: -220
                },
              ],
              id: 'screen-7-chart-text-5',
              class: 'chart-text-group screen-chart-7-text-5',
              x: 126,
              y: 526,
              dy: '0em',
              finalX: 126,
              finalY: 526,
              o: 0,
              textStyle: {
                'text-anchor': 'middle',
                'font-size': 22,
                'font-family': 'Roboto',
                'fill': 'black',
                'font-weight': 600,
              },
            }
          }
        ]
      };

      businessProcessImages.selectedProcess.forEach((content, index) => {
        const group = businessProcess
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `screen7-group selected-group-${index}`,
            'id': `selected-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      let lockFun = false;
      businessProcessImages.dataContent.forEach((content, index) => {
        const group = businessProcess
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            'class': `screen7-group business-process-group business-process-group-${index}`,
            'id': `business-process-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o)
          .on('click', function (d: any) {
            that.selectedProcess = content;
              lockFun = true;
              selectProcess(index);
          });
        createImageUsingData(group, content.background);
        createTextUsingData(group, content.text);
      });

      businessProcessImages.otherImages.forEach((content, index) => {
        const group = businessProcess
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `screen7-group unselected-circle-group-${index}`,
            'id': `unselected-circle-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
        createTextUsingData(group, content.text);
      });
      businessProcessImages.chartImages.forEach((content, index) => {
        const group = businessProcess
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `screen7-group chart-group-${index}`,
            'id': `chart-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
          })
          .style('opacity', (d: any) => d.o);
        createImageUsingData(group, content.background);
      });

      businessProcessImages.chartText.forEach((content, index) => {
        const group = businessProcess
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1,
          })
          .attr({
            'class': `screen7-group chart-text-group-${index}`,
            'id': `chart-text-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${d.s})`
          })
          .style('opacity', (d: any) => d.o)
        createTextUsingData(group, content.text);
      });
      let that = this;

      function selectProcess(index) {
        that.changedManualy = false;
        const chartData = [
          {
            "country": "India",
            "years": [
              {
                "year": "2020",
                "enrollment": "85",
                "siteFailure": "7",
                "emptySites": "4",
                "screeningFailures": "2.5",
                "patientDropOff": "3"
              },
              {
                "year": "2021",
                "enrollment": "45",
                "siteFailure": "3",
                "emptySites": "2",
                "screeningFailures": "0.5",
                "patientDropOff": "1"
              }
            ]
          },
          {
            "country": "Brazil",
            "years": [
              {
                "year": "2020",
                "enrollment": "75",
                "siteFailure": "5",
                "emptySites": "1",
                "screeningFailures": "0.5",
                "patientDropOff": "2"
              },
              {
                "year": "2021",
                "enrollment": "55",
                "siteFailure": "2",
                "emptySites": "2",
                "screeningFailures": "1.5",
                "patientDropOff": "5"
              }
            ]
          },
          {
            "country": "United States",
            "years": [
              {
                "year": "2020",
                "enrollment": "35",
                "siteFailure": "5",
                "emptySites": "1",
                "screeningFailures": "0.5",
                "patientDropOff": "2"
              },
              {
                "year": "2021",
                "enrollment": "95",
                "siteFailure": "6",
                "emptySites": "7",
                "screeningFailures": "3.5",
                "patientDropOff": "1"
              }
            ]
          },
          {
            "country": "UK",
            "years": [
              {
                "year": "2020",
                "enrollment": "60",
                "siteFailure": "8",
                "emptySites": "2",
                "screeningFailures": "6.5",
                "patientDropOff": "1"
              },
              {
                "year": "2021",
                "enrollment": "65",
                "siteFailure": "2",
                "emptySites": "4",
                "screeningFailures": "1.5",
                "patientDropOff": "3"
              }
            ]
          },
          {
            "country": "China",
            "years": [
              {
                "year": "2020",
                "enrollment": "40",
                "siteFailure": "8",
                "emptySites": "3",
                "screeningFailures": "1.5",
                "patientDropOff": "8"
              },
              {
                "year": "2021",
                "enrollment": "75",
                "siteFailure": "3",
                "emptySites": "5",
                "screeningFailures": "2.5",
                "patientDropOff": "0"
              }
            ]
          }
        ]
        deSelectCurrentProcess();
        that.selectedProcessId = index;
        let x = 0, y = 0;
        that.prevProcess = that.selectedProcess;
        businessProcess.selectAll('.selected-0')
          .attr('transform', `translate(${that.selectedProcess.background.x}, ${that.selectedProcess.background.y + 7}) scale(1)`)
          .style('opacity', 1);
        businessProcess.selectAll(`.${that.selectedProcess.background.id}`)
          .style('opacity', 0);
        clearChartData();
        hideImage(businessProcess, 'chart-0', 200, 300, function () { })
        let graphBox = document.getElementsByTagName('foreignObject');
        if (graphBox.length) {
          graphBox[0].remove();
        }
        d3.selectAll('#chart-legend-0').style('opacity', 0);
        d3.selectAll(".chart-legend-group-0").remove();
        d3.selectAll(".chart-legend-text-group-0").remove();
        operateLine(lineGroup, 'chart-line-0', 'hide', 300, 300, function () { })
        operateLine(lineGroup, 'chart-line-1', 'hide', 300, 300, function () { })
        operateLine(lineGroup, 'chart-line-2', 'hide', 300, 300, function () { })
        operateLine(lineGroup, 'chart-line-3', 'hide', 300, 300, function () { })
        operateLine(lineGroup, 'chart-line-4', 'hide', 300, 300, function () { })
        switch (index) {
          case 0: x = 387; y = 258;
            showText(businessProcess, 'screen-chart-7-text-1', 200, 300, function () { })
            businessProcess.append('foreignObject')
              .attr('x', 956)
              .attr('y', 355)
              .attr('width', 920)
              .attr('height', 563)
              .append('xhtml:div')
              .attr({
                'class': `graph-1`
              })
              .style({
                'opacity': 1,
                'transition': (d, i) => `all 500ms ease-in-out`
              })
              .append('xhtml:div')
              .attr({
                'class': `graph-1-child`
              })
              .html(d => `
            <div class="graph-box">
                  <div class="d-flex align-items-center justify-content-between">
                  <div>
                      <h5>Historical Activation Curve</h5>
                      <span>Monte Carlo Simulation</span>
                  </div>
                  <div class="select-wrapper">
                    <select id="selectYear" value="2021">
                        <option value="2021" id="year2021">2021</option>
                        <option value="2020" id="year2020">2020</option>
                        <option value="2019" id="year3">2019</option>
                    </select>
                  </div>
                  </div>
                  <div class="row"> 
                  <svg  id="area1" class="chart-1"></svg>
                  </div>
              </div>`);
            createLineChart();
            break;
          case 1: x = 387; y = 394;
            showText(businessProcess, 'screen-chart-7-text-2', 200, 300, function () { })
            businessProcess.append('foreignObject')
              .attr('x', 956)
              .attr('y', 355)
              .attr('width', 920)
              .attr('height', 563)
              .append('xhtml:div')
              .attr({
                'class': `graph-1`
              })
              .style({
                'opacity': 1,
                'transition': (d, i) => `all 500ms ease-in-out`
              })
              .append('xhtml:div')
              .attr({
                'class': `graph-1-child`
              })
              .html(d => `<div class="graph-box">
                  <div class="d-flex align-items-center justify-content-between">
                  <div>
                      <h5>Distribution Curves</h5>
                      <span>For Pre & Post Feasibility </span>
                  </div>
                  <div class="d-flex flex-column">
                    <p class="mb-1">Toggle to see different distribution views</p>
                    <div class="button-set d-flex">
                      <button id="gamma" class="active">Gamma</button>
                      <button id="triangular" class="">Triangular</button>
                      <button id="halfnorm" class="">Halfnorm</button>
                    </div>
                    </div>
                  </div>
                  <svg id="area3"></svg>
                  
                  <div id="row"> 
                  </div>
              </div>`);
            createAreaChart();
            break;
          case 2: x = 387; y = 532;
            businessProcess.append('foreignObject')
              .attr('x', 956)
              .attr('y', 355)
              .attr('width', 920)
              .attr('height', 563)
              .append('xhtml:div')
              .attr({
                'class': `graph-1`
              })
              .style({
                'opacity': 1,
                'transition': (d, i) => `all 500ms ease-in-out`
              })
              .append('xhtml:div')
              .attr({
                'class': `graph-1-child`
              })
              .html(d => `<div class="graph-box">
          <div class="d-flex justify-content-between">
            <div class="select-set ml-auto d-flex">
            <div class="select-wrapper">
              <select id="chartGlobe-1-country" value="United States">
                <option value="United States">United States</option>
                <option value="UK">UK</option>
                <option value="China">China</option>
                <option value="India">India</option>
                <option value="Brazil">Brazil</option>
              </select>
            </div>
            <div class="select-wrapper">
              <select id="chartGlobe-1-year" value="2021">
                <option value="2021" >2021</option>
                <option value="2020">2020</option>
              </select>
            </div>
            </div>
          </div>
          <div class="status-box mt-4 position-relative">
            <div id = "metrics-loader"></div>
              <ul class="p-0 mb-0">
                <li class="d-flex">
                  <span>Site Failure</span>
                  <span id="siteFailure">6%</span>
                </li>
                <li class="d-flex">
                  <span>Empty Sites</span>
                  <span id="emptySites">7%</span>
                </li>
                <li class="d-flex">
                  <span>Screening Failure</span>
                  <span id="screeningFailures">3.5%</span>
                </li>
                <li class="d-flex mb-0">
                  <span>Patient Drop Off</span>
                  <span id="patientDropOff">1%</span>
                </li>
              </ul>
            </div>
          <svg id="chart-globe"></svg> 
        </div>`);
            const dataSummaryData2 = {
              dataContent: [
                {
                  background: {
                    id: 'globe-image-1',
                    width: 1920,
                    height: 591,
                    src: 'assets/images/ctemp/SVG/globe.svg',
                    x: 0,
                    y: 969,
                    finalX: 0,
                    finalY: 380,
                    class: 'screen-2 globe-image-1 background pie-background animate screen-2-images',
                    s: 1,
                    o: 1
                  },
                },
                {
                  background: {
                    id: 'continents-1',
                    width: 1488,
                    height: 450,
                    src: 'assets/images/ctemp/SVG/continents.svg',
                    x: 353,
                    y: 969,
                    finalX: 353,
                    finalY: 505,
                    class: 'screen-2 continents-1 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0.8
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-1',
                    width: 219,
                    height: 84,
                    src: 'assets/images/ctemp/SVG/Country1.svg',
                    x: 1298,
                    y: 621,
                    finalX: 1298,
                    finalY: 621,
                    class: 'country screen-2-country-1 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-2',
                    width: 109,
                    height: 72,
                    src: 'assets/images/ctemp/SVG/Country2.svg',
                    x: 1288,
                    y: 656,
                    finalX: 1288,
                    finalY: 656,
                    class: 'country screen-2-country-2 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                // {
                //   background: {
                //     id: 'screen-2-country-3',
                //     width: 88,
                //     height: 41,
                //     src: 'assets/images/ctemp/SVG/Country3.svg',
                //     x: 1174,
                //     y: 637,
                //     finalX: 1174,
                //     finalY: 637,
                //     class: 'country screen-2-country-3 background pie-background animate screen-7-images',
                //     s: 1,
                //     o: 0
                //   },
                // },
                // {
                //   background: {
                //     id: 'screen-2-country-4',
                //     width: 81,
                //     height: 48,
                //     src: 'assets/images/ctemp/SVG/Country4.svg',
                //     x: 1048,
                //     y: 727,
                //     finalX: 1048,
                //     finalY: 727,
                //     class: 'country screen-2-country-4 background pie-background animate screen-7-images',
                //     s: 1,
                //     o: 0
                //   },
                // },
                {
                  background: {
                    id: 'screen-2-country-5',
                    width: 186,
                    height: 114,
                    src: 'assets/images/ctemp/SVG/Country5.svg',
                    x: 633,
                    y: 734,
                    finalX: 633,
                    finalY: 734,
                    class: 'country screen-2-country-5 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-6',
                    width: 240,
                    height: 67,
                    src: 'assets/images/ctemp/SVG/Country6.svg',
                    x: 468,
                    y: 622,
                    finalX: 468,
                    finalY: 622,
                    class: 'country screen-2-country-6 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-7',
                    width: 112,
                    height: 50,
                    src: 'assets/images/ctemp/SVG/Country7.svg',
                    x: 952,
                    y: 593,
                    finalX: 952,
                    finalY: 593,
                    class: 'country screen-2-country-7 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-1',
                    width: 100.8,
                    height: 91.8,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 1480,
                    y: 656,
                    finalX: 1430,
                    finalY: 610,
                    class: 'hospital screen-2-hospital-1 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-6',
                    width: 168,
                    height: 153,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 562,
                    y: 588,
                    finalX: 478,
                    finalY: 512,
                    class: 'hospital screen-2-hospital-6 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                // {
                //   background: {
                //     id: 'screen-2-Hospital-4',
                //     width: 100.8,
                //     height: 81.8,
                //     src: 'assets/images/ctemp/SVG/hospital.svg',
                //     x: 1118,
                //     y: 723,
                //     finalX: 1068,
                //     finalY: 682,
                //     class: 'hospital screen-2-hospital-4 background pie-background animate screen-2-images',
                //     s: 0,
                //     o: 1
                //   },
                // },
                // {
                //   background: {
                //     id: 'screen-2-Hospital-1_1',
                //     width: 84,
                //     height: 76.5,
                //     src: 'assets/images/ctemp/SVG/hospital.svg',
                //     x: 1382,
                //     y: 626,
                //     finalX: 1330,
                //     finalY: 588,
                //     class: 'hospital screen-2-hospital-1_1 background pie-background animate screen-2-images',
                //     s: 0,
                //     o: 1
                //   },
                // },
                {
                  background: {
                    id: 'screen-2-Hospital-5',
                    width: 122.4,
                    height: 134.4,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 755,
                    y: 745,
                    finalX: 694,
                    finalY: 678,
                    class: 'hospital screen-2-hospital-5 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-2',
                    width: 100.8,
                    height: 81.8,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 1325,
                    y: 730,
                    finalX: 1275,
                    finalY: 690,
                    class: 'hospital screen-2-hospital-2 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-7',
                    width: 100.8,
                    height: 81.8,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 995,
                    y: 579,
                    finalX: 945,
                    finalY: 538,
                    class: 'hospital screen-2-hospital-7 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                // {
                //   background: {
                //     id: 'screen-2-Hospital-3',
                //     width: 100.8,
                //     height: 91.8,
                //     src: 'assets/images/ctemp/SVG/hospital.svg',
                //     x: 1225,
                //     y: 624,
                //     finalX: 1175,
                //     finalY: 578,
                //     class: 'hospital screen-2-hospital-3 background pie-background animate screen-2-images',
                //     s: 0,
                //     o: 1
                //   },
                // },
              ]
            };

            const svg2 = d3.selectAll('#chart-globe')
              .attr("height", "410")
              .attr("width", "848");
            const chartGroup2 = svg2.append('g')
              .attr('id', 'chart-globe-group-1')
              .attr('class', 'chart-globe-group-1')
              .style('opacity', 1)
              .attr("transform", "translate(-100,-150) scale(0.55)");
            dataSummaryData2.dataContent.forEach((content, index) => {
              const chartGrp2 = chartGroup2
                .append('g')
                .datum({
                  x: 0,
                  y: 0,
                  finalX: 0,
                  finalY: 0,
                  s: 1,
                  o: 1
                })
                .attr({
                  'class': `chart-globe-group chart-globe-group-${index}`,
                  'id': `chart-globe-group-${index}`,
                  'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
                })
                .style('opacity', (d: any) => d.o);
              createImageUsingData(chartGrp2, content.background);
              // createTextUsingData(group, content.text);
            });
            // showImage(businessProcess, 'globe-image-1', 0, 300, function () {

            // });
            showImage(businessProcess, 'continents-1', 0, 300, function () {
              // createCircle(lineGroup, 900, 490, 9);
              operateLine(lineGroup, 'list-line-0', 'draw', 0, 300, function () {
                operateLine(lineGroup, 'list-line-1', 'draw', 0, 300, function () {
                  showText(lineGroup, 'screen-globe-text-screen-2', 0, 200, function () {
                    let countries = businessProcess.selectAll('.screen-2-country-6');
                    countries
                      .transition()
                      .delay((d, i) => 100)
                      .duration(100)
                      // .ease('bounce')
                      .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                      .style('opacity', 1)
                      .call(endAll, function () {
                        let hospital = businessProcess.selectAll('.screen-2-hospital-6');
                        hospital
                          .transition()
                          .delay((d, i) => 100)
                          .duration(100)
                          .ease('bounce')
                          .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                          .style('opacity', 1)
                          .call(endAll, function () {
                          });
                      });
                  })
                })
              })
            });
            showText(businessProcess, 'screen-7-chart-text-3', 200, 300, function () { })

            let countrySelect: any = document.getElementById("chartGlobe-1-country");
            let yearSelect: any = document.getElementById("chartGlobe-1-year");
            countrySelect.addEventListener("change", function () {
              //adding loader on metrics section
              let metricsLoader = businessProcess.select('#metrics-loader');
              metricsLoader
                .classed('animated-background', true)
                .style('position', 'absolute')
                .style('top', 0)
                .style('right', 0)
                .style('bottom', 0)
                .style('left', 0);

              if (metricsLoaderTimer != null) {
                clearTimeout(metricsLoaderTimer);
              }
              metricsLoaderTimer = setTimeout(() => {
                chartData.map((obj) => {
                  if (obj.country == countrySelect.value) {
                    obj.years.map((y) => {
                      if (y.year == yearSelect.value) {
                        document.getElementById('siteFailure').innerHTML = y.siteFailure + "%";
                        document.getElementById('emptySites').innerHTML = y.emptySites + "%";
                        document.getElementById('screeningFailures').innerHTML = y.screeningFailures + "%";
                        document.getElementById('patientDropOff').innerHTML = y.patientDropOff + "%";
                      };
                    });
                  }
                });
                businessProcess.selectAll('.screen-2-hospital-6').style('opacity', 0); //USA
                businessProcess.selectAll('.screen-2-hospital-5').style('opacity', 0); //brazil
                businessProcess.selectAll('.screen-2-hospital-1').style('opacity', 0); //China
                businessProcess.selectAll('.screen-2-hospital-2').style('opacity', 0); //India
                businessProcess.selectAll('.screen-2-hospital-7').style('opacity', 0); // UK
                businessProcess.selectAll('.screen-2-country-6').style('opacity', 0);
                businessProcess.selectAll('.screen-2-country-5').style('opacity', 0);
                businessProcess.selectAll('.screen-2-country-1').style('opacity', 0);
                businessProcess.selectAll('.screen-2-country-2').style('opacity', 0);
                businessProcess.selectAll('.screen-2-country-7').style('opacity', 0);
                switch (countrySelect.value) {
                  case 'United States':
                    showCountry(businessProcess, "screen-2-hospital-6")
                    showCountry(businessProcess, "screen-2-country-6"); break;
                  // businessProcess.selectAll('.screen-2-hospital-6').style('opacity',1);
                  // businessProcess.selectAll('.screen-2-country-6').style('opacity',1);break;
                  case 'India':
                    showCountry(businessProcess, "screen-2-hospital-2")
                    showCountry(businessProcess, "screen-2-country-2"); break;
                  // businessProcess.selectAll('.screen-2-hospital-2').style('opacity',1);
                  // businessProcess.selectAll('.screen-2-country-2').style('opacity',1);break;
                  case 'UK':
                    showCountry(businessProcess, "screen-2-hospital-7")
                    showCountry(businessProcess, "screen-2-country-7"); break;
                  // businessProcess.selectAll('.screen-2-hospital-7').style('opacity',1);
                  // businessProcess.selectAll('.screen-2-country-7').style('opacity',1);break;
                  case 'Brazil':
                    showCountry(businessProcess, "screen-2-hospital-5")
                    showCountry(businessProcess, "screen-2-country-5"); break;
                  // businessProcess.selectAll('.screen-2-hospital-5').style('opacity',1);
                  // businessProcess.selectAll('.screen-2-country-5').style('opacity',1);break;
                  case 'China':
                    showCountry(businessProcess, "screen-2-hospital-1")
                    showCountry(businessProcess, "screen-2-country-1"); break;
                  // businessProcess.selectAll('.screen-2-hospital-1').style('opacity',1);
                  // businessProcess.selectAll('.screen-2-country-1').style('opacity',1);break;
                }

                metricsLoader
                  .classed('animated-background', false)
              }, 500);

            });



            yearSelect.addEventListener("change", function () {
              //adding loader on metrics section
              let metricsLoader = businessProcess.select('#metrics-loader');
              metricsLoader
                .classed('animated-background', true)
                .style('position', 'absolute')
                .style('top', 0)
                .style('right', 0)
                .style('bottom', 0)
                .style('left', 0);

              if (metricsLoaderTimer != null) {
                clearTimeout(metricsLoaderTimer);
              }

              metricsLoaderTimer = setTimeout(() => {
                chartData.map((obj) => {
                  if (obj.country == countrySelect.value) {
                    obj.years.map((y) => {
                      if (y.year == yearSelect.value) {
                        document.getElementById('siteFailure').innerHTML = y.siteFailure + "%";
                        document.getElementById('emptySites').innerHTML = y.emptySites + "%";
                        document.getElementById('screeningFailures').innerHTML = y.screeningFailures + "%";
                        document.getElementById('patientDropOff').innerHTML = y.patientDropOff + "%";
                      };
                    });
                  }
                });
                switch (countrySelect.value) {
                  case 'United States':
                  case 'India':
                  case 'UK':
                  case 'Brazil':
                  case 'China':
                }
                metricsLoader
                  .classed('animated-background', false)
              }, 500);
            });
            // showImage(businessProcess, 'chart-0', 200, 300, function () { })
            break;
          case 3: x = 387; y = 668;
            businessProcess.append('foreignObject')
              .attr('x', 956)
              .attr('y', 355)
              .attr('width', 920)
              .attr('height', 563)
              .append('xhtml:div')
              .attr({
                'class': `graph-1`
              })
              .style({
                'opacity': 1,
                'transition': (d, i) => `all 500ms ease-in-out`
              })
              .append('xhtml:div')
              .attr({
                'class': `graph-1-child`
              })
              .html(d => `<div class="graph-box">
                <div class="d-flex justify-content-between">
                    <div class="select-set ml-auto d-flex">
                    <div class="select-wrapper">
                        <select id="chartGlobe-2-country" value="United States">
                            <option value="United States">United States</option>
                            <option value="UK">UK</option>
                            <option value="China">China</option>
                            <option value="India">India</option>
                            <option value="Brazil">Brazil</option>
                        </select>
                      </div> 
                      <div class="select-wrapper">
                        <select id="chartGlobe-2-year" value="2021">
                            <option value="2021" >2021</option>
                            <option value="2020">2020</option>
                        </select>
                      </div>
                    </div>
                </div>
                <svg id="chart-globe"></svg> 
            </div>`);

            const dataSummaryData = {
              dataContent: [
                {
                  background: {
                    id: 'globe-image-1',
                    width: 1920,
                    height: 591,
                    src: 'assets/images/ctemp/SVG/globe.svg',
                    x: 0,
                    y: 969,
                    finalX: 0,
                    finalY: 380,
                    class: 'screen-2 globe-image-1 background pie-background animate screen-2-images',
                    s: 1,
                    o: 1
                  },
                },
                {
                  background: {
                    id: 'continents-1',
                    width: 1488,
                    height: 450,
                    src: 'assets/images/ctemp/SVG/continents.svg',
                    x: 353,
                    y: 969,
                    finalX: 353,
                    finalY: 505,
                    class: 'screen-2 continents-1 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0.8
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-1',
                    width: 219,
                    height: 84,
                    src: 'assets/images/ctemp/SVG/Country1.svg',
                    x: 1298,
                    y: 621,
                    finalX: 1298,
                    finalY: 621,
                    class: 'country screen-2-country-1 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-2',
                    width: 109,
                    height: 72,
                    src: 'assets/images/ctemp/SVG/Country2.svg',
                    x: 1288,
                    y: 656,
                    finalX: 1288,
                    finalY: 656,
                    class: 'country screen-2-country-2 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                // {
                //   background: {
                //     id: 'screen-2-country-3',
                //     width: 88,
                //     height: 41,
                //     src: 'assets/images/ctemp/SVG/Country3.svg',
                //     x: 1174,
                //     y: 637,
                //     finalX: 1174,
                //     finalY: 637,
                //     class: 'country screen-2-country-3 background pie-background animate screen-7-images',
                //     s: 1,
                //     o: 0
                //   },
                // },
                // {
                //   background: {
                //     id: 'screen-2-country-4',
                //     width: 81,
                //     height: 48,
                //     src: 'assets/images/ctemp/SVG/Country4.svg',
                //     x: 1048,
                //     y: 727,
                //     finalX: 1048,
                //     finalY: 727,
                //     class: 'country screen-2-country-4 background pie-background animate screen-7-images',
                //     s: 1,
                //     o: 0
                //   },
                // },
                {
                  background: {
                    id: 'screen-2-country-5',
                    width: 186,
                    height: 114,
                    src: 'assets/images/ctemp/SVG/Country5.svg',
                    x: 633,
                    y: 734,
                    finalX: 633,
                    finalY: 734,
                    class: 'country screen-2-country-5 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-6',
                    width: 240,
                    height: 67,
                    src: 'assets/images/ctemp/SVG/Country6.svg',
                    x: 468,
                    y: 622,
                    finalX: 468,
                    finalY: 622,
                    class: 'country screen-2-country-6 background pie-background animate screen-7-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-country-7',
                    width: 112,
                    height: 50,
                    src: 'assets/images/ctemp/SVG/Country7.svg',
                    x: 952,
                    y: 593,
                    finalX: 952,
                    finalY: 593,
                    class: 'country screen-2-country-7 background pie-background animate screen-2-images',
                    s: 1,
                    o: 0
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-1',
                    width: 100.8,
                    height: 91.8,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 1480,
                    y: 656,
                    finalX: 1430,
                    finalY: 610,
                    class: 'hospital screen-2-hospital-1 background pie-background animate screen-2-images',
                    s: 0,
                    o: 1
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-6',
                    width: 168,
                    height: 153,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 562,
                    y: 588,
                    finalX: 478,
                    finalY: 512,
                    class: 'hospital screen-2-hospital-6 background pie-background animate screen-2-images',
                    s: 0,
                    o: 1
                  },
                },
                // {
                //   background: {
                //     id: 'screen-2-Hospital-4',
                //     width: 100.8,
                //     height: 81.8,
                //     src: 'assets/images/ctemp/SVG/hospital.svg',
                //     x: 1118,
                //     y: 723,
                //     finalX: 1068,
                //     finalY: 682,
                //     class: 'hospital screen-2-hospital-4 background pie-background animate screen-2-images',
                //     s: 0,
                //     o: 1
                //   },
                // },
                // {
                //   background: {
                //     id: 'screen-2-Hospital-1_1',
                //     width: 84,
                //     height: 76.5,
                //     src: 'assets/images/ctemp/SVG/hospital.svg',
                //     x: 1382,
                //     y: 626,
                //     finalX: 1330,
                //     finalY: 588,
                //     class: 'hospital screen-2-hospital-1_1 background pie-background animate screen-2-images',
                //     s: 0,
                //     o: 1
                //   },
                // },
                {
                  background: {
                    id: 'screen-2-Hospital-5',
                    width: 122.4,
                    height: 134.4,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 755,
                    y: 745,
                    finalX: 694,
                    finalY: 678,
                    class: 'hospital screen-2-hospital-5 background pie-background animate screen-2-images',
                    s: 0,
                    o: 1
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-2',
                    width: 100.8,
                    height: 81.8,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 1325,
                    y: 730,
                    finalX: 1275,
                    finalY: 690,
                    class: 'hospital screen-2-hospital-2 background pie-background animate screen-2-images',
                    s: 0,
                    o: 1
                  },
                },
                {
                  background: {
                    id: 'screen-2-Hospital-7',
                    width: 100.8,
                    height: 81.8,
                    src: 'assets/images/ctemp/SVG/hospital.svg',
                    x: 995,
                    y: 579,
                    finalX: 945,
                    finalY: 538,
                    class: 'hospital screen-2-hospital-7 background pie-background animate screen-2-images',
                    s: 0,
                    o: 1
                  },
                },
                {
                  background: {
                    id: 'green-selection-box',
                    width: 495,
                    height: 195,
                    src: 'assets/images/ctemp/SVG/chartSelection.svg',
                    x: 260,
                    y: 238,
                    finalX: 260,
                    finalY: 238,
                    class: 'hospital green-selection-box animate ',
                    s: 1,
                    o: 1
                  },
                },
                // {
                //   background: {
                //     id: 'screen-2-Hospital-3',
                //     width: 100.8,
                //     height: 91.8,
                //     src: 'assets/images/ctemp/SVG/hospital.svg',
                //     x: 1225,
                //     y: 624,
                //     finalX: 1175,
                //     finalY: 578,
                //     class: 'hospital screen-2-hospital-3 background pie-background animate screen-2-images',
                //     s: 0,
                //     o: 1
                //   },
                // },
              ]
            };

            const svg = d3.selectAll('#chart-globe')
              .attr("height", "410")
              .attr("width", "848");
            const chartGroup = svg.append('g')
              .attr('id', 'chart-globe-group-1')
              .attr('class', 'chart-globe-group-1')
              .style('opacity', 1)
              .attr("transform", "translate(-100,-110) scale(0.55)");

            dataSummaryData.dataContent.forEach((content, index) => {
              const chartGrp = chartGroup
                .append('g')
                .datum({
                  x: 0,
                  y: 0,
                  finalX: 0,
                  finalY: 0,
                  s: 1,
                  o: 1
                })
                .attr({
                  'class': `chart-globe-group chart-globe-group-${index}`,
                  'id': `chart-globe-group-${index}`,
                  'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
                })
                .style('opacity', (d: any) => d.o);
              createImageUsingData(chartGrp, content.background);
              // createTextUsingData(group, content.text);
            });
            // showImage(businessProcess, 'globe-image-1', 0, 300, function () {
            const chartText = {
              textArr: [
                {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 300,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 230,
                        y: -180
                      }, {
                        text: '35%',
                        dy: '0em',
                        x: 550,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-1',
                    class: 'chart-text-group chart-perc-0',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 300,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 230,
                        y: -180
                      }, {
                        text: '95%',
                        dy: '0em',
                        x: 550,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-2',
                    class: 'chart-text-group chart-perc-1',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 1100,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 1050,
                        y: -180
                      }, {
                        text: '85%',
                        dy: '0em',
                        x: 1350,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-3',
                    class: 'chart-text-group chart-perc-2',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 1100,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 1050,
                        y: -180
                      }, {
                        text: '45%',
                        dy: '0em',
                        x: 1350,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-4',
                    class: 'chart-text-group chart-perc-3',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 1250,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 1180,
                        y: -180
                      }, {
                        text: '40%',
                        dy: '0em',
                        x: 1500,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-5',
                    class: 'chart-text-group chart-perc-4',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 1250,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 1180,
                        y: -180
                      }, {
                        text: '75%',
                        dy: '0em',
                        x: 1500,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-1',
                    class: 'chart-text-group chart-perc-5',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 520,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 445,
                        y: -180
                      }, {
                        text: '75%',
                        dy: '0em',
                        x: 770,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-2',
                    class: 'chart-text-group chart-perc-6',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 520,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 445,
                        y: -180
                      }, {
                        text: '55%',
                        dy: '0em',
                        x: 770,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-3',
                    class: 'chart-text-group chart-perc-7',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 750,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 675,
                        y: -180
                      }, {
                        text: '60%',
                        dy: '0em',
                        x: 1000,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-4',
                    class: 'chart-text-group chart-perc-8',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Enrollment Completion',
                        dy: '0em',
                        x: 750,
                        y: -220
                      }, {
                        text: 'Probability',
                        dy: '0em',
                        x: 675,
                        y: -180
                      }, {
                        text: '65%',
                        dy: '0em',
                        x: 1000,
                        y: -220
                      },
                    ],
                    id: 'screen-7-chart-text-5',
                    class: 'chart-text-group chart-perc-9',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 28,
                      'font-family': 'Roboto',
                      'fill': 'white',
                      'font-weight': 500,
                    },
                  }
                }
              ]
            }
            chartText.textArr.forEach((content, index) => {
              const group = chartGroup
                .append('g')
                .datum({
                  x: 0,
                  y: 0,
                  finalX: 0,
                  finalY: 0,
                  s: 1,
                  o: 1
                })
                .attr({
                  'class': `selection-box-text-group selection-box-text-group-${index}`,
                  'id': `chart-globe-group-${index}`,
                  'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
                })
              createTextUsingData(group, content.text);
            })
            // });
            let countrySelect2: any = document.getElementById('chartGlobe-2-country');
            let yearSelect2: any = document.getElementById('chartGlobe-2-year');
            let xVal = 245, yVal = 238;
            showText(chartGroup, 'chart-perc-0', 100, 100, function () { });
            countrySelect2.addEventListener('change', function () {
              for (let i = 0; i < 10; i++) {
                hideText(chartGroup, `chart-perc-${i}`, 100, 100, function () { })
              }
              operateLine(lineGroup, 'chart-line-0', 'hide', 300, 300, function () { })
              operateLine(lineGroup, 'chart-line-1', 'hide', 300, 300, function () { })
              operateLine(lineGroup, 'chart-line-2', 'hide', 300, 300, function () { })
              operateLine(lineGroup, 'chart-line-3', 'hide', 300, 300, function () { })
              operateLine(lineGroup, 'chart-line-4', 'hide', 300, 300, function () { })
              switch (countrySelect2.value) {
                case 'United States': xVal = 260;
                  operateLine(lineGroup, 'chart-line-0', 'draw', 300, 300, function () { })
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-0', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-1', 100, 100, function () { });
                  }
                  break;
                case 'India': xVal = 1050;
                  operateLine(lineGroup, 'chart-line-1', 'draw', 300, 300, function () { });
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-2', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-3', 100, 100, function () { });
                  }
                  break;
                case 'China': xVal = 1200;
                  operateLine(lineGroup, 'chart-line-2', 'draw', 300, 300, function () { });
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-4', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-5', 100, 100, function () { });
                  }
                  break;
                case 'Brazil': xVal = 470;
                  operateLine(lineGroup, 'chart-line-3', 'draw', 300, 300, function () { });
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-6', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-7', 100, 100, function () { });
                  }
                  break;
                case 'UK': xVal = 700;
                  operateLine(lineGroup, 'chart-line-4', 'draw', 300, 300, function () { });
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-8', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-9', 100, 100, function () { });
                  }
                  break;

              }
              d3.selectAll(".green-selection-box")
                .attr("transform", `translate(${xVal},238)`);



            })
            yearSelect2.addEventListener('change', function () {
              for (let i = 0; i < 10; i++) {
                hideText(chartGroup, `chart-perc-${i}`, 100, 100, function () { })
              }
              switch (countrySelect2.value) {
                case 'United States': xVal = 260;
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-0', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-1', 100, 100, function () { });
                  }
                  break;
                case 'India': xVal = 1050;
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-2', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-3', 100, 100, function () { });
                  }
                  break;
                case 'China': xVal = 1200;
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-4', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-5', 100, 100, function () { });
                  }
                  break;
                case 'Brazil': xVal = 470;
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-6', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-7', 100, 100, function () { });
                  }
                  break;
                case 'UK': xVal = 700;
                  if (yearSelect2.value == '2020') {
                    showText(chartGroup, 'chart-perc-8', 100, 100, function () { });
                  } else {
                    showText(chartGroup, 'chart-perc-9', 100, 100, function () { });
                  }
                  break;

              }
            });
            showImage(businessProcess, 'continents-1', 0, 300, function () {
              // createCircle(lineGroup, 900, 490, 9);
              operateLine(lineGroup, 'list-line-0', 'draw', 0, 300, function () {
                operateLine(lineGroup, 'list-line-1', 'draw', 0, 300, function () {
                  showText(lineGroup, 'screen-globe-text-screen-2', 0, 200, function () {
                    let countries = businessProcess.selectAll('.country');
                    countries
                      .transition()
                      .delay((d, i) => 100)
                      .duration(100)
                      // .ease('bounce')
                      .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                      .style('opacity', 1)
                      .call(endAll, function () {
                        let hospital = businessProcess.selectAll('.hospital');
                        hospital
                          .transition()
                          .delay((d, i) => i * 100)
                          .duration(100)
                          .ease('bounce')
                          .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
                          .style('opacity', 1)
                          .call(endAll, function () {
                          });
                      });
                  })
                })
              })
            });
            showText(businessProcess, 'screen-chart-7-text-4', 200, 300, function () { })
            const listData2 = {
              lines: [
                [[1172, 527], [1172, 582]],
                [[1604, 527], [1604, 670]],
                [[1688, 527], [1688, 627]],
                [[1288, 527], [1288, 671]],
                [[1413, 527], [1413, 590]],
              ],
            }

            listData2.lines.forEach((el, i) => {
              let index = i;
              const line = addDashedLine(lineGroup, el, `screen-7-chart chart-line-${i}`, 'black', '5,4', 3, false);
              line.attr('stroke-dashoffset', d => d.pathLength);
              // addLine(chartGroup, el, 'list-line list-line-' + index, index,"black",1,false);
            });
            operateLine(lineGroup, 'chart-line-0', 'draw', 300, 300, function () { })
            // showImage(businessProcess, 'chart-0', 200, 300, function () { })
            break;
          case 4: x = 387; y = 806;
            showText(businessProcess, 'screen-chart-7-text-5', 200, 300, function () { })
            businessProcess.append('foreignObject')
              .attr('x', 956)
              .attr('y', 355)
              .attr('width', 920)
              .attr('height', 563)
              .append('xhtml:div')
              .attr({
                'class': `graph-1`
              })
              .style({
                'opacity': 1,
                'transition': (d, i) => `all 500ms ease-in-out`
              })
              .append('xhtml:div')
              .attr({
                'class': `graph-1-child`
              })
              .html(d => `<div class="graph-box">
                  <div class="d-flex align-items-center justify-content-between">
                  <div>
                      <h5>Validations Curves</h5>
                      <span>   </span>
                  </div>
                  </div>
                  <div class="row"> 
                  <svg id="area2" class="chart-2" ></svg>
                  </div>
              </div>`);
            let chart = d3.selectAll('#chart-legend-0');
            hideText(businessProcess, 'screen-chart-7-text-6', 0, 100, function () { })
            hideText(businessProcess, 'screen-chart-7-text-7', 0, 100, function () { })
            hideText(businessProcess, 'screen-chart-7-text-8', 0, 100, function () { })
            hideText(businessProcess, 'screen-chart-7-text-9', 0, 100, function () { })
            if (!chart[0].length) {
              // showImage(businessProcess,'chart-legend-0',0,100,function(){});
              let chartLegend = {
                background: {
                  id: 'chart-legend-0',
                  width: 236,
                  height: 124,
                  src: 'assets/images/ctemp/SVG/chartLegend.svg',
                  x: 1620,
                  y: 380,
                  finalX: 1620,
                  finalY: 380,
                  class: 'screen-7 chart-legend chart-legend-0 animate screen-7-images',
                  s: 1,
                  o: 1
                }
              };
              let chartText = [
                {
                  text: {
                    wholeText: [
                      {
                        text: 'Mean Forecast',
                        dy: '0em',
                        x: 1660,
                        y: -118
                      },
                    ],
                    id: 'screen-7-chart-text-6',
                    class: 'chart-text-group screen-chart-7-text-6',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 14,
                      'font-family': 'Roboto',
                      'fill': 'black',
                      'font-weight': 600,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Confidence Boost',
                        dy: '0em',
                        x: 1660,
                        y: -92
                      },
                    ],
                    id: 'screen-7-chart-text-7',
                    class: 'chart-text-group screen-chart-7-text-7',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 14,
                      'font-family': 'Roboto',
                      'fill': 'black',
                      'font-weight': 600,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Actual Enrollment',
                        dy: '0em',
                        x: 1660,
                        y: -67
                      },
                    ],
                    id: 'screen-7-chart-text-8',
                    class: 'chart-text-group screen-chart-7-text-8',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 14,
                      'font-family': 'Roboto',
                      'fill': 'black',
                      'font-weight': 600,
                    },
                  }
                }, {
                  text: {
                    wholeText: [
                      {
                        text: 'Reforecast Mean',
                        dy: '0em',
                        x: 1660,
                        y: -40
                      },
                    ],
                    id: 'screen-7-chart-text-9',
                    class: 'chart-text-group screen-chart-7-text-9',
                    x: 126,
                    y: 526,
                    dy: '0em',
                    finalX: 126,
                    finalY: 526,
                    o: 0,
                    textStyle: {
                      'text-anchor': 'middle',
                      'font-size': 14,
                      'font-family': 'Roboto',
                      'fill': 'black',
                      'font-weight': 600,
                    },
                  }
                }
              ]

              const group = businessProcess
                .append('g')
                .datum({
                  x: 0,
                  y: 0,
                  finalX: 0,
                  finalY: 0,
                  s: 1,
                  o: 1
                })
                .attr({
                  'class': `screen7-group chart-legend-group-0`,
                  'id': `chart-legend-group-0`,
                  'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
                })
                .style('opacity', (d: any) => d.o);
              createImageUsingData(group, chartLegend.background);
              const groupText = businessProcess
                .append('g')
                .datum({
                  x: 0,
                  y: 0,
                  finalX: 0,
                  finalY: 0,
                  s: 1,
                  o: 1
                })
                .attr({
                  'class': `screen7-group chart-legend-text-group-0`,
                  'id': `chart-legend-text-group-0`,
                  'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
                })
                .style('opacity', (d: any) => d.o);
              chartText.forEach(
                (x) => {
                  createTextUsingData(groupText, x.text);
                }
              )
            }

            d3.selectAll('#chart-legend-0').style('opacity', 1);
            showText(businessProcess, 'screen-chart-7-text-6', 0, 100, function () { })
            showText(businessProcess, 'screen-chart-7-text-7', 0, 100, function () { })
            showText(businessProcess, 'screen-chart-7-text-8', 0, 100, function () { })
            showText(businessProcess, 'screen-chart-7-text-9', 0, 100, function () { })
            createMultiLineChart();
            break;
          default:
          // hideImage(businessProcess,'chart-legend-0',0,100,function(){});
        }
        businessProcess.selectAll('.selected-circle-0')
          .attr('transform', `translate(${x}, ${y}) scale(1)`)
          .style('opacity', 1);
        let text = businessProcess.selectAll(`.${that.selectedProcess.text.id}`);
        text
          .style('fill', 'white');
        businessProcess.selectAll(`.unselected-circle-${index}`)
          .style('opacity', 0);
          setTimeout(() => {
            lockFun = false;
          }, 500);
      }

      function showCountry(group, className) {
        let obj = group.selectAll(`.${className}`);
        obj
          .transition()
          .delay((d, i) => 100)
          .duration(100)
          // .ease('bounce')
          .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
          .style('opacity', 1)
          .call(endAll, function () { });
      }

      function clearChartData() {
        let lineChart = d3.selectAll('#area1');
        lineChart.selectAll('.x-axis').remove();
        lineChart.selectAll('.y-axis').remove();
        lineChart.selectAll('.focus').remove();
        lineChart.selectAll('.line').remove();
        let selection = d3.selectAll('#area2');
        selection.selectAll('.x-axis').remove();
        selection.selectAll('.y-axis').remove();
        selection.selectAll('path').remove();
        let areaChart = d3.selectAll('#area3');
        areaChart.selectAll('.x-axis').remove();
        areaChart.selectAll('.y-axis').remove();
        areaChart.selectAll('.area').remove();
        areaChart.selectAll('path').remove();

        businessProcess.selectAll(".chart-text-group").style('opacity', 0);
        // hideText(businessProcess, 'screen-chart-7-text-group-0', 0, 0, function () { })
        // hideText(businessProcess, 'screen-chart-7-text-group-1', 0, 0, function () { })
        // hideText(businessProcess, 'screen-chart-7-text-group-2', 0, 0, function () { })
        // hideText(businessProcess, 'screen-chart-7-text-group-3', 0, 0, function () { })
        // hideText(businessProcess, 'screen-chart-7-text-group-4', 0, 0, function () { })

      }

      function deSelectCurrentProcess() {
        if (that.prevProcess == null) {
          that.prevProcess = that.selectedProcess;
          return;
        }
        businessProcess.selectAll(`.unselected`)
          .style('opacity', 1);
        businessProcess.selectAll('.selected-0')
          .style('opacity', 0);
        businessProcess.selectAll('.selected-circle-0')
          .style('opacity', 0);
        businessProcess.selectAll('.labels')
          .style('fill', '#25A1B2');
        businessProcess.selectAll(`.${that.prevProcess.background.id}`)
          .style('opacity', 1);


      }

      function createAreaChart() {
        var graphData = {
          initData: {
            areaData: [
              [{
                x: 0.125,
                y: 0
              }, {
                x: 0.125,
                y: 0,
              }, {
                x: 0.15,
                y: 0,
              }, {
                x: 0.15,
                y: 0,
              }, {
                x: 0.32,
                y: 0,
              }, {
                x: 0.32,
                y: 0,
              }, {
                x: 0.4,
                y: 0,
              }, {
                x: 0.4,
                y: 0,
              }, {
                x: 0.55,
                y: 0,
              }, {
                x: 0.55,
                y: 0,
              }, {
                x: 0.65,
                y: 0,
              }, {
                x: 0.65,
                y: 0,
              }, {
                x: 0.78,
                y: 0,
              }, {
                x: 0.78,
                y: 0,
              }
                ,],
              [{
                x: 0.1,
                y: 0
              }, {
                x: 0.1,
                y: 0,
              }, {
                x: 0.2,
                y: 0,
              }, {
                x: 0.2,
                y: 0,
              }, {
                x: 0.3,
                y: 0,
              }, {
                x: 0.3,
                y: 0,
              }, {
                x: 0.35,
                y: 0,
              }, {
                x: 0.35,
                y: 0,
              }, {
                x: 0.4,
                y: 0,
              }, {
                x: 0.4,
                y: 0,
              }, {
                x: 0.5,
                y: 0,
              }, {
                x: 0.5,
                y: 0,
              }, {
                x: 0.6,
                y: 0,
              }, {
                x: 0.6,
                y: 0,
              }, {
                x: 0.7,
                y: 0,
              }, {
                x: 0.7,
                y: 0,
              }, {
                x: 0.8,
                y: 0,
              }, {
                x: 0.8,
                y: 0,
              }



              ],
              [{
                x: 0.1,
                y: 0
              }, {
                x: 0.1,
                y: 0,
              }, {
                x: 0.175,
                y: 0,
              }, {
                x: 0.175,
                y: 0,
              }, {
                x: 0.25,
                y: 0,
              }, {
                x: 0.25,
                y: 0,
              }, {
                x: 0.35,
                y: 0,
              }, {
                x: 0.35,
                y: 0,
              }, {
                x: 0.4,
                y: 0,
              }, {
                x: 0.4,
                y: 0,
              }, {
                x: 0.7,
                y: 0,
              }, {
                x: 0.7,
                y: 0,
              }, {
                x: 0.75,
                y: 0,
              }, {
                x: 0.75,
                y: 0,
              }, {
                x: 0.85,
                y: 0,
              }, {
                x: 0.85,
                y: 0,
              },
              ],
            ],
            lineData: [
              [{
                x: -0.5,
                y: 0,
              }, {
                x: 0,
                y: 0,
              }, {
                x: 0.25,
                y: 0,
              }, {
                x: 0.50,
                y: 0,
              }, {
                x: 0.75,
                y: 0,
              }, {
                x: 1,
                y: 0,
              }, {
                x: 1.25,
                y: 0,
              }, {
                x: 1.50,
                y: 0,
              }]
            ]
          },
          gamma: {
            areaData: [
              [{
                x: 0.125,
                y: 0
              }, {
                x: 0.125,
                y: 0.175,
              }, {
                x: 0.15,
                y: 0.175,
              }, {
                x: 0.15,
                y: 0.85,
              }, {
                x: 0.32,
                y: 0.85,
              }, {
                x: 0.32,
                y: 0.37,
              }, {
                x: 0.4,
                y: 0.37,
              }, {
                x: 0.4,
                y: 1.65,
              }, {
                x: 0.55,
                y: 1.65,
              }, {
                x: 0.55,
                y: 1.05,
              }, {
                x: 0.65,
                y: 1.05,
              }, {
                x: 0.65,
                y: 0.85,
              }, {
                x: 0.78,
                y: 0.85,
              }, {
                x: 0.78,
                y: 0,
              }
                ,],
              [{
                x: 0.1,
                y: 0
              }, {
                x: 0.1,
                y: 0.2,
              }, {
                x: 0.2,
                y: 0.2,
              }, {
                x: 0.2,
                y: 0.75,
              }, {
                x: 0.3,
                y: 0.75,
              }, {
                x: 0.3,
                y: 1.25,
              }, {
                x: 0.35,
                y: 1.25,
              }, {
                x: 0.35,
                y: 1.5,
              }, {
                x: 0.4,
                y: 1.5,
              }, {
                x: 0.4,
                y: 1.15,
              }, {
                x: 0.5,
                y: 1.15,
              }, {
                x: 0.5,
                y: 0.85,
              }, {
                x: 0.6,
                y: 0.85,
              }, {
                x: 0.6,
                y: 0.95,
              }, {
                x: 0.7,
                y: 0.95,
              }, {
                x: 0.7,
                y: 0.2,
              }, {
                x: 0.8,
                y: 0.2,
              }, {
                x: 0.8,
                y: 0,
              }



              ],
              [{
                x: 0.1,
                y: 0
              }, {
                x: 0.1,
                y: 0.25,
              }, {
                x: 0.175,
                y: 0.25,
              }, {
                x: 0.175,
                y: 1.35,
              }, {
                x: 0.25,
                y: 1.35,
              }, {
                x: 0.25,
                y: 1.75,
              }, {
                x: 0.35,
                y: 1.75,
              }, {
                x: 0.35,
                y: 1.5,
              }, {
                x: 0.4,
                y: 1.5,
              }, {
                x: 0.4,
                y: 1,
              }, {
                x: 0.7,
                y: 1,
              }, {
                x: 0.7,
                y: 1.25,
              }, {
                x: 0.75,
                y: 1.25,
              }, {
                x: 0.75,
                y: 0.25,
              }, {
                x: 0.85,
                y: 0.25,
              }, {
                x: 0.85,
                y: 0,
              },
              ],

            ],
            lineData: [
              [{
                x: -0.25,
                y: 0,
              }, {
                x: 0.1,
                y: 0.2,
              }, {
                x: 0.2,
                y: 0.75,
              }, {
                x: 0.3,
                y: 1.25,
              }, {
                x: 0.38,
                y: 1.5,
              },
              {
                x: 0.7,
                y: 0.95,
              }, {
                x: 0.8,
                y: 0.2,
              }, {
                x: 1.2,
                y: 0
              }
              ], [
                {
                  x: -0.25,
                  y: 0,
                }, {
                  x: 0.1,
                  y: 0.2,
                }, {
                  x: 0.2,
                  y: 1.75,
                }, {
                  x: 0.3,
                  y: 2.25,
                }, {
                  x: 0.35,
                  y: 2.5,
                }, {
                  x: 0.5,
                  y: 2.6,
                },
                {
                  x: 0.7,
                  y: 1.95,
                }, {
                  x: 0.8,
                  y: 0.2,
                }, {
                  x: 1.2,
                  y: 0
                }
              ]
            ]
          },
          trangular: {
            areaData: [
              [{
                x: 0.15,
                y: 0
              }, {
                x: 0.15,
                y: 0.25,
              }, {
                x: 0.25,
                y: 0.25,
              }, {
                x: 0.25,
                y: 0.52,
              }, {
                x: 0.37,
                y: 0.52,
              }, {
                x: 0.37,
                y: 0.73,
              }, {
                x: 0.48,
                y: 0.73,
              }, {
                x: 0.48,
                y: 0.8,
              }, {
                x: 0.5,
                y: 0.8,
              }, {
                x: 0.5,
                y: 1.15,
              }, {
                x: 0.6,
                y: 1.15,
              }, {
                x: 0.6,
                y: 1.25,
              }, {
                x: 0.7,
                y: 1.25,
              }, {
                x: 0.7,
                y: 1.15,
              }, {
                x: 0.8,
                y: 1.15,
              }, {
                x: 0.8,
                y: 0,
              }
              ],
              [{
                x: 0.25,
                y: 0
              }, {
                x: 0.25,
                y: 0.23,
              }, {
                x: 0.35,
                y: 0.23,
              }, {
                x: 0.35,
                y: 0.48,
              }, {
                x: 0.45,
                y: 0.48,
              }, {
                x: 0.45,
                y: 0.8,
              }, {
                x: 0.6,
                y: 0.8,
              }, {
                x: 0.6,
                y: 1.3,
              }, {
                x: 0.7,
                y: 1.3,
              }, {
                x: 0.7,
                y: 1.45,
              }, {
                x: 0.8,
                y: 1.45,
              }, {
                x: 0.8,
                y: 1,
              }, {
                x: 0.9,
                y: 1,
              }, {
                x: 0.9,
                y: 0,
              },
              ],
              [{
                x: 0.23,
                y: 0
              }, {
                x: 0.23,
                y: 0.15,
              }, {
                x: 0.3,
                y: 0.15,
              }, {
                x: 0.3,
                y: 0.28,
              }, {
                x: 0.4,
                y: 0.28,
              }, {
                x: 0.4,
                y: 0.45,
              }, {
                x: 0.53,
                y: 0.45,
              }, {
                x: 0.53,
                y: 0.52,
              }, {
                x: 0.58,
                y: 0.52,
              }, {
                x: 0.58,
                y: 1,
              }, {
                x: 0.65,
                y: 1,
              }, {
                x: 0.65,
                y: 1.05,
              }, {
                x: 0.77,
                y: 1.05,
              }, {
                x: 0.77,
                y: 1,
              }, {
                x: 0.85,
                y: 1,
              }, {
                x: 0.85,
                y: 0,
              }]
            ],
            lineData: [
              [{
                x: -0.25,
                y: 0,
              }, {
                x: 0.1,
                y: 0.2,
              }, {
                x: 0.2,
                y: 0.75,
              }, {
                x: 0.3,
                y: 1.25,
              }, {
                x: 0.38,
                y: 1.5,
              },
              {
                x: 0.7,
                y: 0.95,
              }, {
                x: 0.8,
                y: 0.2,
              }, {
                x: 1.2,
                y: 0
              }
              ], [
                {
                  x: -0.25,
                  y: 0,
                }, {
                  x: 0.1,
                  y: 0.2,
                }, {
                  x: 0.2,
                  y: 1.75,
                }, {
                  x: 0.3,
                  y: 2.25,
                }, {
                  x: 0.35,
                  y: 2.5,
                }, {
                  x: 0.5,
                  y: 2.6,
                },
                {
                  x: 0.7,
                  y: 1.95,
                }, {
                  x: 0.8,
                  y: 0.2,
                }, {
                  x: 1.2,
                  y: 0
                }
              ]
            ]
          },
          halfnorm: {
            areaData: [
              [{
                x: 0.25,
                y: 0
              }, {
                x: 0.25,
                y: 1.05,
              }, {
                x: 0.3,
                y: 1.05,
              }, {
                x: 0.3,
                y: 1.23,
              }, {
                x: 0.4,
                y: 1.23,
              }, {
                x: 0.4,
                y: 1.05,
              }, {
                x: 0.55,
                y: 1.05,
              }, {
                x: 0.55,
                y: 0.9,
              }, {
                x: 0.58,
                y: 0.9,
              }, {
                x: 0.58,
                y: 0.7,
              }, {
                x: 0.7,
                y: 0.7,
              }, {
                x: 0.7,
                y: 0.52,
              }, {
                x: 0.77,
                y: 0.52,
              }, {
                x: 0.77,
                y: 0.23,
              }, {
                x: 0.88,
                y: 0.23,
              }, {
                x: 0.88,
                y: 0,
              }
              ],
              [{
                x: 0.25,
                y: 0
              }, {
                x: 0.25,
                y: 0.23,
              }, {
                x: 0.35,
                y: 0.23,
              }, {
                x: 0.35,
                y: 0.48,
              }, {
                x: 0.45,
                y: 0.48,
              }, {
                x: 0.45,
                y: 0.8,
              }, {
                x: 0.6,
                y: 0.8,
              }, {
                x: 0.6,
                y: 1.3,
              }, {
                x: 0.7,
                y: 1.3,
              }, {
                x: 0.7,
                y: 1.45,
              }, {
                x: 0.8,
                y: 1.45,
              }, {
                x: 0.8,
                y: 1,
              }, {
                x: 0.9,
                y: 1,
              }, {
                x: 0.9,
                y: 0,
              },
              ],
              [{
                x: 0.23,
                y: 0
              }, {
                x: 0.23,
                y: 0.15,
              }, {
                x: 0.3,
                y: 0.15,
              }, {
                x: 0.3,
                y: 0.28,
              }, {
                x: 0.4,
                y: 0.28,
              }, {
                x: 0.4,
                y: 0.45,
              }, {
                x: 0.53,
                y: 0.45,
              }, {
                x: 0.53,
                y: 0.52,
              }, {
                x: 0.58,
                y: 0.52,
              }, {
                x: 0.58,
                y: 1,
              }, {
                x: 0.65,
                y: 1,
              }, {
                x: 0.65,
                y: 1.05,
              }, {
                x: 0.77,
                y: 1.05,
              }, {
                x: 0.77,
                y: 1,
              }, {
                x: 0.85,
                y: 1,
              }, {
                x: 0.85,
                y: 0,
              }]
            ],
            lineData: [
              [{
                x: -0.25,
                y: 0,
              }, {
                x: 0.1,
                y: 0.2,
              }, {
                x: 0.2,
                y: 0.75,
              }, {
                x: 0.3,
                y: 1.25,
              }, {
                x: 0.38,
                y: 1.5,
              },
              {
                x: 0.7,
                y: 0.95,
              }, {
                x: 0.8,
                y: 0.2,
              }, {
                x: 1.2,
                y: 0
              }
              ], [
                {
                  x: -0.25,
                  y: 0,
                }, {
                  x: 0.1,
                  y: 0.2,
                }, {
                  x: 0.2,
                  y: 1.75,
                }, {
                  x: 0.3,
                  y: 2.25,
                }, {
                  x: 0.35,
                  y: 2.5,
                }, {
                  x: 0.5,
                  y: 2.6,
                },
                {
                  x: 0.7,
                  y: 1.95,
                }, {
                  x: 0.8,
                  y: 0.2,
                }, {
                  x: 1.2,
                  y: 0
                }
              ]
            ]
          },

        }


        var margin = {
          top: 20,
          right: 20,
          bottom: 40,
          left: 50
        },
          width = 848 - margin.left - margin.right,
          height = 410 - margin.top - margin.bottom;


        var svg = d3.select("#area3")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var x = d3.scale.linear()
          .range([0, width]);

        var xAxis = d3.svg.axis()
          .scale(x)
          .orient("bottom");

        svg.append("g")
          .attr("class", "x axis x-axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis);


        var y = d3.scale.linear()
          .range([height, 0]);

        var yAxis = d3.svg.axis()
          .scale(y)
          .orient("left");

        svg.append("g")
          .attr("class", "y axis y-axis")

        function update(pervData, currentData) {


          x.domain([
            d3.min([
              d3.min(currentData.lineData, function (d) {
                return d3.min(d, function (d1) {
                  return d1.x;
                })
              })
              ,
              d3.min(currentData.areaData, function (d) {
                return d3.min(d, function (d1) {
                  return d1.x;
                })
              })]
            ),
            d3.max(
              [d3.max(currentData.lineData, function (d) {
                return d3.max(d, function (d1) {
                  return d1.x;
                })
              })
                ,
              d3.max(currentData.areaData, function (d) {
                return d3.max(d, function (d1) {
                  return d1.x;
                })
              })]
            )])

          svg.selectAll(".x-axis")
            .transition()
            .duration(1500)
            .call(xAxis);

          y.domain([
            d3.min(
              [d3.min(currentData.lineData, function (d) {
                return d3.min(d, function (d1) {
                  return d1.y;
                })
              })
                ,
              d3.min(currentData.areaData, function (d) {
                return d3.min(d, function (d1) {
                  return d1.y;
                })
              })]
            ),
            d3.max(
              [d3.max(currentData.lineData, function (d) {
                return d3.max(d, function (d1) {
                  return d1.y;
                })
              })
                ,
              d3.max(currentData.areaData, function (d) {
                return d3.max(d, function (d1) {
                  return d1.y;
                })
              })]
            )
          ])


          svg.selectAll(".y-axis")
            .transition()
            .duration(1500)
            .call(yAxis);


          var line = d3.svg.line()
            .x(function (d, i) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })

          var lineGenerator = d3.svg.line()
            .x(function (d, i) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })
            .interpolate("cardinal");


          var area = d3.svg.area()
            .x(function (d) {
              return x(d.x);
            })
            .y0(height)
            .y1(function (d) {
              return y(d.y);
            });

          for (let index = 0; index < currentData.areaData.length; index++) {
            var areaLine = svg.selectAll('.area')
              .data(
                currentData.areaData
              );

            areaLine.enter()
              .append("path")
              .datum(currentData.areaData[index])
              .attr("class", "area")
              .style("fill", (d, i) => ["#895AF8", "#F5D487", "#D6AD9B"][i])
              .style("opacity", (d, i) => i == 0 ? 1 : i == 1 ? 0.6 : 0.3)
              .attr("d", area)
              .transition()
              .duration(1500)
              .attrTween('d', (d, i) => {
                var interpolator = d3.interpolateArray(pervData.areaData[i], currentData.areaData[i]);
                return function (t) {
                  return area(interpolator(t));

                }
              });



            areaLine.enter()
              .append("path")
              .datum(currentData.areaData[index])
              .attr("class", "area-stroke-line")
              .style("fill", "none")
              .attr("stroke", "#000")
              .attr("d", line)
              .transition()
              .duration(1500)
              .attrTween('d', (d, i) => {
                var interpolator = d3.interpolateArray(pervData.areaData[i], currentData.areaData[i]);
                return function (t) {
                  return line(interpolator(t));

                }
              });
          }

          for (let index = 0; index < currentData.lineData.length; index++) {
            var lineData = svg.selectAll('.line')
              .data(
                currentData.lineData
              );

            lineData.enter()
              .append("path")
              .datum(currentData.lineData[index])
              .attr("class", "line")
              .style("fill", "none")
              .attr("stroke", "red")
              .attr("d", lineGenerator)
              .transition()
              .duration(1500)
              .attrTween('d', () => {
                var interpolator = d3.interpolateArray(pervData.lineData[index], currentData.lineData[index]);
                return function (t) {
                  return lineGenerator(interpolator(t));

                }
              })
              ;
          }


        }

        var pervData = graphData.initData;
        var currentData = graphData.gamma;

        update(pervData, currentData);
        let gamma = document.getElementById('gamma');
        gamma.addEventListener('click', function () {
          gamma.className = 'active';
          triangular.className = "";
          halfnorm.className = "";
          let areaChart = d3.selectAll('#area3');
          // areaChart.selectAll('.x-axis').remove();
          // areaChart.selectAll('.y-axis').remove();
          // areaChart.selectAll('.area').remove();
          areaChart.selectAll('path').remove();
          let dummyArr = [], linedummyArr = []
          graphData.gamma.areaData.map((arr) => {
            let resArr = arr.map((arr2) => {
              return { x: arr2.x, y: 0 }
            });
            dummyArr.push(resArr);
          })
          graphData.gamma.lineData.map((arr) => {
            let resArr = arr.map((arr2) => {
              return { x: arr2.x, y: 0 }
            });
            linedummyArr.push(resArr);
          })
          pervData.areaData = dummyArr;
          pervData.lineData = linedummyArr;
          update(pervData, graphData.gamma);
        });
        let triangular = document.getElementById('triangular');
        triangular.addEventListener('click', function () {
          triangular.className = 'active';
          gamma.className = "";
          halfnorm.className = "";
          let areaChart = d3.selectAll('#area3');
          // areaChart.selectAll('.x-axis').remove();
          // areaChart.selectAll('.y-axis').remove();
          // areaChart.selectAll('.area').remove();
          areaChart.selectAll('path').remove();
          let dummyArr = [], linedummyArr = []
          graphData.trangular.areaData.map((arr) => {
            let resArr = arr.map((arr2) => {
              return { x: arr2.x, y: 0 }
            });
            dummyArr.push(resArr);
          })
          graphData.trangular.lineData.map((arr) => {
            let resArr = arr.map((arr2) => {
              return { x: arr2.x, y: 0 }
            });
            linedummyArr.push(resArr);
          })
          pervData.areaData = dummyArr;
          pervData.lineData = linedummyArr;
          update(pervData, graphData.trangular);
        });
        let halfnorm = document.getElementById('halfnorm');
        halfnorm.addEventListener('click', function () {
          halfnorm.className = 'active';
          triangular.className = "";
          gamma.className = "";
          let areaChart = d3.selectAll('#area3');
          // areaChart.selectAll('.x-axis').remove();
          // areaChart.selectAll('.y-axis').remove();
          // areaChart.selectAll('.area').remove();
          areaChart.selectAll('path').remove();
          let dummyArr = [], linedummyArr = []
          graphData.halfnorm.areaData.map((arr) => {
            let resArr = arr.map((arr2) => {
              return { x: arr2.x, y: 0 }
            });
            dummyArr.push(resArr);
          })
          graphData.halfnorm.lineData.map((arr) => {
            let resArr = arr.map((arr2) => {
              return { x: arr2.x, y: 0 }
            });
            linedummyArr.push(resArr);
          })
          pervData.areaData = dummyArr;
          pervData.lineData = linedummyArr;
          update(pervData, graphData.halfnorm);
        });

      }

      function createMultiLineChart() {
        let graphData = [
          {
            "key": '1',
            "actualEnrollmentValue": 19,
            "reforecartMeanValue": 20,
            "confidenceBoundValue": 30,
            "meanForecartValue": 10,
            "anyValue": 10
          },
          {
            "key": '2',
            "actualEnrollmentValue": 49,
            "reforecartMeanValue": 49,
            "confidenceBoundValue": 150,
            "meanForecartValue": 90,
            "anyValue": 25
          },
          {
            "key": '3',
            "actualEnrollmentValue": 95,
            "reforecartMeanValue": 95,
            "confidenceBoundValue": 250,
            "meanForecartValue": 175,
            "anyValue": 78
          },
          {
            "key": '4',
            "actualEnrollmentValue": 156,
            "reforecartMeanValue": 156,
            "confidenceBoundValue": 350,
            "meanForecartValue": 215,
            "anyValue": 120
          },
          {
            "key": '5',
            "actualEnrollmentValue": 250,
            "reforecartMeanValue": 250,
            "confidenceBoundValue": 450,
            "meanForecartValue": 267,
            "anyValue": 170
          },
          {
            "key": '6',
            "actualEnrollmentValue": 302,
            "reforecartMeanValue": 310,
            "confidenceBoundValue": 570,
            "meanForecartValue": 340,
            "anyValue": 280
          }
        ]

        var margin = {
          top: 20,
          right: 20,
          bottom: 40,
          left: 50
        },
          width = 848 - margin.left - margin.right,
          height = 410 - margin.top - margin.bottom;


        var svg = d3.select(".chart-2")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var x = d3.scale.linear()
          .domain([1, 10])
          .range([0, width]);

        var xAxis = d3.svg.axis()
          .scale(x)
          .orient("bottom")
          .tickValues([]);

        svg.append("g")
          .attr("class", "x axis x-axis")
          .attr("transform", "translate(0," + height + ")")
          .call(xAxis);

        var y = d3.scale.linear()
          .range([height, 0]);

        var yAxis = d3.svg.axis()
          .scale(y)
          .ticks(7)
          .orient("left");

        svg.append("g")
          .attr("class", "y axis y-axis")

        var data = graphData;

        svg.selectAll(".x-axis")
          .transition()
          .duration(1500)
          .call(xAxis);

        // x.domain([1, d3.max(data, function(d){ return d.key; })]);
        y.domain([0,
          d3.max([
            d3.max(data, function (d) {
              return d.actualEnrollmentValue
            })
            ,
            d3.max(data, function (d) {
              return d.reforecartMeanValue
            }),
            d3.max(data, function (d) {
              return d.confidenceBoundValue
            }),
            d3.max(data, function (d) {
              return d.meanForecartValue
            })
          ]),
        ])

        svg.selectAll(".y-axis")
          .transition()
          .duration(1500)
          .call(yAxis);

        var line = d3.svg.line()

        svg.append("path")
          .datum(data)
          .attr("class", "mean-line")
          .style("fill", "none")
          .attr("stroke", "#000")
          // .style("stroke-width", 1)    // set the stroke width
          .attr("d", d3.svg.line()
            .x(function (d) { return x(d.key) })
            .y(function (d) { return y(d.meanForecartValue) })
          )
          .transition()
          .duration(1500)

        svg.append("path")
          .datum(data)
          .attr("class", "confidence-line ")
          .style("stroke-dasharray", "3,3")
          .style("fill", "none")
          .attr("stroke", "#A0522D")
          .style("stroke-width", 1.5)    // set the stroke width
          .attr("d", d3.svg.line()
            .x(function (d) { return x(d.key) })
            .y(function (d) { return y(d.confidenceBoundValue) })
          )
          .transition()
          .duration(1500)

        svg.append("path")
          .datum(data)
          .attr("class", "actual-line")
          .style("fill", "none")
          .attr("stroke", "red")
          .style("stroke-width", 1.5)    // set the stroke width
          .attr("d", d3.svg.line()
            .x(function (d) { return x(d.key) })
            .y(function (d) { return y(d.actualEnrollmentValue) })
          )
          .transition()
          .duration(1500)

        svg.append("path")
          .datum(data)
          .attr("class", "reforcast-line")
          .style("fill", "none")
          .attr("stroke", "blue")
          .style("stroke-width", 1.5)    // set the stroke width
          .attr("d", d3.svg.line()
            .x(function (d) { return x(d.key) })
            .y(function (d) { return y(d.reforecartMeanValue) })
          )
          .transition()
          .duration(1500)

        svg.append("path")
          .datum(data)
          .attr("class", "new-line ")
          .style("stroke-dasharray", "3,3")
          .style("fill", "none")
          .attr("stroke", "#A0522D")
          .style("stroke-width", 1.5)    // set the stroke width
          .attr("d", d3.svg.line()
            .x(function (d) { return x(d.key) })
            .y(function (d) { return y(d.anyValue) })
          )
          .transition()
          .duration(1500);
      }
      function createLineChart() {
        var graphData = {
          "2021": [
            {
              "date": "1/1/2021",
              "likes": 5
            },
            {
              "date": "2/2/2021",
              "likes": 10
            },
            {
              "date": "3/3/2021",
              "likes": 15
            },
            {
              "date": "4/9/2021",
              "likes": 19
            },
            {
              "date": "5/4/2021",
              "likes": 31
            },
            {
              "date": "6/2/2021",
              "likes": 29
            },
            {
              "date": "7/10/2021",
              "likes": 40
            },
            {
              "date": "8/18/2021",
              "likes": 39
            },
            {
              "date": "9/29/2021",
              "likes": 55
            },
            {
              "date": "10/30/2021",
              "likes": 65
            },
            {
              "date": "11/21/2021",
              "likes": 78
            },
            {
              "date": "12/12/2021",
              "likes": 100
            }
          ],
          "2020": [
            {
              "date": "1/1/2020",
              "likes": 7
            },
            {
              "date": "2/2/2020",
              "likes": 12
            },
            {
              "date": "3/3/2020",
              "likes": 19
            },
            {
              "date": "4/9/2020",
              "likes": 25
            },
            {
              "date": "5/4/2020",
              "likes": 31
            },
            {
              "date": "6/2/2020",
              "likes": 28
            },
            {
              "date": "7/10/2020",
              "likes": 40
            },
            {
              "date": "8/18/2020",
              "likes": 30
            },
            {
              "date": "9/29/2020",
              "likes": 55
            },
            {
              "date": "10/30/2020",
              "likes": 65
            },
            {
              "date": "11/21/2020",
              "likes": 78
            },
            {
              "date": "12/12/2020",
              "likes": 80
            }
          ],
          "2019": [
            {
              "date": "1/1/2019",
              "likes": 10
            },
            {
              "date": "2/2/2019",
              "likes": 12
            },
            {
              "date": "3/3/2019",
              "likes": 19
            },
            {
              "date": "4/9/2019",
              "likes": 25
            },
            {
              "date": "5/4/2019",
              "likes": 35
            },
            {
              "date": "6/2/2019",
              "likes": 28
            },
            {
              "date": "7/10/2019",
              "likes": 40
            },
            {
              "date": "8/18/2019",
              "likes": 30
            },
            {
              "date": "9/29/2019",
              "likes": 55
            },
            {
              "date": "10/30/2019",
              "likes": 65
            }
          ]
        }

        var margin = { top: 30, right: 132, bottom: 30, left: 50 },
          width = 848 - margin.left - margin.right,
          height = 410 - margin.top - margin.bottom;

        var svg = d3.select(".chart-1")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var parseDate = d3.time.format("%m/%e/%Y").parse,
          bisectDate = d3.bisector(function (d) { return d.date; }).left,
          formatValue = d3.format(","),
          dateFormatter = d3.time.format("%b %Y");

        var x = d3.time.scale()
          .range([0, width]);

        var y = d3.scale.linear()
          .range([height, 0]);

        var xAxis = d3.svg.axis()
          .scale(x)
          .orient("bottom")
          .tickPadding(5)
          .tickFormat(dateFormatter);

        svg.append("g")
          .attr("class", "x axis x-axis")
          .attr("transform", "translate(0," + height + ")")
          .transition()
          .duration(1500)
          .call(xAxis);

        var yAxis = d3.svg.axis()
          .scale(y)
          .orient("left")
          .ticks(5)
          .tickFormat(function (d) { return d + "%"; });

        svg.append("g")
          .attr("class", "y axis y-axis")
          .call(yAxis)
          .transition()
          .duration(1500)

        var line = d3.svg.line()
          .x(function (d) { return x(d.date); })
          .y(function (d) { return y(d.likes); });

        svg.append("path")
          // .data(yearData)
          .attr("class", "line custom-md-line")
          .attr('fill', 'none')
          .attr('stroke', '#3FBDD1')
        // .attr("d", line);

        var data = graphData;

        var key = Object.keys(data);
        key.forEach(function (d) {
          data[d].forEach(function (d1) {
            d1.date = parseDate(d1.date);
            d1.likes = +d1.likes;
          })
        });
        // var selectVal:any  = document.getElementById('selectButton')
        // let year = selectVal.options[selectVal.selectedIndex].text
        let year = that.selectedYear;
        let selected: any = document.getElementById('selectYear');
        let year2021 = document.getElementById('year2021');
        let year2020 = document.getElementById('year2020');
        let year2019 = document.getElementById('year3');
        selected.addEventListener('change', function () {
          year = selected.value;
          let lineChart = d3.selectAll('#area1');
          update(data, year)
        })
        //load default selected option
        update(data, year);

        function update(data, selectedGroup) {
          var dataFilter = data[selectedGroup]
          // Scale the range of the data
          x.domain(d3.extent(dataFilter, function (d) { return d.date; }));
          y.domain([0, d3.max(dataFilter, function (d) { return d.likes; })]);

          // x.domain([dataFilter[0].date, dataFilter[dataFilter.length - 1].date]);
          // y.domain(d3.extent(dataFilter, function (d) { return d.likes; }));

          svg.selectAll(".x-axis")
            .transition()
            .duration(1000)
            .call(xAxis);

          svg.selectAll(".y-axis")
            .transition()
            .duration(1000)
            .call(yAxis);

          //Give these new data to update line
          d3.selectAll('.custom-md-line')
            .datum(dataFilter)
            .transition()
            .duration(1000)
            .attr("d", d3.svg.line()
              .x(function (d) { return x(d.date) })
              .y(function (d) { return y(+d.likes) })
            )

          var tooltip = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("display", "none");

          var focus = svg.append("g")
            .attr("class", "focus")
            .style("display", "none");

          // append the x line
          focus.append("line")
            .attr("class", "x")
            .style("stroke", "blue")
            .style("stroke-dasharray", "3,3")
            .style("opacity", 0.5)
            .attr("y1", 0)
            .transition()
            .duration(1000)
            .attr("y2", height);

          // append the y line
          focus.append("line")
            .attr("class", "y")
            .style("stroke", "blue")
            .style("stroke-dasharray", "3,3")
            .style("opacity", 0.5)
            .attr("x1", width)
            .attr("x2", width);

          focus.append("circle")
            .attr("class", "y")
            .style("fill", "none")
            .style("stroke", "blue")
            .attr("r", 5);

          var tooltipDate = tooltip.append("div")
            .attr("class", "tooltip-date");

          var tooltipLikes = tooltip.append("div");
          tooltipLikes.append("span")
            // .attr("class", "tooltip-title")
            .text("");

          var tooltipLikesValue = tooltipLikes.append("span")
            .attr("class", "tooltip-likes");
          svg
            .attr("class", "overlay")
            .attr("width", width)
            .attr("height", height)
            .style("pointer-events", "all")
            .on("mouseover", function () { focus.style("display", null); tooltip.style("display", null); })
            .on("mouseout", function () { focus.style("display", "none"); tooltip.style("display", "none"); })
            .on("mousemove", mousemove);

          function mousemove() {
            var x0 = x.invert(d3.mouse(this)[0]),
              i = bisectDate(dataFilter, x0, 1),
              d0 = dataFilter[i - 1],
              d1 = dataFilter[i],
              d = x0 - d0.date > d1.date - x0 ? d1 : d0;

            focus.select("circle.y")
              .attr("transform",
                "translate(" + x(d.date) + "," +
                y(d.likes) + ")");

            focus.select(".x")
              .attr("transform",
                "translate(" + x(d.date) + "," +
                y(d.likes) + ")")
              .attr("y2", height - y(d.likes));

            focus.select(".y")
              .attr("transform",
                "translate(" + width * -1 + "," +
                y(d.likes) + ")")
              .attr("x2", width + width);


            tooltip.attr("style", "left:" + (x(d.date) + 64) + "px; top:" + y(d.likes) + "px;");
            // tooltip.select(".tooltip-date").text(dateFormatter(d.date));
            tooltip.select(".tooltip-likes").text(formatValue(d.likes));
          }
        }
        d3.select("#selectButton").on("change", function (d) {
          var selectedOption = d3.select(this).property("value")
          update(data, selectedOption)
        })
      }

      const listData = {
        lines: [
          [[375, 330], [431, 330]],
          [[376, 467], [431, 467]],
          [[376, 604], [431, 604]],
          [[376, 740], [431, 740]],
          [[376, 877], [431, 877]],
        ],
      }

      listData.lines.forEach((el, i) => {
        let index = i;
        const line = addDashedLine(lineGroup, el, `screen-7 process-line-${i}`, '#25A1B2', '5,4', 1, false);
        line.attr('stroke-dashoffset', d => d.pathLength);
        // addLine(lineGroup, el, 'list-line list-line-' + index, index,"black",1,false);
      });
      // const backgroundData: any = {};

      // backgroundData.id = `chart-background-screen-7`;
      // backgroundData.x = 953;
      // backgroundData.y = 352;
      // backgroundData.height = 565;
      // backgroundData.width = 918;
      // backgroundData.fill = 'white';
      // backgroundData.class = ` chart-background-screen-7`;
      // backgroundData.o = 0.5;
      // addRect(businessProcess, backgroundData);
      // createMultiLineChart();

      function initiateAnimation() {
        const htmlDelayValue = 500;
        operateLine(lineGroup, 'process-line-0', 'draw', 300, 300, function () { })
        operateLine(lineGroup, 'process-line-1', 'draw', 300, 300, function () { })
        operateLine(lineGroup, 'process-line-2', 'draw', 300, 300, function () { })
        operateLine(lineGroup, 'process-line-3', 'draw', 300, 300, function () { })
        operateLine(lineGroup, 'process-line-4', 'draw', 300, 300, function () { })
        showImage(businessProcess, 'unselected-0', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-1', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-2', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-3', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-4', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-circle-0', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-circle-1', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-circle-2', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-circle-3', htmlDelayValue, 300, function () { })
        showImage(businessProcess, 'unselected-circle-4', htmlDelayValue, 300, function () {
          that.selectedProcess = businessProcessImages.dataContent[!that.changedManualy ? 0 : that.selectedProcessId];
          selectProcess(!that.changedManualy ? 0 : that.selectedProcessId);
          // that.selectedProcessId = 0;
          // createLineChart();
        })

      }
      return {
        startAnimation(isFirst = false) {
          d3.selectAll('#area1').attr('opacity', 1);
          d3.selectAll('#area2').attr('opacity', 1);
          d3.selectAll('#area3').attr('opacity', 1);
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen9.png';
          image.attr('xlink:href', baseImage);
          currentProcess.style('opacity', 1);
          d3.selectAll('.screen-2-common-group')
            .attr('transform', 'scale(0)');
          d3.selectAll('.screen-4-common-group')
            .attr('transform', 'scale(0)');
          if (isFirst) {
            initiateAnimation();
          } else {
            if (that.changedManualy) {
              that.selectedProcess = businessProcessImages.dataContent[!that.changedManualy ? 0 : that.selectedProcessId];
              selectProcess(!that.changedManualy ? 0 : that.selectedProcessId);
            }
          }
          businessProcess.selectAll('.business-process-group')
          .style('cursor', 'pointer')
          // commonLeftSection.manageTravellersGroup();
        },
        stopAnimation() {
          d3.selectAll('.screen-2-common-group')
            .attr('transform', 'scale(1)');
          d3.selectAll('.screen-4-common-group')
            .attr('transform', 'scale(1)');
          that.onAnimationEnd();
          // currentProcess.interrupt();
          currentProcess.style('opacity', 0);
          businessProcess.selectAll('.business-process-group')
          .style('cursor', 'initial')
        }
      }
    })();

    const screenEight = (() => {
      return {
        startAnimation(isFirst = false) {

        },
        stopAnimation() {
          that.onAnimationEnd();
        }
      }
    })();


    const commonAnimation = (() => {
      const commonGroup = dataGroup.append('g')
        .attr('id', 'common-group')
        .attr('class', 'common-group');

      const screen2 = commonGroup.append('g')
        .attr('id', 'screen-2-common-group')
        .attr('class', 'screen-2-common-group')
        .style('opacity', 1);
      const screen4 = commonGroup.append('g')
        .attr('id', 'screen-4-common-group')
        .attr('class', 'screen-4-common-group')
        .style('opacity', 1);

      const testTubeGrp = commonGroup.append('g')
        .attr('id', 'testTube-main-group')
        .attr('class', 'testTube-main-group');

      let testTubeArr = {
        textContent: {
          text: {
            wholeText: [
              {
                text: 'Historic Data',
                dy: '0em',
                x: 140,
                y: -120
              }],
            id: 'historic-data',
            class: 'historic-data test-tube-text',
            x: 126,
            y: 526,
            dy: '0em',
            finalX: 126,
            finalY: 526,
            o: 0,
            textStyle: {
              'text-anchor': 'middle',
              'font-size': 20,
              'fill': 'black',
              'font-weight': 600,
            },
          }
        },
        dataContent: [{
          background: {
            id: 'test-tube-image-1',
            width: 106,
            height: 441,
            src: 'assets/images/ctemp/SVG/testTube.svg',
            x: 210,
            y: 441,
            finalX: 210,
            finalY: 441,
            class: 'testTube test-tube-image-1 animate',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-2',
            width: 78,
            height: 240,
            src: 'assets/images/ctemp/SVG/liquid.svg',
            x: 223,
            y: 635,
            finalX: 223,
            finalY: 635,
            class: 'testTube test-tube-image-2 animate',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-3',
            width: 132,
            height: 132,
            src: 'assets/images/ctemp/SVG/bluePill.svg',
            x: 275,
            y: 225,
            finalX: 275,
            finalY: 225,
            class: 'testTube test-tube-image-3 animate',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-4',
            width: 139,
            height: 140,
            src: 'assets/images/ctemp/SVG/purplePill.svg',
            x: 128,
            y: 225,
            finalX: 128,
            finalY: 225,
            class: 'testTube test-tube-image-4 animate',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-5',
            width: 250,
            height: 250,
            src: 'assets/images/ctemp/SVG/capsule.svg',
            x: 150,
            y: 200,
            finalX: 150,
            finalY: 200,
            class: 'testTube test-tube-image-5 animate',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-6',
            width: 18,
            height: 18,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 270,
            finalY: 620,
            class: 'testTube test-tube-image-6 animate bubble bubble-group7',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-7',
            width: 18,
            height: 18,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 235,
            finalY: 620,
            class: 'testTube test-tube-image-7 animate bubble bubble-group7',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-8',
            width: 13,
            height: 13,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 265,
            finalY: 620,
            class: 'testTube test-tube-image-8 animate bubble bubble-group7',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-9',
            width: 10,
            height: 10,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 290,
            finalY: 620,
            class: 'testTube test-tube-image-9 animate bubble bubble-group6',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-10',
            width: 12,
            height: 12,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 232,
            finalY: 620,
            class: 'testTube test-tube-image-10 animate bubble bubble-group6',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-11',
            width: 13,
            height: 13,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 260,
            finalY: 620,
            class: 'testTube test-tube-image-11 animate bubble bubble-group6',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-12',
            width: 10,
            height: 10,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 240,
            finalY: 620,
            class: 'testTube test-tube-image-12 animate bubble bubble-group5',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-13',
            width: 10,
            height: 10,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 263,
            finalY: 620,
            class: 'testTube test-tube-image-13 animate bubble bubble-group5',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-14',
            width: 14,
            height: 14,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 260,
            finalY: 620,
            class: 'testTube test-tube-image-14 animate bubble bubble-group4',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-15',
            width: 9,
            height: 9,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 240,
            finalY: 620,
            class: 'testTube test-tube-image-15 animate bubble bubble-group4',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-16',
            width: 8,
            height: 8,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 280,
            finalY: 620,
            class: 'testTube test-tube-image-16 animate bubble bubble-group4',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-17',
            width: 10,
            height: 10,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 250,
            finalY: 620,
            class: 'testTube test-tube-image-17 animate bubble bubble-group3',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-18',
            width: 8,
            height: 8,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 270,
            finalY: 620,
            class: 'testTube test-tube-image-18 animate bubble bubble-group3',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-19',
            width: 6,
            height: 6,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 270,
            finalY: 620,
            class: 'testTube test-tube-image-19 animate bubble bubble-group3',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-20',
            width: 10,
            height: 10,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 250,
            finalY: 620,
            class: 'testTube test-tube-image-20 animate bubble  bubble-group2',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-21',
            width: 8,
            height: 8,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 275,
            finalY: 620,
            class: 'testTube test-tube-image-21 animate bubble  bubble-group2',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-21',
            width: 10,
            height: 10,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 280,
            finalY: 620,
            class: 'testTube test-tube-image-21 animate bubble bubble-group2',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-22',
            width: 14,
            height: 14,
            src: 'assets/images/ctemp/SVG/purple bubble.svg',
            x: 230,
            y: 330,
            finalX: 250,
            finalY: 620,
            class: 'testTube test-tube-image-22 animate bubble bubble-group1',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-23',
            width: 8,
            height: 8,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 270,
            finalY: 620,
            class: 'testTube test-tube-image-23 animate bubble bubble-group1',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-24',
            width: 8,
            height: 8,
            src: 'assets/images/ctemp/SVG/blue bubble.svg',
            x: 295,
            y: 320,
            finalX: 265,
            finalY: 620,
            class: 'testTube test-tube-image-24 animate bubble bubble-group1',
            s: 1,
            o: 0
          }
        }, {
          background: {
            id: 'test-tube-image-25',
            width: 78,
            height: 240,
            src: 'assets/images/ctemp/SVG/redLiquid.svg',
            x: 223,
            y: 635,
            finalX: 223,
            finalY: 635,
            class: 'testTube test-tube-image-25 animate',
            s: 1,
            o: 0
          }
        },
        ]
      };
      testTubeArr.dataContent.forEach((content, index) => {
        const group = testTubeGrp
          .append('g')
          .datum({
            x: 0,
            y: 0,
            finalX: 0,
            finalY: 0,
            s: 1,
            o: 1
          })
          .attr({
            'class': `testTube-group testTube-group-${index}`,
            'id': `testTube-group-${index}`,
            'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(${content.background.s})`
          })
          .style('opacity', (d: any) => d.o);

        createImageUsingData(group, content.background);
      });
      createTextUsingData(testTubeGrp, testTubeArr.textContent.text);

      function initiateAnimation() {
        const htmlDelayValue = 500;
        testTubeGrp.select('.test-tube-image-1')
          .transition()
          .delay(300)
          .duration(200)
          .style('opacity', 1)
          .call(endAll, function () {
            let capsule = testTubeGrp.select('.test-tube-image-5');
            capsule
              .transition()
              .delay((d, i) => 300)
              .duration(300)
              .style('opacity', 1)
              .call(endAll, function () {

                capsule
                  .transition()
                  .delay((d, i) => 300)
                  .duration(300)
                  .style('opacity', 0)
                  .call(endAll, function () {

                  });
                let bluePill = testTubeGrp.select('.test-tube-image-3');
                bluePill
                  .transition()
                  .delay((d, i) => 400)
                  .duration(300)
                  .style('opacity', 1)
                  .call(endAll, function () {

                  });
                let purplePill = testTubeGrp.select('.test-tube-image-4');
                purplePill
                  .transition()
                  .delay((d, i) => 400)
                  .duration(300)
                  .style('opacity', 1)
                  .call(endAll, function () {
                    bubbleAnimationStart();
                  });
              });
          });
      }

      //Function for bubble animation
      function bubbleAnimationStart() {
        const htmlDelayValue = 500;
        let bubbleGroup1 = testTubeGrp.selectAll('.bubble');
        bubbleGroup1
          .transition()
          .delay((d, i) => i * 200)
          .duration(1200)
          .style('opacity', 1)
          .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
          .call(endAll, function () {

          });
        let bubble = testTubeGrp.selectAll('.bubble');
        bubble
          .transition()
          .delay((d, i) => i * 200 + 1200)
          .duration(500)
          .style('opacity', 0)
          // .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
          .call(endAll, function () {
            reverseBubbleAnimation();
          });
        // let bubbleGroup2 = testTubeGrp.selectAll('.bubble-group2');
        // bubbleGroup2
        //   .transition()
        //   .delay((d, i) => i * 200 )
        //   .duration(500)
        //   .style('opacity', 1)
        //   .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        //   .call(endAll, function () {

        //     let bubbleGroup3 = testTubeGrp.selectAll('.bubble-group3');
        //     bubbleGroup3
        //       .transition()
        //       .delay((d, i) => i * 200)
        //       .duration(500)
        //       .style('opacity', 1)
        //       .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        //       .call(endAll, function () {

        //         let bubbleGroup4 = testTubeGrp.selectAll('.bubble-group4');
        //         bubbleGroup4
        //           .transition()
        //           .delay((d, i) => i * 200)
        //           .duration(500)
        //           .style('opacity', 1)
        //           .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        //           .call(endAll, function () {

        //             let bubbleGroup5 = testTubeGrp.selectAll('.bubble-group5');
        //             bubbleGroup5
        //               .transition()
        //               .delay((d, i) => i * 200)
        //               .duration(500)
        //               .style('opacity', 1)
        //               .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        //               .call(endAll, function () {

        //                 let bubbleGroup6 = testTubeGrp.selectAll('.bubble-group6');
        //                 bubbleGroup6
        //                   .transition()
        //                   .delay((d, i) => i * 200)
        //                   .duration(500)
        //                   .style('opacity', 1)
        //                   .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        //                   .call(endAll, function () {

        //                     let bubbleGroup7 = testTubeGrp.selectAll('.bubble-group7');
        //                     bubbleGroup7
        //                       .transition()
        //                       .delay((d, i) => i * 200)
        //                       .duration(500)
        //                       .style('opacity', 1)
        //                       .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        //                       .call(endAll, function () {
        //                         let liquid = testTubeGrp.select('.test-tube-image-2')
        //                         liquid
        //                           .transition()
        //                           .delay(200)
        //                           .duration(500)
        //                           .style('opacity', 1)
        //                           .call(endAll, function () {

        //                           });
        //                       });
        //                   });
        //               });
        //           });
        //       });
        //   });
      }

      function hospitalBedAnimation() {
        showImage(screen4, 'common-hospital-bed-1', 300, 300, function () {

        })
      }
      //reverse bubble animation
      function reverseBubbleAnimation() {
        const htmlDelayValue = 500;
        let bubble = testTubeGrp.selectAll('.bubble');
        bubble
          .transition()
          .delay((d, i) => i * 10)
          .duration(10)
          .style('opacity', 0)
          .attr('transform', (d) => `translate(${d.x}, ${d.x})`)
          .call(endAll, function () {
            bubbleAnimationStart();
          });
      }

      //function for change liquid color
      function chnageLiquidColor() {
        showText(testTubeGrp, 'historic-data', 300, 300, function () { });
        let liquid = testTubeGrp.select('.test-tube-image-2')
        liquid
          .transition()
          .delay((d, i) => i * 200)
          .duration(500)
          .style('opacity', 0)
          .call(endAll, function () {
            let redLiquid = testTubeGrp.selectAll('.test-tube-image-25');
            redLiquid
              .transition()
              .delay((d, i) => i * 200)
              .duration(500)
              .style('opacity', 1)
              // .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
              .call(endAll, function () {
              });
          });
      }

      function changeLiquidColorBack() {
        hideText(testTubeGrp, 'historic-data', 300, 300, function () { });
        let liquid = testTubeGrp.select('.test-tube-image-25')
        liquid
          .transition()
          .delay(200)
          .duration(500)
          .style('opacity', 0)
          .call(endAll, function () {
            let redLiquid = testTubeGrp.selectAll('.test-tube-image-2');
            redLiquid
              .transition()
              .delay(200)
              .duration(500)
              .style('opacity', 1)
              // .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
              .call(endAll, function () {
              });
          });
      }

      // commonGroup.style('opacity', 0);
      let commonAmerica = {
        background: {
          id: 'common-america-image-1',
          width: 480,
          height: 596,
          src: 'assets/images/ctemp/SVG/america.svg',
          x: 304,
          y: 425,
          finalX: 304,
          finalY: 425,
          class: 'america common-america-image-1 background pie-background animate screen-3-images',
          s: 1,
          o: 0
        }
      };
      let group = screen2
        .append('g')
        .datum({
          x: 0,
          y: 0,
          finalX: 0,
          finalY: 0,
          s: 1,
          o: 1
        })
        .attr({
          'class': `common-group common-group-1`,
          'id': `common-group-1`,
          'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
        })
        .style('opacity', (d: any) => d.o);
      createImageUsingData(group, commonAmerica.background);
      let hospitalBed = {
        background: {
          id: 'common-hospital-bed-1',
          width: 497,
          height: 851,
          src: 'assets/images/ctemp/SVG/hospitalBed.svg',
          x: 300,
          y: 132,
          finalX: 800,
          finalY: 132,
          class: 'common common-hospital-bed-1 animate',
          s: 1,
          o: 0
        },
      };
      let group2 = screen4
        .append('g')
        .datum({
          x: 0,
          y: 0,
          finalX: 0,
          finalY: 0,
          s: 1,
          o: 1
        })
        .attr({
          'class': `common-group common-group-2`,
          'id': `common-group-2`,
          'transform': (d: any) => `translate(${d.x}, ${d.y}) scale(1)`
        })
        .style('opacity', (d: any) => d.o);
      createImageUsingData(group2, hospitalBed.background);
      return {
        startAnimation(isFirst = false) {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen7.png';
          image.attr('xlink:href', baseImage);
          testTubeGrp.style('opacity', 1);
          if (isFirst) {
            initiateAnimation();
          }
          // that.onAnimationEnd();
        },
        startLiquidAnimation() {
          baseImage = '';
          // baseImage = '/assets/images/ctemp/screen7.png';
          image.attr('xlink:href', baseImage);
          // that.onAnimationEnd();
          testTubeGrp.style('opacity', 1);
          chnageLiquidColor();
          // that.onAnimationEnd();
        },
        resetElements() {

        },
        stopAnimation() {
          testTubeGrp.style('opacity', 0);
        },
        showTestTube() {
          showImage(testTubeGrp, 'test-tube-image-1', 500, 300, function () { })
          showImage(testTubeGrp, 'test-tube-image-2', 500, 300, function () { })
          // showImage(testTubeGrp, 'test-tube-image-3', 500, 300, function () { })
          // showImage(testTubeGrp, 'test-tube-image-4', 500, 300, function () { })
        },
        hideAmerica() {
          screen2.style('opacity', 0)
        },
        showAmerica() {
          screen2.style('opacity', 1)
        },
        showHospitalBed() {
          screen4.style('opacity', 1);
          if (!that.animationStarted.hospitalBed) {
            hospitalBedAnimation();
            that.animationStarted.hospitalBed = true;
          }

        },
        hideHospitalBed() {
          screen4.style('opacity', 0);
        },
        showGroup(isFirst = false) {
          commonGroup.style('opacity', 1);
        },
        hideGroup() {
          commonGroup.style('opacity', 0);
        },
        chnageLiquidColorBack() {
          changeLiquidColorBack()
        }
      }
    })()
    // this.createDashedLine(`switch-line`, lineGroup, lineData, 'dashed-lines', '#ffffff', '8,3', 1).classed('switch-lines animate', true);

    function resetElements() {
      // operateLine(travelLeftLineGroup, 'travel-line', 'hide', 0, 0);
      // operateLine(centerLeftLineGroup, 'center-left-line', 'hide', 0, 0);
      // hideImage(middleSection, 'screen-2-images', 0, 0);
      // hideImage(middleSection, 'screen-2-text', 0, 0);
      // hideImage(middleBottomSection, 'screen-2-images', 0, 0);
      // hideImage(middleBottomSection, 'screen-2-text', 0, 0);
    }

    const { resize, heightAspect, widthAspect } = this._responsivefy(svg, true);
    return {
      resize() {
        const aspect = resize();
        that.scaleXValue = aspect.widthAspect;
        that.scaleYValue = aspect.heightAspect;
        return aspect;
      },
      screenOne,
      screenTwo,
      screenThree,
      screenFour,
      screenFive,
      screenSix,
      screenSeven,
      screenEight,
      commonAnimation
    }

  }

  onAnimationEnd() {
    d3.selectAll('#area1').attr('opacity', 0);
    d3.selectAll('#area2').attr('opacity', 0);
    d3.selectAll('#area3').attr('opacity', 0);
  }



  addClassToDom(selector, className, type = 'id') {
    if (type == 'id')
      d3.select(`#${selector}`).classed(className, true)
    else
      d3.select(`.${selector}`).classed(className, true)
  }

  removeClassFromDom(selector, className, type = 'id') {
    if (type == 'id')
      d3.select(`#${selector}`).classed(className, false);
    else
      d3.select(`.${selector}`).classed(className, false);
  }

  ngOnDestroy() {
    this._renderer.removeClass(document.body, 'ctemp')
  }

}
