import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { GalleryService } from '../gallery.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { GAIndex } from 'src/app/shared/enum/enum';

@Component({
  selector: 'app-confirmation-overlay',
  templateUrl: './confirmation-overlay.component.html',
  styleUrls: ['./confirmation-overlay.component.css'],
  providers: [GalleryService]
})
export class ConfirmationOverlayComponent implements OnInit{
  
  public isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationOverlayComponent>,
    public gallaryService: GalleryService) {
    
  }

  ngOnInit() {

  }
  
  onOK() {
    this.dialogRef.close(true); 
  }

  onCancel() {
   this.dialogRef.close(false); 
  }
}
