import { Component, OnInit, Input } from '@angular/core';
import { WidgetService } from '../widgets.service';
import * as _ from 'lodash';

@Component({
	selector: 'app-custom-event-timeline',
	templateUrl: './custom-event-timeline.component.html'
})
export class CustomEventTimelineComponent implements OnInit {
	@Input() widgetData;
	data;

	constructor(private widgetService: WidgetService) {
		this.widgetService.triggerDataChangeForCustomEventTimeline.subscribe((updatedDataInfo) => {
			// if ((updatedDataInfo.formField.uniqueName4001 && updatedDataInfo.formField.uniqueName4001.length)) {
			if (updatedDataInfo.formField.filterField) {
				this.updateData(updatedDataInfo);
			} else {
				// this.resetChart();
				this.data = [];
			}
		});
	}

	updateData(updatedDataInfo) {
		let foundIndex;
		const selectedData = [updatedDataInfo.formField.filterField];
		this.data = [];
		for (let index = 0; index < selectedData.length; index++) {
			foundIndex = _.findIndex(this.widgetData.data, { customerId: selectedData[index] });
			if (foundIndex > -1) {
				for (let timeline = 0; timeline < this.widgetData.data[foundIndex].events.length; timeline++) {
					for (let prevTimelineIndex = 0;
						prevTimelineIndex < this.widgetData.data[foundIndex].events[timeline].timeline.length; prevTimelineIndex++) {
						let eventCount = 1;
						if (this.widgetData.data[foundIndex].events[timeline].timeline[prevTimelineIndex].channel !== 'Rep Visit'
							|| this.widgetData.data[foundIndex].events[timeline].timeline[prevTimelineIndex].isHighlight) {
							continue;
						}
						for (let nextTimelineIndex = prevTimelineIndex + 1;
							nextTimelineIndex < this.widgetData.data[foundIndex].events[timeline].timeline.length; nextTimelineIndex++) {
							if (this.widgetData.data[foundIndex].events[timeline].timeline[prevTimelineIndex].channel
								=== this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex].channel) {
								eventCount++;
								if (this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex].gap > 1
									|| this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex + 1]
									&& this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex + 1].channel === 'Rep Visit') {
									this.widgetData.data[foundIndex].events[timeline].timeline[prevTimelineIndex].isHighlight = true;
									this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex].isHighlight = true;
									if (this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex + 1]
										&& this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex + 1].channel === 'Rep Visit') {
										this.widgetData.data[foundIndex].events[timeline].timeline[nextTimelineIndex + 1].isHighlight = true;
									}
								}
							} else {
								break;
							}
						}
					}
				}
				this.data.push(this.widgetData.data[foundIndex]);
			}
		}
	}

	ngOnInit() { }
}
