import { Directive, Output, EventEmitter, ElementRef, Input, AfterViewInit, Renderer2, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appContainerScrollSpy]'
})
export class ContainerScrollSpyDirective implements AfterViewInit, OnDestroy {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onSectionChange = new EventEmitter<any>();
  @Input() public spyIds = [];
  @Input() public scrollContainerId = '';

  private currentSection: string;
  private initPosition: any = [];
  private unsubscribeScroll = null;
  constructor(
    private _el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }

  // ngDoCheck() {
  //   const height = document.getElementById(this.scrollContainerId).getBoundingClientRect().height;
  //   if (this._lastHeight !== height) {
  //     this._lastHeight = height;
  //     setTimeout(() => {
  //       const offsetTop = document.getElementById(this.scrollContainerId).offsetTop;
  //       this.initPosition.forEach(v => {
  //         // v.position.top = scrollTop + v.element.getBoundingClientRect().top - offsetTop;
  //         v.position.top = v.element.offsetTop - offsetTop;
  //       });
  //     }, 500);
  //   }
  // }
  ngAfterViewInit() {
    // setTimeout(() => {
      this.initPosition = this.calculatePosition();
    // }, 0);
    this.unsubscribeScroll = this.renderer.listen(document.getElementById( this.scrollContainerId), 'scroll', ($e) => {
      const scrollTop = $e.target.scrollTop;
      // Add class to body on 100px scroll
      if (scrollTop >= 100 && !this.document.body.classList.contains('tiny-header')) {
        this.renderer.addClass(this.document.body, 'tiny-header');
      } else if (scrollTop < 100) {
        this.renderer.removeClass(this.document.body, 'tiny-header');
      }
      const OffsetTop = $e.target.offsetTop;
      let currentSection: string = this.currentSection;
      const scrollWindowHeight = $e.target.offsetHeight;
      const result = this.initPosition.filter(v => {
        return ((scrollTop + scrollWindowHeight) > (v.element.offsetTop - OffsetTop))
                && (scrollTop < ((v.element.offsetHeight + (v.element.offsetTop - OffsetTop))));
        // 3 return ((scrollTop + scrollWindowHeight) > (v.position.top))
        //         && (scrollTop < ((v.element.offsetHeight + v.position.top)));
        // 2 return ((scrollTop + scrollWindowHeight) > (v.position.top - OffsetTop))
        //         && (scrollTop < ((v.element.offsetHeight + v.position.top) - OffsetTop));

        // 1 return ((scrollTop + scrollWindowHeight) > (v.position.top - OffsetTop)) && (scrollTop < (v.position.bottom - OffsetTop));
      });
      if (result.length === 1) {
        currentSection = result[0].id;
      } else if (result.length > 1) {
        const topVal = ((result[0].element.offsetHeight + (result[0].element.offsetTop - OffsetTop))) - scrollTop;
        // 3 const topVal = ((result[0].element.offsetHeight + result[0].position.top)) - scrollTop;
        // 2 const topVal = ((result[0].element.offsetHeight + result[0].position.top)) - scrollTop - OffsetTop;
        // 1 const topVal = result[0].position.bottom - scrollTop - OffsetTop;
        // currentSection = (topVal > (scrollWindowHeight / 2)) ? result[0].id : result[1].id;
        const r = (topVal > (scrollWindowHeight / 2)) ? result[0] : result[1];
        currentSection = r.id;
      }
      if (currentSection !== this.currentSection) {
        this.currentSection = currentSection;
        this.onSectionChange.emit({value: this.currentSection});
      }
    });
  }

  private calculatePosition() {
    return this.spyIds.map(v => {
      const element = this._el.nativeElement.querySelector('#' + v);
      return {
        id: v,
        // position: {top: element.offsetTop - offsetTop}, // {top: boundRect.top - offsetTop},
        element
      };
    });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'tiny-header');
    if (this.unsubscribeScroll) {
      this.unsubscribeScroll();
    }
  }
}
