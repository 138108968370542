import { Directive, Input, Output, EventEmitter, ElementRef,
        Renderer2, AfterViewInit, AfterViewChecked, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective implements AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() public spiedTags = [];
  @Input() private parentRef = null;
  @Output() public sectionChange = new EventEmitter<any>();
  @Input() public scrollContainerId = '';
  private currentSection: string;
  private _lastHeight = 0;
  private _scrollSection;
  private unsubscribeScroll = null;
  initPosition;
  constructor(
    private _el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document) { }
  private calculatePosition() {
    const children = this._el.nativeElement.children;
    const result = [];
    for (let i = 0 ; i < children.length; i++) {
      const e = children[i];
      result.push({
          id: e.id,
          // position: (element.getBoundingClientRect()),
          // e
      });
    }
    return result;
  }
  ngAfterViewChecked(): void {
    if (this._lastHeight !== this._scrollSection.offsetHeight) {
      this.initPosition = this.calculatePosition();
    }
  }
  ngAfterViewInit(): void {
    this._scrollSection = document.getElementById(this.scrollContainerId);
    this._lastHeight = this._scrollSection.offsetHeight;
    this.initPosition = this.calculatePosition();
    this.unsubscribeScroll = this.renderer.listen(this._scrollSection, 'scroll', ($e) => {
      const scrollTop = $e.target.scrollTop;
      // Add class to body on 100px scroll
      if (scrollTop >= 100 && !this.document.body.classList.contains('tiny-header')) {
        this.renderer.addClass(this.document.body, 'tiny-header');
      } else if (scrollTop < 100) {
        this.renderer.removeClass(this.document.body, 'tiny-header');
      }
      const OffsetTop = $e.target.offsetTop;
      let currentSection: string = this.currentSection;
      const scrollWindowHeight = $e.target.offsetHeight;
      const result = this.initPosition.filter(v => {
        v.element = document.getElementById(v.id);
        return ((scrollTop + scrollWindowHeight) > (v.element.offsetTop - OffsetTop))
                && (scrollTop < ((v.element.offsetHeight + v.element.offsetTop) - OffsetTop));
      });
      const emitValue = {};
      if (result.length === 1) {
        currentSection = result[0].id;
        for (const x of result[0].element.attributes) {
          emitValue[x.localName] = x.value;
        }
      } else if (result.length > 1) {
        const topVal = ((result[0].element.offsetHeight + result[0].element.offsetTop)) - scrollTop - OffsetTop;
        const r = (topVal > (scrollWindowHeight / 2)) ? result[0] : result[1];
        currentSection = r.id;
        for (const x of r.element.attributes) {
          emitValue[x.localName] = x.value;
        }
      }
      if (currentSection !== this.currentSection) {
        this.currentSection = currentSection;
        this.sectionChange.emit({id: this.currentSection, value: {...emitValue}});
      }
      });
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'tiny-header');
    if (this.unsubscribeScroll) {
      this.unsubscribeScroll();
    }
  }
}
