export class D3CustomShapesService {
    mathPI = Math.PI;
    tau$4 = 2 * this.mathPI;
    ka = 0.89081309152928522810;
    kr = Math.sin(this.mathPI / 10) / Math.sin(7 * this.mathPI / 10);
    kx = Math.sin(this.tau$4 / 10) * this.kr;
    ky = -Math.cos(this.tau$4 / 10) * this.kr;
    sqrt3 = Math.sqrt(3);
    circumradiusCoeff = 1 / 10 * Math.sqrt(50 + 10 * Math.sqrt(5)); 

    flowShapes = null;
    pathActions = null;

    constructor() {
        this.initPathsActions();
        this.initShapes();
    }

    initPathsActions() {
        this.pathActions = {
            moveTo: (x, y) => {
                return "M" + x + ", " + y;
            },
            lineTo: (x, y)  => {
                return "L" + x + ", " + y;
            },
            closePath: ()  => {
                return "Z";
            }
        }
    }

    initShapes() {
        this.flowShapes = {
            square: (size) => {
                let r = Math.sqrt(size) / 2;
                return "M" + -r + "," + -r + "L" + r + "," + -r + " " + r + "," + r + " " + -r + "," + r + "Z";
            },
            circle: (size) => {
                let r = Math.sqrt(size / Math.PI);
                return "M0," + r + "A" + r + "," + r + " 0 1,1 0," + -r + "A" + r + "," + r + " 0 1,1 0," + r + "Z";
            },
            cross: (size) => {
                let r = Math.sqrt(size / 5) / 2;
                return "M" + -3 * r + "," + -r + "H" + -r + "V" + -3 * r + "H" + r + "V" + -r + "H" + 3 * r + "V" + r + "H" + r + "V" + 3 * r + "H" + -r + "V" + r + "H" + -3 * r + "Z";
            },
            hexagoan: (size) => {
                let t = this.tau$4 / 12;
                let num = 2 * size;
                let denom = 3 * Math.sqrt(3);
                let s = Math.sqrt(num / denom);
                let R = s;
                let points = this.rotatePoint(R, 0, t);
                let path = this.pathActions.moveTo(points[0], points[1]);

                for (let i = 0; i < 6; ++i) {
                    let a = this.tau$4 * i / 6;
                    let x = Math.cos(a) * R;
                    let y = Math.sin(a) * R;
                    points = this.rotatePoint(x, y, t);
                    path += this.pathActions.lineTo(points[0], points[1]);

                }

                path += this.pathActions.closePath();
                return path;

            },
            star: (size)=> {
                let r = Math.sqrt(size * this.ka),
                    x = this.kx * r,
                    y = this.ky * r;
                // context.moveTo(0, -r);
                let path = "M0," + -r;
                path += "L" + x + ", " + y;
                // context.lineTo(x, y);
                for (let i = 1; i < 5; ++i) {
                    let a = this.tau$4 * i / 5,
                        c = Math.cos(a),
                        s = Math.sin(a);
                    path += "L" + (s * r) + ", " + (-c * r);
                    path += "L" + (c * x - s * y) + ", " + (s * x + c * y);
                }
                path += "Z";
                return path;
            },
            pentagon: (size) => {

                let s = this.sideLength(size);
                let R = this.circumradius(s);
                let theta = -this.tau$4 / 4; // Rotate 1/4 turn back so the shape is oriented with a point upward.

                let points = this.rotatePoint(R, 0, theta);
                let path = "M" + points[0] + ", " + points[1];

                for (let i = 0; i < 5; ++i) {
                    let a = this.tau$4 * i / 5;
                    let x = Math.cos(a) * R;
                    let y = Math.sin(a) * R;
                    points = this.rotatePoint(x, y, theta);
                    path += "L" + points[0] + ", " + points[1];

                }
                path += "Z";
                return path;
            },
            triangleDown: (size) => {
                let y = -Math.sqrt(size / (this.sqrt3 * 3));
                let path = this.pathActions.moveTo(0, -y * 2);
                path += this.pathActions.lineTo(-this.sqrt3 * y, y);
                path += this.pathActions.lineTo(this.sqrt3 * y, y);
                path += this.pathActions.closePath();
                return path;
            },
            triangleLeft: (size) => {
                let x = -Math.sqrt(size / (this.sqrt3 * 3));
                let path = this.pathActions.moveTo(x * 2, 0);
                path += this.pathActions.lineTo(-x, -this.sqrt3 * x);
                path += this.pathActions.lineTo(-x, this.sqrt3 * x);
                path += this.pathActions.closePath()
                return path;

            },
            triangleRight: (size) => {
                let x = -Math.sqrt(size / (this.sqrt3 * 3));
                let path = this.pathActions.moveTo(-x * 2, 0);
                path += this.pathActions.lineTo(x, -this.sqrt3 * x);
                path += this.pathActions.lineTo(x, this.sqrt3 * x);
                path += this.pathActions.closePath()
                return path;
            }

        }
    }

    circumradius(side) {
        return side * this.circumradiusCoeff;
    }

    sideLength(area) {
        let num = 4 * area;
        let denom = Math.sqrt(5 * (5 + 2 * Math.sqrt(5)));
        return Math.sqrt(num / denom);
    }

    rotatePoint(x, y, theta) {
        return [
            Math.cos(theta) * x + -Math.sin(theta) * y,
            Math.sin(theta) * x + Math.cos(theta) * y
        ];
    }

    getShapeCoordinates(shapeType, size) {
        return this.flowShapes[shapeType](size);
    }
}
