import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
declare let d3: any;

@Component({
  selector: 'app-d3-grouped-bar-chart',
  templateUrl: './d3-grouped-bar-chart.component.html',
  styleUrls: ['./d3-grouped-bar-chart.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class D3GroupedBarChartComponent implements OnInit {

  @ViewChild('d3GroupedBarChartRef') chartElement: ElementRef;
  @Input() widgetData;
  @Input() widgetsCount;

  constructor() {

  }

  ngOnInit() {
    this.loadGroupTree(this.widgetData.data);
  }

  loadGroupTree(data) {

    var margin = { top: 40, right: 20, bottom: 30, left: 65 },
      width = (this.widgetsCount > 1 ? 580 : 700) - margin.left - margin.right, //
      height = (this.widgetsCount > 1 ? 250 : 500) - margin.top - margin.bottom; //

    var x0 = d3.scale.ordinal()
      .rangeRoundBands([0, width], .1);

    var x1 = d3.scale.ordinal();

    var y = d3.scale.linear()
      .range([height, 0]);

    var xAxis = d3.svg.axis()
      .scale(x0)
      .tickSize(0)
      .orient("bottom");

    var yAxis = d3.svg.axis()
      .scale(y)
      .orient("left");

    var color = d3.scale.ordinal()
      .range(["#2fbcd1", "#f36152"]);

    var svg = d3.select(this.chartElement.nativeElement).append("svg")
      .attr("class", () => {

        var className = "";

        if (this.widgetsCount > 1) {
          className += " small-font-size";
        }

        return className;
      })
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    //d3.json("data.json", function(error, data) {

    var categoriesNames = data.map(function (d) { return d.categorie; });
    var rateNames = data[0].values.map(function (d) { return d.rate; });

    x0.domain(categoriesNames);
    x1.domain(rateNames).rangeRoundBands([0, x0.rangeBand()]);
    y.domain([0, d3.max(data, function (categorie) { return d3.max(categorie.values, function (d) { return d.value; }); })]);

    svg.append("g")
      .attr("class", "x axis text-font")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    svg.append("g")
      .attr("class", "y axis text-font")
      .style('opacity', '0')
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -45)
      .attr("x", -(height / 2) + 25)
      .attr("dy", "-0.6em")
      .style("text-anchor", "end")
      // .style('font-weight', 'bold')
      .text(this.widgetData.yAxisLable || "Value");

    svg.select('.y').transition().duration(500).delay(1300).style('opacity', '1');

    var slice = svg.selectAll(".slice")
      .data(data)
      .enter().append("g")
      .attr("class", "g")
      .attr("transform", function (d) { return "translate(" + x0(d.categorie) + ",0)"; });
    var x = 0;
    slice.selectAll("rect")
      .data(function (d) { return d.values; })
      .enter().append("rect")
      .attr("width", x1.rangeBand() - 4)
      //.attr("width", 26)
      .attr("x", function (d) { return x1(d.rate); })
      .style("fill", function (d) { return color(d.rate) })
      .attr("y", function (d) { return y(0); })
      .attr("height", function (d) { return height - y(0); })
      .on("mouseover", function (d) {
        d3.select(this).style("fill", d3.rgb(color(d.rate)).darker(1));
        div.style('display', 'inline');
      }
      )
      .on("mousemove",
        function (d) {
          div
            .html(d.value)
            .style('left', (d3.event.pageX + 15) + 'px')
            .style('top', (d3.event.pageY - 20) + 'px');
        }
      ).on('mouseout', function(d) {
        d3.select(this).style("fill", color(d.rate));
        div.style('display', 'none');
      });

    slice.selectAll("rect")
      .transition()
      .delay(function (d) { return Math.random() * 1000; })
      .duration(1000)
      .attr("y", function (d) { return y(d.value); })
      .attr("height", function (d) { return height - y(d.value); });

    //Legend
    var legend = svg.selectAll(".legend")
      .data(data[0].values.map(function (d) { return d.rate; }).reverse())
      .enter().append("g")
      .attr("class", "legend")
      //.attr("transform", function(d,i) { return "translate(0," + i * 20 + ")"; })
      .attr("transform", function (d, i) { return "translate(" + (i == 0 ? -60 : -175) + ", -35)"; })
      .style("opacity", "0");

    legend.append("rect")
      .attr("x", width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", function (d) { return color(d); });

    legend.append("text")
      .attr("x", width - 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .attr("class", "text-font")
      .style("text-anchor", "end")
      .style("fill", "#000")
      .text(function (d) { return d; });

    legend.transition().duration(500).delay(function (d, i) { return 1300 + 100 * i; }).style("opacity", "1");
    var div = d3.select('body').append('div')
      .attr('class', 'graph-tooltip dark')
      .attr('id', 'd3-grouped-bar-chart-custom-tooltip')
      .style('display', 'none');

    //});
  }

  OnDestroy() {
    d3.select("#d3-grouped-bar-chart-custom-tooltip").remove();
  }

}
