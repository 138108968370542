import { DragDropModule, CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatTableModule,
  MatDialogModule,
  MatSortModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatExpansionModule,
  MatSelectModule,
  MatGridListModule
} from '@angular/material';

// const DragConfig = {
//   dragStartThreshold: 0,
//   pointerDirectionChangeThreshold: 5,
//   zIndex: 100000
// };

@NgModule({
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatSortModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSelectModule,
    MatGridListModule,
    DragDropModule
  ],
  exports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatSortModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSelectModule,
    MatGridListModule,
    DragDropModule
  ],
  // providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }],
})
export class MaterialModule {}