import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import moment from 'moment';

import { HttpWrapperService } from '../../../services/http-wrapper.service';
import { environment } from '../../../../environments/environment';
import { UserStatus } from '../../../shared/enum/enum';

@Injectable()
export class AdminService {

  public currentDate = new Date();
  constructor(private _http: HttpWrapperService) { }

  parseUserObj(result, isResponse: boolean, timeZoneOffset) {
    let accessStatus = result.accessStatus;
    let startDate = result.accessStartDate;
    let endDate = result.accessEndDate;
    if (isResponse) {
      accessStatus = (result.accessStatus === 'unblock') ? true : false;
      startDate = new Date(+result.accessStartDate); // - (timeZoneOffset * 60 * 1000)
      endDate = new Date(+result.accessEndDate); // - (timeZoneOffset * 60 * 1000)
    } else {
      accessStatus = (result.accessStatus) ? 'unblock' : 'block';
      // startDate = result.accessStartDate.getTime(); // + (timeZoneOffset * 60 * 1000);
      // endDate = result.accessEndDate.getTime();// + (timeZoneOffset * 60 * 1000);
      let time = moment(result.accessStartDate).format('YYYY/MM/DD');
      startDate = moment(time).format('x');
      time = (moment(result.accessEndDate).format('YYYY/MM/DD')) + ' 23:59:59';
      endDate = moment(time).format('x');
    }

    result.accessStatus = accessStatus;
    result.accessStartDate = startDate;
    result.accessEndDate = endDate;
    result.status = result.accessStatus === true &&
      (this.currentDate >= result.accessStartDate && this.currentDate <= result.accessEndDate) ? UserStatus.ACTIVE :
      result.accessStatus === false && (this.currentDate >= result.accessStartDate &&
        this.currentDate <= result.accessEndDate) ? UserStatus.ACTIVE_BLOCK : UserStatus.INACTIVE;
    return result;
  }

  getUsersList(): Observable<Response> {
    return this._http.get(environment.api_url + '/users');
  }

  updateUser(user): Observable<Response> {
    return this._http.put(environment.api_url + '/user', user);
  }

  addUser(user): Observable<Response> {
    return this._http.post(environment.api_url + '/user', user);
  }

  getUserHistory(userid): Observable<Response> {
    return this._http.get(environment.api_url + '/user/session-history/' + userid);
  }

  getIpList(userid): Observable<Response> {
    return this._http.get(environment.api_url + '/users/application-accessed?userId=' + userid);
  }

  toggleUserLockedStatus(data): Observable<Response> {
    return this._http.post(environment.api_url + '/users/application-accessed', data);
  }

  getStoriesCredentialsList(): Observable<Response> {
    return this._http.get(environment.api_url + '/stories/meta-details');
  }

  updateStoryCredentials(data): Observable<Response> {
    return this._http.post(environment.api_url + '/story', data);
  }
}
