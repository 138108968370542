import { Component, OnInit, Input, ElementRef, ViewChild, ViewEncapsulation, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
declare let d3: any;
declare let colorbrewer: any;

import * as _ from 'lodash';
import { D3CustomShapesService } from '../../services/helper/d3-custom-shapes.service';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-d3-flowchart-sanky-chart-v2',
  templateUrl: './d3-flowchart-sanky-chart-v2.component.html',
  styleUrls: ['d3-flowchart-sanky-chart-v2.component.css']
})
export class D3FlowchartSankyChartV2Component implements OnInit, OnDestroy {

    constructor(private _d3CustomShapesService: D3CustomShapesService, private renderer: Renderer2) {}
    @Input() widgetData;
    data;
    @ViewChild('sankyChartNewRef') chartElement: ElementRef;

    flowchart;
    svg;
    fitness_color;
    size_color;
    height_color;
    format;
    path;
    link_color;
    public showNodesFormula = [];
    private showNodesFormulaTimer = null;
    private unsubscribeResize = null;

    private responsivefy(svg, isHeightNotToUpdate = false) {
      const container = d3.select(svg.node().parentNode);
      const width = parseInt(svg.style('width'), 10);
      const height = parseInt(svg.style('height'), 10);
      const aspect = width / height;
      // get width of container and resize svg to fit it
      const resize = () => {
        const targetWidth = parseInt(container.style('width'), 10);
        svg.attr('width', targetWidth);
        let targetHeight = targetWidth / aspect;
        if (isHeightNotToUpdate) {
          // Set Container Height as is.
          targetHeight = container.node().getBoundingClientRect().height;
        }
        svg.attr('height', Math.round(targetHeight));
        return {
          widthAspect: targetWidth / width,
          heightAspect: targetHeight / height
        };
      };
      svg.attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(resize);
      this.unsubscribeResize = this.renderer.listen(window, 'resize', _.debounce(() => {
        resize();
      }, 700));
    }

    ngOnInit() {
      this.data = this.widgetData.data;
      this.loadGraph();
    }
    loadGraph() {
      const evolution_height = 250;
      const evolution_width = 1220;

      const margin = { top: 5, right: 0, bottom: 8, left: 0 };
      const width = evolution_width - margin.left - margin.right;
      const height = evolution_height - margin.top - margin.bottom;

      this.format = function(d) {
        const str = 'Fitness: ' + (d.fitness / 100).toFixed(2);
        /*str += " ,size: " + d.size + "(" + d.scaled_size  + ")";
        str += " ,height: " + d.height + "(" + d.scaled_height  + ")";*/
        return str;
      };
      const color = d3.scale.category20();

      this.svg = d3.select(this.chartElement.nativeElement).append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('class', 'aligned')
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
      this.responsivefy(d3.select(this.chartElement.nativeElement).select('svg'));
      this.flowchart = d3.flowchart()
        .node_width(10)
        .node_height(4)
        .size([width, height]);
      this.flowchart.sort_type('unsorted');
      this.flowchart.scale_type('unscaled');

      this.path = this.flowchart.link();

      this.fitness_color = null,
        this.size_color = null,
        this.height_color = null;
      this.link_color = d3.scale.ordinal()
        .domain([-1, 0, 1, 2]) // elite, mutation, crossover, reproduce
        // .range( colorbrewer.Set1[4] );
        .range(['red', 'blue', 'green', 'yellow']);
      // initialize the flowchart, create the graph
      this.flowchart.nodes(this.data.nodes)
        .links(this.data.links)
        .init();

      const color_range = colorbrewer.RdYlGn[6];
      const color_range_fitness = color_range.slice(0); // clone array (deep copy)
      // color_range_fitness.reverse();
      // color_range = d3.scale.category20c()
      //     .domain( range1(20) )
      //     .range();
      this.fitness_color = d3.scale.quantile()
        .domain(this.data.nodes.map(function(n) { return n.fitness; }))
        .range(color_range_fitness);
/*      this.size_color = d3.scale.quantile()
        .domain(this.data.nodes.map(function(n) { return n.scaled_size; }))
        .range(color_range);
      this.height_color = d3.scale.quantile()
        .domain(this.data.nodes.map(function(n) { return n.scaled_height; }))
        .range(color_range);*/

      this.update_visualization();
    }

    update_visualization() {
      const clearSymbolTraversal = () => {
        this.svg.selectAll('.symbol').transition().duration(0);
        this.svg.selectAll('.symbol').remove();
        this.showNodesFormula = [];
      };
      const pathTween = (data, gen, key, totalGenCount) => {
        const path = d3.select(data[gen].paths[key]);
        const length = path.node().getTotalLength();
        const r = d3.interpolate(0, length);
        return (t) => {
          const point = path.node().getPointAtLength(r(t));
          d3.select('#symbol-' + gen + '-' + key) // Select Symbol
            .attr('transform', 'translate(' + point.x + ',' + point.y + ')');
          if ( t === 1 ) {
            if (((gen + 1) < totalGenCount)) {
              if (!data[gen].isTraversed) {
                this.svg.selectAll('.symbol').remove();
                data[gen].isTraversed = true;
                // tslint:disable-next-line: no-use-before-declare
                startSymbolTraverse(data, ++gen, totalGenCount);
              }
            } else {
              clearSymbolTraversal();
              this.showNodesFormula = data[gen].finalFormula;
              this.showNodesFormulaTimer = setTimeout(() => {
                this.showNodesFormula = [];
              }, 3000);
            }
          }
        };
      };
      const startSymbolTraverse = (data, gen, totalGenCount) => {
        const generationShapes = ['circle', 'star', 'square', 'hexagoan'];
        this.showNodesFormula = data[gen].formula;
        this.svg.selectAll('.symbol')
          .data(data[gen].paths)
          .enter()
          .append('path')
          .attr({
            'class': 'symbol',
            'id': (d, i) => 'symbol-' + gen + '-' + i,
            'd': this._d3CustomShapesService.getShapeCoordinates(generationShapes[gen], 150)
          })
          .style('fill', '#000')
          .transition()
          .duration(1500)
          .ease('linear')
          .tween('pathTween', (d, i) => {
            return pathTween(data, gen, i, totalGenCount);
          });
      };
      // remove all existing elements
      this.svg.selectAll('g').remove();
      // calculate the node positions
      this.flowchart.layout();
      // create nodes, hence a group for each node
      const node = this.svg.append('g').selectAll('.node')
        .data(this.flowchart.nodes())
        .enter()
        .append('g')
        .attr('class', function(d) {
          let str = 'node';
          d.ancestor_of.forEach((v) => (str += ' ancestor-of-' + v));
          return str + ' node-generation-of-' + d.generation;
        })
        .attr('transform', (v) => ('translate(' + v.x + ',' + v.y + ')'));
      // append a rect to each node
      const rect = node.append('rect')
        .attr({
          'height': d => d.dy,
          'width': this.flowchart.node_width()
        })
        .style('fill', this.get_node_color())
        .on('mousedown', (d) => {
          // console.log('MouseDown ----------- ', d);
          clearSymbolTraversal();
          clearTimeout(this.showNodesFormulaTimer);

          this.svg.selectAll('path.link')
          .classed('static-link-selected', false);
          this.svg.selectAll('g.node rect')
          .classed('static-node-selected', false);

          const selectedAncestorPaths = this.svg.selectAll('path.link.ancestor-of-' + d.key);
          const selectedAncestorNodes = this.svg.selectAll('g.node.ancestor-of-' + d.key + ' rect');
          selectedAncestorPaths.classed('static-link-selected', true);
          selectedAncestorNodes.classed('static-node-selected', true);
          let data = [];
          selectedAncestorPaths[0].forEach(v => {
            const gen = +(d3.select(v).attr('generation'));
            if (!data[gen]) {
              data[gen] = {formula: [], paths : [], isTraversed: false, finalFormula: [d.formula]};
            }
            data[gen].paths.push(v);
          });
          selectedAncestorNodes.each(v => {
            data[v.generation].formula.push(v.formula);
          });
          // console.log(data);
          data = data.filter(v => v);
          if (data.length) {
            startSymbolTraverse(data, 0, data.length);
          }
        })
        .append('title')
        .text((d) => (this.format(d)));
      // create links
      const link = this.svg.append('g').selectAll('.link')
        .data(this.flowchart.links())
        .enter().append('path')
        .attr('class', function(d) {
          let str = '';
          if (d.op === -1) { str = 'link link-strong'; } else { str = 'link link-normal'; }
          d.ancestor_of.forEach(v => { str += ' ancestor-of-' + v; });
          return str + ' path-generation-of-' + d.source.generation;
        })
        .attr('generation', (d) => d.source.generation)
        .attr('d', this.path)
        .style('stroke-width', function(d) { return Math.max(1, d.dy); })
        .style('stroke', (d) => this.link_color(d.op));
    }

    get_node_color() {
      // const color_type = 'fitness';

      // if (color_type === 'fitness') {
        return (d) => this.fitness_color(d.fitness);
      // }
      // if (color_type === 'size') {
      //   return (d) => this.size_color(d.scaled_size)
      // }
      // if (color_type === 'height') {
      //   return (d) => this.height_color(d.scaled_height)
      // }
    }

    ngOnDestroy(): void {
      if (this.unsubscribeResize) {
        this.unsubscribeResize();
      }
    }
}
