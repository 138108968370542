import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drag-drop-tabs',
  templateUrl: './drag-drop-tabs.component.html',
  styleUrls: ['./drag-drop-tabs.component.css']
})
export class DragDropTabsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
