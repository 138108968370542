import { Component, OnInit, Input } from '@angular/core';
import { WidgetService } from '../widgets.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html'
})
export class AreaChartComponent implements OnInit {

  @Input() widgetData;

  data;

  options = {
    // width: 2000,
    height: 300,
    hAxis: { title: 'Year', titleTextStyle: { color: '#333' } },
    vAxis: { minValue: 0 }
  };
  filterData: any = {
    year: [],
    type: []
  };
  areaChartData;

  constructor(private widgetService: WidgetService) {
    this.widgetService.triggerDataChangeForAreaChart.subscribe((updatedDataInfo) => {
      // alert("Inside area bar chart");
      if (updatedDataInfo.inputDetails.filterOn === 'type') {
        this.filterData.type = updatedDataInfo.formField.filterField;
      } else if (updatedDataInfo.inputDetails.filterOn === 'year') {
        this.filterData.year = updatedDataInfo.formField.filterField;
      }
      if (this.filterData.year.length || this.filterData.type.length) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }
    });
  }

  updateChart(updatedDataInfo) {
    const updatedDataSet = [];
    // if (updatedDataInfo.formField.uniqueName101 && updatedDataInfo.formField.uniqueName101.length) {
    if (this.filterData.type.length) {
      const numberOfRows = this.data.length;
      // const selectedColumnIndexs = this.findIndexesOfElements(this.filterData.type, this.data[0]);
      const selectedColumnIndexs = this.filterData.type.reduce((ini, v, i) => {
        if (this.data[0].indexOf(v) > -1) {
          if (ini.indexOf(this.data[0].indexOf(v)) === -1) {
            ini.push(this.data[0].indexOf(v));
          }
        }
        return ini;
      }, []);
      for (let row = 0; row < numberOfRows; row++) {
        const newRow = [];
        newRow.push(this.data[row][0]); // This is for adding year column value by default
        for (let col = 0; col < selectedColumnIndexs.length; col++) {
          newRow.push(this.data[row][selectedColumnIndexs[col]]); // This is for adding selected columns value
        }
        updatedDataSet.push(newRow);
      }
      this.areaChartData = Object.create(this.areaChartData);
      this.areaChartData.dataTable = updatedDataSet;
    }

    // if (updatedDataInfo.formField.uniqueName102 && updatedDataInfo.formField.uniqueName102.length) {
    if (this.filterData.year.length) {
      const getFromDataSet = updatedDataSet.length ? updatedDataSet : this.data;
      const updatedDataSetByYear = [];
      updatedDataSetByYear.push(getFromDataSet[0]);
      for (let row = 1; row < getFromDataSet.length; row++) {
        if (this.filterData.year.indexOf(getFromDataSet[row][0]) > -1) {
          updatedDataSetByYear.push(getFromDataSet[row]);
        }
      }
      this.areaChartData = Object.create(this.areaChartData);
      this.areaChartData.dataTable = updatedDataSetByYear;
    }
  }

  findIndexesOfElements(indexOfElements, fromArray) {
    const foundIndexes = [];
    for (let index = 0; index < fromArray.length; index++) {
      if (indexOfElements.indexOf(fromArray[index]) > -1) {
        foundIndexes.push(index);
      }
    }
    return foundIndexes;
  }

  resetToOrignal() {
    this.areaChartData = Object.create(this.areaChartData);
    this.areaChartData.dataTable = this.data;
  }

  ngOnInit() {
    this.data = this.widgetData.data;
    this.areaChartData = {
      chartType: 'AreaChart',
      dataTable: this.data,
      options: this.options,
    };
  }
}
