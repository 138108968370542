import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CookieService } from './cookie-wrapper.service';
import { DataShareService } from './data-share.service';
import { LoginService } from '../core/login/service/login.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _dataShareService: DataShareService,
    private _cookieService: CookieService,
    private _loginService: LoginService,
    private _gaService: GoogleAnalyticsService,
  ) { }

  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> {
    // return true;
    const clientDetails = this._authService.getUser();
    if (this._cookieService.verifyCloudFrontCookie()) {
      if ((clientDetails && clientDetails.isAdmin) && !this._authService.getUserFromLocalStorage()) {
        this._loginService.logout(clientDetails.id).subscribe((d) => { });
        this._gaService.triggerEvent('Logout', 'Logout', 'Session Expired');
        const cookieExpiredMessage = 'Your session has been expired. Please login again';
        this._dataShareService.onCustomEvent.next({ event: 'cookiesExpired', data: cookieExpiredMessage });
        this._authService.removeUser();
        this._cookieService.clearCloudfrontCookies();
        return this._router.parseUrl('/login');
      } else {
        if (clientDetails) {
          return true;
        }
        return this._router.parseUrl('/login');
      }
    } else {
      if (clientDetails && clientDetails.id) {
        this._gaService.triggerEvent('Logout', 'Logout', 'Session Expired');
        const cookieExpiredMessage = 'Your session has been expired. Please login again';
        this._dataShareService.onCustomEvent.next({ event: 'cookiesExpired', data: cookieExpiredMessage });
        this._loginService.logout(clientDetails.id).subscribe((d) => { });
      }
      this._authService.removeUser();
      this._cookieService.clearCloudfrontCookies();
      // if (clientDetails && clientDetails.isAdmin) {
      // 	return this._router.parseUrl('/login');
      // } else {
      return this._router.parseUrl('/login');
      // }
    }

    // if (this._authService.getUser()) {
    // 	return true;
    // }
    // return this._router.parseUrl('/login');
  }

  canActivateChild() {
    return this.canActivate();
  }
}
