import { Component, OnInit, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { WidgetService } from '../widgets.service';
declare let d3: any;

@Component({
  selector: 'app-d3-neural-network',
  templateUrl: './d3-neural-network.component.html',
  styleUrls: ['./d3-neural-network.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class D3NeuralNetworkComponent implements OnInit {
  
  @Input() widgetData;
  @Input() widgetsCount;
  @ViewChild('d3NuralTreeRef') chartElement: ElementRef;
  data;

  constructor(private widgetService: WidgetService) { }

  ngOnInit() {
    this.data=this.widgetData.data;
    this.loadNeuralTree();
  }


  loadNeuralTree()
  {

    var outerWidth = this.widgetsCount > 1 ? 450 : 950;
      var outerHeight = this.widgetsCount > 1 ? 300 : 500;
    var width = 470,
    height = 300,
    nodeSize = 22;

    var color = d3.scale.category20();

    var svg = d3.select(this.chartElement.nativeElement).append("svg")
        .attr("width", outerWidth)
        .attr("height", outerHeight);

    //d3.json("data.json", function(error, graph) {
        var nodes = this.data;
        // get network size
        var netsize = {};
        nodes.forEach(function (d) {
          if(d.layer in netsize) {
              netsize[d.layer] += 1;
          } else {
              netsize[d.layer] = 1;
          }
          d["lidx"] = netsize[d.layer];
          d.uid = d.layer + "" + netsize[d.layer]; 
        });

        // calc distances between nodes
        var largestLayerSize = Math.max.apply(
            null, Object.keys(netsize).map(function (i) { return netsize[i]; }));

        var xdist = width / Object.keys(netsize).length,
            ydist = height / largestLayerSize;

        // create node locations
        nodes.map(function(d) {
          d["x"] = (d.layer - 0.5) * xdist;
          d["y"] = (d.lidx - 0.5) * ydist;
        });

        // autogenerate links
        var links = [];
        nodes.map(function(d, i) {
          for (var n in nodes) {
            if (d.layer + 1 == nodes[n].layer) {
              
              links.push({"source": parseInt(i), "target": parseInt(n), "value": 1, "nodes": [nodes[n].uid, d.uid]}) 
            }
          }
        }).filter(function(d) { return typeof d !== "undefined"; });

        // draw links
        var link = svg.selectAll(".link")
            .data(links)
          .enter().append("line")
            .attr("class", "neural-link")
            .attr("x1", function(d) { 
              return nodes[d.source].x; })
            .attr("y1", function(d) { return nodes[d.source].y; })
            .attr("x2", function(d) { return nodes[d.target].x; })
            .attr("y2", function(d) { return nodes[d.target].y; })
            .style("stroke-width", function(d) { return 2; });

        // draw nodes
        var node = svg.selectAll(".node")
            .data(nodes)
          .enter().append("g")
            .attr("transform", function(d) {
                return "translate(" + d.x + "," + d.y + ")"; }
            )
            .on('mouseover', function(d) {
              highlight_link(d, '#e45758');
            })
            .on('mouseout', function(d) {
              highlight_link(d, '#999');
            });

        var circle = node.append("circle")
            .attr("class", "neural-node")
            .attr("r", nodeSize)
            .style("fill", function(d) {return color(d.layer); });


        node.append("text")
            .attr("dx", "-.35em")
            .attr("dy", ".35em")
            .attr("class","node-text-font")
            .text(function(d) { return d.label; });

          function highlight_link(node, color){
              let defaultColor = "#999";
              d3.selectAll(".neural-link")
                .style("stroke", function(paths) {
                  if(paths.nodes.indexOf(node.uid) > -1) {
                    return color;
                  }

                  return defaultColor;
                })

          }    

  }

}
