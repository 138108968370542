import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { D3CustomShapesService } from 'src/app/services/helper/d3-custom-shapes.service';
import { D3EventTimelineService } from 'src/app/services/d3-event-timeline.service';
declare const d3: any;

@Component({

	encapsulation: ViewEncapsulation.None,
	selector: 'app-d3-event-timeline',
	templateUrl: './d3-event-timeline.component.html',
	styleUrls: ['./d3-event-timeline.component.css']
})
export class D3EventTimelineComponent implements OnInit {
	@Input() timelineDetails: any = {
		svg: [],
		index: 0,
		width: 900,
		height: 150,
		data: [],
		translate: { x: 0, y: 0 },
		categoriesColor: ["#FFF"],
		tooltipDivId: "#abc"
	};
	shapePosition: Array<any> = [];
	svg: any = null;
	years = ['1/1/2006', '1/1/2007', '1/1/2008', '1/1/2009', '1/1/2010', '1/1/2011', '1/1/2012', '1/1/2013', '1/1/2014', '1/1/2015', '1/1/2016', '1/1/2017'];
	parseDate = d3.time.format("%m/%d/%Y").parse;
	xScale: Function;
	yScale: Function;
	constructor(
		private shapesService: D3CustomShapesService,
		private timelineService: D3EventTimelineService
	) { }

	createTimeline() {

		function roundedRectangle(x, y, w, h, r) {
			var retval;
			retval = "M" + (x + r) + "," + y;
			retval += "h" + (w - 2 * r);
			retval += "a" + r + "," + r + " 0 0 1 " + r + "," + r
			retval += "v" + (h - 2 * r);
			retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + r;
			retval += "h" + (2 * r - w);
			retval += "a" + r + "," + r + " 0 0 1 " + -r + "," + -r;
			retval += "v" + (2 * r - h);
			retval += "a" + r + "," + r + " 0 0 1 " + r + "," + -r;
			retval += "z";
			return retval;
		}

		const { width, height, data, index, translate, categoriesColor } = this.timelineDetails;

		// Append SVG 
		let svg = this.timelineDetails.svg
			.append("g")
			.attr('id', "timeline_block_" + index)
			.attr({
				"width": width,
				"height": height,
				"transform": "translate(" + translate.x + "," + translate.y + ")"
			});

		this.svg = svg;

		var timelineLineGenerator = d3.svg.line()
			.x((d, i) => {
				return d.xCoordinate;
			})
			.y((d) => {
				return d.yFactorAdj ? (this.yScale(d.yCoordinate) + d.yFactorAdj) : this.yScale(d.yCoordinate);
			});

		var yFactorAdj = -30;
		var dataPoints = {
			xCoordinate: 20,
			yCoordinate: (this.yScale(index + 0.5) + yFactorAdj),
			width: (width - 40),
			height: 60,
			roundedRadius: 30
		};

		//Create main Rounded Timeline Path
		svg
			.append('path')
			.attr({
				"id": "date_timeline_" + index,
				'd': roundedRectangle(dataPoints.xCoordinate, dataPoints.yCoordinate, dataPoints.width, dataPoints.height, dataPoints.roundedRadius)
			})
			.style({
				"fill": "none",
				"stroke": "none"
			});

		let centerLinePoints = [{
			xCoordinate: 50,
			yCoordinate: index + 0.5
		}, {
			xCoordinate: width - 50,
			yCoordinate: index + 0.5
		},];

		//Create Center Path for shapes
		svg
			.append('path')
			.attr({
				"id": "date_timeline_center_path_" + index,
				'd': timelineLineGenerator(centerLinePoints)
			})
			.style({
				"stroke": "none",
				"fill": "none"
			});

		//Create Shapes and place it in Starting Position
		svg.selectAll(".circle")
			.data(data.userActivities)
			.enter()
			.append("path")
			.attr({
				"class": "date_timeline_shapes_" + index,
				"id": (d, subIndex) => {
					return "date_timeline_shapes_" + index + "_" + subIndex;
				},
				"d": (d, i) => {
					this.shapePosition[i] = this.xScale(this.parseDate(d.date));
					return this.shapesService.getShapeCoordinates([d.shapeType], d.shapeSize)
				},
				"transform": "translate(" + centerLinePoints[0].xCoordinate + ", " + this.yScale(centerLinePoints[0].yCoordinate) + ")"
			})
			.style({
				"fill": (d) => {
					return categoriesColor[d.colorId]
				},
				"stroke": "none",
				'fill-opacity': 0
			})

		this.timelineService.setShapePosition(this.shapePosition);

		//Create Text and place it in Starting Position
		svg.selectAll(".date_timeline_shapes_" + index)
			.data(data.userActivities)
			.each((data, key) => {
				if (data.shapeText) {
					svg.append("text")
						.text(data.shapeText)
						.attr({
							"x": centerLinePoints[0].xCoordinate,
							"y": this.yScale(centerLinePoints[0].yCoordinate),
							"fill": "transparent",
							"class": "timeline-node-text",
							"id": "timeline-node-text-" + index + "-" + key
						});
				}

				if (data.showTooltip) {
					let shape = this.svg.select("#date_timeline_shapes_" + index + "_" + key);
					this.showTimelineActivityTooltip(shape.node().getBoundingClientRect(), index, data, key);
				}
			})
	}

	showTimelineActivityTooltip(points, index, data, key) {
		var div = d3.select("#" + this.timelineDetails.tooltipDivId).append('div')
			.attr('class', 'timeline-tooltip')
			.attr("id", "timeline-tooltip-" + index + "-" + key);

		div
			.html("<span>" + data.showTooltip + "</span>")
			.style({
				'left': points.left - (data.showTooltip.length * 4) - 15 + 'px',
				'top': (this.timelineDetails.height - 50) + 'px',
				'position': 'absolute',
				"visibility": "hidden"
			});
	}

	ngOnInit() {
		//Define Scales
		this.xScale = d3.time.scale().domain(d3.extent(this.years, (d) => {
			return this.parseDate(d);
		})).range([50, this.timelineDetails.width - 50]);
		this.yScale = d3.scale.linear().domain([0, 0]).range([50, this.timelineDetails.height - 10]);

		//Initialize Values for timeline and trigger to create
		this.createTimeline();
	}

}
