import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-dialog-box-d3-tree',
  templateUrl: './dialog-box-d3-tree.component.html'
})
export class DialogBoxD3TreeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogBoxD3TreeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    gridData
    dataSectionGridData;

    ngOnInit() {
      // console.log(this.data);
      this.gridData = this.data.treedata;
      this.dataSectionGridData = {data: this.gridData}
  	}
	onNoClick(): void {
	   	this.dialogRef.close(this.data);
  }
  
  save()
  {
    this.dialogRef.close("It is saved");
  }
  
}
