import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PromoVideoComponent } from './header/promo-video-dialog-box/promo-video-dialog-box.component';
import { MaterialModule } from '../material/material.module';
import { StoryNavigationComponent } from './header/story-navigation/story-navigation.component';
import { LoginService } from './login/service/login.service';
import { LoaderComponent } from '../common/components/loader/loader.component';
import { GalleryService } from '../gallery/gallery.service';
import { RouterModule } from '@angular/router';
import { WidgetLoaderComponent } from '../common/components/widget-loader/widget-loader.component';
import { WidgetsModule } from '../widgets/widgets.module';
@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    // HomeComponent,
    FooterComponent,
    ContactComponent,
    ErrorPageComponent,
    LoginComponent,
    PromoVideoComponent,
    StoryNavigationComponent,
    WidgetLoaderComponent
  ],
  imports: [
    SharedModule,
    // AppRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    WidgetsModule
  ],
  exports: [
    RouterModule,
    // AppRoutingModule,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    PromoVideoComponent,
    ContactComponent,
    WidgetLoaderComponent
  ],
  providers: [
    // ShoppingListService
    // RecipeService,
    // DataStorageService,
    // AuthService,
    // {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    // {provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true}
    LoginService
  ],
  entryComponents: [
    PromoVideoComponent
  ]
})
export class CoreModule { }
