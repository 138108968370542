import {
  Directive, AfterViewInit, Input, ElementRef, Renderer2, Inject,
  OnDestroy, HostListener, Output, EventEmitter, OnInit
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appFullpagescroll]',
  exportAs: 'fullPageScroll'
})
export class FullpagescrollDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() public wheelContainerId = '';
  @Input() public allowDelay = false;
  @Input() public time = 1000;
  @Input() public delayTime = 1000;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onSlideStart = new EventEmitter<any>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onSlideComplete = new EventEmitter<any>();

  private _scrollSection;
  private _scrollingSectionList = [];
  private _lastScrolledSection = 0;
  private unsubscribeMouseWheel = null;
  private unsubscribeTouchStart = null;
  private unsubscribeTouchMove = null;
  private isAnnimating = false;
  private onMouseWheel = null;
  private _previousTime = 0;

  constructor(
    private _el: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener('window:keyup', ['$event'])
  onKeyup(e) {
    e.preventDefault();
    if (this.isAnnimating) {
      return;
    }

    if (e.which === 38) {
      // Going up
      const validity = this.checkNavigationValidity('up');
      if (validity.valid) {
        this.navigateTo(validity.value);
      }
    } else if (e.which === 40) {
      // Going Down
      const validity = this.checkNavigationValidity('down');
      if (validity.valid) {
        this.navigateTo(validity.value);
      }
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this._scrollSection.scrollTo({ top: this._scrollingSectionList[this._lastScrolledSection].e.offsetTop });
  }

  private throttle(fn, wait) {
    let time = Date.now();
    return ($e) => {
      if ((time + wait - Date.now()) < 0) {
        fn($e);
        time = Date.now();
      }
    };
  }

  public navigateTo(elementId) {
    this.isAnnimating = true;
    this.document.body.classList.remove(this._scrollingSectionList[this._lastScrolledSection].id);
    const commingChild = this._scrollingSectionList.find((v, i) => {
      if (v.id === elementId) {
        this._lastScrolledSection = i;
        return true;
      }
    });
    if (commingChild) {
      // this.isAnnimating = true;
      this.onSlideStart.next(commingChild.id);
      // console.log('start', (new Date()), this.time + 1000);
      // setTimeout(() => {
      //   this.isAnnimating = false;
      //   console.log('end', (new Date()));
      // }, this.time + 10);

      // let allowDelay = false;
      // if (this.delayItems.length > 0) {
      //   this.delayItems.forEach(element => {
      //     // console.log(element.findIndex((item) => item === elementId));
      //     // console.log(element.findIndex((item) => item === commingChild.id));
      //     if (allowDelay === false && element.length === 2 && element[0] !== element[1] &&
      //       (element.findIndex((item) => item === elementId) !== -1 && element.findIndex((item) => item === commingChild.id) !== -1)) {
      //       allowDelay = true;
      //       return;
      //     }
      //   });
      // }

      if (this.allowDelay) {
        setTimeout(() => {
          this.scrollIt(
            this._scrollSection,
            commingChild.e.offsetTop,
            this.time,
            'easeOutQuint',
            () => {
              // console.log('Animation End in callback')
              // this.document.addEventListener('mousewheel', this.onMouseWheel);
              // //setTimeout(() => {
              this.isAnnimating = false;
              // //}, 1000);
              this._previousTime = new Date().getTime();
              this.onSlideComplete.next(commingChild.id);
              this.document.body.classList.add(this._scrollingSectionList[this._lastScrolledSection].id);
            }
          );
        }, this.delayTime);
      } else {
        this.scrollIt(
          this._scrollSection,
          commingChild.e.offsetTop,
          this.time,
          'easeOutQuint',
          () => {
            // console.log('Animation End in callback')
            // this.document.addEventListener('mousewheel', this.onMouseWheel);
            // //setTimeout(() => {
            this.isAnnimating = false;
            // //}, 1000);
            this._previousTime = new Date().getTime();
            this.onSlideComplete.next(commingChild.id);
            this.document.body.classList.add(this._scrollingSectionList[this._lastScrolledSection].id);
          }
        );
      }
    } else {
      this.isAnnimating = false;
    }
  }

  public setEventState(state: boolean) {
    this.isAnnimating = !state;
    // console.log("ANIMATING VALUE: ", this.isAnnimating);
  }

  ngOnInit() {
    this.document.body.classList.add('parallax');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.document.body.classList.add(this._scrollingSectionList[this._lastScrolledSection].id);
    }, 100);
    this._scrollSection = document.getElementById(this.wheelContainerId);
    // this._page = document.getElementById(this.pageId);
    const children = this._el.nativeElement.children;
    this._scrollingSectionList = [];
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < children.length; i++) {
      const e = children[i];
      this._scrollingSectionList.push({
        id: e.id,
        e
      });
    }

    this.onMouseWheel = ($e) => {
      // $e.preventDefault();
      const curTime = new Date().getTime();
      // console.log($e, $e.deltaY, this.isAnnimating, curTime);
      if (this.isAnnimating) {
        return;
      }
      // if ((curTime - this._previousTime) < 2500) {
      //   console.log('returned');
      //   return;
      // } else {
      //   console.log(curTime - this._previousTime);
      // }
      // this.document.removeEventListener('mousewheel', this.onMouseWheel);
      if ($e.deltaY > 0) {
        // Going Down
        const validity = this.checkNavigationValidity('down');
        if (validity.valid) {
          this.navigateTo(validity.value);
        } else {
          // this.document.addEventListener('mousewheel', this.onMouseWheel);
        }
      } else {
        // Going up
        const validity = this.checkNavigationValidity('up');
        if (validity.valid) {
          this.navigateTo(validity.value);
        } else {
          // this.document.addEventListener('mousewheel', this.onMouseWheel);
        }
      }
    };

    this.unsubscribeMouseWheel = this.renderer.listen(this._scrollSection, 'wheel', this.throttle(this.onMouseWheel, 1800));

    let yDown = null;
    this.unsubscribeTouchStart = this.renderer.listen(this._scrollSection, 'touchstart', this.throttle((e) => {
      if (this.isAnnimating) {
        return;
      }
      const firstTouch = e.touches[0];
      yDown = firstTouch.clientY;
    }, 1800));

    this.unsubscribeTouchMove = this.renderer.listen(this._scrollSection, 'touchmove', this.throttle((e) => {
      if (!yDown) {
        return;
      }
      const yUp = e.touches[0].clientY;
      const yDiff = yDown - yUp;

      let direction = '';
      if (yDiff > 0) {
        /* up swipe means go down */
        direction = 'down';
      } else {
        /* down swipe meand go up*/
        direction = 'up';
      }
      const validity = this.checkNavigationValidity(direction);
      if (validity.valid) {
        this.navigateTo(validity.value);
      }
      yDown = null;
    }, 1800));
    // this.renderer.listen(this._scrollSection, 'touchend', this.throttle((e) => {
    //   console.log('End', e);
    // }, 500));
    // this.document.addEventListener('mousewheel', this.throttle(this.onMouseWheel, 500));

    // this.unsubscribeMouseWheel = this.renderer.listen(this.document, 'mousewheel', ($e) => {
    //   // $e.preventDefault();
    //   if (this.isAnnimating) {
    //     return;
    //   }
    //   console.log($e, $e.deltaY, this.isAnnimating);

    //   if ($e.deltaY > 0) {
    //     // Going Down
    //     const validity = this.checkNavigationValidity('down');
    //     if (validity.valid) {
    //     // (this._lastScrolledSection !== this._scrollingSectionList.length - 1) {
    //       // this.navigateTo(this._scrollingSectionList[this._lastScrolledSection + 1].id);
    //       this.navigateTo(validity.value);
    //     }
    //   } else {
    //     const validity = this.checkNavigationValidity('up');
    //     if (validity.valid) {
    //     // (this._lastScrolledSection !== 0) {
    //       // Going up
    //       this.navigateTo(validity.value);
    //     }
    //   }
    // });
  }

  checkNavigationValidity(direction: string): { valid: boolean, value: string } {
    let _result = false;
    if (direction === 'down') {
      _result = (this._lastScrolledSection !== this._scrollingSectionList.length - 1);
    } else if (direction === 'up') {
      _result = (this._lastScrolledSection !== 0);
    }
    return {
      valid: _result,
      value: _result && (this._scrollingSectionList[this._lastScrolledSection + ((direction === 'down') ? 1 : -1)].id)
    };
  }

  ngOnDestroy(): void {
    this.document.body.classList.remove('parallax');
    this.document.body.classList.remove(this._scrollingSectionList[this._lastScrolledSection].id);
    if (this.unsubscribeMouseWheel) {
      this.unsubscribeMouseWheel();
    }
    if (this.unsubscribeTouchStart) {
      this.unsubscribeTouchStart();
    }
    if (this.unsubscribeTouchMove) {
      this.unsubscribeTouchMove();
    }

    // if (this.onMouseWheel) {
    //   this.document.removeEventListener('wheel', this.onMouseWheel);
    // }
  }

  private scrollIt(scrollContainer, destination, duration = 200, easing = 'linear', callback) {

    // Predefine list of available timing functions
    // If you need more, tween js is full of great examples
    // https://github.com/tweenjs/tween.js/blob/master/src/Tween.js#L421-L737
    const easings = {
      linear(t) {
        return t;
      },
      easeInQuad(t) {
        return t * t;
      },
      easeOutQuad(t) {
        return t * (2 - t);
      },
      easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      },
      easeInCubic(t) {
        return t * t * t;
      },
      easeOutCubic(t) {
        return (--t) * t * t + 1;
      },
      easeInOutCubic(t) {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      },
      easeInQuart(t) {
        return t * t * t * t;
      },
      easeOutQuart(t) {
        return 1 - (--t) * t * t * t;
      },
      easeInOutQuart(t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
      },
      easeInQuint(t) {
        return t * t * t * t * t;
      },
      easeOutQuint(t) {
        return 1 + (--t) * t * t * t * t;
      },
      easeInOutQuint(t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
      }
    };


    // Store initial position of a window and time
    // If performance is not available in your browser
    // It will fallback to new Date().getTime() - thanks IE < 10
    const start = scrollContainer.scrollTop; /* window.pageYOffset; */
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
    // const startTime = typeof(window.performance['now']) == 'function' ? performance.now() : new Date().getTime();


    // Take height of window and document to sesolve max scrollable value
    // Prevent requestAnimationFrame() from scrolling below maximum scollable value
    // Resolve destination type (node or number)
    // const documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    // const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
    // const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    // const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

    const containerScrollHeight = Math.max(scrollContainer.scrollHeight, scrollContainer.offsetHeight, scrollContainer.clientHeight);
    const containerHeight = scrollContainer.innerHeight || scrollContainer.clientHeight || scrollContainer.offsetHeight;
    const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
    const destinationOffsetToScroll = Math.round(containerScrollHeight - destinationOffset < containerHeight ? containerScrollHeight - containerHeight : destinationOffset);

    // If requestAnimationFrame is not supported
    // Move window to destination position and trigger callback function
    if ('requestAnimationFrame' in window === false) {
      // window.scroll(0, destinationOffsetToScroll);
      scrollContainer.scroll(0, destinationOffsetToScroll);
      if (callback) {
        callback();
      }
      return;
    }


    // function resolves position of a window and moves to exact amount of pixels
    // Resolved by calculating delta and timing function choosen by user
    function scroll() {
      const now = 'now' in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, ((now - startTime) / duration));
      const timeFunction = easings[easing](time);
      // window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
      scrollContainer.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
      // Stop requesting animation when window reached its destination
      // And run a callback function
      // SPM Updated Here for smooth scroll
      // (window.pageYOffset === destinationOffsetToScroll);
      if (Math.ceil(scrollContainer.scrollTop/* window.pageYOffset */) === destinationOffsetToScroll) {
        if (callback) {
          callback();
        }
        return;
      }

      // If window still needs to scroll to reach destination
      // Request another scroll invokation
      requestAnimationFrame(scroll);
    }


    // Invoke scroll and sequential requestAnimationFrame
    scroll();
  }
}
