import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoryService } from './story.service';
import { DataShareService } from '../../shared/data-share.service';
import { GalleryService } from '../gallery.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-story-page',
  templateUrl: './story-page.component.html',
  providers: [StoryService]
})
export class StoryPageComponent implements OnInit, OnDestroy {
  
  test:string;
  
	public isClipBoardCopied = false;
	public clipBoardTimeout = null;
	public clipBoardText = '';
  storyData: any;
  selectedSection: any;
  parentSectionDetails: any;
  isDataLoading: boolean;
  private dataServiceObserver: any;
  classVal: any;
  @ViewChild('scrollContainer') scrollContainer;

  constructor(private _route: ActivatedRoute,
    private _storyService: StoryService,
    private dataShareService: DataShareService,
    private galleryService: GalleryService,
    private router : Router) { }

  ngOnInit() {
    if (typeof (Storage) !== 'undefined' && localStorage.getItem('u')) {
      localStorage.removeItem('u');
    }

    let galleryData = this.galleryService.getStoryListById({ url: this._route.snapshot.params.url });
    console.log(galleryData);
    
    this.isDataLoading = true;
    if (!galleryData) {

      this.galleryService.storiesChanged
        .subscribe(
          () => {
            galleryData = this.galleryService.getStoryListById({ url: this._route.snapshot.params.url });
            console.log(galleryData);
            
            this.getStoryDetails(galleryData);
          });
    } else {
      this.getStoryDetails(galleryData);
    }

    this._storyService.loadSection.subscribe((actionData: any) => {
      // console.log(actionData);
      // alert("Load section --> subscribe");
      const foundIndex = _.findIndex(this.storyData.sections, { name: actionData.loadSectionData });
      if (foundIndex > -1) {
        // this.parentSectionDetails = {
        //   name: this.selectedSection.name
        // };
        // this.selectedSection = this.storyData.sections[foundIndex];
        // console.log(this.storyData.sections[foundIndex]);
        this.storyData.sections.forEach(v => {
          if (v.name === this.selectedSection) {
            v.hideSectionLink = true;
            this.storyData.sections[foundIndex].name = v.name;
            this.storyData.sections[foundIndex].hideSectionLink = false;
            // Code to scroll to that widget
            const sec: any = document.querySelector('#' + this.storyData.sections[foundIndex].name.split(' ').join(''));
            const scrollContainer = document.getElementById('scroll-container');
            scrollContainer.scrollTo(0, sec.offsetTop - scrollContainer.offsetTop);
          }
        });
      } else {
        // alert("Section not found");
      }
    });

    this.dataShareService.onNavigationSelect.subscribe((data) => {
      const scrollContainer = document.getElementById('scroll-container');
      const sec = document.querySelector(data);
      scrollContainer.scrollTo({ top: (sec.offsetTop - scrollContainer.offsetTop + 1), left: 0, behavior: 'smooth' });
      // scrollContainer.scrollTo(0, (sec.offsetTop - scrollContainer.offsetTop + 1));
    });
  }

  getStoryDetails(galleryData) {
    console.log(galleryData);
    
    // TODO: PB to add class to body story-wise
    this.classVal = this.dataShareService.getBodyStoryClass(galleryData.url, 'sp');
    this.dataShareService.updateCustomEvent({ event: 'addClassToBody', data: this.classVal });
    console.log(this.classVal,this.dataShareService);
    
    // END
    // this.loderService.show();
    switch (galleryData.url) {
      case 'aiqna':
        this.dataShareService.onCustomEvent.next({ event: 'addClassToElement', data: {selector: '#header', class: 'humburger-header'}});
        break;
    }
    this.dataServiceObserver = this._storyService.getStoryDataNew(galleryData.endpoint)
      .subscribe((resp: any) => {
        // this.loderService.hide();
        this.isDataLoading = false;
        this.storyData = resp.data;
        // this.selectedSection = this.storyData && this.storyData.sections && this.storyData.sections[0];
        this.selectedSection = this.storyData && this.storyData.sections && this.storyData.sections[0].name;
        this.dataShareService.dataChange(galleryData);
        this.dataShareService.changeNavigation(resp.data);
        if(this.storyData.title==='Personalized AI'){
          // console.log(this.storyData.title);
          setTimeout(() => {
            let copybtn=document.getElementById("pi-copy-btn");
            copybtn.addEventListener("click",()=>{
                // console.log("copy btn clicked");
                // console.log(this.storyData);
                const elem = document.createElement('textarea');
                elem.value = this.storyData.sections[0].data.visulizations[0].widget[0].credentials[0].value;
                document.body.appendChild(elem);
                elem.select();
                document.execCommand('copy');
                document.body.removeChild(elem);
                document.getElementById('pi-copy-txt').innerHTML='Passcode Copied!'
                setTimeout(()=>{
                  document.getElementById('pi-copy-txt').innerHTML=''
                },1000)
              })
            }, 100);
        }
        else if(this.storyData.title === 'Patient Radar'){
          // console.log(this.storyData.title);
          // let explorebtn = document.getElementById('explore-btn');
          // explorebtn.addEventListener("click",()=>{
          //   window.location.href = "http://www.w3schools.com"
          // })
          setTimeout(() => {
            let explorebtn = document.getElementById('explore-btn');
            explorebtn.addEventListener("click",()=>{
            window.open("https://prdr.staging.zsservices.com/","_blank");
          })
            let copybtn=document.getElementById("patient-radar-copy-password-btn");
            let copyunbtn = document.getElementById("patient-radar-copy-username-btn");
            copybtn.addEventListener("click",()=>{
                // console.log("copy btn clicked");
                // console.log(this.storyData);
                const elem = document.createElement('textarea');
                elem.value = this.storyData.sections[0].data.visulizations[0].widget[0].credentials[1].value;
                document.body.appendChild(elem);
                elem.select();
                document.execCommand('copy');
                document.body.removeChild(elem);
                document.getElementById('patient-radar-copy-password-txt').innerHTML='Passcode Copied!'
                setTimeout(()=>{

                  document.getElementById('patient-radar-copy-password-txt').innerHTML=''
                },1000)
              })

            copyunbtn.addEventListener("click",()=>{
                // console.log("copy btn clicked");
                // console.log(this.storyData);
                const elem = document.createElement('textarea');
                // elem.value = this.storyData.sections[0].data.visulizations[0].widget[0].credentials[0].value;
                // elem.value = "AI Gallery";
                elem.value = this.storyData.sections[0].data.visulizations[0].widget[0].credentials[0].value;
                document.body.appendChild(elem);
                elem.select();
                document.execCommand('copy');
                document.body.removeChild(elem);
                document.getElementById('patient-radar-copy-username-txt').innerHTML='Username Copied!'
                setTimeout(()=>{

                  document.getElementById('patient-radar-copy-username-txt').innerHTML=''
                },1000)
              })
            }, 100);
        }
        });
    
      
  }
  
  onSectionChange($event) {
    this.dataShareService.navigationSectionChange($event.id);
    this.selectedSection = $event.value.storyname;
  }

  ngOnDestroy() {
    this.dataShareService.updateCustomEvent({ event: 'removeClassToBody', data: this.classVal });
    this.dataShareService.onCustomEvent.next({ event: 'removeClassToElement', data: {selector: '#header', class: 'humburger-header'}});
    this.dataServiceObserver.unsubscribe();
  }
}

// <div class='patient_radar'>
//                   <div class='row h-100'>
//                     <div class='col-6'></div>
//                     <div class='col-6'>
//                       <div class='wrapper'>
//                         <h5 class='text-center pt-5 pb-2'>Patient Radar</h5>
//                         <p class='text-center'>An enterprise grade online platform powered by advanced data science and <br />
//                           years of deep domain knowledge.</p>
                
//                         <div class='right-section position-relative pt-3'>
//                           <div>
//                             <h6 class='text-center my-2'>Steps - To Explore Patient Radar</h6>
//                             <ul class='pi-steps d-flex justify-content-center mb-5'>
//                               <li class='d-flex flex-column align-items-center'>
//                                 <span>1</span>
//                                 <span>Copy Username & Password
//                                 </span>
//                               </li>
//                               <li class='d-flex flex-column align-items-center'>
//                                 <span>2</span>
//                                 <span>Click here to explore button
//                                 </span>
                
//                               </li>
//                               <li class='d-flex flex-column align-items-center mr-0'>
//                                 <span class='last-child'>3</span>
//                                 <span class='mr-0'>Paste Username & Password
//                                 </span>
//                               </li>
//                             </ul>
//                           </div>
//                           <div class='pi-actions position-relative d-flex align-items-center justify-content-center'>
//                             <div class='pi-actions-row pt-3 pb-0'>
//                               <div class='pi-actions-column'>
//                                 <button id='patient-radar-copy-username-btn'>Click here to copy username
//                                   <img class='icon-copy' src='../../../assets/images/patient_radar/copy.png' alt='Copy'>
//                                 </button>
//                                 <span id='patient-radar-copy-username-txt' class='copied-span text-right mt-1 mb-0 context-copied'></span>
//                               </div>
//                               <div class='pi-actions-column'>
//                                 <button id='patient-radar-copy-password-btn'>Click here to copy password
//                                   <img class='icon-copy' src='../../../assets/images/patient_radar/copy.png' alt='Copy'>
//                                 </button>
//                                 <span id='patient-radar-copy-password-txt' class='copied-span text-right mt-1 mb-0 context-copied'></span>
//                               </div>
//                             </div>
//                           </div>
//                           <div class='pi-actions-button text-center'>
//                             <button class='explore-btn'>Click here to explore</button>
//                           </div>
//                         </div>
//                       </div>
                        
//                     </div>
//                   </div>
//                  </div>

