import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserStatus } from 'src/app/shared/enum/enum';


@Component({
  selector: 'app-view-user-dialog-box',
  templateUrl: './view-user-dialog-box.component.html',
  styleUrls: ['./view-user-dialog-box.component.css']
})
export class ViewUserDialogBoxComponent implements OnInit {

  public UserStatus = UserStatus;
  constructor(
    public dialogRef: MatDialogRef<ViewUserDialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() {
  }

}
