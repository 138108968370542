import { Component, ElementRef, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
declare let d3: any;

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-d3-multi-series-line-chart',
  templateUrl: './d3-multi-series-line-chart.component.html',
  styleUrls: ['d3-multi-series-line-chart.component.css']

})
export class D3MultiSeriesLineChart implements OnInit{
  @ViewChild('d3MultiSeriesLineChart') chartElement: ElementRef;
  @Input() widgetData;
  @Input() widgetsCount;

  data;
  svg;
//this.chartElement.nativeElement
  ngOnInit() {
    this.data = this.widgetData.data;  
    this.loadGraph();
  }

  loadGraph() {
    var margin = {top: 20, right: 50, bottom: 30, left: 50},
    width = (this.widgetsCount > 1 ? 450 : 950) - margin.left - margin.right, //960
    height = (this.widgetsCount > 1 ? 250 : 500) - margin.top - margin.bottom; //500

    var parseDate = d3.time.format("%Y").parse;

    var x = d3.scale.linear()
        .range([0, width]);

    var y = d3.scale.linear()
        .range([height, 0]);

            
    var color = d3.scale.category10();

    var xAxis = d3.svg.axis()
        .scale(x)
        .orient("bottom");

    var yAxis = d3.svg.axis()
        .scale(y)
        .orient("left");

    var line = d3.svg.line()
        .interpolate("basis")
        .x(function(d) { return x(d.xValue); })
        .y(function(d) { return y(d.frequence); });

        var svg = d3.select(this.chartElement.nativeElement).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")"); 
    
        color.domain(d3.keys(this.data[0]).filter(function(key) { return key !== "xValue"; }));

        // this.data.forEach(function(d) {
        //   d.date = parseDate(d.date);
        // });
        var emotions = color.domain().map((name) => {
          return {
            name: name,
            values: this.data.map(function(d) {
              return {xValue: d.xValue, frequence: d[name]};
            })
          };
        });
        // x.domain(d3.extent(this.data, function(d) { return d.xValue; }));
        x.domain([d3.min(this.data, function(d) { return d.xValue; }), d3.max(this.data, function(d) { return d.xValue + 2; })]);

        y.domain([
          d3.min(emotions, function(c) { return d3.min(c.values, function(v) { return v.frequence; }); }),
          d3.max(emotions, function(c) { return d3.max(c.values, function(v) { return v.frequence + 50; }); })
        ]);
      
        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);
      
        svg.append("g")
            .attr("class", "y axis")
            .call(yAxis)
          .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .text("Ideal/Actual Startegy");
    

            var emotion = svg.selectAll(".emotion")
            .data(emotions)
            .enter().append("g")
            .attr("class", "emotion");    

            emotion.append("path")
            .attr("class", "line")
            .attr("d", function(d) { return line(d.values); })
            .style("stroke", function(d) { return color(d.name); });
      
        emotion.append("text")
            .datum(function(d) { return {name: d.name, value: d.values[d.values.length - 1]}; })
            .attr("transform", function(d) { return "translate(" + x(d.value.xValue) + "," + y(d.value.frequence) + ")"; })
            .attr("x", 3)
            .attr("dy", ".35em")
            .text(function(d) { return d.name; });
    
    }

       

  
}