import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
//import { WidgetService } from '../widgets.service';
declare let d3: any;
import * as _ from 'lodash';
import { ngDevModeResetPerfCounters } from '@angular/core/src/render3/ng_dev_mode';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-d3-vertical-tree-chart',
    templateUrl: './d3-vertical-tree-chart.component.html',
    styleUrls: ['./d3-vertical-tree-chart.component.css']
})
export class D3VerticalTreeChartComponent implements OnInit {

    @Input() widgetData;
    data;
    @ViewChild('d3TreeChartRef') chartElement: ElementRef;

    treeData;

    ngOnInit() {
        this.data = this.widgetData;

        // this.loadTree(this.data[0])
        // for (let i = 0; i < this.data.length; i++) {
        //     this.loadTree(_.cloneDeep(this.data[i]));
        // }
        // this.treeData=this.widgetData.data;
        //this.loadTree(_.cloneDeep(this.data));
        this.loadTree(this.data);

    }


    constructor(public dialog: MatDialog) {
    }




    loadTree(treeData) {
        var root;

        var margin = { top: 40, right: 0, bottom: 20, left: 60 };
        var width = 1000 - margin.right - margin.left;
        var height = 500 - margin.top - margin.bottom;

        var i = 0, duration = 750;
        var tree = d3.layout.tree()
            .size([900, 550]);
        var diagonal = d3.svg.diagonal()
            .projection(function (d) { return [d.x, d.y]; });
        var svg = d3.select(this.chartElement.nativeElement).append("svg")
            .attr("width", width + margin.right + margin.left)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
        var bkp_arr = [];

        root = treeData;

        // console.log(root.children.length);
        // for (let j = 0; j < root.children.length; j++) {
        //     bkp_arr.push({ name: root.children[j].name, value: root.children[j].children });
        //     // if (j) 

        //     root.children[j].hasChildren = true;
        //     root.children[j].children = null;
        // }

        root.x0 = height / 2;
        root.y0 = 0;

        update(root);
        function update(source) {
            // Compute the new tree layout.
            var nodes = tree.nodes(root).reverse(),
                links = tree.links(nodes);
            // Normalize for fixed-depth.
            nodes.forEach(function (d) { d.y = d.depth * 130; });
            // Declare the nodes…
            var node = svg.selectAll("g.node")
                .data(nodes, function (d) { return d.id || (d.id = ++i); });
            // Enter the nodes.
            var nodeEnter = node.enter().append("g")
                .attr("class", "node")
                .attr("transform", function (d) {
                    return "translate(" + source.x0 + "," + source.y0 + ")";
                }).on("click", nodeclick);

            nodeEnter.filter(function (d) { return d._children || d.children || d.depth == 1; })
                .append("circle")
                .attr("r", 1e-6)
                .attr("stroke", function (d) {

                    if (!d.children && !d._children && d.depth != 1) {
                        if (d.value > 0.5)
                            return d.color ? d.color : '#379ef7';// Blue
                        else if (d.value > 0.2 && d.value < 0.5)
                            return d.color ? d.color : '#f4a600'; // Yellow
                        else (d.value > 0.2)
                        return d.color ? d.color : '#ff5f5f'; // Red
                    }
                    else {
                        return d.color ? d.color : setColor(d);
                    }
                })
                .style("fill", function (d) { return d.children || d._children ? "lightsteelblue" : "#fff"; })
                .on('mouseover', () => {
                    div.style('display', 'inline');
                })
                .on('mousemove', function (d) {

                    div
                        .html(d.label + d.value.toFixed(2))
                        .style('left', (d3.event.pageX + 15) + 'px')
                        .style('top', (d3.event.pageY - 20) + 'px');
                })
                .on('mouseout', () => {
                    div.style('display', 'none');
                });

            nodeEnter.append("text")
                .attr("x", function (d) { return d.children || d._children ? -13 : 13; })
                .attr("dy", ".35em").attr("text-anchor", function (d) {
                    return d.depth == 1 || d.children || d._children ? "end" : "start";
                })
                .text(function (d) {
                    //return d.name;

                    if (typeof d.value == 'undefined') {
                        return d.name;
                    }
                    else if (d.children) {
                        return d.label + d.value.toFixed(2)
                    }
                    else if (d._children) {
                        return d.label + d.value.toFixed(2)
                    }
                    else {
                        //return d.tooltip;
                    }
                })
                .style("fill-opacity", 1e-6);

            var arc = d3.svg.arc()
                .outerRadius(18)
                .innerRadius(0);

            var pie = d3.layout.pie()
                .value(function (d) {
                    return d.value;
                })
                .sort(null);

            var color = d3.scale.ordinal()
                .range(['#5DADE2', '#e75654']);

            // nodeEnter
            //   .filter(function(d){ return (!d.children && d.depth!=1 && d.health);}) 
            //   .selectAll('path')
            //   .data(function(d){ return pie(d.health); })
            //   .enter()
            //   .append('path')
            //   .attr('d', arc)
            //   .attr('fill', function(d, i) {
            //     return color(i);
            //   })
            nodeEnter
                .filter(function (d) { return (!d.children && d.depth != 1 && d.health); })
                .selectAll('path')
                .data(function (d) {
                    return pie(d.health);
                })
                .enter()
                .append('path')
                .attr('d', arc)
                .attr('fill', function (d, i) {
                    return d.data.type ? '#5DADE2' : '#e75654';
                }).on('mouseover', () => {
                    div.style('display', 'inline');
                })
                .on('mousemove', function (d) {
                    var hoverText = d.value + "%";
                    if (d.data.type) {
                        hoverText += " Writer";
                    } else {
                        hoverText += " Non writer";
                    }
                    div
                        .html(
                            hoverText)
                        .style('left', (d3.event.pageX + 15) + 'px')
                        .style('top', (d3.event.pageY - 20) + 'px');
                })
                .on('mouseout', () => {
                    div.style('display', 'none');
                });


            // Transition nodes to their new position.
            //horizontal tree
            var nodeUpdate = node.transition()
                .duration(duration)
                .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });

            nodeUpdate.select("circle")
                .attr("r", 6)
                .style("fill", function (d) {
                    var collapseColor = d.color ? d.color : setColor(d);
                    return d.depth == 1 && d.hasChildren ? setColor(d) : d._children ? collapseColor : "#fff";
                });
            nodeUpdate.select("text")
                .style("fill-opacity", 1);


            // Transition exiting nodes to the parent's new position.
            var nodeExit = node.exit().transition()
                .duration(duration)
                .attr("transform", function (d) { return "translate(" + source.x + "," + source.y + ")"; })
                .remove();
            nodeExit.select("circle")
                .attr("r", 1e-6);
            nodeExit.select("text")
                .style("fill-opacity", 1e-6);
            // Update the links…
            // Declare the links…
            var link = svg.selectAll("path.link")
                .data(links, function (d) { return d.target.id; });
            // Enter the links.
            link.enter().insert("path", "g")
                .attr("class", "link")
                .style("stroke",
                    function (d) {
                        if (d.target.yes == "true")
                            return "blue";
                        else
                            return "red";
                    })
                .attr("d", function (d) {
                    var o = { x: source.x0, y: source.y0 };
                    return diagonal({ source: o, target: o });
                });
            // Transition links to their new position.
            link.transition()
                .duration(duration)
                .attr("d", diagonal);



            // Transition exiting nodes to the parent's new position.
            link.exit().transition()
                .duration(duration)
                .attr("d", function (d) {
                    var o = { x: source.x, y: source.y };
                    return diagonal({ source: o, target: o });
                })
                .remove();

            // Stash the old positions for transition.
            nodes.forEach(function (d) {
                d.x0 = d.x;
                d.y0 = d.y;
            });
        }

        // Toggle children on click.
        function nodeclick(d) {

            // let myObj = _.find(bkp_arr, { 'name': d.name });
            if (d.children) {
                d._children = d.children;
                d.children = null;

            } else {
                d.children = d._children;
                d._children = null;
                // if (d.depth == 1) {
                //     d.children = myObj.value;
                // }

            }
            // d.hasChildren = false;
            update(d);
        }
        // tooltips
        var div = d3.select('body').append('div')
            .attr('class', 'graph-tooltip')
            .attr('id', 'd3-tree-chart-custom-tooltip')
            .style('display', 'none');

        function setColor(d) {
            if (d.category == "Payer") {
                return '#58D68D';
            }
            else if (d.category == "HCP") {
                return '#E67E22';
            }
            else if (d.category == "Claims") {
                return '#5DADE2';
            }
            else if (d.category == "Sales") {
                return '#A569BD';
            }
            else if (d.category == "Promo") {
                return '#7F8C8D';
            }
        }

    }

    OnDestroy() {
        d3.select("#d3-tree-chart-custom-tooltip").remove();
    }


}
