import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-loader-component',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
    // loaded:boolean = false;
    // private subscription : Subscription;
    constructor(private loaderService: LoaderService) { }
    ngOnInit() {
        // this.loaderService.show();
        // this.subscription = this.loaderService.loaderState
        // .subscribe((state : any) => {
        //     // alert("Inside component");
        //         this.loaded = state.loaded;
        //     });
    }
    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }
}