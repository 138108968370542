import { Component, OnInit, Input, SecurityContext } from '@angular/core';
// import {DomSanitizer} from '@angular/platform-browser';
// import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html'
})
export class ImageViewer implements OnInit {
 	/*@Input() data;

  	constructor(private _DomSanitizationService: DomSanitizer) {}

 	ngOnInit() {
 		this.data = this._DomSanitizationService.bypassSecurityTrustUrl(this.data);
 		// console.log(this.data)
 	}*/
 	@Input() widgetData;
	data;
	// htmlString;
	// url;

	/*constructor(private _sanitizer: DomSanitizer) {
 	}*/
	// constructor(private _sanitizer: DomSanitizer) {
 	// }
 	ngOnInit() {
		this.data = this.widgetData.data;
		 //this.htmlString = this.data.htmlString;
		//  this.url = this.data.url;
 	}

 	// public get sanitizedHtmlProperty() : SafeHtml {
    //  	return this._sanitizer.sanitize(SecurityContext.HTML, this.htmlString);
  	// }
}
