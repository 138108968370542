// import { Gallery } from './gallery.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { LoaderService } from '../../services/loader.service';

import { Subject } from 'rxjs';
import { HttpWrapperService } from '../../services/http-wrapper.service';



@Injectable()
export class StoryService {

    constructor(private httpClient: HttpClient, private _httpWrapper: HttpWrapperService) { }

    loadSection = new Subject<any>();
    // loadSectionState = this.loadSection.asObservable();

    private storyDetails: any;
    getStoryData() {
        return this.storyDetails;
    }

    getStoryDataNew(endpoint) {
        // this.loderService.show();
        // return this.httpClient.get(endpoint);
        return this._httpWrapper.get(endpoint);
        // console.log(resp);
        // this.loderService.hide();
        // let stories = resp.stories;
        // this.setStoryList(resp)
        // });
    }

    updateSection(actionData: any) {
        this.loadSection.next(actionData);
    }

}
