import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-promo-video',
    templateUrl: './promo-video-dialog-box.component.html'
})
export class PromoVideoComponent {
    videoURL = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<PromoVideoComponent>) {
        this.videoURL = data.videoURL;
    }
}
