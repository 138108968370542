import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
declare const d3: any;

@Component({
	selector: 'app-small-circle-cluster',
	templateUrl: './small-circle-cluster.component.html',
	styleUrls: ['./small-circle-cluster.component.css']
})
export class SmallCircleClusterComponent implements OnInit {
	@Input() smallClusterDetails: any = {
		svg: null,
		index: 0,
		width: 200,
		height: 400,
		data: [],
		categories: "smallCluster",
		categoriesColor: "#CCC",
		idString: "smallCluster"
	}
	force: any = null;
	forceData: any = { "nodes": [] };
	forceCircleRadius: Number = 5;
	node: any = null;
	outerCircleCharge = -8;
	svgGroup: Array<any> = [];
	constructor() { }

	tick = () => {

		this.svgGroup[this.smallClusterDetails.index]
			.selectAll("circle")
			.attr({
				"cx": function (d) {
					return d.x;
				},
				"cy": function (d) {
					return d.y;
				}
			});
	}

	ngOnInit() {
		const { svg, width, height, data, index, idString } = this.smallClusterDetails
		let svg_group = svg.append('g')
			.attr({
				"width": width,
				"height": height,
				"id": () => {
					return idString + "-" + index;
				}
			});

		this.svgGroup[index] = svg_group;
		this.node = svg_group.selectAll(".node_" + index);
		this.force = d3.layout.force()
			.size([Math.floor(width * 2), height])
			.charge(this.outerCircleCharge)
			.on("tick", this.tick);

		this.drawInit(data);
	}

	drawInit(graph) {
		const { idString, index, categoriesColor } = this.smallClusterDetails;
		this.node = this.node.data(graph.nodes, (d, i) => {
			return d.id;
		})

		this.node
			.enter()
			.append("circle")
			.style({
				"fill": categoriesColor
			})
			.attr({
				"class": "node_" + index,
				"fill-opacity": 0,
				"id": (d, i) => {
					return idString + "-" + index + "-" + d.id;
				},
				"r": this.forceCircleRadius
			})

		this.force
			.nodes(graph.nodes)
			.start();
	}
}
