import { OnInit, Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AdminService } from '../service/admin.service';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
    selector: 'app-stories-credentials-dialog-box',
    templateUrl: './stories-credentials-dialog-box.component.html',
    styleUrls: ['./stories-credentials-dialog-box.component.css']
})
export class StoriesCredentialsDialogBoxComponent implements OnInit, OnDestroy {
    public manipulatedData: any[] = [];
    public isLoading = true;
    public data;
    constructor(private formBuilder: FormBuilder
        , private _adminService: AdminService
        , private dialog: MatDialog
        , private _snackBar: MatSnackBar) {
        // console.log("Inside StoriesCredentialsDialogBoxComponent constructor");
    }

    ngOnInit() {
        // console.log("Inside StoriesCredentialsDialogBoxComponent ngOninti");
        this._adminService.getStoriesCredentialsList().subscribe(
            (response: any) => {
                // console.log("Response: ", response);
                this.data = response;
                this.data.forEach((story, storyIndex) => {
                    const tempResult = {};
                    story.metadata.forEach((widget, widgetIndex) => {
                        widget.credentials.forEach((credential, credIndex) => {
                            tempResult[credential.label.toLowerCase()] = [credential.value, Validators.required];
                        });
                        widget['isLoading'] = false;
                        widget['credentialsForm'] = this.formBuilder.group(tempResult);
                    });
                });
                // console.log("Updated Data: ", this.data);
                this.isLoading = false;
            }
            , (err) => {
                this._snackBar.open(err.error.message, '', {
                    duration: 3000,
                    panelClass: 'error',
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
                this.isLoading = false;
                this.dialog.closeAll();
            })
    }

    onSubmitForm(story, widgetApplication) {
        let data = {
            type: 'widget',
            key: 'credentials',
            storyUrl: story.storyUrl,
            sectionId: widgetApplication.sectionId,
            visualizationId: widgetApplication.visualizationId,
            widgetId: widgetApplication.widgetId,
            credentials: null
        };
        data.credentials = JSON.parse(JSON.stringify(widgetApplication.credentials));

        let tempResult = {};
        data.credentials.forEach(cred => {
            cred.value = widgetApplication.credentialsForm.value[cred.label.toLowerCase()];
            tempResult[cred.label.toLowerCase()] = [cred.value, Validators.required];
        });

        widgetApplication.isLoading = true;
        widgetApplication.credentialsForm.disable();
        this._adminService.updateStoryCredentials(data).subscribe(
            (response:any) => {
                // console.log("Response: ", response);
                widgetApplication.credentialsForm.enable();
                widgetApplication.isLoading = false;
                this._snackBar.open(response.message, '', {
                    duration: 3000,
                    panelClass: 'success',
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
                widgetApplication.credentialsForm = this.formBuilder.group(tempResult);
            },
            (err) => {
                // console.log("Error: ", err);
                widgetApplication.credentialsForm.enable();
                widgetApplication.isLoading = false;
                this._snackBar.open(err.error.message, '', {
                    duration: 3000,
                    panelClass: 'error',
                    verticalPosition: 'top',
                    horizontalPosition: 'right'
                });
            }
        );
    }

    ngOnDestroy() {

    }
}