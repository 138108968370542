import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WidgetService } from '../widgets.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-combo-bar-chart',
  templateUrl: './combo-bar-chart.component.html'
})
export class ComboBarChartComponent implements OnInit {
  @ViewChild('barchart') barChart;
  @Input() widgetData;
  data;

  options = {
    hAxis: { title: 'Year' },
    height: 300,
    seriesType: 'bars',
    series: { 5: { type: 'line' } }
  };
  barChartData;
  filterData: any = {
    year: [],
    drug: []
  };

  constructor(private widgetService: WidgetService) {
    this.widgetService.triggerDataChangeForLineBarChart.subscribe((updatedDataInfo) => {
      // alert("Inside combo bar chart");
      if (updatedDataInfo.inputDetails.filterOn === 'drug') {
        this.filterData.drug = updatedDataInfo.formField.filterField;
      } else if (updatedDataInfo.inputDetails.filterOn === 'year') {
        this.filterData.year = updatedDataInfo.formField.filterField;
      }
      if (this.filterData.year.length || this.filterData.drug.length) {
        // if ((updatedDataInfo.formField.uniqueName5 && updatedDataInfo.formField.uniqueName5.length)
        // || (updatedDataInfo.formField.uniqueName55 && updatedDataInfo.formField.uniqueName55.length)) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }
    });
  }

  updateChart(updatedDataInfo) {
    /*let dataTable = this.barChart.wrapper.getDataTable();
    let selectedDataColumn = dataTable.getColumnIndex(updatedDataInfo.formField.uniqueName5);
    let excludeColumns = [0, selectedDataColumn];
    let totalNumberOfColumns = dataTable.getNumberOfColumns();

    for(let index = 0; index < totalNumberOfColumns; index++) {
      if(excludeColumns.indexOf(index) == -1 ) {
        dataTable.removeColumn(+index)
      }
    }
    this.barChart.redraw();
    console.log(dataTable.getColumnIndex(updatedDataInfo.formField.uniqueName5));
    console.log(dataTable.getNumberOfColumns());*/
    /*    console.log("defore")
        console.log(this.barChartData.dataTable);
        this.barChartData = Object.create(this.barChartData);
        // let excludeColumns = [0, selectedDataColumn];
        let dataTable = this.barChart.wrapper.getDataTable();
        let selectedDataColumn = dataTable.getColumnIndex(updatedDataInfo.formField.uniqueName5);
        let totalNumberOfColumns = dataTable.getNumberOfColumns();
        let totalNumberOfRows = dataTable.getNumberOfRows() + 1;
        // Add one as it excludes first heading row
        let excludeColumns = [0, selectedDataColumn];
    console.log("selectedDataColumn", selectedDataColumn);
    console.log("totalNumberOfColumns", totalNumberOfColumns);
    console.log("totalNumberOfRows", totalNumberOfRows);
    console.log(excludeColumns);
        for (let rows = 0; rows < totalNumberOfRows; rows++) {
          // console.log("rows", rows, this.barChartData.dataTable[rows]);
          for (let col = 0; col < totalNumberOfColumns; col++) {
          // console.log("col", col, this.barChartData.dataTable[rows][col]);
            console.log("Col", col);
            console.log(excludeColumns.indexOf(col) == -1);
            if(excludeColumns.indexOf(col) == -1) {
              this.barChartData.dataTable[rows].splice(col, 1);
            }
          }
        }*/

    /*for (let rows = 0; rows < totalNumberOfRows; rows++) {
          for (let col = 0; col < totalNumberOfColumns; col++) {
          // console.log("col", col, this.barChartData.dataTable[rows][col]);
            console.log("Col", col);

            console.log(excludeColumns.indexOf(col) == -1);
            if(excludeColumns.indexOf(col) == -1) {
              this.barChartData.dataTable[rows].splice(col, 1);
            }
          }
        }*/
    const updatedDataSet = [];
    // if (updatedDataInfo.formField.uniqueName5 && updatedDataInfo.formField.uniqueName5.length) {
    if (this.filterData.drug.length) {
      const numberOfRows = this.data.length;
      // const numberOfColumns = this.data[0].length;
      // let selectedColumnIndex = this.data[0].indexOf(updatedDataInfo.formField.uniqueName5);
      // let selectedColumnIndexs = this.findIndexesOfElements(updatedDataInfo.formField.uniqueName5, this.data[0]);
      const selectedColumnIndexs = this.findIndexesOfElements(this.filterData.drug, this.data[0]);

      for (let row = 0; row < numberOfRows; row++) {
        const newRow = [];
        newRow.push(this.data[row][0]); // This is for adding year column value by default
        for (let col = 0; col < selectedColumnIndexs.length; col++) {
          newRow.push(this.data[row][selectedColumnIndexs[col]]); // This is for adding selected columns value
        }
        updatedDataSet.push(newRow);
      }
      this.barChartData = Object.create(this.barChartData);
      this.barChartData.dataTable = updatedDataSet;
    }

    // if (updatedDataInfo.formField.uniqueName55 && updatedDataInfo.formField.uniqueName55.length) {
    if (this.filterData.year.length) {
      const getFromDataSet = updatedDataSet.length ? updatedDataSet : this.data;
      const numberOfRows = getFromDataSet.length;
      const numberOfColumns = getFromDataSet[0].length;
      const updatedDataSetByYear = [];
      // console.log(getFromDataSet);
      updatedDataSetByYear.push(getFromDataSet[0]);
      for (let row = 1; row < getFromDataSet.length; row++) {
        // newRow.push(this.data[row][0]);
        // newRow.push(this.data[row][selectedColumnIndex]);
        // updatedDataSetByYear.push(newRow);
        // for(let col = 0; col < numberOfCols; col++) {
        // if (updatedDataInfo.formField.uniqueName55.indexOf(getFromDataSet[row][0]) > -1) {
        if (this.filterData.year.indexOf(getFromDataSet[row][0]) > -1) {
          // getFromDataSet.splice(row, 1);
          updatedDataSetByYear.push(typeof getFromDataSet[row] === 'undefined' ? '' : getFromDataSet[row]);
        }
        // }
      }
      this.barChartData = Object.create(this.barChartData);
      this.barChartData.dataTable = updatedDataSetByYear;
    }
    // console.log(this.barChartData.dataTable)

  }

  findIndexesOfElements(indexOfElements, fromArray) {
    const foundIndexes = [];
    for (let index = 0; index < fromArray.length; index++) {
      if (indexOfElements.indexOf(fromArray[index]) > -1) {
        foundIndexes.push(index);
      }
    }

    return foundIndexes;
  }

  resetToOrignal() {
    this.barChartData = Object.create(this.barChartData);
    this.barChartData.dataTable = this.data;
  }

  ngOnInit() {
    // console.log("-----------Combo chart-------");
    // console.log(this.data);
    this.data = this.widgetData.data;

    this.barChartData = {
      chartType: 'ComboChart',
      dataTable: this.data,
      options: this.options
    };
  }
}
