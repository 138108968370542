import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-data-coverage',
  templateUrl: './custom-data-coverage.component.html'
})
export class CustomDataCoverageComponent implements OnInit {
 	@Input() widgetData;

 	coverageData;

 	ngOnInit() {
 		this.coverageData = this.widgetData.data;
 	}
}
