import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { forkJoin, Subject, timer } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { environment } from 'src/environments/environment';
import { DataShareService } from '../shared/data-share.service';
import { AuthService } from '../shared/auth.service';

@Injectable()
export class GalleryService {

  storiesChanged = new Subject<any>();
  private gallery;
  private isDataSet = false;

  private colors = [
    { id: 1, color: "#FFEAD5" },
    { id: 2, color: "#DDEAFF" },
    { id: 3, color: "#D4F8E7" },
    { id: 4, color: "#F2E3FF" },
    { id: 5, color: "#EFEDBC" },
    { id: 6, color: "#EBE6D6" },
    { id: 7, color: "#FFE5E5" },
    { id: 8, color: "#FCE4F7" },
    { id: 9, color: "#D7F5F5" },
    { id: 10, color: "#E4F5D7"},
  ]
  // { id: 17, color: "#F7DEDE" },
  //   { id: 18, color: "#DBF1FC" },
  //   { id: 19, color: "#E4EDED" },
  //   { id: 110, color: "#EBE6D6"},
  //   { id: 1, color: "#F0A158" },
  //   { id: 2, color: "#819FE5" },
  //   { id: 3, color: "#73C6C3" },
  //   { id: 4, color: "#AE8DF5" },
  //   { id: 5, color: "#D9AF4E" },
  //   { id: 6, color: "#B2A53D" },
  //   { id: 7, color: "#C7866E" },
  //   { id: 8, color: "#6FA7FC" },
  //   { id: 9, color: "#75CB82" },
  //   { id: 10, color: "#DB8EB2" }

  public groupColors = [
    { id: 1, color: "#E1CECE" },
    { id: 2, color: "#D4E1CE" },
    { id: 3, color: "#CED3E1" },
    { id: 4, color: "#e9dde7" },
    { id: 5, color: '#EDCED0' },
    { id: 6, color: '#DCD1D4' },
  ]

  groups = [];

  // tile layout changes starts here

  private _galleryLoadCombination;
  private storyClassName: string = null;
  private screenWiseLayoutIndex = [
    [0], [1], [2]
  ];
  private storyListDesign =
    [

      {
        // TODO: below 1440 Screens
        className: 'layout-0',
        designValue:
          // 11
          [
            { cols: 3, rows: 12 }, //1
            { cols: 3, rows: 11 }, //2
            { cols: 3, rows: 12 }, //3
            { cols: 3, rows: 11 }, //4
            { cols: 3, rows: 10 }, //5
            { cols: 3, rows: 11 }, //6
            { cols: 3, rows: 11 }, //7
            { cols: 3, rows: 11 }, //8
            { cols: 3, rows: 13 }, //9
            { cols: 3, rows: 11 }, //10
            { cols: 3, rows: 12 }, //11
            { cols: 3, rows: 12 }, //12
          ],
      },
      {
        // 1440 and above Screens
        className: 'layout-1',
        designValue:
          // 12
          [
            { cols: 3, rows: 14 }, //1
            { cols: 3, rows: 12 }, //2
            { cols: 3, rows: 14 }, //3
            { cols: 3, rows: 12 }, //4
            { cols: 3, rows: 13 }, //5
            { cols: 3, rows: 13 }, //6
            { cols: 3, rows: 13 }, //7
            { cols: 3, rows: 15 }, //8
            { cols: 3, rows: 13 }, //9
            { cols: 3, rows: 14 }, //10
            { cols: 3, rows: 12 } //11
          ]
      }, {
        // Filter and search
        className: 'layout-filter',
        designValue:
          [
            { cols: 3, rows: 13 },
            { cols: 3, rows: 14 },
            { cols: 3, rows: 15 },
            { cols: 3, rows: 15 },
            { cols: 3, rows: 14 },
            { cols: 3, rows: 15 },
            { cols: 3, rows: 15 },
            { cols: 3, rows: 15 }
          ]
      }
    ];
  private previousScreenWidth = null;
  private currentScreenWidth = null;
  private widthBreakValue = [1200, 1440];

  // tile layout changes ends here

  constructor(
    private _httpWrapper: HttpWrapperService, 
    private dataShareService: DataShareService,
    private _authService: AuthService,) { }

  getStoryList() {
    // console.log(this.httpWrapper.get('https://8te4yxvui6.execute-api.us-east-1.amazonaws.com/dev/list'));
    // alert('Called');
    // this.loderService.show();
    // this.httpClient.get('https://upim9c3gpb.execute-api.us-east-1.amazonaws.com/Dev/stories').subscribe((resp: any) => {
    // this._httpWrapper.get(environment.api_url + '/stories').subscribe((resp: any) => {
    //   // this._httpWrapper.get('./assets/story.json').subscribe((resp: any) => {
    //   // console.log(resp);
    //   // this.loderService.hide();
    //   // let stories = resp.stories;
    //   const dummyGroup = () => {
    //     return {
    //       "isGroup": true,
    //       "stories": [],
    //       "title": "Analytics",
    //       "color": "#ff000021",
    //       "tags": [
    //         "#machine-learning",
    //         "#AI",
    //         "#gnome",
    //         "#nlp",
    //         "#Segmentation"
    //       ],
    //       "category": "AI in Personalization",
    //     }
    //   }

    //   const dummyStory = () => {
    //     return {
    //       "category": "AI in Personalization",
    //       "isNotAvailable": false,
    //       "endpoint": "https://ybujwuzuh3.execute-api.us-east-1.amazonaws.com/prod",
    //       "displayTitle": "<span>Adaptive</span>Attribute-based<br/>Offers",
    //       "description": "Personalizing hotel recommendation and marketing offers by utilizing customer attribute level willingness-to-pay through multi-step Bayesian modeling",
    //       "title": "Adaptive Attribute-based Offers",
    //       "url": "adaptive_attribute_based_offers",
    //       "tags": [
    //         "#machine-learning",
    //         "#AI",
    //         "#gnome",
    //         "#nlp",
    //         "#Segmentation"
    //       ],
    //       "isInteractive": false,
    //       "isHorizonalView": true,
    //       "viewType": "business",
    //       "createdAt": "10/22/2020",
    //     }
    //   }
    //   resp.data.stories.splice(0, 0, { ...dummyGroup(), stories:[{ ...dummyStory() }, { ...dummyStory() }, { ...dummyStory() }] }, { ...dummyGroup(), stories: [{ ...dummyStory() }, { ...dummyStory() }] });
    //   this.setStoryList(resp);
    // });
    this.dataShareService.onCustomDashboardEvent.next({
      event: 'showSettings',
      value: false
    })

    forkJoin([
      this.getMetaData(), 
      this.getTileList(), 
      this.getUpdatedCategoryList()
    ]).subscribe((res: any[]) => {
      console.log(res)
      const stories = res[1].data;
      const categories = res[2].data;
    const virtual_story = {
      "id": "23",
      "type": "story",
      "categoryId": "3",
      "sequenceOrder": 23,
      "endpoint": "",
      "title": "Virtual Rep Trainer",
      "description": "Virtual Rep Trainer is a way for everyone to practice their conversational skills with an AI in safe environment.",
      "url": "virtual_rep_trainer",
      "isNew": true,
      "isInteractive": true,
      "isVisible": true,
      "groupId": null,
      "createdAt": null,
      "createdBy": "1",
      "updatedAt": "1655816735000",
      "updatedBy": "1",
      "isHorizontalView": true,
      "tags": [
          "#AI",
          "#chatgpt",
          "#AIGen"
      ],
      "displayTitle": {
          "line1": "Virtual ",
          "line2": "Rep Trainer"
      },
      "viewType": "data_science",
      "categoryName": "AI in Personalisation"
  }
    // stories.push(virtual_story);

      categories.forEach(e => {
        e.categoryClass = 'category_' + this.colors.find(c => c.color === e.color).id //catethis.colors.find(c => c.color === e.color).id;
      });
      stories.forEach((story, i) => {
        if (story.type == 'group') {
          this.groups.push({
            name: story.name,
            id: story.id,
            color: story.color
          })
          story.stories.forEach(s => {
            const index = categories.findIndex(e => e.categoryId === s.categoryId);
            if (index > -1) {
              s.isHidden = !s.isVisible;
              s.isGrouped = story.groupId === null ? false : true;
              s.category = categories[index].name;
              s.categoryClass = categories[index].categoryClass;
            } else {
              // console.log('Something is wrong');
            }
          });
          story.isHidden = !story.isVisible;
          story.isGroup = story.type == 'group' ? true : false
          story.tags = [
            "#machine-learning",
            "#AI",
            "#gnome",
            "#nlp",
            "#Segmentation"
          ],
          story.category = "AI in Personalization";
          // }
        } else {
          const index = categories.findIndex(e => e.categoryId === story.categoryId);
          if (index > -1) {
            story.isHidden = !story.isVisible;
            story.isGrouped = story.groupId === null ? false : true;
            story.category = categories[index].name;
            story.categoryClass = categories[index].categoryClass;
          } else {
              // console.log('Something is wrong');
          }
        }
      });
      const resp = {
        data: {
          promo_video_url: res[0].data.promoVideoUrl,
          stories: res[1].data,
          categories: res[2].data
        }
      }
      this.setStoryList(resp);
      this.dataShareService.onCustomDashboardEvent.next({
        event: 'showSettings',
        value: true
      })
    });
  }

  getStoryListFromLocal() {
    return (this.gallery && this.gallery.stories && this.gallery.stories.slice()) || [];
  }

  getStoryListByCategory(filter) {
    // http://dummy.restapiexample.com/api/v1/employees
    // console.log(this.httpClient.get('https://otccdzw48e.execute-api.us-east-1.amazonaws.com/dev/story/list').subscribe(resp => {
    //   console.log(resp);
    // }));

    const filterData = [];
    if (this.gallery && this.gallery.stories) {
      for (let i = 0; i < this.gallery.stories.length; i++) {
        if (this.gallery.stories[i].category.toLowerCase() === filter.category) {
          filterData.push(this.gallery.stories[i]);
        }
      }
    }
    return filterData;
  }

  getDataSetStatus() {
    return this.isDataSet;
  }

  setStoryList(resp) {
    const stories = (resp && resp.data && resp.data.stories) || [];
    stories.forEach(v => {
      if (v.createdAt) {
        v.createdAt = new Date(v.createdAt);
        v.createdAt = v.createdAt.setMonth(v.createdAt.getMonth() + 1);
      }
    });
    this.gallery = {
      stories: stories,
      categories: (resp && resp.data && resp.data.categories) || [],
      promo_video_url: (resp && resp.data && resp.data.promo_video_url) || ''
    };
    this.isDataSet = true;
    // console.log(this.gallery.stories);
    this.storiesChanged.next(this.gallery.stories.slice());
    this.dataShareService.galleryDataLoad(this.gallery);
  }

  getStoryListById(filter) {
    // let filterData = {};
    if (this.gallery && this.gallery.stories) {
      for (let i = 0; i < this.gallery.stories.length; i++) {
        if (this.gallery.stories[i].type === 'group') {
          for (let j = 0; j < this.gallery.stories[i].stories.length; j++) {
            if (this.gallery.stories[i].stories[j].url.toLowerCase() === filter.url.toLowerCase()) {
              return this.gallery.stories[i].stories[j];
            }
          }
        } else if (this.gallery.stories[i].url.toLowerCase() === filter.url.toLowerCase()) {
          return this.gallery.stories[i];
        }
      }
    }
    return null;
  }

  getPromoVideoURL() {
    return (this.gallery && this.gallery.promo_video_url) || '';
  }

  postData(url, data) {
    // return this.httpClient.post(url, data);
    return this._httpWrapper.post(url, data);
  }

  getData(url) {
    // return this.httpClient.get(url);
    return this._httpWrapper.get(url);
  }
  /*
  setStoryListNew(data) {
    this.gallery = {
       stories: data
    }
  }*/

  setContactData(req) {
    return this._httpWrapper.post(environment.api_url + '/user/contact-request', req);
    // return timer();
  }

  setPurposeData(req) {
    return this._httpWrapper.post(environment.api_url + '/user/access-reason', req);
    // return timer();
  }

  // tile layout changes starts here

  public isStoriesAvailable(): boolean {
    return this.isDataSet;
  }

  getClassName() {
    return this.storyClassName;
  }

  public parseStoryList(list: any[], screenWidth, isFilted = false, initialScreenFlag = false, isQuickSettingsActive = false) {
    list = this._sortTiles(list, isQuickSettingsActive);
    if (initialScreenFlag) {
      this.previousScreenWidth = screenWidth;
      this.currentScreenWidth = screenWidth;
    }
    if (isFilted) {
      this._galleryLoadCombination = 2;
    } else {
      // const randomStoryPositionSelector = (this._galleryLoadCombination == undefined) ? this.setGalleryLayoutIndex(screenWidth) : this._galleryLoadCombination;
      const randomStoryPositionSelector = this.setGalleryLayoutIndex(screenWidth);
      this._galleryLoadCombination = randomStoryPositionSelector;
    }
    const storyDesignArray = [...this.storyListDesign[this._galleryLoadCombination].designValue];
    this.storyClassName = this.storyListDesign[this._galleryLoadCombination].className;
    return list.map((v, i) => {
      const designObject = storyDesignArray[i % storyDesignArray.length];
      v = { ...v, ...designObject };
      if (v.isGroup) {
        v.cols = 6;
        if (v.stories.length != 0) {
          const visibleStories = v.stories.filter(e => isQuickSettingsActive || !e.isHidden);
          v.cols = visibleStories.length > 1 ? 6 : 3;
          if (visibleStories && visibleStories.length !== 0) {
            visibleStories.forEach((e: any, i, arr) => {
              e.rows = 10;
              e.cols = visibleStories.length > 1 ? (i === arr.length - 1 && i % 2 == 0 ? 12 : 6) : 12;
              // e.cols = i === arr.length - 1 && i % 2 == 0 ? 12 : 6;
              return e;
            });
          }
          const paddingTop = 2
          const paddingBottom = 1
          v.rows = Math.ceil(visibleStories.length / 2) * 10 + paddingTop + paddingBottom + (isQuickSettingsActive ? 1 : 0);
          if (visibleStories.length === 0) {
            v.rows = 0;
            v.cols = 0;
            v.noStories = true;
          } else {
            v.noStories = false;
          }
        } else {
          v.rows = 0;
          v.cols = 0;
          v.noStories = true;
        }
      }
      return v;
    });
  }

  setGalleryLayoutIndex(screenWidth) {

    const selectStoryLayoutList =
      screenWidth > this.widthBreakValue[1] ?
        this.screenWiseLayoutIndex[1] : this.screenWiseLayoutIndex[0];
    return selectStoryLayoutList[Math.floor(Math.random() * (selectStoryLayoutList.length))];
    // return 5;
  }

  updateGalleryLayout(screenWidth) {
    this.previousScreenWidth = this.currentScreenWidth;
    this.currentScreenWidth = screenWidth;
    if (screenWidth > this.widthBreakValue[1]) {
      if (this.currentScreenWidth > this.widthBreakValue[1] && this.previousScreenWidth > this.widthBreakValue[1]) {
        return false;
      } else {
        this._galleryLoadCombination = this.setGalleryLayoutIndex(screenWidth);
        return true;
      }
    } else if (screenWidth > this.widthBreakValue[0] && screenWidth < this.widthBreakValue[1]) {
      if (this.currentScreenWidth < this.widthBreakValue[1] && this.previousScreenWidth < this.widthBreakValue[1] && ((this.previousScreenWidth - this.currentScreenWidth) > 0)) {
        return false;
      }
      if (this.currentScreenWidth > this.widthBreakValue[0] && this.previousScreenWidth > this.widthBreakValue[0] && ((this.currentScreenWidth - this.previousScreenWidth) > 0)) {
        return false;
      } else {
        this._galleryLoadCombination = this.setGalleryLayoutIndex(screenWidth);
        return true;
      }
    } else if (screenWidth < this.widthBreakValue[0]) {
      if (this.currentScreenWidth < this.widthBreakValue[0] && this.previousScreenWidth < this.widthBreakValue[0]) {
        return false;
      } else {
        this._galleryLoadCombination = this.setGalleryLayoutIndex(screenWidth);
        return true;
      }
    }
  }

  // tile layout changes ends here

  private _sortTiles(list: any[], isQuickSettingsActive) {
    const groups = [];
    const stories = [];
    list.forEach(e => {
      if (e.type === 'group') {
        e.visibleStories = e.stories.filter(e => isQuickSettingsActive || !e.isHidden).length > 1 ? 1 : 0;
        groups.push(e);
      } else if (e.type === 'story') {
        stories.push(e);
      }
    });
    groups.sort((a, b) => a.visibleStories === b.visibleStories  ? 0 : a.visibleStories - b.visibleStories > 0 ? -1 : 1 );
    return [...groups, ...stories];
  }

  private _getUserId() {
    return this._authService.getUserId()
  }

  updateLocalStoryList(tileData) {
    if (this.gallery && this.gallery.stories) {
      this.gallery.stories = tileData;
      this.storiesChanged.next(this.gallery.stories.slice());
    }
  }

  setAllCategories(data) {
    if (this.gallery && this.gallery.categories) {
      this.gallery.categories = data;
    }
  }

  getCategoryList() {
    return (this.gallery && this.gallery.categories && this.gallery.categories.slice()) || [];
  }

  removeFromCategoryList(id) {
    this.gallery.categories.splice(this.gallery.categories.findIndex(e => e.categoryId === id), 1);
  }

  addToCategoryList(data) {
    this.gallery.categories.push(data);
  }

  updateInCategoryList(data, id) {
    const categoryIndex = this.gallery.categories.findIndex(e => e.categoryId === id);
    if (categoryIndex > -1) {
      this.gallery.categories[categoryIndex] = {...this.gallery.categories[categoryIndex], ...data}
    }
  }

  getGroupList() {
    return this.groups.slice() || [];
  }

  removeFromGroupList(id) {
    this.groups.splice(this.groups.findIndex(e => e.id === id), 1);
  }

  addToGroupList(data) {
    this.groups.push(data);
  }

  getColors() {
    return this.colors.slice() || [];
  }

  getColorId(color: string) {
    const result = this.colors.find(e => e.color === color);
    return result ? result.id : null;
  }

  getTileList() {
    return this._httpWrapper.get(environment.api_url + '/dashboard/tiles');
  }

  getUpdatedCategoryList() {
    return this._httpWrapper.get(environment.api_url + '/dashboard/categories');
  }

  createCategory(data) {
    return this._httpWrapper.post(environment.api_url + '/dashboard/category', {...data, userId: this._getUserId()});
  }

  deleteCategory(data) {
    return this._httpWrapper.delete(environment.api_url + '/dashboard/category', data);
  }

  updateCategory(data) {
    return this._httpWrapper.put(environment.api_url + '/dashboard/category', {...data, userId: this._getUserId()})
  }

  createGroup(data) {
    return this._httpWrapper.post(environment.api_url + '/dashboard/group', {...data, userId: this._getUserId()});
  }

  updateGroup(data) {
    return this._httpWrapper.put(environment.api_url + '/dashboard/group', {...data, userId: this._getUserId()});
  }

  deleteGroup(data) {
    return this._httpWrapper.delete(environment.api_url + '/dashboard/group', {...data, userId: this._getUserId()});
  }

  updateStory(data) {
    return this._httpWrapper.put(environment.api_url + '/dashboard/story', {...data, userId: this._getUserId()});
  }

  updateQuickSettings(data) {
    return this._httpWrapper.put(environment.api_url + '/dashboard/quick-settings/tiles', {...data, userId: this._getUserId()});
  }

  updateCategorySequence(data) {
    return this._httpWrapper.put(environment.api_url + '/dashboard/quick-settings/categories', {...data, userId: this._getUserId()});
  }

  getMetaData() {
    return this._httpWrapper.get(environment.api_url + '/metadata-details');
  }

  getRedirectUrl(url) {
    return this._httpWrapper.get(url);
  }
}
