import { Component, OnInit, Input, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
declare let d3: any;
declare let colorbrewer: any;

import * as _ from 'lodash';
import {DialogBoxComponent} from '../../common/components/dialog-box/dialog-box.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-d3-flowchart-sanky',
  templateUrl: './d3-flowchart-sanky.component.html',
  styleUrls: ['d3-flowchart-sanky.component.css']
})
export class D3FlowchartSankyComponent implements OnInit {
    @Input() widgetData;
    data;
    @ViewChild('sankyChartNewRef') chartElement: ElementRef;

    constructor(public dialog: MatDialog) {}

    ngOnInit() {
      this.data = this.widgetData.data;
      this.loadGraph();       
    }

    flowchart;
    svg;
    fitness_color;
    size_color;
    height_color;
    format;
    path;
    link_color;
    loadGraph() {
      var evolution_height = 600,
        evolution_width = 1500;

      var margin = { top: 5, right: 5, bottom: 8, left: 5 },
        width = evolution_width - margin.left - margin.right,
        height = evolution_height - margin.top - margin.bottom;

      // var format_number = d3.format("4.2f");
      /* this.format = function( d ) {
           var str  = "Fitness: " + format_number( d.fitness );
           str += " ,size: " + d.size + "(" + format_number( d.scaled_size ) + ")";
           str += " ,height: " + d.height + "(" + format_number( d.scaled_height ) + ")";
           return  str;
       };*/
      this.format = function(d) {
        var str = "Fitness: " + (d.fitness / 100).toFixed(2);
        /*str += " ,size: " + d.size + "(" + d.scaled_size  + ")";
        str += " ,height: " + d.height + "(" + d.scaled_height  + ")";*/
        return str;
      };
      var color = d3.scale.category20();

      this.svg = d3.select(this.chartElement.nativeElement).append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("class", "aligned")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      this.flowchart = d3.flowchart()
        .node_width(10)
        .node_height(4)
        .size([width, height]);
      this.flowchart.sort_type('unsorted');
      this.flowchart.scale_type('unscaled');

      this.path = this.flowchart.link();

      this.fitness_color = null,
        this.size_color = null,
        this.height_color = null;
      this.link_color = d3.scale.ordinal()
        .domain([-1, 0, 1, 2]) // elite, mutation, crossover, reproduce
        // .range( colorbrewer.Set1[4] );
        .range(["red", "blue", "green", "yellow"]);
      //             d3.request("http://karstenahnert.com/gp/evol.json")
      // .header("Content-Type", "application/json")
      // .post(function(data) {
      //    console.log(data);
      // })
      //d3.json( "http://karstenahnert.com/gp/evol.json" , function( error, data ) {

      // initialize the flowchart, create the graph
      this.flowchart.nodes(this.data.nodes)
        .links(this.data.links)
        .init();

      /*var i = 0,
        max_size = 0,
        max_height = 0;
      this.flowchart.nodes().forEach(function(node) {
        if (node.size > max_size) { max_size = node.size; }
        if (node.height > max_height) { max_height = node.height; }
      });
      this.flowchart.nodes().forEach(function(node) {
        node.scaled_size = 1.0 - node.size / max_size;
        node.scaled_height = 1.0 - node.height / max_height;
      });*/



      var color_range = colorbrewer.RdYlGn[6];
      var color_range_fitness = color_range.slice(0); // clone array (deep copy)
      color_range_fitness.reverse();
      // color_range = d3.scale.category20c()
      //     .domain( range1(20) )
      //     .range();
      this.fitness_color = d3.scale.quantile()
        .domain(this.data.nodes.map(function(n) { return n.fitness; }))
        .range(color_range_fitness);
/*      this.size_color = d3.scale.quantile()
        .domain(this.data.nodes.map(function(n) { return n.scaled_size; }))
        .range(color_range);
      this.height_color = d3.scale.quantile()
        .domain(this.data.nodes.map(function(n) { return n.scaled_height; }))
        .range(color_range);*/

      this.update_visualization();

      var d = this.flowchart.nodes()[this.flowchart.nodes().length - 20];

      this.svg.selectAll("path.link.ancestor-of-" + d.key)
        .classed("static-link-selected", true);

      this.svg.selectAll("g.node.ancestor-of-" + d.key + " rect")
        .classed("static-node-selected", true);
      //} );            
    }

    update_visualization() {
      // remove all existing elements
      this.svg.selectAll("g").remove();

      // calculate the node positions
      this.flowchart.layout();

      // create nodes, hence a group for each node
      var node = this.svg.append("g").selectAll(".node")
        .data(this.flowchart.nodes())
        .enter().append("g")
        .attr("class", function(d) {
          var str = "node";
          d.ancestor_of.forEach(function(d) { str += " ancestor-of-" + d; });
          return str;
        })
        .attr("transform", function(d) {
          // console.log(d.x, d.y);
          return "translate(" + d.x + "," + d.y + ")";
        });

      // append a rect to each node
      var rect = node.append("rect")
        .attr("height", function(d) { return d.dy; })
        .attr("width", this.flowchart.node_width())
        .style("fill", this.get_node_color())
        .on("mouseover", (d) => {

          this.svg.selectAll("path.link.ancestor-of-" + d.key)
            .classed("link-selected", true);

          this.svg.selectAll("g.node.ancestor-of-" + d.key + " rect")
            .classed("node-selected", true);
        })
        .on("mouseout", (d) => {
          this.svg.selectAll("path.link.ancestor-of-" + d.key)
            .classed("link-selected", false);

          this.svg.selectAll("g.node.ancestor-of-" + d.key + " rect")
            .classed("node-selected", false);
        })
        .on("mousedown", (d) => {
          this.svg.selectAll("path.link")
            .classed("static-link-selected", false);

          this.svg.selectAll("g.node rect")
            .classed("static-node-selected", false);

          this.svg.selectAll("path.link.ancestor-of-" + d.key)
            .classed("static-link-selected", true);

          this.svg.selectAll("g.node.ancestor-of-" + d.key + " rect")
            .classed("static-node-selected", true);
        })
        .on("click", () => {
          // alert("rect");
          this.openDialog()
          d3.event.stopPropagation();
        })
        .append("title")
        .text((d) => { return this.format(d); });

      // create links
      var link = this.svg.append("g").selectAll(".link")
        .data(this.flowchart.links())
        .enter().append("path")
        .attr("class", function(d) {
          var str = "";
          if (d.op == -1) { str = "link link-strong"; } else { str = "link link-normal"; }
          d.ancestor_of.forEach(function(d) { str += " ancestor-of-" + d; });
          return str;
        })
        .attr("d", this.path)
        .style("stroke-width", function(d) { return Math.max(1, d.dy); })
        .style("stroke", (d) => { return this.link_color(d.op); });
    }


    get_node_color() {
      let color_type = 'fitness';

      if (color_type == "fitness") {
        return (d) => { return this.fitness_color(d.fitness); }
      }
      if (color_type == "size") {
        return (d) => { return this.size_color(d.scaled_size); }
      }
      if (color_type == "height") {
        return (d) => { return this.height_color(d.scaled_height); }
      }
    }

    openDialog() {
        const dialogRef = this.dialog.open(DialogBoxComponent, {
           data: {dataUrl: this.data.url}
        });
     
        dialogRef.afterClosed().subscribe(result => {
           // console.log('The dialog was closed');
         });
    }

}
