import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '../shared/auth.service';
import { GAIndex, UserType, GAUserType } from 'src/app/shared/enum/enum';

// Learn More: https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private _ga = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _authService: AuthService
  ) {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.google_analytics_ID;
    this.document.head.prepend(script);

    window['dataLayer'] = window['dataLayer'] || [];
    this._ga = function () {
      window['dataLayer'].push(arguments);
    };
    // this._ga('config', environment.google_analytics_ID);
    this._ga('js', new Date());
  }

  public notifyPageView(route) {
    const config = {
      'page_title': `${this.document.title} | ${route.slice(route.lastIndexOf('/') + 1)}`,
      'page_path': route
    };
    this._ga('config', environment.google_analytics_ID, config);
  }

  public triggerEvent(action, category = 'default', label = 'default', data = null) {
    const userData: any = this._authService.getUser();
    const config: any = {
      'event_category': category,
      'event_label': label,
    };
    if (userData) {
      if (userData.type === UserType.ZS) {
        config[GAIndex.EMAIL_ID] = userData.emailId;
        config[GAIndex.CAPABILITY_GROUP] = userData.capabilityGroup;
        config[GAIndex.DEPARTMENT] = userData.department;
      }
      config[GAIndex.NAME] = userData.name;
      // tslint:disable-next-line: max-line-length
      config[GAIndex.USER_TYPE] = (userData.type === UserType.ADMIN) ? GAUserType.ADMIN : ((userData.type === UserType.ZS) ? GAUserType.ZS : GAUserType.NON_ZS);
    }
    // console.log('event', action, {...config, ...data}, userData);
    this._ga('event', action, {...config, ...data});
  }
}
