import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserManagementComponent } from './user-management.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuardService } from '../shared/auth-guard.service';
import { AdminAuthGuardService } from '../shared/admin-auth-guard.service';


const routes: Routes = [{
  path: 'admin',
  component: UserManagementComponent,
  children: [
    { path: '', component: AdminComponent},
    { path: '**', redirectTo: '' }
  ],
  canActivate: [AuthGuardService, AdminAuthGuardService]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
