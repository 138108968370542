// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google_analytics_ID: 'UA-177518232-1',
  token_refresh_time: 1000 * 60 * 60 * 3.83,
  ssoSupported: true,
  // api_url: 'https://x2og90mqc7.execute-api.us-east-1.amazonaws.com/prod',
  // api_url: 'https://upim9c3gpb.execute-api.us-east-1.amazonaws.com/Dev',
  app_URL: 'http://localhost:4200',
  api_url:"https://aigallery-api.demo.zsservices.com/prod", //ZS account

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
