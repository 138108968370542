import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material';

@Component({
  selector: 'us-chart-pop-up',
  templateUrl: 'us-chart-pop-up.component.html'
})
export class USChartPopUpComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<USChartPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    displayedColumns: string[] = ['CUSTOMER_NUMBER', 'product1_nrx', 'product1_trx','product1_nbrx','month'];
  	
  dataSource;
  state;
  ngOnInit() {
      this.state = this.data.state;
      this.dataSource = new MatTableDataSource(this.data.preferences);
	}
  
  onNoClick(): void {
	   	this.dialogRef.close(this.data);
	}
}