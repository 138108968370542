

import { Component, OnInit, Renderer2, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { GalleryService } from '../gallery.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { VisitReasonOverlayComponent } from '../visit-reason-overlay/visit-reason-overlay.component';
import { AuthService } from 'src/app/shared/auth.service';
import { UserType } from 'src/app/shared/enum/enum';
import _ from 'lodash';
import { TabSettingsOverlayComponent } from '../tab-settings-overlay/tab-settings-overlay.component';
import { TileSettingsOverlayComponent } from '../tile-settings-overlay/tile-settings-overlay.component';
import { GroupSettingsOverlayComponent } from '../group-settings-overlay/group-settings-overlay.component';
import { CdkDragDrop, CdkDragMove, moveItemInArray } from '@angular/cdk/drag-drop';
import { forkJoin, Subscription } from 'rxjs';
import { ConfirmationOverlayComponent } from '../confirmation-overlay/confirmation-overlay.component';
import { DataShareService } from 'src/app/shared/data-share.service';


@Component({
	selector: 'app-gallery-list',
	templateUrl: './gallery-list.component.html'
})
export class GalleryListComponent implements OnInit, OnDestroy {

	public storyList;
	public filerString;
	public categoryList;
	public originalStoryList;
	public selectedCategory;
	public isDataLoading = true;
	public categoryFilerString;
	public visitReasonOverlay = null;

	// new
	public storyClass = '';
	public isInteractive = false;
	private _unsubscribeResize;
	public currentDate = (new Date()).getTime();

	// user-details
	isAdmin = false;

	// tile config
	public isQuickSettingsActive = false;
	public categoryListNew;
	public updatedTiles = {
		groupIds: [],
		storyIds: []
	}

	public changeDetected = false;

	private _closeOverlay: {
		closeTileSetting: Function,
		closeCategorySettings: Function,
		closeGroupSettings: Function
	} = {
			closeTileSetting: null,
			closeCategorySettings: null,
			closeGroupSettings: null
		};
	private _unsubscribeObservables: {
		onGetCategoryList: Subscription,
		onCreateCategory: Subscription,
		onDeleteCategory: Subscription,
		onUpdateCategory: Subscription,
		onCreateGroup: Subscription,
		onUpdateSettings: Subscription
	} = {
			onGetCategoryList: null,
			onCreateCategory: null,
			onDeleteCategory: null,
			onUpdateCategory: null,
			onCreateGroup: null,
			onUpdateSettings: null
		}
	public redirectFlag = true;
	public windowObj:any;
	public isEditMode = false;
	@HostListener('window:beforeunload', ['$event']) unloadHandler(event: Event) {
		if (this.changeDetected) {
			event.returnValue = true;
		  }
	}

	constructor(
		private galleryService: GalleryService,
		private route: ActivatedRoute,
		private router: Router,
		private dataShareService: DataShareService,
		private _gaService: GoogleAnalyticsService,
		private dialog: MatDialog,
		private _authService: AuthService,
		private _renderer: Renderer2,
		private _snackBar: MatSnackBar
	) {
		// this.getCategoryList(); // TODO: Remove post data refresh from backend
		// this.galleryService.getTileList().subscribe(res => {
		// 	console.log(res);
		// })
		let isOverlayDisplayed = false;
		const unSubscribUserInfo = this._authService.onUserInfoUpate.subscribe((data) => {
			// this.isAdmin = data.isAdmin;
			const _purpose = localStorage.getItem('purpose');
			if (!_purpose && !isOverlayDisplayed && data && data.type === UserType.ZS) {
				isOverlayDisplayed = true;
				this.visitReasonOverlay = this.OpenVisitReasonOverlay(data);
			}
			if (unSubscribUserInfo) {
				unSubscribUserInfo.unsubscribe();
			}
		});
	}

	ngOnInit() {
		// existing
		if (this.galleryService.getDataSetStatus()) {
			this.isDataLoading = false;
			this.categoryList = this.galleryService.getCategoryList();
			// this.categoryList = [...this.categoryList, ...this.categoryList];
			this.subscribeToParamsChange();
		} else {
			this.isDataLoading = true;
			this.galleryService.storiesChanged
				.subscribe(
					(stories) => {
						// console.log(this.route.snapshot.queryParams)
						this.subscribeToParamsChange();
						const queryParams = this.route.snapshot.queryParams;
						this.isDataLoading = false;
						this.categoryList = this.galleryService.getCategoryList();
						// this.categoryList = [...this.categoryList, ...this.categoryList];
						if (queryParams && queryParams.category) {
							// updated
							this.storyList = this.galleryService.parseStoryList(this.galleryService.getStoryListByCategory(queryParams), window.innerWidth, false, true, this.isQuickSettingsActive);
							this.storyClass = this.galleryService.getClassName();
							this.originalStoryList = this.storyList.slice();
						} else {
							// updated
							this.storyList = this.galleryService.parseStoryList(this.galleryService.getStoryListFromLocal(), window.innerWidth, false, true, this.isQuickSettingsActive);
							this.storyClass = this.galleryService.getClassName();
							this.originalStoryList = this.storyList.slice();
						}
					}
				);
		}
		// console.log(this.route.snapshot.queryParams);
		// console.log(this.route.snapshot.fragment);
		// console.log(this.storyList)

		// new
		this._unsubscribeResize = this._renderer.listen(window, 'resize', _.debounce(() => {
			const layoutValueUpdated = this.galleryService.updateGalleryLayout(window.innerWidth);
			if (layoutValueUpdated) {
				// this.storyList = this.galleryService.parseStoryList(this.galleryService.getStoryListFromLocal(), window.innerWidth, false, false);
				// this.storyClass = this.galleryService.getClassName();
				// this.originalStoryList = this.storyList.slice();
				// this.onFilterStoryList();
				this.onCategorySelect(this.selectedCategory, this.isInteractive);
			}
		}, 700));

		this._unsubscribeObservables.onUpdateSettings = this.dataShareService.onCustomDashboardEvent.subscribe(res => {
			switch (res.event) {
				case 'enableSettings':
					this.enableQuickSettings();
					break;
				case 'saveSettings':
					this.saveSettings();
					break;
				case 'cancelSettings':
					this.cancelChanges();
					break;
			
				default:
					break;
			}
		});
	}

	onStoryClick(story) {
		// TODO:SPM
		// console.log(story.url);
		if (story.isNotAvailable && story.url !== 'appian_low_code_automation') {
			return;
		}

		if (story.viewType === 'business') {
			this.router.navigate(['story', story.url], { relativeTo: this.route });
		} else {
			this.router.navigate(['story', story.url], { relativeTo: this.route });
		}
	}

	// onFilterStoryList() {
	//     // console.log(this.selectedCategory);
	//     let filteredStories: any[];
	//     if (!this.selectedCategory) {
	//         this.storyList = this.galleryService.parseStoryList(this.originalStoryList.slice(), window.innerWidth, false, false);
	//     } else if (this.selectedCategory.toLowerCase() === 'all') {
	//         this.storyList = this.galleryService.parseStoryList(this.originalStoryList.slice(), window.innerWidth, false, false);
	//     } else {
	//         filteredStories = this.originalStoryList.filter((story) =>
	//             ((story.category.toLowerCase() === this.selectedCategory.toLowerCase())));
	//         this.storyList = this.galleryService.parseStoryList(filteredStories, window.innerWidth, true, false);
	//     }
	//     this.storyClass = this.galleryService.getClassName();
	// }

	clearFilterSelected() {
		this.selectedCategory = 'All';
		this.isInteractive = false;
		// this.storyList = this.galleryService.parseStoryList(this.originalStoryList.slice(), window.innerWidth, false, false);
		this.filerString = '';
		this.onCategorySelect(this.selectedCategory, this.isInteractive);
	}

	// existing functions
	subscribeToParamsChange() {
		this.route.queryParams
			.subscribe(
				(queryParams: Params) => {
					if (queryParams && queryParams.category) {
						this.storyList = this.galleryService.parseStoryList(this.galleryService.getStoryListByCategory(queryParams), window.innerWidth, false, false);
						this.originalStoryList = this.storyList.slice();
					} else {
						this.storyList = this.galleryService.parseStoryList(this.galleryService.getStoryListFromLocal(), window.innerWidth, false, false);
						this.originalStoryList = this.storyList.slice();
					}
					this.storyClass = this.galleryService.getClassName();
				}
			);
	}

	onClickRedirect(story) {
		// TODO:SPM change in json
		if (story.url === 'field_coaching') {
			this.router.navigate(['story-telling-page', story.url], { relativeTo: this.route });
			return;
		}
		if (story.url === 'virtual_rep_trainer') {
			this.router.navigate(['story-telling-page', story.url], { relativeTo: this.route });
			return;
		}
		/* if(story.url === 'intelligentTrial_data_DQM'){
				this.router.navigate(['story-telling-page', story.url], {relativeTo: this.route});
				return;
		} */
		if(story.url === 'atlas-intelligence-kraft') {
			this.router.navigate(['story-telling-page', story.url], {relativeTo: this.route});
			return
		}
		// TODO:SPM change in json
		if (story.isNotAvailable) {
			return;
		}

		this._gaService.triggerEvent('View', 'Usecase', story.title);
		if (story.viewType === 'business') {
			this.router.navigate(['story-telling-page', story.url], { relativeTo: this.route });
		} else if (story.viewType == 'business_application') {
			let that = this;
			this.isDataLoading = true;

			// this.windowObj = window.open("https://www.google.com");
			// this.timer = setInterval(that.checkChild, 500);
			this.galleryService.getRedirectUrl(story.endpoint).subscribe(
				(res:any) => {
					if (res.data.url) {
						this.isDataLoading = false;
						this.windowObj = window.open(res.data.url);
					}
				}
			)
		}
		else {
			this.router.navigate(['story-page', story.url], { relativeTo: this.route });
		}
		// window.location.reload();
	}

	// checkChild = ()=> {
	// 	if (this.windowObj.closed) {
	// 		clearInterval(this.timerObj);
	// 	}
	// }
	

	onCategorySelect($event, isInteractive = false) {
		if (this.isQuickSettingsActive) {
			return;
		}
		this.categoryFilerString = $event;
		this.isInteractive = isInteractive;
		this.selectedCategory = $event;
		if (isInteractive) {
			this._gaService.triggerEvent('Click', 'Tab Filters', 'Interactive');
			const filteredStories = this.originalStoryList.filter((story) => story.isInteractive);
			this.storyList = this.galleryService.parseStoryList(filteredStories, window.innerWidth, true, false);
		} else if ($event) {
			// tslint:disable-next-line: max-line-length
			this._gaService.triggerEvent('Click', 'Tab Filters', $event);
			// const filteredStories = this.originalStoryList.filter((story) => ((story.category.toLowerCase() === $event.toLowerCase() ? true : false)));
			let filteredStories = this.originalStoryList
			.filter((tile) => {
				if (tile.type == 'story' && (this.isQuickSettingsActive || !tile.isHidden)) {
					return (tile.categoryId.toLowerCase() === $event.toLowerCase() ? true : false)
				} else if (tile.type == 'group' && (this.isQuickSettingsActive || !tile.isHidden)) {
					let storyList = tile.stories.filter(story => (story.categoryId.toLowerCase() === $event.toLowerCase() && (this.isQuickSettingsActive || !story.isHidden)) ? true : false)
					return storyList.length ? true : false;
				}
			});

			filteredStories = this.createDeepCopy(filteredStories)
				.map(tile => {
					if (tile.type == 'group') {
						tile.stories = tile.stories.filter(story => (story.categoryId.toLowerCase() === $event.toLowerCase() ? true : false))
					}
					return tile;
				});
			this.storyList = this.galleryService.parseStoryList(filteredStories, window.innerWidth, true, false);
		} else {
			this.storyList = this.galleryService.parseStoryList((this.originalStoryList && this.originalStoryList.slice()) || [], window.innerWidth, false, false);
		}
		this.storyClass = this.galleryService.getClassName();
	}

	createDeepCopy(object) {
		return object ? JSON.parse(JSON.stringify(object)) : object;
	}

	public onSearchFocusOut() {
		this._gaService.triggerEvent('Search', 'Wildcard Search', this.filerString);
	}

	public OpenVisitReasonOverlay(userInfo) {
		const dialogRef = this.dialog.open(VisitReasonOverlayComponent, {
			disableClose: true,
			width: '600px',
			panelClass: 'visit-reason-overlay',
			data: { userInfo }
		});
		// const cdkOverlayContainer = document.getElementsByClassName('cdk-overlay-container');
		// const className = 'promo-video-popup';

		dialogRef.afterClosed().subscribe((data) => {
			// console.log(data)
			if (data) {
				localStorage.setItem('purpose', window.btoa(JSON.stringify(data)));
			}
			// this.renderer.addClass(cdkOverlayContainer[0], className);
		});

		dialogRef.afterOpened().subscribe(() => {
			// this.renderer.removeClass(cdkOverlayContainer[0], className);
		});
		return function () {
			dialogRef.close();
		}
	}

	private backupStoryList = [];
	private backupCategoryList = [];

	getBackupStoryList() {
		this.backupStoryList = (JSON.parse(JSON.stringify(this.galleryService.getStoryListFromLocal())) as Array<any>);
		this.backupCategoryList = (JSON.parse(JSON.stringify(this.galleryService.getCategoryList())) as Array<any>).map((e, i) => {
			return {
				categoryId: e.categoryId,
				sequenceId: i
			}
		})
	}

	updateStoryCategories(stories, key: 'storyList' | 'backupStoryList') {
		const list = this.galleryService.getCategoryList();
		stories.forEach(story => {
			if (story.groupId === null) {
				const oldStory = this[key].find(e => e.type === 'story' && e.id === story.storyId);
				const cat = list.find(e => e.categoryId === story.categoryId);
				oldStory.categoryId = story.categoryId
				oldStory.categoryClass = cat.categoryClass;
				oldStory.category = cat.name;
			} else {
				const oldStory = this[key].find(e => e.type === 'group' && e.id === story.groupId).stories.find(e => e.id === story.storyId);
				const cat = list.find(e => e.categoryId === story.categoryId);
				oldStory.categoryId = story.categoryId
				oldStory.categoryClass = cat.categoryClass;
				oldStory.category = cat.name;
			}
		});
	}

	getStoriesUnderCategories(category) {
		const result = [];
		const isRequestedStory = (story, groupId) => {
			if (story.categoryId === category.categoryId) {
				result.push({
					title: story.title,
					storyId: story.id,
					categoryId: category.categoryId,
					groupId
				});
			}
		}

		this.storyList.forEach(tile => {
			if (tile.type === 'group') {
				tile.stories.forEach(story => {
					isRequestedStory(story, story.groupId);
				});
			} else {
				isRequestedStory(tile, null);
			}
		});
		return result;
	}

	openTabSettings($e: MouseEvent, category, isEdit = false) {
		$e.stopPropagation();
		if (this.categoryList.length >= 10) {
			this.showToastMessage('Reached maximum category limit. Please edit/delete existing categories');
			return;
		}
		const data: any = {
			selected: category || {
				name: '',
				color: '',
			},
			list: this.categoryList,
			isEdit: isEdit,
			stories: isEdit ? this.getStoriesUnderCategories(category) : []
		}
		const dialogRef = this.dialog.open(TabSettingsOverlayComponent, {
			data,
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				if (isEdit) {
					this.galleryService.updateInCategoryList(res.category, res.category.categoryId);
					const oldCategoryIndex = this.backupCategoryList.findIndex(e => e.categoryId === category.categoryId);
					if (oldCategoryIndex > -1) {
						this.backupCategoryList.splice(oldCategoryIndex, 1, JSON.parse(JSON.stringify(res.category)));
					}
					this.updateStoryCategories(res.stories, 'backupStoryList');
					this.updateStoryCategories(res.stories, 'storyList');
					this.galleryService.updateLocalStoryList(this.storyList);
				} else {
					this.galleryService.addToCategoryList(res.category);
					this.backupCategoryList.push(res.category);
					this.galleryService.updateLocalStoryList(this.storyList);
				}
			} else {
			}
		})
		this._closeOverlay.closeCategorySettings = function () {
			dialogRef.close();
		}
	}

	updateTileBackup(story, updatedStory, key: 'storyList' | 'backupStoryList') {
		if (story.groupId === null) {
			if (updatedStory.groupId === null) {
				// no group --> no group
				this[key][this[key].findIndex(story => story.type === 'story' && story.id === updatedStory.id)] = { ...updatedStory };
			} else {
				// no group --> group
				updatedStory.isGrouped = true;
				const storyIndex = this[key].findIndex(story => story.type === 'story' && story.id === updatedStory.id);
				const groupIndex = this[key].findIndex(story => story.type === 'group' && story.id === updatedStory.groupId);
				this[key][groupIndex].stories.push({ ...updatedStory });
				this[key].splice(storyIndex, 1);
				if (key === 'storyList') {
					this.galleryService.updateLocalStoryList(this[key]);
				}
			}
		} else {
			if (story.groupId === updatedStory.groupId) {
				// group --> same group
				const groupIndex = this[key].findIndex(story => story.type === 'group' && story.id === updatedStory.groupId);
				const storyIndex = this[key][groupIndex].stories.findIndex(story => story.id === updatedStory.id);
				this[key][groupIndex].stories[storyIndex] = { ...updatedStory }
			} else if (updatedStory.groupId !== null && story.groupId !== updatedStory.groupId) {
				// group --> another group
				const groupIndex = this[key].findIndex(s => s.type === 'group' && s.id === story.groupId);
				const storyIndex = this[key][groupIndex].stories.findIndex(s => s.id === story.id);
				this[key][groupIndex].stories.splice(storyIndex, 1);
				const newGroupIndex = this[key].findIndex(story => story.type === 'group' && story.id === updatedStory.groupId);
				this[key][newGroupIndex].stories.push({ ...updatedStory });
				if (key === 'storyList') {
					this.galleryService.updateLocalStoryList(this[key]);
				}
			} else {
				// group --> no group
				updatedStory.isGrouped = false;
				const groupIndex = this[key].findIndex(s => s.type === 'group' && s.id === story.groupId);
				const storyIndex = this[key][groupIndex].stories.findIndex(s => s.id === story.id);
				this[key][groupIndex].stories.splice(storyIndex, 1);
				this[key].push({ ...updatedStory });
				if (key === 'storyList') {
					this.galleryService.updateLocalStoryList(this[key]);
				}
			}
		}
	}

	openTileSettings($e: MouseEvent, story) {
		$e.stopPropagation();
		const dialogRef = this.dialog.open(TileSettingsOverlayComponent, {
			data: {
				story: JSON.parse(JSON.stringify(story)),
				groups: this.galleryService.getGroupList(),
				categories: this.categoryList,
			}
		});
		dialogRef.afterClosed().subscribe(updatedStory => {
			if (updatedStory) {
				this.updateTileBackup(story, updatedStory, 'backupStoryList');
				this.updateTileBackup(story, updatedStory, 'storyList');
				this.validateAndDeleteGroup();
				this.updateSaveDisable();
				this.storyList = [...JSON.parse(JSON.stringify(this.storyList.slice()))];
			} else {
				// handle close
			}
		});
		this._closeOverlay.closeTileSetting = function () {
			dialogRef.close();
		}
	}

	fetchStoriesFromGroup(groupId = null) {
		const self = [];
		const other = [];
		this.storyList.forEach(e => {
			if (e.type === 'group') {
				if (e.id === groupId) {
					e.stories.forEach(story => {
						if (story.isGrouped) {
							self.push({
								isSelected: true,
								isAvailable: true,
								title: story.title,
								id: story.id,
								groupId: e.id
							});
						} else {
							other.push({
								isSelected: false,
								isAvailable: true,
								title: story.title,
								id: story.id,
								groupId: e.id
							});	
						}
					});
				} else {
					e.stories.forEach(story => {
						other.push({
							isSelected: false,
							isAvailable: story.isGrouped ? false : true,
							title: story.title,
							id: story.id,
							groupId: e.id
						})
					});
				}
			} else {
				other.push({
					isSelected: false,
					isAvailable: true,
					title: e.title,
					id: e.id,
					groupId: null
				})
			}
		});

		other.sort((a, b) =>  a.title.localeCompare(b.title))
		return {
			other: [...other.filter(e => e.isAvailable), ...other.filter(e => !e.isAvailable)],
			existing: self
		}
	}

	updateGroupBackup(data, updatedGroupData, key: 'storyList' | 'backupStoryList') {
		const setData = () => {
			let backupGroup;
			if (updatedGroupData.type !== 'create') {
				const backupGroupIndex = this[key].findIndex(e => e.type === 'group' && e.id === data.id);
				backupGroup = this[key][backupGroupIndex];
			}
			return {
				data: key === 'storyList' ? data : backupGroup
			}
		}

		const refGroup = setData();

		const getNewStories = (stories) => {
			const result = [];
			stories = stories.map(e => e.storyId);
			stories.forEach(e => {
				const storyIndex = this[key].findIndex(s => s.type === 'story' && s.id === e);
				if (storyIndex > -1) {
					this[key][storyIndex].groupId = updatedGroupData.id;
					this[key][storyIndex].isGrouped = true;
					result.push(this[key][storyIndex]);
					this[key].splice(storyIndex, 1);
				}
			});
			return result
		}

		const removeStories = (stories, storyKey = 'storyId') => {
			stories = stories.map(e => e[storyKey]);
			stories.forEach(e => {
				const storyIndex = refGroup.data.stories.findIndex(s => s.type === 'story' && s.id === e);
				if (storyIndex > -1) {
					refGroup.data.stories[storyIndex].groupId = null;
					this[key].push(refGroup.data.stories[storyIndex]);
					refGroup.data.stories.splice(storyIndex, 1);
				}
			});
		}

		const transferStories = (stories) => {
			const result = [];
			stories.forEach(story => {
				let oldGroupIndex, oldStoryIndex;
				oldGroupIndex = this[key].findIndex(g => g.type === 'group' && g.id === story.groupId);
				if (oldGroupIndex > -1) {
					oldStoryIndex = this[key][oldGroupIndex].stories.findIndex(s => s.type === 'story' && s.id === story.storyId);
				}
				if ( oldGroupIndex > -1 && oldStoryIndex > -1 ) {
					this[key][oldGroupIndex].stories[oldStoryIndex].isGrouped = true;
					this[key][oldGroupIndex].stories[oldStoryIndex].groupId = story.groupId;
					result.push(this[key][oldGroupIndex].stories[oldStoryIndex]);
					this[key][oldGroupIndex].stories.splice(oldStoryIndex, 1);
				}
			});

			return result;
		}

		if (updatedGroupData.type === 'create') {
			const obj = {
				isHidden: updatedGroupData.isHidden,
				name: updatedGroupData.name,
				id: updatedGroupData.id,
				color: updatedGroupData.color,
				stories: [...getNewStories(updatedGroupData.stories.add), ...getNewStories(updatedGroupData.stories.transfer)],
				type: 'group',
				isGroup: true,
				tags: [
					"#machine-learning",
					"#AI",
					"#gnome",
					"#nlp",
					"#Segmentation"
				],
				category: "AI in Personalization"
			};
			this[key].unshift(obj);
			if (key === 'storyList') {
				this.galleryService.updateLocalStoryList(this[key].slice());
				this.galleryService.addToGroupList({
					name: obj.name,
					id: obj.id,
					color: obj.color
				});
			}
		} else if (updatedGroupData.type === 'update') {
			refGroup.data.color = updatedGroupData.color;
			refGroup.data.name = updatedGroupData.name;
			refGroup.data.isHidden = updatedGroupData.isHidden;
			removeStories(updatedGroupData.stories.remove);
			refGroup.data.stories = [...refGroup.data.stories, ...getNewStories(updatedGroupData.stories.add), ...transferStories(updatedGroupData.stories.transfer)]
			if (key === 'storyList') {
				this.galleryService.updateLocalStoryList(this[key].slice());
			}
		} else if (updatedGroupData.type === 'delete') {
			removeStories(refGroup.data.stories, 'id');
			const groupIndex = this[key].findIndex(e => e.type === 'group' && e.id === refGroup.data.id);
			if (groupIndex > -1) {
				this[key].splice(groupIndex, 1);
				this.galleryService.removeFromGroupList(refGroup.data.id);
				if (key === 'storyList') {
					this.galleryService.updateLocalStoryList(this[key].slice());
				}
			}
		}
	}

	openGroupSettings(data) {
		if (!data && this.galleryService.getGroupList().length >= 6) {
			this.showToastMessage('Reached maximum group limit. Please edit/delete existing group');
			return
		}
		const dialogRef = this.dialog.open(GroupSettingsOverlayComponent, {
			data: {
				groupData: data,
				stories: this.fetchStoriesFromGroup(data ? data.id : null),
				groupList: this.galleryService.getGroupList()
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(updatedGroupData => {
			if (updatedGroupData) {
				this.updateGroupBackup(data, updatedGroupData, 'backupStoryList');
				this.updateGroupBackup(data, updatedGroupData, 'storyList');
				this.storyList = [...JSON.parse(JSON.stringify(this.storyList.slice()))];
				this.validateAndDeleteGroup();
				this.updateSaveDisable();
			} else {
				// handle close
			}
		});
		this._closeOverlay.closeGroupSettings = function () {
			dialogRef.close();
		}
	}

	updateTileGroupEditCount(isGroup: boolean, id: number, isAdd: boolean = true) {
		const key = isGroup ? 'groupIds' : 'storyIds';
		const index = this.updatedTiles[key].findIndex(e => e == id);
		if (isAdd) {
			index === -1 && this.updatedTiles[key].push(id);
		} else if (index > -1) {
			this.updatedTiles[key].splice(index, 1);
		}
	}

	deleteTab($e: MouseEvent, category) {
		$e.stopPropagation();
		if (this.getStoriesUnderCategories(category).length > 0) {
			this.showToastMessage('Please assign different category for each story associated with this category before deleting category.', true, 5000)
			return
		}
		const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
			data: {
				title: 'Delete Category',
				message: 'Are you sure you want to delete this category?'
			},
			panelClass: 'cdk-overlay-pane_modifier',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.galleryService.deleteCategory({ "categoryId": category.categoryId }).subscribe((res: any) => {
					this.showToastMessage(res.message, false);
					this.galleryService.removeFromCategoryList(category.categoryId);
					const oldCategoryIndex = this.backupCategoryList.findIndex(e => e.categoryId === category.categoryId);
					if (oldCategoryIndex > -1) {
						this.backupCategoryList.splice(oldCategoryIndex, 1);
					}
					this.galleryService.updateLocalStoryList(this.storyList.slice());
				}, (err) => {
					this.showToastMessage(err.error.message);
				})
			}
		});
	}

	showToastMessage(message, isError = true, duration = 3000) {
		this._snackBar.open(message, '', {
			duration,
			panelClass: isError ? 'error' : 'success',
			verticalPosition: 'top',
			horizontalPosition: 'right'
		})
	}

	toggleQuickOption($e: MouseEvent | null, story, option: 'isInteractive' | 'isHidden' | 'isNew' | 'isGrouped') {
		$e && $e.stopPropagation();
		story[option] = !story[option];
		this.updateSaveDisable();
		return
		// let oldStory;
		// if (story.type === 'story') {
		// 	if (story.groupId !== null) {
		// 		oldStory = this.backupStoryList.find(e => e.type === 'group' && e.id === story.groupId).stories.find(e => e.id === story.id)
		// 	} else {
		// 		oldStory = this.backupStoryList.find(e => e.type === 'story' && e.id === story.id);
		// 	}
		// }
		// if (oldStory) {
		// 	let isChanged = false;
		// 	const flags = ['isInteractive', 'isHidden', 'isNew', 'isGrouped']
		// 	for (let i = 0; i < flags.length; i++) {
		// 		story[flags[i]] = story[flags[i]] === undefined ? false : story[flags[i]]
		// 		if (oldStory[flags[i]] !== story[flags[i]]) {
		// 			isChanged = true;
		// 			this.updateTileGroupEditCount(false, story.title, true);
		// 			i += flags.length;
		// 		}
		// 	}
		// 	!isChanged && this.updateTileGroupEditCount(false, story.title, false);
		// }
	}

	toggleQuickOptionGroup($e: MouseEvent | null, group, option: 'isHidden') {
		$e && $e.stopPropagation();
		group[option] = !group[option];
		this.updateSaveDisable();
		return
		const oldGroup = this.backupStoryList.find(e => e.type === 'group' && e.id === group.id);
		if (oldGroup) {
			let isChanged = false;
			const flags = ['isHidden'];
			for (let i = 0; i < flags.length; i++) {
				group[flags[i]] = group[flags[i]] === undefined ? false : group[flags[i]]
				if (oldGroup[flags[i]] !== group[flags[i]]) {
					isChanged = true;
					this.updateTileGroupEditCount(true, group.id, true);
					i += flags.length;
				}
			}
			!isChanged && this.updateTileGroupEditCount(true, group.id, false);
		}
	}


	getDifference() {
		// const categoryDifference = this.getCategoryDifference(true);
		const result = [];
		const unGrouped = [];
		const updateTileResult = (tile, newTile) => {
			newTile.isVisible = !newTile.isHidden;
			tile.isVisible = !tile.isHidden;
			let counter = 0;
			const obj: any = {
				id: newTile.id,
				type: 'story'
			};
			['isVisible', 'isInteractive', 'isNew'].forEach(d => {
				if (newTile[d] !== tile[d]) {
					counter++;
					obj[d] = newTile[d];
				}
			});
			if (counter > 0) {
				result.push(obj)
			}
		}
		this.backupStoryList.forEach((tile, i) => {
			const newTile = this.storyList.find(e => e.type === tile.type && e.id === tile.id);
			if (tile.type === 'group') {
				if (tile.isHidden !== newTile.isHidden) {
					result.push({
						"id": newTile.id,
						"type": "group",
						"isVisible": !newTile.isHidden
					});
				}
				tile.stories.forEach((story, j) => {
					const newStory = newTile.stories[j];
					updateTileResult(story, newStory);
					if (!newStory.isGrouped) {
						const ungroupedStoryIndex = result.findIndex(e => e.type === 'story' && e.id === story.id);
						if (ungroupedStoryIndex > -1) {
							result[ungroupedStoryIndex].groupId = null
						} else {
							result.push({
								id: newTile.id,
								type: 'story',
								groupId: null
							})
						}
						unGrouped.push(JSON.parse(JSON.stringify(newStory)))
					}
				});
			} else {
				updateTileResult(tile, newTile);
			}
		});
		return {
			updated: result,
			unGrouped,
			// categoryDifference
		}
	}

	resetCounter() {
		this.updatedTiles.groupIds = [];
		this.updatedTiles.storyIds = [];
	}

	updateCategorySequence() {
		// const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
		// 	data: {
		// 		title: 'Confirmation',
		// 		message: 'Do you want to update category sequence?'
		// 	},
		// 	panelClass: 'cdk-overlay-pane_modifier',
		// 	disableClose: true
		// 	});
		// 	dialogRef.afterClosed().subscribe(e => {
		// 		if (e) {
					this.updateQuickSettings(true);
			// 	}		
			// });
	}

	updateTileQuickSettings() {
		// const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
		// 	data: {
		// 		title: 'Confirmation',
		// 		message: 'Are you want to save tile changes?'
		// 	},
		// 	panelClass: 'cdk-overlay-pane_modifier',
		// 	disableClose: true
		// 	});
		// 	dialogRef.afterClosed().subscribe(e => {
		// 		if (e) {
					this.updateQuickSettings(false);
			// 	}		
			// });
	}

	resetCategorySequence() {
		const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
			data: {
				title: 'Confirmation',
				message: 'Are you sure you want to discard the changes?'
			},
			panelClass: 'cdk-overlay-pane_modifier',
			disableClose: true
			});
			dialogRef.afterClosed().subscribe(e => {
				if (e) {
					const res = [];
					this.backupCategoryList.forEach(e => {
						const index = this.categoryList.findIndex(c => c.categoryId === e.categoryId);
						if (index > -1) {
							res.push(this.categoryList[index])
						}
					});
					this.isSequenceUpdated = false;
					this.categoryList = res;
				}		
			});
	}

	updateQuickSettings(isCategory) {
		this.dataShareService.onCustomDashboardEvent.next({
			event: 'toggleLoader',
			value: true
		});
		const changes = this.getDifference();
		const req = [];
		const fnSwitch = [];

		if (isCategory) {
			if (this.getCategoryDifference()) {
				req.push(this.galleryService.updateCategorySequence({
					"sequenceData": this.categoryList.map((e, i) => {
							return {
								categoryId: e.categoryId,
								sequenceOrder: i + 1
							}
						})
				}));
				fnSwitch.push('updateCategorySequence');
			}
		} else {
			if (changes.updated.length > 0) {
				req.push(this.galleryService.updateQuickSettings({
					"configurationId": null,
					"data": changes.updated
				}));
				fnSwitch.push('updateQuickSettings');
			}
		}

		const ungroupTiles = () => {
			if (changes.unGrouped.length > 0) {
				changes.unGrouped.forEach(e => {
					const updatedStory = JSON.parse(JSON.stringify(e));
					updatedStory.groupId = null;
					this.updateTileBackup(e, updatedStory, 'backupStoryList');
					this.updateTileBackup(e, updatedStory, 'storyList');
					fnSwitch.push('ungroupTile');
				});
				this.validateAndDeleteGroup();
				this.updateSaveDisable();
			} else {
				this.dataShareService.onCustomDashboardEvent.next({
					event: 'toggleQuickSettings',
					value: true
				});
				this.dataShareService.onCustomDashboardEvent.next({
					event: 'toggleSaveDisable',
					value: false
				});
				this.changeDetected = false;
				this.backupStoryList = JSON.parse(JSON.stringify(this.storyList));
			}
		}

		const updateCategorySequence = () => {
			this.galleryService.setAllCategories(JSON.parse(JSON.stringify(this.categoryList)))
		}

		forkJoin(req).subscribe(res => {
			this.showToastMessage(res[0].message, false);
			// this.dataShareService.onCustomDashboardEvent.next({
			// 	event: 'toggleQuickSettings',
			// 	value: false
			// });
			// this.dataShareService.updateCustomEvent({ event: 'removeClassToBody', data: 'edited-view' });
			res.forEach((e, i) => {
			switch (fnSwitch[i]) {
				case 'updateQuickSettings':
					ungroupTiles();
					this.resetCounter();
					break;
				case 'updateCategorySequence':
					this.isSequenceUpdated = false;
					updateCategorySequence();
				break;
				default:
				break;
			}
			})
			this.validateAndDeleteGroup();
			// this.changeDetected = false;
			// this.isQuickSettingsActive = false;
			// this.dataShareService.onCustomDashboardEvent.next({
			// 	event: 'toggleQuickSettings',
			// 	value: false
			// });
			// this.isQuickSettingsActive = false;
			this.dataShareService.onCustomDashboardEvent.next({
				event: 'toggleLoader',
				value: false
			});
			this.galleryService.updateLocalStoryList(this.storyList.slice());
		}, (err) => {
			this.showToastMessage("Somethings went wrong while saving changes.")
			this.dataShareService.onCustomDashboardEvent.next({
				event: 'toggleLoader',
				value: false
			});
		})
	}

	resetQuickSettings() {
		const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
			data: {
				title: 'Confirmation',
				message: 'Do you want to reset tile changes?'
			},
			panelClass: 'cdk-overlay-pane_modifier',
			disableClose: true
			});
			dialogRef.afterClosed().subscribe(e => {
				if (e) {
					this.enableQuickSettings();
				}		
			});
	}

	updateGroupSettings() {

	}

	saveSettings() {
		if (this.isQuickSettingsActive) {
			// const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
			// 	data: {
			// 	  title: 'Confirmation',
			// 	  message: 'Do you want to save tile changes?'
			// 	},
			// 	panelClass: 'cdk-overlay-pane_modifier',
			// 	disableClose: true
			//   });
			//   dialogRef.afterClosed().subscribe(e => {
			// 		if (e) {
						this.updateQuickSettings(false);
		// 			}		
		// 	  });
		}
	}

	cancelChanges() {
		const cancel = () => {
			this.isQuickSettingsActive = false;
			this.dataShareService.onCustomDashboardEvent.next({
				event: 'toggleQuickSettings',
				value: false
			});
			this.dataShareService.onCustomDashboardEvent.next({
				event: 'toggleSaveDisable',
				value: false
			});
			this.changeDetected = false;
			this.galleryService.updateLocalStoryList(this.backupStoryList.slice());
			this.resetCounter();
			this.dataShareService.updateCustomEvent({ event: 'removeClassToBody', data: 'edited-view' });
		}
		if (this.changeDetected || this.isSequenceUpdated) {
			const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
				data: {
				  title: 'Confirmation',
				  message: 'Do you want to discard unsaved changes?'
				},
				panelClass: 'cdk-overlay-pane_modifier',
				disableClose: true
			  });
			  dialogRef.afterClosed().subscribe(e => {
					if (e) {
						cancel();
					}		
			  });
		} else {
			cancel();
		}
	}

	enableQuickSettings() {
		this.isEditMode = true;
		this.dataShareService.updateCustomEvent({ event: 'addClassToBody', data: 'edited-view' });
		this.isQuickSettingsActive = true;
		this.dataShareService.onCustomDashboardEvent.next({
			event: 'toggleQuickSettings',
			value: true
		});
		this.dataShareService.onCustomDashboardEvent.next({
			event: 'toggleSaveDisable',
			value: false
		});
		this.changeDetected = false;
		this.getBackupStoryList();
		this.storyList = this.galleryService.parseStoryList(this.galleryService.getStoryListFromLocal(), window.innerWidth, false, true, true);
		this.categoryFilerString = '';
		this.onCategorySelect('');
	}

	public isSequenceUpdated = false;

	getCategoryDifference(isCount = true) {
		this.validateAndDeleteGroup();
		let isUpdated = false;
		this.backupCategoryList.forEach((e, i) => {
			const newIndex = this.categoryList.findIndex(c => c.categoryId === e.categoryId);
			if (i !== newIndex) {
				isUpdated = true;
			}
		});
		this.isSequenceUpdated = isUpdated;
		return isUpdated
	}
	
	validateAndDeleteGroup() {
		const result = [];
		const groups = [];
		this.storyList.forEach(e => {
			if (e.type === 'group' && e.stories.length === 0) {
				result.push({
					"groupId": e.id,
					"configurationId": null
				  });
				groups.push(e)
			}
		});
		if (result.length > 0) {
			forkJoin([...result.map(e => {
				return this.galleryService.deleteGroup(e);
			})]).subscribe((res: any[]) => {
				result.forEach((e, i) => {
					this.updateGroupBackup(groups[i], e, 'backupStoryList');
					this.updateGroupBackup(groups[i], e, 'storyList');
					this.galleryService.removeFromGroupList(e.groupId);
				})
				this.updateSaveDisable();
				this.backupStoryList = JSON.parse(JSON.stringify(this.storyList));
				// this.galleryService.updateLocalStoryList(this.storyList.slice());
			})
		}
	}

	deleteGroup(selectedGroup) {
		const payload = {
		  "groupId": selectedGroup.id,
		  "configurationId": null
		}
		const dialogRef = this.dialog.open(ConfirmationOverlayComponent, {
			data: {
			  title: 'Delete Confirmation',
			  message: 'Do you want to delete a group?'
			},
			panelClass: 'cdk-overlay-pane_modifier',
			disableClose: true
		  });
		  dialogRef.afterClosed().subscribe(e => {
				if (e) {
					this.galleryService.deleteGroup(payload).subscribe((res: any) => {
						this.showToastMessage(res.message, false);
						const obj = { type: 'delete', id: selectedGroup.id}
						this.updateGroupBackup(selectedGroup, obj, 'backupStoryList');
						this.updateGroupBackup(selectedGroup, obj, 'storyList');
						this.galleryService.removeFromGroupList(payload.groupId);
						this.updateSaveDisable();
					}, (err) => {
						this.showToastMessage(err.error.message);
					})
				}		
		  });

	  }

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.categoryList, event.previousIndex, event.currentIndex);
		this.updateCategorySaveDisable();
		// this.updateSaveDisable();
		// this.updateScrollLeft(0);
	}

	@ViewChild('categoryContainer') categoryContainer: ElementRef;
	timer;

	updateScrollLeft(value) {
		const wrapper = (this.categoryContainer.nativeElement as HTMLDivElement);
		const interval = () => {
			const interval = setInterval(() => {
				// console.log('setInterval', value);
				if (value > 0) {
					wrapper.scrollLeft = wrapper.scrollLeft + value >= wrapper.scrollWidth ? wrapper.scrollWidth : wrapper.scrollLeft + value;
				} else if (value < 0) {
					wrapper.scrollLeft = wrapper.scrollLeft + value <= 0 ? 0 : wrapper.scrollLeft + value;
				} else {
					clearInterval(interval);
				}
			}, 200);
			return interval
		}
		clearInterval(this.timer);
		if (value !== 0) {
			this.timer = interval();
		} else {
			clearInterval(this.timer);
		}
	}

	onDrag(e: CdkDragMove<any> | any) {
		// console.log(e.event.clientX)
		if (e.event.clientX >= 450) {
			this.updateScrollLeft(50)
		} else if (e.event.clientX <= 50) {
			this.updateScrollLeft(-50)
		} else {
			this.updateScrollLeft(0);
		}
	}

	// category related
	updateCategoryStoryCount() {
		this.categoryListNew.forEach(category => {
			category.storyCount = 0;
			this.storyList && this.storyList.forEach(story => {
				if (story.category === category.name) {
					category.storyCount++;
				}
			});
		});
	}

	getCategoryList() {
		this._unsubscribeObservables.onGetCategoryList = this.galleryService.getUpdatedCategoryList().subscribe((res: any) => {
			// this.showToastMessage(res.message, false);
			this.categoryListNew = res.data;
			this.updateCategoryStoryCount();
		}, (err) => {
			this.showToastMessage(err.error.message);
		})
	}

	createCategory(data: { name: string, color: string, sequenceOrder: string, userId: number }) {
		this._unsubscribeObservables.onCreateCategory = this.galleryService.createCategory(data).subscribe((res: any) => {
			this.showToastMessage(res.message, false);
		}, (err) => {
			this.showToastMessage(err.error.message);
		})
	}

	deleteCategory(data: { categoryId: number }) {
		this._unsubscribeObservables.onDeleteCategory = this.galleryService.deleteCategory(data).subscribe((res: any) => {
			this.showToastMessage(res.message, false);
		}, (err) => {
			this.showToastMessage(err.error.message);
		})
	}

	updateCategory(data: {
		name: string,
		color: string,
		categoryId: number,
		userId: number,
		stories: { storyId: number, categoryId: number }[]
	}) {
		this._unsubscribeObservables.onUpdateCategory = this.galleryService.updateCategory(data).subscribe((res: any) => {
			this.showToastMessage(res.message, false);
		}, (err) => {
			this.showToastMessage(err.error.message);

		});
		// Stories property can be empty if any of the story mappings has been changed. 
		// Stories will have an array of objects only for which category mapping has been changed. 
		// Object will have two properties: storyId, categoryId(new Id)
	}

	createGroup(data: {
		userId: number,
		configurationId: null,
		name: string,
		color: string,
		isVisible: boolean,
		stories: { storyId: number }[]
	}) {
		this._unsubscribeObservables.onCreateGroup = this.galleryService.createGroup(data).subscribe((res: any) => {
			// console.log(res);
			this.showToastMessage(res.message, false);
		}, (err) => {
			this.showToastMessage(err.error.message);
		})
	}

	updateSaveDisable() {
		const output = this.getDifference();
		this.changeDetected = output.updated.length > 0 || output.unGrouped.length > 0; //|| output.categoryDifference;
		this.dataShareService.onCustomDashboardEvent.next({
			event: 'toggleSaveDisable',
			value: this.changeDetected
		});
	}

	updateCategorySaveDisable() {
		this.isSequenceUpdated = this.getCategoryDifference(true);
	}

	confirm() {
		return {
		  isChangeDetected: this.changeDetected
		}
	}

	ngOnDestroy() {
		this.dataShareService.updateCustomEvent({ event: 'removeClassToBody', data: 'edited-view' });
		if (this._unsubscribeResize) {
			this._unsubscribeResize();
		}
		if (this.visitReasonOverlay) {
			this.visitReasonOverlay();
		}
		for (const key in this._closeOverlay) {
			if (Object.prototype.hasOwnProperty.call(this._closeOverlay, key)) {
				if (typeof this._closeOverlay[key] === 'function') {
					this._closeOverlay[key]();
				}
			}
		}
		for (const key in this._unsubscribeObservables) {
			if (Object.prototype.hasOwnProperty.call(this._unsubscribeObservables, key)) {
				if (this._unsubscribeObservables[key]) {
					this._unsubscribeObservables[key].unsubscribe();
				}
			}
		}
	}
}

