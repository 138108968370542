import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-widget',
	templateUrl: './widgets.component.html'
})
export class WidgetsComponent implements OnInit {
	@Input() widget;
	@Input() widgetsCount;
	@Input() osetParent;
	public isClipBoardCopied = false;
	public clipBoardTimeout = null;
	public clipBoardText = '';


	ngOnInit() { }

	clipBoardClicked(event) {
		// console.log("Visualization Function triggered", event);
		// console.log("this.clipBoardTimeout: ", this.clipBoardTimeout);
		this.isClipBoardCopied = true;
		if (this.clipBoardTimeout != null) {
			clearTimeout(this.clipBoardTimeout);
		};
		this.clipBoardText = `${event} Copied!`;
		this.clipBoardTimeout = setTimeout(() => {
			this.isClipBoardCopied = false;
			this.clipBoardTimeout = null;
			this.clipBoardText = '';
		}, 1500);
	}
}
