import { Component, ViewEncapsulation, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatPaginator, MatSnackBar } from '@angular/material';

import { AuthService } from '../../shared/auth.service';
import { AdminService } from './service/admin.service';
import { MatDialog } from '@angular/material';
import { UserDialogBoxComponent } from './user-dialog-box/user-dialog-box.component';
import { HistoryDialogBoxComponent } from './history-dialog-box/history-dialog-box.component';
import { UserStatus } from 'src/app/shared/enum/enum';
import { ViewUserDialogBoxComponent } from './view-user-dialog-box/view-user-dialog-box.component';
import { LockoutDialogBoxComponent } from './lockout-dialog-box/lockout-dialog-box.component';
import { StoriesCredentialsDialogBoxComponent } from './stories-credentials-dialog-box/stories-credentials-dialog-box.component';
import { DataShareService } from 'src/app/shared/data-share.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AdminComponent implements OnInit, OnDestroy {
  public displayedColumns = ['clientName', 'requesterName', 'accessCode', 'accessStartDate', 'status', 'lastLoggedIn', 'more'];
  public currentDate = new Date();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) pageinator: MatPaginator;

  public adminForm: FormGroup;
  public userList: MatTableDataSource<any>;
  public UserStatus = UserStatus;
  public filterText = '';
  public displayNoRecords = false;
  public selectedRowIndex = -1;
  public classVal = '';
  constructor(
    private _router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _authService: AuthService,
    private _adminService: AdminService,
    private dataShareService: DataShareService) { }

  ngOnInit() {
    this.getUsersList();
    this.classVal = 'admin-section-active';
    this.dataShareService.updateCustomEvent({ event: 'addClassToBody', data: this.classVal });
  }

  private getUsersList() {
    const timeZoneOffset = (new Date()).getTimezoneOffset();
    this._adminService.getUsersList().subscribe((res: any) => {
      res.data.forEach(v => {
        v = this._adminService.parseUserObj(v, true, timeZoneOffset);
      });

      this.userList = new MatTableDataSource(res.data);
      this.userList.sort = this.sort;
      this.userList.paginator = this.pageinator;

      this.userList.sortData = (item, sort) => {
        if (sort.active === 'accessStartDate') {
          return item.sort((a, b) => {
            let x = 0;
            if (a.accessStartDate > b.accessStartDate) {
              x = 1;
            } else if (a.accessStartDate.getTime() === b.accessStartDate.getTime()) {
              x = (a.accessEndDate > b.accessEndDate) ? 1 : ((a.accessEndDate < b.accessEndDate) ? -1 : x);
            } else {
              x = -1;
            }
            return x * (sort.direction === 'asc' ? 1 : sort.direction === 'desc' ? -1 : 0);
          });
        }

        return item.sort((a, b) => {
          const x = (a[sort.active] > b[sort.active] ? 1 : a[sort.active] < b[sort.active] ? -1 : 0);
          return x * (sort.direction === 'asc' ? 1 : sort.direction === 'desc' ? -1 : 0);
        });

      };

      this.userList.filterPredicate = (data, filter: string): boolean => {
        filter = filter.toLowerCase();
        return data.clientName.toLowerCase().includes(filter) || data.accessCode.toLowerCase().includes(filter) ||
          data.requesterName.toLowerCase().includes(filter) || data.status.toLowerCase().includes(filter);

      };
      this.applyFilter(this.filterText);
    });

  }


  private openDialog(isUpdate: boolean = false, user: any = null, errorMsg: string = '') {
    const dialogRef = this.dialog.open(UserDialogBoxComponent, {
      data: {
        isUpdate,
        user
      },
      panelClass: 'user-dialog-container',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result:`, result);
      if (result) {
        // Close on click of save or update user button.
        this._snackBar.open(result.message, '', {
          duration: 3000,
          panelClass: 'success',
          verticalPosition: 'top',
          horizontalPosition: 'right'
        });
        this.highlightSelectedRecord(isUpdate ? user : result.data);
      }
      this.getUsersList();

    });
  }

  openHistoryDialog(user) {
    const dialogRef = this.dialog.open(HistoryDialogBoxComponent, {
      width: '500px',
      height: '500px',
      data: {...user}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.highlightSelectedRecord(user);
    });

  }

  openLockoutDialog() {
    this.dialog.open(LockoutDialogBoxComponent, {
      width: '800px',
    });
  }

  openStoriesCredentialsDialog() {
    // console.log("Inside openStoriesCredentialsDialog function");
    this.dialog.open(StoriesCredentialsDialogBoxComponent, {
      panelClass: 'stories-credentials-dialog-container',
      // disableClose: true,
      // width: '800px',
    });
  }

  public applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    this.userList.filter = filterValue.trim().toLowerCase();

    if (this.userList.filteredData.length === 0) {
      this.displayNoRecords = true;
    } else {
      this.displayNoRecords = false;
    }
  }

  onUserGenerate() {
    this.openDialog();
  }

  onUserEditClick(user) {
    this.openDialog(true, user);
  }

  onUserViewClick(user) {
    const dialogRef = this.dialog.open(ViewUserDialogBoxComponent, {
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      this.highlightSelectedRecord(user);
    });
  }

  highlightSelectedRecord(user) {
    this.selectedRowIndex = user.accessCode;
    setTimeout(() => {
      this.selectedRowIndex = -1;
    }, 1000);
  }

  ngOnDestroy(){
    this.dataShareService.updateCustomEvent({ event: 'removeClassToBody', data: this.classVal });
  }
}
