import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
import { LoginService } from './service/login.service';
import { DataShareService } from 'src/app/shared/data-share.service';
import { CookieService } from 'src/app/shared/cookie-wrapper.service';
import { UserType, GAIndex, GAUserType } from 'src/app/shared/enum/enum';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  type = 'guest';
  error = '';
  showForm = false;
  isLoading = false;
  public loginType = 'custom';
  public unsubscribeActivatedRoute = null;
  public ssoSupported = environment.ssoSupported;
  constructor(
    private formBuilder: FormBuilder,
    private _authService: AuthService,
    private _dataShareService: DataShareService,
    private _cookieService: CookieService,
    private _router: Router,
    private _loginService: LoginService,
    private _activatedRoute: ActivatedRoute,
    private _gaService: GoogleAnalyticsService
    ) {
    this.unsubscribeActivatedRoute = this._activatedRoute.data.subscribe(d => {
      this.type = d.role;
    });
    this._activatedRoute.queryParams.subscribe(params => {
      // console.log(params);
      if (params.TID) {
        this.getSSOUserDetails(params.TID);
      } if (params.src && params.src === 'enNwYWNl') {
        // window.atob('enNwYWNl') === 'zspace'
        this.onSSOLogin();
      }
    });
    this.loginForm = formBuilder.group({
      uname: ['', Validators.nullValidator],
      passcode: ['', Validators.required],
    });
  }

  ngOnInit() {
    // Redirect logged In user Home page.
    if (this._authService.getUser()) {
      this._router.navigate(['gallery']);
    }
    this._dataShareService.onLoginFormLink.subscribe(type => {
      this.error = '';
      this.loginForm.reset();
      this.type = type;
      this.showForm = true;
      this.isLoading = false;
    });
    // this._dataShareService.onUserLogout.subscribe((data) => {
    //   this.logoutUser(data);
    // });
  }

  onUserLogin(loginButton) {
    this.error = '';
    this.loginType = 'custom';
    if (this.loginForm.invalid) {
      if (this.type === 'admin') {
        this.error = 'Please enter Username and Password.';
      } else {
        this.error = 'Please enter Access Code.';
      }
      return false;
    }
    loginButton.disabled = true;
    this.isLoading = true;
    this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: true });
    this._loginService.login(this.loginForm.value.uname, this.loginForm.value.passcode, this.type, this.loginType)
      .subscribe((data: any) => {
        this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: false });
        this.isLoading = false;
        loginButton.disabled = false;
        this._authService.setUser({
          id: data.data.userDetails.userId,
          emailId: data.data.userDetails.emailId,
          name: data.data.userDetails.name,
          isAdmin: (data.data.userDetails.role === 'admin'),
          type: (data.data.userDetails.role === 'admin') ? UserType.ADMIN : UserType.NON_ZS,
          userType: data.data.userDetails.type,
          token: data.data.token,
          data: data.data.userDetails,
          _s: data.data._s
        });
        // tslint:disable-next-line: max-line-length
        this._gaService.triggerEvent('Login', 'Login', 'User Login');
        // this._cookieService.setCloudFrontCookie(data.data._s);
        this._router.navigate(['gallery']);
      }, (err) => {
        this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: false });
        console.log(err);
        this.isLoading = false;
        loginButton.disabled = false;
        this.error = err.error.message;
      });
  }
  public onSSOLogin() {

    if(!this.ssoSupported) {
      this.error = 'Not supported on this env';
      return;
    }
    this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: true });
    this._loginService.initiateSAMLLogin().subscribe(res => {
      window.location.href = res.headers.get('x-redirect');
    }, (err) => {
      console.log(err);
      this.error = err.error.message;
      this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: false });
    });
  }

  public getSSOUserDetails(tID) {
    this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: true });
    this.loginType = 'sso';
    this._loginService.login('', tID, this.type, this.loginType).subscribe((data: any) => {
      this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: false });
      this.isLoading = false;
      this._authService.setUser({
        id: data.data.userDetails.userId || 1,
        emailId: data.data.userDetails.emailId,
        name: data.data.userDetails.name || data.data.userDetails.displayName,
        isAdmin: (data.data.userDetails.role === 'admin'),
        type: UserType.ZS,
        userType: data.data.userDetails.type,
        token: data.data.token,
        data: data.data.userDetails,
        _s: data.data._s
      });
      this._gaService.triggerEvent('Login', 'Login', 'User Login');
      // this._cookieService.setCloudFrontCookie(data.data._s);
      this._router.navigate(['gallery']);

    }, (err) => {
      console.log(err);
      this._dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: false });
      this.isLoading = false;
      this.error = err.error.message;
    });
  }

}
