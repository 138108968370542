import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../material/material.module';

import { CustomDataCoverageComponent } from './custom-data-coverage/custom-data-coverage.component';
import { SankyChartComponent } from './sanky-chart/sanky-chart.component';
import { NlpTextHighlighterComponent } from './nlp-text-highlighter/nlp-text-highlighter.component';
import { ComboBarChartComponent } from './combo-bar-chart/combo-bar-chart.component';
import { AreaChartComponent } from './area-chart/area-chart.component';
import { TrendChartNewComponent } from './trend-chart-new/trend-chart-new.component';
import { SolidGaugeComponent } from './solid-gauge-chart/solid-gauge-chart.component';
import { GuideBookComponent } from './guide-book/guide-book.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { HtmlViewerComponent } from './html-viewer/html-viewer.component';
import { ImageViewer } from './image-viewer/image-viewer.component';
import { SuccessFailure } from './success-failure/success-failure.component';
import { EventTimelineComponent } from './event-timeline/event-timeline.component';
import { D3SankyChartComponent } from './d3-sanky-chart/d3-sanky-chart.component';
import { D3FlowchartSankyComponent } from './d3-flowchart-sanky/d3-flowchart-sanky.component';
import { D3BarChart } from './d3-bar-chart/d3-bar-chart.component';
import { D3MultiSeriesLineChart } from './d3-multi-series-line-chart/d3-multi-series-line-chart.component';
import { DialogBoxComponent } from '../common/components/dialog-box/dialog-box.component';
import { DialogBoxD3TreeComponent } from '../common/components/dialog-box-d3-tree/dialog-box-d3-tree.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { SanitizeHtmlPipe } from '../pipes/sanitize-html.pipe';
import { PopUpComponent } from '../common/components/pop-up/pop-up.component';
import { PredictionGameComponent } from './prediction-game/prediction-game.component';
import { DialogInputBoxComponent } from '../common/components/dialog-input-box/dialog-input-box.component';
import { D3TreeChartComponent } from './d3-tree-chart/d3-tree-chart.component';
import { USChartPopUpComponent } from '../common/components/us-chart-pop-up/us-chart-pop-up.component';
import { USChartComponent } from './us-chart/us-chart.component';
import { USSliderChartComponent } from './us-slider-chart/us-slider-chart.component';
import { WidgetsComponent } from './widgets.component';
import { CustomEventTimelineComponent } from './custom-event-timeline/custom-event-timeline.component';
import { D3HeatmapComponent } from './d3-heatmap/d3-heatmap.component';
import { D3GeneticAlgorithmComponent } from './d3-genetic-algorithm/d3-genetic-algorithm.component';
import { D3NeuralNetworkComponent } from './d3-neural-network/d3-neural-network.component';
import { D3VerticalTreeChartComponent } from './d3-vertical-tree-chart/d3-vertical-tree-chart.component';
import { GridWidgetComponent } from './grid-widget/grid-widget.component';
import { D3GroupedBarChartComponent } from './d3-grouped-bar-chart/d3-grouped-bar-chart.component';
import { GroupedStackChartComponent } from './grouped-stack-chart/grouped-stack-chart.component';
import { D3PieChartComponent } from './d3-pie-chart/d3-pie-chart.component';
import { D3ThreedDonutComponent } from './d3-threed-donut/d3-threed-donut.component';
import { D3BoxplotComponent } from './d3-boxplot/d3-boxplot.component';
import { ContainerScrollSpyDirective } from '../shared/container-scroll-spy.directive';
import { D3ClusterOfCirclesComponent } from './d3-cluster-of-circles/d3-cluster-of-circles.component';
import { PlugNPredictTelecomComponent } from './plug-n-predict-telecom/plug-n-predict-telecom.component';
import { MicroLeadIdentificationComponent } from './micro-lead-identification/micro-lead-identification.component';
import { PlugNPredictRwdComponent } from './plug-n-predict-rwd/plug-n-predict-rwd.component';
import { SmallCircleClusterComponent } from './small-circle-cluster/small-circle-cluster.component';
import { D3EventTimelineComponent } from './d3-event-timeline/d3-event-timeline.component';
import { VerticalDnaHelixComponent } from './vertical-dna-helix/vertical-dna-helix.component';
import { NlpBuisnessViewComponent } from './nlp-buisness-view/nlp-buisness-view.component';
import { D3CustomShapesService } from '../services/helper/d3-custom-shapes.service';
import { D3FlowchartSankyChartV2Component } from './d3-flowchart-sanky-chart-v2/d3-flowchart-sanky-chart-v2.component';
import { D3EventTimelineChart } from './d3-event-timeline-chart/d3-event-timeline-chart.component';
import { NlpBuisnessViewNewComponent } from './nlp-buisness-view-new/nlp-buisness-view-new.component';
import { FieldCoachingComponent } from './field-coaching/field-coaching.component';
import { FullpagescrollDirective } from '../shared/fullpagescroll.directive';
import { FieldCoachingAppDialogComponent } from './field-coaching/field-coaching-app-dialog/field-coaching-app-dialog.component';
import { CopyToClipboardComponent } from './copy-to-clipboard/copy-to-clipboard.component';
import { AdaptivePricingComponent } from './adaptive-pricing/adaptive-pricing.component';
import { DragDropTabsComponent } from './drag-drop-tabs/drag-drop-tabs.component';
import { CtempComponent } from './ctemp/ctemp.component';
import { PatientRadarComponent } from './patient-radar/patient-radar.component';
import { AtlasIntelligenceKraftComponent } from './atlas-intelligence-kraft/atlas-intelligence-kraft.component';
import { PlaygroundDialogComponent } from './atlas-intelligence-kraft/playground-dialog/playground-dialog.component';
import { VirtualRepTrainerAppDialogComponent } from './virtual-rep-trainer/virtual-rep-trainer-app-dialog/virtual-rep-trainer-app-dialog.component';
import { VirtualRepTrainerComponent } from './virtual-rep-trainer/virtual-rep-trainer.component';


@NgModule({
  declarations: [
    WidgetsComponent,
    CustomDataCoverageComponent,
    SankyChartComponent,
    NlpTextHighlighterComponent,
    ComboBarChartComponent,
    AreaChartComponent,
    TrendChartNewComponent,
    SolidGaugeComponent,
    GuideBookComponent,
    VideoPlayerComponent,
    HtmlViewerComponent,
    SanitizeHtmlPipe,
    ImageViewer,
    SuccessFailure,
    EventTimelineComponent,
    D3SankyChartComponent,
    DialogBoxComponent,
    DialogBoxD3TreeComponent,
    DialogInputBoxComponent,
    PopUpComponent,
    USChartPopUpComponent,
    D3FlowchartSankyComponent,
    PredictionGameComponent,
    D3TreeChartComponent,
    D3BarChart,
    D3MultiSeriesLineChart,
    USChartComponent,
    CustomEventTimelineComponent,
    USSliderChartComponent,
    D3HeatmapComponent,
    D3GeneticAlgorithmComponent,
    D3NeuralNetworkComponent,
    D3VerticalTreeChartComponent,
    GridWidgetComponent,
    D3GroupedBarChartComponent,
    GroupedStackChartComponent,
    D3PieChartComponent,
    D3ThreedDonutComponent,
    D3BoxplotComponent,
    ContainerScrollSpyDirective,
    FullpagescrollDirective,
    D3ClusterOfCirclesComponent,
    PlugNPredictTelecomComponent,
    MicroLeadIdentificationComponent,
    PlugNPredictRwdComponent,
    SmallCircleClusterComponent,
    D3EventTimelineComponent,
    VerticalDnaHelixComponent,
    NlpBuisnessViewComponent,
    D3FlowchartSankyChartV2Component,
    D3EventTimelineChart,
    NlpBuisnessViewNewComponent,
    FieldCoachingComponent,
    FieldCoachingAppDialogComponent,
    CopyToClipboardComponent,
    AdaptivePricingComponent,
    DragDropTabsComponent,
    CtempComponent,
    PatientRadarComponent,
    AtlasIntelligenceKraftComponent,
    PlaygroundDialogComponent,
    VirtualRepTrainerComponent,
    VirtualRepTrainerAppDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    Ng2GoogleChartsModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    CustomDataCoverageComponent,
    SankyChartComponent,
    NlpTextHighlighterComponent,
    ComboBarChartComponent,
    AreaChartComponent,
    TrendChartNewComponent,
    SolidGaugeComponent,
    GuideBookComponent,
    VideoPlayerComponent,
    HtmlViewerComponent,
    ImageViewer,
    SuccessFailure,
    EventTimelineComponent,
    D3SankyChartComponent,
    D3FlowchartSankyComponent,
    PredictionGameComponent,
    D3TreeChartComponent,
    D3BarChart,
    D3MultiSeriesLineChart,
    USChartComponent,
    WidgetsComponent,
    CustomEventTimelineComponent,
    USSliderChartComponent,
    WidgetsComponent,
    D3NeuralNetworkComponent,
    D3VerticalTreeChartComponent,
    GridWidgetComponent,
    D3GroupedBarChartComponent,
    GroupedStackChartComponent,
    D3PieChartComponent,
    D3ThreedDonutComponent,
    D3BoxplotComponent,
    D3ClusterOfCirclesComponent,
    PlugNPredictTelecomComponent,
    MicroLeadIdentificationComponent,
    PlugNPredictRwdComponent,
    NlpBuisnessViewComponent,
    D3FlowchartSankyChartV2Component,
    D3EventTimelineChart,
    NlpBuisnessViewNewComponent,
    FieldCoachingComponent,
    CopyToClipboardComponent,
    AdaptivePricingComponent,
    CtempComponent,
    PatientRadarComponent,
    AtlasIntelligenceKraftComponent,
    VirtualRepTrainerComponent
  ],
  entryComponents: [
    DialogBoxComponent,
    DialogInputBoxComponent,
    PopUpComponent,
    USChartPopUpComponent,
    USSliderChartComponent,
    DialogBoxD3TreeComponent,
    FieldCoachingAppDialogComponent,
    PlaygroundDialogComponent,
    VirtualRepTrainerAppDialogComponent

  ],
  providers: [D3CustomShapesService],

})
export class WidgetsModule { }
