import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-story-navigation',
  templateUrl: './story-navigation.component.html',
  styleUrls: ['./story-navigation.component.css']
})
export class StoryNavigationComponent implements OnInit {

  @Input() navigationData = [];
  @Input() storyURL = '';
  @Input() selectedNavigation = '';
  @Input() isClickable = true;
  @Input() showBusinessViewBtn = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onStoryNavigationClick = new EventEmitter<any>();
  @Output() public businessViewClicked = new EventEmitter<any>();

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  onNavigationClick(section) {
    this.onStoryNavigationClick.emit(section);
  }

  onBusinessViewClick() {
    this.businessViewClicked.emit(true);
    this._router.navigate(['gallery/story-telling-page', this.storyURL]);
  }

}
