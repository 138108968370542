import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GalleryComponent } from './gallery.component';
import { GalleryListComponent } from './gallery-list/gallery-list.component';

import { StoryPageComponent } from './story-page/story-page.component';
import { VisulizationComponent } from './story-page/visulization/visulization.component';

import { GalleryRoutingModule } from './gallery-routing.module';

import { SparatorSplitPipe } from '../pipes/separator-split.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { ReplacePipe } from '../pipes/replace-space.pipe';
import { WidgetsModule } from '../widgets/widgets.module';

import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';

import { VisulizationService } from './services/visulization.service';
import { ScrollSpyDirective } from '../shared/scroll-spy.directive';
import { ContainerScrollSpyDirective } from '../shared/container-scroll-spy.directive';
import { StoryTellingPageComponent } from './story-telling-page/story-telling-page.component';
import { HorizontalVisualizationComponent } from './story-telling-page/horizontal-visualization/horizontal-visualization.component';
import { VisitReasonOverlayComponent } from './visit-reason-overlay/visit-reason-overlay.component';
import { MaterialModule } from '../material/material.module';
import { GridLoaderComponent } from '../common/components/grid-loader/grid-loader.component';
import { TabSettingsOverlayComponent } from './tab-settings-overlay/tab-settings-overlay.component';
import { TileSettingsOverlayComponent } from './tile-settings-overlay/tile-settings-overlay.component';
import { GroupSettingsOverlayComponent } from './group-settings-overlay/group-settings-overlay.component';
import { ConfirmationOverlayComponent } from './confirmation-overlay/confirmation-overlay.component';
// import { FullpagescrollDirective } from '../shared/fullpagescroll.directive';

// import { SharedModule } from '../shared/shared.module';




@NgModule({
  declarations: [
    GridLoaderComponent,
    GalleryComponent,
    GalleryListComponent,
    StoryPageComponent,
    VisulizationComponent,
    SparatorSplitPipe,
    FilterPipe,
    ReplacePipe,
    ScrollSpyDirective,
    // FullpagescrollDirective,
    // ContainerScrollSpyDirective,
    StoryTellingPageComponent,
    HorizontalVisualizationComponent,
    VisitReasonOverlayComponent,
    TabSettingsOverlayComponent,
    TileSettingsOverlayComponent,
    GroupSettingsOverlayComponent,
    ConfirmationOverlayComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    GalleryRoutingModule,
    WidgetsModule,
    DynamicFormModule,
    MaterialModule
  ],
  // exports:[
  // 	ContainerScrollSpyDirective
  // ],
  providers: [
    VisulizationService
  ],
  entryComponents: [
    VisitReasonOverlayComponent,
    TabSettingsOverlayComponent,
    TileSettingsOverlayComponent,
    GroupSettingsOverlayComponent,
    ConfirmationOverlayComponent,
  ]

})
export class GalleryModule { }
