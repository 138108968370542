import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, Renderer2 } from '@angular/core';
import _ from 'lodash';
import jsonData from './data.json';
declare let d3: any;

@Component({
  selector: 'app-nlp-buisness-view',
  templateUrl: './nlp-buisness-view.component.html',
  styleUrls: ['./nlp-buisness-view.component.css']
})
export class NlpBuisnessViewComponent implements OnInit, AfterViewInit {
  @ViewChild('container') container: ElementRef;
  @ViewChild('content') content: ElementRef;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onVisibleSectionChange = new EventEmitter<any>();
  public visibleDataTab = '';
  public visibleSolutionTab = '';
  public showDataClass = false;
  private dataTimer = null;
  public showSolutionClass = true;
  private solutionTimer = null;
  private sectionDimention = null;
  private scrollSectionDimention: any;
  public showSolutionBigCircle = false;
  private unsubscribeResize = null;
  private solutionBigCircleTimer: any;
  constructor(private renderer: Renderer2) {
    this.unsubscribeResize = this.renderer.listen(window, 'resize', _.debounce(() => {
      // console.log(new Date());
      this.calculateScrollDomain();
    }, 700));
   }

  ngAfterViewInit(): void {
    this.calculateScrollDomain();
  }
  private calculateScrollDomain() {
    const problem = d3.select('#problem').node(0).getBoundingClientRect();
    const data = d3.select('#data').node(0).getBoundingClientRect();
    const solution = d3.select('#solution').node(0).getBoundingClientRect();
    const outcomes = d3.select('#outcomes').node(0).getBoundingClientRect();
    const  solutionBigCircle = d3.select('#solutionBigCircle').node(0).getBoundingClientRect();
    const main = d3.select('#scroll-container').node(0).getBoundingClientRect();
    this.scrollSectionDimention = main;
    function checkLeavePoint(bottom) {
      let result = bottom - main.height;
      if (result < 0) {
        result = 0;
      }
      return result;
    }
    const size: any = {
      problem : {
        top: 0,
        bottom: problem.height,
        scrollStart: 0,
        scrollLeave: problem.height - main.height,
        scrollEnd: problem.height
      }
    };
    size.data = {
      top: size.problem.bottom + 1,
      bottom: size.problem.bottom + data.height,
      scrollHalf:size.problem.bottom - (main.height / 3) ,
      scrollStart: size.problem.bottom + 1,
      scrollLeave: (size.problem.bottom + data.height - main.height),
      scrollEnd: (size.problem.bottom + data.height)
    };
    size.solution = {
      top: size.data.bottom + 1,
      bottom: size.data.bottom + solution.height,
      scrollStart: size.data.bottom + 1,
      scrollLeave: (size.data.bottom + solution.height - main.height),
      scrollEnd: (size.data.bottom + solution.height)
    };
    size.outcomes = {
      top: size.solution.bottom + 1,
      bottom: size.solution.bottom + outcomes.height,
      scrollStart: size.solution.bottom + 1,
      scrollLeave: (size.solution.bottom + outcomes.height - main.height),
      scrollEnd: (size.solution.bottom + outcomes.height - main.height)
    };
    size.solutionBigCircle = {
      scrollEnter: solutionBigCircle.top - main.top - main.height
    };
    this.sectionDimention = size;
  }
  ngOnInit() {
    this.renderStory();
  }

  private renderStory() {
    let _scrollTop = 0;
    let _newScrollTop = 0;
    const that = this;
    d3.select('#scroll-container').on('scroll.scroller', function () {
      _newScrollTop = d3.select(this).node(0).scrollTop;
      if (that.sectionDimention.solutionBigCircle.scrollEnter < _newScrollTop) {
        clearTimeout(that.solutionBigCircleTimer);
        that.solutionBigCircleTimer = setTimeout(() => {
          that.showSolutionBigCircle = true;
        }, 10);
      } else {
        clearTimeout(that.solutionBigCircleTimer);
        that.showSolutionBigCircle = false;
      }
      if (that.sectionDimention.data.scrollHalf < _newScrollTop) {
        that.showDataClass = true;
        // clearTimeout(that.dataTimer);
        // that.dataTimer = setTimeout(() => {
        //   that.showDataClass = true;
        // }, 10);
      } else {
        that.showDataClass = false;
      }
    });

    this.initProblemSection(jsonData);

    // const render = () => {
    //   if (_scrollTop !== _newScrollTop) {
    //     _scrollTop = _newScrollTop;

    //     // if (this.sectionDimention.problem.scrollStart <= _scrollTop
    //     //   && _scrollTop < this.sectionDimention.problem.scrollEnd + 100) {
    //     // }
    //     // if ((this.sectionDimention.data.scrollStart - this.scrollSectionDimention.height) < _scrollTop
    //     //   && _scrollTop < (this.sectionDimention.data.scrollEnd + 100)) {

    //     // }
    //     // if ((this.sectionDimention.solution.scrollStart - this.scrollSectionDimention.height) < _scrollTop
    //     //   && _scrollTop <= (this.sectionDimention.solution.scrollEnd + 100)) {

    //     // }
    //     // if ((this.sectionDimention.outcomes.scrollStart - this.scrollSectionDimention.height) < _scrollTop) {

    //     // }
    //     if (this.sectionDimention.solutionBigCircle.scrollEnter < _scrollTop) {
    //       clearTimeout(this.solutionBigCircleTimer);
    //       this.solutionBigCircleTimer = setTimeout(() => {
    //         this.showSolutionBigCircle = true;
    //       }, 10);
    //     } else {
    //       clearTimeout(this.solutionBigCircleTimer);
    //       this.showSolutionBigCircle = false;
    //     }
    //   }
    //   window.requestAnimationFrame(render);
    // };
    // window.requestAnimationFrame(render);
  }
  // selectDataTab(data) {
  //   this.showDataClass = false;
  //   clearTimeout(this.dataTimer);
  //   this.visibleDataTab = data;
  //   this.dataTimer = setTimeout(() => {
  //     this.showDataClass = true;
  //   }, 10);
  // }

  selectSolutionTab(data) {
    this.showSolutionClass = false;
    clearTimeout(this.solutionTimer);
    this.visibleSolutionTab = data;
    this.solutionTimer = setTimeout(() => {
      this.showSolutionClass = true;
    }, 10);
  }

  initProblemSection(jsonData) {
    const svg = d3.select('.problem-hero-bottom-wrap').append('svg')
      .attr({
        'height': 1000,
        'width': 1280
      });

    const lineFunction = d3.svg.line()
      .x(function (d) { return d.x })
      .y(function (d) { return d.y })
      .interpolate('bundle');

    const StraitlineFunction = d3.svg.line()
      .x(function (d) { return d.x })
      .y(function (d) { return d.y })
      .interpolate('basic');

    let pathArr = [jsonData.data_nlp_path.nlpRevrsPathData, jsonData.data_nlp_path.nlpRevrsFirstPathData, jsonData.data_nlp_path.nlpRevrssecondPathData, jsonData.data_nlp_path.nlpRevrsthirdPathData, jsonData.data_nlp_path.nlpRevrsfourthPathData, jsonData.data_nlp_path.nlpRevrsfifthPathData, jsonData.data_nlp_path.nlpRevrsSixthPathData, jsonData.data_nlp_path.nlpRevrsSeventhPathData, jsonData.data_nlp_path.nlpPathData, jsonData.data_nlp_path.nlpFirstPathData, jsonData.data_nlp_path.nlpSecondPathData, jsonData.data_nlp_path.nlpThirdPathData, jsonData.data_nlp_path.nlpFourthPathData, jsonData.data_nlp_path.nlpFifthPathData, jsonData.data_nlp_path.nlpSixthPathData, jsonData.data_nlp_path.nlpSeventhPathData];
    let striatPatharr = [jsonData.data_nlp_path.nlpRevrsStraitPathData, jsonData.data_nlp_path.nlpStraitfirstPathData, jsonData.data_nlp_path.nlpStraitsecondPathData, jsonData.data_nlp_path.nlpStraitthirdPathData, jsonData.data_nlp_path.nlpStraitfourthPathData, jsonData.data_nlp_path.nlpStraitfifthPathData, jsonData.data_nlp_path.nlpStraitSixPathData, jsonData.data_nlp_path.nlpStraitSeventhPathData, jsonData.data_nlp_path.nlpStraitPathData, jsonData.data_nlp_path.nlpFirstStraitPathData, jsonData.data_nlp_path.nlpSecondStraitPathData, jsonData.data_nlp_path.nlpThirdStraitPathData, jsonData.data_nlp_path.nlpFourthStraitPathData, jsonData.data_nlp_path.nlpFifthStraitPathData, jsonData.data_nlp_path.nlpSixthStraitPathData, jsonData.data_nlp_path.nlpSeventhStraitPathData];
    //let categories = ['nlpPath', 'firstPath', 'secondPath', 'thirdPath', 'fourthPath', 'fifthPath', 'sixthPath', 'seventhPath', 'nlpRevrsPath', 'nlpRevrsFirstPath', 'nlpRevrssecondPath', 'nlpRevrsthirdPath', 'nlpRevrsfourthPath', 'nlpRevrsfifthPath', 'nlpRevrsSixthPath', 'nlpRevrsSeventhPath'];
    let pathDataarr = [];


    var g = svg.append('g')
    for (let i = 0; i < pathArr.length; i++) {
      pathDataarr.push(g.append('path')
        .attr('d', lineFunction(pathArr[i]) + StraitlineFunction(striatPatharr[i]).replace('M', 'L').replace('Z', ''))
        .attr('stroke', 'none')
        .attr('stroke-width', 2)
        .attr('fill', 'none'))
    }

    var symbolArr = ['diamond', 'square'];
    var arrayData = [];
    var colorArr = ['#faa24c', '#39b2ff', '#a1be00', '#d94785']

    var symbol = d3.svg.symbol().type('square').size(90);

    for (var index = 0; index < 250; index++) {
      arrayData.push({
        id: index + 1,
        //type: categories[index % 16],
        //type: categories[Math.floor(Math.random() * categories.length)],
        symbol: symbolArr[1],
        color: colorArr[Math.floor(Math.random() * colorArr.length)],
        pathdata: pathDataarr[Math.floor(Math.random() * pathDataarr.length)]
        // pathdata:pathDataarr[index % 16]

      })
    }


    let movingSymbol = g.selectAll('.nlpSymbol')
      .data(arrayData)
      .enter()
      .append('path')
      .attr('d', function (d) { return symbol(); })
      //.style('stroke', 'black')
      .style('fill', function (d) {
        return d.color;
      })
    repeatTransition();
    function repeatTransition() {
      movingSymbol
        .transition()
        .attr('class', 'moving-shapes')
        .delay(function (d, i) {
          return 140 * i;
        })
        //.style('opacity',0)
        .duration(8000)

        .ease('linear')
        .tween('pathTween', function (d) { return pathTween(d.pathdata) })
        .call(endAll, function () {
          repeatTransition();
        })
    }

    function endAll(transition, callback) {
      if (typeof callback !== 'function') throw new Error('Wrong callback in endall');
      if (transition.size() === 0) {
        callback()
      }
      var n = 0;
      transition
        .each(function () { ++n; })
        .each('end', function () {

          if (--n === 220)
            callback.apply(this, arguments);

        });
    }

    function pathTween(path) {

      var length = path.node().getTotalLength(); // Get the length of the path
      var r = d3.interpolate(0, length); //Set up interpolation from 0 to the path length
      return function (t) {
        var point = path.node().getPointAtLength(r(t)); // Get the next point along the path
        d3.select(this) // Select the circle
          .attr('transform', 'translate(' + point.x + ',' + point.y + ')rotate(45)') // Set the cx
      }
    }

  }

  /**
   * This is Visble Section change Handler.
   *
   * @param $event - Section Id
   */
  onScrollSecionChange($event) {
    this.onVisibleSectionChange.emit($event);
  }

  ngOnDestroy(): void {
    if (this.unsubscribeResize) {
      this.unsubscribeResize();
    }
    if (this.solutionBigCircleTimer) {
      clearTimeout(this.solutionBigCircleTimer);
    }
  }
}
