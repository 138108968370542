import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items: [], filterOn: string = "", isQuickSettingsActive = true) {
    // if (!items || !filterOn) {
    //   return items;
    // }
    // return items;
    var filteredItem = items.filter((item) => this.applyFilter(item, filterOn, isQuickSettingsActive));
    if (filteredItem.length == 0) {
      return [-1];
    }
    return filteredItem;
  }

  getUpdatedDim(v, visibleStories, isQuickSettingsActive) {
    if (v.isGroup) {
      v.cols = 6;
      if (visibleStories.length != 0) {
        v.cols = visibleStories.length > 1 ? 6 : 3;
        if (visibleStories && visibleStories.length !== 0) {
          visibleStories.forEach((e: any, i, arr) => {
            e.rows = 10;
            e.cols = visibleStories.length > 1 ? (i === arr.length - 1 && i % 2 == 0 ? 12 : 6) : 12;
            return e;
          });
        }
        const paddingTop = 2
        const paddingBottom = 1
        v.rows = Math.ceil(visibleStories.length / 2) * 10 + paddingTop + paddingBottom + (isQuickSettingsActive ? 1 : 0);
        if (visibleStories.length === 0) {
          v.rows = 0;
          v.cols = 0;
          v.noStories = true;
        } else {
          v.noStories = false;
        }
      } else {
        v.rows = 0;
        v.cols = 0;
        v.noStories = true;
      }
    }
  }

  applyFilter(item, filter, isQuickSettingsActive): boolean {
    var foundItemInTitle = true;
    var foundItemInTags = true;
    var foundByCategory = true;

    // var foundByGroupName = true;
    var foundInGroupStoryList = true;

    if (item.type === 'story' && (isQuickSettingsActive || !item.isHidden)) {
      if ((item.title.toLowerCase().indexOf(filter.toLowerCase()) < 0)) {
        foundItemInTitle = false;
      }

      if ((item.category.toLowerCase().indexOf(filter.toLowerCase()) < 0)) {
        foundByCategory = false;
      }

      if (item.tags && item.tags.length) {
        var foundTags = item.tags.filter((tag) => ((tag.toLowerCase().indexOf(filter.toLowerCase()) < 0 ? false : true)));
        if (!foundTags || !foundTags.length) {
          foundItemInTags = false;
        }
      }
      return foundItemInTitle || foundItemInTags || foundByCategory;
    } else if (item.type === 'group' && (isQuickSettingsActive || !item.isHidden)) {
      // if ((item.name.toLowerCase().indexOf(filter.toLowerCase()) < 0) ||  item.stories.length == 0) {
      //   foundByGroupName = false;
      // }

      const isTagPresent = (obj) => {
        if (obj.tags && obj.tags.length) {
          var foundTags = obj.tags.filter((tag) => ((tag.toLowerCase().indexOf(filter.toLowerCase()) < 0 ? false : true)));
          return (!foundTags || !foundTags.length) ? false : true;
        }
      }

      let storyList = item.stories.filter(obj => {
        return (isQuickSettingsActive || !obj.isHidden) &&
          (
            ((obj.title.toLowerCase().indexOf(filter.toLowerCase()) > -1) ? true : false) ||
            ((obj.category.toLowerCase().indexOf(filter.toLowerCase()) > -1) ? true : false) ||
            (isTagPresent(obj))
          )
        // || ((obj.tags.filter((tag) => ((tag.toLowerCase().indexOf(filter.toLowerCase()) > -1 ? true : false))).length > 0) ? true : false)
      });

      this.getUpdatedDim(item, storyList, isQuickSettingsActive);

      foundInGroupStoryList = storyList.length ? true : false;

      return foundInGroupStoryList;
    }
  }
}
