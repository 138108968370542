import { Component, OnInit, Input, SecurityContext, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
declare let d3: any;


@Component({
    selector: 'app-d3-cluster-of-circles',
    templateUrl: './d3-cluster-of-circles.component.html',
    styleUrls: ['./d3-cluster-of-circles.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class D3ClusterOfCirclesComponent implements OnInit {
    @ViewChild('d3ClusterCircleRef') chartElement: ElementRef;
    @Input() data;
    @Input() settings;

    cluster: any = {
        width: 500,
        height: 500,
        svg: null,
        id: "circles-cluster",
        circleNodes: [],
        force: null,
        charge: -15,
        data: [],
        circleRadius: 8,
        circleColor: "#aaafb5",
        circleNodesClassName: "big-circle-cluster",
        parentSvg: null,
        transformX: 0,
        transformY: 0
    }

    ngOnInit() {
        this.cluster.width = this.settings.width || this.cluster.width;
        this.cluster.height = this.settings.height || this.cluster.height;
        this.cluster.charge = this.settings.charge || this.cluster.charge;
        this.cluster.circleRadius = this.settings.circleRadius || this.cluster.circleRadius;
        this.cluster.circleColor = this.settings.circleColor || this.cluster.circleColor;
        this.cluster.id = this.settings.id || this.cluster.id;
        this.cluster.circleNodesClassName = this.settings.circleNodesClassName || this.cluster.circleNodesClassName;
        this.cluster.parentSvg = this.settings.parentSvg || this.cluster.parentSvg;        
        this.cluster.transformX = this.settings.transformX || this.cluster.transformX;        
        this.cluster.transformY = this.settings.transformY || this.cluster.transformY;        
        this.cluster.data = this.data;
        this.initialize();
    }

    createData() {
        for (let i = 0; i < 250; i++) {
            this.cluster.data.push({
                id: i
            });
        }
    }


    initialize() {
        
        //To add id attribute 
        d3.select(this.chartElement.nativeElement)
            .attr('id', this.cluster.id);

        let appendCircleTo = null;    
        if(this.cluster.parentSvg) {
            appendCircleTo = this.cluster.parentSvg
                .append("g")
                .attr("transform", "translate("+ this.cluster.transformX +","+  this.cluster.transformY +" )");
        } else {
            appendCircleTo = d3.select("#" + this.cluster.id).append("svg");
        }

        //Create SVG of requested size
        this.cluster.svg = appendCircleTo
            .attr("id", "circles-cluster-svg")
            .attr("width", this.cluster.width)
            .attr("height", this.cluster.height)

        if(!this.cluster.parentSvg) {
            this.cluster.svg.call(this.responsivefy);
        }

        //Initizalize force
        this.cluster.force = d3.layout.force()
            .size([this.cluster.width, this.cluster.height])
            .charge(this.cluster.charge)
            .on("tick", () => {
                this.cluster.svg.selectAll("." + this.cluster.circleNodesClassName)
                    .attr("cx", function (d) {
                        return d.x;
                    })
                    .attr("cy", function (d) {
                        return d.y;
                    });
            })
            .start();

        this.cluster.circleNodes = this.cluster.svg.selectAll("." + this.cluster.circleNodesClassName);

        this.render();
    }

    render() {
        this.cluster.circleNodes = this.cluster.circleNodes.data(this.cluster.data, (d, i) => {
            d.x = this.cluster.width * Math.random();
            d.y = this.cluster.height * Math.random();
            return d.id;
        });
        this.cluster.circleNodes
            .enter()
            .append("circle")
            .attr("class", this.cluster.circleNodesClassName)
            .attr("id", function (d, i) {
                return "big_circle_node_" + d.id;
            })
            .style("fill", this.cluster.circleColor)
            .attr("r", this.cluster.circleRadius)

        this.cluster.force
            .nodes(this.cluster.data)
            .start();
    }

    responsivefy(svg) {
        // get container + svg aspect ratio
        var container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style("width")),
            height = parseInt(svg.style("height")),
            aspect = width / height;
        // add viewBox and preserveAspectRatio properties,
        // and call resize so that svg resizes on inital page load
        svg.attr("viewBox", "0 0 " + width + " " + height)
            .attr("perserveAspectRatio", "xMinYMid")
            .call(resize);

        // to register multiple listeners for same event type, 
        // you need to add namespace, i.e., 'click.foo'
        // necessary if you call invoke this function for multiple svgs
        // api docs: https://github.com/mbostock/d3/wiki/Selections#on
        d3.select(window).on("resize." + container.attr("id"), resize);

        // get width of container and resize svg to fit it
        function resize() {
            var targetWidth = parseInt(container.style("width"));
            svg.attr("width", targetWidth);
            svg.attr("height", Math.round(targetWidth / aspect));
        }
    }

}
