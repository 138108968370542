import * as core from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { GalleryComponent } from './gallery.component';
import { GalleryListComponent } from './gallery-list/gallery-list.component';
import { StoryPageComponent } from './story-page/story-page.component';
import { StoryTellingPageComponent } from './story-telling-page/story-telling-page.component';
import { AuthGuardService } from '../shared/auth-guard.service';
import { ConfirmCancelGuard } from './guards/confirm-cancel.guard';

const galleryRoutes: Routes = [{
  path: 'gallery',
  component: GalleryComponent,
  children: [
    { path: '', component: GalleryListComponent, canDeactivate: [ConfirmCancelGuard] },
    { path: 'story-page/:url', component: StoryPageComponent },
    { path: 'story-telling-page/:url', component: StoryTellingPageComponent }
  ],
  canActivate: [AuthGuardService],
  canActivateChild: [AuthGuardService]
}
];

@core.NgModule({
  imports: [
    RouterModule.forChild(galleryRoutes)
  ],
  exports: [RouterModule]

})
export class GalleryRoutingModule {

}
