import { Component, OnInit, ViewChild, Renderer2, AfterContentInit, ViewEncapsulation, OnDestroy, ElementRef } from '@angular/core';
import _ from 'lodash';
import { FullpagescrollDirective } from 'src/app/shared/fullpagescroll.directive';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FieldCoachingAppDialogComponent } from './field-coaching-app-dialog/field-coaching-app-dialog.component';
import { DataShareService } from 'src/app/shared/data-share.service';

declare const d3: any;

@Component({
  selector: 'app-field-coaching',
  templateUrl: './field-coaching.component.html',
  styleUrls: ['./field-coaching.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FieldCoachingComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChild('fullPageScroll') fpScroll: FullpagescrollDirective;
  @ViewChild('checkBox1') checkBox1: ElementRef;
  @ViewChild('checkBox2') checkBox2: ElementRef;
  @ViewChild('checkBox3') checkBox3: ElementRef;

  public activeStartSlide = '';
  public activeSlide = '';
  public previousActiveSlide = [];
  public lastActiveSlide = 'screen-1';
  public nextActiveSlide = 'screen-2';
  public previousTraversedSlide = '';
  public isLoading = false;
  private _unsubscribeResize = null;
  private _svgSection = null;
  private currentRelativeUrl = '';
  public isShowScreen4 = false;
  public isShowScreen6 = false;
  private screens = ['screen-1', 'screen-2', 'screen-3', 'screen-4', 'screen-5', 'screen-6'];
  constructor(
    private _renderer: Renderer2
    , private _router: Router,
    private dialog: MatDialog,
    private dataShareService: DataShareService
  ) {
    this.currentRelativeUrl = this._router.url;
  }

  ngOnInit() {
    this.dataShareService.onCustomEvent.next({ event: 'addClassToElement', data: {selector: '#header', class: 'humburger-header'}});
    this.renderStory();
  }

  ngAfterContentInit(): void {
    this._unsubscribeResize = this._renderer.listen(window, 'resize', _.debounce(() => {
      this._svgSection.resize();
    }, 700));
  }

  public onFullPageSlideChangeStart(event) {
    this.previousTraversedSlide = this.activeStartSlide;
    if (this.previousActiveSlide.indexOf(event) < 0) {
      this.previousActiveSlide.push(this.activeStartSlide);
    } else {
      this.previousActiveSlide.splice(this.previousActiveSlide.indexOf(this.activeStartSlide), 1);
    }
    this.activeStartSlide = event;
    this.lastActiveSlide = this.screens[this.screens.indexOf(event) - 1];
    this.nextActiveSlide = this.screens[this.screens.indexOf(event) + 1];
    this._svgSection.changeScreen();
  }

  public onFullPageSlideChangeComplete(event) {
    this.activeSlide = event;
  }

  public onDownArrowClick(sectionId) {
    this.fpScroll.navigateTo(sectionId);
  }

  private _responsivefy(svg, isHeightNotToUpdate = false) {
    const container = d3.select(svg.node().parentNode);
    const width = parseInt(svg.attr('width'), 10);
    const height = parseInt(svg.attr('height'), 10);
    const aspect = width / height;

    // get width of container and resize svg to fit it
    const resize = () => {
      const targetWidth = parseInt(container.style('width'), 10);
      svg.attr('width', targetWidth);
      let targetHeight = targetWidth / aspect;
      if (isHeightNotToUpdate) {
        // Set Container Height as is.
        targetHeight = container.node().getBoundingClientRect().height;
      }
      svg.attr('height', Math.round(targetHeight));
      return {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height,
        width: parseInt(svg.style('width'), 10),
        height: parseInt(svg.style('height'), 10)
      };
    };
    svg.attr('viewBox', '0 0 ' + width + ' ' + height)
      .attr('perserveAspectRatio', 'xMinYMid')
      .call(() => {
        setTimeout(() => {
          resize();
        }, 10);
      });

    return {
      resize,
      widthAspect: parseInt(svg.style('width'), 10) / width,
      heightAspect: parseInt(svg.style('height'), 10) / height
    };
  }

  private renderStory() {
    this._svgSection = this._initSvgSection('svg-wrapper');
    setTimeout(() => {
      this.activeStartSlide = 'screen-1';
      this.activeSlide = 'screen-1';
      this._svgSection.changeScreen();
    }, 500);
  }

  private _initSvgSection(containerId) {
    const that = this;
    const visitedScreen = {
      screen1: false,
      screen2: false,
      screen3: false,
      screen4: false,
      screen5: false,
      screen6: false,
    }
    const container = d3.select('#' + containerId);
    const WIDTH = 1920;
    const HEIGHT_OFFSET = 969 / 1920;
    const HEIGHT = WIDTH * HEIGHT_OFFSET;
    const svg = container.append('svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT)
      /* .on('click', function () {
        console.log(d3.mouse(this));
      }) */;

    const { resize, heightAspect, widthAspect } = this._responsivefy(svg, true);

    const dataGroup = svg.append('g')
      .attr('id', 'svg-data')
      .attr('class', 'svg-data');

    const linearGradient1 = svg.append('defs')
      .append('linearGradient')
      .attr({
        'id': "challengesIndicatorColor",
        'x1': "0.968",
        // 'y1': "0.5",
        'x2': "0.278",
        'gradientUnits': "objectBoundingBox"
      })

    linearGradient1.append('stop')
      .attr({
        'offset': "0",
        'stop-color': "#fff"
      });

    linearGradient1.append('stop')
      .attr({
        'offset': "1",
        'stop-color': "#4f4642"
      })

    const linearGradient2 = svg.append('defs')
      .append('linearGradient')
      .attr({
        'id': "arcColor",
        'x1': "0.968",
        'y1': "0",
        'x2': "0.278",
        'y2': "0.278",
        'gradientUnits': "objectBoundingBox"
      })

    linearGradient2.append('stop')
      .attr({
        'offset': "0",
        'stop-color': "#86c8bc"
      });

    linearGradient2.append('stop')
      .attr({
        'offset': "1",
        'stop-color': "#00629b"
      })

    let baseImage = '';

    // switch (this.activeStartSlide) {
    //   case 'screen-1':
    //     baseImage = '/assets/images/field-coaching/bubble_cloud_base.png';
    //     break;
    //   case 'screen-2':
    //     baseImage = '/assets/images/field-coaching/screen2.png';
    //     break;
    //   case 'screen-3':
    //     baseImage = '/assets/images/field-coaching/screen3.png';
    //     break;
    //   case 'screen-4':
    //     baseImage = '/assets/images/field-coaching/screen3.png';
    //     break;
    //   case 'screen-5':
    //     baseImage = '/assets/images/field-coaching/screen5.png';
    //     break;
    //   default:
    //     baseImage = '/assets/images/field-coaching/screen6.png';
    //     break;
    // }

    const image = dataGroup.append('image')
      .attr('xlink:href', baseImage)
      .attr('class', 'base-image-group')
      .attr('height', 969)
      .attr('width', 1920)
      .attr('id', 'img');


    const commonGroup = dataGroup.append('g')
      .attr('id', 'common-group-data')
      .attr('class', 'common-group-data')
      .attr('opacity', 0);

    const currentProcess = dataGroup.append('g')
      .attr('id', 'current-process-data')
      .attr('class', 'current-process-data');


    const common_images = [{
      src: '/assets/images/field-coaching/director-board.png',
      id: 'director-board-img',
      class: 'director-board animate',
      height: 261,
      width: 273,
      finalX: 75,
      finalY: 309,
      x: 211,
      y: 439,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/director.png',
      id: 'director-img',
      class: 'director animate',
      height: 443,
      width: 453,
      finalX: 280,
      finalY: 115,
      x: 280,
      y: 0,
      scaleHeight: 535,
      scaleWidth: 535,
      newX: 57,
      newY: 353,
      opacity: 0
    }];

    commonGroup.selectAll('.images')
      .data(common_images)
      .enter()
      .append('image')
      .attr('id', (d) => d.id)
      .attr('xlink:href', (d) => d.src)
      .attr('class', (d) => d.class)
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
      .attr('opacity', (d) => d.opacity);

    const current_process_first_part_images = [{
      src: '/assets/images/field-coaching/screen_1_1.png',
      id: 'screen-1-img-1',
      class: 'screen-1-1 animate',
      height: 463,
      width: 885,
      finalX: 233,
      finalY: 249,
      x: 0,
      y: 249,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/screen_1_2.png',
      id: 'screen-1-img-2',
      class: 'screen-1-1 animate',
      height: 316,
      width: 210,
      finalX: 679,
      finalY: 388,
      x: 900,
      y: 388,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/screen_1_3.png',
      id: 'screen-1-img-3',
      class: 'screen-1-1 animate',
      height: 359,
      width: 381,
      finalX: 54,
      finalY: 446,
      x: 0,
      y: 446,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/managerCase.png',
      id: 'screen-1-img-4',
      class: 'screen-1-1 animate',
      height: 79,
      width: 99,
      finalX: 186,
      finalY: 710,
      x: 0,
      y: 710,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/salesCase.png',
      id: 'screen-1-img-5',
      class: 'screen-1-1 animate',
      height: 79,
      width: 99,
      finalX: 790,
      finalY: 626,
      x: 900,
      y: 626,
      opacity: 0
    }];

    const current_process_second_part_images = [{
      src: '/assets/images/field-coaching/screen_2_2.png',
      id: 'screen-1-2-img-2',
      class: 'screen-1-2 animate',
      height: 285,
      width: 303,
      finalX: 103,
      finalY: 538,
      x: 250,
      y: 538,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/screen_2_1.png',
      id: 'screen-1-2-img-1',
      class: 'screen-1-2 animate',
      height: 338,
      width: 243,
      finalX: 91,
      finalY: 484,
      x: 54,
      y: 446,
      opacity: 0
    }, {
      src: '/assets/images/field-coaching/screen_2_4.png',
      id: 'screen-1-2-img-4',
      class: 'screen-1-2 animate',
      height: 388,
      width: 473,
      finalX: 588,
      finalY: 496,
      x: 588,
      y: 969,
      opacity: 0
    }];

    const processFirstPart = currentProcess.append('g').attr('opacity', 0);

    const processSecondPart = currentProcess.append('g').attr('opacity', 0);

    processFirstPart.selectAll('.images')
      .data(current_process_first_part_images)
      .enter()
      .append('image')
      .attr('id', (d) => d.id)
      .attr('xlink:href', (d) => d.src)
      .attr('class', (d) => d.class)
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
      .attr('opacity', (d) => d.opacity);

    processSecondPart.selectAll('.images')
      .data(current_process_second_part_images)
      .enter()
      .append('image')
      .attr('id', (d) => d.id)
      .attr('xlink:href', (d) => d.src)
      .attr('class', (d) => d.class)
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
      .attr('opacity', (d) => d.opacity);

    const bubble_images = [{
      src: '/assets/images/field-coaching/bubble_cloud_1.png',
      class: 'bubble animate',
      height: 97,
      width: 180,
      finalX: 460,
      finalY: 260,
      x: 460,
      y: 360,
      initHeight: 0
    }, {
      src: '/assets/images/field-coaching/bubble_cloud_2.png',
      class: 'bubble animate',
      height: 88,
      width: 158,
      finalX: 680,
      finalY: 300,
      x: 769,
      y: 375,
      initHeight: 0
    }];

    const bubbleGroup = processFirstPart.append('g')
      .attr('id', 'bubble-group-data')
      .attr('class', 'bubble-group-data')
      .attr('opacity', 0);

    bubbleGroup.selectAll('.images')
      .data(bubble_images)
      .enter()
      .append('image')
      .attr('xlink:href', (d) => d.src)
      .attr('class', (d) => d.class)
      .attr('height', (d) => d.initHeight)
      .attr('width', (d) => d.width)
      .attr('transform', (d) => `translate(${d.x}, ${d.y})`)
      .attr('opacity', 0);


    const conversationGroup = bubbleGroup.append('g');
    const conversationLineCordinates = [
      [[500, 285], [600, 285]],
      [[493, 295], [582, 295]],
      [[485, 305], [566, 305]],
      [[710, 324], [804, 324]],
      [[716, 334], [790, 334]],
      [[712, 344], [778, 344]]
    ];

    const conversationLineData = [];

    conversationLineCordinates.forEach((lineData) => {
      conversationLineData.push(createLine(conversationGroup, lineData).classed('conversation-lines animate', true));
    });

    const ipadLineCordinates = [
      [[324, 589], [345, 601]],
      [[321, 591], [340, 603]],
      [[318, 594], [336, 604]],
      [[313, 596], [333, 607]],
      [[310, 598], [329, 610]]
    ];

    const ipadLineData = [];

    const ipadGroup = bubbleGroup.append('g')
      .attr('transform', 'translate(-22, -16)');

    ipadLineCordinates.forEach((lineData) => {
      ipadLineData.push(createLine(ipadGroup, lineData, '#ffffff').classed('ipad-lines animate', true));
    });

    function startConversationAnimation() {

      conversationLineData.forEach((v, i) => {
        v.attr('stroke-dashoffset', v.node().getTotalLength());
      });

      conversationLineData.forEach((v, i) => {
        v
          .attr({
            'opacity': 1
          }).transition()
          .duration(1000)
          .delay(i * 500)
          .attr('stroke-dashoffset', 0)
          .each('end', function () {
            if (i === (conversationLineData.length - 1)) {
              startConversationAnimation();
            }
          });
      });
    }

    function startIpadAnimation() {

      ipadLineData.forEach((v, i) => {
        v.attr('stroke-dashoffset', v.node().getTotalLength());
      });

      ipadLineData.forEach((v, i) => {
        v
          .attr({
            'opacity': 1
          }).transition()
          .duration(1000)
          .delay(i * 500)
          .attr('stroke-dashoffset', 0)
          .each('end', function () {
            if (i === (ipadLineData.length - 1)) {
              startIpadAnimation();
            }
          });
      });
    }


    // Left View Lines
    const notificationCordinates = [
      [[298, 514], [298, 514]],
      [[298, 514], [337, 478]],
      [[298, 514], [385, 545]],
      [[298, 514], [318, 433]],
      [[298, 514], [377, 458]],
      [[298, 514], [456, 565]],
      [[298, 514], [516, 603]],
      [[298, 514], [566, 563]]
    ];

    const notification_images = [{
      height: 54,
      width: 52,
      finalX: 368,
      finalY: 110,
      x: 415,
      y: 195,
      // textX: 0,
      // textY: 0,
      text: 4
    }, {
      height: 54,
      width: 52,
      finalX: 630,
      finalY: 540,
      x: 676,
      y: 589,
      // textX: 0,
      // textY: 0,
      text: 1
    }, {
      height: 54,
      width: 52,
      finalX: 784,
      finalY: 452,
      x: 834,
      y: 500,
      // textX: 0,
      // textY: 0,
      text: 1
    }, {
      height: 54,
      width: 52,
      finalX: 901,
      finalY: 493,
      x: 940,
      y: 546,
      // textX: 0,
      // textY: 0,
      text: 1
    }, {
      height: 54,
      width: 52,
      finalX: 798,
      finalY: 605,
      x: 824,
      y: 650,
      // textX: 0,
      // textY: 0,
      text: 1
    }];


    const notificationGroup = processSecondPart.append('g')
      .attr('id', 'notification-group-data')
      .attr('class', 'notification-group-data')
      .attr('opacity', 0);

    notificationGroup.selectAll('.images')
      .data(notification_images)
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x}, ${d.y}) scale(0)`)
      .attr('class', (d) => 'notification animate')
      .attr('opacity', 0)
      .append('image')
      .attr('xlink:href', '/assets/images/field-coaching/notification.png')
      .attr('class', (d) => 'notification-img')
      .attr('height', (d) => d.height)
      .attr('width', (d) => d.width)
      .select(function () { return this.parentNode; })
      .append('text')
      .attr('fill', '#ffffff')
      .style('font-size', '14px')
      .attr('transform', (d) => `translate(35, 14)`)
      .text(function (d) { return d.text; });


    const smallNotificationGroup = processSecondPart.append('g')
      .attr('id', 'small-notification-group-data')
      .attr('class', 'small-notification-group-data')
      .attr('opacity', 0);

    smallNotificationGroup.selectAll('.lines')
      .data(notificationCordinates)
      .enter()
      .append('g')
      .append('path')
      .attr('d', this._line())
      .attr('class', 'message-line')
      .attr('stroke', 'none')
      .attr('stroke-width', 0)
      .attr('fill', 'none');

    for (let index = 0; index < 8; index++) {
      smallNotificationGroup
        .append('image')
        .attr('id', 'small-note-' + (index + 1))
        .attr('xlink:href', '/assets/images/field-coaching/small_notification.png')
        .attr('class', 'small-notification-image animate')
        .attr('height', 20)
        .attr('width', 22)
        .attr('opacity', 0)
        .attr('transform', `translate(298, 514)`);
    }

    function pathTween(path) {
      const length = path.node().getTotalLength();
      const r = d3.interpolate(0, length);
      return function (t) {
        const point = path.node().getPointAtLength(r(t));
        let opacityVal = 1;
        let scale = 1;
        if (t > 0.25) {
          // opacityVal = 0.7;
        }
        if (t > 0.50) {
          // opacityVal = 0.5;
          scale = 1.5 - t;
        }
        if (t > 0.75) {
          opacityVal = 0.2;
          scale = 1.5 - t;
        }
        if (t >= 1) {
          opacityVal = 0;
          scale = 1.5 - t;
        }


        d3.select(this) // Select the circle
          .style('opacity', opacityVal)
          .attr('transform', 'translate(' + point.x + ',' + point.y + ')'); // Set the cx
      };
    }

    function repeatTransition(className, path, duration, delay, cb) {
      d3.selectAll(`#${className}`).transition()
        .delay((d, i) => delay * i)
        .duration(duration)
        .ease('linear')
        .tween('pathTween', (d: any, i) => {
          return pathTween(path);
        })
        // tslint:disable-next-line: only-arrow-functions
        .call(endAll, function () {
          if (cb) {
            cb();
          }
        });
    }

    function endAll(tran, callback) {
      if ((typeof callback) !== 'function') { throw new Error('Wrong callback in endall'); }
      if (tran.size() === 0) { callback(); }
      let n = 0;
      tran.each(() => { ++n; })
        .each('end', function (e) {
          if (--n === 0) { callback.apply(this, arguments); }
        });
    }

    // PB Start
    let screen3ForwardAnimationStart = true;

    function createLine(group, cordinates, strokeColor = '#000000') {
      return group.append('path')
        .datum(cordinates)
        .attr('d', that._line())
        .attr('stroke', strokeColor)
        .attr('stroke-width', 1)
        .attr('stroke-dasharray', function (d) {
          d.pathLength = d3.select(this).node().getTotalLength();
          return `${d.pathLength}, ${d.pathLength}`;
          // return 0;
        })
        .attr('stroke-dashoffset', (d) => d.pathLength)
        .attr('fill', 'none');
    }

    const challengesGroup = dataGroup.append('g')
      .attr({
        'id': 'challenges-main-section-group',
        'class': 'challenges-main-section-group',
        // 'transform': 'translate(200,231) scale(0,0)'
      });

    const monitorGroup = challengesGroup.append('g')
      .attr({
        'id': 'challenges-monitor-section-group',
        'class': 'challenges-monitor-section-group',
        // 'transform': 'translate(200,231) scale(0,0)'
      });

    monitorGroup.append('image')
      .attr({
        'xlink:href': 'assets/images/field-coaching/challenges-monitor.png',
        'width': 515,
        'height': 652,
        'id': 'challenges-monitor-image',
        'class': 'challenges-monitor-image challenges',
        // 'transform': 'translate(370, 508) scale(0.13) rotate(-5 71 25) skewY(139)',
        'transform': 'translate(308, 550) scale(0.087) rotate(20) skewY(-60)',
        'opacity': 0
      });

    function startMonitorAnimation() {
      screen3ForwardAnimationStart = true;
      challengesGroup.selectAll('.challenges').interrupt();
      if (that.activeStartSlide === 'screen-3') {
        monitorGroup.select('#challenges-monitor-image')
          .transition()
          .duration(900)
          .attr({
            'transform': 'translate(643, 145) scale(1) rotate(0 0 0)',
            'opacity': 1
          })
          .call(endAll, () => {
            startIndicatorSectionAnimation();
            startFileAnimation();
          });
      }
    }

    function resetMonitor() {
      monitorGroup.select('#challenges-monitor-image')
        .transition()
        .duration(100)
        .attr({
          opacity: 0
        })
        .transition()
        .attr({
          // 'transform': 'translate(370, 508) scale(0.13)',
          'transform': 'translate(308, 550) scale(0.087) rotate(20) skewY(-60)',
        })
    }

    // Files Section Start
    const directoryGroup = challengesGroup.append('g')
      .attr({
        'id': 'challenges-files-group',
        'class': 'challenges-files-group',
        'transform': 'translate(670,227)'
      })
      .style({
        'transform': 'translate(670px,227px)'
      });

    const filesGroup = directoryGroup.append('g')
      .attr({
        'id': 'challenges-files-group',
        'class': 'challenges-files-group'
      });

    const selectedFileGroup = directoryGroup.append('g')
      .attr({
        'id': 'challenges-selected-files-group',
        'class': 'challenges-selected-files-group'
      });

    const blueTickGroup = directoryGroup.append('g')
      .attr({
        'id': 'challenges-files-group',
        'class': 'challenges-files-group'
      });

    const filesData = [{
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 0,
      y: 0,
      width: 67,
      height: 118,
      moveable: true
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 77,
      y: 45,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 154,
      y: 90,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 231,
      y: 134,
      width: 67,
      height: 118,
      moveable: true
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 308,
      y: 178,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 385,
      y: 222,
      width: 67,
      height: 118,
      moveable: true
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 0,
      y: 90,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 77,
      y: 134,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 154,
      y: 178,
      width: 67,
      height: 118,
      moveable: true
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 231,
      y: 222,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 308,
      y: 266,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 385,
      y: 310,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 0,
      y: 180,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 77,
      y: 224,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 154,
      y: 268,
      width: 67,
      height: 118,
      moveable: true
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 231,
      y: 312,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 308,
      y: 356,
      width: 67,
      height: 118
    }, {
      path: '/assets/images/field-coaching/challenges-file.png',
      x: 385,
      y: 400,
      width: 67,
      height: 118
    },];

    const fileImageRef = [];

    filesData.forEach((file, fileIndex) => {
      const image = filesGroup.append('image')
        .attr({
          'xlink:href': file.path,
          'width': file.width,
          'height': file.height,
          'id': `monitor-file-${fileIndex}`,
          'class': `monitor-file challenges`,
          'x': file.x,
          'y': file.y,
          'opacity': 0,
          'transform': 'scale(1.1)'
          // 'transform': 'translate(267,277) scale(0)'
        });

      fileImageRef.push(image);
    });

    function startFileAnimation() {
      if (that.activeStartSlide === 'screen-3') {
        filesGroup.selectAll('.monitor-file')
          .data(filesData)
          .attr({
            'opacity': 0,
            'x': (d) => d.x,
            'y': (d) => d.y,
            'transform': 'scale(1.1)'
          })
          .transition()
          .duration(600)
          .delay((d, i) => i * 50)
          .attr({
            'opacity': 1,
            'transform': 'scale(1)'
          })
          .call(endAll, () => {
            startBlueTickAnimation();
          })
      }
    }

    const selectedFileData = [{
      path: '/assets/images/field-coaching/challenges-clicked-file.png',
      x: 145,
      y: 171,
      width: 85,
      height: 136
    }];

    selectedFileData.forEach((file, fileIndex) => {
      selectedFileGroup.append('image')
        .attr({
          'xlink:href': file.path,
          'width': file.width,
          'height': file.height,
          'id': `monitor-clicked-file-${fileIndex}`,
          'class': `monitor-clicked-file challenges`,
          'x': file.x,
          'y': file.y,
          'opacity': 0,
          // 'transform': 'translate(267,277) scale(0)'
        });
    });

    function startClickedFileAnimation() {
      if (that.activeStartSlide === 'screen-3') {
        selectedFileGroup.select('.monitor-clicked-file')
          .attr('opacity', 0)
          .transition()
          .duration(200)
          .delay((d, i) => i * 20)
          .attr({
            'opacity': 1
          })
          .transition()
          .duration(50)
          .attr({
            'opacity': 0.6
          })
          .transition()
          .duration(80)
          .attr({
            'opacity': 1
          })
          .each('end', () => {
            startChallengeExcelSectionAnimation();
            selectedFileGroup.select('.monitor-clicked-file')
              .transition()
              .delay(100)
              .attr('opacity', 0)
          });
      }
    }

    const blueTicksData = [{
      path: '/assets/images/field-coaching/selected-tick.png',
      x: 127,
      y: 141,
      width: 29,
      height: 31
    },
    {
      path: '/assets/images/field-coaching/selected-tick.png',
      x: 280,
      y: 231,
      width: 29,
      height: 31,
      moveable: true
    },
    {
      path: '/assets/images/field-coaching/selected-tick.png',
      x: 203,
      y: 275,
      width: 29,
      height: 31,
      moveable: true
    },
    {
      path: '/assets/images/field-coaching/selected-tick.png',
      x: 280,
      y: 410,
      width: 29,
      height: 31
    },
    {
      path: '/assets/images/field-coaching/selected-tick.png',
      x: 358,
      y: 276,
      width: 29,
      height: 31
    }]

    blueTicksData.forEach((blueTick, blueTickIndex) => {
      blueTickGroup.append('image')
        .attr({
          'xlink:href': blueTick.path,
          'width': blueTick.width,
          'height': blueTick.height,
          'id': `file-blue-tick-${blueTickIndex}`,
          'class': `file-blue-tick challenges`,
          'x': blueTick.x,
          'y': blueTick.y,
          'opacity': 0,
          // 'transform': 'translate(267,277) scale(0)'
        });
    });

    function startBlueTickAnimation() {
      if (that.activeStartSlide === 'screen-3') {
        blueTickGroup.selectAll('.file-blue-tick')
          .data(blueTicksData)
          .attr('opacity', 0)
          .attr('x', (d) => d.x)
          .attr('y', (d) => d.y)
          .transition()
          .duration(400)
          .delay((d, i) => i * 80)
          .attr({
            'opacity': 1
          })
          .call(endAll, () => {
            setTimeout(() => {
              startClickedFileAnimation();
            }, 500);
          })
      }
    }

    function resetDirectoryFilesAndTicks() {

      selectedFileGroup.select('.monitor-clicked-file')
        .attr('opacity', 0);

      blueTickGroup.selectAll('.file-blue-tick')
        .data(blueTicksData)
        .transition()
        .delay(350)
        .duration(20)
        .attr('opacity', 0)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y);

      fileImageRef.forEach((dirFile, index) => {
        if (filesData[index].moveable == undefined) {
          const d = filesData[index];
          dirFile
            .transition()
            .delay(350)
            .duration(20)
            .attr({
              'opacity': 0,
              'x': d.x,
              'y': d.y
            })
            .each('end', function () {
              if (index === (filesData.length - 1)) {
                resetMonitor();
              }
            });
        }
      })
    }

    function hideScreen3Elements() {
      commonGroup.select('#director-board-img')
        .transition()
        .attr('transform', (d) => `translate(${d.x}, ${d.y}) scale(0)`);
      resetIndicatorPosition();

      selectedFileGroup.select('.monitor-clicked-file')
        .attr('opacity', 0);

      blueTickGroup.selectAll('.file-blue-tick')
        .data(blueTicksData)
        .transition()
        .delay(400)
        .duration(20)
        .attr('opacity', 0)
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y);

      filesGroup.selectAll('.monitor-file')
        .data(filesData)
        .transition()
        .duration(0)
        .attr({
          'opacity': 0,
          'x': (d, i) => d.x,
          'y': (d, i) => d.y
        })
        .call(endAll, () => {
          resetMonitor();
        });
    }
    // Files Section Closed

    // Excel Section Start
    const excelSectionGroup = challengesGroup.append('g')
      .attr({
        'id': 'challenges-excel-section-group',
        'class': 'challenges-excel-section-group',
        // 'transform': 'translate(200,231) scale(0,0)'
      })
      .style({
        'transform': 'translate(628px,210px)'
      });

    excelSectionGroup.append('image')
      .attr({
        'xlink:href': 'assets/images/field-coaching/challenges-excel-document.png',
        'width': 535,
        'height': 555,
        'id': 'excel-section-image',
        'class': 'excel-section-image challenges',
        'transform': 'translate(267,277) scale(0)'
      });

    const barChartGroup = excelSectionGroup.append('g')
      .attr({
        'id': 'challenges-bar-chart',
        'class': 'challenges-bar-chart',
      })
      .style({
        'transform': 'translate(75px, 165px)skewY(32deg)'
      });

    const yellowTextSectionGroup = excelSectionGroup.append('g')
      .attr({
        'id': 'challenges-lines-group',
        'class': 'challenges-lines-group',
        'transform': 'translate(170, 220)'
      })
      .style({
        'transform': 'translate(170px, 220px)'
      });

    const screenIndicatorSectionGroup = excelSectionGroup.append('g')
      .attr({
        'id': 'challenges-screenIndicator-group',
        'class': 'challenges-screenIndicator-group',
        'transform': 'translate(145, 0)'
      })
      .style({
        'transform': 'translate(145px, 0px)'
      });

    // fee3c0
    // 5ab25e
    const barCharData = [{
      x: 0,
      totalHeight: 80,
      duration: 1000,
      delay: 200,
      data: [{
        height: 30,
        width: 6,
        fill: '#fee3c0'
      }, {
        height: 50,
        width: 6,
        fill: '#5ab25e'
      }]
    }, {
      x: 17,
      totalHeight: 80,
      duration: 1000,
      delay: 600,
      data: [{
        height: 40,
        width: 6,
        fill: '#fee3c0'
      }, {
        height: 65,
        width: 6,
        fill: '#5ab25e'
      }]
    }, {
      x: 34,
      totalHeight: 80,
      duration: 1000,
      delay: 1000,
      data: [{
        height: 25,
        width: 6,
        fill: '#fee3c0'
      }, {
        height: 50,
        width: 6,
        fill: '#5ab25e'
      }]
    }, {
      x: 51,
      totalHeight: 80,
      duration: 1000,
      delay: 1400,
      data: [{
        height: 20,
        width: 6,
        fill: '#fee3c0'
      }, {
        height: 75,
        width: 6,
        fill: '#5ab25e'
      }]
    }];

    const barChar = barChartGroup.selectAll('.barChartDataGroup')
      .data(barCharData)
      .enter()
      .append('g')
      .each(function (d) {
        const g = d3.select(this)
          .attr('class', 'barChartDataGroup challenges')
          .attr('transform', `translate(${d.x}, 0)`);
        g.selectAll('.bars')
          .data(d.data)
          .enter()
          .append('rect')
          .each(function (data: any, i) {
            data.totalHeight = d.totalHeight;
            data.duration = d.duration;
            data.delay = d.delay;
            d3.select(this)
              .attr('class', 'animate challenges')
              .attr('x', ((data.width * i) + (1 * i)))
              .attr('y', d.totalHeight/* (d.totalHeight - data.height) */)
              .attr('width', data.width)
              // .attr('height', data.height)
              .attr('height', 0)
              .attr('fill', data.fill);
          });
      });

    function startBarChartAnimation() {
      if (that.activeStartSlide === 'screen-3' && (+excelSectionGroup.select('image').attr('opacity') == 1)) {
        barChar
          .attr('opacity', 1)
          .selectAll('rect')
          .attr('y', (d: any) => d.totalHeight).attr('height', 0)
          .attr('opacity', 1)
          .transition()
          .delay((d: any) => d.delay)
          .duration((d: any) => d.duration)
          .attr('y', (d: any) => (d.totalHeight - d.height))
          .attr('height', (d: any) => d.height)
          .call(endAll, function () {
            reanimateBarChart();
          });
      } else {
        barChar
          .attr('opacity', 0)
          .selectAll('rect')
          .attr('opacity', 0);
      }
    }

    function reanimateBarChart() {
      if (that.activeStartSlide === 'screen-3' && (+excelSectionGroup.select('image').attr('opacity') == 1)) {
        // console.log("reanimate called");
        barChar
          .selectAll('rect')
          .transition()
          .delay(150)
          .duration(1000)
          .attr('y', (d: any) => d.totalHeight).attr('height', 0)
          .call(endAll, function () {
            // console.log('start animation called');
            startBarChartAnimation();
          });
      } else {
        barChar
          .attr('opacity', 0)
          .selectAll('rect')
          .attr('opacity', 0);
      }
    }

    const lines = [];

    const linesData = [
      [[0, 6], [54, 6]],
      [[0, 11], [46, 11]],
      [[0, 16], [47, 16]],
      [[0, 21], [52, 21]],
      [[0, 26], [46, 26]],
      [[0, 31], [43, 31]],
      [[0, 36], [25, 36]],
    ];

    linesData.forEach((lineData) => {
      lines.push(createLine(yellowTextSectionGroup, lineData).classed('yellow-box-lines challenges', true));
    });

    function startYellowSectionTextAnimation() {

      lines.forEach((v, i) => {
        v.attr({
          'stroke-dashoffset': v.node().getTotalLength(),
          'opacity': 0
        });
      });

      if (that.activeStartSlide === 'screen-3' && (+excelSectionGroup.select('image').attr('opacity') == 1)) {
        lines.forEach((v, i) => {
          v.attr({
            'opacity': 1,
            'transform': 'skewY(31)',
            'stroke-width': '1.5'
          }).transition()
            .duration(1000)
            .delay(i * 500)
            .attr('stroke-dashoffset', 0)
            .each('end', function () {
              if (i === (lines.length - 1)) {
                startYellowSectionTextAnimation();
              }
            });
        });
      }
    }
    // Excel Section End

    // Indicator Section Start
    const indicatorLines = [];
    const indicatorLinesData = [
      {
        line: {
          'co-ordinates': [[0, 6], [315, 5]]
        },
        image: {
          path: '/assets/images/field-coaching/challenge-indicator-1.png',
          height: 34,
          width: 34
        },
        text: {
          content: 'Increased time',
          color: '#FFF'
        }
      },
      {
        line: {
          'co-ordinates': [[102, 63], [402, 62]]
        },
        image: {
          path: '/assets/images/field-coaching/challenge-indicator-2.png',
          height: 34,
          width: 34
        },
        text: {
          content: 'Tedious manual efforts',
          color: '#FFF'
        }
      },
      {
        line: {
          'co-ordinates': [[204, 120], [502, 119]]
        },
        image: {
          path: '/assets/images/field-coaching/challenge-indicator-2.png',
          height: 34,
          width: 34
        },
        text: {
          content: 'Inconsistency',
          color: '#FFF'
        }
      }
    ];

    indicatorLinesData.forEach((indicatorLineData, indicatorIndex) => {
      const screenIndicatorBlockGroup = screenIndicatorSectionGroup.append('g')
        .attr({
          'class': 'challenges-indicator-block-group',
          'id': `challenges-indicator-block-group-${indicatorIndex}`
        });
      const { line, image, text } = indicatorLineData;
      // Adding Line
      indicatorLines.push(createLine(screenIndicatorBlockGroup, line['co-ordinates']).classed(`challenges-indicator-${indicatorIndex} challenges`, true));
      const imageTranslateValue = {
        x: line['co-ordinates'][0][0] + (line['co-ordinates'][1][0] - line['co-ordinates'][0][0]),
        y: line['co-ordinates'][0][1] - (image.height >> 1)
      }
      // Adding Image
      screenIndicatorBlockGroup.append('image')
        .attr({
          'xlink:href': image.path,
          'width': image.width,
          'height': image.height,
          'id': `image-${indicatorIndex}`,
          'class': 'challenges',
          'transform': `translate(${imageTranslateValue.x}, ${imageTranslateValue.y})`,
          'opacity': 0
        })
      // Adding Text
      screenIndicatorBlockGroup.append('text')
        .text(text.content)
        .attr({
          'x': imageTranslateValue.x + image.width + 10,
          'y': line['co-ordinates'][0][1],
          'fill': text.color,
          'dy': '0.35em',
          'id': `text-${indicatorIndex}`,
          'class': 'challenges',
          'opacity': 0
        })
        .style({
          'letter-spacing': '0.5px',
          'font-size': '14px',
        })
    });

    function startIndicatorSectionAnimation() {
      if (that.activeStartSlide === 'screen-3') {
        indicatorLines.forEach((line, indicatorIndex) => {
          line
            .attr('opacity', 1)
            .transition()
            .duration(700)
            .delay(indicatorIndex * 200)
            .attr('stroke-width', 1)
            .attr('stroke-dashoffset', 0)
            .attr('stroke', `url(${that.currentRelativeUrl}#challengesIndicatorColor)`)
            .each('end', () => {
              screenIndicatorSectionGroup.select(`#image-${indicatorIndex}`)
                .transition()
                .duration(300)
                .attr({
                  'opacity': 1
                })
                .each('end', () => {
                  screenIndicatorSectionGroup.select(`#text-${indicatorIndex}`)
                    .transition()
                    .duration(300)
                    .attr({
                      'opacity': 1
                    })
                })
            });
        });
      }
    }

    function resetIndicatorPosition() {
      indicatorLines.forEach((line, indicatorIndex) => {
        line
          .transition()
          .duration(90)
          .attr('stroke-dashoffset', line.node().getTotalLength())
          .each('end', () => {
            screenIndicatorSectionGroup.select(`#image-${indicatorIndex}`)
              .transition()
              .duration(10)
              .attr({
                'opacity': 0
              })
              .each('end', () => {
                screenIndicatorSectionGroup.select(`#text-${indicatorIndex}`)
                  .attr({
                    'opacity': 0
                  })
              })
          });
      });
    }
    // Indicator Section End

    const movefileIndex = [{
      position: 0,
      selected: false,
    },
    {
      position: 3,
      selected: true,
      tickPosition: 1
    }, {
      position: 5,
      selected: false
    }, {
      position: 8,
      selected: true,
      tickPosition: 2
    }, {
      position: 14,
      selected: false
    },], fileMoveValue = 900;

    function startChallengeExcelSectionAnimation() {
      if (that.activeStartSlide === 'screen-3') {
        excelSectionGroup.select('image')
          .attr({
            'transform': 'translate(267,277) scale(0, 0)',
            'opacity': 1,
            'stroke': '6c757d'
          })
          .transition()
          .duration(1000)
          .attr({
            'transform': 'translate(0,0) scale(1, 1)',
          })
          .call(endAll, () => {
            startBarChartAnimation();
            startYellowSectionTextAnimation();
          });
      }
    }

    function closeExcelSectionAnimation() {
      // screen3ForwardAnimationStart = false; //Stop all forward animation
      challengesGroup.selectAll('.challenges').interrupt(); //Interrupt all challenges transition

      // Stop Lines Animation
      lines.forEach((v, i) => {
        v.transition()
          .attr('stroke-dashoffset', v.node().getTotalLength());
      });

      // Stop Bar Chart Animation
      barChar.selectAll('rect')
        .transition()
        .duration(100)
        .attr('y', (d: any) => (d.totalHeight))
        .attr('height', 0)
        .attr('opacity', 0)

      // Scale down Excel Section Image
      excelSectionGroup.select('image')
        .transition()
        .duration(300)
        .attr({
          'transform': 'translate(267,277) scale(0, 0)'
        })
        .each('end', () => {
          movefileIndex.forEach((file, index) => {
            filesGroup.select(`#monitor-file-${file.position}`)
              .transition()
              .ease('linear')
              .delay(index * 10)
              .duration(1050)
              .attr('x', () => {
                if (file.selected) {
                  blueTickGroup.select(`#file-blue-tick-${file.tickPosition}`)
                    .transition()
                    .delay(1)
                    .transition()
                    .ease('linear')
                    .delay(index * 100)
                    .duration(1000)
                    .attr({
                      // 'x': blueTicksData[file.tickPosition].x + fileMoveValue,
                      'opacity': 0
                    })
                }
                return filesData[file.position].x + fileMoveValue
              })
              .attr({
                'transform': 'scale(1.2)',
                'opacity': 0
              })
              .each('end', () => {
                if ((movefileIndex.length - 1) === index) {
                  if (that.activeStartSlide === 'screen-4') {
                    showScreen4();
                  }
                }
              });
          });

          resetChallangesElementPosition();

          commonGroup.select('#director-board-img')
            .transition()
            .ease('linear')
            .delay(400)
            .duration(10)
            .attr({
              'transform': (d) => `translate(${d.x}, ${d.y}) scale(0)`,
            });

          commonGroup.select('#director-img')
            .transition()
            .ease('linear')
            .delay(400)
            .duration(100)
            .attr('height', (d) => d.scaleHeight)
            .attr('width', (d) => d.scaleWidth)
            .attr('transform', (d) => `translate(${d.newX}, ${d.newY})`)
            .attr('opacity', 0);
        });
    }

    function resetChallangesElementPosition() {
      // commonGroup.select('#director-board-img').attr('opacity', 0);
      resetDirectoryFilesAndTicks();
      resetIndicatorPosition();
    }
    // PB End

    const centerCircleImageData = [
      {
        src: '/assets/images/field-coaching/polygon_10.png',
        class: 'rotate-clockwise',
        height: 391,
        width: 391,
        finalX: 0,
        finalY: 0,
        X: 195,
        Y: 195
      }, {
        src: '/assets/images/field-coaching/polygon_9.png',
        class: 'rotate-anti-clockwise',
        height: 296,
        width: 296,
        finalX: 47.5,
        finalY: 47.5,
        X: 195,
        Y: 195
      }, {
        src: '/assets/images/field-coaching/polygon_8.png',
        id: 'polygon8',
        class: 'rotate-clockwise',
        height: 210,
        width: 210,
        finalX: 90.5,
        finalY: 90.5,
        X: 195,
        Y: 195
      }];

    const docImages = [{
      src: '/assets/images/field-coaching/circle_empty_doc.png',
      id: 'empty-doc',
      class: '',
      height: 210,
      width: 210,
      finalX: 170,
      finalY: 163,
      X: 195,
      Y: 195
    }, {
      src: '/assets/images/field-coaching/circle_target_doc.png',
      id: 'empty-doc',
      class: '',
      height: 210,
      width: 210,
      finalX: 155,
      finalY: 154,
      X: 195,
      Y: 195
    }]

    const centerGroupDotsData = [
      { X: 118, Y: 105, radius: 4 },
      { X: 137, Y: 128, radius: 4 },
      { X: 156, Y: 148, radius: 4 }
    ]

    const centerCircleGroup = commonGroup.append('g')
      .attr({
        'id': 'center-circle-main-group',
        'class': 'center-circle-main-group',
        'transform': 'translate(696,310)',
        'opacity': 0
      });

    centerCircleGroup.selectAll('.image')
      .data(centerCircleImageData)
      .enter()
      .append('image')
      .attr('xlink:href', d => d.src)
      .attr('class', 'image')
      .attr('transform', d => 'translate(' + d.X + ',' + d.Y + ') scale(0)')
      .attr('opacity', 0);

    centerCircleGroup.selectAll('.docs')
      .data(docImages)
      .enter()
      .append('image')
      .attr('xlink:href', d => d.src)
      .attr('class', 'docs')
      .attr('transform', d => 'translate(' + d.X + ',' + d.Y + ') scale(0)')
      .attr('opacity', 0);

    centerCircleGroup.selectAll('.dots')
      .data(centerGroupDotsData)
      .enter()
      .append('circle')
      .attr('cx', d => d.X)
      .attr('cy', d => d.Y)
      .attr('class', 'dots')
      .attr('fill', '#C63FC6')
      .attr('r', d => d.radius)
      .attr('opacity', 0);

    const docLinesColors = ['grey', 'green', 'brown', 'black', 'steelblue']

    const docLinesCordinates = [
      [[0, 0], [15, 0]],
      [[0, 10], [15, 10]],
      [[0, 20], [30, 20]],
      [[0, 30], [30, 30]],
      [[0, 40], [30, 40]],
      [[0, 50], [30, 50]],
    ];

    const docLinesGroup = centerCircleGroup.append('g').attr('transform', 'translate(180,175)')
    const docLinesData = [];

    docLinesCordinates.forEach((lineData) => {
      docLinesData.push(createLine(docLinesGroup, lineData).classed('document-line', true));
    });

    function startDocAnimation() {
      docLinesData.forEach((lines, i) => {
        lines
          .attr({
            'opacity': 1,
            'stroke-width': 5,
            'stroke': docLinesColors[i],
            'stroke-linecap': 'round'
          }).transition()
          .duration(1000)
          .delay(i * 500)
          .attr('stroke-dashoffset', 0);
      });
    }

    function animatePlygon(value) {
      const polygons = d3.selectAll('.image')
      polygons
        .transition()
        .duration(100)
        .attr('transform', (d: any, i) => {
          return `translate(${d.finalX}, ${d.finalY}) rotate(${i % 2 === 0 ? value : -value}, ${d.width / 2}, ${d.height / 2})`
        })
        .each('end', function () {
          value += 1;
          animatePlygon(value >= 360 ? 0 : value);
        });
    }

    this._initNplSvgSection('svg-npl-wrapper');


    const nlpTextGroup = centerCircleGroup.append('g')
      .attr('class', 'nlp-text')
      .attr('transform', 'translate(60,-32)');

    nlpTextGroup
      .append('text')
      .attr('font-size', '27px')
      .attr('font-weight', 'medium')
      .attr('stroke', 'white')
      .attr('fill', 'white')
      .text('Process Involved in NLP');

    const nlpBottomTextGroup = nlpTextGroup.append('g').attr('transform', 'translate(60,390)')

    nlpBottomTextGroup.append('line')
      .attr({
        'x1': 0,
        'y1': 0,
        'x2': 0,
        'y2': 65,
        'stroke': '#ED8B00',
        'stroke-width': 5
      })


    const testLines = [
      { text: 'NLP', X: 10, Y: 20, size: '23px', weight: 'bold', stroke: '#ED8B00' },
      { text: 'Segregating text based on', X: 10, Y: 40, size: '13px', weight: 'medium', stroke: 'white' },
      { text: 'Quality & Structure', X: 10, Y: 60, size: '13px', weight: 'medium', stroke: 'white' }
    ];

    nlpBottomTextGroup.selectAll('text')
      .data(testLines).enter().append('text')
      .attr('font-size', d => d.size)
      .attr('font-weight', d => d.weight)
      // .attr('stroke', d => d.stroke)
      .attr('fill', d => d.stroke)
      .attr('x', d => d.X)
      .attr('y', d => d.Y)
      .text(d => d.text)


    const benefitGroup = dataGroup.append('g')
      .attr({
        'id': 'benefit-main-group',
        'class': 'benefit-main-group',
        'opacity': 0
        // 'transform': 'translate(200,231) scale(0,0)'
      });

    const chartGroup = benefitGroup.append('g')
      .attr({
        'id': 'benefit-chart-main-group',
        'class': 'benefit-chart-main-group',
        'transform': 'translate(82,252)'
      });

    // let svg = d3.select("#scatter-test1").append("svg");

    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const width = 600 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    chartGroup
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .style('font-family', '"Roboto", sans-serif');


    let pre_endAngle = 0;
    const arcArray = [];

    const dataArray = [
      { angle: 45, radius: 170, title: 'IMMEDIATE' },
      { angle: 130, radius: 200, title: 'SPECIFIC' },
      { angle: 100, radius: 235, title: 'BALANCED' },
      { angle: 85, radius: 200, title: 'FOCUSED' }
    ];

    const degToRad = d => (d * Math.PI) / 180;

    for (let key in dataArray) {
      let arcAngle = degToRad(dataArray[key].angle);
      let startAngle = pre_endAngle;
      let endAngle = startAngle + arcAngle;
      pre_endAngle = endAngle;
      arcArray.push({
        title: dataArray[key].title,
        data: dataArray[key].angle,
        index: key,
        value: dataArray[key].angle,
        startAngle: startAngle,
        endAngle: endAngle,
        outerRadius: dataArray[key].radius
      });
    }
    const arc = d3.svg.arc()
      .innerRadius(26 * 3)
      .outerRadius(d => d.outerRadius);

    const g = chartGroup
      .append('g')
      .attr('height', height)
      .attr('width', width)
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    g.selectAll('path')
      .data(arcArray)
      .enter()
      .append('path')
      .attr('class', 'benefit-arc')
      // .attr('fill', (d, i) => color[i]);
      .attr('fill', `url(${that.currentRelativeUrl}#arcColor)`);


    const chartGroupCenterCircle = [
      { radius: 100, fill: '#0F3A71' },
      { radius: 70, fill: '#ED8B0D' }
    ];

    g.selectAll('circle')
      .data(chartGroupCenterCircle)
      .enter()
      .append('circle')
      .attr('class', 'main-circle')
      .attr('fill', d => d.fill)

    g.append('text')
      .attr('opacity', 1)
      .attr('x', 0)
      .attr('y', 0)
      .attr('class', 'arc-center-text')
      .attr('fill', '#000000')
      .style({
        'font-size': '26px',
        'transform': 'translate(-46px, 10px)'
      })
      .text('LABELS')
      .attr('opacity', 0);

    const graphText = []
    arcArray.forEach(value => {
      const position = arc.centroid(value)
      graphText.push({ X: position[0], Y: position[1] })
    })


    g.selectAll('.arc-text')
      .data(graphText)
      .enter()
      .append('text')
      .attr('opacity', 0)
      .attr('x', d => d.X - 30)
      .attr('y', d => d.Y)
      .attr('class', 'arc-text')
      .attr('fill', 'white')
      .attr('font-size', 15)
      .attr('dy', '0.33em')
      .text((d, i) => arcArray[i].title);

    // g.append('circle')
    //   .attr('r', 25 * 4)
    //   .attr('fill', '#0F3A71');

    // g.append('circle')
    //   .attr('r', 35 * 2)
    //   .attr('fill', '#ED8B0D');


    function arcAnimation() {
      chartGroup.selectAll('.benefit-arc').attr('d', '');
      chartGroup.selectAll('.benefit-arc')
        .transition()
        .delay((d, i) => i * 500)
        .attrTween('d', (d: any) => {
          const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        });


      g.selectAll('.arc-text')
        .transition()
        .delay((d, i) => i * 500)
        .duration(500)
        .attr('opacity', 1)
    }

    function resetScreen() {
      // commonGroup.select('#director-board-img').attr('opacity', 0);
      // centerCircleGroup.attr('opacity', 0);
      commonGroup.attr('opacity', 0);
      currentProcess.attr('opacity', 0);
      processFirstPart.attr('opacity', 0);
      processSecondPart.attr('opacity', 0);
      // solutionGroup.attr('opacity', 0);
      benefitGroup.attr('opacity', 0);
      nlpTextGroup.attr('opacity', 0);
      nlpBottomTextGroup.attr('opacity', 0);
      that.isShowScreen4 = false;
      that.isShowScreen6 = false;
      // hideSwitchAnimation('switch-line-0');
      // hideSwitchAnimation('switch-line-1');
      // hideSwitchAnimation('switch-line-2');
    }

    const switchLineId = 'switch-line-';

    function showScreen5() {
      // commonGroup.attr('opacity', 1);
      if (!visitedScreen.screen5) {
        visitedScreen.screen5 = true;
        that.checkBox1.nativeElement.click();
      }
      // startSwitchAnimation(`switch-line-0`);
      // startSwitchAnimation(`switch-line-1`);
      // startSwitchAnimation(`switch-line-2`);
      centerCircleGroup
        .transition()
        .duration(400)
        .attr({
          'opacity': 0.1,
          'transform': 'translate(696,360)',
        });


    }

    function showScreen4() {
      that.isShowScreen4 = true;
      nlpTextGroup.attr('opacity', 1);
      nlpBottomTextGroup.attr('opacity', 1);
      commonGroup.attr('opacity', 1);
      centerCircleGroup.attr('opacity', 1);
      if (!visitedScreen.screen4) {
        visitedScreen.screen4 = true;


        centerCircleGroup.selectAll('.image')
          .attr('opacity', 1)
          .transition()
          .delay((d, i) => i * 200)
          .duration(500)
          .attr('transform', d => `translate(${d.finalX},${d.finalY}) scale(1, 1)`)
          .call(endAll, function () {
            centerCircleGroup.selectAll('.docs')
              .attr('opacity', 1)
              .transition()
              .delay((d, i) => i * 200)
              .duration(500)
              .attr('transform', d => `translate(${d.finalX},${d.finalY}) scale(1, 1)`)
              .call(endAll, function () {
                animatePlygon(0);

                startDocAnimation();
                // centerCircleGroup.selectAll('.doc-line')
                //   .transition()
                //   .duration(500)
                //   .delay((d, i) => i * 500)
                //   .attr('width', d => d.width)
                //   .attr('height', d => d.height);

                centerCircleGroup.selectAll('.dots')
                  .transition()
                  .delay((d, i) => i * 200)
                  .attr('opacity', 1);
              });
          });
      } else {

        centerCircleGroup
          .transition()
          .duration(200)
          .attr({
            'opacity': 1,
            'transform': 'translate(696,310)',
          });

      }
    }

    function hideScreen4elements() {
      centerCircleGroup.transition().attr('opacity', 0);
    }

    function hidescreen2Elemenets() {
      // console.log("hidescreen2Elemenets triggered");
      commonGroup.select('#director-img')
        .attr('height', (d) => d.height)
        .attr('width', (d) => d.width)
        .transition()
        .duration(0)
        .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
        .attr('opacity', 0);

      currentProcess.select('#screen-1-2-img-4')
        .transition()
        .duration(0)
        .attr({
          'transform': (d) => `translate(${d.finalX}, ${d.finalY})`,
          'opacity': 1
        });
    }

    function hideDirectorElement() {
      commonGroup.select('#director-board-img')
        .transition()
        .ease('linear')
        .duration(1)
        .attr('transform', (d) => `translate(${d.x}, ${d.y}) scale(0)`);

      commonGroup.select('#director-img')
        .attr('height', (d) => d.scaleHeight)
        .attr('width', (d) => d.scaleWidth)
        .attr('transform', (d) => `translate(${d.newX}, ${d.newY})`)
        .transition()
        .duration(1)
        .attr('opacity', 0);
    }

    function animateNotification() {



      smallNotificationGroup.attr('opacity', 1);
      smallNotificationGroup.selectAll('.message-line').each(function (item, i) {
        const index = i + 2;
        repeatTransition(`small-note-${index}`, d3.select(this), 1000, 200, index === 7 ? function () {
          notificationGroup.attr('opacity', 1);
          notificationGroup.selectAll('.notification')
            .transition()
            .delay((d, i) => i * 500)
            .duration(500)
            .ease('bounce')
            .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
            .attr('opacity', 1)
            .call(endAll, function () {
              // console.log('end');
            });
        } : '');
      });
    }
    return {
      changeScreen() {
        resetScreen();
        if (that.activeStartSlide === 'screen-1') {
          if (visitedScreen.screen2) {
            hidescreen2Elemenets();
          }
          if (!visitedScreen.screen1) {
            visitedScreen.screen1 = true;
            currentProcess.select('#screen-1-img-1')
              .transition()
              .duration(500)
              .ease('linear')
              .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
              .attr('opacity', 1)
              .call(endAll, () => {
                currentProcess.select('#screen-1-img-2')
                  .transition()
                  .delay(100)
                  .duration(500)
                  .ease('linear')
                  .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                  .attr('opacity', 1)
                  .call(endAll, function () {
                    currentProcess.select('#screen-1-img-5')
                      .transition()
                      .delay(100)
                      .duration(500)
                      .ease('linear')
                      .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                      .attr('opacity', 1)
                      .call(endAll, function () {
                        currentProcess.select('#screen-1-img-3')
                          .transition()
                          .delay(100)
                          .duration(500)
                          .ease('linear')
                          .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                          .attr('opacity', 1)
                          .call(endAll, function () {
                            currentProcess.select('#screen-1-img-4')
                              .transition()
                              .delay(100)
                              .duration(500)
                              .ease('linear')
                              .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                              .attr('opacity', 1)
                              .call(endAll, function () {
                                bubbleGroup.attr('opacity', 1);
                                bubbleGroup.selectAll('.bubble')
                                  .transition()
                                  .delay((d, i) => i * 500)
                                  .duration(500)
                                  .ease('bounce')
                                  .attr('opacity', 1)
                                  .attr('height', (d) => d.height)
                                  .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                                  .call(endAll, function () {
                                    startConversationAnimation();
                                    setTimeout(() => {
                                      startIpadAnimation();
                                    }, 1000);
                                  });
                              });
                          });
                      });
                  });
              });
          } else {
            startConversationAnimation();
            startIpadAnimation();
          }
          currentProcess.attr('opacity', 1);
          processFirstPart.attr('opacity', 1);
        }
        if (that.activeStartSlide === 'screen-2') {

          if (visitedScreen.screen3) {
            // PB Start
            screen3ForwardAnimationStart = false; //Stop all forward animation
            hideScreen3Elements();
            challengesGroup.selectAll('.challenges')
              .interrupt() //Interrupt all challenges transition
              .attr('opacity', 0); //Hide all challenges transition
            // PB End
          }

          if (!visitedScreen.screen2) {
            visitedScreen.screen2 = true;
            currentProcess.select('#screen-1-2-img-1').attr('opacity', 1)
              .transition()
              .duration(500)
              .ease('linear')
              .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`);

            currentProcess.select('#screen-1-2-img-2')
              .transition()
              .delay(500)
              .duration(500)
              .ease('linear')
              .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
              .attr('opacity', 1)
              .call(endAll, () => {
                commonGroup.attr('opacity', 1);
                if (that.activeStartSlide === 'screen-2') {
                  commonGroup.select('#director-img')
                    .transition()
                    .delay(100)
                    .duration(500)
                    .ease('linear')
                    .attr('height', (d) => d.height)
                    .attr('width', (d) => d.width)
                    .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                    .attr('opacity', 1)
                    .call(endAll, function () {
                      currentProcess.select('#screen-1-2-img-4')
                        .transition()
                        .delay(100)
                        .duration(500)
                        .ease('linear')
                        .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
                        .attr('opacity', 1)
                        .call(endAll, function () {
                          if (that.previousTraversedSlide === 'screen-1') {
                            animateNotification();
                          }
                        });
                    });
                }
              });
          } else {
            currentProcess.selectAll('.slide-1-2').attr('opacity', 0);
            notificationGroup.attr('opacity', 0);
            notificationGroup.selectAll('.notification')
              .attr({
                'opacity': 0,
                'transform': (d) => `translate(${d.x}, ${d.y}) scale(0)`
              });


            currentProcess.select('#screen-1-2-img-4')
              .attr({
                'opacity': 1,
                'transform': (d) => `translate(${d.finalX}, ${d.finalY})`,
              });

            commonGroup.select('#director-img')
              .transition()
              .attr('height', (d) => d.height)
              .attr('width', (d) => d.width)
              .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY})`)
              .attr('opacity', 1);

            setTimeout(() => {
              currentProcess.selectAll('.slide-1-2')
                .transition()
                .duration(50)
                .attr('opacity', 1)
                .call(endAll, () => {
                  animateNotification();
                });

            }, 200);
          }
          commonGroup.attr('opacity', 1);
          currentProcess.attr('opacity', 1);
          processSecondPart.attr('opacity', 1);
        }
        if (that.activeStartSlide === 'screen-3') {
          commonGroup.attr('opacity', 1);
          commonGroup.select('#director-board-img')
            .transition()
            .attr('transform', (d) => `translate(${d.finalX}, ${d.finalY}) scale(1)`)
            .attr('opacity', 1);
          if (visitedScreen.screen4) {
            hideScreen4elements();
          }
          if (!visitedScreen.screen3) {
            visitedScreen.screen3 = true;
          }
          commonGroup.select('#director-img')
            .transition()
            .duration(500)
            .ease('linear')
            .attr('height', (d) => d.scaleHeight)
            .attr('width', (d) => d.scaleWidth)
            .attr('transform', (d) => `translate(${d.newX}, ${d.newY})`)
            .attr('opacity', 1)
            .call(endAll, () => {
              // TODO : pratik call next function
              startMonitorAnimation(); // PB
            });
        }
        // PB Start
        if (that.activeStartSlide === 'screen-4') {
          if (that.previousTraversedSlide === 'screen-3') {
            commonGroup.attr('opacity', 1);
            closeExcelSectionAnimation();
          }
          if (that.previousTraversedSlide === 'screen-5') {
            showScreen4();
          }

        }
        // PB End
        if (that.activeStartSlide === 'screen-5') {
          // solutionGroup.attr('opacity', 1);
          hideDirectorElement();
          if (!visitedScreen.screen4) {
            visitedScreen.screen4 = true;
            centerCircleGroup.selectAll('.image').attr('opacity', 1)
              .transition()
              .duration(0)
              .call(endAll, function () {
                centerCircleGroup.selectAll('.docs').attr('opacity', 1)
                  .transition()
                  .duration(0)
                  .call(endAll, function () {
                    animatePlygon(0);
                    docLinesData.forEach((lines, i) => {
                      lines
                        .attr({
                          'opacity': 1,
                          'stroke-width': 5,
                          'stroke': docLinesColors[i],
                          'stroke-linecap': 'round'
                        })
                        .transition()
                        .attr('stroke-dashoffset', 0);
                    });
                    centerCircleGroup.selectAll('.dots').attr('opacity', 1);
                  });
              });
          }
          commonGroup.attr('opacity', 1);
          showScreen5();
        }
        if (that.activeStartSlide === 'screen-6') {
          setTimeout(() => {
            that.isShowScreen6 = true;
            benefitGroup.attr('opacity', 1);
            hideDirectorElement();
            // arcAnimation();

            if (!visitedScreen.screen6) {
              visitedScreen.screen6 = true;
              chartGroup.selectAll('.benefit-arc').attr('d', '');
              g.selectAll('.arc-text').attr('opacity', 0);

              g.selectAll('.main-circle')
                .attr('r', 0)
                .transition()
                .delay((d, i) => i * 1200)
                .duration(1200)
                .attr('r', d => d.radius)
                .call(endAll, function () {
                  g.selectAll('.arc-center-text').attr('opacity', 1);
                  arcAnimation();
                });
            }
          }, 500);
        }
        switch (that.activeStartSlide) {
          case 'screen-1':
            baseImage = '/assets/images/field-coaching/screen1.png';
            break;
          case 'screen-2':
            baseImage = '/assets/images/field-coaching/screen2.png';
            break;
          case 'screen-3':
            baseImage = '/assets/images/field-coaching/screen3.png';
            break;
          case 'screen-4':
            baseImage = '/assets/images/field-coaching/screen5.png';
            break;
          case 'screen-5':
            baseImage = '/assets/images/field-coaching/screen5.png';
            break;
          default:
            baseImage = '/assets/images/field-coaching/screen6.png';
            break;
        }
        // image.attr('xlink:href', baseImage);
      },
      resize() {
        const aspect = resize();
      },
      destroy() {
        d3.selectAll('.animate').interrupt();
        d3.selectAll('.challenges').interrupt();
      }
    };
  }


  private _initNplSvgSection(containerId) {
    const container = d3.select('#' + containerId);
    const WIDTH = 720;
    const HEIGHT_OFFSET = 365 / 720;
    const HEIGHT = WIDTH * HEIGHT_OFFSET;
    const svg = container.append('svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT)
      /* .on('click', function () {
        console.log(d3.mouse(this));
      }) */;

    const solutionGroup = svg.append('g')
      .attr({
        'id': 'solution-main-group',
        'class': 'solution-main-group',
        // 'transform': 'translate(200,231) scale(0,0)'
      });

    const lineGroup = solutionGroup.append('g')
      .attr({
        'id': 'solution-line-main-group',
        'class': 'solution-line-main-group',
        // 'transform': 'translate(200,231) scale(0,0)'
      });


    // Left View Lines
    const switchCordinates = [
      [[44, 102], [38, 102], [38, 172], [52, 172]],
      [[44, 57], [18, 57], [18, 228], [52, 228]],
      [[44, 12], [2, 12], [2, 295], [52, 295]]
    ];

    const switchLineData = [];

    switchCordinates.forEach((lineData, i) => {
      // tslint:disable-next-line: max-line-length
      switchLineData.push(this.createDashedLine(`switch-line-${i}`, lineGroup, lineData, 'dashed-lines', '#ffffff', '8,3', 1).classed('switch-lines animate', true));
    });



  }

  onSwitchClick(event, switchId, blockId) {
    if (event.target.checked) {
      this.startSwitchAnimation(switchId, blockId);
      if (event.target.id === 'checkbox1') {
        this._renderer.addClass(document.getElementById('right-file'), 'active');
        if (!this.checkBox2.nativeElement.checked) {
          this.checkBox2.nativeElement.click();
        }
        if (!this.checkBox3.nativeElement.checked) {
          this.checkBox3.nativeElement.click();
        }
      }
    } else {
      this.hideSwitchAnimation(switchId);
      this._renderer.addClass(document.getElementById(blockId), 'hidden');
      if (event.target.id === 'checkbox1') {
        this._renderer.removeClass(document.getElementById('right-file'), 'active');
      }
    }
  }

  private startSwitchAnimation(id, blockId) {
    const switchLine = d3.select(`#${id}`);
    switchLine
      .attr({
        'stroke-dashoffset': switchLine.node().getTotalLength(),
        'opacity': 1
      }).
      transition()
      .attr('stroke-dashoffset', 0)
      .each('end', () => {
        this._renderer.removeClass(document.getElementById(blockId), 'hidden');
      });
  }

  private hideSwitchAnimation(id) {
    // console.log("hideSwitchAnimation: ", id);
    const switchLine = d3.select(`#${id}`);
    switchLine
      .transition()
      .attr({
        'stroke-dashoffset': switchLine.node().getTotalLength(),
        'opacity': 0
      });
  }

  private _line() {
    return d3.svg.line()
      .x((d) => d[0]) // set the x values for the line generator
      .y((d) => d[1]);
    // .interpolate('basis'); // set the y values for the line generator
  }


  private createDashedLine(id, group, lineData, className, strokeColor = '#000000', strokeDash = 'none', strokeWidth = 1) {
    return group.append('path')
      .datum(lineData)
      .attr('d', this._line())
      .attr('stroke', strokeColor)
      .attr('id', id)
      .attr('class', className)
      .attr('stroke-width', strokeWidth)
      .attr('stroke-dasharray', function (d: any) {
        d.pathLength = d3.select(this).node().getTotalLength();
        const dashing = '8, 3';
        const dashLength =
          dashing
            .split(/[\s,]/)
            .map(function (a) { return parseFloat(a) || 0 })
            .reduce(function (a, b) { return a + b; });

        // console.log('dashLength', dashLength);
        const dashCount = Math.ceil(d.pathLength / dashLength);
        // console.log('dashCount', dashCount);

        const newDashes = new Array(dashCount).join(dashing + ' ');
        const dashArray = newDashes + ' 10 , ' + (d.pathLength + 7);
        // return `${d.pathLength}, ${d.pathLength}`;
        return dashArray;
      })
      // .attr('stroke-dashoffset', (d: any) => d.pathLength)
      .attr('stroke-dashoffset', (d: any) => d.pathLength + 7)
      .attr('fill', 'none');
  }

  onTryOurApp() {
    const dialogRef = this.dialog.open(FieldCoachingAppDialogComponent, {
      maxWidth: '98%',
      width: '98%',
      height: '98%',
      // data: user.userId
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy(): void {
    if (this._unsubscribeResize) {
      this._unsubscribeResize();
    }

    if (this._svgSection) {
      this._svgSection.destroy();
    }
    this.dataShareService.onCustomEvent.next({ event: 'removeClassToElement', data: {selector: '#header', class: 'humburger-header'}});
  }

}
