import { Component, Renderer2, OnInit } from "@angular/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";

import { DataShareService } from "./shared/data-share.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { AuthService } from "./shared/auth.service";
import { HttpWrapperService } from "./services/http-wrapper.service";
import { environment } from "src/environments/environment";
import { UserType } from "./shared/enum/enum";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "ai-gallery-web";

  marketStatus;
  marketStatusToPlot;
  baner;

  ngOnInit() {
    this.renderer.listen(window, "scroll", ($event) => {
      // this.scroll = (window.scrollY / this.sections);
      // console.log(window.scrollY);
      if (window.scrollY > 150) {
        this.renderer.addClass(document.body, "sticky-header");
      } else {
        this.renderer.removeClass(document.body, "sticky-header");
      }
    });
  }

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private dataShareService: DataShareService,
    private _gaService: GoogleAnalyticsService,
    private _authService: AuthService,
    private _http: HttpWrapperService
  ) {
    if (_authService.isStoredUser()) {
      this.fetchRefreshedToken();
    }
    this.baner = {
      title: "zs ai gallery",
      storyThemeClass: "",
    };

    this.router.events.subscribe((event) => {
      this.baner = {
        title: "zs ai gallery",
      };

      if (event instanceof NavigationStart) {
        this.renderer.removeClass(document.body, "parallax");
        this.renderer.removeClass(document.body, "field-story");
        this.renderer.removeClass(document.body, "adaptive-pricing");

        switch (event.url) {
          case "/gallery/story-telling-page/field_coaching":
            case "/gallery/story-telling-page/virtual_rep_trainer":
          case "/gallery/story-telling-page/adaptive_attribute_based_offers":
          // TODO:RM Rename the url
          case "/gallery/story-telling-page/ctemp":
            this.renderer.addClass(document.body, "parallax");
            break;
        }
        if (event.url.endsWith("/field_coaching")) {
          this.renderer.addClass(document.body, "field-story");
        } else if (event.url.endsWith("/adaptive_attribute_based_offers")) {
          this.renderer.addClass(document.body, "adaptive-pricing");
          // TODO:RM Rename the url
        } else if (event.url.endsWith("/ctemp")) {
          this.renderer.addClass(document.body, "ctemp");
        }
        else if (event.url.endsWith("/virtual_rep_trainer")) {
          this.renderer.addClass(document.body, "field-story");
        } /* else if (event.url.endsWith("/atlas-intelligence-kraft")) {
          this.renderer.addClass(document.body, "atlas-intelligence-kraft");
        } */
        // console.log(event)
        // const removeClassForRoutes = ['/', '/gallery', '/gallery?category=usecases', '/gallery?category=experiments', '/login', '/admin'];
        // const removeClassForRoutes = ['/', '/gallery', '/login', '/admin'];
        // if (removeClassForRoutes.indexOf((event.url.split('?'))[0]) > -1) {
        //   this.renderer.removeClass(document.body, 'inner-page-wrapper');
        // } else {
        //   this.renderer.addClass(document.body, 'inner-page-wrapper');
        // }
        // const loginContainerClassForRoutes = ['/', '/login', '/login/admin', '/admin', '/gallery'];
        // if (loginContainerClassForRoutes.indexOf((event.url.split('?'))[0]) > -1) {
        //   this.renderer.addClass(document.body, 'login-admin-container');
        //   this.renderer.removeClass(document.body, 'inner-page-wrapper');
        // } else {
        //   this.renderer.removeClass(document.body, 'login-admin-container');
        //   this.renderer.addClass(document.body, 'inner-page-wrapper');
        // }

        if (this.baner.storyThemeClass) {
          this.renderer.removeClass(document.body, this.baner.storyThemeClass);
        }
      }
      if (event instanceof NavigationEnd) {
        // console.log(this.router.parseUrl(this.router.url));
        // console.log((event.url.split('?'))[0]);
        const loginContainerClassForRoutes = [
          "/",
          "/login",
          "/login/admin",
          "/admin",
          "/gallery",
        ];
        if (
          loginContainerClassForRoutes.indexOf(
            event.urlAfterRedirects.split("?")[0]
          ) > -1
        ) {
          this.renderer.addClass(document.body, "login-admin-container");
          this.renderer.removeClass(document.body, "inner-page-wrapper");
        } else {
          this.renderer.removeClass(document.body, "login-admin-container");
          this.renderer.addClass(document.body, "inner-page-wrapper");
        }
        _gaService.notifyPageView(event.url.split("?")[0]);
        if (event.url.startsWith("/gallery/story-telling-page/")) {
          this.renderer.addClass(document.body, "business-view-wrapper");
        } else {
          this.renderer.removeClass(document.body, "business-view-wrapper");
        }
        if (event.url.endsWith("/atlas-intelligence-kraft")) {
          this.renderer.addClass(document.body, "atlas-intelligence-kraft");
        }
        const scrollingContainer = document.getElementById("scroll-container");
        if (scrollingContainer) {
          scrollingContainer.scrollTop = 0;
        }
      }
    });

    this.dataShareService.updateBanerData.subscribe((updatedDataInfo) => {
      this.baner.title = updatedDataInfo.title || "zs ai gallery";
      // updatedDataInfo.storyThemeClass = 'Heyme';
      // if (updatedDataInfo && updatedDataInfo.storyThemeClass) {
      //   this.renderer.addClass(document.body, updatedDataInfo.storyThemeClass);
      // }
      this.baner.description =
        updatedDataInfo.description ||
        "ZS AI Gallery is an interactive portfolio and experiments of AI & Machine Learning use cases developed by ZS";
    });

    this.dataShareService.onCustomEvent.subscribe(($e) => {
      // console.log(data);
      switch ($e.event) {
        case "refresh_token":
          this.fetchRefreshedToken();
          break;
      }
    });
  }

  fetchRefreshedToken() {
    // this.dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: true });
    this._http.get(`${environment.api_url}/auth/refresh-token`).subscribe(
      (data: any) => {
        // this.dataShareService.updateCustomEvent({ event: 'togglePrimaryLoader', data: false });
        // TODO:PK validate
        if (data.data.userDetails.role === "admin") {
          this.renderer.addClass(document.body, "admin-view");
          this.renderer.removeClass(document.body, "user-view");
        } else {
          this.renderer.removeClass(document.body, "admin-view");
          this.renderer.addClass(document.body, "user-view");
        }
        this._authService.setUser({
          id: data.data.userDetails.userId,
          emailId: data.data.userDetails.emailId,
          name: data.data.userDetails.name,
          isAdmin: data.data.userDetails.role === "admin",
          // tslint:disable-next-line: max-line-length
          type:
            data.data.userDetails.role === "admin"
              ? UserType.ADMIN
              : data.data.userDetails.type === UserType.SSO
              ? UserType.ZS
              : UserType.NON_ZS,
          userType: data.data.userDetails.type,
          token: data.data.token,
          data: data.data.userDetails,
          _s: data.data._s,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
