import { Injectable } from '@angular/core'
// import { Subject, BehaviorSubject } from 'rxjs';
import { StoryService } from '../story-page/story.service'



@Injectable()
export class VisulizationService {
// 
 	// private actionClicked = new Subject <any> ();
  // 
  
  constructor(private storyService: StoryService) {}

  onActionClick(actionData:any) {
  	// alert("action clicked");
  	//console.log(actionData);
	// this.actionClicked.next(data);
  	
  	if (actionData.loadSection) {
  		// alert("IF --> Load section")
  		this.storyService.updateSection(actionData);
  		// this.loadSection.next(actionData);	
  	}
  }



}