import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material';

@Component({
  selector: 'pop-up',
  templateUrl: 'pop-up.component.html'
})
export class PopUpComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<PopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
    displayedColumns: string[] = ['row_lable', 'start_date', 'row_tooltip'];
  	
	dataSource;
  ngOnInit() {
      this.dataSource = new MatTableDataSource(JSON.parse(this.data));
	}
  
  onNoClick(): void {
	   	this.dialogRef.close(this.data);
	}
}