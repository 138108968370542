import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { GalleryService } from '../gallery.service';
import { CdkDragDrop, CdkDragMove, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-tab-settings-overlay',
  templateUrl: './tab-settings-overlay.component.html',
  styleUrls: ['./tab-settings-overlay.component.css'],
  providers: [GalleryService]
})
export class TabSettingsOverlayComponent {

  public isUpdated = false;
  public categoryList = [];
  public selectedCategory = null;
  public isDuplicate = false;
  public oldName = '';
  public oldColor = '';
  public isStoryUpdated = false;

  public validateKeys = {
    'name': false,
    'color': false,
  }

  public validateEmpty = {
    color: false,
    name: false,
  }

  colors = [];

  constructor(
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TabSettingsOverlayComponent>,
    public gallaryService: GalleryService,
  ) {
    dialogRef.disableClose;
    this.selectedCategory = JSON.parse(JSON.stringify(data.selected));
    this.oldName = this.selectedCategory.name;
    this.oldColor = this.selectedCategory.color;
    data.stories && data.stories.forEach(e => {
      e.oldCategoryId = e.categoryId;
    });
    this.categoryList = data.list;
    this.colors = JSON.parse(JSON.stringify(gallaryService.getColors()));
    this.updateColorAvailability();
    this.updateName();
    this.validateSave();
  }

  updateColorAvailability() {
    this.colors.forEach(e => {
      const color = this.categoryList.find(c => e.color !== this.selectedCategory.color && c.color === e.color)
      if (color) {
        e.available = false;
      } else {
        e.available = true;
      }
    });
    this.colors = [...this.colors.filter(e => e.available), ...this.colors.filter(e => !e.available)];
    if (this.oldColor.trim() !== '') {
      const colorIndex = this.colors.findIndex(e => e.color === this.oldColor);
      if (colorIndex > -1) {
        const selectedColor = this.colors[colorIndex];
        this.colors.splice(colorIndex, 1);
        this.colors.splice(0, 0, selectedColor);
      }
    } else {
      this.updateColor(this.colors[0]);
    }
  }

  updateColor(color) {
    if (color.available) {
      this.selectedCategory.color = color.color;
      this.selectedCategory.categoryClass = 'category_' + color.id;
      if (this.selectedCategory.color.trim() === '') {
        this.validateEmpty.color = true;
      } else {
        this.validateEmpty.color = false;
        this.validateKeys.color = this.oldColor !== this.selectedCategory.color;
      }
      this.validateSave();
    }
  }

  updateName() {
    if (this.selectedCategory.name.trim() === '') {
      this.validateEmpty.name = true;
    } else {
      this.validateEmpty.name = false;
      this.validateKeys.name = this.oldName !== this.selectedCategory.name;
    }
    this.validateSave();
  }

  updateValue($e, story) {
    story.categoryId = $e.target.value;
    this.validateChange();
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  public isDataLoading = false;

  onSave() {
    if (!this.isUpdated && !this.isStoryUpdated) {
      return
    }
    if (!this.data.isEdit) {
      const obj = {
        "name": this.selectedCategory.name,
        "color": this.selectedCategory.color,
      };
      this.isDataLoading = true;
      this.gallaryService.createCategory(obj).subscribe((res : any) => {
        this.showToastMessage(res.message, false);
        const data = {
          isEdit: false,
          category: {
            name: this.selectedCategory.name,
            color: this.selectedCategory.color,
            categoryId: res.data.categoryId,
            categoryClass: 'category_' + this.colors.find(e => this.selectedCategory.color === e.color).id
          }
        }
        this.isDataLoading = false;
        this.dialogRef.close(data);
      }, (err) => {
        this.showToastMessage(err.error.message);
        this.isDataLoading = false;
      })
    } else {
      const obj = {
        "name": this.selectedCategory.name,
        "color": this.selectedCategory.color,
        "categoryId": this.selectedCategory.categoryId,
        "stories": this.data.stories.map(e => {
          return {
            storyId: e.storyId,
            categoryId: e.categoryId,
          }
        }),
      };
      this.isDataLoading = true;
      this.gallaryService.updateCategory(obj).subscribe((res: any) => {
        this.showToastMessage(res.message, false);
        const data = {
          isEdit: this.data.isEdit,
          isColorChanged: this.oldColor !== this.selectedCategory.color,
          stories: this.data.stories,
          category: this.selectedCategory,
        }
        this.isDataLoading = false;
        this.dialogRef.close(data);
      }, (err) => {
        this.showToastMessage(err.error.message);
        this.isDataLoading = false;
      })
    }
  }

  validateSave() {
    this.isUpdated = false;
    let isEmpty = false;
    for (const key in this.validateEmpty) {
      if (Object.prototype.hasOwnProperty.call(this.validateEmpty, key)) {
        if (this.validateEmpty[key] === true) {
          isEmpty = true;
        }
      }
    }

    if (isEmpty) { return false }

    for (const key in this.validateKeys) {
      if (Object.prototype.hasOwnProperty.call(this.validateKeys, key)) {
        if (this.validateKeys[key] === true) {
          this.isUpdated = true;
        }
      }
    }

    return this.isUpdated;
  }

  validateChange() {
    this.isStoryUpdated = false;
    for (let i = 0; i < this.data.stories.length; i++) {
      const story = this.data.stories[i];
      if (story.oldCategoryId !== story.categoryId) {
        this.isStoryUpdated = true;
        i += this.data.stories.length;
      }
    }
  }

  showToastMessage(message, isError = true, duration= 3000) {
    this._snackBar.open(message, '', {
			duration,
			panelClass: isError ? 'error' : 'success',
			verticalPosition: 'top',
			horizontalPosition: 'right'
		})
  }

}
