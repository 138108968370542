import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { WidgetService } from '../widgets.service';
import moment from 'moment';
import * as _ from 'lodash';
import * as topojson from 'topojson';
import usLocationData from './usLocationData.json';

declare var require: any;
declare let d3: any;

@Component({
  selector: 'app-us-slider-chart',
  templateUrl: './us-slider-chart.component.html',
  styleUrls: ['./us-slider-chart.component.css'],
  encapsulation: ViewEncapsulation.None //ShadowDom
})
export class USSliderChartComponent implements OnInit, OnDestroy {

  @ViewChild('slider3') sliderElement: ElementRef;
  @ViewChild('usSliderChart') chartElement: ElementRef;

  constructor(private widgetService: WidgetService) {
  }

  @Input() widgetData;
  summaryData = { "totalTargetsIdentified": 0, "writers": 0, "newWriters": 0, "repeatWriters": 0 };
  date_data = [];
  site_data = [];
  loadUsChart = false;
  timerCallback;
  monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  currentMonth = 'Jan';

  ngOnInit() {
    this.summaryData.totalTargetsIdentified = _.uniqBy(this.widgetData.data, 'CUSTOMER_NUMBER').length;
    setTimeout(() => {
      this.loadChart(this.widgetData.data);
    }, 0);
  }

  loadChart(data) {
    let width = 700, height = 470;

    var projection = d3.geo.albersUsa()
      .scale(920)
      .translate([width / 2, height / 2]);

    var path = d3.geo.path()
      .projection(projection);

    var svg = d3.select(this.chartElement.nativeElement).append("svg")
      .attr("width", width)
      .attr("height", height);

    this.loadUsChart = true;
    svg.append("path")
      .datum(topojson.feature(usLocationData, usLocationData.objects.land))
      .attr("d", path)
      .attr("class", "us-map-chart-path land-boundary");

    svg.append("path")
      .datum(topojson.mesh(usLocationData, usLocationData.objects.counties, function (a, b) { return a !== b && (a.id / 1000 | 0) === (b.id / 1000 | 0); }))
      .attr("d", path)
      .attr("class", "us-map-chart-path county-boundary");

    svg.append("path")
      .datum(topojson.mesh(usLocationData, usLocationData.objects.states, function (a, b) { return a !== b; }))
      .attr("d", path)
      .attr("class", "us-map-chart-path state-boundary");

    for (let i = 0; i <data.length; i++) {
      this.site_data.push({
        lat: parseFloat(data[i].latitude),
        lng: parseFloat(data[i].longitude),
        customerNumber: data[i].CUSTOMER_NUMBER,
        month: data[i].month,
        prod1_nrx_flag: data[i].prod1_nrx_flag,
        created_at: moment(data[i].year + "-" + data[i].month + "-01 00:00:00", "YYYY-MM-DD HH:mm:ss").valueOf()
      });
    }
    this.site_data = _.orderBy(this.site_data, ['created_at'], ['asc']);

    var displaySites = (data) => {
      var sites = svg.selectAll(".zone-circle")
        .data(data, function (d, index) {
          if (!d.cx || !d.cy) {
            d.cx = projection([d.lng, d.lat])[0];
            d.cy = projection([d.lng, d.lat])[1];
          }

          return index;
        });

      sites.enter().append("circle")
        .attr("class", function (d) {
          var className = "zone-circle ";
          className += (d.prod1_nrx_flag == 1) ? "site" : "site-red";
          return className;
        })
        .attr("cx", function (d) {
          return d.cx;
        })
        .attr("cy", function (d) {
          return d.cy;
        })
        .attr("r", 1)
        .transition().duration(200)
        .attr("r", 4);


      sites.exit()
        .transition().duration(200)
        .attr("r", 1)
        .remove();
    };

    var maxDate1 = new Date(Math.max.apply(null, this.widgetData.data.map(function (e) {
      return new Date(e.created_at);
    })));
    var minDate1 = new Date(Math.min.apply(null, this.widgetData.data.map(function (e) {
      return new Date(e.created_at);
    })));
    var minDateUnix = new Date(minDate1.getFullYear(), minDate1.getMonth(), 1);;
    var maxDateUnix = new Date(maxDate1.getFullYear(), maxDate1.getMonth() + 1, 0);;

    d3.select(this.sliderElement.nativeElement).call(d3.slider()
      .axis(true).min(minDateUnix).max(maxDateUnix)
      .on("slide", (evt, value, timerCallback) => {
        let dataTillCurrentMonth, dataTillPreviousMonth, dataPerMonth = [];

        dataTillCurrentMonth = _.filter(this.site_data, function (site) {
          return site.created_at <= value;
        })

        this.resetSummaryData();
        let month = new Date(value).getMonth();
        this.currentMonth = this.monthList[month];
        if (!this.timerCallback && timerCallback) {
          this.resetSummaryData();
          this.timerCallback = timerCallback;
        }

        dataPerMonth = _.filter(this.site_data, function (site) {
          return new Date(site.created_at).getMonth() == month;
        })
        let dataGroupedByNRXFlag = _.groupBy(dataPerMonth, 'prod1_nrx_flag');
        this.summaryData.writers = _.uniqBy(dataGroupedByNRXFlag[1], 'customerNumber').length;

        let writerCustomers = _.uniqBy(dataGroupedByNRXFlag[1], 'customerNumber')
        let previousMonth = new Date(value);
        previousMonth.setMonth(previousMonth.getMonth() - 1)
        if (month != 0) {
          dataTillPreviousMonth = _.filter(this.site_data, function (site) {
            return (site.created_at <= previousMonth.getTime()) && (_.findIndex(writerCustomers, { 'customerNumber': site.customerNumber }) > 0);
          })
          this.summaryData.repeatWriters = 1;
          let dataGroupedByCustomerNumber = _.groupBy(dataTillPreviousMonth, 'customerNumber');
          for (let customer in dataGroupedByCustomerNumber) {
            let sum = _.sumBy(dataGroupedByCustomerNumber[customer], 'prod1_nrx_flag');
            if (sum == 0) {
              this.summaryData.newWriters += 1;
            } else {
              this.summaryData.repeatWriters += 1;
            }
          }
        }
        displaySites(dataTillCurrentMonth);
      })
    );
  }

  resetSummaryData() {
    this.summaryData.writers = 0;
    this.summaryData.newWriters = 0;
    this.summaryData.repeatWriters = 0;
  }

  ngOnDestroy() {
    if (this.timerCallback) {
      clearInterval(this.timerCallback);
    }
  }

}