import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { ContactComponent } from 'src/app/core/contact/contact.component';
import { GalleryService } from '../gallery.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { GAIndex } from 'src/app/shared/enum/enum';

@Component({
  selector: 'app-visit-reason-overlay',
  templateUrl: './visit-reason-overlay.component.html',
  styleUrls: ['./visit-reason-overlay.component.css'],
  providers: [GalleryService]
})
export class VisitReasonOverlayComponent {
  visitReasonForm: FormGroup;
  isFormSubmitted = false;
  isSubmitting = false;
  userInfo = null;
  public purposeList = [{
    name: 'Campus Presentation',
    slug: 'campus_presentation'
  }, {
    name: 'Client Demo',
    slug: 'client_demo'
  }, {
    name: 'Conference',
    slug: 'conference'
  }, {
    name: 'Internal Demo',
    slug: 'internal_demo'
  }, {
    name: 'RFP',
    slug: 'rfp'
  }
  /* , {
    name: 'Testing',
    slug: 'testing'
  } */
  , {
    name: 'Other',
    slug: 'other'
  }];
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContactComponent>,
    public gallaryService: GalleryService,
    private _gaService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar) {
    this.userInfo = data.userInfo;
    this.visitReasonForm = formBuilder.group({
      purpose: [null, Validators.required],
      reason: ['', Validators.required],
    });
  }

  onSubmit() {
    this.isFormSubmitted = true;
    if (this.visitReasonForm.invalid) {
      return false;
    }
    const requestObj = { ...this.visitReasonForm.value, userId: this.userInfo.id };
    requestObj.purpose = this.purposeList.find(p => p.slug === requestObj.purpose);
    this.isSubmitting = true;
    // console.log(requestObj);
    this.gallaryService.setPurposeData(requestObj).subscribe((res: any) => {
      const GA_config = {};
      GA_config[GAIndex.CLIENT_NAME_OR_PURPOSE] = requestObj.reason;
      this._gaService.triggerEvent('Visit', 'Purpose', requestObj.purpose.name, GA_config);
      this.isSubmitting = false;
      this._snackBar.open(res.message, '', {
        duration: 3000,
        panelClass: 'success',
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.dialogRef.close(requestObj);
    }, (err: any) => {
      this.dialogRef.close();
      this._snackBar.open(err.error.message, '', {
        duration: 3000,
        panelClass: 'error',
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
    });
  }
}
