import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


// import { DynamicFormService } from '../../dynamic-form/services/dynamic-form.service';
// import { NlpTextHighlighterService } from './nlp-text-highlighter.service';

import { WidgetService } from "../widgets.service";


@Component({
	selector: 'app-nlp-text-highlighter',
	templateUrl: './nlp-text-highlighter.component.html'
	// providers: [NlpTextHighlighterService]
})
export class NlpTextHighlighterComponent implements OnInit {
	@Input() widgetData;

	_originalHtmlProperty: string = 'No Data Found';

	groupedData;
	selectedDataInfo;

	constructor(private widgetService: WidgetService, private _sanitizer: DomSanitizer) {
		this.widgetService.triggerDataChangeForNlp.subscribe((updatedDataInfo) => {
			if (updatedDataInfo.formField.filterField) {
				this.selectedDataInfo = this.groupedData[updatedDataInfo.formField.filterField];
				this.generateTemplate()
			} else {
				this.selectedDataInfo = null;
			}
		});
	}

	public get sanitizedHtmlProperty(): SafeHtml {
		return this._sanitizer.sanitize(SecurityContext.HTML, this._originalHtmlProperty);
	}

	generateTemplate() {
		var template = '';

		_.forEach(this.selectedDataInfo, (nctValues, key) => {
			var text = [];
			var bioMarkers = '';
			var attributes = '';
			var valuesUnits = '';
			var replaceText = "";
			var replaceWith = "";
			var processedValues = [];

			// console.log("nctValues, key", nctValues, key);
			_.forEach(nctValues, (nctValue, key) => {
				// console.log(nctValue, key);
				if (!text.length) {
					// console.log(nctValue.sent);
					text = nctValue.sent.split('');
				}

				if (nctValue.biom_value) {
					bioMarkers += `	<div class="legends"> 
										<span><i>${nctValue.biom_value}</i></span>
									</div> `;
					if (processedValues.indexOf(nctValue.biom_value) == -1) {
						processedValues.push(nctValue.biom_value);
						replaceWith = ` <span class="nct-biometric"><i>${nctValue.biom_value}</i></span> `;
						// text = this.getReplacedTextString(text, nctValue.biom_value, replaceWith);
						this.stringReplaceAt(text, nctValue.start_biom, nctValue.end_biom, replaceWith);
					}
				} else {
					bioMarkers += `	<div class="legends"> 
										<p class='message-text sm m-0'>NA</p>
									</div> `;
				}

				if (nctValue.attri_val) {
					attributes += ` <div class="legends">
										<span><i>${nctValue.attri_val}</i></span> 
									</div>`;
					if (processedValues.indexOf(nctValue.attri_val) == -1) {
						processedValues.push(nctValue.attri_val);
						replaceWith = ` <span class="nct-attribute"><i>${nctValue.attri_val}</i></span> `;
						// text = this.getReplacedTextString(text, nctValue.attri_val, replaceWith);
						// text = this.stringReplaceAt(text, nctValue.start_attri, replaceWith);
						this.stringReplaceAt(text, nctValue.start_attri, nctValue.end_attri, replaceWith);
					}
				} else {
					attributes += `	<div class="legends"> 
										<p class='message-text sm m-0'>NA</p>
									</div> `;
				}

				if (nctValue.biom_val_value || nctValue.unit_val) {
					var uniTValueText = "";
					
					if (nctValue.biom_val_value) {
						uniTValueText = nctValue.biom_val_value;	
					}
					
					if (nctValue.unit_val) {
						uniTValueText += " " + nctValue.unit_val;
					}

					if (uniTValueText) {

						valuesUnits += `<div class="legends"> 
											<span><i>${uniTValueText}</i></span> 
										</div>`;

						if (processedValues.indexOf(uniTValueText) == -1) {
							processedValues.push(uniTValueText);
							replaceWith = ` <span class="nct-value"><i>${uniTValueText}</i></span> `;
							// text = this.getReplacedTextString(text, nctValue.biom_val_value, replaceWith);
							this.stringReplaceAt(text, (nctValue.start_biom_val), nctValue.end_unit, replaceWith);

						}

					} else {
						valuesUnits += `	<div class="legends"> 
										<p class='message-text sm m-0'>NA</p>
									</div> `;
					}
				}

			});	

			template += `
				<div class="row-wrapper" *ngFor="let item of selectedDataInfo | keyvalue">
				    <div class="ml-content">
				      ${text.join('')}
				    </div>
				    <div class="ml-legends">
				      <div class="row m-0">
				        <div class="col-6 col-xl-4 biom p-0">
				          <div class="head"><span>Biomarker</span></div>
				          
				            ${bioMarkers}
				          
				        </div>
				        <div class="col-6 col-xl-4 attr p-0">
				          <div class="head"><span>Attribute</span></div>
				          
				            ${attributes}
				          
				        </div>
				        <div class="col-6 col-xl-4 valUnit p-0">
				          <div class="head"><span>Value/Unit</span></div>
				          
				            ${valuesUnits}
				          
				        </div>
				      </div>
				    </div>
				</div>
			`;


		});

		this._originalHtmlProperty = template;
	}

	stringReplaceAt(text, startIndex, endIndex, replacement) {
		text[startIndex] = replacement;
		// console.log(text);
		// console.log(startIndex + 1,  ((endIndex - 1) - startIndex))
		let replaceWith = Array(((endIndex - 1) - startIndex)).join(' ').split(' ');
		// console.log(replaceWith);
		text.splice(startIndex + 1,  ((endIndex - 1) - startIndex), ...replaceWith);
		// console.log(text);
	
	}

	// stringReplaceAt(text, startIndex, endIndex, replacement) {
	// 	return text.substr(0, startIndex) + replacement + text.substr(endIndex);
	// }

	getReplacedTextString(originalText, searchWith, replaceWith) {
		// var replaceText = "<span class='temp'>" + searchWith + "</span>"; /(?:(^|[^a-z]))(([^a-z]*)(?=cat)cat)(?![a-z])/
		//return originalText.replace(new RegExp(searchWith, 'g'), replaceWith);	
		return originalText.replace(new RegExp(" " + searchWith + " ", 'g'), replaceWith);

		// return originalText.replace(new RegExp("(?:(^|[^a-z0-9]))(([^a-z0-9]*)(?=" + searchWith + ")" + searchWith + ")(?![a-z0-9])", 'gi'), replaceWith);	
	}

	ngOnInit() {
		// console.log(this.widgetData.data);
		this.groupedData = _.groupBy(this.widgetData.data, 'nct_id');
		_.forEach(this.groupedData, (value, key) => {
			this.groupedData[key] = _.groupBy(this.groupedData[key], 'sent_id');
		});
		// console.log(this.groupedData);
		// console.log("this,chartData", this.chartData);
		// this.form = this.dynamicFormService.toFormGroup(this.interactionDataList);
	}
}
