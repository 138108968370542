import { Injectable } from '@angular/core';
import { HttpWrapperService } from '../../../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class LoginService {

  constructor(private _http: HttpWrapperService) { }

  login(requesterName, accessCode, role: string, loginType: string): Observable<Response> {
    let request: any = {
      requesterName: requesterName || '',
      accessCode,
      role,
      accessTime: (new Date()).getTime(),
      loginType
    };
    if (loginType === 'sso') {
      request = {
        loginType,
        tid: accessCode,
        accessTime: (new Date()).getTime()
      };
    }
    return this._http.post(environment.api_url + '/auth/login', request);
    // return this._http.post('https://zwnfqn0pfk.execute-api.us-east-1.amazonaws.com/prod/auth/testsaml', request);
  }

  logout(userId): Observable<Response> {
    const request = {
      userId: userId,
      logoutTime: (new Date()).getTime()
    };
    return this._http.post(environment.api_url + '/auth/logout', request);
  }

  initiateSAMLLogin() {
    return this._http.get(`https://aigallery-api.demo.zsservices.com/prod/auth/saml`, {observe: 'response'});
    // return this._http.get(`${environment.api_url}/auth/saml`);
  }
}
