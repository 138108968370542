import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GalleryService } from 'src/app/gallery/gallery.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { UserType, GAIndex } from 'src/app/shared/enum/enum';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [GalleryService]
})
export class ContactComponent {
  contactUsForm: FormGroup;
  isFormSubmitted = false;
  isSubmitting = false;
  userInfo = null;
  USER_TYPE = UserType;
  stories = [];
  public usageList = [{
    name: 'Client Demo',
    slug: 'client_demo'
  }, {
    name: 'ZS Internal',
    slug: 'zs_internal'
  }];
  public currentDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContactComponent>,
    public gallaryService: GalleryService,
    private _gaService: GoogleAnalyticsService,
    private _snackBar: MatSnackBar) {
    this.userInfo = data.userInfo;
    // console.log(data);
    this.stories = data.stories;
    this.contactUsForm = formBuilder.group({
      name: [(this.userInfo.name || ''), Validators.required],
      emailId: [(this.userInfo.emailId || ''), [Validators.required, Validators.email]],
      stories: [[], Validators.nullValidator],
      reason: ['', Validators.nullValidator],
      purpose: ['', Validators.required],
      usage: [null, Validators.required],
    });
  }

  onDropdownChange(event) {
    // this.contactUsForm.s
  }

  onSubmit() {
    this.isFormSubmitted = true;
    if (this.contactUsForm.invalid) {
      return false;
    }

    const requestObj = { ...this.contactUsForm.value, type: this.userInfo.userType, userId: this.userInfo.id };
    // console.log(this.stories);
    // const newStoryData = [];
    // requestObj.stories.forEach(element => {
    //   const selectedStory = this.stories.find(story => story.url === element);
    //   newStoryData.push({
    //     slug: selectedStory.url,
    //     name: selectedStory.title
    //   });
    // });

    // requestObj.stories = [...newStoryData];
    requestObj.stories = requestObj.stories.map(v => {
      return {
        slug: v.url,
        name: v.title
      };
    });
    this.isSubmitting = true;
    this.gallaryService.setContactData(requestObj).subscribe((res: any) => {
      const GA_config = {};
      GA_config[GAIndex.NAME] = res.data.name;
      GA_config[GAIndex.EMAIL_ID] = res.data.emailId;
      GA_config[GAIndex.CLIENT_NAME_OR_PURPOSE] = requestObj.purpose;
      if (res.data.stories.length === 0) {
          this._gaService.triggerEvent('Contact Us', res.data.usageType.name, 'NA', GA_config);
      } else {
        res.data.stories.forEach(element => {
          this._gaService.triggerEvent('Contact Us', res.data.usageType.name, element.name, GA_config);
        });
      }

      this.isSubmitting = false;
      this._snackBar.open(res.message, '', {
        duration: 3000,
        panelClass: 'success',
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.dialogRef.close();
    }, (err: any) => {
      this._snackBar.open(err.error.message, '', {
        duration: 3000,
        panelClass: 'error',
        verticalPosition: 'top',
        horizontalPosition: 'right'
      });
      this.dialogRef.close();
    });
  }
}
