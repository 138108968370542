import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';
import { WidgetService } from '../widgets.service';
import { MatDialog } from '@angular/material';
import { PopUpComponent } from 'src/app/common/components/pop-up/pop-up.component';

@Component({
  selector: 'app-event-timeline',
  templateUrl: './event-timeline.component.html'
})
export class EventTimelineComponent implements OnInit {
  @ViewChild('eventTimeline') chartRef;

  @Input() widgetData;
  data;
  chartData;
  processedData;
  options = {
    timeline: {
      colorByRowLabel: true,
      rowLabelStyle: { fontName: 'Helvetica', fontSize: 14, color: '#333', height: 10 },
      showBarLabels: false
    },
    backgroundColor: '#fffae9',
    avoidOverlappingGridLines: false,
    tooltip: { isHtml: true },
    // width: 1500,
    height: 700,
    colors: ['#ff8f53', '#4caffc', '#ceb300', '#20bc01']
  };

  constructor(private renderer: Renderer2, private elem: ElementRef, private widgetService: WidgetService, public dialog: MatDialog) {
    this.widgetService.triggerDataChangeForTimeline.subscribe((updatedDataInfo) => {
      // if ((updatedDataInfo.formField.uniqueName10 && updatedDataInfo.formField.uniqueName10.length)) {
      if (updatedDataInfo.formField.filterField && updatedDataInfo.formField.filterField.length) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }
    });
  }

  ngOnInit() {
    this.data = this.widgetData.data;
    this.preProcessData();
  }

  updateChart(updatedDataInfo) {
    const getFromDataSet = this.processedData;
    const updatedDataSetByYear = [];
    updatedDataSetByYear.push(getFromDataSet[0]);
    for (let row = 1; row < getFromDataSet.length; row++) {
      if (updatedDataInfo.formField.filterField.indexOf(getFromDataSet[row][0]) > -1) {
        updatedDataSetByYear.push(typeof getFromDataSet[row] === 'undefined' ? '' : getFromDataSet[row]);
      }
    }
    this.chartData = Object.create(this.chartData);
    this.chartData.dataTable = updatedDataSetByYear;
  }

  resetToOrignal() {
    this.chartData = Object.create(this.chartData);
    this.chartData.dataTable = this.processedData;
  }


  preProcessData() {
    this.processedData = [
      ['User', 'Name', {
        type: 'string',
        label: 'Tooltip Chart',
        role: 'tooltip',
        p: { html: true }
      }, 'Start', 'End'],
    ];
    let eventData = [];
    let date;
    let year;
    let month;

    for (let index = 0; index < this.data.length; index++) {
      eventData = [];
      eventData.push(this.data[index].row_lable);
      eventData.push(this.data[index].row_tooltip);
      eventData.push(`
          <div id="event-timeline-tooltip-id" class="event-timeline-tooltip">
            <p>
              <span> ${this.data[index].row_lable} </span>
              <span class="text-right"> ${moment(new Date(this.data[index].start_date)).format('MMM DD, YYYY')} </span>
            </p>
            <span> ${this.data[index].row_tooltip}</span>
          </div>
         `);
      date = new Date(this.data[index].start_date);
      year = date.getFullYear();
      month = date.getMonth();
      eventData.push(new Date(this.data[index].start_date));
      date = new Date(this.data[index].end_date);
      year = date.getFullYear();
      month = date.getMonth();
      eventData.push(new Date(this.data[index].end_date));
      this.processedData.push(eventData);
    }

    this.chartData = {
      chartType: 'Timeline',
      dataTable: this.processedData,
      options: this.options,
    };

  }

  select(event) {
    const value = event.selectedRowValues[0];
    const filteredData = _.filter(this.data, { 'row_lable': value });

    this.openDialog(JSON.stringify(filteredData));
  }

  openDialog(clickedData) {
    const dialogRef = this.dialog.open(PopUpComponent, {
      data: clickedData
    });

    dialogRef.afterClosed().subscribe(() => {
      const ccchart = this.chartRef.wrapper;
      ccchart.draw();

      const element = (this.renderer.parentNode(this.elem.nativeElement.querySelector('#event-timeline-tooltip-id')));
      this.renderer.removeChild(this.elem.nativeElement, element);
    });
  }
}
