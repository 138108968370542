import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-patient-radar',
  templateUrl: './patient-radar.component.html',
  styleUrls: ['./patient-radar.component.css']
})
export class PatientRadarComponent implements OnInit {

  @Input() widgetData;
	data;
 	htmlString;

	// constructor(private _sanitizer: DomSanitizer) {
 // 	}

 	ngOnInit() {
 		// console.log("this.data..........", this.data)
 		this.htmlString = this.widgetData.data.htmlString;
 	}

 	/*public get sanitizedHtmlProperty() : SafeHtml {
     	return this._sanitizer.sanitize(SecurityContext.HTML, this.htmlString);
  	}*/

}
