import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { WidgetService } from "../widgets.service";

@Component({
  selector: 'app-sanky-chart',
  templateUrl: './sanky-chart.component.html'
})
export class SankyChartComponent implements OnInit {
  @Input() widgetData;

  data;
  sanky_height = 2500;

  constructor(private widgetService: WidgetService) {
    this.widgetService.triggerDataChangeForSankyChart.subscribe((updatedDataInfo) => {
      if (updatedDataInfo.formField.uniqueName201 && updatedDataInfo.formField.uniqueName201.length) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }
    });
  }

  updateChart(updatedDataInfo) {
    let getFromDataSet = this.data;
    let updatedDataSet = [];
    updatedDataSet.push(getFromDataSet[0]);
    let checkForNodes = updatedDataInfo.formField.uniqueName201.slice(); 
    // for (let row = 1; row < getFromDataSet.length; row++) {
    //   if (updatedDataInfo.formField.uniqueName201.indexOf(getFromDataSet[row][0]) > -1) {
    //     updatedDataSet.push(getFromDataSet[row]);
    //   }
    // }
    for(let cIndex = 0; cIndex < checkForNodes.length; cIndex++) {
      for (let row = 1; row < getFromDataSet.length; row++) {
        if (checkForNodes[cIndex] == getFromDataSet[row][0]) {          
          updatedDataSet.push(getFromDataSet[row]);
          checkForNodes.push(getFromDataSet[row][1]);
        }
      }
    }
    this.sankyChartData = Object.create(this.sankyChartData);
    this.sankyChartData.dataTable = updatedDataSet;
    // console.log(updatedDataInfo);
  }

  resetToOrignal() {
    this.sankyChartData = Object.create(this.sankyChartData);
    this.sankyChartData.dataTable = this.data;
  }

  colors = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f',
    '#cab2d6', '#ffff99', '#1f78b4', '#33a02c'
  ];
  options = {
    width: 1500,
    height: this.sanky_height,
    sankey: {
      node: {
        label: {
          fontSize: 13,
          color: '#333',
          bold: true
        }


      },
      link: {
        colorMode: 'gradient',
        colors: this.colors
      }
    },
  };

  sankyChartData;

  ngOnInit() {
  this.data = this.widgetData.data;

    this.options.height = (this.data.length > 250)? 2500 : 1500;
    this.sankyChartData = {
      chartType: 'Sankey',
      dataTable: this.data,
      options: this.options,
    };   
  }
}
