import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { AdaptivePricingComponent } from 'src/app/widgets/adaptive-pricing/adaptive-pricing.component';
import { DataShareService } from 'src/app/shared/data-share.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CtempComponent } from 'src/app/widgets/ctemp/ctemp.component';
import { AtlasIntelligenceKraftComponent } from 'src/app/widgets/atlas-intelligence-kraft/atlas-intelligence-kraft.component';

@Component({
  selector: 'app-horizontal-visualization',
  templateUrl: './horizontal-visualization.component.html',
  styleUrls: ['./horizontal-visualization.component.css']
})
export class HorizontalVisualizationComponent implements OnInit, OnDestroy {
  @Input() storyData;

  @ViewChild('adaptivePricing') adaptivePricing: AdaptivePricingComponent;
  @ViewChild('ctemp') ctemp: CtempComponent;
  @ViewChild('kraft') kraft: AtlasIntelligenceKraftComponent;

  public unsubscribe: Subject<any> = new Subject<any>();

  constructor(public dataShareService: DataShareService) { }

  ngOnInit() {

    this.dataShareService.onCustomEvent.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(($e) => {
      switch ($e.event) {
        case 'setFullPageScrollEvent':
          if (this.adaptivePricing) {
            this.adaptivePricing.changeDirectiveScrollEvent($e.data);
          }
          if (this.ctemp) {
            this.ctemp.changeDirectiveScrollEvent($e.data);
          }
          if (this.kraft) {
            this.kraft.changeDirectiveScrollEvent($e.data);
          }
          break;
      }
    });
  }

  visibleSectionChagne() {

  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
