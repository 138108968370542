import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation ,OnDestroy} from '@angular/core';
import { WidgetService } from '../widgets.service';
declare let d3: any;
import * as _ from 'lodash';
import { flatten } from '@angular/core/src/render3/util';


@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-d3-tree-chart',
  templateUrl: './d3-tree-chart.component.html',
  styleUrls: ['./d3-tree-chart.component.css']
})
export class D3TreeChartComponent implements OnInit {

  @Input() widgetData;
  @ViewChild('d3TreeChartRef') chartElement: ElementRef;

  data;
  treeData;

  ngOnInit() {
    this.data = this.widgetData.data;
    for (let i = 0; i < this.data.length; i++) {
      this.loadTree(_.cloneDeep(this.data[i]));
    }
  }


  constructor(private widgetService: WidgetService) {
    // this.widgetService.triggerDataChangeForTreeChart.subscribe((updatedDataInfo) => {
    //       // alert("Inside area bar chart");

    //       if ((updatedDataInfo.formField.uniqueNameTree201 && updatedDataInfo.formField.uniqueNameTree201.length)) {
    //         this.updateChart(updatedDataInfo);
    //       } else {
    //         this.resetChart();
    //         this.treeData = null;
    //       }
    //   });

  }

  // updateChart(updatedDataInfo) {
  //   let foundIndex = _.findIndex(this.data, {"name": updatedDataInfo.formField.uniqueNameTree201})
  //   console.log(foundIndex)
  //   if (foundIndex > -1) {
  //     //this.treeData = this.data[foundIndex];
  //     this.treeData = this.data[foundIndex];
  //     this.resetChart();
  //     this.loadTree();
  //   };
  // }

  // resetChart() {
  //   d3.select("#tree_widget").remove();
  // }  

  loadTree(treeData) {


    var margin = { top: 20, right: 120, bottom: 20, left: 120 },
      width = 960 - margin.right - margin.left,
      height = 250 - margin.top - margin.bottom;

    var i = 0,
      duration = 750,
      root;

    var tree = d3.layout.tree()
      .size([height, width]);


    var diagonal = d3.svg.diagonal()
      .projection(function (d) { return [d.y, d.x]; });

    var svg = d3.select(this.chartElement.nativeElement)
    .append("div")
    .append("svg")
      
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", "d3_tree_widget")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    var bkp_arr = [];

    root = treeData;
    for (let j = 0; j < root.children.length; j++) {
      bkp_arr.push({ name: root.children[j].name, value: root.children[j].children });
      // if (j) 

      root.children[j].hasChildren = true;
      root.children[j].children = null;
    }

    root.x0 = height / 2;
    root.y0 = 0;

    update(root);

    //svg.style("height", "500px");

    function update(source) {

      // Compute the new tree layout.
      var nodes = tree.nodes(root).reverse(),
        links = tree.links(nodes);

      // Normalize for fixed-depth.
      nodes.forEach(function (d) { d.y = d.depth * 120; });

      // Update the nodes…
      var node = svg.selectAll("g.node")
        .data(nodes, function (d) { return d.id || (d.id = ++i); });

      // Enter any new nodes at the parent's previous position.
      var nodeEnter = node.enter().append("g")
        .attr("class", "node")
        .attr("transform", function (d) { return "translate(" + source.y0 + "," + source.x0 + ")"; })
        .on("click", handleTreeClick);

      nodeEnter.append("circle")
        .attr("r", 1e-6)
        .attr('stroke', function (d) {
          // if(d.depth<=1)
          // {
          //   return d.color ? d.color : '#787878';
          // }else 
          // if(d.depth>1 && d.children)
          // {
          //   return d.color ? d.color : 'green';
          // }
          if (!d.children && d.depth != 1) {
            if(d.value>0.5)
              return d.color ? d.color : '#379ef7';// Blue
            else if( d.value>0.2 && d.value<0.5)
              return d.color ? d.color : '#f4a600'; // Yellow
            else (d.value>0.2)
              return d.color ? d.color : '#ff5f5f'; // Red
          }
          else {
            return d.color ? d.color : setColor(d);
          }

        })
        .style("fill", function (d) { return d._children ? "#ccc" : "#fff"; })
        .on('mouseover', () => {
          div.style('display', 'inline');
         })
        .on('mousemove', function(d) {
           
          div
              .html(d.label + d.value.toFixed(2))
              .style('left', (d3.event.pageX + 15) + 'px')
              .style('top', (d3.event.pageY - 20) + 'px');
      })
        .on('mouseout', () => {
          div.style('display', 'none');
      });


      nodeEnter.append("text")
        
        .attr("x", function (d) { return !d.depth ? -13 : (d.depth == 1 ? 1 : d.children || d._children ? 1 : 13); })
        .attr("dy", function (d) { return d.depth != 1 && (!d.depth || !d.children) ? "0.35em" : "-1em"; })
        .attr("text-anchor", function (d) {
          return d.depth == 1 || d.children || d._children ? "end" : "start";
        })
        .text(function (d) {
          //return d.name;

          if (typeof d.value == 'undefined') {
            return d.name;
          }
          else if(d.depth !=1 && !d.children)
          {
            return d.label + d.value.toFixed(2)
          }
          else {
            //return d.tooltip;
          }
        })
        .style("fill-opacity", 1e-6);
        
      // Transition nodes to their new position.
      var nodeUpdate = node.transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        });

      nodeUpdate.select("circle")
        .attr("r", 6//function(d) {return d.depth < 2 ? 10 : 6; }
        )
        .style("fill", function (d) {
          var collapseColor = d.color ? d.color : setColor(d);
          return d.depth == 1 && d.hasChildren ? setColor(d) : d._children ? collapseColor : "#fff";

        });

      function setColor(d) {
        if (d.category == "Payer access") {
          return '#58D68D';
        }
        else if (d.category == "HCP Profile") {
          return '#E67E22';
        }
        else if (d.category == "Claims data") {
          return '#5DADE2';
        }
        else if (d.category == "Sales data") {
          return '#A569BD';
        }
        else if (d.category == "Sales force") {
          return '#7F8C8D';
        }
      }

      nodeUpdate.select("text")
        .style("fill-opacity", 1);

      // Transition exiting nodes to the parent's new position.
      var nodeExit = node.exit().transition()
        .duration(duration)
        .attr("transform", function (d) { return "translate(" + source.y + "," + source.x + ")"; })
        .remove();

      nodeExit.select("circle")
        .attr("r", 1e-6);

      nodeExit.select("text")
        .style("fill-opacity", 1e-6);

      // Update the links…
      var link = svg.selectAll("path.link")
        .data(links, function (d) { return d.target.id; });

      // Enter any new links at the parent's previous position.
      link.enter().insert("path", "g")
        .attr("class", "link")
        .attr("d", function (d) {
          var o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        });

      // Transition links to their new position.
      link.transition()
        .duration(duration)
        .attr("d", diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition()
        .duration(duration)
        .attr("d", function (d) {
          var o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        })
        .remove();

      // Stash the old positions for transition.
      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    // Toggle children on click.
    function handleTreeClick(d) {
     
      let myObj = _.find(bkp_arr, { 'name': d.name });
      if (d.children) {
        d._children = d.children;
        d.children = null;

      } else {
        d.children = d._children;
        d._children = null;
        if (d.depth == 1) {
          d.children = myObj.value;
        }

      }
      d.hasChildren = false;
      update(d);
    }
	
    // tooltips
    var div = d3.select('body').append('div')
    .attr('class', 'graph-tooltip')
	.attr('id', 'd3-tree-chart-custom-tooltip')
    .style('display', 'none');
  }
  OnDestroy() {
    d3.select("#d3-tree-chart-custom-tooltip").remove();
  }
}