import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GalleryService } from "../gallery.service";
import { DataShareService } from "src/app/shared/data-share.service";

@Component({
  selector: "app-story-telling-page",
  templateUrl: "./story-telling-page.component.html",
  styleUrls: ["./story-telling-page.component.css"],
})
export class StoryTellingPageComponent implements OnInit, OnDestroy {
  isDataLoading: boolean;
  paramsData: any;
  storyData: any;
  selectedSection: any;
  classVal: any;
  constructor(
    private _route: ActivatedRoute,
    private dataShareService: DataShareService,
    private galleryService: GalleryService
  ) {}

  ngOnInit() {
    this.isDataLoading = false;
    this.paramsData = this._route.snapshot.params;

    this.storyData = this.galleryService.getStoryListById({
      url: this.paramsData.url,
    });

    if (!this.storyData) {
      this.galleryService.storiesChanged.subscribe(() => {
        this.storyData = this.galleryService.getStoryListById({
          url: this.paramsData.url,
        });

        //! Hardcoded, will be removed later
        if (this.paramsData.url == "atlas-intelligence-kraft") {
          this.storyData = {
            id: "26",
            type: "story",
            categoryId: "3",
            sequenceOrder: 18,
            endpoint: null,
            title: "Clinical Trial Enrollment Modeling and Predictions",
            description:
              "Finding right sites to create enrolment plans for clinical trials by Historical Benchmarking, Distribution Parameters, Probabilistic Measurements, Tracking and Reforecasting",
            url: "atlas-intelligence-kraft",
            isNew: true,
            isInteractive: false,
            isVisible: true,
            groupId: null,
            createdAt: null,
            createdBy: "1",
            updatedAt: "1634716239000",
            updatedBy: "1",
            isHorizontalView: true,
            tags: ["#pharma", "#AI", "#gnome", "#nlp", "#Segmentation"],
            displayTitle: {
              line1: "Clinical Trial",
              line2: "Enrollment Modeling and Predictions",
            },
            viewType: "business",
            isHidden: false,
            isGrouped: false,
            category: "AI in Personalization ",
            categoryClass: "category_1",
            isHorizonalView: true,
          };
        }
        this.getStoryDetails(this.storyData);
      });
    } else {
      this.getStoryDetails(this.storyData);
    }
  }

  private getStoryDetails(data) {
    // TODO:SPM add property to json for horizontal view
    // TODO:RM Rename the url
    if (
      data.url === "field_coaching" || data.url === 'virtual_rep_trainer' ||
      data.url === "ctemp" ||
      //! Hardcoded, will be removed later
      data.url === "atlas-intelligence-kraft"
    ) {
      data.isHorizonalView = true;
    }
    // TODO: PB to add class to body story-wise
    this.classVal = this.dataShareService.getBodyStoryClass(data.url, "stp");
    this.dataShareService.updateCustomEvent({
      event: "addClassToBody",
      data: this.classVal,
    });
    // END
    this.dataShareService.dataChange(data);
    const navData = {
      sections: [
        {
          name: "Problem",
        },
        {
          name: "Data",
        },
        {
          name: "Solution",
        },
        {
          name: "Outcomes",
        },
      ],
      disableClick: true,
    };
    this.dataShareService.changeNavigation(navData);
  }

  visibleSectionChagne(e) {
    this.dataShareService.navigationSectionChange(e.value);
    this.selectedSection = e.value;
  }

  onChildLoad($event) {
    this.dataShareService.changeNavigation({ ...$event, disableClick: true });
  }

  ngOnDestroy() {
    this.dataShareService.updateCustomEvent({
      event: "removeClassToBody",
      data: this.classVal,
    });
    this.dataShareService.onCustomEvent.next({ event: 'removeClassToElement', data: {selector: '#header', class: 'humburger-header'}});
  }
}
