import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-field-coaching-app-dialog',
  templateUrl: './field-coaching-app-dialog.component.html',
  styleUrls: ['./field-coaching-app-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FieldCoachingAppDialogComponent implements OnInit {

  // tslint:disable-next-line: quotemark
  // tslint:disable-next-line: max-line-length
  public htmlString = "<iframe class='field-coaching-iframe-wrapper' width='100%' height='100%' src='https://aigallery.demo.zsservices.com/applications/stories/field_coaching/app_1/index.html' frameborder='0'></iframe>";

  constructor(public dialogRef: MatDialogRef<FieldCoachingAppDialogComponent>) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogRef.close();
  }

}
