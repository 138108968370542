import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatTableDataSource, MatTable } from '@angular/material';
import { AdminService } from '../service/admin.service';

@Component({
  selector: 'app-history-dialog-box',
  templateUrl: './history-dialog-box.component.html',
  styleUrls: ['./history-dialog-box.component.css']
})
export class HistoryDialogBoxComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  public userHistoryData: MatTableDataSource<any>;
  local_data: any;



  public displayedColumns = ['createdAt', 'ipAddress'];
  constructor(public dialogRef: MatDialogRef<HistoryDialogBoxComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _adminService: AdminService) { }

  ngOnInit() {
    this._adminService.getUserHistory(this.data.userId).subscribe((res: any) => {
      this.userHistoryData = new MatTableDataSource(res.data);
    });
  }
}
