import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PlugNPredictRWDService {

    // private customerDataSource = new BehaviorSubject(this.getCustomerData(this.circlesCount));
    private customerData: any = [];

    constructor() {}

    setCustomerData(circlesCount = 50) {
        // let data = [];
        for (let index = 0; index < circlesCount; index++) {
            this.customerData.push({
                id: index
            });
        }
    }

    getCustomerData() {
        return [...this.customerData];
    }

    //   changeCustomerData(customerData: []) {
    //     this.customerDataSource.next([...customerData]);
    //   }

}