import { Component, OnInit, Input } from '@angular/core';
import { D3CustomShapesService } from 'src/app/services/helper/d3-custom-shapes.service';

import _ from 'lodash';
declare const d3: any;

@Component({
	selector: 'app-vertical-dna-helix',
	templateUrl: './vertical-dna-helix.component.html',
	styleUrls: ['./vertical-dna-helix.component.css']
})
export class VerticalDnaHelixComponent implements OnInit {
	@Input() dna = {
		svg: d3.select("body"),
		container: null,
		width: 150,
		height: 650,
		index: 0,
		data: [],
		categories: ["abandonCart", "surgeCall", "portOut", "churn"],
		categoriesColor: ["#faa24c", "#39b2ff", "#a1be00", "#d94785"],
		shapes: ["hexagoan", "square", "triangleDown", "star"],
		translate: null,
		idString: null
	};
	numX = 35;
	torsion = 0.35;
	x: Function;
	y: Function;
	z = d3.scale.linear().range([10, 2]);

	constructor(private shapesConfigService: D3CustomShapesService) { }

	ngOnInit() {
		this.initializeData();
		this.createDna();
	}

	initializeData() {
		this.x = d3.scale.linear().range([10, this.dna.width - 10]);
		this.y = d3.scale.linear().range([40, this.dna.height - 40]);

		let generate = generateData.bind(this);
		var counter = 0;
		this.dna.data = generate();

		function generateData() {
			var data = [];
			counter++;
			data = d3.range(this.numX).map((d) => {
				var t = d * this.torsion * counter;
				return [{
					x: Math.cos(t),
					y: d,
					z: Math.sin(t)
				}, {
					x: Math.cos(t - Math.PI),
					y: d,
					z: Math.sin(t - Math.PI)
				}]
			});

			var flat = _.flatten(data);
			this.x.domain(d3.extent(flat, function (d) {
				return d.x
			}));
			this.y.domain(d3.extent(flat, function (d) {
				return d.y
			}));
			this.z.domain(d3.extent(flat, function (d) {
				return d.z
			}));

			var tempData = [...data],
				finalData = [];

			//tempData = ...data;
			finalData = []
			for (let j = 0; j < tempData.length; j++) {
				let randomValue = Math.random() + 1;
				let category = this.dna.shapes[this.dna.index];
				if (j % Math.floor((4 * randomValue)) === 0) {
					category = this.dna.shapes[Math.floor(Math.random() * this.dna.shapes.length)];
				}
				finalData[j] = [
					...data[j],
					{
						shapeType: category
					}
				]
			}
			return finalData
		}
	}

	createDna() {

		var leftArcPath = [],
			rightArcPath = []

		var dnaLineGenerator = d3.svg.line()
			.interpolate("cardinal")
			.x((d, i) => {
				return this.x(d[1].x);
			})
			.y((d) => {
				return this.y(d[0].y);
			});

		var dnaLineGenerator2 = d3.svg.line()
			.interpolate("cardinal")
			.x((d, i) => {
				return this.x(d[0].x);
			})
			.y((d) => {
				return this.y(d[0].y);
			});

		this.dna.svg = this.dna.svg
			.append("g")
			.attr('id', "dna-svg-block_" + this.dna.index)
			.attr("width", this.dna.width)
			.attr("height", this.dna.height);

		this.dna.svg.
			append("rect")
			.attr("width", this.dna.width)
			.attr("height", this.dna.height)
			.attr("fill", "none");

		this.dna.container = this.dna.svg.append("g");

		var cont = this.dna.container.selectAll("g").data(this.dna.data);
		cont.exit().remove();

		var enter = cont.enter()
			.append("g")
			.each(function (d, index) {
				d3.select(this).append('line')
					.attr({
						"stroke": "grey",
						"stroke-width": 2
					});
			});
		let parentScope = this;
		cont.each(function (d, index) {

			var inverted = (d[0].x < d[1].x) ? 1 : -1;

			d3.select(this)
				.select('line')
				.attr({
					"x2": parentScope.x(d[0].x),
					"x1": parentScope.x(d[1].x),
					"y2": parentScope.y(d[0].y),
					"y1": parentScope.y(d[0].y),
					"opacity": 0.3 * inverted * (d[1].x - d[0].x)
				});
		});

		leftArcPath = this.dna.svg
			.append('path')
			.attr({
				'd': dnaLineGenerator(this.dna.data),
				"class": this.dna.idString + "-left",
				"id": this.dna.idString + "-left-" + this.dna.index,
			})
			.style({
				"stroke": "none",
				"fill": "none",
				'stroke-dasharray': function (d) {
					var l = d3.select(this).node().getTotalLength();
					return l + 'px, ' + l + 'px';
				},
				'stroke-dashoffset': function (d) {
					return d3.select(this).node().getTotalLength() + 'px';
				}
			});

		rightArcPath = this.dna.svg
			.append('path')
			.attr({
				'd': dnaLineGenerator2(this.dna.data),
				"class": this.dna.idString + "-right",
				"id": this.dna.idString + "-right-" + this.dna.index,
			})
			.style({
				"stroke": "none",
				"fill": "none",
				'stroke-dasharray': function (d) {
					var l = d3.select(this).node().getTotalLength();
					return l + 'px, ' + l + 'px';
				},
				'stroke-dashoffset': function (d) {
					return d3.select(this).node().getTotalLength() + 'px';
				}
			});

		//Right Shapes
		this.dna.svg.selectAll(".right-shapes").data(this.dna.data).enter().append("path")
			.attr({
				"d": (d) => {
					return this.shapesConfigService.getShapeCoordinates(d[2].shapeType, (this.z(d[0].z) * 15))
				},
				"class": "right-shapes-" + this.dna.index,
				"id": (d, i) => {
					return "dna-right-moving-shapes-" + this.dna.index + "-" + i;
				},
				"transform": "translate(140,40)",
				"fill-opacity": 0,
				"stroke": "none",
			})
			.style({
				"fill": (d) => {
					return this.dna.categoriesColor[this.dna.shapes.indexOf(d[2].shapeType)]
				}
			});

		//Left Shapes
		this.dna.svg.selectAll(".left-shapes").data(this.dna.data.reverse()).enter().append("path")
			.attr({
				"d": (d) => {
					return this.shapesConfigService.getShapeCoordinates(d[2].shapeType, (this.z(d[0].z) * 15))
				},
				"class": "left-shapes-" + this.dna.index,
				"id": (d, i) => {
					return "dna-left-moving-shapes-" + this.dna.index + "-" + i;
				},
				"transform": "translate(10,40)",
				"fill-opacity": 0,
				'stroke': "none",
			})
			.style({
				"fill": (d) => {
					return this.dna.categoriesColor[this.dna.shapes.indexOf(d[2].shapeType)]
				}
			});

		this.dna.svg.attr({
			transform: "translate(" + this.dna.translate.x + "," + this.dna.translate.y + ")"
		})
	}

}
