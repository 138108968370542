import { Component, ElementRef, OnInit, Input, ViewChild } from '@angular/core';
declare let d3: any;

@Component({
  selector: 'app-d3-bar-chart',
  templateUrl: './d3-bar-chart.component.html'
})
export class D3BarChart implements OnInit{
  @ViewChild('d3BarChartRef') chartElement: ElementRef;
  @Input() widgetData;
  @Input() widgetsCount;
  data;
  svg;

  ngOnInit() {
    this.data = this.widgetData.data;  
    this.loadGraph();
  }

  loadGraph() {
      var outerWidth = this.widgetsCount > 1 ? 450 : 950;
      var outerHeight = this.widgetsCount > 1 ? 250 : 500;
      var margin = { left: 90, top: 16, right: 30, bottom: 30 };
      var barPadding = 0.2;

      var xColumn = "xValue";
      var yColumn = "yValue";
      var colorColumn = "xValue";

      var innerWidth  = outerWidth  - margin.left - margin.right;
      var innerHeight = outerHeight - margin.top  - margin.bottom;

      var svg = d3.select(this.chartElement.nativeElement).append("svg")
        .attr("width",  outerWidth)
        .attr("height", outerHeight);
      var g = svg.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
      var xAxisG = g.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + innerHeight + ")");
      var yAxisG = g.append("g")
        .attr("class", "y axis");

      var xScale = d3.scale.ordinal().rangeBands([0, innerWidth], barPadding);
      var yScale = d3.scale.linear().range([innerHeight, 0]);
      var colorScale = d3.scale.category10();

      // Use a modified SI formatter that uses "B" for Billion.
      var siFormat = d3.format("s");
      var customTickFormat = function (d){
        // return siFormat(d).replace("G", "B");
        return d;
      };

      var xAxis = d3.svg.axis().scale(xScale).orient("bottom")
        .outerTickSize(0);
      var yAxis = d3.svg.axis().scale(yScale).orient("left")
        .ticks(5)
        .tickFormat(customTickFormat)
        .outerTickSize(0);

        xScale.domain(this.data.map( function (d){ return d[xColumn]; }));
        yScale.domain([0, d3.max(this.data, function (d){ return d[yColumn]; })]);
        colorScale.domain(this.data.map(function (d){ return d[colorColumn]; }));

        xAxisG
          .call(xAxis)
          .selectAll("text")  
        //   .attr("dx", "-0.4em")
        //   .attr("dy", "1.24em")
        //   .attr("transform", "rotate(-16)" );

        yAxisG.call(yAxis)
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .style("text-anchor", "end")
        .text("Value ($)");

        var bars = g.selectAll("rect").data(this.data);
        bars.enter().append("rect")
          .attr("width", xScale.rangeBand());
        bars
          .attr("x", function (d){ return xScale(d[xColumn]); })
          .attr("y", function (d){ return yScale(d[yColumn]); })
          .attr("height", function (d){ return innerHeight - yScale(d[yColumn]); })
          .attr("fill", function (d){ return colorScale(d[colorColumn]); })
          .on('mouseover', () => {
            div.style('display', 'inline');
           })
          .on('mousemove', function() {
            var d = d3.select(this).data()[0]
            div
                .html(d[yColumn])
                .style('left', (d3.event.pageX + 15) + 'px')
                .style('top', (d3.event.pageY - 20) + 'px');
        })
          .on('mouseout', () => {
            div.style('display', 'none');
        });
        bars.exit().remove();  

        // tooltips
    var div = d3.select('body').append('div')
    .attr('class', 'graph-tooltip')
    .style('display', 'none');

  }

  
}