import { Component, OnInit, Renderer2, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DataShareService } from 'src/app/shared/data-share.service';
import { Router, NavigationStart, NavigationEnd, GuardsCheckEnd } from '@angular/router';
import { AuthService } from 'src/app/shared/auth.service';
// import { HttpEvent, HttpEventType } from '@angular/common/http';

// import { DataStorageService } from '../../shared/data-storage.service';
// import { AuthService } from '../../auth/auth.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PromoVideoComponent } from './promo-video-dialog-box/promo-video-dialog-box.component';
import { GalleryService } from 'src/app/gallery/gallery.service';
import { CookieService } from 'src/app/shared/cookie-wrapper.service';
import { LoginService } from '../login/service/login.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { UserType } from 'src/app/shared/enum/enum';
import { ContactComponent } from '../contact/contact.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  copydata=false;
  @ViewChild('sidebarOverlay') sidebarOverlay: ElementRef;
  // @ViewChild('unsupportedBanner') unsupportedBanner: ElementRef;
  public isClipBoardCopied = false;
	public clipBoardTimeout = null;
	public clipBoardText = '';
  showLoader = false;
  baner: any;
  showBusinessViewBtn = false;
  storyUrl;
  showNavigation = false;
  showMenu = false;
  enableNavigationClick = true;
  storyData = [];
  selectedSection = '';
  isLoggedIn = false;
  isAdmin = false;
  selectedLogin = '';
  loadPromoButton = false;
  promoVideoUrl = '';
  isSSOUser = false;
  loggedInUserInfo = null;
  storiesList = null;

  showEditDashboard = false;
  // unsupportedBannerTimer = null;

  public isQuickSettingsActive = false;
  public disableSave = true;
  public isDataLoaded = false;

  constructor(
    private dataShareService: DataShareService,
    private router: Router,
    private _authService: AuthService,
    private _cookieService: CookieService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private _snackBar: MatSnackBar,
    private _loginService: LoginService,
    private _gaService: GoogleAnalyticsService
  ) {
    this.baner = {
      title: 'zs ai gallery',
      storyThemeClass: '',
    };
    this.router.events
      .subscribe((event) => {
        this.showNavigation = false;
        this.showBusinessViewBtn = false;
        this.baner = {
          title: 'zs ai gallery',
          storyThemeClass: '',
        };

        this.selectedSection = '';
        this.selectedLogin = '';
      });

    this.dataShareService.updateBanerData.subscribe((updatedDataInfo) => {

      this.storyUrl = updatedDataInfo.url;
      this.baner.title = updatedDataInfo.title || 'zs ai gallery';
      this.baner.description = updatedDataInfo.description ||
        'ZS AI Gallery is an interactive portfolio and experiments of AI & Machine Learning use cases developed by ZS';
        if(this.storyUrl!="advanced_market_intelligence"){
          this.copydata=false;
        }
    });
    this.dataShareService.updateNavigationData.subscribe((navData) => {

      this.enableNavigationClick = true;
      if (navData.disableClick) {
        this.enableNavigationClick = false;
      }
      if (navData.isBusinessViewAvailable) {
        this.showBusinessViewBtn = true;
      }
      this.storyData = navData.sections;
      // console.log("Story data is:",this.storyData);
      // console.log(this.storyUrl);
      if(this.storyUrl==="advanced_market_intelligence" && this.storyData!=null){
        this.copydata=true;
      }
      this.showNavigation = true;
    });
    this.dataShareService.onNavigationSectionChange.subscribe((data) => {
      this.selectedSection = data || '';
    });
    this._authService.onUserInfoUpate.subscribe((data) => {
      if (data) {
        this.isLoggedIn = true;
        this.isAdmin = data.isAdmin;
        this.isSSOUser = data.type === UserType.ZS;
        if (this.isAdmin) {
          this.renderer.addClass(document.body, "admin-view");
          this.renderer.removeClass(document.body, "user-view");
        } else {
          this.renderer.removeClass(document.body, "admin-view");
          this.renderer.addClass(document.body, "user-view");
        }
      } else {
        this.isLoggedIn = false;
        this.isAdmin = false;
        this.isSSOUser = false;
        this.renderer.removeClass(document.body, "user-view");
        this.renderer.removeClass(document.body, "admin-view");
      }
      this.loggedInUserInfo = data;
    });

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          const promoButtonRoutes = ['/', '/gallery'];
          if (promoButtonRoutes.indexOf(event.url) > -1) {
            this.loadPromoButton = true;
            this.showEditDashboard = true;
          } else {
            this.loadPromoButton = false;
            this.showEditDashboard = false;
            this.isQuickSettingsActive = false;
          }
          this.showMenu = false;
        }
        if (event instanceof NavigationEnd) {
          const promoButtonRoutes = ['/', '/gallery'];
          if (promoButtonRoutes.indexOf(event.url) > -1) {
            this.loadPromoButton = true;
            this.showEditDashboard = true;
          } else {
            this.loadPromoButton = false;
            this.showEditDashboard = false;
            this.isQuickSettingsActive = false;
          }
          this.showMenu = false;
          this._setFullPageScrollEventState(true);
        }
        if (event instanceof GuardsCheckEnd) {
          if (event.shouldActivate) {
            this.loadPromoButton = false;
            this.showEditDashboard = false;
            this.isQuickSettingsActive = false;
          } else {
            this.showEditDashboard = true;
            this.loadPromoButton = true;
            this.isQuickSettingsActive = true;
          }
        }
      });


    this.dataShareService.onCustomEvent.subscribe(($e) => {
      // console.log(data);
      switch ($e.event) {
        case 'cookiesExpired':
          this._snackBar.open($e.data, '', {
            duration: 3000,
            panelClass: 'error',
            verticalPosition: 'top',
            horizontalPosition: 'right'
          });
          break;
        case 'addClassToBody':
          this.renderer.addClass(document.body, $e.data);
          break;
        case 'removeClassToBody':
          this.renderer.removeClass(document.body, $e.data);
          break;
        case 'togglePrimaryLoader':
          this.showLoader = $e.data;
          break;
        case 'addClassToElement':
          this.renderer.addClass(document.querySelector(`${$e.data.selector}`), $e.data.class);
          break;
        case 'removeClassToElement':
          this.renderer.removeClass(document.querySelector(`${$e.data.selector}`), $e.data.class);
          break;
      }
    });

    this.dataShareService.onGalleryDataLoad.subscribe((data) => {
      // console.log(data);
      this.storiesList = data.stories;
      this.promoVideoUrl = data.promo_video_url;
    });

    this.dataShareService.onCustomDashboardEvent.subscribe((res: any) => {
			switch (res.event) {
				case 'toggleQuickSettings':
					this.toggleQuickSettings(res.value);
					break;
				case 'toggleSaveDisable':
					this.toggleSaveDisable(res.value);
					break;		
        case 'toggleLoader':
          this.toggleLoader(res.value);
          break;
        case 'showSettings':
          this.isDataLoaded = res.value;
          break;		
          
				default:
					break;
			}res.event
		});
  }
  

  ngAfterViewInit() {
    if (this.sidebarOverlay) {
      this.renderer.listen(this.sidebarOverlay.nativeElement, 'mousewheel', ($e) => {
        $e.stopImmediatePropagation();
        // $e.stopPropagation();
        return false;
      });
    }
    
    // this.renderer.addClass(this.unsupportedBanner.nativeElement, 'in');
    // this.unsupportedBannerTimer = setTimeout(() => {
    //   this.renderer.removeClass(this.unsupportedBanner.nativeElement, 'in');
    // }, 1000 * 60);

  }

  redirectTo() {
    this.router.navigate(['gallery/story-telling-page', this.storyUrl]);
  }

  onLogoClick() {
    this.router.navigate(['gallery']);
  }


  goToHome() {
    this.showMenu = false;
    this.router.navigate(['/']);
  }

  onMenuClick() {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this._setFullPageScrollEventState(false);
    } else {
      this._setFullPageScrollEventState(true);
    }
  }

  private _setFullPageScrollEventState(state: boolean) {
    this.dataShareService.updateCustomEvent({ event: 'setFullPageScrollEvent', data: state });
  }


  onNavigationSelect(data) {
    this.dataShareService.navigationClick(data);
  }
  // onSaveData() {
  //   this.dataStorageService.storeRecipes()
  //     .subscribe(
  //       (response) => {
  //         console.log(response);
  //       }
  //     );
  // }

  // onFetchData() {
  //   this.dataStorageService.getRecipes();
  // }

  // onLogout() {
  //   this.authService.logout();
  // }

  isAuthenticated() {
    return false;
  }

  onLogoutClick() {
    // this.dataShareService.triggerLogout(this._authService.getUser().id);
    this._loginService.logout(this._authService.getUser().id).subscribe((d) => { });
    this._gaService.triggerEvent('Logout', 'Logout', 'User Logout');
    this._authService.removeUser();
    this._cookieService.clearCloudfrontCookies();
    // this.router.navigate(['login']);
  }

  onLoginFormLinkClick(role) {
    this.selectedLogin = role;
    this.dataShareService.showLoginForm(role);
  }

  openVideoModal() {
    this._gaService.triggerEvent('Play', 'Video', 'Know More');
    const dialogRef = this.dialog.open(PromoVideoComponent, {
      data: { 'videoURL': this.promoVideoUrl },
      panelClass: "promo-video-popup"
    });
    // const cdkOverlayContainer = document.getElementsByClassName('cdk-overlay-container');
    // const className = 'promo-video-popup';

    // dialogRef.afterOpened().subscribe(() => {
    //   this.renderer.addClass(cdkOverlayContainer[0], className);
    // });

    // dialogRef.afterClosed().subscribe(() => {
    //   this.renderer.removeClass(cdkOverlayContainer[0], className);
    // });
  }

  onContactUs() {
    const dialogRef = this.dialog.open(ContactComponent, {
      panelClass: 'contact-us-overlay',
      data: {userInfo: {...this.loggedInUserInfo}, stories: [...this.storiesList]}
    });
    // const cdkOverlayContainer = document.getElementsByClassName('cdk-overlay-container');
    // const className = 'promo-video-popup';

    dialogRef.afterOpened().subscribe(() => {
      // this.renderer.addClass(cdkOverlayContainer[0], className);
    });

    dialogRef.afterClosed().subscribe(() => {
      // this.renderer.removeClass(cdkOverlayContainer[0], className);
    });
  }

  public enableQuickSettings() {
    this.dataShareService.onCustomDashboardEvent.next({
      event: 'enableSettings',
      value: true
    });
  }

  public saveSettings() {
    this.dataShareService.onCustomDashboardEvent.next({
      event: 'saveSettings',
      value: true
    });
  }

  public cancelChanges() {
    this.dataShareService.onCustomDashboardEvent.next({
      event: 'cancelSettings',
      value: true
    });
  }

  public toggleSaveDisable(value) {
    this.disableSave = !value;
  }

  public toggleQuickSettings(value) {
    if (value) {
      this.isQuickSettingsActive = true;
      this.dataShareService.updateCustomEvent({ event: 'addClassToBody', data: 'edited-view' });
    } else {
      this.isQuickSettingsActive = false;
      this.dataShareService.updateCustomEvent({ event: 'removeClassToBody', data: 'edited-view' });
    }
  }

  toggleLoader(value) {
    this.showLoader = !!value
  }
  clipBoardClicked(event) {
		// console.log("Visualization Function triggered", event);
    // console.log("this.clipBoardTimeout: ", this.clipBoardTimeout);
    // console.log(event);
		this.isClipBoardCopied = true;
		if (this.clipBoardTimeout != null) {
			clearTimeout(this.clipBoardTimeout);
		};
		this.clipBoardText = `${event} Copied!`;
		this.clipBoardTimeout = setTimeout(() => {
			this.isClipBoardCopied = false;
			this.clipBoardTimeout = null;
			this.clipBoardText = '';
		}, 1500);
	}

  ngOnDestroy() {
    // clearTimeout(this.unsupportedBannerTimer);
  }
}
