import { AuthGuardService } from './shared/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

// import { HomeComponent } from './core/home/home.component';
// import { ShoppingListComponent } from './shopping-list/shopping-list.component';
// import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './core/contact/contact.component';
import { LoginComponent } from './core/login/login.component';
// import { AdminComponent } from './core/admin/admin.component';
// import { ErrorPageComponent } from './core/error-page/error-page.component';
// import { GalleryListComponent } from './gallery/gallery-list/gallery-list.component';
// import { StoryPageComponent } from './gallery/story-page/story-page.component';
// import { DataInputComponent } from './gallery/story-page/data-input/data-input.component';
// import { DataAnalysisComponent } from './gallery/story-page/data-analysis/data-analysis.component';
// import { DataOutputComponent } from './gallery/story-page/data-output/data-output.component';


const appRoutes: Routes = [
  { path: '', redirectTo: '/gallery', pathMatch: 'full' },
  // { path: 'gallery/:type', component: GalleryComponent },
  { path: 'contact', component: ContactComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent, data: { role: 'guest' } },
  { path: 'login/admin', component: LoginComponent, data: { role: 'admin' } },
  { path: 'admin', redirectTo: '/admin', },
  { path: '**', redirectTo: '/gallery' },
  // { path: 'admin', component: AdminComponent , canActivate: [AuthGuardService, AdminAuthGuardService]},
  // { path: 'not-found', component: ErrorPageComponent, data: {message: 'Page not found!'} },
  // { path: '**', redirectTo: '/not-found' }
  // { path: 'recipes', loadChildren: './recipes/recipes.module#RecipesModule'},
  // { path: 'shopping-list', component: ShoppingListComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
