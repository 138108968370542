export class ShapesConfigService {
    flowShapes = null;
    constructor() {
        var pi$4 = Math.PI;
        var tau$4 = 2 * pi$4;
        var ka = 0.89081309152928522810,
            kr = Math.sin(pi$4 / 10) / Math.sin(7 * pi$4 / 10),
            kx = Math.sin(tau$4 / 10) * kr,
            ky = -Math.cos(tau$4 / 10) * kr;
        var sqrt3 = Math.sqrt(3);

        var flow_shapes = {
            square: function (size) {
                var r = Math.sqrt(size) / 2;
                // console.log("M" + -r + "," + -r + "L" + r + "," + -r + " " + r + "," + r + " " + -r + "," + r + "Z")
                return "M" + -r + "," + -r + "L" + r + "," + -r + " " + r + "," + r + " " + -r + "," + r + "Z";
            },
            circle: function(size){
                var r = Math.sqrt(size / Math.PI);
                return "M0," + r + "A" + r + "," + r + " 0 1,1 0," + -r + "A" + r + "," + r + " 0 1,1 0," + r + "Z";
            },
            cross: function (size) {
                var r = Math.sqrt(size / 5) / 2;
                return "M" + -3 * r + "," + -r + "H" + -r + "V" + -3 * r + "H" + r + "V" + -r + "H" + 3 * r + "V" + r + "H" + r + "V" + 3 * r + "H" + -r + "V" + r + "H" + -3 * r + "Z";
            },
            hexagoan: function (size) {
                var t = tau$4 / 12;
                var num = 2 * size;
                var denom = 3 * Math.sqrt(3);
                var s = Math.sqrt(num / denom);
                var R = s;
                var points = rotatePoint(R, 0, t);
                var path = this.moveTo(points[0], points[1]);

                for (var i = 0; i < 6; ++i) {
                    var a = tau$4 * i / 6;
                    var x = Math.cos(a) * R;
                    var y = Math.sin(a) * R;
                    points = rotatePoint(x, y, t);
                    path += this.lineTo(points[0], points[1]);

                }

                path += this.closePath();
                return path;

            },
            star: function (size) {
                var r = Math.sqrt(size * ka),
                    x = kx * r,
                    y = ky * r;
                // context.moveTo(0, -r);
                var path = "M0," + -r;
                path += "L" + x + ", " + y;
                // context.lineTo(x, y);
                for (var i = 1; i < 5; ++i) {
                    var a = tau$4 * i / 5,
                        c = Math.cos(a),
                        s = Math.sin(a);
                    path += "L" + (s * r) + ", " + (-c * r);
                    path += "L" + (c * x - s * y) + ", " + (s * x + c * y);
                }
                path += "Z";
                return path;
            },
            pentagon: function (size) {

                var s = sideLength(size);
                var R = circumradius(s);
                var theta = -tau$4 / 4; // Rotate 1/4 turn back so the shape is oriented with a point upward.

                var points = rotatePoint(R, 0, theta);
                var path = "M" + points[0] + ", " + points[1];

                for (var i = 0; i < 5; ++i) {
                    var a = tau$4 * i / 5;
                    var x = Math.cos(a) * R;
                    var y = Math.sin(a) * R;
                    points = rotatePoint(x, y, theta);
                    path += "L" + points[0] + ", " + points[1];

                }
                path += "Z";
                return path;
            },
            triangleDown: function (size) {
                var y = -Math.sqrt(size / (sqrt3 * 3));
                var path = this.moveTo(0, -y * 2);
                path += this.lineTo(-sqrt3 * y, y);
                path += this.lineTo(sqrt3 * y, y);
                path += this.closePath();
                return path;
            },
            triangleLeft: function (size) {
                var x = -Math.sqrt(size / (sqrt3 * 3));
                var path = this.moveTo(x * 2, 0);
                path += this.lineTo(-x, -sqrt3 * x);
                path += this.lineTo(-x, sqrt3 * x);
                path += this.closePath()
                return path;

            },
            triangleRight: function (size) {
                var x = -Math.sqrt(size / (sqrt3 * 3));
                var path = this.moveTo(-x * 2, 0);
                path += this.lineTo(x, -sqrt3 * x);
                path += this.lineTo(x, sqrt3 * x);
                path += this.closePath()
                return path;
            },
            moveTo: function (x, y) {
                return "M" + x + ", " + y;
            },
            lineTo: function (x, y) {
                return "L" + x + ", " + y;
            },
            closePath: function () {
                return "Z";
            }

        }

        var circumradiusCoeff = 1 / 10 * Math.sqrt(50 + 10 * Math.sqrt(5)); // ~ 0.85065080835204
        function circumradius(side) {
            return side * circumradiusCoeff;
        }

        function sideLength(area) {
            var num = 4 * area;
            var denom = Math.sqrt(5 * (5 + 2 * Math.sqrt(5))); // ~ 6.881909602355868

            return Math.sqrt(num / denom);
        }

        function rotatePoint(x, y, theta) {
            return [
                Math.cos(theta) * x + -Math.sin(theta) * y, // x
                Math.sin(theta) * x + Math.cos(theta) * y, // y
            ];
        }

        this.flowShapes = flow_shapes;
    }

    getShapeCoordinates(shapeType, size) {
        return this.flowShapes[shapeType](size);
    }
}
