import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { GalleryService } from '../gallery.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { GAIndex } from 'src/app/shared/enum/enum';

@Component({
  selector: 'app-tile-settings-overlay',
  templateUrl: './tile-settings-overlay.component.html',
  styleUrls: ['./tile-settings-overlay.component.css'],
  providers: [GalleryService]
})
export class TileSettingsOverlayComponent implements OnInit {

  public isLoading = false;
  public oldStory;
  public isUpdated = false;

  public validateKeys = {
    'groupId': false,
    'category': false,
    'isInteractive': false,
    'isNew': false,
    'isHidden': false,
    'description': false,
    'boldTitle': false,
    'title': false,
  }

  public validateEmpty = {
    title: false,
    description: false,
  }

  constructor(
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TileSettingsOverlayComponent>,
    public gallaryService: GalleryService) {
    dialogRef.disableClose =  true;
    this.isLoading = true;
    this.oldStory = { ...data.story, displayTitle: { ...data.story.displayTitle } }
  }

  ngOnInit() {

  }

  updateDescription() {
    if (this.data.story.description.trim() === '') {
      this.validateEmpty.description = true;
    } else {
      this.validateEmpty.description = false;
      this.validateKeys.description = this.oldStory.description !== this.data.story.description;
    }
    this.validateSave();
  }

  updateCategory() {
    this.validateKeys.category = this.oldStory.categoryId !== this.data.story.categoryId;
    this.validateSave()
  }

  updateGroup() {
    this.validateKeys.groupId = this.oldStory.groupId !== this.data.story.groupId;
    this.validateSave()
  }

  updateLine1() {
    if (this.data.story.displayTitle.line1.trim() === '') {
      this.validateEmpty.title = true;
    } else {
      this.validateEmpty.title = false;
      this.validateKeys.title = this.oldStory.displayTitle.line1 !== this.data.story.displayTitle.line1;
    }
    this.validateSave();
  }

  updateLine2() {
    this.validateKeys.boldTitle = this.oldStory.displayTitle.line2 !== this.data.story.displayTitle.line2;
    this.validateSave();
  }

  updateQuickToggles(key) {
    this.data.story[key] = !this.data.story[key];
    this.validateKeys[key] = this.oldStory[key] !== this.data.story[key];
    this.validateSave();
  }

  validateSave() {
    this.isUpdated = false;
    let isEmpty = false;
    for (const key in this.validateEmpty) {
      if (Object.prototype.hasOwnProperty.call(this.validateEmpty, key)) {
        if (this.validateEmpty[key] === true) {
          isEmpty = true;
        }
      }
    }

    if (isEmpty) { return false }

    for (const key in this.validateKeys) {
      if (Object.prototype.hasOwnProperty.call(this.validateKeys, key)) {
        if (this.validateKeys[key] === true) {
          this.isUpdated = true;
        }
      }
    }

    return this.isUpdated;
  }

  public isDataLoading = false;

  onSubmit() {
    if (this.validateSave()) {
      this.isDataLoading = true;
      const tempTitle = `${this.data.story.displayTitle.line1.trim()} ${this.data.story.displayTitle.line2.trim()}`
      const payload = {
        "storyId": this.data.story.id, 
        "title": tempTitle,
        "displayTitle": {
          line1: this.data.story.displayTitle.line1,
          line2: this.data.story.displayTitle.line2,
        },
        "categoryId": this.data.story.categoryId, 
        "description": this.data.story.description,
        "configurationId": null, 
        "groupId": this.data.story.groupId, 
        "isVisible": !this.data.story.isHidden, 
        "isNew": this.data.story.isNew, 
        "isInteractive": this.data.story.isInteractive, 
      }

      this.gallaryService.updateStory(payload).subscribe((res: any) => {
        this.showToastMessage(res.message, false);
        this.isDataLoading = false;
        if (this.validateKeys.category) {
          const index = this.data.categories.findIndex(e => e.categoryId === this.data.story.categoryId);
          if (index > -1) {
            this.data.story.category = this.data.categories[index].name;
            this.data.story.categoryClass = this.data.categories[index].categoryClass;
          }
        }
        this.dialogRef.close(this.data.story);
      }, (err) => {
        this.isDataLoading = false;
        this.showToastMessage(err.error.message);
      });
    }
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  showToastMessage(message, isError = true, duration= 3000) {
    this._snackBar.open(message, '', {
			duration,
			panelClass: isError ? 'error' : 'success',
			verticalPosition: 'top',
			horizontalPosition: 'right'
		})
  }
}
