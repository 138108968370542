import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class WidgetService {

  // isOpen = false;

  @Output() triggerDataChangeForNlp: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForLineBarChart: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForAreaChart: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForSankyChart: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForTableViewer: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForTimeline: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForTreeChart: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForUSChart: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForCustomEventTimeline: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForD3SankyChart: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeFord3Nueral: EventEmitter<any> = new EventEmitter();
  @Output() triggerDataChangeForDTGridWidget: EventEmitter<any> = new EventEmitter();


  dataChange(udpatedDataInfo) {
    // this.isOpen = !this.isOpen;
    // console.log(udpatedDataInfo)
    // alert('Here');
    if (udpatedDataInfo.widgetType.indexOf('nlp-text-highlighter-widget') > -1) {
      this.triggerDataChangeForNlp.emit(udpatedDataInfo);
    }
    if (udpatedDataInfo.widgetType.indexOf('line-chart-widget') > -1) {
      this.triggerDataChangeForLineBarChart.emit(udpatedDataInfo);
    }
    if (udpatedDataInfo.widgetType.indexOf('area-chart-widget') > -1) {
      this.triggerDataChangeForAreaChart.emit(udpatedDataInfo);
    }
    if (udpatedDataInfo.widgetType.indexOf('sanky-chart-widget') > -1) {
      this.triggerDataChangeForSankyChart.emit(udpatedDataInfo);
    }
    if (udpatedDataInfo.widgetType.indexOf('success-failure-widget') > -1) {
      this.triggerDataChangeForTableViewer.emit(udpatedDataInfo);
    }
    if (udpatedDataInfo.widgetType.indexOf('event-timeline-widget') > -1) {
      this.triggerDataChangeForTimeline.emit(udpatedDataInfo);
    }
    if (udpatedDataInfo.widgetType.indexOf('d3-tree-chart') > -1) {
      this.triggerDataChangeForTreeChart.emit(udpatedDataInfo);
    }

    if (udpatedDataInfo.widgetType.indexOf('us-chart-widget') > -1) {
      this.triggerDataChangeForUSChart.emit(udpatedDataInfo);
    }

    if (udpatedDataInfo.widgetType.indexOf('custom-event-timeline-widget') > -1) {
      this.triggerDataChangeForCustomEventTimeline.emit(udpatedDataInfo);
    }

    if (udpatedDataInfo.widgetType.indexOf('d3-sanky-chart-widget') > -1) {
      this.triggerDataChangeForD3SankyChart.emit(udpatedDataInfo);
    }

    if (udpatedDataInfo.widgetType.indexOf('d3-neural-network') > -1) {
      this.triggerDataChangeFord3Nueral.emit(udpatedDataInfo);
    }

    if (udpatedDataInfo.widgetType.indexOf('custom-grid-widget') > -1) {
      this.triggerDataChangeForDTGridWidget.emit(udpatedDataInfo);
    }
  }
}
