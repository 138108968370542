import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
declare let d3: any;
import * as _ from 'lodash';
import { GalleryService } from 'src/app/gallery/gallery.service';
import { MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { DialogInputBoxComponent } from 'src/app/common/components/dialog-input-box/dialog-input-box.component';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-prediction-game',
  templateUrl: './prediction-game.component.html'
})
export class PredictionGameComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() widgetData;
  data;
  @Input() osetParent;
  @ViewChild('predictionChartNewRef') chartElement: ElementRef;
  currentTimeSeries;
  groundTruthArr = [];
  userForecastArr = [];
  userName;
  userPredictedData = [];
  colorFlag;
  userPredictionDataFromInputBox = [{
    label: "Month 25",
    x: "25",
    value: null,
  }, {
    label: "Month 26",
    x: "26",
    value: null,
  }, {
    label: "Month 27",
    x: "27",
    value: null,
  }, {
    label: "Month 28",
    x: "28",
    value: null,
  }, {
    label: "Month 29",
    x: "29",
    value: null,
  }, {
    label: "Month 30",
    x: "30",
    value: null,
  }]
  childSection = "timeSeriesSection";
  gamePlays;
  isPredictionValuePresent = false;
  algorithm;
  value = 1;
  gaugeMaxValue = 1;
  displayedColumns = ["algorithm", "value"];
  dataSource = [];
  algorithmNames = [
    { "name": "Naïve", "abbrevation": "Error_NAIVE" },
    { "name": "Holt Winter’s Model", "abbrevation": "Error_HW" },
    { "name": "Theta Model", "abbrevation": "Error_ETS" },
    { "name": "Auto ARIMA", "abbrevation": "Error_ARIMA" },
    { "name": "TBATS Model", "abbrevation": "Error_TBATS" },
    { "name": "XGBoost for Time Series", "abbrevation": "Error_XGBAR" },
    { "name": "Neural Net for Time Series", "abbrevation": "Error_TSLMX" },
    { "name": "AutoForecast/Driverless Forecast", "abbrevation": "Error_AutoForecast" }]

  percentValue = this.value / this.gaugeMaxValue;
  canvasRef;
  @ViewChild(MatSort) sort: MatSort;
  inputData = {
    "user_name": null,
    "series_no": null,
    "data": [],
    "algorithms": []
  }
  commonContainer: any = {
    aspectRatio: null,
    svgGroup: {
      margin: {
        left: 0.1 * 500,
        top: 0.1 * 500,
      }
    }
  }
  containerId: any;

  constructor(private galleryService: GalleryService,
    public dialog: MatDialog, private _el: ElementRef) { }

  ngAfterViewInit() {
    this.getRandomdataAndPlotGraph();
  }

  getRandomdataAndPlotGraph() {
    this.isPredictionValuePresent = false;
    this.resetGameData(true);
    this.currentTimeSeries = JSON.parse(JSON.stringify(this.data.timeSeriesData[Math.floor(Math.random() * this.data.timeSeriesData.length)]));
    // this.currentTimeSeries = {"Pred_Month_1":1166.462,"Pred_Month_2":1181.346,"Pred_Month_3":1204.402,"Pred_Month_4":1229.013,"Pred_Month_5":1241.218,"Pred_Month_6":1226.924,"Error_NAIVE":3.522786013,"Error_HW":4.947946008,"Error_ARIMA":2.951106427,"Error_ETS":2.951039082,"Error_TBATS":2.148917901,"Error_TSLMX":3.971096504,"Error_XGBAR":2.712276043,"Error_AutoForecast":2.094169738,"coordinates":[{"x":"1","y":1042.818},{"x":"2","y":1052.897},{"x":"3","y":1055.553},{"x":"4","y":1056.475},{"x":"5","y":1052.682},{"x":"6","y":1045.751},{"x":"7","y":1051.812},{"x":"8","y":1059.874},{"x":"9","y":1077.156},{"x":"10","y":1079.406},{"x":"11","y":1084.024},{"x":"12","y":1076.019},{"x":"13","y":1071.161},{"x":"14","y":1069.95},{"x":"15","y":1062.028},{"x":"16","y":1073.088},{"x":"17","y":1082.535},{"x":"18","y":1094.606},{"x":"19","y":1104.652},{"x":"20","y":1120.702},{"x":"21","y":1150.95},{"x":"22","y":1167.265},{"x":"23","y":1187.312},{"x":"24","y":1174.894}]};
    setTimeout(() => {
      this.groundTruthArr = [];
      this.userForecastArr = [];
      this.drawChart("#predictionChartNewRef");
    }, 0);
  }

  ngOnInit() {
    this.data = this.widgetData.data;
    setTimeout(() => this.openDialog());
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogInputBoxComponent, {
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(userName => {

      if (userName) {
        this.getUserName(userName);
        this.childSection = "timeSeriesSection";
      }
    });
  }

  getUserName(userName){
    let name = userName.split("_");
    this.userName = this.inputData.user_name = name[0] + "_" + Date.now();
  }

  y_scale;
  x_scale;
  tooltipDiv;
  drawChart(refid) {

    var canvas_width = 1100 * 0.8;
    var canvas_height = 500 * 0.8;
    var svg = d3.select(refid).append("svg")
      .attr("height", 500)
      .attr("width", 1000)
      .call(this.responsivefy.bind(this));

    this.canvasRef = svg.append("g")
      .attr("transform", "translate(" + this.commonContainer.svgGroup.margin.left + "," + this.commonContainer.svgGroup.margin.top + ")");

    let Pred_Month_Max = 0;
    let minArr = [];
    for (let i = 1; i <= 6; i++) {
      minArr.push(this.currentTimeSeries['Pred_Month_' + i]);
      if (Pred_Month_Max < this.currentTimeSeries['Pred_Month_' + i]) {
        Pred_Month_Max = this.currentTimeSeries['Pred_Month_' + i];
      }
    }

    let min = minArr.reduce((a, b) => Math.min(a, b));
    let coordinatesMax = this.currentTimeSeries.coordinates.reduce(function (l, v) {
      if (v.y > l) l = v.y;
      return l;
    }, 0)

    this.y_scale = d3.scale.linear()
      .domain([Math.min(d3.min(this.currentTimeSeries.coordinates, function (d) { return d.y - 50; }), min - 50), Math.max(Pred_Month_Max, coordinatesMax)])//d3.max(this.currentTimeSeries.coordinates, function (d) { return d.y + 50; }
      .range([canvas_height, 0]);

    this.x_scale = d3.scale.ordinal()
      .domain(["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"])
      .rangeRoundBands([0, canvas_width], 1);

    var y_axis = d3.svg.axis()
      .scale(this.y_scale)
      .orient("left");

    var x_axis = d3.svg.axis()
      .scale(this.x_scale)
      .orient("bottom");


    var line = d3.svg.line()
      .x((d) => {
        return this.x_scale(d.x);

      })
      .y((d) => {
        return this.y_scale(d.y);
      });

    //To render y axis        
    this.canvasRef.append("g")
      .style("fill", "none")
      .style("stroke", "#000")
      .style("shape-rendering", "crispEdges")
      .attr("class", "linear-chart-coordinates")
      .call(y_axis);

    //To render x axis        
    this.canvasRef.append("g")
      .style("fill", "none")
      .style("stroke", "#000")
      .style("shape-rendering", "crispEdges")
      .attr("class", "linear-chart-coordinates")
      .attr("transform", "translate(0, " + canvas_height + ")")
      .call(x_axis);

    this.userForecastArr = _.orderBy(this.userForecastArr, ['x'], ['asc'])
    if (refid == "#chart-gauge-map") {
      this.canvasRef.append("path")
        .attr("d", line(this.groundTruthArr))//.slice(0, 24)
        .attr("stroke", "green")
        .attr("fill", "none");

      this.canvasRef.append("path")
        .attr("d", line(this.userForecastArr.sort()))//.slice(0, 24)
        .attr("stroke", "pink")
        .attr("fill", "none");
    }


    this.canvasRef.append("g") // Draw the x Grid lines
      .attr("class", "prediction-chart-grid")
      .attr("transform", "translate(0," + canvas_height + ")")
      .call(this.make_x_axis()
        .tickSize(-canvas_height, 0, 0)
        .tickFormat("")
      );

    this.canvasRef.append("g") // Draw the y Grid lines
      .attr("class", "prediction-chart-grid")
      .call(this.make_y_axis()
        .tickSize(-canvas_width, 0, 0)
        .tickFormat("")
      );

    this.canvasRef.append("svg:path")
    .attr("d", line(this.currentTimeSeries.coordinates.slice(0, 24)))
    .attr("stroke", "black")
    .attr("fill", "none");

    this.tooltipDiv = d3.select("#predictionChartNewRef")
      .style("position", "relative")
      .append("div") // declare the properties for the div used for the tooltips
      .attr("class", "graph-tooltip") // apply the 'tooltip' class
      .style("opacity", 0);

    this.canvasRef.append("text")
      .attr("text-anchor", "middle")  // this makes it easy to centre the text as the transform is applied to the anchor
      .attr("transform", "translate(" + (canvas_width / 2) + "," + (canvas_height + 50) + ")")  // centre below axis
      .attr("class", "chart-axis-label")
      .text("MONTHS");

    this.renderCirclesOnSvg(this.currentTimeSeries.coordinates);


    var self = this;
    svg.on("click", function () {
      var coords = d3.mouse(this);

      if (d3.mouse(d3.event.currentTarget)[1] > 452) {
        return;
      }
      if (d3.mouse(d3.event.currentTarget)[1] <= 51) {
        return;
      }
      if (refid == "#chart-gauge-map") {
        return;
      }
      // console.log(y_scale.range());
      var newData = {
        //x: Math.round( x_scale.invert(coords[0])),  // Takes the pixel number to convert to number
        y: Math.round(self.y_scale.invert(d3.mouse(d3.event.currentTarget)[1] - 50))
      };


      // console.log(newData);
      var xPos = d3.mouse(d3.event.currentTarget)[0];
      var leftEdges = self.x_scale.range();
      var width = self.x_scale.rangeBand();
      var j;
      var clickedOn;
      for (j = 0; xPos > (leftEdges[j] + 50); j++) { }
      //do nothing, just increment j until case fails
      clickedOn = self.x_scale.domain()[j];
      if (!clickedOn || clickedOn < 25) {
        return;
      }

      self.updateChart(clickedOn, newData.y);
      self.isPredictionValuePresent = true;

      var foundIndex = _.findIndex(self.userPredictionDataFromInputBox, { "x": clickedOn });

      if (foundIndex > -1) {
        self.userPredictionDataFromInputBox[foundIndex].value = newData.y;
      }

    })
  }


  make_x_axis() { // function for the x grid lines
    return d3.svg.axis()
      .scale(this.x_scale)
      .orient("bottom")
      .ticks(5)
  }

  make_y_axis() { // function for the y grid lines
    return d3.svg.axis()
      .scale(this.y_scale)
      .orient("left")
      .ticks(10)
  }

  scrollToTop() {
    const scrollContainer = document.getElementById('scroll-container');
    scrollContainer.scrollTo(0, (this.osetParent[1].offsetTop - scrollContainer.offsetTop + 1));
  }
userPlotArr=[];
  updateChart(xValue, yValue) {
    this.removePlotedPoint(xValue);

    this.currentTimeSeries.coordinates.push({ "x": xValue, "y": yValue, userPrediction: true });
    this.userPlotArr=[];
    this.userPlotArr.push({ "x": xValue, "y": yValue, userPrediction: true });

    var foundIndex = _.findIndex(this.userForecastArr, { "x": xValue });

    if (foundIndex > -1) {
      this.userForecastArr[foundIndex].y = yValue;
    } else {
      this.userForecastArr.push({ "y": yValue, "x": xValue })
    }

    this.renderCirclesOnSvg(this.userPlotArr);
  }

  renderCirclesOnSvg(datCordinates) {
    this.canvasRef.selectAll(".circle")
      .data(datCordinates)
      .enter()
      .append("circle")
      .attr("id", function (d) { return "circle_" + d.x })
      .attr("cy", (d) => { return this.y_scale(d.y) })
      .attr("cx", (d) => { return this.x_scale(d.x) })
      .attr("r", 5)
      .attr("fill", function (d) { return d.userPrediction == 'system-forecast' ? '#006400' : !d.userPrediction ? '#42b1ff' : '#ff3d4b' })
      .on("mouseover", (d) => { // when the mouse goes over a circle, do the following
        let { svgGroup, aspectRatio } = this.commonContainer;
        this.tooltipDiv.transition() // declare the transition properties to bring fade-in div
          .duration(600) // it shall take 200ms
          .style("opacity", 0.9); // and go all the way to an opacity of .9
        this.tooltipDiv.html(d.y) // add the text of the tooltip as html
          .style({
            left: (((this.x_scale(d.x) + svgGroup.margin.left + 11) * aspectRatio.widthAspect)) + "px",
            top: (((this.y_scale(d.y) + svgGroup.margin.top) * aspectRatio.heightAspect) - (this.tooltipDiv.node().offsetHeight / 2)) + "px"
          });
      })
      .on("mouseout", (d) => { // when the mouse leaves a circle, do the following
        this.tooltipDiv.transition() // declare the transition properties to fade-out the div
          .duration(500) // it shall take 500ms
          .style("opacity", 0); // and go all the way to an opacity of nil
      });
  }
  updatePredictionValue(index) {
    //console.log(this.userPredictionDataFromInputBox);

    this.updateChart(this.userPredictionDataFromInputBox[index].x, this.userPredictionDataFromInputBox[index].value)
  }

  clearPrediction(index) {
    this.userPredictionDataFromInputBox[index].value = null;
    this.removePlotedPoint(this.userPredictionDataFromInputBox[index].x);
    this.modelChanged(null);
  }

  removePlotedPoint(xValue) {
    var foundIndex = _.findIndex(this.currentTimeSeries.coordinates, { "x": xValue });

    if (foundIndex > -1) {
      d3.selectAll('g #circle_' + xValue).remove();
      this.currentTimeSeries.coordinates.splice(foundIndex, 1);
    }
  }

  modelChanged(event) {
    if (!event) {
      let foundElements = _.reject(this.userPredictionDataFromInputBox, ['value', null]);;
      if (foundElements.length) {
        this.isPredictionValuePresent = true;
      } else {
        this.isPredictionValuePresent = false;
      }
      // console.log(foundIndex);
    } else {
      this.isPredictionValuePresent = true;
    }

  }

  resetGameData(isPlayAgain) {
    this.inputData = {
      user_name: this.userName,
      series_no: 2,
      data: [],
      algorithms: []
    };

    if (isPlayAgain) {
      this.userPredictionDataFromInputBox = [{
        label: "Month 25",
        x: "25",
        value: null,
      }, {
        label: "Month 26",
        x: "26",
        value: null,
      }, {
        label: "Month 27",
        x: "27",
        value: null,
      }, {
        label: "Month 28",
        x: "28",
        value: null,
      }, {
        label: "Month 29",
        x: "29",
        value: null,
      }, {
        label: "Month 30",
        x: "30",
        value: null,
      }]
    }

  }

  getUsersGameData() {
    this.resetGameData(false);

    let dataDetails = {};
    let actualPointSeriesMapping = {
      "25": 1,
      "26": 2,
      "27": 3,
      "28": 4,
      "29": 5,
      "30": 6
    }

    let algorithmsName = ["Error_NAIVE", "Error_HW", "Error_ARIMA", "Error_ETS", "Error_TBATS", "Error_TSLMX", "Error_XGBAR", "Error_AutoForecast"];
    let maxValuePrediction = _.maxBy(this.userPredictionDataFromInputBox, 'value');
    for (let index = 0; index < this.userPredictionDataFromInputBox.length; index++) {
      dataDetails = {
        point: "p" + (index + 1),
        actual_point: this.currentTimeSeries["Pred_Month_" + actualPointSeriesMapping[this.userPredictionDataFromInputBox[index].x]],
        user_plotted_point: this.userPredictionDataFromInputBox[index].value || maxValuePrediction.value
      }

      this.inputData.data.push(dataDetails);
    }

    for (let index = 0; index < algorithmsName.length; index++) {

      this.inputData.algorithms.push({
        [algorithmsName[index]]: this.currentTimeSeries[algorithmsName[index]]
      });
    }
    this.inputData.series_no = this.currentTimeSeries.series_no;
  }

  submitGame() {
    this.getUsersGameData();
    this.scrollToTop();
    this.childSection = "gaugeSection";
    let url = this.data.matchPredictionAPI;
    this.userName = this.inputData.user_name;
    this.galleryService.postData(url, this.inputData).subscribe((response: any) => {
      let maxObjValue: any;
      let minObjValue: any;
      let tableData = [];
      let inputAlgorithms = response.data.algorithms;
      for (let key in inputAlgorithms) {
        let algorithm = _.find(this.algorithmNames, ['abbrevation', key]);
        tableData.push({ "algorithm": algorithm.name, "value": inputAlgorithms[key] })
        if (!maxObjValue) {
          maxObjValue = minObjValue = inputAlgorithms[key];
        } else {
          if (inputAlgorithms[key] > maxObjValue) {
            maxObjValue = inputAlgorithms[key];
          }
          if (inputAlgorithms[key] < minObjValue) {
            minObjValue = inputAlgorithms[key];
          }
        }
      }

      this.dataSource = tableData.sort(function (a, b) {
        return b.value - a.value;
      });


      this.value = response.data.score;
      if (this.value >= (minObjValue - 0.1) && this.value <= (minObjValue + 0.1)) {
        this.colorFlag = true;
      }
      this.gaugeMaxValue = maxObjValue;
      this.percentValue = this.value / this.gaugeMaxValue;
      if (this.value > this.gaugeMaxValue) {
        this.percentValue = this.gaugeMaxValue / this.gaugeMaxValue;
      }
      if (this.value < 0) {
        this.percentValue = 0;
      }
      let algorithm = _.find(this.algorithmNames, ['abbrevation', response.data.algorithm_based_on_match_score]);
      this.algorithm = algorithm.name;
      this.groundTruthArr.push(this.currentTimeSeries.coordinates[23]);
      this.userForecastArr.push(this.currentTimeSeries.coordinates[23]);

      for (let i = 1; i <= 6; i++) {
        this.currentTimeSeries.coordinates.push({ "x": 24 + i, "y": this.currentTimeSeries['Pred_Month_' + i], userPrediction: 'system-forecast' });
        this.groundTruthArr.push({ "x": 24 + i, "y": this.currentTimeSeries['Pred_Month_' + i], userPrediction: 'system-forecast' });

      }
      setTimeout(() => {
        //this.groundTruthArr=[];
        this.drawChart("#chart-gauge-map");
        this.loadSlider();
      }, 50)
    });

  }

  playAgain() {
    this.scrollToTop();
    this.childSection = "timeSeriesSection";
    this.algorithm = "";
    this.getRandomdataAndPlotGraph();
  }

  getAlgorithmValue(gamePlay, algorithm) {
    let algorithmValue;
    gamePlay.algorithms.forEach(element => {
      for (let key in element) {
        if (key == algorithm) {
          algorithmValue = element[key];
          break;
        }
      }
    });
    return algorithmValue;
  }

  getAlgorithmName(algorithm) {
    return _.find(this.algorithmNames, ['abbrevation', algorithm]).name;
  }

  valueOrder = (a: KeyValue<string, number>, b: KeyValue<string, number>): number => {
    return a.value < b.value ? 1 : (b.value < a.value ? -1 : 0)
  }

  endGame() {
    this.scrollToTop();
    this.childSection = "scoreBoardSection";
    this.gamePlays = [];
    this.galleryService.getData(this.data.getScorecardAPI + this.userName).subscribe((response: any) => {
      this.gamePlays = response.data.gameplays;
    });
  }

  restartGame() {
    this.scrollToTop();
    this.getUserName(this.userName);
    this.childSection = "timeSeriesSection";
    this.getRandomdataAndPlotGraph();
  }

  loadSlider() {

    var barWidth, chart, chartInset, degToRad, repaintGauge,
      height, margin, numSections, padRad, percToDeg, percToRad,
      percent, radius, sectionIndx, svg, totalPercent, width;


    percent = this.percentValue;

    numSections = 1;
    var sectionPerc = 1 / numSections / 2;
    padRad = 0.025;
    chartInset = 10;

    // Orientation of gauge:
    totalPercent = .75;

    let el = d3.select('.chart-gauge');

    margin = {
      top: 40,
      right: 20,
      bottom: 30,
      left: 60
    };

    width = el[0][0].offsetWidth - margin.left - margin.right;
    height = width;
    radius = Math.min(width, height) / 2;
    barWidth = 40 * width / 300;
    //Utility methods 

    percToDeg = (perc) => {
      return perc * 360;
    };

    percToRad = (perc) => {
      return degToRad(percToDeg(perc));
    };

    degToRad = (deg) => {
      return deg * Math.PI / 180;
    };

    // Create SVG element
    svg = el.append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);

    // Add layer for the panel
    chart = svg.append('g').attr('transform', "translate(" + ((width + margin.left) / 2) + ", " + ((height + margin.top) / 2) + ")");

    chart.append('path').attr('class', "arc chart-first");

    let formatValue = d3.format('1%');

    let arc1 = d3.svg.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)

    repaintGauge = () => {
      let perc = 0.5;
      var next_start = totalPercent;
      let arcStartRad = percToRad(next_start);
      let arcEndRad = arcStartRad + percToRad(perc);
      next_start += perc;

      arc1.startAngle(arcStartRad).endAngle(arcEndRad);

      chart.select(".chart-first").attr('d', arc1);
    }

    var dataset = [{
      metric: "Value",
      value: this.value
    }]

    var texts = svg.selectAll("text")
      .data(dataset)
      .enter();

    texts.append("text")
      .text(() => {
        return 0;
      })
      .attr('id', 'scale0')
      .attr('transform', "translate(" + (((width + margin.left) / 100) + 15) + ", " + ((height + margin.top) / 2) + ")")
      .attr("font-size", 15)
      .attr("class", "gauge-points")
      .style("fill", "#000000");


    texts.append("text")
      .text(() => {
        return this.gaugeMaxValue.toFixed(2);
      })
      .attr('id', 'scale20')
      .attr('transform', "translate(" + (((width + 15 + margin.left) / 1.08) - 5) + ", " + ((height + margin.top) / 2) + ")")
      .attr("font-size", 15)
      .attr("class", "gauge-points")
      .style("fill", "#000000");

    var Needle = (() => {

      //Helper function that returns the `d` value for moving the needle
      var recalcPointerPos = function (perc) {
        var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
        thetaRad = percToRad(perc / 2);
        centerX = 0;
        centerY = 0;
        topX = centerX - this.len * Math.cos(thetaRad);
        topY = centerY - this.len * Math.sin(thetaRad);
        leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
        leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
        rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
        rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
        return "M " + leftX + " " + leftY + " L " + topX + " " + topY + " L " + rightX + " " + rightY;
      };

      function Needle(el) {
        this.el = el;
        this.len = width / 2.5;
        this.radius = this.len / 8;
      }

      Needle.prototype.render = function () {
        this.el.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 0).attr('r', this.radius);
        return this.el.append('path').attr('class', 'slider-needle').attr('id', 'client-needle').attr('d', recalcPointerPos.call(this, 0));
      };

      Needle.prototype.moveTo = function (perc) {
        var self, oldValue = this.perc || 0;
        this.perc = perc;
        self = this;

        // Reset pointer position
        this.el.transition().delay(100).ease('quad').duration(200).select('.slider-needle').tween('reset-progress', function () {
          return (percentOfPercent) => {
            var progress = (1 - percentOfPercent) * oldValue;
            repaintGauge(progress);
            return d3.select(this).attr('d', recalcPointerPos.call(self, progress));
          };
        });

        this.el.transition().delay(200).ease('bounce').duration(1000).select('.slider-needle').tween('progress', function () {
          return (percentOfPercent) => {
            var progress = percentOfPercent * perc;
            repaintGauge(progress);
            return d3.select(this).attr('d', recalcPointerPos.call(self, progress));
          };
        });
      };
      return Needle;
    })();

    let needle = new Needle(chart);
    needle.render();
    needle.moveTo(percent);

  }


  responsivefy(svg) {
    // get container + svg aspect ratio
    var container = d3.select(svg.node().parentNode),
      width = parseInt(svg.style("width")),
      height = parseInt(svg.style("height")),
      aspect = width / height;

    // get width of container and resize svg to fit it
    let resize = () => {
      var targetWidth = parseInt(container.style("width"));
      svg.attr("width", targetWidth);
      let targetHeight = targetWidth / aspect;
      svg.attr("height", Math.round(targetHeight));

      this.commonContainer.aspectRatio = {
        widthAspect: targetWidth / width,
        heightAspect: targetHeight / height
      };
    }

    // add viewBox and preserveAspectRatio properties,
    // and call resize so that svg resizes on inital page load
    svg.attr("viewBox", "0 0 " + width + " " + height)
      .attr("perserveAspectRatio", "xMinYMid")
      .call(resize);
    this.containerId = container.attr("id");
    // to register multiple listeners for same event type, 
    // you need to add namespace, i.e., 'click.foo'
    // necessary if you call invoke this function for multiple svgs
    // api docs: https://github.com/mbostock/d3/wiki/Selections#on
    d3.select(window).on("resize." + container.attr("id"), resize);
  }

  ngOnDestroy() {
    d3.select(window).on("resize." + this.containerId, null);
  }
}