import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-dialog-box',
  templateUrl: 'dialog-box.component.html'
})
export class DialogBoxComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}
  	imageData;

  	ngOnInit() {
  		// console.log(this.data);
  		this.imageData = { 
				data: {
					url: this.data.dataUrl
				}
      }  		
  	}
	onNoClick(): void {
	   	this.dialogRef.close(this.data);
	}

}