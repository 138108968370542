import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseModel } from '../models/base.model';

@Injectable()
export class DynamicFormService {
  constructor() { }

  toFormGroup(controls: BaseModel<any>[] ) {
    let group: any = {};

    controls.forEach(control => {
    	// console.log("In Form service:-------");
    	// console.log("control", control);
      group[control.uniqueIdentity] = control.required ? new FormControl(control.value || '', Validators.required)
                                              : new FormControl(control.value || '');
    });
    // console.log("group----->", group);
    return new FormGroup(group);
  }
}