import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-dialog-input-box',
	templateUrl: 'dialog-input-box.component.html'
})
export class DialogInputBoxComponent implements OnInit {

	inputData;

	constructor(
		public dialogRef: MatDialogRef<DialogInputBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any) { }

	ngOnInit() {
		// console.log(this.data);
	}

	onNoClick(): void {
		if(this.inputData){
			this.dialogRef.close(this.inputData);
		}		
	}

}