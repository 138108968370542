export enum UserStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    ACTIVE_BLOCK = 'Active (Blocked)'
}

export enum UserType {
    ADMIN = 'Admin',
    NON_ZS = 'Non ZS',
    ZS = 'ZS',
    CUSTOM = 'custom',
    SSO = 'zser'
}
export enum GAIndex {
    NAME = 'dimension1',
    EMAIL_ID = 'dimension2',
    CAPABILITY_GROUP = 'dimension3',
    CLIENT_NAME = 'dimension4',
    REQUESTER_NAME = 'dimension5',
    USER_TYPE = 'dimension6',
    ERROR_REASON = 'dimension7',
    DEPARTMENT = 'dimension8',
    CLIENT_NAME_OR_PURPOSE = 'dimension9'
}

export enum GAUserType {
    ADMIN = 'Admin',
    NON_ZS = 'Non-SSO',
    ZS = 'SSO'
}

// export enum GAEvents {
//     PLAY = 'Play',
//     SEARCH = 'Search',
//     CLICK = 'Click',
//     VIEW = 'View',
//     DS_VIEW = 'DS View',
//     CREATE = 'Create',
//     UPDATE = 'Update'
// }

// export enum GACategory {
//     VIDEO = 'Video',
//     FILTER = 'Filter',
//     USECASE = 'Usecase',
//     USER_MANAGEMENT = 'User Management'
// }

// export enum GALabel {
//     KNOW_MORE = 'Know More',
//     TAB_FILTER = 'Tab Filter',
//     WILDCARD_SEARCH = 'Wildcard Search'
// }
