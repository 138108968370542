import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { DialogBoxD3TreeComponent } from '../../common/components/dialog-box-d3-tree/dialog-box-d3-tree.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WidgetService } from '../widgets.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-grid-widget',
  templateUrl: './grid-widget.component.html',
  styleUrls: ['./grid-widget.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GridWidgetComponent implements OnInit {
  @Input() widgetData;
  data;

  constructor(public dialog: MatDialog, private _sanitizer: DomSanitizer, private widgetService: WidgetService) {
    this.widgetService.triggerDataChangeForDTGridWidget.subscribe((updatedDataInfo) => {
      if (updatedDataInfo.formField.uniqueNameTree201 && updatedDataInfo.formField.uniqueNameTree201.length) {
        this.updateChart(updatedDataInfo);
      } else {
        this.resetToOrignal();
      }
    });
  }

  updateChart(updatedDataInfo) {
    const category = updatedDataInfo.formField.uniqueNameTree201;
    this.data = _.filter(this.widgetData.data, function (o) { return o.category === category; });
  }

  resetToOrignal() {
    this.data = this.widgetData.data;
  }

  ngOnInit() {
    this.data = this.widgetData.data;
  }

  onGridClick(gridData) {
    if (gridData.isClickable) {
      this.openDialog(gridData);
    }
  }

  openDialog(gridData) {
    const dialogRef = this.dialog.open(DialogBoxD3TreeComponent, {
      data: { treedata: gridData, widgetData: this.widgetData }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  sanitizeURL(url) {
    if (!url) {
      return `#ccc no-repeat`;
    }
    return this._sanitizer.bypassSecurityTrustStyle(`#ccc url(${url}) no-repeat`);
  }

  getClassName(category) {
    return category.split(' ').join('-');
  }
}
