import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-grid-loader-component',
    templateUrl: './grid-loader.component.html',
    styleUrls: ['./grid-loader.component.css']
})
export class GridLoaderComponent implements OnInit {
    constructor(private loaderService: LoaderService) { }
    ngOnInit() {}
    ngOnDestroy() {}
}