export class SmallClusterService {
    private clusterData: Array<any> = [];

    createData(index, settings, count) {
        const { categories, width, height } = settings;
        this.clusterData[index] = { "nodes": [] };
        for (let subIndex = 1; subIndex < count; subIndex++) {
            let tempObject = {
                id: subIndex,
                type: categories
            };
            this.clusterData[index].nodes.push(tempObject);
        }
        let i = 0;
        let initialCircleAxis = {
            "fixed": true,
            id: i,
            x: width,
            y: height / 2,
            type: categories
        }
        this.clusterData[index].nodes.unshift(initialCircleAxis);
    }

    setData(nodeData, index) {
        this.clusterData[index].nodes = nodeData.nodes;
    }

    getData(index) {
        return this.clusterData[index];
    }
}
