import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Input } from '@angular/core';
declare let d3: any;

@Component({
  selector: 'app-d3-boxplot',
  templateUrl: './d3-boxplot.component.html',
  styleUrls: ['./d3-boxplot.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class D3BoxplotComponent implements OnInit {

  @ViewChild('d3BoxplotChartRef') chartElement: ElementRef;
  @Input() widgetData;
  @Input() widgetsCount;

  constructor() { }

  ngOnInit() {
    this.loadBoxplot();
  }

  loadBoxplot() {
    var labels = true; // show the text labels beside individual boxplots?

    // var margin = { top: 30, right: 50, bottom: 112, left: 50 };
    // var width = 800 - margin.left - margin.right;
    // var height = 400 - margin.top - margin.bottom;

    var margin = { top: 30, right: 50, bottom: 80, left: 50 },
      width = (this.widgetsCount > 1 ? 580 : 700) - margin.left - margin.right, //
      height = (this.widgetsCount > 1 ? 250 : 500) - margin.top - margin.bottom; //

    var min = Infinity,
      max = -Infinity;

    var blogplotData = this.widgetData.data;

    var data = [];
    data[0] = [];
    data[1] = [];
    data[2] = [];
    data[3] = [];
    data[4] = [];
    data[5] = [];
    //data[6] = [];

    // add more rows if your csv file has more columns

    // add here the header of the csv file
    //data[0][0] = "Customer Id";
    data[0][0] = "HQ Email";
    data[1][0] = "Mobile Alert";
    data[2][0] = "Rep Email";
    data[3][0] = "Rep Visit";
    data[4][0] = "Speaker Program";
    data[5][0] = "Third Party Email";
    // add more rows if your csv file has more columns

    data[0][1] = [];
    data[1][1] = [];
    data[2][1] = [];
    data[3][1] = [];
    data[4][1] = [];
    data[5][1] = [];
    //data[6][1] = [];
    blogplotData.forEach(function (x) {
      var v1 = Math.floor(x["HQ Email"]),
        v2 = Math.floor(x["Mobile Alert"]),
        v3 = Math.floor(x["Rep Email"]),
        v4 = Math.floor(x["Rep Visit"]),
        v5 = Math.floor(x["Speaker Program"]),
        v6 = Math.floor(x["Third Party Email"]);
      // add more variables if your csv file has more columns

      var arr = [v1, v2, v3, v4, v5, v6]
      // 		var rowMax = Math.max(v1, Math.max(v2, Math.max(v3, Math.max(v4,Math.max(v5, Math.max(v6, Math.max(v7,v8)))))));
      // 		var rowMin = Math.min(v1, Math.min(v2, Math.min(v3, Math.min(v4,Math.min(v5, Math.min(v6, Math.min(v7,v8)))))));
      var rowMax = Math.max(v1, v2, v3, v4, v5, v6);
      var rowMin = Math.min(v1, v2, v3, v4, v5, v6);

      data[0][1].push(v1);
      data[1][1].push(v2);
      data[2][1].push(v3);
      data[3][1].push(v4);
      data[4][1].push(v5);
      data[5][1].push(v6);
      // add more rows if your csv file has more columns

      if (rowMax > max) max = rowMax;
      if (rowMin < min) min = rowMin;

      max = 30

     });


    var chart = d3.box()
      .whiskers(iqr(1.5))
      .height(height)
      .domain([min, max])
      .showLabels(labels);

    var svg = d3.select(this.chartElement.nativeElement).append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("class", "box")
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // the x-axis
    var x = d3.scale.ordinal()
      .domain(data.map(function (d) { return d[0] }))
      .rangeRoundBands([0, width], 0.7, 0.3);

    var xAxis = d3.svg.axis()
      .scale(x)
      .orient("bottom");

    // the y-axis
    var y = d3.scale.linear()
      .domain([min, max])
      .range([height + margin.top, 0 + margin.top]);

    var yAxis = d3.svg.axis()
      .scale(y)
      .orient("left")

    // draw the boxplots	
    svg.selectAll(".box")
      .data(data)
      .enter().append("g")
      .attr("transform", function (d) { return "translate(" + x(d[0]) + "," + margin.top + ")"; })
      .call(chart.width(x.rangeBand()));


    // add a title
    // svg.append("text")
    //   .attr("x", (width / 2))
    //   .attr("y", 0 + (margin.top / 2))
    //   .attr("text-anchor", "middle")
    //   .style("font-size", "18px")
    //.style("text-decoration", "underline")  
    //.text("SFFD Response Times by Call Type in Dec 2016");

    // draw y axis
    svg.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text") // and text1
      .attr("transform", "rotate(-90)")
      .attr("y", -35)
      .attr("x", -58)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      // .style("font-size", "11px")
      // .style("font-weight", "bold")
      .text("No of days [Gap]");

    // draw x axis	
    svg.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0," + (height + margin.top + 10) + ")")
      .call(xAxis)
      .append("text")             // text label for the x axis
      .attr("x", (width / 2))
      .attr("y", 40)
      .attr("dy", ".71em")
      .style("text-anchor", "middle")
      .style("font-weight", "bold");
    //.text("Call Type");
    //});

    // Returns a function to compute the interquartile range.
    function iqr(k) {


      return function (d, i: number) {
        var q1 = d.quartiles[0],
          q3 = d.quartiles[2],
          iqr = (q3 - q1) * k,
          i = -1,
          j = d.length;
        while (d[++i] < q1 - iqr);
        while (d[--j] > q3 + iqr);
        return [i, j];

      };
    }
  }
}
