import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
	selector: 'app-video-player',
	templateUrl: './video-player.component.html'
})
export class VideoPlayerComponent implements OnInit, AfterViewInit {

	@ViewChild('videoPlayer') videoplayer: ElementRef;
	@Input() widgetData;
	data;
	showPlayButton = false;


	ngAfterViewInit() {
		if (!this.widgetData.data.autoplay) {
			this.videoplayer.nativeElement.pause();
		}
	}

	ngOnInit() {
		this.data = this.widgetData.data;
		if (!this.widgetData.data.autoplay) {
			this.showPlayButton = true;
		}
	}

	toggleVideo(event: any) {
		event.preventDefault();
		if (this.videoplayer.nativeElement.paused) {
			this.showPlayButton = false;
			this.videoplayer.nativeElement.play();
		} else {
			this.showPlayButton = true;
			this.videoplayer.nativeElement.pause();
		}

	}

	onPaused() {
		this.showPlayButton = true;
	}

	onPlaying() {
		this.showPlayButton = false;
	}
}
